import {useAppSelector} from "@app/hooks";
import SVGIcon from "@components/SVGIcon";
import {selectDepartmentById} from "@state/departments/slice";
import {selectQboIntegrationVendors} from "@state/integrations/slice";

import {selectors, selectSelectedRow} from "../state/selectors";
import DatasourceDebugTools from "./Datasources";
import useStyles from "./RowDebugTools.jss";

export default function RowDebugTools({resolveIds}: {resolveIds: boolean}) {
  const activeCell = useAppSelector(selectors.activeCell);
  const styles = useStyles();

  const selectedRow = useAppSelector(selectSelectedRow);
  const department = useAppSelector((state) => selectDepartmentById(state, activeCell?.departmentId ?? "")) ?? null;
  const vendorsMapping = useAppSelector(selectQboIntegrationVendors);
  const vendor = vendorsMapping[activeCell?.vendor ?? ""] ?? null;

  return (
    <div className={styles.rowDebugToolsMain}>
      {!activeCell?.rowId ? (
        <div>No active cell</div>
      ) : (
        <>
          <ul className={styles.selectedRowInfos}>
            <li>
              <span className={styles.title}>
                <SVGIcon name="addRow" />
                Row
              </span>
              <span className={styles.value}>
                <span>{selectedRow?.display_name}</span>
                <span className={styles.slug}>{selectedRow?.name}</span>
              </span>
            </li>
            {department && (
              // <li>
              //   <span className={styles.title}>
              //     <SVGIcon name="department" />
              //     Department
              //   </span>
              //   : <span className={styles.value}>{department.display_name}</span>
              // </li>
              <li>
                <span className={styles.title}>
                  <SVGIcon name="department" />
                  Department
                </span>
                <span className={styles.value}>
                  <span>{department.display_name}</span>
                  <span className={styles.slug}>{department.name}</span>
                </span>
              </li>
            )}
            {vendor && (
              <li>
                Vendor:{" "}
                <span className={styles.value}>
                  <SVGIcon name="vendor" />
                  {vendor.displayName}
                </span>
              </li>
            )}
          </ul>
          <DatasourceDebugTools resolveIds={resolveIds} />
        </>
      )}
    </div>
  );
}
