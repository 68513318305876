import {createEffectHandler} from "../listeners-list";
import {api, deleteDepartments, upsertDepartments} from "./slice";

export const syncDepartmentUpsertsWithAPI = createEffectHandler(upsertDepartments, (action, {getState, dispatch}) => {
  if (!action.payload) return;
  const departments = Array.isArray(action.payload) ? action.payload : Object.values(action.payload);
  dispatch(api.upsertDepartments(departments));
});

export const syncDepartmentDeletesWithAPI = createEffectHandler(deleteDepartments, (action, {getState, dispatch}) => {
  dispatch(api.deleteDepartments(action.payload as string[]));
});
