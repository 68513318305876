import {globalStyles} from "@app/styles/general-ui";
import {sharedValues as sidebarSharedValues} from "@components/RightSidebar/styles.jss";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  templateMain: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 25,
    position: "relative",
    height: "calc(100% - 55px)",

    "& > table": {
      marginTop: 15,
      overflow: "auto",
      height: "100%",
      display: "block",
      paddingBottom: 30,
      marginRight: navigator.platform.includes("Win")
        ? `calc(-${globalStyles.mainViewPaddingX}px - 17px)`
        : -globalStyles.mainViewPaddingX,
      paddingRight: navigator.platform.includes("Win")
        ? `calc(${globalStyles.mainViewPaddingX}px + 16px)`
        : globalStyles.mainViewPaddingX,
      "& th": {
        position: "sticky",
        top: 0,
        zIndex: 2,

        "&:first-child": {
          left: 0,
          zIndex: 3,
        },
      },

      "& td": {
        height: 40,
        verticalAlign: "middle",

        "&:first-child": {
          position: "sticky",
          left: 0,
          zIndex: 2,
          // backgroundColor: colors.ui.white,
        },
      },

      "& tr:first-child > td > div:first-child": {
        //borderTop: 0,
      },
    },

    "&$sidebarOpen": {
      transition: "transform 0.3s",
      "& > div:first-child, & > table": {
        // paddingRight: sidebarSharedValues.width,
        transform: `translateX(-${sidebarSharedValues.width - 25}px)`,
        paddingLeft: sidebarSharedValues.width - 25,
      },
    },
  },
  sidebarOpen: {
    // "& > div:first-child, & > table": {
    //   // paddingRight: sidebarSharedValues.width,
    //   transform: `translateX(-${sidebarSharedValues.width - 25}px)`,
    //   paddingLeft: sidebarSharedValues.width - 25,
    // },
  },
  // templateContentWrapper: {
  //   "$sidebarOpen &": {
  //     transform: `translateX(-${sidebarSharedValues.width}px)`,
  //   },
  // },
  dragging: {
    cursor: "crosshair",
  },
});
