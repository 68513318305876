import memoizeOne from "memoize-one";

import {getFormulaRefType, matchWithNamedGroups} from "./formula-utilities";
import {analyzeFormula, type FormulaChunkWithIndexes} from "./parse-utilities";

import type {ValidatedChunk} from "client/features/shared-views/FormulaTextEditor/pretty-print-provider";

const advancedFormulaTypeMapping = {
  department: "department",
  vendor: "vendor",
  row: "row",
  from: "range_start",
  to: "range_end",
  compensation_type: "compensation_type",
  team: "team",
} as const;

export const prettyPrintFormulaToHtmlString = memoizeOne((formula: string | null) => {
  const chunks = analyzeFormula(formula);
  return {chunks, html: prettyPrintChunksToHtmlString(chunks)};
});

export const identifyAdvancedFormulaPartType = (key: string) => {
  return key in advancedFormulaTypeMapping
    ? advancedFormulaTypeMapping[key as keyof typeof advancedFormulaTypeMapping]
    : "generic";
};

export function prettyPrintChunksToHtmlString(chunks: (FormulaChunkWithIndexes | ValidatedChunk)[]) {
  let htmlStr = "";
  for (const chunk of chunks) {
    const chunkClasses: string[] = [];
    const typeClassName = `t_${chunk.type}`;

    chunkClasses.push(typeClassName);
    if (chunk.subtype) {
      const subtypeClassName = `st_${chunk.subtype.replace("_value", "")}`;
      chunkClasses.push(subtypeClassName);
    }

    if ("isValidRef" in chunk && chunk.isValidRef) {
      chunkClasses.push("validated");
    }

    htmlStr += `<span class="${chunkClasses.join(" ")}">${chunk.value}</span>`;
  }
  htmlStr += "";
  return htmlStr;
}

export const parseRefStr = (refStr: string, refStartIdx: number = 0): FormulaChunkWithIndexes[] => {
  const formulaChunks: FormulaChunkWithIndexes[] = [];
  const refType = getFormulaRefType(refStr);

  if (refType === "advanced") {
    let startOfCurrentBuffer = 0;
    let cursor = 0;
    let colonIdxInSegment: number | null = null;

    while (cursor <= refStr.length) {
      if (refStr[cursor] === "[") {
        // gather the start bracket and any following spaces
        let startBracketStartIndex = cursor;
        let startBracketStr = "[";

        while (refStr[cursor] === " ") {
          cursor++;
          startBracketStr += refStr[cursor];
        }
        formulaChunks.push({
          type: "cb_advanced_ref",
          subtype: "start",
          value: startBracketStr,
          startIndex: startBracketStartIndex,
          endIndex: cursor,
        });
        startOfCurrentBuffer = cursor + 1;
      } else if (refStr[cursor] === "," || refStr[cursor] === "]" || cursor === refStr.length) {
        const segment = refStr.slice(startOfCurrentBuffer, cursor);

        const colonIdx = colonIdxInSegment === null ? segment.indexOf(":") : colonIdxInSegment - startOfCurrentBuffer;
        const key = segment.slice(0, colonIdx).trim();

        // Determine the type of this part
        const type = identifyAdvancedFormulaPartType(key);

        // Capture the original text for key, colon (with spaces), and value
        const originalKeySegment = segment.slice(0, colonIdx);

        if (originalKeySegment.length) {
          formulaChunks.push({
            type: "cb_advanced_ref",
            subtype: "key",
            value: originalKeySegment,
            startIndex: startOfCurrentBuffer,
            endIndex: startOfCurrentBuffer + originalKeySegment.length - 1,
          });
        }

        // gather the delimiter characters (colon and any surrounding spaces)
        let delimiterStartIndex = startOfCurrentBuffer + originalKeySegment.length;
        let delimiterEndIndex = delimiterStartIndex;
        let delimiterStr = refStr[delimiterEndIndex] ?? "";

        while (refStr[delimiterEndIndex + 1] === " ") {
          delimiterEndIndex++;
          delimiterStr += refStr[delimiterEndIndex];
        }

        if (delimiterStr?.length) {
          formulaChunks.push({
            type: "cb_advanced_ref",
            subtype: "key_value_delimiter",
            value: delimiterStr,
            startIndex: delimiterStartIndex,
            endIndex: delimiterEndIndex,
          });
        }

        // gather the value
        let valueStartIndex = delimiterEndIndex + 1;
        let valueEndIndex = cursor;
        let valueStr = refStr.slice(valueStartIndex, valueEndIndex);
        if (valueStr.length) {
          if (type === "row" && valueStr.includes("!")) {
            const [templateName, rowName] = valueStr.split("!");
            formulaChunks.push(
              {
                type: "cb_advanced_ref",
                subtype: "row_template_value",
                value: templateName,
                startIndex: valueStartIndex,
                endIndex: valueStartIndex + templateName.length - 1,
              },
              {
                type: "cb_advanced_ref",
                subtype: "row_template_delimiter",
                value: "!",
                startIndex: valueStartIndex + templateName.length,
                endIndex: valueStartIndex + templateName.length,
              },
              {
                type: "cb_advanced_ref",
                subtype: "row_value",
                value: rowName,
                startIndex: valueStartIndex + templateName.length + 1,
                endIndex: valueStartIndex + valueStr.length - 1,
              },
            );
          } else {
            formulaChunks.push({
              type: "cb_advanced_ref",
              subtype: `${type}_value`,
              value: valueStr,
              startIndex: valueStartIndex,
              endIndex: valueStartIndex + valueStr.length - 1,
            });
          }
        }

        // Capture comma if it's there, along with any surrounding spaces
        if (refStr[cursor] === ",") {
          let commaStartIndex = cursor;
          let commaChunkStr = ",";
          while (refStr[cursor + 1] === " ") {
            cursor++;
            commaChunkStr += refStr[cursor];
          }

          formulaChunks.push({
            type: "cb_advanced_ref",
            subtype: `key_value_pair_delimiter`,
            value: commaChunkStr,
            startIndex: commaStartIndex,
            endIndex: cursor,
          });
        }

        if (refStr[cursor] === "]") {
          // gather the end bracket and any preceding spaces
          let endBracketStartIndex = cursor;
          let endBracketStr = "]";
          while (refStr[cursor] === " ") {
            cursor++;
            endBracketStr += refStr[cursor];
          }

          formulaChunks.push({
            type: "cb_advanced_ref",
            subtype: "end",
            value: endBracketStr,
            startIndex: endBracketStartIndex,
            endIndex: cursor,
          });
        }

        // Move to the next segment
        startOfCurrentBuffer = cursor + 1;
      } else if (cursor === refStr.length) {
        // add whatever is left in the buffer at the end of the last chunk (and update end index)
        const segment = refStr.slice(startOfCurrentBuffer, cursor);

        if (!segment.length) break;

        const lastChunk = formulaChunks.at(-1);

        if (lastChunk) {
          lastChunk.value += segment;
          lastChunk.endIndex = cursor;
        }
        break;
      }
      if (refStr[cursor] === ":") colonIdxInSegment = cursor;
      cursor++;
    }
  } else {
    // const parts = refStr.match(refPartsRegex);
    const parseResult = matchWithNamedGroups(refStr);
    // If if doesn't match the regex for a complete and valid ref, try parsing it manually
    if (!parseResult.result) {
      // First make sure there's no invalid characters
      let cleanedStr = refStr.startsWith("[") ? refStr.slice(1) : refStr;
      cleanedStr = cleanedStr.endsWith("]") ? cleanedStr.slice(0, -1) : cleanedStr;
      const validCharactersRegex = /[^\d\w\s,-_:!]+/g;
      if (validCharactersRegex.test(cleanedStr)) {
        return [
          {
            type: "cb_ref",
            value: refStr,
            subtype: "invalid",
            startIndex: 0,
            endIndex: refStr.length - 1,
          },
        ];
      }
      // Try parsing
      const listOfPossibleParameters = ["row", "range", "department", "vendor"] as const;
      let index = 0;
      let currentPart = "";
      let currentRefParameterIndex = 0;
      let commaIndex = 0;
      while (index < refStr.length) {
        if (refStr[index] === "[") {
          formulaChunks.push({
            type: "cb_ref",
            subtype: "start",
            value: refStr[index],
            startIndex: index,
            endIndex: index,
          });
          index++;
        } else if (refStr[index] === "]") {
          // If there's a current part in the "buffer", flush it into the formulaChunks
          const currentPartLength = currentPart.length;
          if (currentPartLength) {
            formulaChunks.push({
              type: "cb_ref",
              subtype: listOfPossibleParameters[currentRefParameterIndex],
              value: currentPart,
              startIndex: index,
              endIndex: index + currentPartLength - 1,
            });
            currentPart = "";
          }
          formulaChunks.push({
            type: "cb_ref",
            subtype: "end",
            value: refStr[index],
            startIndex: index + currentPartLength,
            endIndex: index + currentPartLength,
          });
          currentRefParameterIndex++;
          index++;
        } else if (refStr[index] === ",") {
          if (currentPart.length) {
            const subtype = listOfPossibleParameters[currentRefParameterIndex];
            if (subtype === "row" && currentPart.includes("!")) {
              const [templateName, rowName] = currentPart.split("!");
              formulaChunks.push(
                {
                  type: "cb_ref",
                  subtype: "template",
                  value: templateName,
                  startIndex: index - currentPart.length,
                  endIndex: index - currentPart.length + templateName.length - 1,
                },
                {
                  type: "cb_ref",
                  subtype: "template_delimiter",
                  value: "!",
                  startIndex: index - currentPart.length + templateName.length,
                  endIndex: index - currentPart.length + templateName.length,
                },
                {
                  type: "cb_ref",
                  subtype: "row",
                  value: rowName,
                  startIndex: index - currentPart.length + templateName.length + 1,
                  endIndex: index - 1,
                },
              );
            } else {
              formulaChunks.push({
                type: "cb_ref",
                subtype: listOfPossibleParameters[currentRefParameterIndex],
                value: currentPart,
                startIndex: index - currentPart.length,
                endIndex: index - 1,
              });
            }
          }
          formulaChunks.push({
            type: "cb_ref",
            subtype: commaIndex === 0 ? "row_comma" : commaIndex === 1 ? "range_comma" : "department_comma",
            value: ",",
            startIndex: index,
            endIndex: index,
          });
          currentRefParameterIndex++;
          commaIndex++;
          index++;
          currentPart = "";
        } else if (refStr[index] === " " && !currentPart.length) {
          index++;
          // Add the space to last parts value
          const lastPart = formulaChunks.at(-1);
          if (lastPart) {
            lastPart.value += " ";
            lastPart.endIndex++;
          }
        } else {
          currentPart += refStr[index];
          index++;
        }
      }
      if (currentPart.length) {
        const parameterType = listOfPossibleParameters[commaIndex];
        if (parameterType === "range" && currentPart.includes(":")) {
          const [start, end] = currentPart.split(":");
          formulaChunks.push(
            {
              type: "cb_ref",
              subtype: "range_start",
              value: start,
              startIndex: index - currentPart.length,
              endIndex: index - currentPart.length + start.length - 1,
            },
            {
              type: "cb_ref",
              subtype: "range_delimiter",
              value: ":",
              startIndex: index - currentPart.length + start.length,
              endIndex: index - currentPart.length + start.length,
            },
          );
          if (end?.length) {
            formulaChunks.push({
              type: "cb_ref",
              subtype: "range_end",
              value: end,
              startIndex: index - currentPart.length + start.length + 1,
              endIndex: index - currentPart.length + start.length + 1 + end.length - 1,
            });
          }
        } else if (parameterType === "row" && currentPart.includes("!")) {
          const [templateName, rowName] = currentPart.split("!");
          formulaChunks.push(
            {
              type: "cb_ref",
              subtype: "template",
              value: templateName,
              startIndex: index - currentPart.length,
              endIndex: index - currentPart.length + templateName.length - 1,
            },
            {
              type: "cb_ref",
              subtype: "template_delimiter",
              value: "!",
              startIndex: index - currentPart.length + templateName.length,
              endIndex: index - currentPart.length + templateName.length,
            },
            {
              type: "cb_ref",
              subtype: "row",
              value: rowName,
              startIndex: index - currentPart.length + templateName.length + 1,
              endIndex: index - 1,
            },
          );
        } else {
          if (currentPart.length) {
            formulaChunks.push({
              type: "cb_ref",
              subtype: parameterType,
              value: currentPart,
              startIndex: index - currentPart.length,
              endIndex: index - 1,
            });
          }
        }
      }
    } else {
      const groups = parseResult.result.groups ?? {};
      for (const [i, group] of Object.keys(groups).entries()) {
        const value = groups[group];
        const indexes = parseResult.indexes[i];
        if (!value || !indexes) continue;
        formulaChunks.push({
          type: "cb_ref",
          value,
          subtype: group,
          startIndex: indexes[0],
          endIndex: indexes[1],
        });
      }
    }
  }

  // If refStartIdx is not 0, adjust the indexes of the chunks
  if (refStartIdx) {
    for (const chunk of formulaChunks) {
      chunk.startIndex += refStartIdx;
      chunk.endIndex += refStartIdx;
    }
  }

  return formulaChunks;
};
