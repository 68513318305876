import {isBrowser, isNode, isWebWorker} from "browser-or-node";

import type {AppActionMeta} from "@state/store";

const noop = async (...args: any[]) => undefined as void;

export const dispatchInMainThread = async (
  ...args: Parameters<typeof import("../client/app/worker/worker-thread/state")["dispatchInMainThread"]>
) => {
  if (isNode) return noop();
  if (!isWebWorker) throw new Error("This function can only be called from the worker thread");
  const importedModule = await getImport<typeof import("../client/app/worker/worker-thread/state")>(
    "dispatchInMainThread",
  );
  const action = {...args[0], meta: {_isThreadSync: true} as AppActionMeta};
  return importedModule.dispatchInMainThread(action);
};

export const getClientSocket = async () => {
  if (!isBrowser) throw new Error("This function can only be called from the browser main thread");
  return (await getImport<typeof import("../client/app/websockets/websockets")>("socket")).socket;
};

export const getWorkerStore = async () => {
  if (isNode) throw new Error("This function cannot be called from the backend");
  const {store} = await import("../client/app/worker/worker-thread/worker-store.js");
  return store;
};

const supportedImports = ["dispatchInMainThread", "socket", "compute", "worker-store"] as const;
type SupportedImports = (typeof supportedImports)[number];

const importCache: Record<string, any> = {};
async function getImport<T>(importRequestesd: SupportedImports): Promise<T> {
  let prefix = "";
  if (isNode) prefix = import.meta.url.split("/").slice(0, -1).join("/").replace("file://", "");
  switch (importRequestesd) {
    case "dispatchInMainThread":
      importCache[importRequestesd] ||= await import("../client/app/worker/worker-thread/state.js");
      break;
    case "socket":
      importCache[importRequestesd] ||= await import("../client/app/websockets/websockets.js");
      break;
    case "worker-store":
      importCache[importRequestesd] ||= await import("../client/app/worker/worker-thread/worker-store.js");
      break;
    case "compute":
      importCache[importRequestesd] ||= isNode
        ? await import(/* @vite-ignore */ `${prefix}/data-functions/formula/compute`)
        : await import("../shared/data-functions/formula/compute.js");
      break;
  }
  return importCache[importRequestesd];
}
