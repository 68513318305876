import {store} from "@app/client-store";
import {getDatesFromTimePeriod} from "@features/templates/TemplateOptions/DatePickerTab";
import id from "@shared/lib/id";
import {getLayoutForNewReportItem} from "@shared/lib/report-utilities";
import {reportItemAdded} from "@shared/state/report-items/slice";

import {setSelectedReportItemId, updateReportingState} from "./slice";

import type {MenuElement} from "@components/Menu";
import type {NewMenuElementsProvider, TopBarMenuElement} from "@features/shared-views/Navigation/NotificationsArea";
import type {ReportItem} from "@shared/types/reporting";

export function getNewMenuElements(): ReturnType<NewMenuElementsProvider> {
  const onChange = (element: MenuElement) => {
    const state = store.getState();
    const newId = id();
    const layout = getLayoutForNewReportItem(state.reportItems.entities as Record<string, ReportItem>, newId);
    const report_id = state.reporting.selectedReportId;

    if (!report_id) return;

    if (element.text === "Chart") {
      store.dispatch(
        reportItemAdded({
          id: newId,
          type: "chart",
          name: "New Chart",
          report_id,
          layout,
          data: {
            type: "line",
            lines: [],
            timePeriod: "last_month",
            boundsType: "dynamic",
            upperBound: null,
            lowerBound: null,
            numberFormat: "auto",
            yAxisInterval: null,
            prefix: null,
            suffix: null,
            applyPrefixToFirstOnly: false,
            applySuffixToFirstOnly: false,
            timePeriodFormat: "MMM YYYY",
            xAxisInterval: "months",
            ...getDatesFromTimePeriod("last_month", null), // TODO: adapt to have a dynamic and a static version of this function
          },
        }),
      );
      store.dispatch(setSelectedReportItemId(newId));
    }
    if (element.text === "Table (Time Series)") {
      store.dispatch(
        reportItemAdded({
          id: newId,
          type: "time_series_table",
          name: "",
          report_id,
          layout,
          data: {
            lines: [],
            subtitle: "",
            timePeriod: "last_month",
            timePeriodFormat: "MMM YYYY",
            ...getDatesFromTimePeriod("last_month", null),
          },
        }),
      );
      store.dispatch(setSelectedReportItemId(newId));
    }
    if (element.text === "Table (Custom Columns)") {
      store.dispatch(
        reportItemAdded({
          id: newId,
          type: "custom_table",
          name: "",
          report_id,
          layout,
          data: {
            lines: [],
            columns: [],
            subtitle: "",
          },
        }),
      );
      store.dispatch(setSelectedReportItemId(newId));
    }
    if (element.text === "Title / Subtitle") {
      store.dispatch(
        reportItemAdded({
          id: newId,
          type: "header",
          name: "",
          report_id,
          layout: {
            ...layout,
            h: 2,
          },
          data: {
            subtitle: "",
          },
        }),
      );
      store.dispatch(setSelectedReportItemId(newId));
    }
    if (element.text === "Text Box") {
      store.dispatch(
        reportItemAdded({
          id: newId,
          type: "textbox",
          name: "",
          report_id,
          layout,
          data: {
            text: "",
          },
        }),
      );
      store.dispatch(setSelectedReportItemId(newId));
    }

    if (element.text === "Report") {
      store.dispatch(updateReportingState({reportModalStatus: "create"}));
    }
  };

  return {
    onChange,
    width: 225,
    elements: [
      {type: "header", text: "Insert Elements"},
      {
        type: "item",
        text: "Chart",
        icon: "chart",
        // content: [
        //   {
        //     type: "item",
        //     text: "Bar Chart",
        //   },
        //   {
        //     type: "item",
        //     text: "Bar Chart - Stacked",
        //   },
        // ],
      },
      {
        type: "item",
        text: "Table (Time Series)",
        icon: "table",
      },
      {
        type: "item",
        text: "Table (Custom Columns)",
        icon: "table",
      },
      {
        type: "item",
        text: "Title / Subtitle",
        icon: "title",
      },
      {
        type: "item",
        text: "Text Box",
        icon: "textbox",
      },
      {type: "header", text: "Insert New Report"},
      {
        type: "item",
        text: "Report",
        icon: "chart",
      },
    ],
  };
}

export function getSettingsItems(): TopBarMenuElement[] {
  const elements: TopBarMenuElement[] = [
    {
      text: "Edit Report",
      type: "item",
      icon: "chart",
      onClick: () => {
        store.dispatch(updateReportingState({reportModalStatus: "edit"}));
      },
    },
  ];

  return elements;
}

export default {
  getNewMenuElements,
  getSettingsItems,
};
