import colors from "@app/styles/colors";
import {getBorderAsBoxShadow} from "@app/styles/styles-utilities";
import {getSvgColorStyles} from "@components/SVGIcon";
import color from "color";
import {createUseStyles} from "react-jss";

export const sharedValues = {
  minCellWidth: 68,
  maxCellWidth: 100,
};

export default createUseStyles({
  rowMain: {
    lineHeight: 24,
    marginLeft: -24,
    marginRight: navigator.platform.includes("Win") ? `-${24 + 16}px` : -24,
    paddingRight: 24,
    fontWeight: 500,

    // Add highlight to row when hovering over it or the sidebar is open for that row, unless it's being moved or the cell is highlighted
    "&:hover, &$sidebarOpen": {
      "&:not($isBeingMoved):not($checksFailing) $cell:not($highlighted)": {
        backgroundColor: colors.secondary.lightGreen08,
      },
    },
    "&:hover $blueRowsExpandIcon": {
      display: "flex",
    },
  },
  draggingOver: {},
  draggingLeft: {},
  draggingRight: {},
  highlightingLeft: {},
  highlightingRight: {},
  notClickable: {},
  sidebarOpen: {},
  blueRowsExpandIcon: {
    display: "none",
    ...getSvgColorStyles(colors.tertiary.blue),
  },
  blueRowsExpanded: {
    display: "flex",
  },
  isBeingMoved: {
    boxShadow: " 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
    pointerEvents: "none",

    "& td": {
      borderTop: [1, "solid", colors.ui.black],
      borderBottom: [1, "solid", colors.ui.black],
      backgroundColor: colors.tertiary.lightBlue06,

      "&$rowName > div": {
        marginLeft: -1,
      },

      "& > div": {
        marginTop: -2,
        marginRight: 1,
      },
      "&:first-child": {
        borderLeft: [1, "solid", colors.ui.black],
        backgroundColor: colors.tertiary.lightBlue06 + " !important",
      },
      "&:last-child": {
        borderRight: [1, "solid", colors.ui.black],
      },
    },
  },
  collapsed: {
    "& $collapseIcon": {
      transform: "rotate(-90deg)",
    },
  },
  collapseIcon: {},
  highlighted: {
    "& $cell > div": {
      padding: [7, 9],
      borderLeft: 0,
      borderRight: 0,
      borderTop: [1, "solid", colors.secondary.lightGreen01],
      borderBottom: [1, "solid", colors.secondary.lightGreen01],
      backgroundColor: color(colors.secondary.lightGreen).alpha(0.5).rgb().string(),
      borderRadius: 0,
    },
    "& $cell:last-child > div": {
      borderRight: [1, "solid", colors.secondary.lightGreen01],
      paddingRight: 8,
    },
  },
  parentCollapsed: {
    display: "none",
  },
  bold: {
    fontWeight: 700,
  },
  borderTop: {
    "& > $cell > div:first-child": {
      boxShadow: getBorderAsBoxShadow("top", 1, "rgba(115, 114, 163, 0.2)"),
    },
  },
  borderBottom: {
    "& $cell": {
      boxShadow: getBorderAsBoxShadow("bottom", 1, "rgba(115, 114, 163, 0.2)"),
    },
  },
  italic: {
    fontStyle: "italic",
  },

  settingsIcon: {
    marginLeft: 4,
    opacity: 0,
    pointerEvents: "none",

    "$rowMain:hover &": {
      opacity: 1,
      pointerEvents: "auto",
    },
  },

  dragIconContainer: {
    padding: 8,
    opacity: 0,
    // pointerEvents: "none",
    cursor: "move",
    position: "absolute",
    left: -27,
    display: "flex",
    alignItems: "center",

    "$rowMain:hover &, $isBeingMoved &": {
      opacity: 1,
      pointerEvents: "auto",
    },

    "$rowMain:hover &:hover": {
      ...getSvgColorStyles(colors.ui.black),
    },
  },

  // draggingOver: {},

  dragIcon: {},

  textInput: {
    "&$textInput": {
      border: 0,
      color: colors.text.black,
      fontSize: 12,
      flex: 1,

      "& > *": {fontSize: 12},

      "&:focus, &:focus-within": {
        boxShadow: "none!important",
      },

      "& input": {
        padding: [0, 9],
        border: 0,
        boxShadow: "none!important",
        background: "transparent",
        width: "100%",
        color: colors.text.black,
        fontSize: 12,

        "&:focus, &:focus-within": {
          boxShadow: "none!important",
        },
      },
    },
  },

  dragging: {
    "& *": {
      pointerEvents: "none",
    },
  },

  isBeingEdited: {},
  checksFailing: {},
  checksRow: {},
  name: {
    height: "calc(100% - 2px)",
    flex: 1,
    display: "flex",
    alignItems: "center",
    paddingTop: 1,
    paddingLeft: 18,
    position: "relative",
    textOverflow: "ellipsis",

    "$blueRow &": {
      backgroundColor: colors.tertiary.lightBlue07,
    },

    "$checksFailing &": {
      backgroundColor: colors.reds.lightRed04,
    },

    "$checksRow$checksFailing &": {
      backgroundColor: colors.reds.lightRed05,
    },

    "$isBeingEdited &": {
      paddingLeft: 7,
      paddingTop: 2,
    },

    "& > span": {
      flex: 1,
    },

    "& svg": {
      transition: "transform 100ms ease-out",
      transform: "rotate(0deg)",

      "& path": {
        fill: colors.text.lightBlue01,
      },
    },
  },
  rowNameTextWrapper: {
    display: "inline-flex",
    alignItems: "center",

    "& > span:first-child:not(:last-child):not($sanityCheckStatus):not($rowNameText)": {
      marginRight: 5,
      display: "inline-flex",
      alignItems: "center",
      marginTop: -2,
      minWidth: 16,
      justifyContent: "center",
      ...getSvgColorStyles(colors.tertiary.blue),
    },
  },
  rowNameText: {},
  sanityCheckStatus: {
    "& svg": {
      maxHeight: 15,
      maxWidth: 15,
    },
  },
  sanityChecksFail: {
    ...getSvgColorStyles(colors.reds.red),
  },
  sanityChecksPass: {
    ...getSvgColorStyles(colors.other.darkGreen02),
  },
  beforeName: {
    marginRight: 9,
  },
  afterName: {
    marginLeft: 9,
    marginBottom: -2,
  },
  rightButtons: {
    display: "inline-flex",
  },
  iconWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    paddingLeft: 8,
    left: -34,
    top: 0,
    cursor: "pointer",
    userSelect: "none",
  },
  blueRow: {},
  deeperBlueRow: {},
  lastHighlighted: {},
  firstHighlighted: {},
  lastDraggedOver: {},
  firstDraggedOver: {},
  errored: {},
  firstErrored: {},
  lastErrored: {},
  cell: {
    minWidth: sharedValues.minCellWidth,
    maxWidth: sharedValues.maxCellWidth,

    "$blueRow &": {
      backgroundColor: colors.tertiary.lightBlue07,
    },

    "$checksFailing &": {
      backgroundColor: colors.reds.lightRed04,
    },

    "$checksRow$checksFailing &": {
      backgroundColor: colors.reds.lightRed05,
    },

    "$deeperBlueRow &": {
      backgroundColor: colors.tertiary.lightBlue06,
    },

    "&$highlighted > div": {
      borderTop: [1, "solid", colors.secondary.lightGreen01],
      borderBottom: [1, "solid", colors.secondary.lightGreen01],
      backgroundColor: colors.secondary.green50Opacity,
      borderRadius: 0,
      padding: [7, 9],

      borderLeft: 0,
      borderRight: 0,
    },

    "&$highlighted$firstHighlighted$highlightingLeft > div": {
      borderLeft: [1, "solid", colors.secondary.lightGreen01],
      paddingLeft: 8,
    },

    "&$highlighted$lastHighlighted$highlightingLeft > div": {
      borderRight: [1, "solid", colors.secondary.lightGreen01],
      paddingRight: 8,
    },

    "&$highlighted$firstHighlighted$highlightingRight > div": {
      borderLeft: [1, "solid", colors.secondary.lightGreen01],
      paddingLeft: 8,
    },

    "&$highlighted$lastHighlighted$highlightingRight > div": {
      borderRight: [1, "solid", colors.secondary.lightGreen01],
      paddingRight: 8,
    },

    "&$draggingOver > div": {
      borderTop: [1, "dashed", colors.secondary.lightGreen01],
      borderBottom: [1, "dashed", colors.secondary.lightGreen01],
      borderRadius: 0,
      padding: [7, 9],

      borderLeft: 0,
      borderRight: 0,
    },

    "&$draggingOver$lastDraggedOver$draggingRight > div": {
      borderRight: [1, "dashed", colors.secondary.lightGreen01],
      paddingRight: 8,
    },

    "&$draggingOver$firstDraggedOver$draggingLeft > div": {
      borderLeft: [1, "dashed", colors.secondary.lightGreen01],
      paddingLeft: 8,
    },

    "&$errored": {
      "& > div": {
        borderTop: [1, "solid", colors.reds.red],
        borderBottom: [1, "solid", colors.reds.red],
        borderRadius: 0,
        backgroundColor: colors.reds.lightRed05,
        padding: [7, 9],

        borderLeft: 0,
        borderRight: 0,
      },

      "&$active > div": {
        border: [1, "solid", colors.secondary.green],
        backgroundColor: colors.ui.white,
      },
    },

    "&$errored$lastErrored > div": {
      borderRight: [1, "solid", colors.reds.red],
      paddingRight: 8,
    },

    "&$errored$firstErrored > div": {
      borderLeft: [1, "solid", colors.reds.red],
      paddingLeft: 8,
    },

    "&$errored$firstErrored:not($active) > div": {
      position: "relative",

      "&::before": {
        content: '""',
        position: "absolute",
        top: -7,
        left: -7,
        width: 0,
        height: 0,
        border: [7, "solid"],
        borderColor: `transparent ${colors.reds.red} transparent transparent`,
        transform: "rotate(45deg)",
      },
    },

    "$dragging &": {
      cursor: "crosshair",
      userSelect: "none",
    },
  },

  firstMonthOfForecast: {
    position: "relative",
  },

  fakeBorder: {
    position: "absolute",
    width: 1,
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: color(colors.tertiary.blue01).alpha(0.2).rgb().string(),
    zIndex: 1,
  },

  indentationLevel: {
    marginLeft: 2,
    paddingLeft: 27,
    height: "100%",
    borderLeft: `1px dashed ${colors.ui.lightBlue04}`,
  },

  cellWidthProvider: {
    position: "absolute",
    height: 1,
    left: 0,
    right: 0,
    bottom: 0,
  },

  dragHandleContainer: {
    cursor: "crosshair",
    position: "absolute",
    bottom: -8,
    right: -8,
    width: 16,
    height: 16,
    zIndex: 1,
  },
  draggable: {},
  active: {},
  dragHandle: {
    "$draggable &": {
      position: "absolute",
      bottom: 5,
      right: 5,
      width: 6,
      height: 6,
      background: colors.secondary.green,
    },
  },
  mouseMoveListener: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 5,
    //background: "rgba(255, 0, 0, 0.2)",
  },
  vendorRow: {},
  rowName: {
    width: 400,
    maxWidth: 400,
    minWidth: 400,
    paddingLeft: 0,

    "&$active$active": {
      zIndex: 3,
    },

    "&$vendorRow": {
      width: 550,
      maxWidth: 550,
      minWidth: 550,
    },

    "& > div": {
      padding: [0, 0],
      marginLeft: 30,
      paddingLeft: 10,
      position: "relative",
      overflow: "visible",

      "& > span": {
        flex: 1,
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
      },
    },

    "& svg": {
      transition: "transform 100ms ease-out",
      transform: "rotate(0deg)",

      "& path:not(.no-color)": {
        fill: colors.text.lightBlue01,
      },
    },
  },

  checkmarkContainer: {
    position: "absolute",
    right: -3,
    top: 0,
    height: 15,

    "& svg path": {
      fill: colors.secondary.green,
      stroke: colors.secondary.green,
    },
  },
});
