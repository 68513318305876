import {useSelectedEmployeeDetails} from "../../hooks";
import CompensationAndTaxesShared from "../shared/CompensationAndTaxes";

export default function CompensationAndTaxes() {
  const employeeDetails = useSelectedEmployeeDetails();

  if (!employeeDetails?.entity) return null;

  return <CompensationAndTaxesShared details={employeeDetails} />;
}
