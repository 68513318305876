import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {intersect} from "@shared/lib/misc";
import {isBalance} from "@shared/lib/row-utilities";
import {projKeyOmitNulls} from "@state/utils";
import memoize from "fast-memoize";
// @ts-ignore
import {Parser} from "hot-formula-parser";

import {resolveDaterange} from "./date-range";
import daterangeShortcuts from "./daterange-shortcuts";

import type {DepartmentsState, TemplateRowsState} from "@shared/state/entity-adapters";
import type {FormulaReference} from "@shared/types/datasources";

export const NO_DEPARTMENT_NAME = "no_department";

type ResolveFormulaAndRefsParams = {
  readonly lastMonthOfActuals: string;
  readonly dateKey: string;
  readonly templateRowsState: TemplateRowsState;
  readonly departmentsState: DepartmentsState;
  readonly formula: string;
  readonly monthlyCache: Record<string, number>;
  readonly scenarioId: string;
  readonly references: FormulaReference[];
};

export function resolveFormulaAndRefs({
  lastMonthOfActuals,
  dateKey,
  templateRowsState,
  departmentsState,
  formula,
  monthlyCache,
  references,
  scenarioId,
}: ResolveFormulaAndRefsParams) {
  const parserVariables: Record<string, number | string | number[] | string[]> = {};
  let resolvedFormula: null | string = null;
  const formulaRefs: FormulaReference[] = [];

  resolvedFormula = formula.replace("=", "").replace("&nbsp;", "").trim();

  // Reset the regex state
  let numberOfReferences = 0;
  for (const ref of references) {
    try {
      formulaRefs.push(ref);
      const resolvedValues = getValuesForRef(
        ref,
        dateKey,
        lastMonthOfActuals,
        templateRowsState,
        departmentsState,
        monthlyCache,
        scenarioId,
      );

      const varLetter = String.fromCharCode(97 + numberOfReferences++); // 97 is "a"
      resolvedFormula = (resolvedFormula || "").replace(ref.refStr, varLetter);
      parserVariables[varLetter] = resolvedValues.length > 1 ? resolvedValues : resolvedValues[0];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      resolvedFormula = null;
    }
  }

  return {parserVariables, formulaRefs, resolvedFormula};
}

function getValuesForRef(
  ref: FormulaReference,
  dateKey: string,
  lastMonthOfActuals: string,
  templateRowsState: TemplateRowsState,
  departmentsState: DepartmentsState,
  monthlyCache: Record<string, number>,
  scenarioId: string,
): number[] {
  let values: number[] = [];
  const resolvedDaterange = !ref.from?.length
    ? {start: dateKey, end: dateKey}
    : resolveDaterange(ref.from, ref.to, dateKey, lastMonthOfActuals);

  let matchingRowIds: string[] = [];
  if (ref.row) {
    const rowSlug = ref.row.split("!").at(-1)!.toLowerCase();
    // If the row is specified, use that
    matchingRowIds = [templateRowsState.idsByName[rowSlug] ?? "NOT_FOUND"];
  } else if (ref.tags) {
    // Otherwise it's a combination of tags
    const arraysToIntersect: string[][] = [];
    for (const [key, value] of Object.entries(ref.tags)) {
      arraysToIntersect.push(templateRowsState.idsByTag[`${key}::${value}`] ?? []);
    }
    matchingRowIds = intersect(arraysToIntersect);
  }

  let departmentId: string | null = null;
  if (ref.department) {
    departmentId = departmentsState.idsByName[ref.department === "none" ? NO_DEPARTMENT_NAME : ref.department] ?? null;
    if (!departmentId) {
      console.error(`Department "${ref.department}" not found (formula ref: ${ref.refStr})`);
      return [];
    }
  }

  for (const matchingRowId of matchingRowIds) {
    let matchingRow = templateRowsState.entities[matchingRowId ?? ""];
    const isBalanceRow = isBalance(matchingRow);

    if (matchingRow?.mirror_of) {
      while (matchingRow?.mirror_of) {
        matchingRow = templateRowsState.entities[matchingRow.mirror_of];
      }
    }

    if (!matchingRow) {
      console.error(`Row id ${matchingRowId} for reference ${ref.row} does not exist`);
      values = [];
      break;
    }

    const monthlyValues: number[] = [];
    const dateKeysInRange = getAllDateKeysBetween(resolvedDaterange.start, resolvedDaterange.end);
    for (const dateKey of dateKeysInRange) {
      let lookupKey = projKeyOmitNulls(matchingRow.id, departmentId, ref.vendor, scenarioId, dateKey);

      // By default, if we reference a balance row, we use the balance value and not the actual monthly value.
      // We might want to make this behavior configurable
      if (isBalanceRow) lookupKey += "::balance";
      let finalLookupKey = lookupKey;
      const forceOwnValue = ref.total?.toLowerCase() === "false";
      if (
        !forceOwnValue &&
        (!!templateRowsState.idsByParentRowId[matchingRow.id]?.length ||
          (departmentId && departmentsState.idsByParent[departmentId]))
      ) {
        finalLookupKey += "::total";
      }

      const value = monthlyCache[finalLookupKey] ?? 0;
      monthlyValues.push(value);
    }
    values.push(...monthlyValues);
  }

  return values;
}

export const refRegex =
  /((?:(?:REF\()|\[)((?:\w*[a-zA-Z_\-]\w*(?: *,* *)?)(?:\w*(?:[A-Za-z0-9_ ,\-:&!]+)\w*(?: *,* *)?){1,2})(?:\]|\)))/gi;

// This is the fastest for valid formulas, but slows down to a crawl for invalid formulas, increasing with the length of the invalid reference
export function getRefsFromFormulaRegex(formula: string, rowName: string) {
  const formulaRefs: FormulaReference[] = [];

  let matchResult;

  // Reset the regex state
  refRegex.lastIndex = 0;
  while ((matchResult = refRegex.exec(formula)) !== null) {
    const ref = getRefFromRefStr(matchResult[2], rowName);
    formulaRefs.push(ref);
  }

  return formulaRefs;
}

/**
 * Formula examples:
 *
 * [self, this_month]
 * SUM([self, -3a:-1a])
 * [self, -1]+[revenue, this_month]
 * [self, -1]+[profit_and_loss!revenue]
 * [self, -1]+[revenue]
 * AVERAGE([transportation_mileage, -6a:-1a])
 * [self,-1]*(1+[raises])
 * SUM([profit_and_loss!payroll_product,-2:0])/SUM([profit_and_loss!revenue,-2:0])
 *
 *
 * AVERAGE([hosting, -3a:-1a, service_delivery, 16])/AVERAGE([revenue, -3a:-1a])*[revenue, this_month]
 * AVERAGE([hosting, -3a:-1a, service_delivery, 16])/AVERAGE([profit_and_loss!revenue, -3a:-1a])*[revenue, this_month]
 *
 * SUM([compensation_type: salary, from: this_month, to: this_month, team: customer_support_contractors])
 * AVERAGE([team: customer_support_contractors])
 * COUNTIFS([compensation_type: salary, team: customer_support], ">0")
 * [payroll_metrics!2022]+[payroll_metrics!2023]+[payroll_metrics!2024]+[payroll_metrics!2025]+[payroll_metrics!2026]
 *
 */

// This is slightly faster than the manual loop method below
export function getRefsFromFormulaUsingArrayMethods(formula: string, rowName: string) {
  const formulaRefs: FormulaReference[] = [];

  const groups = formula
    .split("[")
    .slice(1)
    .map((unclosedGroup) => {
      const splitUnclosedGroup = unclosedGroup.split("]");
      return splitUnclosedGroup.length === 1 ? "" : splitUnclosedGroup[0];
    });

  for (const group of groups) {
    if (!group?.length) continue;
    const ref = getRefFromRefStr(group, rowName);

    formulaRefs.push(ref);
  }

  return formulaRefs;
}

export function getRefsFromFormula(formula: string, rowName: string) {
  const refStrs: string[] = getRefStrsFromFormula(formula, false, false);

  const formulaRefs = refStrs.map((refStr) => getRefFromRefStr(refStr, rowName));

  return formulaRefs;
}

export const getRefStrsFromFormula = memoize((formula: string, includeUnfinished: boolean, withBrackets: boolean) => {
  let inRef = false;
  let currentGroup = "";
  const refStrs: string[] = [];
  for (const char of formula) {
    if (!inRef && char === "[") {
      inRef = true;
    } else if (inRef && char === "]" && currentGroup !== null) {
      refStrs.push(withBrackets ? `[${currentGroup}]` : currentGroup);
      inRef = false;
      currentGroup = "";
    } else if (inRef) {
      currentGroup += char;
    }
  }

  if (includeUnfinished && currentGroup.length) refStrs.push(withBrackets ? `[${currentGroup}` : currentGroup);

  return refStrs;
});

export function getRefFromRefStr(refStr: string, rowName: string): FormulaReference {
  const ref: FormulaReference = {
    from: "",
    to: "",
    refStr: `[${refStr}]`,
  };

  const refType = getFormulaRefType(refStr);
  if (refType === "advanced") {
    return parseAdvancedRef(ref, refStr, rowName);
  } else {
    return parseRegularRef(ref, refStr, rowName);
  }
}

export function getFormulaRefType(refStr: string) {
  const refComponents = refStr.split(",");
  if (refComponents[0]?.includes(":")) {
    return "advanced";
  } else {
    return "regular";
  }
}

function parseRegularRef(ref: FormulaReference, refStr: string, rowName: string) {
  const [refStrWithTemplate, dateRange, department, vendor] = refStr.replace(/ |\\n/g, "").split(",");

  let dateRangeStr = daterangeShortcuts[dateRange]?.range ?? dateRange ?? "this_month";

  if (!dateRangeStr.includes(":")) dateRangeStr += `:${dateRangeStr}`;
  const [from, to] = dateRangeStr.split(":");
  ref.from = from;
  ref.to = to;

  if (department) ref.department = department;
  if (vendor) ref.vendor = vendor;

  const splitFirstItem = refStrWithTemplate.split("!");

  let cleanedRowName = "";
  if (splitFirstItem.length === 2) {
    ref.template = splitFirstItem[0];
    cleanedRowName = splitFirstItem[1];
  } else {
    cleanedRowName = splitFirstItem[0];
  }

  ref.row = cleanedRowName.toLowerCase() === "self" ? rowName : cleanedRowName;

  return ref;
}

const refOptions = {
  department: true,
  vendor: true,
  from: true,
  to: true,
  template: true,
  row: true,
  total: true,
} as const;

const refOptionsList = ["department", "vendor", "from", "to", "template", "row", "total"] as const;

function parseAdvancedRef(ref: FormulaReference, refStr: string, rowName: string) {
  const optionPairs = refStr.split(",");
  const options: Record<string, string> = {};
  const tags: Record<string, string> = {};
  let hasTags = false;
  for (const optionPair of optionPairs) {
    const [key, value] = optionPair.trim().split(":");
    if (key in refOptions) {
      let cleanedValue = value.trim();
      if (key === "row" && value === "self") {
        cleanedValue = rowName;
      }
      options[key] = cleanedValue;
      if (key === "vendor") options[key] = options[key].toLowerCase();
    } else {
      hasTags = true;
      tags[key] = value?.trim();
    }
  }

  for (const key of refOptionsList) {
    if (key in options) ref[key] = options[key];
  }

  if (hasTags) ref.tags = tags;

  if (!ref.from) ref.from = "this_month";
  if (!ref.to) ref.to = "this_month";

  return ref;
}

export function validateFormulaGeneralSyntax(formula: string): boolean {
  let singleQuoteOpen = false;
  let doubleQuoteOpen = false;
  let bracketOpen = false;
  let parenthesisOpen = false;
  let termStart = true; // To identify the start of a term within brackets
  let expectValue = false; // To check if a value is expected after a key:value pair
  let isFirstTerm = true; // To enforce the exclamation point rule on the first term

  // Helper function to reset term-related flags
  const resetTermFlags = () => {
    termStart = true;
    expectValue = false;
    isFirstTerm = false;
  };

  if (formula.startsWith("=")) {
    formula = formula.slice(1);
  }

  for (const char of formula) {
    // Handling quote toggle
    if (char === '"' && !singleQuoteOpen) doubleQuoteOpen = !doubleQuoteOpen;
    else if (char === "'" && !doubleQuoteOpen) singleQuoteOpen = !doubleQuoteOpen;

    if (!singleQuoteOpen && !doubleQuoteOpen) {
      // Ensure we're not inside quotes
      switch (char) {
        case "[":
          if (parenthesisOpen) return false; // Invalid if parentheses are open before bracket
          bracketOpen = true;
          resetTermFlags();
          break;
        case "]":
          if (expectValue) return false; // Expecting a value before closing bracket
          bracketOpen = false;
          isFirstTerm = true; // Reset for potential next bracket group
          break;
        case "(":
          if (bracketOpen) return false; // Cannot open parentheses inside brackets
          parenthesisOpen = true;
          break;
        case ")":
          parenthesisOpen = false;
          break;
        case ",":
          if (bracketOpen) {
            if (expectValue || termStart) return false; // Comma shouldn't appear before a value or if no term has started
            resetTermFlags();
          }
          break;
        case ":":
          if (bracketOpen && termStart && !expectValue) {
            expectValue = true; // Expecting a value after colon
          } else {
            return false; // Colon not allowed outside of key:value in brackets
          }
          break;
        case " ":
          // Ignore spaces for validation purposes, but reset term start if expecting a value
          if (expectValue) termStart = false;
          break;
        default:
          // If the character is not a special character, handle term logic
          if (bracketOpen) {
            if (termStart && char === "!" && !isFirstTerm) return false; // Exclamation only allowed in the first term
            termStart = false; // No longer at the start of a term
            if (expectValue) expectValue = false; // A value has been provided
          }
          break;
      }
    }

    // Early exit if counts go negative, indicating invalid structure
    if ((bracketOpen && !parenthesisOpen && char === ")") || (!bracketOpen && parenthesisOpen && char === "]")) {
      return false;
    }
  }

  // Ensure all structures are properly closed and no value is expected at the end
  return !singleQuoteOpen && !doubleQuoteOpen && !bracketOpen && !parenthesisOpen && !expectValue;
}

export function validateFormulaThroughParser(formula: string, refStrs: string[]) {
  const parserVariables: Record<string, number | string | number[] | string[]> = {};
  let resolvedFormula: null | string = null;

  resolvedFormula = formula?.replace("=", "").replace("&nbsp;", "").trim() ?? "";

  // Reset the regex state
  let numberOfReferences = 0;
  for (const refStr of refStrs) {
    try {
      const resolvedValues = [1];

      const varLetter = String.fromCharCode(97 + numberOfReferences++); // 97 is "a"
      resolvedFormula = (resolvedFormula || "").replace(`[${refStr}]`, varLetter);
      parserVariables[varLetter] = resolvedValues.length > 1 ? resolvedValues : resolvedValues[0];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      resolvedFormula = null;
    }
  }

  const parser = getParser();

  for (const [variable, value] of Object.entries(parserVariables)) {
    parser.setVariable(variable, value);
  }

  let valid = true;
  const finalFormula = resolvedFormula?.replaceAll(arrayOnlyFnsRegex, "CB$1CB$2");
  // console.log({formula, finalFormula, parserVariables});
  try {
    const {error} = parser.parse(finalFormula);
    // console.log({error});
    if (error) valid = false;
  } catch (e: any) {
    // console.log("Caught error", e);
    valid = false;
  }

  return valid;
}

export const FNS_THAT_ONLY_ACCEPT_ARRAYS = [
  "COUNTIF",
  "COUNTIFS",
  "COUNTUNIQUE",
  "SUMIF",
  "SUMIFS",
  "AVERAGEIF",
  "AVERAGEIFS",
  "SUMPRODUCT",
  "MAX",
  "MIN",
  "MEDIAN",
  "PERCENTILE",
  "PERCENTILEEXC",
  "PERCENTILEINC",
  "PERCENTRANKEXC",
  "PERCENTRANKINC",
  "AVEDEV",
];

export const arrayOnlyFnsRegex = new RegExp(`(${FNS_THAT_ONLY_ACCEPT_ARRAYS.join("|")})(\\()`, "gi");

export function getParser() {
  const parser = new Parser();
  parser.setFunction("PERCENTILE", (params: (string | number)[]) => parser._callFunction("PERCENTILEINC", params));
  for (const fnName of FNS_THAT_ONLY_ACCEPT_ARRAYS) {
    parser.setFunction(`CB${fnName}CB`, (params: (string | number)[][]) => {
      const modifiedParams = [...params];
      if (!Array.isArray(modifiedParams[0])) modifiedParams[0] = [modifiedParams[0]];
      return parser._callFunction(fnName, modifiedParams);
    });
  }
  return parser;
}

export type RegExpGroups<T extends string> =
  | (RegExpMatchArray & {
      groups?: {[name in T]: string | undefined} | {[key: string]: string | undefined};
    })
  | null;

export const refPartsRegex =
  /(\[)([A-Za-z0-9&:!_-]+)( *, *)?([A-Za-z0-9-_]+)?( *: *)?([A-Za-z0-9-_]+)?( *, *)?([A-Za-z0-9_]+)?( *, *)?([A-Za-z0-9_]+)?(\])/i;

export const refPartsWithNamedGroupsRegex =
  /(?<start>\[)(?:(?<template>[\w\d_-]+)(?<template_delimiter>!))?(?<row>[\w\d&:_-]+)(?<row_comma>\s*,\s*)?(?<range_start>[\w\d_-]+)?(?<range_delimiter>\s*:\s*)?(?<range_end>[\w\d_-]+)?(?<range_comma>\s*,\s*)?(?<department>[\w\d_-]+)?(?<department_comma>\s*,\s*)?(?<vendor>[\w\d_-]+)?(?<end>\])/i;

type GroupNames =
  | "start"
  | "template"
  | "template_delimiter"
  | "row"
  | "row_comma"
  | "range_start"
  | "range_delimiter"
  | "range_end"
  | "range_comma"
  | "department"
  | "department_comma"
  | "vendor"
  | "end";

export const matchWithNamedGroups = (
  str: string,
): {result: RegExpGroups<GroupNames>; indexes: ([number, number] | null)[]} => {
  const startEndIndexes: ([number, number] | null)[] = [];
  const result = str.match(refPartsWithNamedGroupsRegex);

  if (!result) return {result: null, indexes: []};

  let cursor = result.index ?? 0;

  for (const captureGroup of result.slice(1)) {
    startEndIndexes.push(captureGroup ? [cursor, cursor + captureGroup.length - 1] : null);
    cursor += captureGroup?.length ?? 0;
  }

  return {result, indexes: startEndIndexes};
};

export type FormulaPartRegexGroups = {
  start: string;
  template: string | null;
  template_delimiter: string | null;
  row: string;
  row_comma: string | null;
  range_start: string | null;
  range_delimiter: string | null;
  range_end: string | null;
  range_comma: string | null;
  department: string | null;
  department_comma: string | null;
  vendor: string | null;
  end: string;
};

/**
 * Given a formula, iterate through it and attempt to close any brackets or parentheses that are left open in the order they were opened.
 * @param formula The formula to attempt to close brackets and parentheses in
 * @returns The formula with any open brackets or parentheses closed
 */
export function attemptToCloseBracketsAndParentheses(formula: string): string {
  // If the formula ends with a comma and an optional space, remove those
  formula = formula.replace(/,\s*$/, "");

  const stack: string[] = [];
  const openToCloseMap: {[key: string]: string} = {
    "(": ")",
    "[": "]",
    "{": "}",
  };

  // Iterate through the formula and track open brackets/parentheses
  for (const char of formula) {
    if (openToCloseMap[char]) {
      // If the character is an opening bracket/parenthesis, push it onto the stack
      stack.push(char);
    } else if (Object.values(openToCloseMap).includes(char)) {
      // If the character is a closing bracket/parenthesis, pop from the stack
      stack.pop();
    }
  }

  // Close any remaining open brackets/parentheses in the stack
  while (stack.length > 0) {
    const open = stack.pop()!;
    formula += openToCloseMap[open];
  }

  return formula;
}
