import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getIdsFromPayload} from "@shared/lib/entity-functions";
import id from "@shared/lib/id";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getUniqueSlug} from "@shared/lib/slug";
import {getEmployeesAdapter} from "@state/entity-adapters";

import employeesApi from "./api";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {RemoveEntitiesActionPayload} from "@shared/lib/entity-functions";
import type {Employee, ScenarioSpecificEmployeeProps} from "@shared/types/hiring-plan";
import type {EmployeesState} from "@state/entity-adapters";
import type {RootState} from "@state/store";

export const api = {
  fetchHiringPlanEntities: createAsyncThunk("employees/fetchHiringPlanEntities", employeesApi.fetchHiringPlanEntities),
  upsertEmployee: createAsyncThunk("employees/upsert", employeesApi.upsertEmployee),
  removeEmployees: createAsyncThunk("employees/remove", employeesApi.removeEmployees),
};

export type BasePayload = {
  id: keyof EmployeesState["entities"] & string;
};

export const getSlice = () => {
  const employeesAdapter = getEmployeesAdapter();

  return createSlice({
    name: "employees",
    initialState: employeesAdapter.getInitialState(),
    reducers: {
      upsertEmployeesLocal: employeesAdapter.upsertMany,
      removeEmployeesLocal: employeesAdapter.removeMany,
      employeeAdded: (state, action) => {
        const newEmployee: Employee = {
          ...action.payload,
          id: action.payload.id,
          slug: id(),
          first_name: "",
          last_name: "",
          role: "",
          team_id: action.payload.team_id || null,
          custom_tags: {},
          scenario_properties: action.payload.scenario_properties || {},
        };

        employeesAdapter.addOne(state, newEmployee);
        /*if (action.payload.teamId)
        state.idsByTeamId[action.payload.teamId] = [
          ...(state.idsByTeamId[action.payload.teamId] || []),
          newEmployee.id,
        ];*/
      },
      employeeUpdated: (
        state,
        action: PayloadAction<
          | {
              id: string;
              key: "role" | "first_name" | "last_name" | "team_id";
              value: string;
            }
          | {id: string; key: "custom_tags"; value: Record<string, string>}
        >,
      ) => {
        const {id, key, value} = action.payload;

        const employee = state.entities[id];
        if (!employee) return;

        const newEmployee = {...employee};

        if (key === "custom_tags") {
          newEmployee[key] = value;
        } else {
          newEmployee[key] = value;
          if (key === "first_name" || key === "last_name") {
            newEmployee.slug = getUniqueSlug(
              `${newEmployee.first_name} ${newEmployee.last_name}`,
              state.idsBySlug,
              newEmployee.slug,
            ).slug;
          }
        }

        employeesAdapter.upsertOne(state, newEmployee);
      },
      removeEmployees: (state, action: PayloadAction<RemoveEntitiesActionPayload>) => {
        employeesAdapter.removeMany(state, getIdsFromPayload(action.payload));
      },
      employeeScenarioPropertiesUpdated: (
        state,
        action: PayloadAction<
          BasePayload & {
            key: keyof ScenarioSpecificEmployeeProps;
            value: string;
            scenarioId: string;
          }
        >,
      ) => {
        const {id, key, value, scenarioId} = action.payload;

        const employee = state.entities[id];
        if (!employee) return;

        employee.scenario_properties[scenarioId] ||= {hire_date: ""};
        employee.scenario_properties[scenarioId][key] = value;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchHiringPlanEntities.fulfilled, (state, action) => {
        employeesAdapter.setAll(state, action.payload.employees);
      });
    },
  });
};

const _slice = getSlice();

export const {removeEmployeesLocal, upsertEmployeesLocal} = _slice.actions;

export const {employeeAdded, employeeUpdated, employeeScenarioPropertiesUpdated, removeEmployees} =
  createSyncedActionCreators(_slice.actions);
export type EmployeesActions = typeof _slice.actions;

export * from "./selectors";

export const selectEmployeesByTeamId = (teamId: string | null) => (state: RootState) =>
  state.employees.idsByTeamId[`${teamId}`] || null;

export const {
  selectById: selectEmployeeById,
  selectIds: selectEmployeeIds,
  selectEntities: selectEmployeeEntities,
  selectAll: selectAllEmployees,
  selectTotal: selectTotalEmployees,
} = getEmployeesAdapter().getSelectors((state: RootState) => state.employees);
