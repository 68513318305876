import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  notificationsArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: colors.text.darkBlue02,
    fontWeight: 600,

    "&:last-child": {
      marginRight: 25,
    },

    "& > * + *": {
      marginLeft: 20,
    },
  },
  viewOnlyText: {
    fontWeight: 700,
    color: colors.tertiary.blue,
  },
  separator: {
    fontSize: 20,
    color: colors.text.lightBlue04,
  },
  newButtonWrapper: {},
  newPopoverWrapper: {},
  notificationsIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& svg": {
      "& path": {
        color: colors.primary.gold,
      },
    },
  },
  settingsMenuWrapper: {
    "& .popover-trigger-wrapper svg": {
      "& path": {
        fill: colors.text.darkBlue02,
      },
    },
  },
  helpMenuWrapper: {},
  icons: {
    display: "flex",
    alignItems: "center",

    "& > * + *": {
      marginLeft: 14,
    },
  },
  hasAlerts: {
    extend: getSvgColorStyles(colors.reds.red),
  },
  userMenuWrapper: {},
});
