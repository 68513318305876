import {createEffectHandler} from "../listeners-list";
import {api, deleteSanityChecks, upsertSanityChecks} from "./slice";

export const syncSanityCheckUpsertsWithAPI = createEffectHandler(upsertSanityChecks, (action, {getState, dispatch}) => {
  if (!action.payload) return;
  const sanityChecks = Array.isArray(action.payload) ? action.payload : Object.values(action.payload);
  dispatch(api.upsertSanityChecks(sanityChecks));
});

export const syncSanityCheckDeletesWithAPI = createEffectHandler(deleteSanityChecks, (action, {getState, dispatch}) => {
  dispatch(api.deleteSanityChecks(action.payload as string[]));
});
