import {addDeltaMonthsToDateKey, getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {isBalance} from "@shared/lib/row-utilities";
import {projKeyOmitNulls} from "@state/utils";
import memoizeOne from "memoize-one";

import type {Dictionary, EntityId} from "@reduxjs/toolkit";
import {FormulaReference} from "@shared/types/datasources";
import type {CbTx, Department, TemplateRow} from "@shared/types/db";
import type {DepartmentsState} from "@state/entity-adapters";
import type {RootState} from "@state/store";
import type {DependencyCachePartialState} from "./dependency-cache";

export type ParsedCacheKey = {
  rowId: EntityId;
  departmentId?: EntityId | null | undefined;
  vendor?: string | null | undefined;
  scenarioId: EntityId;
  dateKey: string;
  balance?: boolean;
  total?: boolean;
};

export type ParsedCacheKeyWithVersion = ParsedCacheKey & {version: string | null};

/**
 * Parse a cache key into a ParsedCacheKey object
 * @param key The cache key string to parse
 * @returns The parsed cache key
 */
export function parseCacheKey(key: string): ParsedCacheKey {
  let balance = false;
  let total = false;

  try {
    if (key.endsWith("::total")) {
      key = key.slice(0, -7);
      total = true;
    }
    if (key.endsWith("::balance")) {
      key = key.slice(0, -9);
      balance = true;
    }
  } catch (e) {
    console.error("Error parsing cache key", key, e);
    console.log({typeOfKey: typeof key});
  }

  // Split the remainder
  const exploded = key.split("::");

  // First item is the row id
  const rowId = exploded.shift() ?? "[NO_ROW_ID]";
  // Last item is the date key
  let dateKey = exploded.pop() ?? "[NO_DATE_KEY]";
  // Next to last item is the scenario id
  const scenarioId = exploded.pop() ?? "[NO_SCENARIO_ID]";

  // If we still have items left, it's the departmentId first and then maybe the vendor
  let departmentId: string | null = null;
  let vendor: string | null = null;
  if (exploded.length) departmentId = exploded.shift() ?? null;
  if (exploded.length) vendor = exploded.shift() ?? null;

  return {rowId, departmentId, vendor, scenarioId, dateKey, balance, total};
}

/**
 * Extracts a value from the cache key
 * @param key the cache key
 * @param property the property to extract
 * @returns the extracted property
 */
export function extractFromCacheKey<P extends keyof ParsedCacheKey>(key: string, property: P): ParsedCacheKey[P] {
  return parseCacheKey(key)[property];
}

export function getStringKeyFromParsedKey(key: ParsedCacheKey) {
  const {rowId, departmentId, vendor, scenarioId, dateKey, balance, total} = key;
  return projKeyOmitNulls(
    rowId,
    departmentId,
    vendor ? vendor.toLowerCase() : null,
    scenarioId,
    dateKey,
    balance ? "balance" : null,
    total ? "total" : null,
  );
}

export function getStringKeyFromParsedKeyWithVersion(key: ParsedCacheKeyWithVersion) {
  const {version, rowId, departmentId, vendor, scenarioId, dateKey, balance, total} = key;
  return projKeyOmitNulls(
    version,
    rowId,
    departmentId,
    vendor ? vendor.toLowerCase() : null,
    scenarioId,
    dateKey,
    balance ? "balance" : null,
    total ? "total" : null,
  );
}

export function getCacheKeyFromReadableKey(state: RootState, key: string) {
  const {
    rowId: rowName,
    departmentId: departmentName,
    vendor,
    scenarioId: scenarioName,
    dateKey,
    balance,
    total,
  } = parseCacheKey(key);

  const rowId = state.templateRows.idsByName[rowName] ?? "[NO_ROW_ID]";
  const departmentId = state.departments.idsByName[departmentName ?? ""] ?? null;
  const scenarioId =
    Object.entries(state.scenarios?.entities ?? 8).find(([id, scenario]) => scenario?.name === scenarioName)?.[0] ??
    "scenario";

  return getStringKeyFromParsedKey({rowId, departmentId, vendor, scenarioId, dateKey, balance, total});
}

/**
 * Calculates the balance for a given dateKey.
 *
 * IMPORTANT: the previous balance must already have been calculated.
 *
 * @param monthlyCache - The monthlyCache
 * @param rowId - The id of the row to calculate the balance for
 * @param departmentId - The departmentId to calculate the balance for
 * @param vendor - The vendor to calculate the balance for
 * @param scenarioId - The scenarioId to calculate the balance for
 * @param dateKey - The dateKey to calculate the balance for
 */
export function calculateBalanceForDateKey(
  monthlyCache: Record<string, number>,
  rowId: EntityId,
  departmentId: EntityId | null | undefined,
  vendor: string | null | undefined,
  scenarioId: EntityId,
  dateKey: string,
) {
  const prevDateKey = addDeltaMonthsToDateKey(dateKey, -1);
  const prevBalanceKey = projKeyOmitNulls(rowId, departmentId, vendor, scenarioId, prevDateKey, "balance");
  const selfValueKey = projKeyOmitNulls(rowId, departmentId, vendor, scenarioId, dateKey);
  const newBalance = (monthlyCache[prevBalanceKey] ?? 0) + (monthlyCache[selfValueKey] ?? 0);
  return newBalance;
}

/**
 * Calculate the total value for a given row for a given date.
 *
 * IMPORTANT: the child totals must have been already calculated before calling this function.
 *
 * @param {TemplateRowsState} templateRowsState
 * @param {Record<string, number>} monthlyCache
 * @param {string} rowId
 * @param {string} scenarioId
 * @param {string} dateKey
 * @param {boolean} balance
 * @returns {number}
 */
export function calculateTotalForDateKey(
  state: DependencyCachePartialState,
  monthlyCache: Record<string, number>,
  rowId: EntityId,
  departmentId: EntityId | null | undefined,
  scenarioId: EntityId,
  dateKey: string,
  balance: boolean,
) {
  const selfValueKey = projKeyOmitNulls(rowId, departmentId, scenarioId, dateKey, balance ? "balance" : null);
  const departmentIsParent = !!state.departments.idsByParent[departmentId ?? ""]?.length;
  // const row = state.templateRows.entities[rowId];
  // if (row?.name === "expenses" && departmentId && dateKey === "2023-03") {
  //   console.log();
  // }
  let total = monthlyCache[selfValueKey] ?? 0;

  // If this is a department, add all its children totals as well
  if (departmentId) {
    const childDepartmentIds = state.departments.idsByParent[departmentId] ?? [];
    for (const childDepartmentId of childDepartmentIds) {
      const childDeptIsParent = !!state.departments.idsByParent[childDepartmentId]?.length;
      const childDepartmentValueKey = projKeyOmitNulls(
        rowId,
        childDepartmentId,
        scenarioId,
        dateKey,
        balance ? "balance" : null,
        childDeptIsParent ? "total" : null,
      );
      const childDepartmentValue = monthlyCache[childDepartmentValueKey] ?? 0;
      total += childDepartmentValue;
    }
  }

  for (const childId of state.templateRows.idsByParentRowId[rowId] ?? []) {
    const childRow = state.templateRows.entities[childId];
    if (!childRow) continue;
    const childIsParent = !!state.templateRows.idsByParentRowId[childId]?.length;
    const childValueKey = projKeyOmitNulls(
      childRow.mirror_of || childRow.id,
      departmentId,
      scenarioId,
      dateKey,
      balance && isBalance(childRow) ? "balance" : null,
      childIsParent || departmentIsParent ? "total" : null,
    );
    const childValue = monthlyCache[childValueKey] ?? 0;
    total +=
      childValue *
      ((childRow.type === "account" || childRow.type === "generic") &&
      childRow.mirror_of &&
      childRow.options.reverseSign
        ? -1
        : 1);
  }

  return total;
}

export function getValueFromCacheInState(
  state: RootState,
  row: TemplateRow | string,
  department: Department | string | null | undefined,
  vendor: string | null | undefined,
  scenarioId: string,
  dateKey: string,
  balance?: boolean | null | undefined,
  total?: boolean | null | undefined,
): number | null {
  if (typeof row === "string") {
    const rowFromState = state.templateRows.entities[row];
    if (!rowFromState) return null;
    row = rowFromState;
  }
  return getValueFromCache(
    state.transactionItems.valuesByRowIdDateKey,
    row,
    department,
    vendor,
    scenarioId,
    dateKey,
    balance,
    total,
  );
}

export function getValueFromCache(
  monthlyCache: Record<string, number>,
  row: TemplateRow,
  department: Department | string | null | undefined,
  vendor: string | null | undefined,
  scenarioId: string,
  dateKey: string,
  balance?: boolean | null | undefined,
  total?: boolean | null | undefined,
): number | null {
  const rowId = row.mirror_of || row.id;

  let departmentId: string | null = null;
  if (department && typeof department !== "string") {
    departmentId = department.id;
  } else if (department) {
    departmentId = department;
  }

  if (vendor) vendor = vendor.toLowerCase();

  const cacheKey = projKeyOmitNulls(
    rowId,
    departmentId || null,
    vendor || null,
    scenarioId,
    dateKey,
    balance ? "balance" : null,
    total ? "total" : null,
  );

  if (!monthlyCache[cacheKey]) return null;

  return row.mirror_of && (row.type === "account" || row.type === "generic") && row.options.reverseSign
    ? monthlyCache[cacheKey] * -1
    : monthlyCache[cacheKey];
}

export function setValuesInCache({
  cache,
  keyFilter,
  keysToSkip,
  value,
  rowId,
  departmentIdToTagWith,
  txDepartmentId,
  vendor,
  scenarioId,
  dateKey,
  isParentRow,
  isParentDept,
  rowIdToListOfParentsMapping,
  deptIdToListOfParentsMapping,
  balance,
  omitTotal,
}: {
  cache: Record<string, number>;
  keyFilter: Record<string, any> | null;
  keysToSkip: Record<string, any> | null;
  value: number;
  rowId: string | EntityId;
  departmentIdToTagWith: string | null | undefined | EntityId;
  txDepartmentId: string | null | undefined | EntityId;
  vendor: string | null | undefined;
  scenarioId: string | EntityId;
  dateKey: string;
  isParentRow: boolean;
  isParentDept: boolean;
  rowIdToListOfParentsMapping: Record<string, string[]>;
  deptIdToListOfParentsMapping: Record<string, string[]> | null;
  balance: boolean;
  omitTotal?: boolean;
}) {
  vendor = vendor?.toLowerCase();

  let rowLevelLookupKey = projKeyOmitNulls(
    rowId,
    departmentIdToTagWith,
    vendor,
    scenarioId,
    dateKey,
    balance ? "balance" : null,
  );

  if (keysToSkip && keysToSkip[rowLevelLookupKey]) return;
  const newValue = !cache[rowLevelLookupKey] ? value : (cache[rowLevelLookupKey] ?? 0) + value;
  if (newValue) {
    cache[rowLevelLookupKey] = newValue;
  } else if (cache[rowLevelLookupKey]) {
    delete cache[rowLevelLookupKey];
  }

  if (!omitTotal && (isParentRow || !!rowIdToListOfParentsMapping[rowId]?.length)) {
    addValueToAllParents(
      cache,
      "row",
      keyFilter,
      keysToSkip,
      value,
      rowId,
      departmentIdToTagWith,
      vendor,
      scenarioId,
      dateKey,
      rowIdToListOfParentsMapping,
      balance,
      isParentRow,
    );
  }

  if (
    deptIdToListOfParentsMapping &&
    departmentIdToTagWith &&
    (isParentDept || deptIdToListOfParentsMapping?.[departmentIdToTagWith]?.length)
  ) {
    addValueToAllParents(
      cache,
      "department",
      keyFilter,
      keysToSkip,
      value,
      rowId,
      departmentIdToTagWith,
      vendor,
      scenarioId,
      dateKey,
      deptIdToListOfParentsMapping,
      balance,
      isParentDept,
    );
  }
}

export function addValueToAllParents(
  cache: Record<string, number>,
  type: "row" | "department",
  keyFilter: Record<string, any> | null,
  keysToSkip: Record<string, any> | null,
  value: number,
  rowId: string | EntityId,
  departmentId: string | null | undefined | EntityId,
  vendor: string | null | undefined,
  scenarioId: string | EntityId,
  dateKey: string,
  listOfParentIds: Record<string, string[]>,
  balance: boolean,
  includeOwnTotal: boolean,
) {
  if (type === "department" && !departmentId) return;
  const entityId = type === "row" ? rowId : departmentId;
  if (!entityId) return;
  const parentIds: EntityId[] = entityId ? listOfParentIds[entityId] ?? [] : [];
  if (includeOwnTotal && !parentIds.includes(entityId)) parentIds.push(entityId);
  for (const id of parentIds) {
    const cacheKeyRowId = type === "row" ? id : rowId;
    const cacheKeyDepartmentId = type === "row" ? departmentId : id;

    const cacheKeyTotal = projKeyOmitNulls(
      cacheKeyRowId,
      cacheKeyDepartmentId,
      vendor,
      scenarioId,
      dateKey,
      balance ? "balance" : null,
      "total",
    );

    if (!keyFilter || keyFilter[cacheKeyTotal]) {
      const newValue = !cache[cacheKeyTotal] ? value : (cache[cacheKeyTotal] ?? 0) + value;
      if (newValue) {
        cache[cacheKeyTotal] = newValue;
      } else if (cache[cacheKeyTotal]) {
        delete cache[cacheKeyTotal];
      }
    }
  }
}

export const noClassIdentifier = "__NULL__";
export const getQboClassToDeptIdMapping = memoizeOne((departmentsState: DepartmentsState) => {
  const qboClassToDeptIdMapping: Record<string, string> = {};
  for (const departmentId of departmentsState.ids) {
    const department = departmentsState.entities[departmentId];
    if (!department) continue;

    for (const filter of department.filters) {
      if (filter.property === "tags" && filter.key === "qbo_class") {
        qboClassToDeptIdMapping[filter.value ?? noClassIdentifier] = department.id;
      }
    }
  }

  return qboClassToDeptIdMapping;
});

export function getDepartmentIdForCbTx(
  cbTx: CbTx,
  qboClassToDeptIdMapping: Record<string, string> | Dictionary<string>,
): string | null {
  if (cbTx.department_id) return cbTx.department_id;

  const klass = cbTx.tags.qbo_class ?? noClassIdentifier;

  return qboClassToDeptIdMapping[klass] ?? qboClassToDeptIdMapping[noClassIdentifier] ?? null;
}

export type MonthlyCacheDiff = {updatedCacheKeys: Record<string, number>; deletedCacheKeys: string[]};
export type MonthlyCacheAndCbTxDiff = MonthlyCacheDiff & {upsertedCbTx: CbTx[]; deletedCbTx: string[]};

export function getEmptyMonthlyCacheDiff(): MonthlyCacheDiff {
  return {updatedCacheKeys: {}, deletedCacheKeys: []};
}

export function getEmptyMonthlyCacheAndCbTxDiff(): MonthlyCacheAndCbTxDiff {
  return {updatedCacheKeys: {}, deletedCacheKeys: [], upsertedCbTx: [], deletedCbTx: []};
}

export function getMonthlyCacheDiff(
  oldMonthlyCache: Record<string, number>,
  newMonthlyCache: Record<string, number>,
  scenarioIdsToCompare: EntityId[],
): MonthlyCacheDiff {
  const updatedCacheKeys: Record<string, number> = {};
  const deletedCacheKeys: string[] = [];

  const typedScenarioIds = scenarioIdsToCompare as string[];

  for (const [key, value] of Object.entries(oldMonthlyCache)) {
    if (
      !typedScenarioIds.some(function (v) {
        // eslint-disable-next-line unicorn/prefer-includes
        return key.indexOf(v) >= 0;
      })
    ) {
      continue;
    }
    if (!newMonthlyCache[key]) {
      deletedCacheKeys.push(key);
    } else if (newMonthlyCache[key] !== value) {
      updatedCacheKeys[key] = newMonthlyCache[key];
    }
  }

  for (const [key, value] of Object.entries(newMonthlyCache)) {
    if (!oldMonthlyCache[key]) {
      updatedCacheKeys[key] = value;
    }
  }

  return {updatedCacheKeys, deletedCacheKeys};
}

export function mergeDiffs(existingDiff: MonthlyCacheAndCbTxDiff, diffToMerge: MonthlyCacheAndCbTxDiff) {
  const mergedDiff: MonthlyCacheAndCbTxDiff = {
    updatedCacheKeys: {...existingDiff.updatedCacheKeys, ...diffToMerge.updatedCacheKeys},
    deletedCacheKeys: [...existingDiff.deletedCacheKeys, ...diffToMerge.deletedCacheKeys],
    upsertedCbTx: [...existingDiff.upsertedCbTx, ...diffToMerge.upsertedCbTx],
    deletedCbTx: [...existingDiff.deletedCbTx, ...diffToMerge.deletedCbTx],
  };

  return mergedDiff;
}

export function applyDiffToMonthlyCache(oldMonthlyCache: Record<string, number>, diff: MonthlyCacheDiff) {
  const newMonthlyCache = {...oldMonthlyCache};
  // Process deleted keys first so that updated keys can override them
  for (const key of diff.deletedCacheKeys) {
    delete newMonthlyCache[key];
  }
  for (const [key, value] of Object.entries(diff.updatedCacheKeys)) {
    newMonthlyCache[key] = value;
  }
  return newMonthlyCache;
}

// export type FormulaReference = {
//   from: string;
//   to: string;
//   row?: string | null;
//   department?: string | null;
//   vendor?: string | null;
//   template?: string | null;
//   total?: string | null;
//   refStr: string;
//   tags?: FormulaReferenceCbTags | Record<string, string>;
// };
export function getCacheKeysFromFormulaRef(
  ref: FormulaReference,
  scenarioId: string,
  resolvedDates: {from: string; to: string},
) {
  const months = getAllDateKeysBetween(resolvedDates.from, resolvedDates.to);

  const keys: string[] = [];

  for (const month of months) {
    keys.push(projKeyOmitNulls(ref.row, ref.department, ref.vendor, scenarioId, month));
  }
}
