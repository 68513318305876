import colors from "@app/styles/colors";
import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./V2.jss";

import type {IconNames} from "@components/SVGIcon";
import type {FunctionComponent} from "react";

export interface ButtonProps {
  active?: boolean;
  textAlign?: "center" | "right" | "left";
  className?: string;
  color?: "orange" | "red" | "grey" | "blue" | "green" | null | undefined;
  disabled?: boolean;
  fill?: boolean;
  full?: boolean;
  iconLeft?: IconNames | JSX.Element;
  iconLeftSize?: number;
  iconRight?: IconNames | JSX.Element;
  iconRightSize?: number;
  isLink?: boolean;
  onClick?: React.MouseEventHandler;
  rightText?: string;
  size?: "xsmall" | "small" | "normal" | "large" | "xlarge";
  text?: string;
  width?: number;
}

export type ButtonColor = ButtonProps["color"];

export type ResolvedColors = {
  border: string;
  text: string;
  background: string;
  hoverBackground: string;
  hoverText: string;
  activeBackground: string;
  activeText: string;
};

export function getResolvedColorsFromPassedColor(
  passedColor: ButtonColor,
  disabled: boolean,
  isLink: boolean,
): ResolvedColors {
  if (isLink) {
    return {
      border: "transparent",
      text: colors.text.lightBlue01,
      hoverText: colors.text.darkBlue02,
      activeText: colors.text.lightBlue02,
      background: "transparent",
      hoverBackground: "transparent",
      activeBackground: "transparent",
    };
  }
  switch (passedColor) {
    case "red": {
      return {
        text: disabled ? colors.reds.red : colors.ui.white,
        border: disabled ? colors.reds.red : "transparent",
        background: disabled ? "transparent" : colors.reds.lightRed01,
        activeBackground: colors.reds.lightRed02,
        activeText: colors.text.white,
        hoverBackground: colors.reds.red,
        hoverText: colors.text.white,
      };
    }
    case "blue": {
      return {
        text: colors.text.white,
        border: disabled ? colors.tertiary.darkBlue01 : "transparent",
        background: disabled ? "transparent" : colors.tertiary.darkBlue01,
        activeBackground: colors.tertiary.customDarkBlue02,
        activeText: colors.text.white,
        hoverBackground: colors.tertiary.customDarkBlue01,
        hoverText: colors.text.white,
      };
    }
    case "green": {
      return {
        text: colors.other.darkGreen02,
        border: disabled ? colors.secondary.lightGreen08 : "transparent",
        background: disabled ? "transparent" : colors.secondary.lightGreen08,
        activeBackground: colors.secondary.green50Opacity,
        activeText: colors.other.darkGreen02,
        hoverBackground: colors.secondary.green50Opacity,
        hoverText: colors.other.darkGreen02,
      };
    }
    case "grey": {
      return {
        text: isLink ? colors.text.lightBlue01 : colors.text.darkBlue01,
        border: colors.ui.lightBlue05,
        background: "transparent",
        activeBackground: colors.ui.lightBlue08,
        activeText: isLink ? colors.text.lightBlue02 : colors.text.darkBlue01,
        hoverBackground: colors.ui.lightBlue06,
        hoverText: colors.text.darkBlue01,
      };
    }
    case "orange":
    default:
      return {
        border: disabled ? colors.primary.gold : "transparent",
        text: colors.primary.gold,
        background: disabled ? "transparent" : colors.primary.lightGold03,
        activeBackground: colors.primary.lightGold01,
        activeText: colors.text.white,
        hoverBackground: colors.primary.gold,
        hoverText: colors.text.white,
      };
  }
}
const Button: FunctionComponent<ButtonProps> = ({
  active = false,
  className,
  color,
  disabled = false,
  fill,
  full,
  iconLeft,
  iconLeftSize,
  iconRight,
  iconRightSize,
  isLink = false,
  onClick,
  rightText,
  size = "normal",
  text,
  width,
  textAlign = "left",
}: ButtonProps) => {
  const resolvedColors = getResolvedColorsFromPassedColor(color || null, disabled, isLink);

  const styles = useStyles({
    ...resolvedColors,
    disabled,
    size,
    textAlign,
  });

  const classes = clsx(
    styles.buttonMain,
    {
      [styles.active]: active,
      [styles.disabled]: disabled,
      [styles.small]: size === "small",
      [styles.xsmall]: size === "xsmall",
      [styles.isLink]: isLink,
      [styles.fill]: fill,
    },
    className,
  );

  const style: React.CSSProperties = {};
  if (fill) {
    style.width = "100%";
  } else if (!!width) {
    style.width = `${width}px`;
  }

  let iconLeftComponent: JSX.Element | null = null;
  if (iconLeft && typeof iconLeft === "string") {
    iconLeftComponent = <SVGIcon className={styles.iconLeft} name={iconLeft} size={iconLeftSize ?? 11} />;
  } else if (iconLeft) {
    iconLeftComponent = iconLeft;
  }

  let iconRightComponent: JSX.Element | null = null;
  if (iconRight && typeof iconRight === "string") {
    iconRightComponent = (
      <div className={styles.iconRight}>
        <SVGIcon className={styles.iconRight} name={iconRight} size={iconRightSize ?? 11} />;
      </div>
    );
  } else if (iconRight) {
    iconRightComponent = <div className={styles.iconRight}>{iconRight}</div>;
  }

  return (
    <div className={classes} onClick={!disabled ? onClick : undefined} style={style}>
      {iconLeftComponent}
      {text ? <span>{text}</span> : null}
      {rightText ? <div className={styles.rightText}>{rightText}</div> : null}
      {iconRightComponent}
    </div>
  );
};

export default Button;
