import colors from "@app/styles/colors";
import color from "color";
import {createUseStyles} from "react-jss";

import type {RoundIconProps} from "./index";

export default createUseStyles({
  "@keyframes spin": {
    from: {transform: "rotate(0deg)"},
    to: {opacity: "rotate(360deg)"},
  },

  roundIconMain: {
    borderRadius: 9999,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 23,
    fontSize: ({text}: RoundIconProps) => (text?.length ? 10 : 16),
    color: getIconColor,
    background: ({mainColor, minimal}: RoundIconProps) => {
      if (minimal) return "transparent";
      switch (mainColor) {
        case "orange": {
          return colors.primary.lightGold03;
        }
        case "fullOrange": {
          return colors.primary.lightGold03;
        }
        case "green": {
          return color(colors.secondary.lightGreen).alpha(0.3).rgb().string();
        }
        case "blue": {
          return colors.tertiary.lightBlue02;
        }
        case "tertiaryBlue": {
          return colors.tertiary.blue;
        }
        case "purple": {
          return colors.ui.lightBlue06;
        }
        case "grey": {
          return colors.ui.lightBlue05;
        }
        case "darkGrey": {
          return colors.text.lightBlue02;
        }
        case "white": {
          return colors.ui.white;
        }
        case "red": {
          return colors.reds.red;
        }
      }
    },

    "& div svg": {
      width: ({containerSize, iconSize}: RoundIconProps) => (iconSize || containerSize || 30) / 2,
      height: ({containerSize, iconSize}: RoundIconProps) => (iconSize || containerSize || 30) / 2,

      "& path": {
        fill: getIconColor,
      },
    },
  },
  animate: {
    animationName: "$spin",
  },
  compact: {
    fontWeight: 800,
  },
  svgIconMain: {},
});

function getIconColor({iconColor, mainColor}: RoundIconProps) {
  if (iconColor) {
    switch (iconColor) {
      case "grey": {
        return colors.ui.lightBlue01;
      }
      case "orange": {
        return colors.primary.gold;
      }
      case "tertiaryBlue": {
        return colors.tertiary.blue;
      }
      case "red": {
        return colors.reds.red;
      }
      default: {
        return colors.text.darkBlue02;
      }
    }
  } else {
    switch (mainColor) {
      case "grey": {
        return colors.ui.lightBlue01;
      }
      default: {
        return colors.ui.black;
      }
    }
  }
}
