import {
  getStringKeyFromParsedKeyWithVersion,
  type ParsedCacheKeyWithVersion,
} from "@shared/data-functions/cache/cache-utilities";
import {resolveDaterange} from "@shared/data-functions/formula/date-range";
import {formatNumber} from "@shared/lib/templates-utilities";
import {projKeyOmitNulls} from "@state/utils";
import dayjs from "dayjs";

import {getAllDateKeysBetween} from "./date-utilities";
import {isBalance} from "./row-utilities";

import type {Dictionary, EntityId} from "@reduxjs/toolkit";
import type {Template, TemplateRow} from "@shared/types/db";
import type {
  ChartData,
  ChartReportItem,
  CustomTableColumn,
  CustomTableReportItem,
  ReportItem,
  TimeSeriesTableData,
  TimeSeriesTableReportItem,
} from "@shared/types/reporting";
import type {TemplateRowsState} from "@state/entity-adapters";

type Layout = ReportItem["layout"];

export const LAYOUT_NB_COLUMNS = 12;
export const LAYOUT_ROW_HEIGHT = 95;
export const LAYOUT_MARGINS = 20;

export function getLayoutForNewReportItem(
  reportItemEntities: Dictionary<ReportItem>,
  id: string,
  reportItemToDuplicate?: ReportItem,
): Layout {
  // Initialize cursors
  let maxXCursor = 0;
  let maxYCursor = 0;

  // Iterate through all report items to find the maximum X and Y positions
  for (const reportItem of Object.values(reportItemEntities)) {
    if (!reportItem) continue;
    maxXCursor = Math.max(maxXCursor, reportItem.layout.x + reportItem.layout.w);
    maxYCursor = Math.max(maxYCursor, reportItem.layout.y + reportItem.layout.h);
  }

  // Default layout for a new item
  const defaultLayout: Partial<Layout> = {
    i: id,
    w: LAYOUT_NB_COLUMNS / 2,
    h: Math.floor(640 / (LAYOUT_ROW_HEIGHT + LAYOUT_MARGINS)), // 500px
    x: maxXCursor < LAYOUT_NB_COLUMNS ? maxXCursor : 0,
    y: maxXCursor < LAYOUT_NB_COLUMNS ? maxYCursor : maxYCursor + 1,
  };

  // If duplicating, adjust the layout based on the duplicated item
  if (reportItemToDuplicate) {
    const duplicatedLayout: Partial<Layout> = {...reportItemToDuplicate.layout, i: id};

    // Check if the duplicated item can fit in the remaining space of the current row
    if (typeof duplicatedLayout.w !== "undefined" && maxXCursor + duplicatedLayout.w <= LAYOUT_NB_COLUMNS) {
      duplicatedLayout.x = maxXCursor;
      duplicatedLayout.y = maxYCursor;
    } else {
      // Place it in a new row if it doesn't fit
      duplicatedLayout.x = 0;
      duplicatedLayout.y = maxYCursor + 1;
    }

    return duplicatedLayout as Layout;
  }

  // Return the layout for a new item if no item is being duplicated
  return defaultLayout as Layout;
}

export function getDuplicateName(name: string, existingNames: string[]): string {
  const copyRegex = /(.*) \(copy( (\d+))?\)/;
  const match = name.match(copyRegex);

  // Determine the base name and the copy number
  let baseName: string;
  let copyNumber: number;

  if (match) {
    baseName = match[1];
    copyNumber = match[3] ? parseInt(match[3], 10) + 1 : 2;
  } else {
    baseName = name;
    copyNumber = 1;
  }

  // Generate the new name
  const newName = `${baseName} (copy${copyNumber > 1 ? ` ${copyNumber}` : ""})`;

  // Check if the new name already exists in the array
  if (existingNames.includes(newName)) {
    return getDuplicateName(newName, existingNames);
  }

  return newName;
}

export function findUpdatedLayoutItems(reportItems: ReportItem[], newLayout: Layout[]) {
  const updatedItems: Layout[] = [];
  const oldLayoutsByItemId = Object.fromEntries(reportItems.map((item) => [item.id, item.layout]));
  // const newLayoutsByItemId = Object.fromEntries(newLayout.map((item) => [item.i, item]));

  for (const itemInNewLayout of newLayout) {
    const matchingOldItem = oldLayoutsByItemId[itemInNewLayout.i];
    if (
      matchingOldItem &&
      (matchingOldItem.h !== itemInNewLayout.h ||
        matchingOldItem.w !== itemInNewLayout.w ||
        matchingOldItem.x !== itemInNewLayout.x ||
        matchingOldItem.y !== itemInNewLayout.y)
    ) {
      updatedItems.push(itemInNewLayout);
    }
  }

  return updatedItems;
}

export type TableDataForDisplay = {
  cols: {value: string; id: string}[];
  rows: {id: string; values: (string | null)[]}[];
};
export function getTableData(
  table: ReportItem,
  {
    templateRowEntities,
    rowIdsByParentId,
    monthlyCache,
    reportsMonthlyCache,
    templateEntities,
  }: {
    rowIdsByParentId: Record<string, string[] | undefined>;
    templateRowEntities: TemplateRowsState["entities"];
    monthlyCache: Record<string, number>;
    reportsMonthlyCache: Record<string, number>;
    templateEntities: Record<string, Template>;
  },
): TableDataForDisplay {
  const currentMonth = dayjs().format("YYYY-MM");
  const templateLmoa = getReportItemLmoa(table, templateEntities);
  const tableData: TableDataForDisplay = {
    cols: [],
    rows: [],
  };
  if (table.type === "time_series_table") {
    const {cols: months, cacheKeys: tableCacheKeys} = getTimeSeriesChartOrTableCacheKeys(
      table,
      templateRowEntities,
      rowIdsByParentId,
      templateLmoa,
    );

    for (const month of months) {
      const formatted = dayjs(month, "YYYY-MM").format(table.data.timePeriodFormat || "MMM YYYY");
      tableData.cols.push({id: formatted, value: formatted});
    }

    for (const {cacheKeys: lineCacheKeys, row, line} of tableCacheKeys) {
      const rowValues: (string | null)[] = [];
      for (const parsedCacheKey of lineCacheKeys) {
        const cacheKey = parsedCacheKey ? getStringKeyFromParsedKeyWithVersion(parsedCacheKey) : null;
        // if (table.name === "Version testing") console.log({parsedCacheKey, row, line, cache: reportsMonthlyCache});
        const value =
          !cacheKey || !row
            ? null
            : (monthlyCache[cacheKey] ?? reportsMonthlyCache[cacheKey]) *
              ((row.type === "account" || row.type === "generic") && row.mirror_of && row.options.reverseSign ? -1 : 1);
        rowValues.push(
          formatNumber(value, false, line.formatting?.decimals ?? 0, line.formatting?.percentage === true),
        );
      }
      tableData.rows.push({id: line.id, values: rowValues});
    }
  } else if (table.type === "custom_table") {
    for (const col of table.data.columns) {
      tableData.cols.push({id: col.id, value: col.name ?? ""});
    }

    for (const line of table.data.lines) {
      const row = templateRowEntities[line.datasource || ""];
      const formattedRowValues: (string | null)[] = [];
      const rowValues: number[] = [];

      for (const [i, col] of table.data.columns.entries()) {
        if (col.timePeriod) {
          const {start: from, end: to} = resolveDaterange(`${col.from}:${col.to}`, currentMonth, templateLmoa);
          if (!from || !to) {
            formattedRowValues.push(null);
            continue;
          }

          const months = getAllDateKeysBetween(from, to);

          let colTotalValue = 0;
          for (const month of months) {
            if (!row) continue;
            // Handle balance rows / period end lines
            if (
              month !== to &&
              (isBalance(row) ||
                // @ts-ignore
                line.balance ||
                line.displayAs === "periodEndValue")
            )
              continue;

            if (month !== from && line.displayAs === "periodStartValue") continue;
            const cacheKey = projKeyOmitNulls(
              col.version,
              row.mirror_of ?? row.id,
              line.department,
              col.scenario,
              month,
              isBalance(row) ? "balance" : null,
              !!rowIdsByParentId[line.datasource!]?.length ? "total" : null,
            );

            const resolvedValue = col.scenario ? (reportsMonthlyCache[cacheKey] ?? monthlyCache[cacheKey]) || 0 : 0;
            colTotalValue +=
              resolvedValue *
              ((row.type === "account" || row.type === "generic") && row.mirror_of && row.options.reverseSign ? -1 : 1);
          }
          const formattedValue = formatNumber(
            colTotalValue,
            false,
            line.formatting?.decimals || 0,
            line.formatting?.percentage === true,
          );
          formattedRowValues.push(formattedValue);
          rowValues.push(colTotalValue);
        } else if (col.compares) {
          let comparisonValue: number | null = null;
          if (col.compares.type === "delta") {
            comparisonValue = rowValues[col.compares.col2Index] - rowValues[col.compares.col1Index];
          } else {
            if (!rowValues[col.compares.col1Index] && rowValues[col.compares.col2Index]) {
              comparisonValue = 1;
            } else if (!rowValues[col.compares.col2Index] && rowValues[col.compares.col1Index]) {
              comparisonValue = -1;
            } else if (!rowValues[col.compares.col1Index] && !rowValues[col.compares.col2Index]) {
              comparisonValue = 0;
            } else {
              comparisonValue = rowValues[col.compares.col2Index] / rowValues[col.compares.col1Index] - 1;
            }
            if (comparisonValue < 0.00000001 && comparisonValue > -0.00000001) comparisonValue = 0;
          }
          rowValues.push(comparisonValue);
          formattedRowValues.push(
            formatNumber(
              comparisonValue,
              false,
              col.compares.type === "delta" ? line.formatting?.decimals || 0 : 1,
              col.compares.type === "deltaPct" || line.formatting?.percentage === true,
            ),
          );
        }
      }
      tableData.rows.push({id: line.id, values: formattedRowValues});
    }
  }

  return tableData;
}

type TableLineCacheKeys<T extends TimeSeriesTableReportItem | CustomTableReportItem | ChartReportItem> = {
  cacheKeys: (ParsedCacheKeyWithVersion | null)[];
  line: T["data"]["lines"][number];
  row: TemplateRow | null;
};

export function getTimeSeriesChartOrTableCacheKeys<T extends TimeSeriesTableReportItem | ChartReportItem>(
  entity: T,
  rowsById: Dictionary<TemplateRow>,
  rowIdsByParentId: Record<EntityId, EntityId[] | undefined>,
  lastMonthOfActuals: string,
): {cols: string[]; cacheKeys: TableLineCacheKeys<T>[]} {
  const cacheKeys: TableLineCacheKeys<T>[] = [];
  const {start, end} = resolveDaterange(
    `${entity.data.from}:${entity.data.to}`,
    dayjs().format("YYYY-MM"),
    lastMonthOfActuals,
  );
  const months = getAllDateKeysBetween(start, end);
  for (const line of entity.data.lines) {
    const row = rowsById[line.datasource || ""];
    const cacheKeysForLine: TableLineCacheKeys<T>["cacheKeys"] = [];
    for (const month of months) {
      if (row && line.scenario) {
        const parsedCacheKey: ParsedCacheKeyWithVersion = {
          version: line.version ?? null,
          rowId: row.mirror_of ?? row.id,
          departmentId: line.department,
          scenarioId: line.scenario,
          dateKey: month,
          balance: isBalance(row),
          total: !!rowIdsByParentId[line.datasource!]?.length,
        };
        cacheKeysForLine.push(parsedCacheKey);
      } else {
        cacheKeysForLine.push(null);
      }
    }
    cacheKeys.push({cacheKeys: cacheKeysForLine, row: row ?? null, line});
  }

  return {cols: months, cacheKeys};
}

export function getCustomTableCacheKeys(
  table: CustomTableReportItem,
  rowsById: Dictionary<TemplateRow>,
  rowIdsByParentId: Record<EntityId, EntityId[] | undefined>,
  templatesById: Record<string, Template>,
): {cols: CustomTableReportItem["data"]["columns"]; cacheKeys: TableLineCacheKeys<CustomTableReportItem>[]} {
  const cacheKeys: TableLineCacheKeys<CustomTableReportItem>[] = [];
  for (const line of table.data.lines) {
    const row = rowsById[line.datasource || ""];
    const lineData: TableLineCacheKeys<CustomTableReportItem> = {
      cacheKeys: [],
      line,
      row: row ?? null,
    };
    for (const col of table.data.columns) {
      if (col.timePeriod) {
        const {from, to} = getPeriodFromTo(col, templatesById);
        if (!from || !to) {
          lineData.cacheKeys.push(null);
          continue;
        }

        const months = getAllDateKeysBetween(from, to);
        for (const month of months) {
          if (
            !row ||
            // Handle balance rows / period end lines
            (month !== to &&
              (isBalance(row) ||
                // @ts-ignore
                line.balance ||
                line.displayAs === "periodEndValue")) ||
            (month !== from && line.displayAs === "periodStartValue")
          ) {
            lineData.cacheKeys.push(null);
            continue;
          }

          if (!col.scenario) {
            lineData.cacheKeys.push(null);
            continue;
          }

          const parsedCacheKey = {
            version: col.version ?? null,
            rowId: row.mirror_of ?? row.id,
            departmentId: line.department,
            scenarioId: col.scenario,
            dateKey: month,
            balance: isBalance(row),
            total: !!rowIdsByParentId[line.datasource!]?.length,
          };

          lineData.cacheKeys.push(parsedCacheKey);
        }
      } else if (col.compares) {
        lineData.cacheKeys.push(null);
      }
    }
    cacheKeys.push(lineData);
  }

  return {cols: table.data.columns, cacheKeys};
}

export function getReportCacheKeys(
  reportItems: ReportItem[],
  rowsById: Dictionary<TemplateRow>,
  rowIdsByParentId: Record<EntityId, EntityId[] | undefined>,
  templatesById: Record<string, Template>,
) {
  const totalCacheKeys: ParsedCacheKeyWithVersion[] = [];
  for (const _reportItem of reportItems) {
    totalCacheKeys.push(...getReportItemCacheKeys(_reportItem, rowsById, rowIdsByParentId, templatesById));
  }

  return totalCacheKeys;
}

export function getReportItemCacheKeys(
  _reportItem: ReportItem,
  rowsById: Dictionary<TemplateRow>,
  rowIdsByParentId: Record<EntityId, EntityId[] | undefined>,
  templatesById: Record<string, Template>,
) {
  if (!["chart", "time_series_table", "custom_table"].includes(_reportItem.type)) return [];

  const reportItem: ChartReportItem | TimeSeriesTableReportItem | CustomTableReportItem = _reportItem as any;
  const {cacheKeys} =
    reportItem.type === "time_series_table" || reportItem.type === "chart"
      ? getTimeSeriesChartOrTableCacheKeys(
          reportItem,
          rowsById,
          rowIdsByParentId,
          getReportItemLmoa(reportItem, templatesById),
        )
      : getCustomTableCacheKeys(reportItem, rowsById, rowIdsByParentId, templatesById);

  const totalCacheKeys: ParsedCacheKeyWithVersion[] = [];
  for (const {cacheKeys: lineCacheKeys} of cacheKeys) {
    for (const cacheKey of lineCacheKeys) {
      if (cacheKey) totalCacheKeys.push(cacheKey);
    }
  }

  return totalCacheKeys;
}

export function getReportItemTemplate(reportItem: ReportItem, templatesById: Record<string, Template>) {
  return (
    templatesById[(reportItem.data as any).lastMonthOfActualsFrom ?? ""] ??
    Object.values(templatesById).find((item) => item?.name === "profit_and_loss") ??
    null
  );
}

export function getReportItemLmoa(reportItem: ReportItem, templatesById: Record<string, Template>) {
  return getReportItemTemplate(reportItem, templatesById)?.options?.lastMonthOfActuals ?? dayjs().format("YYYY-MM");
}

export function getPeriodFromTo(
  item: ChartData | TimeSeriesTableData | CustomTableColumn,
  templateEntities: Record<string, Template> | Dictionary<Template>,
) {
  const currentMonth = dayjs().format("YYYY-MM");
  const fullRange = `${item.from}:${item.to}`;
  const pnlTemplate = Object.values(templateEntities).find((item) => item?.name === "profit_and_loss");
  const lastMonthOfActuals =
    templateEntities[item.lastMonthOfActualsFrom ?? ""]?.options?.lastMonthOfActuals ??
    pnlTemplate?.options?.lastMonthOfActuals ??
    currentMonth;
  const {start: from, end: to} = resolveDaterange(
    oldPresetToNew.full[fullRange] ?? fullRange,
    currentMonth,
    lastMonthOfActuals,
  );

  return {from, to};
}

export const timePeriodPresets = {
  reportItemTimePeriod: {
    "-1a": "Last Month of Actuals",
    "-3a": "3 Months Ago",
    "-12a": "12 Months Ago",
  },
  reportItemTimePeriodFullList: {
    "-1a": "Last Month of Actuals",
    "-2a": "2 Months Ago",
    "-3a": "3 Months Ago",
    "-4a": "4 Months Ago",
    "-5a": "5 Months Ago",
    "-6a": "6 Months Ago",
    "-7a": "7 Months Ago",
    "-8a": "8 Months Ago",
    "-9a": "9 Months Ago",
    "-10a": "10 Months Ago",
    "-11a": "11 Months Ago",
    "-12a": "12 Months Ago",
    "-13a": "13 Months Ago",
    "-14a": "14 Months Ago",
    "-15a": "15 Months Ago",
    "-16a": "16 Months Ago",
    "-17a": "17 Months Ago",
    "-18a": "18 Months Ago",
    "-19a": "19 Months Ago",
    "-20a": "20 Months Ago",
    "-21a": "21 Months Ago",
    "-22a": "22 Months Ago",
    "-23a": "23 Months Ago",
    "-24a": "24 Months Ago",
    "1f": "1 Month Ahead (First Forecast Month)",
    "2f": "2 Months Ahead",
    "3f": "3 Months Ahead",
    "4f": "4 Months Ahead",
    "5f": "5 Months Ahead",
    "6f": "6 Months Ahead",
    "7f": "7 Months Ahead",
    "8f": "8 Months Ahead",
    "9f": "9 Months Ahead",
    "10f": "10 Months Ahead",
    "11f": "11 Months Ahead",
    "12f": "12 Months Ahead",
    "13f": "13 Months Ahead",
    "14f": "14 Months Ahead",
    "15f": "15 Months Ahead",
    "16f": "16 Months Ahead",
    "17f": "17 Months Ahead",
    "18f": "18 Months Ahead",
    "19f": "19 Months Ahead",
    "20f": "20 Months Ahead",
    "21f": "21 Months Ahead",
    "22f": "22 Months Ahead",
    "23f": "23 Months Ahead",
    "24f": "24 Months Ahead",
  },
};

export type TimePeriodPresets = keyof typeof timePeriodPresets;
export const oldPresetToNew: {
  start: Record<string, string>;
  end: Record<string, string>;
  full: Record<string, string>;
} = {
  start: {
    this_year: "2024-01",
    this_year_to_last_month: "2024-01",
    last_year: "2023-01",
    this_month: "0",
    last_month: "-1a",
  },
  end: {
    this_year: "-1a",
    this_year_to_last_month: "-1a",
    last_year: "2023-12",
    this_month: "0",
    last_month: "-1a",
  },
  full: {
    this_year: "2024-01:-1a",
    this_year_to_last_month: "2024-01:-1a",
    last_year: "2023-01:2023-12",
    this_month: "0:0",
    last_month: "-1a:-1a",
  },
};
