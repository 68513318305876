import clsx from "clsx";
import {forwardRef} from "react";

import useStyles from "./styles.jss";

export interface BasePopoverLayerProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const BasePopoverLayer = forwardRef<HTMLDivElement, BasePopoverLayerProps>(function BasePopoverLayer(
  {children, className, style},
  ref,
) {
  const styles = useStyles();
  return (
    <div className={clsx(styles.basePopoverLayer, className)} style={style} ref={ref}>
      <div>{children}</div>
    </div>
  );
});
