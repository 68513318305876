import {sharedButtonStyles} from "@app/styles/general-ui";
import {getSvgColorStylesFromProps} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

import type {ButtonProps, ResolvedColors} from "./V2";

export const sharedValues = {};

type ButtonStyleVars = ResolvedColors & Pick<ButtonProps, "disabled" | "size" | "textAlign">;

export default createUseStyles({
  buttonMain: {
    extend: sharedButtonStyles,
    borderColor: ({border}: ButtonStyleVars) => border,
    color: ({text}: ButtonStyleVars) => text,
    backgroundColor: ({background}: ButtonStyleVars) => background,
    cursor: ({disabled}: ButtonStyleVars) => (disabled ? "default" : "pointer"),
    pointerEvents: ({disabled}: ButtonStyleVars) => (!disabled ? "auto" : "none"),
    textAlign: ({textAlign}: ButtonStyleVars) => textAlign,
    userSelect: "none",
    transition: "all 65ms ease",

    "& > span": {
      flex: 1,
    },

    "&:hover:not(:active):not($active)": {
      backgroundColor: ({hoverBackground}: ButtonStyleVars) => hoverBackground,
      color: ({hoverText}: ButtonStyleVars) => hoverText,

      "& $iconRight, & $iconLeft": {
        extend: getSvgColorStylesFromProps("hoverText"),
      },

      "& $rightText": {
        color: ({hoverText}: ButtonStyleVars) => hoverText,
      },
    },

    "&:active, &:focus, &$active": {
      backgroundColor: ({activeBackground}: ButtonStyleVars) => activeBackground,
      color: ({activeText}: ButtonStyleVars) => activeText,

      "& $iconRight, & $iconLeft": {
        extend: getSvgColorStylesFromProps("activeText"),
      },

      "& $rightText": {
        color: ({activeText}: ButtonStyleVars) => activeText,
      },
    },
  },
  disabled: {
    opacity: 0.5,
  },
  isLink: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  fill: {
    "& > span": {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  small: {
    height: 35,
    fontSize: 12,
    paddingLeft: 15,
    paddingRight: 15,
  },
  xsmall: {
    height: 30,
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,

    "& $iconLeft": {
      marginRight: 1,
    },
  },
  rightText: {},
  active: {},
  iconLeft: {
    extend: getSvgColorStylesFromProps("text"),
    height: 10,
    display: "flex",
    marginTop: -2,

    "& + span": {
      marginLeft: 4,
    },
  },

  iconRight: {
    extend: getSvgColorStylesFromProps("text"),
    marginLeft: 12,
  },
});
