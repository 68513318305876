import {projKeyOmitNulls} from "@shared/state/utils";

import {getDepartmentIdForCbTx} from "./cache/cache-utilities";

import type {EntityId} from "@reduxjs/toolkit";
import type {CbTxState, DepartmentsState} from "@shared/state/entity-adapters";
import type {Department, TemplateRow} from "@shared/types/db";

export const creditIncreaseClassifications = ["revenue", "liability", "equity"];

/**
 * Given a row, a debit and a credit amount, returns the amount that should be used
 *
 * @param credit - The amount of credit (or null)
 * @param debit - The amount of debit (or null)
 * @param row - The row for which we want to get the amount
 * @returns The value (amount)
 */
export const getAmountForDisplay = function getAmountForDisplay({
  type,
  amount,
  classification,
  reverseSign,
}: {
  type: "credit" | "debit" | null;
  amount: number;
  classification?: string;
  reverseSign?: boolean;
}): number {
  if (!classification) return amount;
  let returnedValue = 0;
  if (type === "credit" || type === null) {
    returnedValue = creditIncreaseClassifications.includes(classification || "NIL") ? amount : amount * -1;
  } else if (type === "debit") {
    returnedValue = creditIncreaseClassifications.includes(classification || "NIL") ? amount * -1 : amount;
  }

  return reverseSign ? returnedValue * -1 : returnedValue;
};

export function getCachedValueForMonth(
  monthlyCache: Record<string, number>,
  rowId: string,
  scenarioId: string,
  dateKey: string,
  isTotal: boolean = false,
  isBalance: boolean = false,
) {
  return (
    monthlyCache[
      projKeyOmitNulls(rowId, scenarioId, dateKey, isBalance ? "balance" : null, isTotal ? "total" : null)
    ] || null
  );
}

export function getFiltersForDepartment(departmentId: string, departmentsState: DepartmentsState) {
  const filters: Department["filters"] = [];

  function recursive(iteratorDeptId: string) {
    const department = departmentsState.entities[iteratorDeptId];
    if (!department) return;
    filters.push(...(department.filters ?? []));
    const children = Object.values(departmentsState.entities).filter((item) => item?.parent === department.id);
    for (const child of children) {
      if (!child) continue;
      recursive(child.id);
    }
  }

  recursive(departmentId);

  return filters;
}

/**
 * Given a row and a lookupKey, calculate the cache monthly value (not the total)
 *
 * @param row - The row for which the value needs to be crunched
 * @param lookupKey - The lookupKey for which the value needs to be crunched
 * @returns The value
 */
export function getValueForLookupKey(
  row: TemplateRow | undefined,
  departmentsState: DepartmentsState,
  lookupKey: string,
  cbTxState: Pick<CbTxState, "entities" | "idsByRowIdDateKey">,
  departmentId?: EntityId | null,
  vendor?: string | null,
): number | null {
  if (!row) return null;
  let value = 0;

  const cbTxIdsForLookupKey = cbTxState.idsByRowIdDateKey[lookupKey];
  if (!cbTxIdsForLookupKey?.length) return null;

  for (const cbTxId of cbTxIdsForLookupKey) {
    const cbTx = cbTxState.entities[cbTxId];

    if (
      !cbTx ||
      (departmentId && getDepartmentIdForCbTx(cbTx, departmentsState.idByQboClass) !== departmentId) ||
      (vendor && cbTx.tags.qbo_vendor_id !== vendor)
    ) {
      continue;
    }

    value += getAmountForDisplay({
      type: cbTx.amount_type,
      amount: cbTx.amount,
      classification: row.type === "account" ? row.options.classification : undefined,
      reverseSign: !!(row.type === "account" && row.options.reverseSign),
    });
  }

  return value;
}
