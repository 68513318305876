import {store} from "@app/client-store";
import {parseCacheKey} from "@shared/data-functions/cache/cache-utilities";
import {makeDependencyKeyHumanReadable} from "@shared/data-functions/cache/dependency-cache-debug-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";
import {selectQboIntegrationVendors} from "@state/integrations/slice";
import {createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import {useVirtualizer} from "@tanstack/react-virtual";
import {useRef, useState} from "react";

import type {AppReduxStore} from "@state/store";
import type {Row, SortingState} from "@tanstack/react-table";

const columnHelper = createColumnHelper<CacheKeyForDebugDisplay>();

const columns = [
  columnHelper.accessor("humanReadableKey", {header: "Resolved Key"}),
  columnHelper.accessor("cacheKey", {cell: "cacheKey", header: "Actual Key"}),
  columnHelper.accessor("value", {cell: (info) => formatNumber(info.getValue()), header: "Value"}),
  columnHelper.accessor("row", {cell: "row", header: "Row"}),
  columnHelper.accessor("department", {cell: "department", header: "Department"}),
  columnHelper.accessor("vendor", {cell: "vendor", header: "Vendor"}),
  columnHelper.accessor("scenario", {cell: "scenario", header: "Scenario"}),
  columnHelper.accessor("dateKey", {cell: "dateK“ey", header: "Date"}),
  columnHelper.accessor("balance", {cell: "balance", header: "Balance"}),
  columnHelper.accessor("total", {cell: "total", header: "Total"}),
];

export default function CacheDebugTools() {
  const cacheKeysForDebugging = getAllCacheKeysForDisplay(store) ?? [];

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: cacheKeysForDebugging,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {rows} = table.getRowModel();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 34,
    overscan: 20,
  });

  return (
    <div ref={parentRef} className="container">
      <div style={{height: `${virtualizer.getTotalSize()}px`}}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{width: header.getSize()}}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index] as Row<CacheKeyForDebugDisplay>;
              return (
                <tr
                  key={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export type CacheKeyForDebugDisplay = {
  row: string;
  department: string | null;
  template: string;
  vendor: string | null;
  scenario: string;
  dateKey: string;
  balance: boolean;
  total: boolean;
  cacheKey: string;
  value: number | null;
  humanReadableKey: string;
};
export function getAllCacheKeysForDisplay(store: AppReduxStore) {
  const state = store.getState();
  const monthlyCache = state.transactionItems.valuesByRowIdDateKey;
  const templateRowsState = state.templateRows;
  const templatesState = state.templates;
  const scenariosState = state.scenarios;
  const departments = state.departments;
  const vendorsMapping = selectQboIntegrationVendors(state);

  const cacheKeys: CacheKeyForDebugDisplay[] = [];

  for (const [key, value] of Object.entries(monthlyCache)) {
    const parsedKey = parseCacheKey(key);
    const row = templateRowsState.entities[parsedKey.rowId];
    const department = departments.entities[parsedKey.departmentId ?? ""];
    const scenario = scenariosState.entities[parsedKey.scenarioId];
    const vendor = vendorsMapping[parsedKey.vendor ?? ""];
    const template = templatesState.entities[row?.template_id ?? ""];
    const humanReadableKey = makeDependencyKeyHumanReadable(key, state, false, true);

    cacheKeys.push({
      row: row?.name ?? "",
      department: department?.name ?? null,
      template: template?.name ?? "",
      vendor: vendor?.displayName ?? null,
      scenario: scenario?.name ?? "",
      dateKey: parsedKey.dateKey,
      balance: parsedKey.balance ?? false,
      total: parsedKey.total ?? false,
      cacheKey: key,
      value,
      humanReadableKey,
    });
  }

  return cacheKeys;
}
