import {getMonthlyCacheDiff} from "@shared/data-functions/cache/cache-utilities";
import {buildDependencyCache} from "@shared/data-functions/cache/dependency-cache";
import {generateDisplayValuesCache} from "@shared/data-functions/cache/display-monthly-cache";
import {dispatchInMainThread} from "@shared/fn-gateway";
import {checkKeysForCircularDependencies} from "@shared/lib/alert-utilities";
import {time, timeEnd} from "@shared/lib/debug-provider";
import id from "@shared/lib/id";
import {ensureNotBrowser} from "@shared/lib/misc";
import {runSanityChecks} from "@shared/lib/sanity-check-utilities";
import {deleteAlerts, upsertAlerts, upsertAlertsForDs} from "@state/alerts/slice";
import {updateGlobalState} from "@state/global/slice";
import {fetchQboStatementRows} from "@state/integrations/slice";
import {createEffectHandler} from "@state/listeners-list";
import {setLastRun, upsertSanityChecks} from "@state/sanity-checks/slice";

import {
  applyDiffToMonthlyCache,
  clearMonthlyCacheForDateKeys,
  generateMonthlyCache,
  monthlyCacheUpdated,
} from "./slice";

import {generateAlgoValuesCache} from "@shared/data-functions/cache/algo-values-cache";
import type {SanityCheckFailingAlert} from "@shared/types/alerts";

export const generateMonthlyCacheListener = createEffectHandler(
  generateMonthlyCache,
  (action, {getState, dispatch}) => {
    ensureNotBrowser();
    time("GenerateMonthlyCache listener", "Generate monthly values cache");

    const state = getState();

    const globalDepartmentId = state.global.selectedDepartmentId;

    let algoCache: Record<string, number> = {};
    for (const scenarioId of state.scenarios.ids) {
      // const scenarioId = state.global.scenarioId;
      // if (!scenarioId) return;

      const dependencyCache = buildDependencyCache({
        scenarioId,
        state,
        rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
      });
      setTimeout(async () => {
        // while (!getState().global.fullCacheLoadDone) {
        //   console.log("Waiting for full cache load before running circular dependency check");
        //   await sleep(500);
        // }

        const checkResult = checkKeysForCircularDependencies({
          dependencyCache,
          state,
          cacheKeys: Object.keys(dependencyCache.cellToDependencies),
        });

        if (checkResult.upserts.length > 0) {
          dispatch(upsertAlertsForDs(checkResult.upserts));
        }

        if (checkResult.deletes.length > 0) {
          dispatch(deleteAlerts(checkResult.deletes.map(({id}) => id)));
        }
      }, 6000);

      state.global.dependencyCache[scenarioId] = dependencyCache;
      state.global.algoCache[scenarioId] =
        globalDepartmentId === null ? generateAlgoValuesCache(scenarioId, {getState, dispatch}) : {};
      algoCache = {...algoCache, ...state.global.algoCache[scenarioId]};
    }

    // const algoValuesCache =
    //   globalDepartmentId === null ? generateAlgoValuesCache(scenarioId, {getState, dispatch}) : {};

    const vendorLevelRowIdsDepartmentIds: {
      rowId: string;
      departmentId: string;
    }[] = [];
    for (const [rowId, departmentIds] of Object.entries(state.global.expandedVendors)) {
      for (const departmentId of departmentIds) {
        vendorLevelRowIdsDepartmentIds.push({rowId, departmentId});
      }
    }

    let displayCache = generateDisplayValuesCache({
      state,
      algoValuesCache: algoCache,
      globalDepartmentId,
      departmentLevelRowIds: state.global.departmentsExpandedRowIds,
      vendorLevelRowIdsDepartmentIds,
    });

    // logDependenciesTreeForKey(
    //   state.global.dependencyCache[state.scenarios.ids[0]],
    //   "expenses::Base-Case::marketing::2024-01",
    //   state,
    //   true,
    //   displayCache,
    //   5,
    // );

    // logDependenciesTreeForKey(
    //   state.global.dependencyCache[state.scenarios.ids[0]],
    //   "expenses::Base-Case::sales_marketing::2024-01",
    //   state,
    //   true,
    //   displayCache,
    //   5,
    // );

    // log("GenerateMonthlyCache listener", `Total generated cache size: ${JSON.stringify(displayCache).length / 1024} KB`);
    // log(
    //   "GenerateMonthlyCache listener",
    //   `Total generated cache diff size: ${JSON.stringify(displayCacheDiff).length / 1024} KB`,
    // );

    // console.log(`Generated cache for ${numberOfCacheEntries} entries, with ${numberOfDiffEntries} diff entries`);

    if (action.payload.sendToMainThread) {
      const numberOfCacheEntries = Object.keys(displayCache).length;
      const MAX_CACHE_ENTRIES = 15000;
      if (numberOfCacheEntries > MAX_CACHE_ENTRIES) {
        time(
          "GenerateMonthlyCache listener",
          `Computed the cacheDiff since the whole cache exceeds ${MAX_CACHE_ENTRIES} entries`,
        );
        const previousDisplayValuesCache = state.transactionItems.valuesByRowIdDateKey;
        const displayCacheDiff = getMonthlyCacheDiff(previousDisplayValuesCache, displayCache, state.scenarios.ids);
        timeEnd(
          "GenerateMonthlyCache listener",
          `Computed the cacheDiff since the whole cache exceeds ${MAX_CACHE_ENTRIES} entries`,
        );

        dispatchInMainThread(applyDiffToMonthlyCache({diff: displayCacheDiff}));
      } else {
        dispatchInMainThread(monthlyCacheUpdated({valuesByRowIdDateKey: displayCache}));
      }
    }

    state.transactionItems.valuesByRowIdDateKey = displayCache;
    if (action.payload.fullCache) dispatch(updateGlobalState({fullCacheLoadDone: true}));
    timeEnd("GenerateMonthlyCache listener", "Generate monthly values cache");

    // logDependenciesTreeForKey(
    //   state.global.dependencyCache[state.scenarios.ids[0]],
    //   "sales_marketing_spend::Base-Case::2024-01",
    //   state,
    //   true,
    //   algoValuesCache,
    //   6,
    // );

    // logDependenciesTreeForKey(
    //   state.global.dependencyCache[state.scenarios.ids[0]],
    //   "sales_marketing_spend::Base-Case::2024-01",
    //   state,
    //   true,
    //   displayCache,
    //   6,
    // );
  },
);

export const runSanityChecksOnCacheUpdate = createEffectHandler(
  [
    applyDiffToMonthlyCache,
    monthlyCacheUpdated,
    clearMonthlyCacheForDateKeys,
    fetchQboStatementRows.fulfilled,
  ] as const,
  (action, {getState, dispatch}) => {
    const state = getState();

    // Only run checks if the full cache has been loaded
    if (!state.global.fullCacheLoadDone) return;

    const checksBeforeRun = Object.values(state.sanityChecks.entities);

    const {sanityChecks, monthsFailingBySanityCheckId} = runSanityChecks({state});

    const sanityChecksToUpsert: typeof sanityChecks = [];

    // Handle alerts - failing sanity checks generate an alert of type "SANITY_CHECK_FAILING"
    const currentAlerts = Object.values(state.alerts.entities).filter(
      (alert) => alert?.type === "SANITY_CHECK_FAILING",
    ) as SanityCheckFailingAlert[];

    const alertsToUpsert: SanityCheckFailingAlert[] = [];
    const alertsToDelete: string[] = [];

    for (const check of sanityChecks) {
      // Check if the check is newly failing or passing
      let changeType: "newlyFailing" | "newlyPassing" | "unchanged" = "unchanged";
      const checkPreviousState =
        checksBeforeRun.find((c) => c?.id === check.id)?.passed === true ? "passing" : "failing";
      if (check.passed === false && checkPreviousState === "passing") {
        changeType = "newlyFailing";
      } else if (check.passed === true && checkPreviousState === "failing") {
        changeType = "newlyPassing";
      }

      // Mark the check as needing to be upserted if it's newly failing or passing
      if (changeType === "newlyFailing" || changeType === "newlyPassing") {
        sanityChecksToUpsert.push(check);
      }

      // Get the current alert for this check
      const existingAlert = currentAlerts.find((alert) => alert.sanity_check_id === check.id);

      // If the check is failing and there's no existing alert, create a new alert
      if (!existingAlert && check.passed === false) {
        alertsToUpsert.push({
          id: id(),
          type: "SANITY_CHECK_FAILING",
          sanity_check_id: check.id,
          details: {
            months: monthsFailingBySanityCheckId[check.id]?.toSorted((a, b) => a.localeCompare(b)) ?? [],
          },
          created_at: new Date().toISOString(),
        });
      } else if (changeType === "newlyPassing" && existingAlert) {
        // If the check is passing and there's an existing alert, delete the alert
        alertsToDelete.push(existingAlert.id);
      }
    }

    if (alertsToUpsert.length > 0) dispatch(upsertAlerts(alertsToUpsert));
    if (alertsToDelete.length > 0) dispatch(deleteAlerts(alertsToDelete));

    if (sanityChecksToUpsert.length > 0) dispatch(upsertSanityChecks(sanityChecksToUpsert));

    if (!state.sanityChecks.lastRun) {
      dispatch(setLastRun(Date.now()));
    }
  },
);
