import {store} from "@app/client-store";
import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import {selectors} from "@features/templates/state/selectors";
import {toggleExpanded} from "@features/templates/state/thunks";
import {findDatasourcesToRemoveAfterForecastTypeChange, getDatasourceDiff} from "@shared/lib/datasource-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {fireActionInWorker} from "@shared/worker-fn-gateway";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {selectVersionLocked} from "@state/global/slice";
import {upsertRow, upsertRowsLocal} from "@state/template-rows/slice";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import type {Datasource} from "@shared/types/datasources";
import type {AccountTemplateRow, Template} from "@shared/types/db";

export const forecastTypeDropdownItems = [
  {
    key: "row",
    value: "Account",
    iconLeft: "editPen",
    isVisible: (departmentId: string | null) => !departmentId,
  },
  {
    key: "department",
    value: "Department",
    iconLeft: "department",
    isVisible: () => true,
  },
  {
    key: "vendor",
    value: "Vendor",
    iconLeft: "vendor",
    isVisible: () => true,
  },
] as const;

export type ForecastType = (typeof forecastTypeDropdownItems)[number]["key"];

export default function ForecastType({
  row,
  forecastType,
  template,
}: {
  row: AccountTemplateRow;
  forecastType: ForecastType;
  template: Template;
}) {
  const isVersionLocked = useAppSelector(selectVersionLocked);
  const dispatch = useAppThunkDispatch();

  const {departmentId} = useAppSelector(selectors.activeCell);

  const disabled = row.options.type === "Bank" || template.name === "cash_flow_statement";

  const rowOrAccount = row.type === "account" ? "account" : "row";

  const dropdownItems = forecastTypeDropdownItems.filter((item) => item.isVisible(departmentId ?? null));

  const handleForecastTypeChange = (item: (typeof forecastTypeDropdownItems)[number]) => {
    const previouslySelectedForecastType = row.options.forecastType;
    const state = store.getState();
    let datasourcesToRemove: Datasource[] = [];
    let newRowOptions: AccountTemplateRow["options"] = {...row.options};
    if (item.key === "vendor") {
      const newVendorLevelDepartments = !departmentId
        ? (state.departments.ids as string[])
        : [...(row.options.vendorLevelDepartments ?? []), departmentId];

      newRowOptions.forecastType = "department";
      newRowOptions.vendorLevelDepartments = newVendorLevelDepartments;
    } else {
      newRowOptions = {...row.options, forecastType: item.key};
      if (departmentId) {
        const newVendorLevelDepartments = (newRowOptions.vendorLevelDepartments ?? []).filter(
          (deptId) => deptId !== departmentId,
        );
        if (newVendorLevelDepartments.length === 0) {
          delete newRowOptions.vendorLevelDepartments;
        } else {
          newRowOptions.vendorLevelDepartments = newVendorLevelDepartments;
        }
      } else {
        delete newRowOptions.vendorLevelDepartments;
      }
    }

    datasourcesToRemove = findDatasourcesToRemoveAfterForecastTypeChange(state, row.id, departmentId ?? null, item.key);

    const datasourcesForRow = mapEntitiesToIds(state.datasources.entities, state.datasources.idsByRowId[row.id]);

    const datasourceDiff = getDatasourceDiff(
      datasourcesForRow,
      datasourcesForRow.filter((datasource) => !datasourcesToRemove.includes(datasource)),
      state,
    );

    const dispatchAction = () => {
      dispatch(upsertRowsLocal([{...row, options: newRowOptions}]));

      // Log the event
      logUserEvent("forecast-type-change", {
        rowId: row.id,
        oldForecastType: previouslySelectedForecastType,
        newForecastType: item.key,
        departmentId,
      });

      if (item.key === "vendor") {
        dispatch(
          toggleExpanded({
            rowId: row.id,
            departmentId: !departmentId ? (state.departments.ids as string[]) : departmentId,
            forceState: true,
          }),
        );
      } else if (item.key === "department") {
        dispatch(toggleExpanded({rowId: row.id, departmentId: null, forceState: true}));
      } else {
        dispatch(toggleExpanded({rowId: row.id, forceState: false}));
      }

      if (datasourcesToRemove.length > 0) {
        setTimeout(async () => {
          dispatch(
            applyDatasourceChanges({
              datasourceDiff,
              reason: `Forecast granularity changed to ${item.key}-level for ${rowOrAccount} ${row.name}`,
            }),
          );
        }, 1);
      }
      fireActionInWorker(upsertRow({...row, options: newRowOptions}));
    };

    let text = "";
    switch (item.key) {
      case "row": {
        text = `This ${rowOrAccount} currently has department-level and / or vendor-level forecasts.`;
        break;
      }
      case "department": {
        text = `This row currently has ${
          previouslySelectedForecastType === "row" ? rowOrAccount : "vendor"
        }-level forecasts.`;
        break;
      }
      case "vendor": {
        if (previouslySelectedForecastType === "row") {
          `This ${rowOrAccount} currently has a ${rowOrAccount}-level forecast.`;
        } else {
          `This ${rowOrAccount} currently has a department-level forecast.`;
        }
        break;
      }
    }

    if (datasourcesToRemove.length > 0) {
      dispatch(
        askForConfirmation({
          onConfirm: () => {
            dispatchAction();
          },
          backgroundImage: "/assets/images/ui/modals/notifs/blue-default.svg",
          mainColor: "blue",
          actionButtonText: "Confirm",
          text: `${text}<br /><br />
          In order to change the forecast type to be at the <b>${item.key}-level</b>, these existing forecasts will need to be deleted.`,
        }),
      );
    } else {
      dispatchAction();
    }
  };

  return (
    <FormElement
      label="Forecast Granularity"
      tooltip={
        departmentId
          ? "Templates::Sidebar::FormulaBuilder::ForecastGranularityDepartment"
          : "Templates::Sidebar::FormulaBuilder::ForecastGranularityAccount"
      }
    >
      <Dropdown
        disabled={isVersionLocked || disabled}
        items={dropdownItems}
        onSelect={handleForecastTypeChange}
        selectedKey={forecastType}
      />
    </FormElement>
  );
}
