// Migrated to uuid. Might just get rid of this if it doesn't become more complex.

import {customAlphabet} from "nanoid";
import {v4} from "uuid";

export default function getUniqueId(): string {
  return v4();
}

const nanoidLengthMapping: Record<string, ReturnType<typeof customAlphabet>> = {};
export function getShortId(length = 12) {
  const nanoid =
    nanoidLengthMapping[length] ??
    (nanoidLengthMapping[length] = customAlphabet("23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz", length));
  return nanoid();
}
