import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getVersionsAdapter} from "@shared/state/entity-adapters";
import {createSyncedActionCreators} from "@shared/lib/misc";

import versionsAPI from "./api";

import type {RootState} from "@state/store";

export const api = {
  fetchAll: createAsyncThunk("versions/fetchAll", versionsAPI.fetchAll),
  createVersion: createAsyncThunk("versions/create", versionsAPI.create),
};

export const getSlice = () => {
  const versionsAdapter = getVersionsAdapter();
  return createSlice({
    name: "versions",
    initialState: versionsAdapter.getInitialState(),
    reducers: {
      upsertVersions: versionsAdapter.upsertMany,
      upsertVersionsLocal: versionsAdapter.upsertMany,
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchAll.fulfilled, versionsAdapter.setAll);
    },
  });
};

const _slice = getSlice();
export const {upsertVersionsLocal} = _slice.actions;
export const {upsertVersions} = createSyncedActionCreators(_slice.actions);

export const {
  selectById: selectVersionById,
  selectIds: selectVersionIds,
  selectEntities: selectVersionEntities,
  selectAll: selectAllVersions,
  selectTotal: selectTotalVersions,
} = getVersionsAdapter().getSelectors((state: RootState) => state.versions);
