import type {FormulaSuggestion} from "./Suggestions";

// export type FormulaSuggestion = {
//   displayName: string;
//   slug: string;
//   rightText?: string;
//   subtext?: string;
//   type: "row" | "department" | "vendor" | "formulaFunction" | "range"
// };

export const rangeSuggestions: FormulaSuggestion[] = [
  // {
  //   slug: "last_month",
  //   subtext: "last_month",
  //   displayName: "Last Month",
  //   type: "range",
  // },
  // {
  //   slug: "this_month",
  //   subtext: "this_month",
  //   displayName: "This Month",
  //   type: "range",
  // },
];

for (let i = 0; i < 24; i++) {
  const month = i + 1;
  rangeSuggestions.push(
    {
      slug: `-${month}`,
      subtext: `-${month}`,
      displayName: `${month} month${month > 1 ? "s" : ""} ago`,
      type: "range",
    },
    {
      slug: `-${month}a`,
      subtext: `-${month}a`,
      displayName: `${month} month${month > 1 ? "s" : ""} ago (Locked to Actuals)`,
      type: "range",
    },
  );
}
