import {createEffectHandler} from "../listeners-list";
import {api as reportsApi} from "../reports/slice";
import {
  api,
  multipleLayoutsUpdated,
  reportItemAdded,
  reportItemDeleted,
  reportItemUpdated,
  upsertReportItem,
} from "./slice";

export const syncReportItemUpsertsToAPI = createEffectHandler(
  [reportItemAdded, reportItemUpdated, upsertReportItem],
  (action, {getState, dispatch}) => {
    const reportItem = getState().reportItems.entities[action.payload.id];

    if (reportItem)
      dispatch(
        api.upsertReportItem({
          reportItem,
          refetchCache: !!("refetchCache" in action.payload && action.payload.refetchCache),
        }),
      );
  },
);

export const syncReportItemDeletesToAPI = createEffectHandler(reportItemDeleted, (action, {getState, dispatch}) => {
  dispatch(api.deleteReportItem(action.payload.id));
});

export const syncMultipleItemLayoutsToAPI = createEffectHandler(
  multipleLayoutsUpdated,
  (action, {getState, dispatch}) => {
    dispatch(api.updateMultipleItemLayouts(action.payload));
  },
);

export const refetchMonthlyCacheAfterUpsertIfNecessary = createEffectHandler(
  api.upsertReportItem.fulfilled,
  (action, {getState, dispatch}) => {
    const typedAction = action as ReturnType<typeof api.upsertReportItem.fulfilled>;
    if (typedAction.meta.arg.refetchCache) {
      dispatch<any>(reportsApi.fetchReportItemMontlyCache(typedAction.payload.reportItem.id));
    }
  },
);
