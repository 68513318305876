import {createUseStyles} from "react-jss";
import colors from "@app/styles/colors";

export const sharedValues = {};

export default createUseStyles({
  compensationMain: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  futureChange: {
    marginBottom: 15,
  },
  row: {width: "100%"},

  buttonSection: {
    display: "inline-flex",
    paddingBottom: 10,
  },

  noCompensationText: {
    paddingTop: 25,
    paddingBottom: 20,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 20,
    color: colors.text.lightBlue01,
  },

  noCompensationRange: {
    fontWeight: 500,
    color: colors.text.lightBlue01,
    fontSize: 13,
    paddingBottom: 10,
    lineHeight: 20,
  },

  newCompRangeWrapper: {
    paddingBottom: 25,
  },

  deleteRangeButton: {
    marginTop: 15,
    marginRight: -5,
  },

  removeRangeIconWrapper: {
    flex: 0,
    marginTop: -3,
  },

  compensationDate: {
    "&&& > div": {
      marginRight: 0,
    },
  },

  dateContainer: {
    "& input": {
      maxWidth: 75,
    },
  },

  filler: {
    flex: 1,
  },
  rangeWrapper: {display: "flex", paddingBottom: 25},
});
