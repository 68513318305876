import {DefaultDropdownTrigger} from "@components/Dropdown";
import useDropdownStyles from "@components/Dropdown/styles.jss";
import {WithFloatingElementOnClick} from "@components/Floating";
import RoundButton from "@components/RoundButton";
import {useFloating, useInteractions, useListNavigation} from "@floating-ui/react";
import clsx from "clsx";
import dayjs from "dayjs";
import React, {useEffect, useRef, useState} from "react";

import useStyles from "./MonthPicker.jss";

interface MonthPickerProps {
  onChange: (newValue: string) => void;
  value?: string | null; // format YYYY-MM
  max?: string | null; // format YYYY-MM
  min?: string | null; // format YYYY-MM
  disabled?: boolean;
  children?: React.ReactNode;
  portal?: boolean;
  closeOnClick?: boolean;
}

const months = Array.from({length: 12}, (_, i) => new Date(0, i).toLocaleString("default", {month: "short"}));

const MonthPickerInner: React.FC<MonthPickerProps> = ({onChange, value, max, min}) => {
  const selectedValueIsValid = !!value && /^\d{4}-\d{2}$/.test(value);
  const cleanedSelectedValue = selectedValueIsValid ? value : null;
  const [currentlySelectedYear, currentlySelectedMonth] = !cleanedSelectedValue
    ? [null, null]
    : cleanedSelectedValue.split("-");
  const currentlySelectedMonthIndex = currentlySelectedMonth !== null ? parseInt(currentlySelectedMonth) - 1 : null;

  const [currentYear, setCurrentYear] = useState<number>(
    currentlySelectedYear ? parseInt(currentlySelectedYear) : dayjs().year(),
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const styles = useStyles();

  // If the selectedValue changes, update the currentYear
  useEffect(() => {
    setCurrentYear(currentlySelectedYear ? parseInt(currentlySelectedYear) : dayjs().year());
  }, [cleanedSelectedValue, currentlySelectedYear]);

  const {refs, floatingStyles, context} = useFloating({open: true});
  const listNavigation = useListNavigation(context, {listRef, activeIndex, onNavigate: setActiveIndex, cols: 4});

  const {getFloatingProps, getItemProps} = useInteractions([listNavigation]);

  const handleSelectMonth = (monthIndex: number) => {
    const monthNumberStr = (monthIndex + 1).toString().padStart(2, "0");
    onChange(`${currentYear}-${monthNumberStr}`);
  };

  const handleChangeYear =
    (increment: number): React.MouseEventHandler =>
    (evt) => {
      evt.stopPropagation();
      setCurrentYear(currentYear + increment);
    };

  return (
    <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} className={styles.monthPickerMain}>
      <div className={styles.header}>
        <RoundButton
          onClick={handleChangeYear(-1)}
          icon="arrow"
          enableCssStates
          disabled={isYearChangeArrowDisabled(currentYear, "prev", min, max)}
        />
        <span className={styles.currentYear}>{currentYear}</span>
        <RoundButton
          onClick={handleChangeYear(1)}
          icon="arrow"
          rotate={180}
          enableCssStates
          disabled={isYearChangeArrowDisabled(currentYear, "next", min, max)}
        />
      </div>
      <div className={styles.monthsContainer}>
        {months.map((month, index) => (
          <div
            className={clsx(styles.monthContainer, {
              [styles.disabled]: isMonthDisabled(`${currentYear}-${(index + 1).toString().padStart(2, "0")}`, min, max),
              [styles.selected]:
                currentlySelectedYear === currentYear.toString() && currentlySelectedMonthIndex === index,
            })}
            key={month}
            tabIndex={activeIndex === index ? 0 : -1}
            ref={(node) => {
              listRef.current[index] = node;
            }}
            {...getItemProps({
              onClick: () => handleSelectMonth(index),
              onFocus: () => setActiveIndex(index),
            })}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};

const MonthPicker: React.FC<MonthPickerProps> = (props) => {
  const styles = useStyles();
  const formattedMonth = props.value ? dayjs(props.value).format("MMMM YYYY") : "Select Month";
  const {triggerOpen} = useDropdownStyles();

  return (
    <WithFloatingElementOnClick
      triggerClasses={styles.triggerWrapper}
      content={() => <MonthPickerInner {...props} />}
      matchTriggerWidth
      isOpenClassName={triggerOpen}
      portal={props.portal}
      closeOnClick={props.closeOnClick}
    >
      {props.children ? (
        <>{props.children}</>
      ) : (
        <DefaultDropdownTrigger text={formattedMonth} iconLeft="calendar" disabled={props.disabled} />
      )}
    </WithFloatingElementOnClick>
  );
};

function isYearChangeArrowDisabled(
  currentYear: number,
  direction: "prev" | "next",
  min?: string | null,
  max?: string | null,
) {
  if (direction === "prev") {
    return min ? currentYear - 1 < parseInt(min.split("-")[0]) : false;
  } else {
    return max ? currentYear + 1 > parseInt(max.split("-")[0]) : false;
  }
}

function isMonthDisabled(month: string, min?: string | null, max?: string | null) {
  if (min && max) {
    return month < min || month > max;
  } else if (min) {
    return month < min;
  } else if (max) {
    return month > max;
  } else {
    return false;
  }
}

export default MonthPicker;
