import {useState} from "react";
import React from "react";
import clsx from "clsx";
import SVGIcon from "@components/SVGIcon";
import RoundButton from "@components/RoundButton";
import SearchBar from "@components/SearchBar";

import useStyles from "./styles.jss";

import type {IconNames} from "@components/SVGIcon";

export interface SectionProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
  collapsed?: boolean;
  summary?: string;
  wrapContent?: boolean;
  contentOnly?: boolean;
  rightButtonIcon?: IconNames;
  rightButtonDisabled?: boolean;
  rightButtonClickHandler?: () => void;
  searchText?: string;
  onSearchChange?: (searchStr: string) => void;
}

export default function Section({
  title,
  children,
  className,
  collapsed = false,
  summary,
  wrapContent = true,
  contentOnly = false,
  rightButtonIcon,
  rightButtonClickHandler,
  rightButtonDisabled,
  onSearchChange,
  searchText,
}: SectionProps) {
  const styles = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const classes = clsx(className, styles.sidebarSectionMain, {
    [styles.collapsed]: isCollapsed,
  });

  const rightButtonHandlerWrapper: React.MouseEventHandler = (evt) => {
    evt.stopPropagation();
    if (rightButtonClickHandler) rightButtonClickHandler();
  };

  return (
    <div className={classes}>
      {!contentOnly ? (
        <div className={styles.title} onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className={styles.iconWrapper}>
            <SVGIcon name="dropdown" />
          </div>
          <div className={styles.titleText}>{title}</div>
          {!!onSearchChange ? (
            <div className={styles.searchWrapper}>
              <SearchBar alwaysVisible={false} onSearchChange={onSearchChange} value={searchText || ""} />
            </div>
          ) : null}
          <div className={styles.flexFiller} />
          {!!summary && isCollapsed ? <div className={styles.summary}>{summary}</div> : null}
          {!isCollapsed && rightButtonIcon ? (
            <div className={styles.rightButton}>
              <RoundButton
                icon={rightButtonIcon}
                onClick={rightButtonHandlerWrapper}
                disabled={rightButtonDisabled}
                enableCssStates
                iconSize={26}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {!isCollapsed ? wrapContent ? <SectionContent>{children}</SectionContent> : children : null}
    </div>
  );
}

export function SectionContent({children}: {children?: React.ReactNode}) {
  const styles = useStyles();
  return <div className={styles.content}>{children}</div>;
}

export function SectionCol({
  children,
  small = false,
  className,
}: {
  children?: React.ReactNode;
  small?: boolean;
  className?: string;
}) {
  const styles = useStyles();

  const classes = clsx(className, styles.sectionCol, {[styles.small]: small});

  return (
    <div className={classes}>
      <div className={styles.sectionColInnerContainer}>{children}</div>
    </div>
  );
}

export function FullWidthContent({children, className}: {children: React.ReactNode; className?: string}) {
  const styles = useStyles();

  const classes = clsx(className, styles.fullWidthContent);

  return <div className={classes}>{children}</div>;
}
