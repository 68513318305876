import {createUseStyles} from "react-jss";

export default createUseStyles({
  activeCellDebug: {
    "& pre": {
      background: "#f4f4f4",
      border: "1px solid #ddd",
      borderRadius: 5,
      padding: 10,
    },
    "& strong": {
      display: "block",
      fontSize: 14,
      marginBottom: 5,
      fontWeight: 600,
    },
    "& h2": {
      marginBottom: 20,

      "& > span": {
        fontWeight: 500,
        fontSize: 13,
      },
    },
  },
  debugPanelItem: {
    marginBottom: 25,
  },
  debugPanelItemTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,

    "& > strong": {
      alignSelf: "center",
      marginRight: 10,
      display: "inline-flex",
      alignItems: "center",
    },
  },
});
