import { Suspense } from "react";
import * as React from "react";
import clsx from "clsx";

const MotionDiv = React.lazy(async () => ({default: (await import("framer-motion")).motion.div}));

import useStyles from "./styles.jss";

export type Tab = {
  readonly name: string;
  readonly value: string;
  readonly disabled?: boolean;
};

export type TabsProps<T> = {
  readonly tabs: T[];
  readonly selectedTab: T;
  readonly onChange: (newTab: T) => void;
};

export default function Tabs<T extends Tab>({tabs, selectedTab, onChange}: TabsProps<T>) {
  const styles = useStyles();

  const handleTabClick = (tab: T) => (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (tab.disabled || tab === selectedTab) return;
    onChange(tab);
  };

  return (
    (<div className={styles.tabsMain}>
      {tabs.map((tab) => (
        <div
          className={clsx(styles.tab, {
            [styles.active]: tab.name === selectedTab.name,
            [styles.disabled]: tab.disabled,
          })}
          key={tab.name}
          onClick={handleTabClick(tab)}
        >
          <Suspense>
            <span>{tab.value}</span>
            {tab.name === selectedTab.name ? (
              <MotionDiv
                className={styles.activeTabMarker}
                transition={{type: "spring", duration: 0.35, bounce: 0.31}}
                layoutId="underline"
              />
            ) : null}
          </Suspense>
          <div className={styles.tabBorderBottom} />
        </div>
      ))}
    </div>)
  );
}
