// Ported from Redux Toolkit createAdapter
import type {Dictionary, EntityId, EntityState, Update} from "@reduxjs/toolkit";

type EntityWithId = {id: string | number};

export type RemoveEntitiesActionPayload = EntityId | EntityId[] | EntityWithId | EntityWithId[];

export function getIdsFromPayload<T extends RemoveEntitiesActionPayload>(payload: T): EntityId[] {
  if (Array.isArray(payload)) {
    return payload.map((entity) => (typeof entity === "object" ? entity.id : entity));
  }
  return [typeof payload === "object" ? payload.id : payload];
}

export function mapEntitiesToIds<T>(entities: Dictionary<T>, ids: EntityId[] | undefined): T[] {
  if (!ids) return [];
  const mapping: T[] = [];
  for (const entityId of ids) {
    if (entities[entityId]) mapping.push(entities[entityId] as T);
  }
  return mapping;
}

export function ensureEntitiesArray<T>(entities: readonly T[] | Record<EntityId, T>): readonly T[] {
  if (!Array.isArray(entities)) {
    entities = Object.values(entities);
  }

  return entities;
}

export function splitAddedUpdatedEntities<T extends EntityWithId>(
  newEntities: readonly T[] | Record<EntityId, T>,
  state: EntityState<T>,
): [T[], Update<T>[]] {
  newEntities = ensureEntitiesArray(newEntities);

  const added: T[] = [];
  const updated: Update<T>[] = [];

  for (const entity of newEntities) {
    const id = entity.id;
    if (id in state.entities) {
      updated.push({id, changes: entity});
    } else {
      added.push(entity);
    }
  }
  return [added, updated];
}

export function addOne<T extends EntityWithId>(entity: T, state: EntityState<T>): void {
  const key = entity.id;

  if (key in state.entities) {
    return;
  }

  state.ids.push(key);
  state.entities[key] = entity;
}

export function addMany<T extends EntityWithId>(
  newEntities: readonly T[] | Record<EntityId, T>,
  state: EntityState<T>,
): void {
  newEntities = ensureEntitiesArray(newEntities);

  for (const entity of newEntities) {
    addOne<T>(entity, state);
  }
}

export function setOne<T extends EntityWithId>(entity: T, state: EntityState<T>): void {
  const key = entity.id;
  if (!(key in state.entities)) {
    state.ids.push(key);
  }
  state.entities[key] = entity;
}

export function setMany<T extends EntityWithId>(
  newEntities: readonly T[] | Record<EntityId, T>,
  state: EntityState<T>,
): void {
  newEntities = ensureEntitiesArray(newEntities);
  for (const entity of newEntities) {
    setOne<T>(entity, state);
  }
}

export function setAll<T extends EntityWithId>(
  newEntities: readonly T[] | Record<EntityId, T>,
  state: EntityState<T>,
): void {
  newEntities = ensureEntitiesArray(newEntities);

  state.ids = [];
  state.entities = {};

  addMany<T>(newEntities, state);
}

export function removeOne<T extends EntityWithId>(key: EntityId, state: EntityState<T>): void {
  return removeMany<T>(state, [key]);
}

export function removeMany<T extends EntityWithId>(state: EntityState<T>, keys: readonly EntityId[]): void {
  let didMutate = false;

  for (const key of keys) {
    if (key in state.entities) {
      delete state.entities[key];
      didMutate = true;
    }
  }

  if (didMutate) {
    state.ids = state.ids.filter((id: string | number) => id in state.entities);
  }
}

export function removeAll<T extends EntityWithId>(state: EntityState<T>): void {
  Object.assign(state, {
    ids: [],
    entities: {},
  });
}

export function takeNewKey<T extends EntityWithId>(
  keys: {[id: string]: EntityId},
  update: Update<T>,
  state: EntityState<T>,
): boolean {
  const original = state.entities[update.id];
  if (!original) {
    // Handle the case where the original entity is not found
    return false;
  }

  // Using type assertion to assure TypeScript that the type of 'updated' is 'T'
  const updated = {...original, ...update.changes} as T;

  const newKey = updated.id;
  const hasNewKey = newKey !== update.id;

  if (hasNewKey) {
    keys[update.id] = newKey;
    delete state.entities[update.id];
  }

  state.entities[newKey] = updated;

  return hasNewKey;
}

export function updateOne<T extends EntityWithId>(update: Update<T>, state: EntityState<T>): void {
  return updateMany<T>([update], state);
}

export function updateMany<T extends EntityWithId>(updates: ReadonlyArray<Update<T>>, state: EntityState<T>): void {
  const newKeys: {[id: string]: EntityId} = {};

  const updatesPerEntity: {[id: string]: Update<T>} = {};

  for (const update of updates) {
    // Only apply updates to entities that currently exist
    if (update.id in state.entities) {
      // If there are multiple updates to one entity, merge them together
      updatesPerEntity[update.id] = {
        id: update.id,
        // Spreads ignore falsy values, so this works even if there isn't
        // an existing update already at this key
        changes: {
          ...(updatesPerEntity[update.id] ? updatesPerEntity[update.id].changes : null),
          ...update.changes,
        },
      };
    }
  }

  updates = Object.values(updatesPerEntity);

  const didMutateEntities = updates.length > 0;

  if (didMutateEntities) {
    const didMutateIds = updates.some((update) => takeNewKey(newKeys, update, state));

    if (didMutateIds) {
      state.ids = state.ids.map((id: string | number) => newKeys[id] || id);
    }
  }
}

export function upsertOne<T extends EntityWithId>(state: EntityState<T>, entity: T): void {
  return upsertMany<T>(state, [entity]);
}

export function upsertMany<T extends EntityWithId>(
  state: EntityState<T>,
  newEntities: readonly T[] /* | Record<EntityId, T>*/,
): void {
  const [added, updated] = splitAddedUpdatedEntities<T>(newEntities, state);

  updateMany<T>(updated, state);
  addMany<T>(added, state);
}
