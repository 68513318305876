import {useAppSelector} from "@app/hooks";
import {MonthSelector, YearSelector} from "@components/Datepicker";
import FormElement from "@components/FormElement";
import {SectionContent} from "@components/RightSidebar/Section";
import Tag from "@components/Tag";
import {selectAllIntegrations} from "@shared/state/integrations/slice";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {QBOIntegration, Template} from "@shared/types/db";

export interface ActualsSyncTabProps {
  template: Template;
}

export default function ActualsSyncTab({template}: ActualsSyncTabProps) {
  const styles = useStyles();
  const classes = clsx(styles.actualsSyncTabMain);

  const integrations = useAppSelector(selectAllIntegrations);

  const integration = integrations[0] as QBOIntegration; // TODO: make this dynamic

  if (!integration) return null;

  return (
    <div className={classes}>
      <div className={styles.tag}>
        <Tag color="green" text={integration.data.companyName || "Unknown Company"} iconLeft="qbLogo" />
      </div>
      <FormElement label="Actuals End">
        <SectionContent>
          <div className={styles.actualsMonthSelector}>
            <MonthSelector
              buttonFill
              onChange={() => null}
              selectedKey={template.options.lastMonthOfActuals.split("-")[1]}
            />
          </div>
          <YearSelector onChange={() => null} selectedKey={template.options.lastMonthOfActuals.split("-")[0]} />
        </SectionContent>
      </FormElement>
      <FormElement label="Data Sync">
        <span>All Available Data</span>
      </FormElement>
    </div>
  );
}
