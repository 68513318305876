import api from "@shared/lib/fetch";

import type {EntityId} from "@reduxjs/toolkit";
import type {ApiEndpoints} from "@shared/state/store";
import type {CbTx, IntegrationTx} from "@shared/types/db";

const endpoints = {
  fetchAllCbTxTransactions(_arg, thunkAPI) {
    return api.get<CbTx[]>(thunkAPI, "transactions", {entity: "cb_tx", source: "formula,hiring_plan,manual,null"});
  },
  fetchMonthlyCache(department: string | null, thunkAPI) {
    const queryParams: Record<string, string> = {};
    if (department) queryParams.department = encodeURIComponent(department);
    return api.get<{monthlyCache: Record<string, number>}>(thunkAPI, "transactions/monthly-cache", queryParams);
  },
  fetchTxForDisplay(
    {
      from,
      to,
      rowId,
      scenarioId,
      departmentId,
      vendor,
    }: {
      rowId: string;
      from: string;
      to: string;
      scenarioId: string;
      departmentId?: EntityId | null;
      vendor?: string | null;
    },
    thunkAPI,
  ) {
    const params: Record<string, EntityId> = {from, to, scenarioId, rowId};
    if (departmentId) params.departmentId = departmentId;
    if (vendor) params.vendor = vendor;

    return api.get<{integrationTx: IntegrationTx[]; cbTx: CbTx[]}>(thunkAPI, `transactions/for-display`, params);
  },
  upsert(txItems: CbTx[], thunkAPI) {
    // Remove duplicates. Make sure to handle it properly in the eventuality values differ one day
    const found: Record<string, CbTx> = {};
    let duplicatesFound = false;
    for (const tx of txItems) {
      // eslint-disable-next-line no-console
      if (found[tx.id]) {
        // console.log("WARNING: duplicate txItems found in upsert payload:", tx, found[tx.id]);
        duplicatesFound = true;
      }
      found[tx.id] = tx;
    }
    if (duplicatesFound) console.log(txItems.sort((a, b) => a.id.localeCompare(b.id)).slice(0, 10));
    if (duplicatesFound) console.log(`WARNING: duplicate txItems found in upsert payload`);
    return api.put<CbTx[]>(thunkAPI, `transactions`, txItems);
  },
  deleteCbTx(txItems: CbTx[] | string[], thunkAPI) {
    return api.del<CbTx[]>(
      thunkAPI,
      `transactions/items`,
      txItems.map((txItem) => (typeof txItem === "string" ? txItem : txItem.id)),
    );
  },
} satisfies ApiEndpoints;

export default endpoints;
