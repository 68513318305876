import {
  getDatasourceOptionsForNewFc,
  getDefaultFcDatasourceForTeam,
} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import id from "@shared/lib/id";
import dayjs from "dayjs";

import {getFormattedFullDate} from "./date-utilities";
import {getUniqueSlug} from "./slug";

import type {Dictionary} from "@reduxjs/toolkit";
import type {HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {HiringPlanTemplateRow, Scenario, Template, TemplateOptions, TemplateRow} from "@shared/types/db";
import type {Employee, FinancialComponent, Team} from "@shared/types/hiring-plan";

export const getEmployeeDisplayName = (employee?: Employee) => {
  if (!employee) return null;

  const nameComponents: string[] = [];

  if (employee.first_name?.length) nameComponents.push(employee.first_name);
  if (employee.middle_name?.length) nameComponents.push(employee.middle_name);
  if (employee.last_name?.length) nameComponents.push(employee.last_name);

  return nameComponents.length ? nameComponents.join(" ") : null;
};

export function getSalaryComponent(
  fcRows: (TemplateRow | undefined)[],
  financialComponents: Dictionary<FinancialComponent>,
) {
  for (const row of fcRows) {
    if (row?.type !== "hiring-plan") continue;
    const fp = financialComponents[row.options.fc_name];
    if (!fp) continue;

    if (fp.type === "salary") return row;
  }
}

export function generateNewFcRow(
  employee: Employee,
  template: Template,
  baseFc: FinancialComponent,
  team: Team | null,
  scenarios: Scenario[],
  scenarioId: string,
  rowsByName: Record<string, any>,
) {
  const rowId = id();
  const employeeName = getEmployeeDisplayName(employee) || "Unnamed Employee";
  const displayName = `${employeeName} - ${baseFc.display_name}`;
  const datasources: HiringPlanFormulaDatasource[] = [];
  for (const scenario of scenarios) {
    const hireDate = employee.scenario_properties[scenario.id]?.hire_date;
    const termDate = employee.scenario_properties[scenario.id]?.term_date;
    if (hireDate && scenario.id === scenarioId)
      datasources.push(getDefaultFcDatasourceForTeam(team, baseFc, rowId, scenarioId, employee.id, hireDate, termDate));
  }

  const newRow: HiringPlanTemplateRow = {
    id: rowId,
    display_name: displayName,
    formatting: {},
    name: getUniqueSlug(displayName, rowsByName).slug,
    type: "hiring-plan",
    template_id: template.id,
    options: {
      employee_id: employee.id,
      fc_name: baseFc.name,
      // payment_debit_row:
      //   team?.defaults.row_mappings?.[baseFc.name]?.payment_debit || baseFc.row_mappings?.payment_debit,
    },
    tags: {team: team?.name || "unassigned", compensation_type: baseFc.type},
  };

  return {row: newRow, datasources, template: {...template, ordering: [...template.ordering, {rowId: newRow.id}]}};
}

export function addNewFcDatasource(
  fcRowId: string,
  scenarioId: string,
  departmentId: string | null,
  employeeId: string,
  originalDatasources: HiringPlanFormulaDatasource[],
  hireDate: string,
  termDate: string | null | undefined,
  templateOptions: TemplateOptions,
  fc: FinancialComponent,
): HiringPlanFormulaDatasource[] {
  // console.log(JSON.stringify([originalRanges, hireDate, termDate, templateOptions, fc]));
  const sortedDatasources = originalDatasources.toSorted((a, b) =>
    (a.start || "0000-00-00").localeCompare(b.start || "0000-00-00"),
  );
  const lastDatasource = sortedDatasources.at(-1);

  if (!sortedDatasources?.length || !lastDatasource) {
    return [
      {
        id: id(),
        row_id: fcRowId,
        scenario_id: scenarioId,
        department_id: departmentId,
        type: "hiring-plan-formula",
        ...getDatasourceOptionsForNewFc(fc, employeeId, hireDate, termDate),
      },
    ];
  }

  const lastDatasourceStart = lastDatasource.start || hireDate;

  // As a default, we'll start the new datasource one year after the last datasource
  let newDatasourceStart = dayjs(lastDatasourceStart).add(1, "year").format("YYYY-MM-DD");

  // If the last datasource starts after newDatasourceStart, we'll start the new datasource one year after the last datasource start
  if (newDatasourceStart < lastDatasourceStart) {
    newDatasourceStart = dayjs(lastDatasourceStart).add(1, "year").format("YYYY-MM-DD");
  }

  // If newDatasourceStart is after the term date, we'll start the new datasource on the term date (could be a workaround to reflect a severance payment)
  if (termDate && newDatasourceStart > termDate) {
    newDatasourceStart = termDate;
  }

  // If the last datasource used to end on the same day or after the new start date, we'll end it on the day before the new start date
  if (getFormattedFullDate(lastDatasource.end || termDate || templateOptions.end, "end") >= newDatasourceStart) {
    // Update the last datasource to end on the day before the new datasource starts
    const updatedLastDatasourceEnd = dayjs(newDatasourceStart).subtract(1, "day").format("YYYY-MM-DD");
    sortedDatasources[sortedDatasources.length - 1] = {
      ...lastDatasource,
      end: updatedLastDatasourceEnd,
    };
  }

  const ui = {...lastDatasource.options.ui};
  const formulaValue = ui.expressedAs === "yearly" ? parseFloat(ui.value) / 12 : ui.value;

  sortedDatasources.push({
    id: id(),
    row_id: fcRowId,
    scenario_id: scenarioId,
    start: newDatasourceStart,
    end: termDate || templateOptions.end,
    type: "hiring-plan-formula",
    department_id: departmentId,
    options: {
      formula: `=${formulaValue}`,
      fc_name: fc.name,
      employee_id: employeeId,
      // Set ui values the same as the previous range
      ui,
    },
  });

  // console.log(JSON.stringify(ranges));
  return sortedDatasources;
}

type EmployeeStatus = "terminated" | "terminationPending" | "futureHire" | "active";

export function getEmployeeStatus(employee: Employee, scenarioId: string): EmployeeStatus {
  const scenarioProperties = employee.scenario_properties[scenarioId];
  if (!scenarioProperties?.hire_date) return "terminated";

  const today = dayjs().format("YYYY-MM-DD");
  if (scenarioProperties.hire_date > today) {
    return "futureHire";
  } else {
    if (!scenarioProperties.term_date) {
      return "active";
    } else {
      return scenarioProperties.term_date < today ? "terminated" : "terminationPending";
    }
  }
}

export const employeeStatusToDisplayMapping: Record<EmployeeStatus, string> = {
  active: "Current Team",
  futureHire: "Future Hire",
  terminated: "Terminated",
  terminationPending: "Termination Pending",
} as const;

export function getEmployeeStatusForDisplay(employee: Employee, scenarioId: string) {
  return employeeStatusToDisplayMapping[getEmployeeStatus(employee, scenarioId)];
}
