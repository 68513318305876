import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  datepickerMain: {
    padding: [27.35, 17.33],
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  arrowLeft: {
    marginRight: 8,
  },
  arrowRight: {
    marginLeft: 8,
    transform: "rotate(180deg)",
  },
  monthDropdown: {marginRight: 5},
  yearDropdown: {},
});
