import clsx from "clsx";

import useStyles from "./styles.jss";
import tooltipTexts from "./texts";

import type {GetSubstitutions, TooltipTexts} from "./texts";

const highlightRegex = /\*{3}(.*)\*{3}/gim;
const boldRegex = /\*\*(.*?)\*\*/gim;
const italicRegex = /\*(.*?)\*/gim;
const substitutionRegex = /\{\{(.*?)\}\}/gim;

function processFormatting(text: string, highlightClassName: string, variables: Record<string, string>) {
  let formatted = text
    .split("\n")
    .map((str) => `<p>${str}</p>`)
    .join("");
  formatted = formatted.replace(highlightRegex, `<span class="${highlightClassName}">$1</span>`);
  formatted = formatted.replace(boldRegex, `<b>$1</b>`);
  formatted = formatted.replace(italicRegex, `<em>$1</em>`);
  const matches = formatted.matchAll(substitutionRegex);

  for (const match of matches) {
    if (typeof variables[match[1]] !== "undefined") formatted = formatted.replace(match[0], variables[match[1]]);
  }
  return formatted;
}

function TooltipBuilder<T extends TooltipTexts>(tooltipTextsScoped: T) {
  return function Tooltip<
    K extends keyof T,
    Subs extends GetSubstitutions<T, K, "mainText"> | GetSubstitutions<T, K, "subsection">,
  >(props: [Subs] extends [never] ? {id: K} : {id: K; variables: Subs}): JSX.Element {
    const styles = useStyles();
    const classes = clsx(styles.tooltipMain);
    const {mainText, subsection} = tooltipTextsScoped[props.id];

    const mergedVariables = "variables" in props ? props.variables : {};

    return (
      <div className={classes}>
        <div
          className={styles.mainText}
          dangerouslySetInnerHTML={{__html: processFormatting(mainText, styles.highlight, mergedVariables)}}
        />
        {!!subsection ? (
          <div
            className={styles.subsection}
            dangerouslySetInnerHTML={{__html: processFormatting(subsection, styles.highlight, mergedVariables)}}
          />
        ) : null}
      </div>
    );
  };
}

const fn = TooltipBuilder(tooltipTexts);
export default fn;
