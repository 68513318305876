import getSlug from "@shared/lib/slug";
import dayjs from "dayjs";

import type {Department, Template, Version} from "@shared/types/db";

interface GenerateCsvStringParams {
  headers: string[];
  items: Record<string, string | number | null | undefined>[];
}

export function generateCsvString({headers, items}: GenerateCsvStringParams) {
  let str = ``;
  str += headers.join(",");
  str += "\n";

  const lines: string[] = [];
  for (const item of items) {
    const lineValues: string[] = [];
    for (const key of headers) {
      const value = item[key] ?? "";
      const strValue = value.toString().replace(`"`, `""`).replace("\n", "");
      lineValues.push(`"${strValue}"`);
    }
    lines.push(lineValues.join(","));
  }

  str += lines.join("\n");
  str += "\n";

  return str;
}

interface TriggerCsvDownloadParams {
  content: string;
  filename: string;
}

export function triggerCsvDownload({content, filename}: TriggerCsvDownloadParams) {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  document.body.appendChild(link);

  link.dispatchEvent(new MouseEvent("click"));

  setTimeout(() => {
    link.remove();
  }, 1000);
}

export function getFilename(template: Template, version?: Version | null, globalDerpartment?: Department | null) {
  const filenameVersion = !version ? "" : `-${getSlug(getSlug(version.display_name, true))}`;
  const filenameDepartment = !globalDerpartment ? "" : `-${getSlug(globalDerpartment.name)}`;
  const filenameDate = dayjs(version?.created_at).format("YYYY.MM.DD.HH.mm.ss");
  const filename = `cloudberry-${template.name}${filenameDepartment}-export-${filenameVersion}-${filenameDate}`;
  return filename;
}
