import {useEffect, useState} from "react";

export function useCursorPosition(inputRef: React.RefObject<HTMLInputElement>, isTheActiveComponent: boolean) {
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  // Use useEffect to register a listener on "selectionchange" event
  useEffect(() => {
    if (!isTheActiveComponent) return;

    const handleSelectionChange = () => {
      setCursorPosition(inputRef.current?.selectionStart ? inputRef.current.selectionStart - 1 : null);
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [inputRef, isTheActiveComponent]);

  return cursorPosition;
}
