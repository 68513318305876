import type {Integration} from "@shared/types/db";

export const integrationMappings: Record<Integration["provider"], {name: string; img: string; shortName: string}> = {
  "quickbooks-online": {
    name: "QuickBooks Online",
    img: `/assets/images/ui/integrations/quickbooks-online-icon.svg`,
    shortName: "QuickBooks",
  },
  snowflake: {name: "Snowflake", img: `/assets/images/ui/integrations/snowflake-icon.svg`, shortName: "Snowflake"},
};
