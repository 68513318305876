import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import id from "@shared/lib/id";
import {projKey} from "@state/utils";
import dayjs from "dayjs";

import {findAllCbTxRelatedToFormulaForDateKey} from "../transactions-upsert-utilities";
import {getTransactionRows} from "./generate-transactions-utils";

import type {CbTxState, TemplateRowsState} from "@shared/state/entity-adapters";
import type {Datasource} from "@shared/types/datasources";
import type {CbTx, TemplateRow} from "@shared/types/db";

/**
 * Generates the transactions to upsert from the result of the formula
 *
 * @param row - The row for which we have calculated the formula
 * @param datasource - The datasource for which we have calculated the formula
 * @param scenarioId - The scenario id for which we have calculated the formula
 * @param dateKey - The dateKey for which we're generating the tx
 * @param result - The result of resolving the formula
 * @param idsByName - A dictionary of row ids by their name
 * @param rowEntities - A dictionary of rows by their id
 * @returns The value (amount)
 */
export function generateTransactionsFromFormulaResult(
  row: TemplateRow,
  datasource: Datasource,
  scenarioId: string,
  dateKey: string,
  value: number,
  templateRowsState: Pick<TemplateRowsState, "entities" | "idsByName">,
  cbTxState: CbTxState,
) {
  // If a cash impact schedule is set, get the delayed date
  const txType = row.type === "account" ? "accounting" : row.type === "hiring-plan" ? "hiring_plan" : "generic";
  let cashImpactDate = null;
  if (row.type === "account" && typeof row.options.cashImpactDelay !== "undefined" && row.options.cashImpactDelayUnit) {
    const cashImpactDayJs = dayjs(dateKey, "YYYY-MM")
      .add(row.options.cashImpactDelay, row.options.cashImpactDelayUnit)
      .startOf("month");
    cashImpactDate = cashImpactDayJs.format("YYYY-MM-DD");
  }

  const outputTxTags: CbTx["tags"] = {};
  if (datasource.dimensions?.vendor) outputTxTags.qbo_vendor_id = datasource.dimensions?.vendor;

  const {sourceTxItemRows, cashImpactTxItemRows} = getTransactionRows(templateRowsState, row, cashImpactDate);

  if (!cashImpactDate) {
    // This works for the simple use case - a formula datasource produces a single transaction, debit + credit
    // Will need to be updated to handle cash impact delay and those things, although now that dsId is in the txItem it should be easier

    const datasourceCbTxForDateKey = mapEntitiesToIds(
      cbTxState.entities,
      cbTxState.idsByDsIdDateKey[projKey(datasource.id, dateKey)] ?? [],
    );
    const generatedCbTx: CbTx[] = [];
    const txItemsChanged: boolean[] = [];

    const txId = id();
    for (const [i, cbTxRow] of sourceTxItemRows.entries()) {
      if (!cbTxRow) {
        const destinationRow = row.type === "account" ? row.options.destinationRow : null;
        console.error(`destination row "${destinationRow}" not found for ${row.name} forecast`);
        continue;
      }
      const existingCbTxIndex = datasourceCbTxForDateKey.findIndex((txItem) => txItem.row_id === cbTxRow.id);
      const existingCbTx =
        existingCbTxIndex !== -1 ? datasourceCbTxForDateKey.splice(existingCbTxIndex, 1)[0] ?? null : null;

      const newValue = getActualAmountAndType(txType !== "accounting" ? null : i === 0 ? "left" : "right", value);
      const valueChanged =
        !existingCbTx || newValue.amount !== existingCbTx?.amount || newValue.amount_type !== existingCbTx?.amount_type;
      const departmentChanged = (existingCbTx?.department_id ?? null) !== (datasource.department_id ?? null);
      const vendorChanged = (existingCbTx?.tags?.qbo_vendor_id ?? null) !== (datasource.dimensions?.vendor ?? null);

      txItemsChanged.push(valueChanged || departmentChanged || vendorChanged);

      if (existingCbTx) {
        const updatedCbTx: CbTx = {
          ...existingCbTx,
          ...newValue,
        };
        if (departmentChanged) updatedCbTx.department_id = datasource.department_id;
        if (vendorChanged) {
          if (datasource.dimensions?.vendor) {
            updatedCbTx.tags = {
              ...(updatedCbTx.tags ?? {}),
              qbo_vendor_id: datasource.dimensions?.vendor,
            };
          } else {
            updatedCbTx.tags = {...updatedCbTx.tags};
            if (updatedCbTx.tags.qbo_vendor_id) delete updatedCbTx.tags.qbo_vendor_id;
          }
        }
        generatedCbTx.push(updatedCbTx);
      } else {
        const newCbTx: CbTx = {
          id: id(),
          name: "Forecast transaction",
          date: `${dateKey}-01`,
          scenario_id: scenarioId,
          ds_id: datasource.id,
          tx_id: txId,
          row_id: cbTxRow.id,
          ...newValue,
          description: ``,
          department_id: datasource.department_id?.toString() ?? null,
          tx_type: txType,
          source: "formula",
          tags: outputTxTags,
        };
        generatedCbTx.push(newCbTx);
      }
    }

    // Delete the remaining transactions
    return {txItems: generatedCbTx, txItemsChanged, txItemsToDelete: datasourceCbTxForDateKey};
  } else {
    // TODO: these find() could be a major slowdown, will need to be cached
    // Check if debited account already has a buffer tx item
    // If it does, edit it and all linked transactions instead of creating new ones

    let nbMatchedSourceTxItems = 0;
    let nbMatchedLinkedTxItems = 0;
    const txItems: CbTx[][] = [[], []];
    const txItemsChanged: boolean[][] = [[], []];

    const {foundCbTx: unorderedMatchingCbTx, toDelete} = findAllCbTxRelatedToFormulaForDateKey(
      cbTxState,
      row.id,
      scenarioId,
      dateKey,
      datasource.department_id,
      datasource.dimensions?.vendor,
    );

    // Order the rows the same way as when initially created
    const rowsMatchingCbTx = {
      source: sourceTxItemRows,
      linked: cashImpactTxItemRows,
    };

    const matchingCbTx: {
      source: CbTx[];
      linked: CbTx[];
    } = {source: [], linked: []};

    for (const type of ["source", "linked"] as const) {
      for (const row of rowsMatchingCbTx[type] ?? []) {
        const matchingTxItem = unorderedMatchingCbTx[type].find((txItem) => txItem.row_id === row.id);
        if (!matchingTxItem) continue;
        matchingCbTx[type].push(matchingTxItem);
      }
    }

    if (matchingCbTx.source.length) {
      txItems[0] = [];
      for (let i = 0; i < matchingCbTx.source.length; i++) {
        nbMatchedSourceTxItems++;
        const newValue = getActualAmountAndType(txType !== "accounting" ? null : i === 0 ? "left" : "right", value);
        txItems[0].push({
          ...matchingCbTx.source[i],
          ...newValue,
        });
        // Only update the transaction if either amount or amount_type have changed
        if (
          newValue.amount !== matchingCbTx.source[i].amount ||
          newValue.amount_type !== matchingCbTx.source[i].amount_type
        ) {
          txItemsChanged[0].push(true);
        }
      }

      // That code works for bank impact
      // Will need update to handle multiple other tx items like deferred revenue
      if (matchingCbTx.linked.length) {
        txItems[1] = [];
        for (let i = 0; i < matchingCbTx.linked.length; i++) {
          nbMatchedLinkedTxItems++;
          const newValue = getActualAmountAndType(txType !== "accounting" ? null : i === 0 ? "left" : "right", value);
          txItems[1].push({
            ...matchingCbTx.linked[i],
            ...newValue,
          });
          // Only update the transaction if either amount or amount_type have changed
          if (
            newValue.amount !== matchingCbTx.linked[i].amount ||
            newValue.amount_type !== matchingCbTx.linked[i].amount_type
          ) {
            txItemsChanged[1].push(true);
          }
        }
      }
    }

    if (nbMatchedSourceTxItems !== sourceTxItemRows.length) {
      // for (const existingTxItem of matchingCbTx.source) {
      //   toDelete.push(existingTxItem);
      // }

      const txId = id();
      txItems[0] = [];
      for (const [i, sourceTxItemRow] of sourceTxItemRows.entries()) {
        txItems[0].push({
          id: id(),
          name: "Buffer transaction",
          date: `${dateKey}-01`,
          scenario_id: scenarioId,
          ds_id: datasource.id,
          tx_id: txId,
          row_id: sourceTxItemRow.id,
          ...getActualAmountAndType(txType !== "accounting" ? null : i === 0 ? "left" : "right", value),
          description: `Auto-generated from ${row.display_name} forecast`,
          department_id: datasource.department_id?.toString() ?? null,
          tx_type: txType,
          source: "formula",
          tags: outputTxTags,
        });
        txItemsChanged[0].push(true);
      }
    }

    if (row.type === "account" && cashImpactDate && nbMatchedLinkedTxItems !== (cashImpactTxItemRows?.length ?? 0)) {
      // for (const existingTxItem of matchingCbTx.linked) {
      //   toDelete.push(existingTxItem);
      // }
      const delayDisplayText = `(${row.options.cashImpactDelay} ${row.options.cashImpactDelayUnit}(s))`;
      const txId = id();
      txItems[1] = [];
      for (const [i, cashImpactTxItemRow] of cashImpactTxItemRows.entries()) {
        const tags: Record<string, string> = {};
        if (datasource.dimensions?.vendor) tags.vendor = datasource.dimensions?.vendor;
        txItems[1].push({
          id: id(),
          name: "Buffer transaction",
          date: cashImpactDate,
          scenario_id: scenarioId,
          ds_id: datasource.id,
          tx_id: txId,
          row_id: cashImpactTxItemRow.id,
          source_tx_id: txItems[0][0].tx_id,
          ...getActualAmountAndType(txType !== "accounting" ? null : i === 0 ? "left" : "right", value),
          description: `Auto-generated bank impact from ${row.display_name} forecast on delay in settings ${delayDisplayText}`,
          department_id: datasource.department_id?.toString() ?? null,
          tx_type: txType,
          source: "formula",
          tags: outputTxTags,
        });
        txItemsChanged[1].push(true);
      }
    }

    const flatTxItems = txItems.flat(2);
    const flatChangedTxItems = txItemsChanged.flat(2);
    return {txItems: flatTxItems, txItemsChanged: flatChangedTxItems, txItemsToDelete: toDelete};
  }
}

export function getActualAmountAndType(
  sideOfTx: "left" | "right" | null,
  value: number,
): {amount: number; amount_type: "debit" | "credit" | null} {
  if (!sideOfTx) {
    return {amount: value, amount_type: null};
  } else if (sideOfTx === "left") {
    return {amount: Math.abs(value), amount_type: value >= 0 ? "debit" : "credit"};
  } else {
    return {
      amount: Math.abs(value),
      amount_type: value >= 0 ? "credit" : "debit",
    };
  }
}
