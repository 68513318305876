import {createSlice} from "@reduxjs/toolkit";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {SuggestionsResult} from "./suggestion-utilities";

export interface TooltipState {
  isOpen: boolean;
  suggestionsResult: SuggestionsResult | null;
  activeItemIndex: number | null;
}

const slice = createSlice({
  name: "tooltip",
  initialState: {
    isOpen: false,
    suggestionsResult: null,
    activeItemIndex: 0,
  } as TooltipState,

  reducers: {
    openTooltip: (state) => {
      state.isOpen = true;
      state.activeItemIndex = 0;
      state.suggestionsResult = null;
    },
    closeTooltip: (state) => {
      state.isOpen = false;
      state.activeItemIndex = 0;
      state.suggestionsResult = null;
    },
    setActiveItemIndex: (state, action: PayloadAction<number | null>) => {
      state.activeItemIndex = action.payload ?? 0;
    },
    updateTooltipState: (state, action: PayloadAction<Partial<TooltipState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const {openTooltip, closeTooltip, updateTooltipState, setActiveItemIndex} = slice.actions;

const reducer = slice.reducer;

export default reducer;
