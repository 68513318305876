import clsx from "clsx";
import React from "react";

import useStyles from "./styles.jss";

import type {NotificationModalProps} from "@app/ui-state/slice";

interface StepperProps {
  progress: NonNullable<NotificationModalProps["progress"]>;
}

const Stepper: React.FC<StepperProps> = ({progress}) => {
  const styles = useStyles();
  const {currentStep, totalSteps} = progress;
  const steps = Array.from({length: totalSteps}, (_, index) => index + 1);

  return (
    (<div className={styles.stepper}>
      {Array.from({length: totalSteps}, (_, index) => index + 1).map((step, i) => (
        <React.Fragment key={step}>
          <div
            className={clsx(styles.step, step <= currentStep ? styles.completedStep : styles.inactiveStep, {
              [styles.currentStep]: step === currentStep,
            })}
          />
          {i !== totalSteps - 1 && (
            <div className={clsx(styles.stepConnector, {[styles.stepComplete]: step < currentStep})} />
          )}
        </React.Fragment>
      ))}
    </div>)
  );
};

export default Stepper;
