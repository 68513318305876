/* eslint-disable @typescript-eslint/consistent-type-imports */
import {isBrowser, isNode, isWebWorker} from "browser-or-node";

import type {WorkerFunctionMapping} from "client/app/worker/worker-thread";
if (isWebWorker) throw new Error("!!!!!!!!!!!!!!! SHOULD NOT BE LOADED FROM WEB WORKER !!!!!!!!!!!!!!!");

export async function callWorkerFn<
  F extends Parameters<typeof import("../client/app/worker")["callWorkerFn"]>,
  R extends Promise<ReturnType<WorkerFunctionMapping[F[0]]>>,
>(...args: F): Promise<R> {
  if (!isBrowser) throw new Error("This function can only be called from the browser main thread");
  const importedModule = await getImport<typeof import("../client/app/worker")>("callWorkerFn");
  return importedModule.callWorkerFn(...(args as Parameters<typeof importedModule.callWorkerFn>)) as Promise<R>;
}

export const fireActionInWorker = async (
  ...args: Parameters<typeof import("../client/app/worker/call-worker")["fireActionInWorker"]>
) => {
  if (!isBrowser) throw new Error("This function can only be called from the browser main thread");
  const importedModule = await getImport<typeof import("../client/app/worker/call-worker")>("fireActionInWorker");
  return importedModule.fireActionInWorker(...args);
};

export const getWorkerStore = async () => {
  if (isNode) throw new Error("This function cannot be called from the backend");
  const {store} = await import("../client/app/worker/worker-thread/worker-store.js");
  return store;
};

const supportedImports = ["callWorkerFn", "fireActionInWorker"] as const;
type SupportedImports = (typeof supportedImports)[number];

const importCache: Record<string, any> = {};
async function getImport<T>(importRequested: SupportedImports): Promise<T> {
  switch (importRequested) {
    case "callWorkerFn":
      return importCache[importRequested] || (await import("../client/app/worker/index.js"));
    case "fireActionInWorker":
      return importCache[importRequested] || (await import("../client/app/worker/call-worker.js"));
  }
}
