import {addDeltaMonthsToDateKey, getAllDateKeysBetween, resolveDatasourceDates} from "@shared/lib/date-utilities";
import {isBalance} from "@shared/lib/row-utilities";
import {projKeyOmitNulls} from "@state/utils";

import {resolveDaterange} from "../formula/date-range";
import {NO_DEPARTMENT_NAME} from "../formula/formula-utilities";
import {getStringKeyFromParsedKey} from "./cache-utilities";
import {orderDependencyCache, resolveRowIdsForRef} from "./dependency-cache-utilities";

import type {EntityId} from "@reduxjs/toolkit";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {Datasource, FormulaDatasource, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {TemplateOptions, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";

const dateKeysBetweenCache: Record<string, string[]> = {};

type BalanceRanges = Record<
  string,
  {
    departmentId?: EntityId | null | undefined;
    vendor?: string | null | undefined;
    rowId: EntityId;
  }
>;

type DepartmentsToResolveMapping = Record<EntityId, null | Record<EntityId, Record<string, true>>>;

export type DependencyCacheListItem = {
  scenarioId: EntityId;
  rowId: EntityId;
  dateKey: string;
  departmentId?: EntityId | null;
  vendor?: string | null;
  total?: boolean;
  balance?: boolean;
};

let templateOptionsOverrideGlobal: Record<EntityId, TemplateOptions> = {};

export type DependencyCache = {
  cellToDependencies: Record<string, Set<string>>;
  dependencyToCells: Record<string, Set<string>>;
  fullListCollection: DependencyCacheListItem[];
  fullList: Record<string, DependencyCacheListItem>;
  orderedCacheKeys: string[];
};
export type DependencyCachePartialState = Pick<RootState, "datasources" | "templates" | "templateRows" | "departments">;
export function buildDependencyCache({
  scenarioId,
  state,
  rowIdsWithDepartmentsToResolve,
  templateOptionsOverride,
  resolvedDates,
  datasourcesOverride,
  datasourcesOnly = false,
}: {
  scenarioId: EntityId;
  state: DependencyCachePartialState;
  rowIdsWithDepartmentsToResolve?: EntityId[];
  templateOptionsOverride?: Record<EntityId, TemplateOptions>;
  resolvedDates?: DatasourceDiff["resolvedDates"]["new" | "old"];
  datasourcesOverride?: Record<EntityId, Datasource>;
  datasourcesOnly?: boolean;
}): DependencyCache {
  // time("GenerateDependencyCache", "Main loop");
  const cache: DependencyCache = {
    cellToDependencies: {},
    dependencyToCells: {},
    fullListCollection: [],
    fullList: {},
    orderedCacheKeys: [],
  };

  const templateDateKeysMapping: Record<string, string[]> = {};

  const balanceRanges: BalanceRanges = {};

  const parentIdsScopedPairsToResolve: Record<string, Record<string, true>> = {};

  let datasourceIds: EntityId[] = [];
  let datasourceEntities: Record<EntityId, Datasource | undefined> = {};
  if (!datasourcesOverride) {
    datasourceIds = [...state.datasources.ids];
    datasourceEntities = {...state.datasources.entities};
  } else {
    datasourceEntities = {...datasourcesOverride};
    datasourceIds = Object.keys(datasourceEntities);
  }

  if (templateOptionsOverride) templateOptionsOverrideGlobal = templateOptionsOverride;

  const departmentsToResolveByRowId: DepartmentsToResolveMapping = rowIdsWithDepartmentsToResolve?.length
    ? Object.fromEntries(rowIdsWithDepartmentsToResolve.map((rowId) => [rowId, null]))
    : {};

  for (const datasourceId of datasourceIds) {
    const datasource = datasourceEntities[datasourceId];
    if (
      !datasource ||
      datasource.scenario_id !== scenarioId ||
      (datasource.type !== "formula" && datasource.type !== "hiring-plan-formula")
    )
      continue;

    const row = state.templateRows.entities[datasource.row_id];
    if (!row) continue;
    const template = state.templates.entities[row.template_id];
    if (!template) continue;

    const templateOptions = templateOptionsOverrideGlobal[template.id] || template.options;

    let resolvedStartEnd: {start: string; end: string};
    if (resolvedDates?.[datasource.id]) {
      resolvedStartEnd = resolvedDates[datasource.id];
    } else {
      const start = datasource.start && datasource.start.length > 7 ? datasource.start.slice(0, 7) : datasource.start;
      const end = datasource.end && datasource.end.length > 7 ? datasource.end.slice(0, 7) : datasource.end;
      resolvedStartEnd = resolveDatasourceDates(
        start,
        end,
        templateOptions.start,
        templateOptions.end,
        templateOptions.lastMonthOfActuals,
      );
    }

    templateDateKeysMapping[row.template_id] ||= getAllDateKeysBetween(templateOptions.start, templateOptions.end);

    const formulaReferences = datasource.options.references;

    dateKeysBetweenCache[`${resolvedStartEnd.start}::${resolvedStartEnd.end}`] ||= getAllDateKeysBetween(
      resolvedStartEnd.start,
      resolvedStartEnd.end,
    );
    const resolvedDateKeys = dateKeysBetweenCache[`${resolvedStartEnd.start}::${resolvedStartEnd.end}`];

    for (const dateKey of resolvedDateKeys) {
      const cacheKey: DependencyCacheListItem = {
        scenarioId,
        rowId: row.mirror_of || row.id,
        departmentId: datasource.department_id,
        vendor: datasource.dimensions?.vendor,
        dateKey,
      };

      // Handle row-level dependencies like balances, mirrors and totals
      addRowLevelDependencies(row, scenarioId, dateKey, cacheKey, cache, state);

      if (!formulaReferences) continue;

      for (const ref of formulaReferences) {
        const resolvedRowIdsForRef: string[] = resolveRowIdsForRef(ref, state);
        let resolvedDateRange: {start: string; end: string};
        try {
          resolvedDateRange = resolveDaterange(
            ref.from || "this_month",
            ref.to || "this_month",
            dateKey,
            templateOptions.lastMonthOfActuals,
          );
        } catch (e) {
          console.error(e);
          continue;
        }

        // Get all the dateKeys that are referenced by this ref
        dateKeysBetweenCache[`${resolvedDateRange.start}::${resolvedDateRange.end}`] ||= getAllDateKeysBetween(
          resolvedDateRange.start,
          resolvedDateRange.end,
        );
        const referenceDateKeys = dateKeysBetweenCache[`${resolvedDateRange.start}::${resolvedDateRange.end}`];

        for (const refDateKey of referenceDateKeys) {
          for (const refRowId of resolvedRowIdsForRef) {
            const refRow = state.templateRows.entities[refRowId];
            if (!refRow) continue;
            const refRowIsBalance = (refRow.type === "account" || refRow.type === "generic") && isBalance(refRow);
            const refRowHasChildren = !!state.templateRows.idsByParentRowId[refRow.id]?.length;
            let refDepartmentHasChildren = false;

            let refDepartmentId = null;
            if (ref.department) {
              refDepartmentId =
                state.departments.idsByName[ref.department === "none" ? NO_DEPARTMENT_NAME : ref.department];
              if (refDepartmentId) refDepartmentHasChildren = !!state.departments.idsByParent[refDepartmentId]?.length;
            }
            const forceOwnValue = ref.total && ref.total.toLowerCase() === "false";

            const isSelfRef =
              refRow.id === row.id &&
              refDepartmentId === (datasource.department_id ?? null) &&
              (ref.vendor ?? null) === (datasource.dimensions?.vendor?.toLowerCase() ?? null);

            const refCacheKey: DependencyCacheListItem = {
              scenarioId,
              rowId: refRow.mirror_of || refRowId,
              departmentId: refDepartmentId,
              dateKey: refDateKey,
              vendor: ref.vendor,
              balance: refRowIsBalance,
              total: !forceOwnValue && (refRowHasChildren || refDepartmentHasChildren) && !isSelfRef,
            };

            // Mark this cell as depending on the referenced cell
            addToBothCaches({cache, cell: cacheKey, dependsOn: refCacheKey});

            if (refCacheKey.total && !parentIdsScopedPairsToResolve[refRowId]) {
              parentIdsScopedPairsToResolve[refRowId] = {};
            }

            // If the ref is a parent and it has either a department or a vendor, add it to the list of rows for which we need to resolve the scoped children depenencies
            if (refRowHasChildren && refDepartmentId) {
              // TODO: do the same for vendors
              parentIdsScopedPairsToResolve[refRowId] ||= {};
              if (!parentIdsScopedPairsToResolve[refRowId][refDepartmentId])
                parentIdsScopedPairsToResolve[refRowId][refDepartmentId] = true;
            }

            if (refDepartmentHasChildren && refDepartmentId && departmentsToResolveByRowId[refRowId] !== null) {
              departmentsToResolveByRowId[refRowId] ||= {};
              departmentsToResolveByRowId[refRowId]![refDepartmentId] ||= {};
              departmentsToResolveByRowId[refRowId]![refDepartmentId]![refDateKey] = true;
            }

            // If ref is a balance row, add it to the balanceRanges
            if (refRowIsBalance) {
              addToBalanceRanges(refRowId, datasource, balanceRanges);
            }
          }
        }
      }
    }
  }
  // timeEnd("GenerateDependencyCache", "Main loop");

  // Take care of totals
  // time("GenerateDependencyCache", "Totals");
  if (!datasourcesOnly) addAllTotalsToDependencyCache(state, cache, scenarioId, parentIdsScopedPairsToResolve);
  // timeEnd("GenerateDependencyCache", "Totals");

  // Take care of balances
  // time("GenerateDependencyCache", "Balances");
  if (!datasourcesOnly) addBalancesToDependencyCache(state, cache, scenarioId, templateDateKeysMapping, balanceRanges);
  // timeEnd("GenerateDependencyCache", "Balances");

  // Take care of mirror rows
  // time("GenerateDependencyCache", "Mirror rows");
  if (!datasourcesOnly) addMirrorRowsToDependencyCache(state, cache, scenarioId);
  // timeEnd("GenerateDependencyCache", "Mirror rows");

  // Take care of rows for which departments need to be resolved
  // time("GenerateDependencyCache", "Resolve departments");
  if (!datasourcesOnly)
    addParentDepartmentsDependenciesForRowIds(state, cache, scenarioId, departmentsToResolveByRowId);
  // timeEnd("GenerateDependencyCache", "Resolve departments");

  // Generate the "orderedCacheKeys" array that will be used to iterate over the cache in the correct order
  // time("GenerateDependencyCache", "Ordered dependency cache");
  orderDependencyCache(cache);
  // timeEnd("GenerateDependencyCache", "Ordered dependency cache");

  // return env !== "production" ? Object.freeze(cache) : cache;
  templateOptionsOverrideGlobal = {};
  return cache;
}

function addRowLevelDependencies(
  row: TemplateRow,
  scenarioId: EntityId,
  dateKey: string,
  cacheKey: DependencyCacheListItem,
  cache: DependencyCache,
  state: DependencyCachePartialState,
  // datasource: DbHiringPlanFormulaDatasource | DbFormulaDatasource,
  // balanceRanges: BalanceRanges,
) {
  const template = state.templates.entities[row.template_id];
  if (!template) return;

  const templateOptions = templateOptionsOverrideGlobal[template.id] ?? template.options;

  const isBalanceRow = isBalance(row);

  const rowLevelCacheKey = {scenarioId, rowId: row.mirror_of || row.id, dateKey};
  if ((cacheKey.departmentId && !cacheKey.vendor) || (cacheKey.vendor && !cacheKey.departmentId)) {
    // Mark the row-level cell as depending on the referenced cell if only one of department or vendor is set
    addToBothCaches({cache, cell: rowLevelCacheKey, dependsOn: cacheKey});
  } else if (cacheKey.departmentId && cacheKey.vendor) {
    // Mark the row-level cell and the department-level cell as depending on the referenced cell
    addToBothCaches({cache, cell: rowLevelCacheKey, dependsOn: cacheKey});
    addToBothCaches({
      cache,
      cell: {scenarioId, rowId: row.mirror_of || row.id, dateKey, departmentId: cacheKey.departmentId},
      dependsOn: cacheKey,
    });
  }

  // If this is an account row
  if (row.type === "account") {
    const otherEndOfTxToMarkAsDependent: {row: TemplateRow; dateKey: string}[] = [];
    // mark the destination row as depending on the source row
    if (row.options.destinationRow) {
      const destinationRow =
        state.templateRows.entities[state.templateRows.idsByName[row.options.destinationRow] ?? ""];

      if (destinationRow) {
        otherEndOfTxToMarkAsDependent.push({row: destinationRow, dateKey});

        // if present, mark the cash impact row as depending on the source row
        if (
          // destinationRow.type === "account" &&
          // destinationRow.options.destinationRow &&
          typeof row.options.cashImpactDelay !== "undefined"
        ) {
          // TODO: if we use cash impact for real, fix this instead of using a hardcoded "checking"
          // const cashImpactRow =
          //   state.templateRows.entities[state.templateRows.idsByName[destinationRow.options.destinationRow] ?? ""];
          const cashImpactRow = state.templateRows.entities[state.templateRows.idsByName["checking"] ?? ""];
          if (cashImpactRow) {
            const cashImpactDateKey = addDeltaMonthsToDateKey(dateKey, row.options.cashImpactDelay);
            otherEndOfTxToMarkAsDependent.push({row: cashImpactRow, dateKey: cashImpactDateKey});
          }
        }
      }
    }

    for (const {row: splitRow, dateKey: splitDateKey} of otherEndOfTxToMarkAsDependent) {
      const splitCacheKey: DependencyCacheListItem = {
        scenarioId,
        rowId: splitRow.id,
        dateKey: splitDateKey,
        departmentId: cacheKey.departmentId,
        vendor: cacheKey.vendor,
      };
      addToBothCaches({cache, cell: splitCacheKey, dependsOn: cacheKey});

      // if (isBalance(splitRow)) {
      //   // If the destination row is a balance, mark it as depending on its self value
      //   addToBalanceRanges(splitRow.id, datasource, balanceRanges, splitDateKey);
      // }
    }

    // If this is a balance row, keep track of the first and last dateKeys in that row that are generated by a key in the dependency cache
    if (isBalanceRow) {
      //   addToBalanceRanges(row.id, datasource, balanceRanges);

      const prevDateKey = addDeltaMonthsToDateKey(dateKey, -1);
      if (prevDateKey >= templateOptions.start) {
        const prevMonthCacheKey: DependencyCacheListItem = {
          scenarioId,
          rowId: row.id,
          dateKey: prevDateKey,
          balance: true,
        };
        addToBothCaches({cache, cell: cacheKey, dependsOn: prevMonthCacheKey});
      }
    }
  }
}

/**
 * Adds a row to the balance ranges. If the key has no entry in the cache yet, it creates a new one. If it exists, it updates the start and end dates.
 *
 * @param rowId - the rowId
 * @param datasource - the datasource
 * @param balanceRanges - the balance ranges to add the cacheKey to
 *
 * @returns {void}
 */
function addToBalanceRanges(
  rowId: string,
  datasource: FormulaDatasource | HiringPlanFormulaDatasource,
  balanceRanges: BalanceRanges,
) {
  const key = projKeyOmitNulls(rowId, datasource.department_id, datasource.dimensions?.vendor);
  if (!balanceRanges[key])
    balanceRanges[key] = {
      departmentId: datasource.department_id,
      vendor: datasource.dimensions?.vendor,
      rowId,
    };
}

/**
 * Adds all the balances to the dependency cache
 *
 * @param scopedBalances - The balance ranges for each row that has a balance
 * @param state - The root state
 * @param scenarioId - The scenario ID
 * @param cache - The dependency cache
 */
function addBalancesToDependencyCache(
  state: DependencyCachePartialState,
  cache: DependencyCache,
  scenarioId: EntityId,
  templateDateKeysMapping: Record<string, string[]>,
  scopedBalances: BalanceRanges,
) {
  const rowIdToDeptVendorMapping: Record<EntityId, {departmentId?: EntityId | null; vendor?: string | null}[]> = {};
  for (const {departmentId, vendor, rowId} of Object.values(scopedBalances)) {
    if (!rowIdToDeptVendorMapping[rowId]) rowIdToDeptVendorMapping[rowId] = [];
    rowIdToDeptVendorMapping[rowId].push({departmentId, vendor});
  }

  for (const id of state.templateRows.ids) {
    const scopedBalancesForRow = [{departmentId: null, vendor: null}, ...(rowIdToDeptVendorMapping[id] ?? [])];

    const row = state.templateRows.entities[id];

    if (!row || !isBalance(row)) continue;

    const template = state.templates.entities[row.template_id];
    if (!template) continue;

    for (const {departmentId, vendor} of scopedBalancesForRow) {
      // This actually needs to be from beginning of time since the algo cache cannot assume the values for previous months to be calculated
      templateDateKeysMapping[row.template_id] ||= getAllDateKeysBetween(
        (templateOptionsOverrideGlobal[template.id] ?? template.options).start,
        (templateOptionsOverrideGlobal[template.id] ?? template.options).end,
      );
      const dateKeys = templateDateKeysMapping[row.template_id];

      let prevDateKey: string | null = null;
      for (const dateKey of dateKeys) {
        const cellKey = {scenarioId, rowId: row.mirror_of || row.id, dateKey, departmentId, vendor, balance: true};
        if (prevDateKey) {
          const prevBalanceKey = {
            scenarioId,
            rowId: row.mirror_of || row.id,
            dateKey: prevDateKey,
            departmentId,
            vendor,
            balance: true,
          };
          addToBothCaches({cache, cell: cellKey, dependsOn: prevBalanceKey});
        }

        const selfValueKey = {
          scenarioId,
          rowId: row.mirror_of || row.id,
          dateKey,
          departmentId,
          vendor,
          balance: false,
        };

        addToBothCaches({cache, cell: cellKey, dependsOn: selfValueKey});

        prevDateKey = dateKey;
      }
    }
  }
}

/**
 * Adds a new dependency to the cache.
 *
 * @param cache - The dependency cache
 */
function addToBothCaches({
  cache,
  cell,
  dependsOn,
}: {
  cache: DependencyCache;
  cell: DependencyCacheListItem;
  dependsOn: DependencyCacheListItem;
}) {
  addToCache(cache, "cellToDependencies", cell, dependsOn);
  addToCache(cache, "dependencyToCells", dependsOn, cell);
}

/**
 * Adds a dependency to the specified projection of the cache.
 * @param cache The dependency cache.
 * @param projection The projection to add the dependency to.
 * @param listKey The key of the list to add the dependency to.
 * @param itemKey The key of the item to add to the list.
 */
function addToCache(
  cache: DependencyCache,
  projection: "cellToDependencies" | "dependencyToCells",
  listKey: DependencyCacheListItem,
  itemKey: DependencyCacheListItem,
) {
  const cacheKeys = {
    listKey: "",
    itemKey: "",
  };
  // Generate the string cache keys, depending on whether or not vendor and department are specified
  for (const type of ["listKey", "itemKey"] as const) {
    const cacheKey = type === "listKey" ? listKey : itemKey;
    if (itemKey.vendor) itemKey.vendor = itemKey.vendor.toLowerCase();

    cacheKeys[type] = getStringKeyFromParsedKey(cacheKey);
  }

  // Add it to the specified projection list, initializing that list if needed
  if (!cache[projection][cacheKeys.listKey]) cache[projection][cacheKeys.listKey] = new Set();
  cache[projection][cacheKeys.listKey].add(cacheKeys.itemKey);

  // If it's a dependency we encounter for the first time, add it to the collection
  if (!cache.fullList[cacheKeys.itemKey]) {
    cache.fullList[cacheKeys.itemKey] = itemKey;
    cache.fullListCollection.push(itemKey);
  }
}

function addMirrorRowsToDependencyCache(
  state: DependencyCachePartialState,
  cache: DependencyCache,
  scenarioId: EntityId,
) {
  for (const rowIds of Object.values(state.templateRows.idsByMirrorOf)) {
    if (!rowIds) continue;
    for (const rowId of rowIds) {
      const row = state.templateRows.entities[rowId];
      if (!row?.mirror_of) continue;
      const template = state.templates.entities[row.template_id];
      if (!template) continue;

      const templateOptions = templateOptionsOverrideGlobal[template.id] ?? template.options;

      const dateKeys = getAllDateKeysBetween(templateOptions.start, templateOptions.end);

      for (const dateKey of dateKeys) {
        const cellKey = {scenarioId, rowId, dateKey};
        const mirrorCellKey = {scenarioId, rowId: row.mirror_of, dateKey};
        addToBothCaches({cache, cell: cellKey, dependsOn: mirrorCellKey});
      }
    }
  }
}

/**
 * Adds ALL totals to the dependency cache
 *
 * @param state - The current state
 * @param cache - The dependency cache
 * @param scenarioId - The scenario id
 */
function addAllTotalsToDependencyCache(
  state: DependencyCachePartialState,
  cache: DependencyCache,
  scenarioId: EntityId,
  parentIdsScopedPairsToResolve: Record<string, Record<string, true>>,
) {
  const dateKeysMapping: Record<string, ReturnType<typeof getAllDateKeysBetween>> = {};

  // for (const [parentRowId, childIds] of Object.entries(state.templateRows.idsByParentRowId)) {
  for (const [parentRowId, departmentIdsObj] of Object.entries(parentIdsScopedPairsToResolve)) {
    const row = state.templateRows.entities[parentRowId];
    const childIds = state.templateRows.idsByParentRowId[parentRowId];

    if (!row || (!childIds?.length && !parentIdsScopedPairsToResolve[parentRowId]?.length)) continue;

    const departmentIds = Object.keys(departmentIdsObj ?? {});

    const template = state.templates.entities[row.template_id];
    if (!template) continue;

    const templateOptions = templateOptionsOverrideGlobal[template.id] ?? template.options;

    dateKeysMapping[template.id] ||= getAllDateKeysBetween(templateOptions.start, templateOptions.end);

    const parentIsBalance = isBalance(row);

    for (const dateKey of dateKeysMapping[template.id]) {
      const parentTotalKey = {
        scenarioId,
        rowId: parentRowId,
        dateKey,
        balance: parentIsBalance,
        total: true,
      };

      const parentSelfKey = {
        scenarioId,
        rowId: parentRowId,
        dateKey,
        balance: parentIsBalance,
        total: false,
      };

      for (const departmentId of departmentIds) {
        // Tell the cache that this row's total SCOPED TO A DEPARTMENT depends on its self value SCOPED TO THAT SAME DEPARTMENT
        addToBothCaches({cache, cell: {...parentTotalKey, departmentId}, dependsOn: {...parentSelfKey, departmentId}});
      }

      if (!childIds?.length) continue;

      // Tell the cache that this row's total depends on its self value
      addToBothCaches({cache, cell: parentTotalKey, dependsOn: parentSelfKey});

      function recursive(childIds: string[], previousLevelParentRowId?: string) {
        // Tell the cache that the previousLevelParentRowId's total depends on its self value
        if (previousLevelParentRowId) {
          addToBothCaches({
            cache,
            cell: {...parentTotalKey, rowId: previousLevelParentRowId},
            dependsOn: {...parentSelfKey, rowId: previousLevelParentRowId},
          });
        }

        for (const childId of childIds) {
          const childRow = state.templateRows.entities[childId];
          if (!childRow) continue;

          const childRowIsBalance = isBalance(childRow) && !childRow.mirror_of;

          const childRowChildIds = state.templateRows.idsByParentRowId[childRow.id];
          const rowId = childRow.mirror_of || childRow.id;
          const childKey = {
            scenarioId,
            rowId,
            dateKey,
            balance: childRowIsBalance,
            total: !!childRowChildIds?.length,
          };

          // Tell the cache that this row's total depends on the child row's total (or own value if it doesn't have children)
          addToBothCaches({
            cache,
            cell: {...parentTotalKey, rowId: previousLevelParentRowId ?? parentRowId},
            dependsOn: childKey,
          });
          for (const departmentId of departmentIds) {
            const departmentHasChildren = !!state.departments.idsByParent[departmentId]?.length;

            // addToBothCaches(
            //   cache,
            //   {...parentTotalKey, departmentId, total: departmentHasChildren},
            //   {...childKey, departmentId, total: departmentHasChildren},
            // );
            //
            // Tell the cache that this row's total SCOPED TO A DEPARTMENT depends on the child row's total SCOPED TO THAT SAME DEPARTMENT
            addToBothCaches({
              cache,
              cell: {...parentTotalKey, departmentId, rowId: previousLevelParentRowId ?? parentRowId},
              dependsOn: {...childKey, departmentId, total: childKey.total || departmentHasChildren},
            });
          }

          if (childRowChildIds?.length) recursive(childRowChildIds, childId);
        }
      }
      recursive(childIds);
    }
  }
}

function addParentDepartmentsDependenciesForRowIds(
  state: DependencyCachePartialState,
  cache: DependencyCache,
  scenarioId: EntityId,
  departmentsToResolveMapping: DepartmentsToResolveMapping,
) {
  const dateKeysMapping: Record<string, ReturnType<typeof getAllDateKeysBetween>> = {};

  for (const [rowId, departmentIdToDateKeysMapping] of Object.entries(departmentsToResolveMapping)) {
    const childRowIds = [rowId, ...(state.templateRows.idsByParentRowId[rowId] ?? [])];

    const row = state.templateRows.entities[rowId];
    if (!row) continue;

    const template = state.templates.entities[row.template_id];
    if (!template) continue;

    const templateOptions = templateOptionsOverrideGlobal[template.id] ?? template.options;
    dateKeysMapping[template.id] ||= getAllDateKeysBetween(templateOptions.start, templateOptions.end);

    const rowIsBalance = isBalance(row);

    let dateKeys: string[] = [];

    const parentDepartmentIds = departmentIdToDateKeysMapping
      ? Object.keys(departmentIdToDateKeysMapping)
      : Object.keys(state.departments.idsByParent);

    for (const parentDepartmentId of parentDepartmentIds) {
      const childDepartmentIds = state.departments.idsByParent[parentDepartmentId];

      if (parentDepartmentId === "null" || !childDepartmentIds?.length) continue;

      if (departmentIdToDateKeysMapping) {
        dateKeys = Object.keys(departmentIdToDateKeysMapping[parentDepartmentId]);
      } else {
        dateKeys = dateKeysMapping[template.id];
      }

      for (const dateKey of dateKeys) {
        const parentTotalCacheKey = {
          rowId,
          departmentId: parentDepartmentId,
          scenarioId,
          dateKey,
          balance: rowIsBalance,
          total: true,
        };

        const parentSelfKey = {
          rowId,
          departmentId: parentDepartmentId,
          scenarioId,
          dateKey,
          balance: rowIsBalance,
          total: false,
        };

        addToBothCaches({cache, cell: parentTotalCacheKey, dependsOn: parentSelfKey});

        for (const childRowId of [rowId, ...childRowIds]) {
          for (const childId of childDepartmentIds) {
            const childHasChildren = !!state.departments.idsByParent[childId]?.length;
            const childCacheKey = {
              rowId: childRowId,
              departmentId: childId,
              scenarioId,
              dateKey,
              balance: rowIsBalance,
              total: childHasChildren,
            };

            addToBothCaches({cache, cell: parentTotalCacheKey, dependsOn: childCacheKey});
          }
        }
      }
    }
  }
}
