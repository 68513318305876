import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./styles.jss";

export type CheckboxColors = "orange";

export interface CheckboxProps {
  checked?: boolean;
  text?: string;
  color?: CheckboxColors;
  onClick?: (key: string, checked: boolean) => void;
  value?: string;
  disabled?: boolean;
}

export default function Checkbox({checked, text, color = "orange", onClick, value, disabled}: CheckboxProps) {
  const styles = useStyles();

  const classes = clsx(styles.checkboxMain, {[styles.checked]: checked, [styles.disabled]: disabled});

  const handleClick = () => {
    if (onClick) onClick(value || "", !checked);
  };

  return (
    <div className={classes} onClick={handleClick}>
      <div className={styles.iconContainer}>
        <SVGIcon name="checkbox" />
      </div>
      {text || ""}
    </div>
  );
}
