import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import {selectSelectedRow, selectSelectedTemplate} from "@features/templates/state/selectors";
import {toggleRowIdPendingState} from "@features/templates/state/slice";
import {getAllDateKeysBetween, resolveDatasourceDates} from "@shared/lib/date-utilities";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {integrationMappings} from "@shared/lib/integration-utilities";
import {selectScenarioId, selectVersionLocked} from "@state/global/slice";
import {selectAllIntegrations} from "@state/integrations/slice";
import {updateRowIntegration} from "@state/template-rows/slice";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
import type {DbIntegrationDatasource} from "@shared/types/datasources";

export interface IntegrationProps {}

export default function Integration({}: IntegrationProps) {
  const styles = useStyles();
  const classes = clsx(styles.integrationsTabMain);

  const dispatch = useAppThunkDispatch();

  const integrations = useAppSelector(selectAllIntegrations);
  const scenarioId = useAppSelector(selectScenarioId);

  const isVersionLocked = useAppSelector(selectVersionLocked);

  const integrationsItems: DropdownItem[] = integrations.map(
    (integration) =>
      ({
        key: integration.id,
        value: integrationMappings[integration.provider].name,
        disabled: integration.provider !== "snowflake",
        iconRight: integration.provider === "quickbooks-online" ? "qbLogo" : "snowflake",
      } as DropdownItem),
  );
  integrationsItems.unshift({key: null, value: "None"});

  const datasourceEntities = useAppSelector((state) => state.datasources.entities);
  const row = useAppSelector(selectSelectedRow);
  const template = useAppSelector(selectSelectedTemplate);
  const rowDatasourceIds = useAppSelector((state) => state.datasources.idsByRowId[row?.id ?? ""]);

  const rowDatasources = mapEntitiesToIds(datasourceEntities, rowDatasourceIds);

  if (!template || !scenarioId || (row?.type !== "generic" && row?.type !== "account")) return null;

  const actualsDatasource =
    (rowDatasources.find((ds) => !ds.start && ds.end === "-1a" && ds.type === "integration") as
      | DbIntegrationDatasource
      | undefined) ?? null;

  const integration = !!actualsDatasource
    ? integrations.find((item) => item.id === actualsDatasource?.integration_id)
    : null;

  const availableIntegrationRows: DropdownItem[] = [];
  if (integration?.provider === "snowflake") {
    for (const col of integration.data.availableColumns) {
      availableIntegrationRows.push({key: col, value: col});
    }
  }

  const handleColOrIntegrationSelect = (type: "col" | "integration") => async (item: DropdownItem) => {
    console.log(row);
    const payload = {
      rowId: row.id,
      integrationId: integration?.id ?? null,
      type,
      value: item.key,
    };

    const dispatchPromise = dispatch(updateRowIntegration(payload));

    const datasourceDates = resolveDatasourceDates(
      null,
      "-1a",
      template.options.start,
      template.options.end,
      template.options.lastMonthOfActuals,
    );

    const dateKeys = getAllDateKeysBetween(datasourceDates.start, datasourceDates.end);

    dispatch(toggleRowIdPendingState({rowId: row.id, dateKeys}));

    time(`handleColOrIntegrationSelect`, `Updating row integration for row ${row.name} to ${item.key}`);
    await dispatchPromise;
    timeEnd(`handleColOrIntegrationSelect`, `Updating row integration for row ${row.name} to ${item.key}`);

    dispatch(toggleRowIdPendingState({rowId: row.id, dateKeys: null}));
  };

  return (
    <Section
      title="Integration"
      wrapContent={false}
      className={classes}
      collapsed
      summary={integration?.provider ? integrationMappings[integration.provider].name : "None"}
    >
      <SectionCol>
        <FormElement
          label="Integration"
          tooltip="Templates::Sidebar::Integrations::Integration"
          className={styles.integrationsFirstSection}
        >
          <Dropdown
            items={integrationsItems}
            onSelect={handleColOrIntegrationSelect("integration")}
            selectedKey={actualsDatasource ? actualsDatasource.integration_id : null}
            disabled={isVersionLocked}
          />
        </FormElement>
        {actualsDatasource ? (
          <>
            {integration?.provider === "snowflake" ? (
              <FormElement label="Data Source" tooltip="Templates::Sidebar::Integrations::DataSource">
                <Dropdown
                  items={availableIntegrationRows}
                  onSelect={handleColOrIntegrationSelect("col")}
                  selectedKey={actualsDatasource.options.remoteId}
                />
              </FormElement>
            ) : null}
            <FormElement label="Sync Time Period" tooltip="Templates::Sidebar::Integrations::TimePeriod">
              <div className={styles.timePeriodRow}>
                <Dropdown disabled items={[]} onSelect={() => null} text="Beginning of Time" />
                <div>to</div>
                <Dropdown disabled items={[]} onSelect={() => null} text="Last Month of Actuals" />
              </div>
            </FormElement>
          </>
        ) : null}
      </SectionCol>
    </Section>
  );
}
