import {store} from "@app/client-store";
import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import {selectTemplateById} from "@shared/state/templates/slice";
import {makeSelectCachedValueForMonth} from "@shared/state/transaction-items/selectors";
import numeral from "numeral";

import {generateCsvString, getFilename, triggerCsvDownload} from "./export-utilities";

import type {RootState} from "@shared/state/store";
import type {TemplateOrdering, TemplateRow} from "@shared/types/db";

export async function exportTemplateAllData({
  format = "csv",
  templateId,
}: {
  format?: "csv" | "json";
  templateId: string;
}) {
  const state = store.getState();

  const template = selectTemplateById(state, templateId);
  if (!template) return;

  const dateKeys = getAllDateKeysBetween(template.options.start, template.options.end);

  const version = state.global.version ? state.versions.entities[state.global.version] ?? null : null;
  const globalDepartment = state.global.selectedDepartmentId
    ? state.departments.entities[state.global.selectedDepartmentId] ?? null
    : null;

  const exportedRows: any[] = [];
  function recursive({rowId, children}: TemplateOrdering, indentation: number) {
    const childRowIds = state.templateRows.idsByParentRowId[rowId];
    const hasChildRows = !!childRowIds?.length;
    const row = state.templateRows.entities[rowId];

    if (!row) return;
    exportedRows.push(getRowForExport({state, row, dateKeys, indentation}));

    if (children?.length) {
      const processedChildrenIds: string[] = [];
      for (const childOrdering of children) {
        if (processedChildrenIds.includes(childOrdering.rowId)) continue;
        recursive(childOrdering, indentation + 1);
        processedChildrenIds.push(childOrdering.rowId);
      }
    }
    if (hasChildRows && ((row.type !== "account" && row.type !== "generic") || !row.options.hidden))
      exportedRows.push(getRowForExport({state, row, dateKeys, indentation, isTotalRow: true}));
  }

  for (const rowOrdering of template.ordering) {
    recursive(rowOrdering, 0);
  }

  if (format === "csv") {
    const csvStr = generateCsvString({headers: Object.keys(exportedRows[0]), items: exportedRows});
    triggerCsvDownload({
      content: csvStr,
      filename: `${getFilename(template, version, globalDepartment)}.csv`,
    });
  } else {
    console.log(exportedRows);
    console.error("JSON export not implemented yet");
  }
}

function getRowForExport({
  state,
  row,
  dateKeys,
  indentation,
  isTotalRow,
}: {
  state: RootState;
  row: TemplateRow;
  dateKeys: string[];
  indentation: number;
  isTotalRow?: boolean;
}) {
  let indentationStr = "";
  for (let i = 0; i < indentation; i++) {
    indentationStr += "  ";
  }
  const exportedRow: any = {};

  if (row.type === "hiring-plan") {
    const employee = state.employees.entities[row.options.employee_id];
    const team = state.teams.entities[employee?.team_id || ""];
    const teamDepartment = state.departments.entities[team?.department_id || ""];
    exportedRow.employee = getEmployeeDisplayName(employee);
    exportedRow.display_name = exportedRow.employee;
    exportedRow.team = team?.display_name || "Unassigned";
    exportedRow.financial_component = row.options.fc_name;
    exportedRow.department = teamDepartment?.display_name || "";
  } else {
    exportedRow.row_name = `${indentationStr}${row.display_name}${isTotalRow ? " (Total)" : ""}`;
  }

  const isBalance = row.type === "account" ? !!row.options.balance : false;
  const reverseSign =
    (row.type === "account" || row.type === "generic") && row.mirror_of ? !!row.options.reverseSign : false;

  for (const dateKey of dateKeys) {
    let value =
      makeSelectCachedValueForMonth()(
        state,
        row.mirror_of || row.id,
        state.global.scenarioId!,
        dateKey,
        isTotalRow,
        isBalance,
      ) || null;
    if (reverseSign && !!value) value = value * -1;
    exportedRow[dateKey] =
      !!value && !(value > -0.000000001 && value < 0.000000001)
        ? numeral(value * (row.formatting?.percentage ? 100 : 1)).format("0.00") +
          (row.formatting?.percentage ? "%" : "")
        : "";
  }

  return exportedRow;
}
