import {useAppSelector} from "@app/hooks";
import {selectIsSuperuser} from "@state/session/slice";
import clsx from "clsx";

import Integration from "./Integration";
import Datasources from "./datasources";
import useStyles from "./styles.jss";
import Transactions from "./transactions";

export default function DataTab({expanded}: {expanded: boolean}) {
  const styles = useStyles();
  const isSu = useAppSelector(selectIsSuperuser);

  return (
    (<div className={clsx(styles.transactionsTabMain, {[styles.expanded]: expanded})}>
      <Integration />
      <Transactions expanded={expanded} />
      {isSu ? <Datasources expanded={expanded} /> : null}
    </div>)
  );
}
