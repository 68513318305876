import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

function refChunkClassesStr(...subtypes: string[]): string {
  return _refChunkClassesStr(subtypes, true);
}

function genericRefChunkClassesStr(...subtypes: string[]): string {
  return _refChunkClassesStr(subtypes, false);
}

function _refChunkClassesStr(subtypes: string[], needsToBeValidated: boolean): string {
  return subtypes
    .map(
      (subtype) =>
        `& span.t_cb_ref.st_${subtype.replace("_value", "")}${
          needsToBeValidated ? ".validated" : ""
        }, & span.t_cb_advanced_ref.st_${subtype.replace("_value", "")}${needsToBeValidated ? ".validated" : ""}`,
    )
    .join(", ");
}

export default createUseStyles({
  formulaEditorInputContainer: {
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    cursor: "text",

    "$plainText &": {
      fontFamily: "Mont",
    },
  },
  formulaEditorInput: {
    alignItems: "center",
    background: "transparent",
    // background: "rgba(235, 135, 235, 0.2)",
    border: 0,
    caretColor: "black",
    color: "transparent",
    // color: "red",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontSize: 12,
    fontWeight: 500,
    height: "100%",
    lineHeight: 25,
    marginLeft: -13,
    paddingRight: 12,
    outline: "none",

    width: "100%",
    fontFamily: `"Red Hat Mono", Mont, sans-serif`,
    whiteSpace: "pre",

    "&$plainText": {
      paddingLeft: 25,
      fontFamily: "Mont",
      marginTop: -2,
    },

    "&.component-formulaBar": {
      marginLeft: 0,
      paddingRight: 0,
      paddingLeft: 12,
    },
  },
  prettyPrintedWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    // opacity: 0,

    "$alignRight &": {
      justifyContent: "flex-end",
    },
  },
  formulaPrettyPrinted: {
    alignItems: "center",
    caretColor: "black",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    fontWeight: 500,
    height: "100%",
    outline: "none",
    paddingLeft: 12,
    paddingRight: 12,
    position: "relative",
    fontFamily: `"Red Hat Mono", Mont, sans-serif`,
    whiteSpace: "pre",

    "&$plainText": {
      fontFamily: "Mont",
    },

    "&$alignRight": {
      justifyContent: "flex-end",
    },

    [genericRefChunkClassesStr("start", "end")]: {
      color: "#bb36c9",
    },
    // "& span.t_cb_ref.st_row, & span.t_cb_ref.st_template, & span.t_cb_ref.st_template_delimiter": {
    [refChunkClassesStr("row", "template", "template_delimiter", "row_template", "row_template_delimiter")]: {
      color: colors.primary.gold,
    },
    // "& span.t_cb_ref.st_range, & span.t_cb_ref.st_range_start, & span.t_cb_ref.st_range_end, & span.t_cb_ref.st_range_delimiter":
    [genericRefChunkClassesStr("range", "range_start", "range_end", "range_delimiter")]: {
      color: colors.secondary.green,
    },
    // "& span.t_cb_ref.st_department": {
    [refChunkClassesStr("department")]: {
      color: "#4571c4",
    },
    [refChunkClassesStr("vendor")]: {
      color: colors.tertiary.blue,
    },
  },
  plainText: {
    "&:not($formulaEditorInput)": {
      marginTop: 1,
      marginLeft: -3,
    },
  },
  disabled: {
    pointerEvents: "none",
    background: colors.ui.lightBlue08,

    "& > span": {
      opacity: 0.8,
    },
  },
  alignRight: {
    "& input": {
      textAlign: "right",
    },
  },
  generic: {
    color: colors.text.black,
  },
  rowName: {
    color: colors.primary.gold,
  },
  range: {
    color: colors.secondary.green,
  },
  range_start: {
    color: colors.secondary.green,
  },
  range_end: {
    color: colors.secondary.green,
  },
  department: {
    color: "#4571c4",
  },
  refFunction: {
    color: "#bb36c9",
  },
  vendor: {
    color: colors.tertiary.blue,
  },
});
