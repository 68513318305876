import api from "@shared/lib/fetch";

import type {Version} from "@shared/types/db";
import type {ApiEndpoints} from "@shared/state/store";

const endpoints = {
  fetchAll(_args, thunkAPI) {
    return api.get<Version[]>(thunkAPI, "versions");
  },
  create(
    {displayName, description, sourceVersion}: {displayName: string; description?: string; sourceVersion?: string},
    thunkAPI,
  ) {
    const body: Record<string, any> = {displayName, description, sourceVersion};
    return api.post<void>(thunkAPI, "versions", body);
  },
} satisfies ApiEndpoints;

export default endpoints;
