import {DEBUG, time, timeEnd} from "@shared/lib/debug-provider";

import {buildDependencyCache} from "../cache/dependency-cache";
import {makeDependencyKeyHumanReadable} from "../cache/dependency-cache-debug-utilities";

import type {ScenariosState} from "@state/entity-adapters";
import type {RootState} from "@state/store";
import type {DependencyCache} from "../cache/dependency-cache";

export function flagDirtyCells({
  scenarioId,
  state,
  cacheKeys,
  recalcTriggerCells,
  dependencyCache: providedDependencyCache,
  limitToAffectedRows: limitToSameRow,
}: {
  dependencyCache?: DependencyCache;
  scenarioId: string;
  recalcTriggerCells: boolean;
  state: RootState;
  cacheKeys: string[];
  limitToAffectedRows?: boolean;
}) {
  if (DEBUG) time("[FlagDirtyCells]", `Build dependency cache (scenario: ${scenarioId})`);
  const dependencyCache: DependencyCache = !providedDependencyCache
    ? buildDependencyCache({scenarioId, state, rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds})
    : providedDependencyCache;
  if (DEBUG) timeEnd("[FlagDirtyCells]", `Build dependency cache (scenario: ${scenarioId})`);
  if (!dependencyCache) return {};

  const dirty: Record<string, null | Set<string>> = {};

  function recursive(cacheKey: string, limitToRowId?: string) {
    // Mark the cell as dirty first
    if (recalcTriggerCells && typeof dirty[cacheKey] === "undefined")
      dirty[cacheKey] = dependencyCache.cellToDependencies[cacheKey] ?? new Set();

    // Find its dependents
    const cellsToMarkDirty = dependencyCache.dependencyToCells[cacheKey];
    if (!cellsToMarkDirty) return;

    // For each cell that depends on the updated rowId for the current dateKey
    // Needs to include the totals as well
    for (const cellToMarkDirty of cellsToMarkDirty) {
      if (limitToRowId && !cellToMarkDirty.includes(limitToRowId)) continue;
      if (!!dirty[cellToMarkDirty] || !dependencyCache.fullList[cellToMarkDirty]) continue;

      if (dependencyCache.cellToDependencies[cellToMarkDirty]) {
        dirty[cellToMarkDirty] = dependencyCache.cellToDependencies[cellToMarkDirty];
      }
      recursive(cellToMarkDirty, limitToRowId);
    }
  }

  // Init the recursive function
  if (DEBUG) time("[FlagDirtyCells]", "Main loop");

  for (const key of cacheKeys) {
    // For each rowId (and its dateKeys) that have initially been updated, mark its dependents as dirty recursively
    let rowId = limitToSameRow ? key.split("::")[0] : undefined;
    recursive(key, rowId);
  }

  if (DEBUG) timeEnd("[FlagDirtyCells]", "Main loop");

  if (DEBUG) logDirty(dirty, state);

  return dirty;
}

export function logDirty(
  dirty: Record<string, Set<string> | null>,
  state: Pick<RootState, "departments" | "templateRows"> & {scenarios?: ScenariosState},
  skipNulls: boolean | null = false,
  tail: number | null = 0,
  rowNameFilter: string | null = null,
  returnValue: boolean = false,
) {
  const dirtyKeys: Record<string, string[] | null> = {};
  let dirtyEntriesToDisplay = Object.entries(dirty);
  if (tail) dirtyEntriesToDisplay = dirtyEntriesToDisplay.slice(-tail);

  for (const [key, values] of dirtyEntriesToDisplay) {
    if (values === null && skipNulls) continue;
    const updatedKeyName = makeDependencyKeyHumanReadable(key, state, false, true);
    if (!!rowNameFilter && !updatedKeyName.includes(rowNameFilter)) continue;

    const newSet: Set<string> | null = !!values ? new Set() : null;
    if (!!newSet && !!values) {
      for (const key of values) {
        newSet.add(makeDependencyKeyHumanReadable(key, state, false, true));
      }
    }
    dirtyKeys[updatedKeyName] = !newSet ? null : [...newSet];
  }
  if (!returnValue) {
    // eslint-disable-next-line no-console
    console.log(`Dirty keys:`, dirtyKeys);
  } else {
    return dirtyKeys;
  }
}

export function logDirtyCellsWithDependencies(
  state: Pick<RootState, "templateRows" | "departments">,
  dirtyCells: Record<string, Set<string> | null>,
  rowNameFilter: string | null = null,
) {
  console.log(
    Object.keys(dirtyCells)
      .filter((k) => (rowNameFilter ? k.includes(state.templateRows.idsByName[rowNameFilter] ?? "") : true))
      .map(
        (k) =>
          `${makeDependencyKeyHumanReadable(k, state, false, true)} -> ${[...(dirtyCells[k] || [])]
            .map((k) => makeDependencyKeyHumanReadable(k, state, false, true))
            .join(", ")}`,
      )
      .join("\n"),
  );
}
