import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import {pauseListener} from "@app/websockets/websockets";
import Button from "@components/Button/V2";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import Modal from "@components/Modal";
import Tabs from "@components/Tabs";
import TextInput from "@components/TextInput";
import {nanoid} from "@reduxjs/toolkit";
import {api, selectAllVersions, upsertVersions} from "@state/versions/slice";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {useState} from "react";

import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
dayjs.extend(advancedFormat);

interface SaveVersionProps {
  onClose: () => void;
}
const tabsList = [{name: "details", value: "Details"}];

export default function SaveVersion({onClose}: SaveVersionProps) {
  const styles = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sourceVersion, setSourceVersion] = useState<string | null>(null);

  const dispatch = useAppThunkDispatch();
  const versions = useAppSelector(selectAllVersions);

  const items = [
    {key: null, value: "Current Version"},
    ...versions.map((version) => ({key: version.id, value: version.display_name})),
  ];

  const handleSave = async () => {
    pauseListener("out-of-sync");
    dispatch(
      askForConfirmation({
        title: "Confirm Long Wait Time",
        backgroundImage: "/assets/images/ui/modals/notifs/blue-default.svg",
        mainColor: "blue",
        actionButtonText: "Confirm",
        text: `Saving a version can <b>take up to 2-3 minutes and lock everyone from using Cloudberry</b>.<br /><br />You'll see a notification once the version has been saved.`,
        onConfirm: () => {
          dispatch(
            upsertVersions([
              {
                id: nanoid(),
                display_name: name,
                description,
                availability: "hot",
                status: "initializing",
                schema: "___",
                company_id: "",
                created_at: dayjs().toISOString(),
              },
            ]),
          );
          const apiCallParams: Parameters<typeof api.createVersion>[0] = {displayName: name, description};
          if (sourceVersion) apiCallParams.sourceVersion = sourceVersion;

          dispatch<any>(api.createVersion(apiCallParams));

          onClose();
        },
      }),
    );
  };

  const handleDuplicateScenarioSelect = (item: DropdownItem) => {
    setSourceVersion(item?.key ?? null);
  };

  return (
    <Modal onClose={onClose} backgroundImage="/assets/images/ui/version-modal-bg.svg">
      <div className={styles.createVersionMain}>
        <h3>Save Version</h3>
        <Tabs tabs={tabsList} selectedTab={tabsList[0]} onChange={() => null} />

        <div className={styles.firstRow}>
          <div>
            <FormElement label="Version Name" tooltip="Versions::SaveVersionModal::VersionName">
              <TextInput
                placeholder="Enter Version name..."
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />
            </FormElement>
          </div>
          <div>
            <FormElement label="Version to Save">
              <Dropdown
                items={items}
                onSelect={handleDuplicateScenarioSelect}
                renderAsChild
                selectedKey={sourceVersion}
              />
            </FormElement>
          </div>
        </div>
        <div className={styles.secondRow}>
          <FormElement label="Description" className={styles.secondRowFormElement}>
            <TextInput
              multiline
              placeholder="Version description..."
              className={styles.inputWrapper}
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
            />
          </FormElement>
        </div>
        <div className={styles.buttonsRow}>
          <Button color="orange" text="Save" onClick={handleSave} disabled={!name} />
          <Button text="Cancel" color="grey" onClick={onClose} isLink />{" "}
        </div>
      </div>
    </Modal>
  );
}
