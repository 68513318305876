import {useAppDispatch, useAppSelector} from "@app/hooks";
import RDPDatePicker from "@components/Datepicker/RDP"; // Update the import path
import TextInput from "@components/TextInput"; // Update the import path
import {addDeltaMonthsToDateKey, getFormattedFullDate} from "@shared/lib/date-utilities";
import {api as adminAPI} from "@shared/state/admin/slice";
import {changeLastMonthOfActuals, selectAllTemplates, upsertTemplate} from "@state/templates/slice";
import React, {useState} from "react";

import useStyles from "./styles.jss";

import Button from "@components/Button/V2";
import SVGIcon from "@components/SVGIcon";
import type {TextInputProps} from "@components/TextInput";
import type {Template, TemplateOptions} from "@shared/types/db";
import clsx from "clsx";

export interface StatsProps {}

interface ScriptActionProps {
  scriptName: "recalc" | "extend-ranges-until-end" | "recreate-all-hiring-plan-transactions";
  label: string;
  disabled: boolean;
  scriptRunning: Record<string, boolean>;
  runResult: Record<string, boolean | null>;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ScriptAction: React.FC<ScriptActionProps> = ({
  scriptName,
  label,
  disabled,
  scriptRunning,
  runResult,
  onClick,
}) => {
  const styles = useStyles();
  return (
    <li>
      <span>{label}</span>
      <Button color="orange" size="xsmall" text="Run" onClick={onClick} disabled={disabled} />
      {scriptRunning[scriptName] && (
        <span className={styles.resultIcon}>
          <SVGIcon name="spinner" />
        </span>
      )}
      {runResult[scriptName] !== null && (
        <span className={clsx(styles.resultIcon, runResult[scriptName] ? styles.success : styles.error)}>
          <SVGIcon name={runResult[scriptName] ? "checkmark" : "errorExclamationPoint"} />
        </span>
      )}
    </li>
  );
};

const TemplateSettings: React.FC<StatsProps> = () => {
  const styles = useStyles();
  const templates = useAppSelector(selectAllTemplates);
  const dispatch = useAppDispatch();
  const [pendingRecalc, setPendingRecalc] = useState(false);
  const [runResult, setRunResult] = useState<Record<string, boolean | null>>({
    recalc: null,
    "extend-ranges-until-end": null,
    "recreate-all-hiring-plan-transactions": null,
  });
  const [scriptRunning, setScriptRunning] = useState<Record<string, boolean>>({
    recalc: false,
    "extend-ranges-until-end": false,
    "recreate-all-hiring-plan-transactions": false,
  });

  const handleTextInputChange =
    (template: Template): TextInputProps["onChange"] =>
    (event) => {
      dispatch(upsertTemplate({...template, display_name: event.target.value}));
    };

  const handleDatePickerChange = <
    K extends keyof TemplateOptions & ("start" | "end" | "visibleStart" | "visibleEnd" | "lastMonthOfActuals"),
  >(
    template: Template,
    key: K,
    value: TemplateOptions[K],
  ) => {
    const templatesImpacted =
      template.type === "financial_statement" ? templates.filter((t) => t.type === "financial_statement") : [template];
    let formattedValue = typeof value === "string" ? value.slice(0, 7) : value;

    if (key === "lastMonthOfActuals" && typeof formattedValue === "string") {
      dispatch(
        changeLastMonthOfActuals({
          templateIds: templatesImpacted.map(({id}) => id),
          newLMOA: formattedValue,
        }),
      );
      setPendingRecalc(true);
    } else {
      templatesImpacted.forEach((template) => {
        const finalValue =
          template.name === "balance_sheet" && key === "start"
            ? addDeltaMonthsToDateKey(formattedValue, -1)
            : formattedValue;
        dispatch(upsertTemplate({...template, options: {...template.options, [key]: finalValue}}));
      });
      if (["start", "end"].includes(key)) setPendingRecalc(true);
    }
  };

  const handleRunScriptClick =
    (
      scriptName: "recalc" | "extend-ranges-until-end" | "recreate-all-hiring-plan-transactions",
    ): React.MouseEventHandler<HTMLButtonElement> =>
    async () => {
      setScriptRunning((prev) => ({...prev, [scriptName]: true}));
      const result = await dispatch<any>(adminAPI.runUtility({utility: scriptName, dryRun: false, allSchemas: false}));
      const success = result && !result.payload.error;
      setRunResult((prev) => ({...prev, [scriptName]: success}));
      setScriptRunning((prev) => ({...prev, [scriptName]: false}));
    };

  return (
    <div className={styles.templateSettings}>
      <table className={clsx(styles.table, pendingRecalc && styles.disabled)}>
        <thead>
          <tr>
            <th className={styles.th}>Display Name</th>
            <th className={styles.th}>Start</th>
            <th className={styles.th}>End</th>
            <th className={styles.th}>Last Month of Actuals</th>
            <th className={styles.th}>Visible Start</th>
            <th className={styles.th}>Visible End</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template) => (
            <tr key={template.id}>
              <td className={styles.td}>
                <TextInput
                  value={template.display_name}
                  onChange={handleTextInputChange(template)}
                  changeTrigger="blur"
                  onChangeDelay={750}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.start, "start")}
                  onChange={(date) => handleDatePickerChange(template, "start", date || "")}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.end, "end")}
                  onChange={(date) => handleDatePickerChange(template, "end", date || "")}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.lastMonthOfActuals, "end")}
                  onChange={(date) => handleDatePickerChange(template, "lastMonthOfActuals", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.visibleStart, "start")}
                  onChange={(date) => handleDatePickerChange(template, "visibleStart", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
              <td className={styles.td}>
                <RDPDatePicker
                  disabled={["balance_sheet", "cash_flow_statement"].includes(template.name)}
                  inline
                  value={getFormattedFullDate(template.options.visibleEnd, "end")}
                  onChange={(date) => handleDatePickerChange(template, "visibleEnd", date || "")}
                  max={template.options.end}
                  min={template.options.start}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pendingRecalc && (
        <div className={styles.templateChangeWarning}>
          <h2>Attention</h2>
          You have edited a template date that requires triggering multiple recalculations.
          <br />
          Please now run the following scripts:
          <ul>
            <ScriptAction
              scriptName="extend-ranges-until-end"
              label="1. Extend employee ranges"
              disabled={
                scriptRunning["extend-ranges-until-end"] || !pendingRecalc || !!runResult["extend-ranges-until-end"]
              }
              scriptRunning={scriptRunning}
              runResult={runResult}
              onClick={handleRunScriptClick("extend-ranges-until-end")}
            />
            <ScriptAction
              scriptName="recreate-all-hiring-plan-transactions"
              label="2. Recreate all hiring plan transactions"
              disabled={
                scriptRunning["recreate-all-hiring-plan-transactions"] ||
                !runResult["extend-ranges-until-end"] ||
                !!runResult["recreate-all-hiring-plan-transactions"]
              }
              scriptRunning={scriptRunning}
              runResult={runResult}
              onClick={handleRunScriptClick("recreate-all-hiring-plan-transactions")}
            />
            <ScriptAction
              scriptName="recalc"
              label="3. Run global recalc"
              disabled={
                scriptRunning.recalc || !runResult["recreate-all-hiring-plan-transactions"] || !!runResult.recalc
              }
              scriptRunning={scriptRunning}
              runResult={runResult}
              onClick={handleRunScriptClick("recalc")}
            />
          </ul>
        </div>
      )}
    </div>
  );
};

export default TemplateSettings;
