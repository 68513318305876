import {createSlice} from "@reduxjs/toolkit";
import {applyDiffToMonthlyCache as applyDiffToMonthlyCacheFn} from "@shared/data-functions/cache/cache-utilities";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {projKey} from "@shared/state/utils";
import {isWebWorker} from "browser-or-node";

import {api} from "../cb-tx/slice";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {MonthlyCacheDiff} from "@shared/data-functions/cache/cache-utilities";

export const getInitialState = () => ({
  valuesByRowIdDateKey: {} as Record<string, number>,
  initialMonthlyCache: {} as Record<string, number>,
});

export type TxItemsBaseState = ReturnType<typeof getInitialState>;

export const getSlice = () =>
  createSlice({
    name: "transactionItems",
    initialState: {
      ...getInitialState(),
    },
    reducers: {
      monthlyCacheUpdated(
        state,
        action: PayloadAction</*({scenarioId: string; triggerAlgo: true} | {scenarioId?: string; triggerAlgo: false}) & */ {
          valuesByRowIdDateKey: TxItemsBaseState["valuesByRowIdDateKey"];
        }>,
      ) {
        state.valuesByRowIdDateKey = action.payload.valuesByRowIdDateKey;
      },

      generateMonthlyCache(
        _state,
        _action: PayloadAction<{
          sendToMainThread: boolean;
          fullCache?: boolean;
        }>,
      ) {},

      applyDiffToMonthlyCache(
        state,
        action: PayloadAction<{
          diff: MonthlyCacheDiff;
        }>,
      ) {
        // if (!isWebWorker) debugger;
        log("ApplyDiffToMonthlyCache", `Applying diff to monthly cache`);
        const logLabel = `Completed execution (updatedKeys: ${
          Object.keys(action.payload.diff.updatedCacheKeys).length
        }, deletedKeys: ${action.payload.diff.deletedCacheKeys.length})`;
        time("ApplyDiffToMonthlyCache", logLabel);
        const currentCache = state.valuesByRowIdDateKey;
        const newCache = applyDiffToMonthlyCacheFn(currentCache, action.payload.diff);
        if (newCache) state.valuesByRowIdDateKey = newCache;
        timeEnd("ApplyDiffToMonthlyCache", logLabel);
      },

      clearMonthlyCacheForDateKeys(
        state,
        action: PayloadAction<{dateKeys: string[]; rowId: string; scenarioId: string; removeVirtualTx: boolean}>,
      ) {
        console.log(`Called clearMonthlyCacheForDateKeys in ${isWebWorker ? "worker" : "main thread"}`);
        for (const dateKey of action.payload.dateKeys) {
          const cacheKey = projKey(action.payload.rowId, action.payload.scenarioId, dateKey);
          if (state.valuesByRowIdDateKey[cacheKey]) delete state.valuesByRowIdDateKey[cacheKey];
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchMonthlyCache.fulfilled, (state, action) => {
        state.initialMonthlyCache = {...action.payload.monthlyCache};
        state.valuesByRowIdDateKey = action.payload.monthlyCache;
      });
    },
  });

export const slice = getSlice();

const reducer = slice.reducer;

export const {monthlyCacheUpdated, generateMonthlyCache, applyDiffToMonthlyCache} = slice.actions;
export const {clearMonthlyCacheForDateKeys} = createSyncedActionCreators(slice.actions);

export type TxItemsActions = typeof slice.actions;

export default reducer;

export * from "./selectors";
