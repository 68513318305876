import {defaultMotionFadeIn} from "@app/styles/general-ui";
import RoundButton from "@components/RoundButton";
import clsx from "clsx";
import * as React from "react";
import {Suspense} from "react";
import {createPortal} from "react-dom";
import {useKeyPressEvent} from "react-use";

import useStyles from "./styles.jss";

import type {CSSProperties} from "react";

const AnimatePresence = React.lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));
const MotionDiv = React.lazy(async () => ({default: (await import("framer-motion")).motion.div}));

export interface ModalProps {
  backgroundImage?: string;
  closeButton?: boolean;
  closeOnEsc?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
export default function Modal({backgroundImage, closeButton = true, children, onClose, closeOnEsc}: ModalProps) {
  const styles = useStyles();
  const classes = clsx(styles.modalMain);

  const domElement = document.querySelector("#root");

  useKeyPressEvent(
    (evt) => evt.key === "Esc",
    () => {
      if (closeOnEsc) onClose();
    },
  );

  if (!domElement) return null;

  const mainStyles: CSSProperties = {
    backgroundImage: backgroundImage ? `url('${backgroundImage}')` : "none",
  };

  const handleClose = () => {
    onClose();
  };

  return createPortal(
    <div className={styles.backdrop}>
      <Suspense>
        <AnimatePresence>
          <MotionDiv {...defaultMotionFadeIn} className={classes} style={mainStyles}>
            {closeButton ? (
              <div className={styles.closeButton} onClick={handleClose}>
                <RoundButton enableCssStates icon="cross" iconSize={22} white />
              </div>
            ) : null}
            {children}
          </MotionDiv>
        </AnimatePresence>
      </Suspense>
    </div>,
    domElement,
  );
}
