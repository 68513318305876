import {store} from "@app/client-store";
import id from "@shared/lib/id";
import {upsertDepartments} from "@state/departments/slice";
import {getUniqueSlug} from "@shared/lib/slug";

import {setSelectedEntityId, toggleSidebarOpen} from "./slice";

import type {MenuElement} from "@components/Menu";

export function getNewMenuElements(): {elements: MenuElement[]; onChange: (element: MenuElement) => void} {
  const onChange = (element: MenuElement) => {
    const state = store.getState();
    const departmentId = id();
    store.dispatch(
      upsertDepartments([
        {
          id: departmentId,
          display_name: "",
          name: getUniqueSlug("New Department", Object.keys(state.departments.idsByName)).slug,
          filters: [],
        },
      ]),
    );
    store.dispatch(setSelectedEntityId(departmentId));
    store.dispatch(toggleSidebarOpen(true));
  };

  return {
    onChange,
    elements: [
      {
        type: "item",
        text: "Department",
        icon: "department",
      },
    ],
  };
}

export default {
  getNewMenuElements,
};
