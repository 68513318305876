import {createSelector, createSlice} from "@reduxjs/toolkit";
import {generateClientSelectors} from "@shared/lib/misc";

import {selectors as hiringPlanSelectors, selectFilteredAndSortedEmployeesForListView} from "../slice";

import type {ClientRootState, ClientThunkAction} from "@app/client-store";
import type {ActionCreator, PayloadAction} from "@reduxjs/toolkit";
import type {Employee} from "@shared/types/hiring-plan";
import type {ColumnDefinition} from "./Columns";

export interface HiringPlanListView {
  selectedEntityIndex: number | null;
  selectedColIndex: number | null;
  cellBeingEdited: {value: string; component: "formulaBar" | "cell"} | null;
  sidebarOpen: boolean;
  displayedColumns: string[] | null;
}

const initialState: HiringPlanListView = {
  selectedEntityIndex: null,
  selectedColIndex: null,
  cellBeingEdited: null,
  sidebarOpen: false,
  displayedColumns: null,
};

const slice = createSlice({
  name: "hiringPlanListView",
  initialState,
  reducers: {
    updateHiringPlanListView: (state, action: PayloadAction<Partial<HiringPlanListView>>) => ({
      ...state,
      ...action.payload,
    }),
    updateEditedCellValue: (state, action: PayloadAction<string>) => {
      if (!state.cellBeingEdited) return;
      state.cellBeingEdited.value = action.payload;
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const moveSelectedCell: ActionCreator<ClientThunkAction> =
  (direction: "up" | "down" | "left" | "right") => (dispatch, getState) => {
    const state = getState();
    const employees = selectListViewEntities(state);
    const selectedEntityIndex = selectors.selectedEntityIndex(state);
    const selectedColIndex = selectors.selectedColIndex(state);
    const columns = hiringPlanSelectors.columns(state);

    if (["up", "down"].includes(direction)) {
      if (selectedEntityIndex === null) return;
      if (
        (direction === "up" && selectedEntityIndex === 0) ||
        (direction === "down" && selectedEntityIndex === employees.length - 1)
      )
        return;
      dispatch(updateHiringPlanListView({selectedEntityIndex: selectedEntityIndex + (direction === "up" ? -1 : 1)}));
    } else {
      if (selectedColIndex === null) return;
      if (
        (direction === "left" && selectedColIndex === 0) ||
        (direction === "right" && selectedColIndex === columns.length - 1)
      )
        return;
      dispatch(updateHiringPlanListView({selectedColIndex: selectedColIndex + (direction === "left" ? -1 : 1)}));
    }
  };

export const enableEditing: ActionCreator<ClientThunkAction> =
  (component?: "formulaBar" | "cell", valueOverride?: string) => (dispatch, getState) => {
    const state = getState();

    const colIndex = state.hiringPlanListView.selectedColIndex;
    const entityIndex = state.hiringPlanListView.selectedEntityIndex;

    if (colIndex === null || entityIndex === null) return;

    let value: string | number = valueOverride ?? "";

    if (typeof valueOverride === "undefined") {
      value = state.hiringPlan.columns[colIndex].valueResolver(entityIndex) ?? "";
    }
    dispatch(
      updateHiringPlanListView({
        cellBeingEdited: {
          component: component || "cell",
          value: value.toString(),
        },
      }),
    );
  };

export const clearValueForSelectedCell: ActionCreator<ClientThunkAction> = () => (dispatch, getState) => {
  const state = getState();
  const selectedColIndex = selectors.selectedColIndex(state);
  const selectedEntityIndex = selectors.selectedEntityIndex(state);
  if (selectedColIndex === null || selectedEntityIndex === null) return;
  const col = state.hiringPlan.columns[selectedColIndex];
  if (
    !col.readOnly &&
    (col.dataType === "text" || col.dataType === "number" || (col.dataType === "date" && col.name === "term_date"))
  ) {
    const handler =
      col.dataType === "date" ? col.blurHandler(selectedEntityIndex) : col.blurHandler(selectedEntityIndex);
    handler({target: {value: ""}});
  }
};

export const selectCell =
  (col: ColumnDefinition, employee: Employee): ClientThunkAction =>
  async (dispatch, getState) => {
    const state = getState();
    const {indexMapping} = selectFilteredAndSortedEmployeesForListView(state);
    const columns = state.hiringPlan.columns;

    dispatch(
      updateHiringPlanListView({
        selectedEntityIndex: indexMapping[employee.id] ?? null,
        selectedColIndex: columns.findIndex((item) => item.name === col.name),
        cellBeingEdited: null,
      }),
    );
  };

export const selectors = generateClientSelectors("hiringPlanListView", slice.getInitialState());

export const selectListViewEntities = createSelector(selectFilteredAndSortedEmployeesForListView, ({list}) =>
  list.map((item) => item.employee),
);

export const selectListViewEntityByIndex = createSelector(
  selectListViewEntities,
  (_state: ClientRootState, index: number | null) => index,
  (entities, index) => (index !== null ? entities[index] || null : null),
);

export const selectSelectedEntity = createSelector(
  selectors.selectedEntityIndex,
  selectListViewEntities,
  (index, entities) => (index !== null ? entities[index] || null : null),
);

export const makeSelectCellIsSelected = () =>
  createSelector(
    selectors.selectedEntityIndex,
    selectors.selectedColIndex,
    (_state: ClientRootState, {entityIndex, colIndex}: {entityIndex: number; colIndex: number}) => ({
      entityIndex,
      colIndex,
    }),
    (selectedEntityIndex, selectedColIndex, {colIndex, entityIndex}) =>
      selectedColIndex === colIndex && selectedEntityIndex === entityIndex,
  );

export const makeSelectCellIsBeingEdited = () =>
  createSelector(
    selectors.cellBeingEdited,
    (_state: ClientRootState, cellIsSelected: boolean) => cellIsSelected,
    (cellBeingEdited, cellIsSelected) => cellIsSelected && !!cellBeingEdited,
  );

export const makeSelectValueBeingEdited = () =>
  createSelector(
    selectors.cellBeingEdited,
    (_state: ClientRootState, cellIsSelected: boolean) => cellIsSelected,
    (cellBeingEdited, cellIsSelected) => (cellIsSelected && !!cellBeingEdited ? cellBeingEdited.value : null),
  );

export const {setSidebarOpen, updateHiringPlanListView, updateEditedCellValue} = slice.actions;

export default slice.reducer;
