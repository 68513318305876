import {handleIntegrationSync} from "@features/settings/Integrations/state-utils";
import {DEBUG} from "@shared/lib/debug-provider";
import {triggerSync} from "@state/integrations/slice";
import {authLocal, deleteSession} from "@state/session/slice";

import type {ListenerEffect} from "@reduxjs/toolkit";
import type {RouteContext} from "@shared/types/server/Context";
import type {AppStartListening} from "@state/listener-middleware";
import type {AppThunkDispatch, RootState} from "@state/store";

export const redirectAfterLogout: ClientActionCreatorEffect<
  "deleteSession.fulfilled" | "deleteSession.rejected"
> = () => {
  if (window.location.pathname !== "/login") window.location.assign("/login");
};

export const redirectAfterLogin: ClientActionCreatorEffect<"authLocal.fulfilled"> = () => {
  if (window.location.pathname.includes("/login")) window.location.assign("/reports");
};

export const triggerSyncEffect: ClientActionCreatorEffect<"triggerSync"> = (action, {dispatch}) => {
  dispatch<any>(handleIntegrationSync({integrationId: action.payload}));
};

const listenersList = [
  {actionCreators: ["deleteSession.fulfilled", "deleteSession.rejected"], effects: [redirectAfterLogout]},
  {actionCreators: ["authLocal.fulfilled"], effects: [redirectAfterLogin]},
  {actionCreators: ["triggerSync"], effects: [triggerSyncEffect]},
];

export const listOfEvents = {
  "deleteSession.fulfilled": deleteSession.fulfilled,
  "deleteSession.rejected": deleteSession.rejected,
  "authLocal.fulfilled": authLocal.fulfilled,
  triggerSync,
};
export type ListOfClientEvents = typeof listOfEvents;

export type ClientActionCreatorEffect<K extends keyof ListOfClientEvents> = ListenerEffect<
  ReturnType<ListOfClientEvents[K]> & {
    meta?: {receivedFromWs?: boolean; ctx?: RouteContext};
  },
  RootState,
  AppThunkDispatch
>;

export default listenersList;

export function registerClientListeners(startListening: AppStartListening) {
  const registeredListeners: Record<string, string[]> = {};
  let nbListeners: number = 0;
  for (const item of listenersList) {
    const listenerItem = item as typeof item & {skipIfSyncedAction?: boolean};
    for (const actionCreator of listenerItem.actionCreators) {
      if (!registeredListeners[actionCreator]) registeredListeners[actionCreator] = [];
      for (const effect of listenerItem.effects) {
        const wrappedEffect = (...args: Parameters<typeof effect>) => {
          if (listenerItem.skipIfSyncedAction && args[0].meta?.receivedFromWs) return;
          if (DEBUG)
            // eslint-disable-next-line no-console
            console.log(
              `⚡ Fired listener effect "${effect.name}" following "${actionCreator}" event with following action:`,
              args[0],
            );
          // @ts-ignore
          effect(...args);
        };

        startListening({
          // @ts-ignore
          actionCreator: listOfEvents[actionCreator],
          // @ts-ignore
          effect: wrappedEffect,
        });
        registeredListeners[actionCreator].push(effect.name);
        nbListeners++;
      }
    }
  }
  // eslint-disable-next-line no-console
  if (DEBUG) console.log(`Registered ${nbListeners} listeners:`, registeredListeners);
}
