import Popover from "@components/Popover";
import RoundIcon from "@components/RoundIcon";
import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";
import {cloneElement, useState} from "react";
import * as ReactIs from "react-is";
import {Link} from "react-router-dom";

import useStyles from "./styles.jss";

import type {PopoverGenericProps} from "@components/Popover";
import type {IconNames} from "@components/SVGIcon";

export type MenuHeader = {
  type: "header";
  subtext?: string;
  text: string;
};

export type MenuSeparator = {
  type: "separator";
  text: string;
};

export type MenuSubmenu = {
  type: "submenu";
  text: string;
  content: React.ReactNode | MenuItem[];
} & Omit<MenuItem, "link" | "type">;

export type MenuItem = {
  disabled?: boolean;
  icon?: IconNames;
  iconText?: string;
  link?: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler;
  onSelect?: (item: MenuElement) => void;
  type: "item";
  text: string;
};

export type MenuElement = MenuHeader | MenuSeparator | MenuSubmenu | MenuItem;

type MenuProps = PopoverGenericProps & {
  elements: MenuElement[];
  minWidth?: number;
  onSelect?: (item: MenuElement) => void;
  menuTriggerWrapperClass?: string;
};

export default function Menu({
  onSelect,
  elements,
  minWidth,
  children: trigger,
  menuTriggerWrapperClass,
  ...popoverProps
}: MenuProps) {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item: MenuElement) => () => {
    if (item.type !== "submenu") setIsOpen(false);
    if (onSelect) onSelect(item);
    if (item.type === "item" && item.onSelect) item.onSelect(item);
  };

  const style: React.CSSProperties = {};
  if (!!minWidth) style.minWidth = `${minWidth}px`;
  if (isOpen) trigger = cloneElement(trigger, {...trigger.props, active: true});
  return (
    <Popover
      isOpen={isOpen}
      arrow
      disableSelection
      onClose={() => setIsOpen(false)}
      content={
        <ul className={styles.menuMain} style={style}>
          {elements
            .filter((element) => element.type === "item" || element.type === "header" || element.type === "submenu")
            .map((element) =>
              element.type === "item" || element.type === "submenu" ? (
                <MenuItemComponent
                  element={element}
                  key={element.text}
                  onSelect={element.disabled ? undefined : handleItemClick(element)}
                />
              ) : (
                <MenuElementHeader item={element} key={element.text} />
              ),
            )}
        </ul>
      }
      {...popoverProps}
    >
      <div className={menuTriggerWrapperClass} onClick={() => setIsOpen(!isOpen)}>
        {trigger}
      </div>
    </Popover>
  );
}

function MenuItemComponent({element, onSelect}: {element: MenuItem | MenuSubmenu; onSelect?: () => void}) {
  const styles = useStyles();

  const elementInsideElements: JSX.Element[] = [];
  if (element.icon) {
    elementInsideElements.push(
      <div key="icon" className={styles.iconContainer}>
        <SVGIcon name={element.icon} />
      </div>,
    );
  } else if (element.iconText) {
    elementInsideElements.push(
      <div key="iconText" className={styles.iconContainer}>
        <RoundIcon mainColor="blue" text={element.iconText} containerSize={25} />
      </div>,
    );
  }
  elementInsideElements.push(<span key="text">{element.text}</span>);

  const menuElement = (
    <li
      className={clsx(styles.menuItem, {
        [styles.disabled]: element.disabled,
        [styles.selected]: element.selected,
      })}
      key={element.text}
      onClick={onSelect}
    >
      {element.type === "item" && element.link ? (
        <Link className={styles.itemElements} to={element.link}>
          {elementInsideElements}
        </Link>
      ) : (
        <div className={styles.itemElements} onClick={element.onClick}>
          {elementInsideElements}
        </div>
      )}
    </li>
  );

  return element.type === "item" ? (
    menuElement
  ) : (
    <Popover
      content={
        <>
          {ReactIs.isElement(element.content) ? (
            element.content
          ) : ReactIs.isValidElementType(element.content) ? (
            <>
              {/* @ts-ignore */}
              <element.content />
            </>
          ) : null}
        </>
      }
      position="left"
      arrow
      closeOnClick={false}
      renderAsChild
    >
      {menuElement}
    </Popover>
  );
}

function MenuElementHeader({item}: {item: MenuElement}) {
  const styles = useStyles();

  if (item.type !== "header") return null;

  return (
    <li className={styles.headerItem} key={item.text}>
      <span className={styles.mainText}>{item.text}</span>
      {item.subtext ? <span className={styles.subtext}>{item.subtext}</span> : null}
    </li>
  );
}
