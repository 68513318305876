import {useAppThunkDispatch} from "@app/hooks";
import Button from "@components/Button/V2";
import Checkbox from "@components/Checkbox";
import Dropdown from "@components/Dropdown";
import SVGIcon from "@components/SVGIcon";
import {utilityScriptDefinitions} from "@shared/lib/utility-scripts";
import {api as adminAPI} from "@shared/state/admin/slice";
import clsx from "clsx";
import {useState} from "react";

import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
import type {UtilityScriptNames, UtilityScriptResponsePayload} from "@shared/lib/utility-scripts";

export interface UtilitiesProps {}

const defaultSuccessMessage: UtilityScriptResponsePayload = {
  duration: "N/A",
  summary: "Success",
  logLines: [],
  error: null,
};

const defaultErrorMessage: UtilityScriptResponsePayload = {
  duration: "N/A",
  summary: null,
  logLines: [],
  error: "There was an error while running this script.",
};

const dropdownItems: readonly DropdownItem[] = [
  {key: "select_one", value: "Select an utility..."},
  ...utilityScriptDefinitions.map((utility) => ({key: utility.name, value: utility.displayName})),
];

export default function Utilities({}: UtilitiesProps) {
  const [loading, setLoading] = useState(false);
  const [dryRun, setDryRun] = useState(false);
  const [selectedUtilityName, setSelectedUtilityName] = useState<UtilityScriptNames | "select_one">("select_one");
  const [runResult, setRunResult] = useState<null | UtilityScriptResponsePayload>(null);
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const selectedUtility =
    selectedUtilityName === "select_one"
      ? null
      : utilityScriptDefinitions.find(({name}) => name === selectedUtilityName) ?? null;

  const handleButtonClick = async () => {
    if (!selectedUtility) return;
    setRunResult(null);
    setLoading(true);
    const result = await dispatch<any>(adminAPI.runUtility({utility: selectedUtility.name, dryRun, allSchemas: false}));

    if (result.payload.logLines) {
      setRunResult(result.payload);
    } else {
      setRunResult(defaultSuccessMessage);
    }

    setLoading(false);
    // setSelectedUtilityName("select_one");
  };

  return (
    <div className={styles.utilities}>
      <div className={styles.utilitySelectRow}>
        <Dropdown
          items={dropdownItems}
          selectedKey={selectedUtilityName}
          onSelect={({key}) => setSelectedUtilityName((key as UtilityScriptNames) || "select_one")}
          renderAsChild
          buttonWidth={325}
          width={325}
        />
      </div>
      {selectedUtility ? (
        <div className={styles.scriptDescription}>
          <h4>{selectedUtility.displayName}</h4>
          <p>{selectedUtility.description}</p>
          <Checkbox
            disabled={!selectedUtility.dryRunEnabled || loading}
            checked={dryRun && selectedUtility.dryRunEnabled}
            text={`Dry run${!selectedUtility.dryRunEnabled ? " (not available for this script)" : ""}`}
            onClick={() => setDryRun(!dryRun)}
          />
          <Button
            className={styles.runButton}
            disabled={!selectedUtility || loading}
            color="orange"
            text={`Execute${dryRun && selectedUtility?.dryRunEnabled ? " (dry run)" : ""}`}
            onClick={handleButtonClick}
          />
        </div>
      ) : null}
      {runResult ? (
        <div className={styles.utilityExecResult}>
          <h4>
            <span className={clsx(styles.resultIcon, runResult.error ? styles.error : styles.success)}>
              <SVGIcon name={runResult.error ? "errorExclamationPoint" : "checkmark"} />
            </span>
            <span>
              Execution {runResult.error ? "failed" : "completed"}{" "}
              <span className={styles.subtext}>({runResult?.duration})</span>
            </span>
          </h4>

          <div className={styles.summary}>
            {runResult?.error ? (
              <span>
                Error while running this script:
                <br />
                <i>{runResult.error}</i>
              </span>
            ) : (
              <span>
                <span className={styles.resultLabel}>Result:</span>
                <span>{runResult?.summary}</span>
              </span>
            )}
          </div>
          {runResult.logLines.length ? (
            <>
              <br />
              <h4>Execution log</h4>
              <div className={styles.logLines}>
                {(runResult.logLines || []).map((line, i) => (
                  <pre key={i}>{line}</pre>
                ))}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
