import colors from "@app/styles/colors";
import {containerShadowStyles} from "@app/styles/general-ui";
import {createUseStyles} from "react-jss";

import type {NotificationModalProps} from "@app/ui-state/slice";

export const sharedValues = {};

export default createUseStyles({
  notificationModalMain: {
    extend: containerShadowStyles,
    width: 500,
    height: 200,
    padding: [30, 51, 20, 180],
    borderRadius: 5,
    backgroundColor: colors.ui.white,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "auto 100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 20,
  },
  title: {
    fontWeight: 700,
    lineHeight: 35,
    fontSize: 20,
    color: colors.ui.black,
    paddingBottom: 12,
  },
  text: {
    color: colors.ui.darkBlue02,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 24,
    flex: 1,

    "& > b": {
      color: (props: {mainColor?: NotificationModalProps["mainColor"]}) => colors.states[props.mainColor || "red"],
      fontWeight: 700,
    },
    "& > strong": {
      color: colors.ui.darkBlue02,
      fontWeight: 600,
    },
  },
  actionButtons: {
    marginTop: 9,
    display: "flex",
    alignItems: "center",

    "& > div:first-child": {
      marginRight: 22,
    },
  },
});
