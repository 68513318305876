import {store} from "@app/client-store";
import {getDatasourceDiff} from "@shared/lib/datasource-utilities";
import {addNewFcDatasource, generateNewFcRow, getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import id from "@shared/lib/id";
import {sleep} from "@shared/lib/misc";
import {selectEmployeeFcDatasourcesSorted} from "@state/datasources/selectors";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {employeeAdded} from "@state/employees/slice";
import {selectScenarioId} from "@state/global/slice";
import {selectAllScenarios, selectScenarioIds} from "@state/scenarios/slice";
import {upsertRows} from "@state/template-rows/slice";
import {selectTemplateByName} from "@state/templates/selectors";
import {upsertTemplate} from "@state/templates/slice";
import dayjs from "dayjs";

import {setSelectedEmployeeId} from "./slice";

import type {ScenarioSpecificEmployeeProps} from "@shared/types/hiring-plan";

export function addEmployee(teamId: string | null) {
  const dispatch = store.dispatch;
  let state = store.getState();

  const scenarioIds = selectScenarioIds(state);
  const selectedScenarioId = selectScenarioId(state);
  const team = state.teams.entities[teamId ?? ""];
  const employeeId = id();

  const scenario_properties: {[scenarioId: string]: ScenarioSpecificEmployeeProps} = {};
  const todayFormatted = dayjs().format("YYYY-MM-DD");
  for (const id of scenarioIds) {
    scenario_properties[id] = {};
    if (selectedScenarioId === id) scenario_properties[id].hire_date = todayFormatted;
  }

  dispatch(employeeAdded({id: employeeId, team_id: teamId, scenario_properties}));
  dispatch(setSelectedEmployeeId(employeeId));
  state = store.getState();
  const salaryFc = state.financialComponents.entities[state.financialComponents.idsByType["salary"]?.[0] ?? ""];
  const employee = state.employees.entities[employeeId];
  const hiringPlanTemplate = selectTemplateByName(state, "hiring_plan");
  const scenarios = selectAllScenarios(state);
  const scenarioId = state.global.scenarioId;

  if (!salaryFc || !employee || !hiringPlanTemplate || !scenarioId) return;

  // This creates a compensation row for the employee
  const result = generateNewFcRow(
    employee,
    hiringPlanTemplate,
    salaryFc,
    team ?? null,
    scenarios,
    scenarioId,
    state.templateRows.idsByName,
  );

  dispatch(upsertRows([result.row]));
  dispatch(upsertTemplate(result.template));

  // sleep(100).then(() => console.log({requests: state.global.requests.filter((req) => !req.completedAt)}));
  // sleep(250).then(() => console.log({requests: state.global.requests.filter((req) => !req.completedAt)}));
  // sleep(500).then(() => console.log({requests: state.global.requests.filter((req) => !req.completedAt)}));
  // sleep(1000).then(() => console.log({requests: state.global.requests.filter((req) => !req.completedAt)}));
  // sleep(1500).then(() => console.log({requests: state.global.requests.filter((req) => !req.completedAt)}));

  sleep(2000).then(() => {
    // console.log({requests: state.global.requests.filter((req) => !req.completedAt)});
    // This creates a compensation range in that row
    const datasources = selectEmployeeFcDatasourcesSorted(state, {
      employeeId: employee.id,
      fcName: result.row.options.fc_name,
      scenarioId,
    });

    const newDatasource = addNewFcDatasource(
      result.row.id,
      scenarioId,
      team?.department_id ?? null,
      employee.id,
      datasources,
      employee.scenario_properties[scenarioId ?? ""].hire_date || "",
      employee.scenario_properties[scenarioId ?? ""].term_date,
      hiringPlanTemplate.options,
      salaryFc,
    );

    state = store.getState();
    const datasourceDiff = getDatasourceDiff(datasources, newDatasource, state);

    dispatch(
      applyDatasourceChanges({
        datasourceDiff,
        reason: `Add ${salaryFc.display_name} change for ${getEmployeeDisplayName(employee)}}`,
      }),
    );
  });
}
