import type {NotificationModalProps} from "./slice";

export const confirmPresets = {
  delete: {
    title: "Confirm Delete",
    actionButtonText: "Delete",
    backgroundImage: "/assets/images/ui/delete-modal-bg.svg",
    mainColor: "red",
    closeOnConfirm: true,
  },
} as const;

export const headsUpNotifPresets = {
  error: {
    title: "Error",
    backgroundImage: "/assets/images/ui/error-heads-up-notif-bg.svg",
    mainColor: "red",
  },
  success: {
    title: "Success",
    backgroundImage: "/assets/images/ui/success-heads-up-notification.svg",
    mainColor: "green",
  },
} as const;
export const notificationModalPresets = {
  loggedOut: {
    title: "Session Expired",
    actionButtonText: "Reload",
    backgroundImage: "/assets/images/ui/modals/notifs/orange-default.svg",
    mainColor: "orange",
  },
  outOfSync: {
    title: "Data Out of Sync",
    actionButtonText: "Refresh",
    backgroundImage: "/assets/images/ui/modals/notifs/orange-default.svg",
    mainColor: "orange",
    height: 220,
    confirmButton: true,
  },
  error: {
    title: "Error: Company Does Not Match",
    actionButtonText: "Close",
    backgroundImage: "/assets/images/ui/modals/notifs/red-default.svg",
    mainColor: "red",
  },
  integrationSync: {
    title: "Sync",
    actionButtonText: "Close",
    backgroundImage: "/assets/images/ui/modals/notifs/blue-excited.svg",
    mainColor: "blue",
  },
} as const satisfies Record<string, Partial<NotificationModalProps>>;
