import RoundButton from "@components/RoundButton";
import RoundIcon from "@components/RoundIcon";
import clsx from "clsx";

import useStyles from "./styles.jss";

export interface SearchBarProps {
  alwaysVisible?: boolean;
  onSearchChange: (searchText: string) => void;
  value?: string;
  leftMargin?: boolean;
  variant?: "normal" | "small";
  clearable?: boolean;
}

export default function SearchBar({
  onSearchChange,
  value,
  alwaysVisible = true,
  leftMargin = true,
  variant = "normal",
  clearable = false,
}: SearchBarProps) {
  const styles = useStyles();

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    onSearchChange(evt.target.value);
  };

  const handleClear = () => {
    onSearchChange("");
  };

  const classes = clsx(styles.searchBarMain, styles[variant], {[styles.noLeftMargin]: leftMargin === false});

  return (
    <div className={classes} onClick={(evt) => evt.stopPropagation()}>
      <label className={styles.searchIconWrapper} htmlFor="search">
        <RoundIcon icon="searchAlt" minimal />
      </label>
      {typeof value === "undefined" ? (
        <input
          type="text"
          placeholder="Search..."
          name="search"
          id="search"
          onChange={handleSearchChange}
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
        />
      ) : (
        <input
          type="text"
          spellCheck={false}
          autoComplete="off"
          autoCorrect="off"
          placeholder="Search..."
          name="search"
          id="search"
          onChange={handleSearchChange}
          value={value}
        />
      )}
      {clearable && value ? (
        <div className={styles.clearIcon} onClick={handleClear}>
          <RoundButton icon="smallCross" enableCssStates minimal />
        </div>
      ) : null}
    </div>
  );
}
