import {apiWithContract} from "@shared/lib/fetch";

import type {SanityChecksApiContracts} from "@shared/apis/contracts/sanity-checks";
import type {SanityCheck} from "@shared/types/db";
import type {ApiEndpoints} from "@state/store";

const endpoints = {
  fetchAll(_arg: undefined, thunkAPI) {
    return apiWithContract<SanityChecksApiContracts["get"]>(thunkAPI, {
      method: "get",
      path: `sanity-checks`,
      query: null,
      body: null,
    });
  },
  upsert(checks: SanityCheck[], thunkAPI) {
    return apiWithContract<SanityChecksApiContracts["upsert"]>(thunkAPI, {
      method: "put",
      path: `sanity-checks`,
      body: checks,
      query: null,
    });
  },
  remove(ids: string[], thunkAPI) {
    return apiWithContract<SanityChecksApiContracts["delete"]>(thunkAPI, {
      method: "del",
      path: `sanity-checks`,
      body: ids,
      query: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
