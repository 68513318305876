import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import DatePicker from "@components/Datepicker/RDP";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import Section, {FullWidthContent, SectionCol} from "@components/RightSidebar/Section";
import TextInput from "@components/TextInput";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import {getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import {employeeScenarioPropertiesUpdated, employeeUpdated} from "@shared/state/employees/slice";
import {selectTeamEntities} from "@shared/state/teams/slice";
import {selectTemplateEntities} from "@shared/state/templates/slice";
import {selectVersionLocked} from "@state/global/slice";
import clsx from "clsx";
import dayjs from "dayjs";

import {useSelectedEmployeeDetails} from "../../hooks";
import useStyles from "./styles.jss";
import Tags from "./Tags";

import type {ScenarioSpecificEmployeeProps} from "@shared/types/hiring-plan";

const employeeTypes = [
  {
    key: "employee",
    value: "Employee",
  },
  {
    key: "contractor",
    value: "Contractor",
  },
] as const;

export interface DetailsProps {}

export default function Details({}: DetailsProps) {
  const styles = useStyles();

  const teamEntities = useAppSelector(selectTeamEntities);
  const employeeDetails = useSelectedEmployeeDetails();
  const dispatch = useAppThunkDispatch();
  const templateEntities = useAppSelector(selectTemplateEntities);

  const isVersionLocked = useAppSelector(selectVersionLocked);

  const hiringPlanTemplate = Object.values(templateEntities).find((item) => item?.name === "hiring_plan");

  if (!employeeDetails?.entity) return null;

  const {entity: employee, scenarioProperties, scenarioId} = employeeDetails;
  const employeeTeam = teamEntities[employee.team_id || ""];
  const employeeTeamName = employeeTeam?.display_name || "No Team";

  const handleEmployeeTextInputBaseChange =
    (key: "role" | "first_name" | "last_name" | "team_id") => (evt: React.ChangeEvent<HTMLInputElement>) =>
      handleEmployeeBaseChange(key, evt.target.value);

  const handleEmployeeBaseChange = (key: "role" | "first_name" | "last_name" | "team_id", value: string) => {
    if (key === "team_id") value = Object.values(teamEntities).find((item) => item?.name === value)?.id ?? "";
    dispatch(employeeUpdated({id: employee.id, key, value}));
  };

  const handleEmployeeScenarioPropertyChange = (key: keyof ScenarioSpecificEmployeeProps, value: string | null) => {
    dispatch(employeeScenarioPropertiesUpdated({id: employee.id, key, value: value || "", scenarioId}));
  };

  const classes = clsx(styles.detailsMain);

  const selectedEmployeeType =
    employeeTypes.find((item) => item.key === employee.scenario_properties[scenarioId]?.employee_type) ||
    employeeTypes[0];

  const formattedHireDate = scenarioProperties?.hire_date
    ? dayjs(scenarioProperties.hire_date).format("MM/DD/YYYY")
    : "";

  const nbCustomProperties = Object.keys(employee.custom_tags).length;

  return (
    <div className={classes}>
      <Section
        title="Name"
        summary={getEmployeeDisplayName(employee) || "New Employee"}
        collapsed={!!(employee.first_name?.length || employee.last_name?.length)}
      >
        <FullWidthContent>
          <SectionCol>
            <FormElement label="First Name" tooltip="HiringPlan::Employees::Sidebar::FirstName">
              <TextInput
                value={employee.first_name}
                onChange={handleEmployeeTextInputBaseChange("first_name")}
                changeTrigger="blur"
                onChangeDelay={1000}
                disabled={isVersionLocked}
              />
            </FormElement>
          </SectionCol>
          <SectionCol>
            <FormElement label="Last Name" tooltip="HiringPlan::Employees::Sidebar::LastName">
              <TextInput
                value={employee.last_name}
                onChange={handleEmployeeTextInputBaseChange("last_name")}
                changeTrigger="blur"
                onChangeDelay={1000}
                disabled={isVersionLocked}
              />
            </FormElement>
          </SectionCol>
        </FullWidthContent>
      </Section>
      <Section
        collapsed={!!scenarioProperties?.hire_date?.length}
        title="Hire Date & Status"
        summary={
          scenarioProperties
            ? `${formattedHireDate}${
                scenarioProperties.term_date ? ` - ${dayjs(scenarioProperties.term_date).format("MM/DD/YYYY")}` : ""
              }`
            : "No Dates"
        }
      >
        <FullWidthContent>
          <SectionCol>
            <FormElement label="Hire Date" tooltip="HiringPlan::Employees::Sidebar::HireDate">
              <DatePicker
                onChange={(value) => handleEmployeeScenarioPropertyChange("hire_date", value)}
                value={
                  scenarioProperties?.hire_date
                    ? getFormattedFullDate(scenarioProperties.hire_date, "start", "MM/DD/YYYY")
                    : ""
                }
                max={scenarioProperties?.term_date || hiringPlanTemplate?.options.end}
                disabled={isVersionLocked}
              />
            </FormElement>
          </SectionCol>
          <SectionCol>
            <FormElement label="Termination Date" tooltip="HiringPlan::Employees::Sidebar::TerminationDate">
              <DatePicker
                onChange={(value) => handleEmployeeScenarioPropertyChange("term_date", value)}
                value={
                  scenarioProperties?.term_date
                    ? getFormattedFullDate(scenarioProperties.term_date, "end", "MM/DD/YYYY")
                    : ""
                }
                min={scenarioProperties?.hire_date || hiringPlanTemplate?.options.start}
                max={hiringPlanTemplate?.options.end}
                disabled={isVersionLocked}
              />
            </FormElement>
          </SectionCol>
        </FullWidthContent>
      </Section>
      <Section title="Role" summary={employee.role} collapsed={!!employee.role?.length}>
        <FullWidthContent>
          <SectionCol>
            <FormElement label="Role" tooltip="HiringPlan::Employees::Sidebar::Role">
              <TextInput
                value={employee.role}
                onChange={handleEmployeeTextInputBaseChange("role")}
                changeTrigger="blur"
                disabled={isVersionLocked}
                onChangeDelay={1000}
              />
            </FormElement>
          </SectionCol>
          <SectionCol>
            <FormElement label="Start Date" tooltip="HiringPlan::Employees::Sidebar::RoleStart">
              <TextInput disabled minimal value={formattedHireDate} />
            </FormElement>
          </SectionCol>
        </FullWidthContent>
      </Section>
      <Section title="Team" summary={employeeTeamName} collapsed={!!employee.team_id}>
        <FullWidthContent>
          <SectionCol>
            <FormElement label="Team" tooltip="HiringPlan::Employees::Sidebar::Team">
              <Dropdown
                items={Object.values(teamEntities).map((team) => ({
                  key: team!.name,
                  value: team!.display_name || "Unassigned",
                }))}
                onSelect={(item) => handleEmployeeBaseChange("team_id", item.key as string)}
                text={employeeTeamName}
                buttonBold={false}
                buttonFill
                position={["bottom", "top"]}
                disabled={isVersionLocked}
              />
            </FormElement>
          </SectionCol>
          <SectionCol>
            <FormElement label="Start Date" tooltip="HiringPlan::Employees::Sidebar::TeamStart">
              <TextInput disabled minimal value={formattedHireDate} />
            </FormElement>
          </SectionCol>
        </FullWidthContent>
      </Section>
      <Section title="Employment Type" summary={`${selectedEmployeeType.value}`} collapsed>
        <SectionCol>
          <FormElement label="Employment Type" tooltip="HiringPlan::Employees::Sidebar::EmploymentType">
            <Dropdown
              items={employeeTypes}
              selectedKey={selectedEmployeeType.key}
              onSelect={({key}) => handleEmployeeScenarioPropertyChange("employee_type", key as string)}
              buttonBold={false}
              buttonFill
              position="top"
              disabled={isVersionLocked}
            />
          </FormElement>
        </SectionCol>
      </Section>
      <Section
        title="Custom Properties"
        collapsed
        className={styles.tags}
        summary={
          !nbCustomProperties
            ? "No custom properties"
            : `${nbCustomProperties} custom propert${nbCustomProperties === 1 ? "y" : "ies"}`
        }
      >
        <Tags employee={employee} />
      </Section>
    </div>
  );
}
