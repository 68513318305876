import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  templateOptionsMain: {
    padding: [20, 30, 30, 30],

    "& $formElement": {
      "&:not(:first-child)": {
        marginTop: 35,
      },
    },
  },
  formElement: {},
  timeRangeFormRow: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,

    "& > span:first-child, & > span:last-child": {
      flex: 1,
    },
  },
  subSettings: {
    extend: "to",
    marginTop: 19,
    padding: 0,
    lineHeight: 25,
  },
  subSettingsLabel: {},
  subSettingsCheckboxes: {
    paddingLeft: 20,
    paddingTop: 8,
  },
  to: {
    padding: [0, 15],
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 23,
    color: colors.ui.lightBlue01,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    marginTop: 35,

    "& > div:not(:first-child)": {
      marginLeft: 21,
    },
  },
  actualsSyncTabMain: {
    paddingTop: 25,
    fontWeight: 500,
    fontWise: 13,
  },
  tag: {paddingBottom: 25},
  actualsMonthSelector: {
    flex: 1,
    paddingRight: 10,
  },
  datePickerMain: {
    width: 308,
    padding: [20, 30, 30, 30],

    "$templateOptionsMain &": {
      padding: [0, 0, 0, 0],
      marginTop: 35,
    },
  },
  hideRows: {
    paddingTop: 25,
  },
  showSanityChecks: {
    paddingTop: 0,
  },
});
