import colors from "@app/styles/colors";
import {sharedValues as dropdownSharedValues} from "@components/Dropdown/styles.jss";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export const sharedValues = {
  paddingY: 7,
};

export default createUseStyles({
  menuMain: {
    padding: [sharedValues.paddingY, 0],

    "& > div > li": {
      padding: [0, 15],
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: colors.text.black,
    fontWeight: 500,

    "&:not($disabled)": {
      cursor: "pointer",

      "&:hover": dropdownSharedValues.hoverStyle,
    },
  },
  itemElements: {
    display: "flex",
    alignItems: "center",
    padding: [0, 15],
    height: 35,
    width: "100%",
  },
  iconContainer: {
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 2,
    marginRight: 8,

    ...getSvgColorStyles(colors.ui.lightBlue01),
  },
  disabled: {},
  selected: dropdownSharedValues.hoverStyle,

  headerItem: {
    fontSize: 12,
    marginTop: 13,
    paddingLeft: 15,

    "&:first-child": {
      marginTop: 13 - sharedValues.paddingY,
    },

    "& span": {
      color: colors.text.darkBlue01,
      display: "block",
    },
  },
  mainText: {
    display: "block",
    fontWeight: 700,
    lineHeight: 25,
    color: colors.text.darkBlue01,
  },
  subtext: {
    display: "block",
    fontWeight: 600,
    lineHeight: 12,
    paddingBottom: 6,
  },
});
