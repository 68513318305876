import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@shared/state/store";
import type {Company} from "@shared/types/db";

export const endpoints = {
  fetchAll(_arg, thunkAPI) {
    return api.get<Company[]>(thunkAPI, `admin/companies`);
  },
  reset(companyName: string, thunkAPI) {
    return api.put<null>(thunkAPI, `admin/companies/${companyName}/reset`, {});
  },
  create(company: Company, thunkAPI) {
    return api.post<Company>(thunkAPI, `admin/companies`, company);
  },
} satisfies ApiEndpoints;

export default endpoints;
