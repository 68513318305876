import {createSelector} from "@reduxjs/toolkit";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";

import {selectSanityCheckEntities} from "./slice";

import type {RootState} from "@state/store";

export const makeSelectSanityChecksForRowId = () =>
  createSelector(
    selectSanityCheckEntities,
    (state: RootState) => state.sanityChecks.idsByCheckEntityIdentifier,
    (_: RootState, rowId: string) => rowId,
    (sanityChecksById, idsByCheckEntityIdentifier, rowId) => {
      const sanityCheckIds = idsByCheckEntityIdentifier[rowId] || [];
      return mapEntitiesToIds(sanityChecksById, sanityCheckIds);
    },
  );

export const selectAllFailingSanityChecks = createSelector(selectSanityCheckEntities, (sanityCheckEntities) => {
  return Object.values(sanityCheckEntities).filter((sanityCheck) => sanityCheck?.passed === false);
});
