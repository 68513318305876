import "@/../core-js-polyfills";
import {store} from "@app/client-store";
import {setDebug} from "@shared/lib/debug-provider";
import {callWorkerFn} from "@shared/worker-fn-gateway";
import {setUseWhatChange} from "@simbathesailor/use-what-changed";
import {isBrowser} from "browser-or-node";
import {setAutoFreeze} from "immer";
import {create as createJss} from "jss";
import preset from "jss-preset-default";
import ReactDOMClient from "react-dom/client";
import {JssProvider} from "react-jss";
import {Provider as ReduxProvider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {registerSW} from "virtual:pwa-register";
import "./index.css";

import Admin from "./features/admin";

import App from "@/App";

setAutoFreeze(false);
setUseWhatChange(process.env.NODE_ENV === "development");

const updateSW = registerSW({
  onNeedRefresh() {
    console.log(`App needs to be refreshed`);
  },
  onOfflineReady() {
    console.log(`App is now offline`);
  },
});

const jss = createJss();
jss.setup(
  preset({
    defaultUnit: {
      "line-height": "px",
    },
  }),
);

const rootContainer = document.querySelector("#root");

if (!rootContainer) throw new Error("React root container not found in DOM");

export const root = ReactDOMClient.createRoot(rootContainer);

root.render(
  <ReduxProvider store={store}>
    <JssProvider jss={jss}>
      <BrowserRouter>
        <Routes>
          <Route path="/admin" element={<Admin />} />
          <Route path={`/admin/*`} element={<Admin />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </JssProvider>
  </ReduxProvider>,
);

if (isBrowser) {
  window.setDebug = (enabled?: boolean) => {
    setDebug(enabled);
    callWorkerFn("setDebug", [enabled]);
  };

  window.setTiming = (enabled?: boolean) => {
    setDebug(enabled);
    callWorkerFn("setTiming", [enabled]);
  };
}
