import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {
  selectFormulaForSelectedCell,
  selectIsCellDisabled,
  selectors,
  selectSelectedTemplate,
  selectUiDatasourceForSelectedCell,
} from "@features/templates/state/selectors";
import {updateTemplatesViewState} from "@features/templates/state/slice";
import {enableEditing} from "@features/templates/state/thunks";
import {clearAlertsForDatasource} from "@state/alerts/slice";
import {selectScenarioId} from "@state/global/slice";
import clsx from "clsx";
import {useEffect, useRef} from "react";

import {handleFormulaBlurStateUpdates, keyDownHandler, makeHandlePaste} from "./event-handlers";
import {getPrettyPrintedSelectedFormulaHtml} from "./pretty-print-provider";
import useStyles from "./styles.jss";
import {closeTooltip} from "./Tooltip/slice";
import FormulaTooltip from "./Tooltip/Tooltip";

export const FORMULA_EDITOR_INPUT_CLASS = "formula-editor-input";

export function FormulaTextEditor({component}: {component: "formulaBar" | "cell"}) {
  const formulaInputRef = useRef<HTMLInputElement>(null);
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const scenarioId = useAppSelector(selectScenarioId);
  const template = useAppSelector(selectSelectedTemplate);
  const {column} = useAppSelector(selectors.activeCell);
  const cellBeingEdited = useAppSelector(selectors.cellBeingEdited);
  const activeDatasourceRange = useAppSelector(selectUiDatasourceForSelectedCell);
  const activeDatasourceFormula = useAppSelector(selectFormulaForSelectedCell);
  const disabled = useAppSelector(selectIsCellDisabled);
  const formulaType = activeDatasourceRange?.type === "text" ? "plainText" : "formula";
  const cellBeingEditedComponent = cellBeingEdited?.component ?? null;

  const isTheActiveComponent = cellBeingEditedComponent === component;

  useEffect(() => {
    if (cellBeingEditedComponent === component && document.activeElement !== formulaInputRef.current) {
      formulaInputRef.current?.focus();
    }
  }, [cellBeingEditedComponent, component]);

  useEffect(() => {
    if (disabled) formulaInputRef.current?.blur();
  }, [disabled]);

  if (!scenarioId || !template) return null;

  const handleFormulaChange = (formulaStr: string, tooltipOpenOverride?: boolean) => {
    if (!isTheActiveComponent) return;

    // console.log(`Formula change: `, {formulaStr, component, tooltipOpenOverride});

    const cleanedFormula = formulaStr.replace("\n", "");
    if (cellBeingEdited) {
      if (typeof activeDatasourceRange?.value === "object") {
        dispatch(clearAlertsForDatasource(activeDatasourceRange.value.id));
      }
      console.log({...cellBeingEdited});
      const newCellBeingEditedState = {
        ...cellBeingEdited,
        formula: cleanedFormula,
      };

      console.log({...newCellBeingEditedState});
      dispatch(
        updateTemplatesViewState({
          cellBeingEdited: newCellBeingEditedState,
          caller:
            "FormulaTextEditor->handleFormulaChange->after validating cellBeingEdited is set and clearing alerts for datasource",
        }),
      );

      if (tooltipOpenOverride === false) {
        dispatch(closeTooltip());
      }
    }
  };

  const handlePaste = () => {
    if (!isTheActiveComponent) return;
    makeHandlePaste(handleFormulaChange);
  };

  const handleFocus = () => {
    if (
      isTheActiveComponent &&
      component === "formulaBar" &&
      !(template.type === "financial_statement" && column === "name")
    ) {
      dispatch(enableEditing(component));
    }
  };

  const handleFormulaBlur: React.FocusEventHandler<HTMLInputElement> = async (e) => {
    // If the releated target is an input with class [inputClass], do not consider it a blur, actually.
    // It's just changing where the formula editing happens but it's still editing
    // console.log(`Formula blur: `, {
    //   cellBeingEdited,
    //   component,
    //   isTheActiveComponent,
    //   disabled,
    //   enableEditing,
    //   relatedTarget: e.relatedTarget,
    // });

    if (cellBeingEdited && e.relatedTarget?.classList.contains(styles.formulaEditorInput)) {
      return;
    }

    if (!cellBeingEdited || disabled || !enableEditing) return;

    if (!isTheActiveComponent) return;

    await handleFormulaBlurStateUpdates(e);
  };

  const handleFormulaMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    // If the cell is the active component and it's already being edited, prevent default unless the click was on the inputRef
    if (isTheActiveComponent && cellBeingEdited && e.target !== formulaInputRef.current) {
      console.log(e.target, formulaInputRef.current);
      e.preventDefault();
      dispatch(enableEditing(component));
    }
    // e.preventDefault();
    // const input = e.currentTarget.querySelector("input");
    // console.log(input, document.activeElement);
    // if (input && document.activeElement !== input) {
    //   input.focus();
    // }

    // if (cellBeingEdited && cellBeingEdited.component !== component) {
    //   // Set the cell being edited component to the formula bar
    //   dispatch(
    //     updateTemplatesViewState({
    //       cellBeingEdited: {...cellBeingEdited, component},
    //       caller:
    //         "FormulaTextEditor->handleFormulaMouseDown->set cellBeingEdited component to formula bar (because it was not the active component)",
    //     }),
    //   );
    // }
  };

  const prettyPrintedFormula = getPrettyPrintedSelectedFormulaHtml({styles});

  const classes = clsx(styles.formulaPrettyPrinted, {
    [styles.disabled]: disabled,
    [styles.alignRight]: formulaType === "formula" && component === "cell",
    [styles.plainText]: formulaType === "plainText" && component === "cell",
  });

  return (
    <FormulaTooltip
      component={component}
      isTheActiveComponent={isTheActiveComponent && formulaType !== "plainText"}
      inputRef={formulaInputRef}
      formula={cellBeingEdited?.formula ?? null}
      onFormulaChange={handleFormulaChange}
    >
      <div className={classes} onMouseDown={handleFormulaMouseDown}>
        <>
          <div className={styles.formulaEditorInputContainer} onMouseDown={handleFormulaMouseDown}>
            {isTheActiveComponent && (
              <input
                className={clsx(styles.formulaEditorInput, `component-${component} `, {
                  [styles.plainText]: formulaType === "plainText" && component === "cell",
                })}
                type="text"
                onChange={(evt) => handleFormulaChange(evt.target.value)}
                onPaste={handlePaste}
                onBlur={handleFormulaBlur}
                onFocus={handleFocus}
                onKeyDown={keyDownHandler(component, "formulaInput", handleFormulaChange, formulaInputRef)}
                // onKeyUp={handleKeyUp}
                ref={formulaInputRef}
                spellCheck={false}
                value={cellBeingEdited !== null ? cellBeingEdited.formula : activeDatasourceFormula || ""}
              />
            )}
          </div>
          <span dangerouslySetInnerHTML={{__html: prettyPrintedFormula}} className={styles.prettyPrintedWrapper} />
        </>
      </div>
    </FormulaTooltip>
  );
}
