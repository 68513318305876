import {createSelector} from "@reduxjs/toolkit";
import {NO_DEPARTMENT_NAME} from "@shared/data-functions/formula/formula-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";

import type {Department} from "@shared/types/db";
import type {RootState} from "@state/store";

export type DepartmentWithDepth = Department & {depth: number; hasChildren: boolean};
export const selectFlattenedDepartments = createSelector(
  (state: RootState) => state.departments,
  (departmentsState) => {
    let noDept: Department | null = null;
    const flattenedDepartments: DepartmentWithDepth[] = [];

    function recursive(departments: Department[], depth: number) {
      for (const department of departments) {
        const childDepartmentIds = departmentsState.idsByParent[department.id];
        if (department.name === NO_DEPARTMENT_NAME) {
          noDept = department;
        } else {
          flattenedDepartments.push({...department, depth, hasChildren: !!childDepartmentIds?.length});
        }
        if (childDepartmentIds?.length) {
          recursive(mapEntitiesToIds(departmentsState.entities, childDepartmentIds), depth + 1);
        }
      }
    }

    recursive(mapEntitiesToIds(departmentsState.entities, departmentsState.idsByParent["null"] ?? []), 0);

    if (noDept) flattenedDepartments.push({...(noDept as Department), depth: 0, hasChildren: false});
    return flattenedDepartments;
  },
);
