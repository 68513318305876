import {DEBUG} from "@shared/lib/debug-provider";
import {isWebWorker} from "browser-or-node";

import {getWrappedWorker} from "./singletons";

import type {WorkerOptions} from "./call-worker";
import type {WorkerFunctionCallReturnType, WorkerFunctionMapping, WorkerFunctionName, fnMapping} from "./worker-thread";

if (isWebWorker) console.log("!!!!!!!!!!!!!!! SHOULD NOT BE LOADED FROM WEB WORKER !!!!!!!!!!!!!!!");

import "./worker-to-main";

export type CallWorkerFn = typeof callWorkerFn;

export async function callWorkerFn<
  FnName extends WorkerFunctionName,
  Fn extends WorkerFunctionMapping[FnName],
  P extends Parameters<Fn>,
>(fnName: FnName, args: P, workerOptions?: WorkerOptions) {
  if (isWebWorker) throw new Error("callWorkerFn should only be called from main thread");
  const wrappedWorker = getWrappedWorker();

  // eslint-disable-next-line no-console
  if (DEBUG)
    console.log(
      `Starting Worker<"${fnName}">${workerOptions?.executionId ? ` (exec #${workerOptions?.executionId})` : ""}`,
    );
  if (workerOptions?.measureTotalTime)
    console.time(
      `Worker<"${fnName}"> total${workerOptions?.executionId ? ` (exec #${workerOptions?.executionId})` : ""}`,
    );
  const timestampWhenSentFromMainThread = Date.now();

  const optionsWithTimestamp: WorkerOptions = {
    ...workerOptions,
    DEBUG,
    timestampWhenSentFromMainThread,
  };
  let finalArgs: string | P;

  if (workerOptions?.dataTransferMethod === "json") {
    finalArgs = JSON.stringify(args);
  } else {
    finalArgs = args;
  }
  let fnResult: ReturnType<(typeof fnMapping)[FnName]>;
  let fnResultMetadata: Awaited<WorkerFunctionCallReturnType<(typeof fnMapping)[FnName]>>["metadata"];
  try {
    const result = await wrappedWorker(fnName, finalArgs, optionsWithTimestamp);
    if (!result) return null;

    fnResultMetadata = result.metadata;

    fnResult = (
      workerOptions?.dataTransferMethod === "json" && typeof result.functionResult === "string"
        ? (JSON.parse(result.functionResult) as any)
        : (result.functionResult as any)
    ) as Awaited<ReturnType<(typeof fnMapping)[FnName]>>;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(fnName, args);
    console.error(e);
    return null;
  }
  if (workerOptions?.measureDataTransferTime) {
    const timestampWhenReceivedInMainThread = Date.now();
    if (DEBUG)
      // eslint-disable-next-line no-console
      console.log(
        `Elapsed time between web worker send and main thread receive: ${
          timestampWhenReceivedInMainThread - fnResultMetadata.timestampWhenSentFromWorker
        }ms`,
      );
  }
  if (workerOptions?.measureTotalTime)
    console.timeEnd(
      `Worker<"${fnName}"> total${workerOptions?.executionId ? ` (exec #${workerOptions?.executionId})` : ""}`,
    );
  return fnResult;
}
