import {resolveDaterange} from "@shared/data-functions/formula/date-range";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import memoize from "fast-memoize";

import type {Datasource} from "@shared/types/datasources";
import type {TemplateOptions} from "@shared/types/db";
import type {RootState} from "@state/store";

dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export function isValidDate(value?: string | null) {
  return !value ? false : dayjs(value, "YYYY-MM-DD", true).isValid() || dayjs(value, "MM/DD/YYYY", true).isValid();
}

export function formatTime(timestamp: string) {
  return dayjs(timestamp).format("MM/DD/YYYY") + " at " + dayjs(timestamp).format("h:mm A z");
}

export const months = dayjs.months();

export function getFormattedFullDate(date: string, day: "end" | "start", format: string = "YYYY-MM-DD") {
  if (date.length === 10) {
    return format ? dayjs(date).format(format) : date;
  }
  if (date.length === 7 && day === "start") {
    return format ? dayjs((date += "01")).format(format) : (date += "-01");
  }

  if (date.length === 7 && day === "end") {
    return dayjs(date).endOf("month").format(format);
  }

  return date;
}

// Perf test vs using dayjs: https://www.measurethat.net/Benchmarks/Show/16962/0/manually-adding-month-to-yyyy-mm-formatted-date-vs-dayj
export const addDeltaMonthsToDateKey = memoize((date: string, delta: number): string => {
  if (!delta) return date;
  const [year, month] = date.split("-");
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(year, 10);
  let newYear = yearInt;
  let newMonth = monthInt + delta;
  if (newMonth > 12 || newMonth <= 0) {
    const spilloverMonths = newMonth % 12;
    const spilloverYears = newMonth === 0 ? -1 : Math.floor((newMonth - 1) / 12);
    newMonth = spilloverMonths === 0 ? 12 : newMonth > 0 ? spilloverMonths : 12 + spilloverMonths;
    newYear = yearInt + spilloverYears;
  }
  const updatedDate = `${newYear}-${newMonth.toString().padStart(2, "0")}`;

  return updatedDate;
});

export const getAllDateKeysBetween = memoize((start: string, end: string, inclusive: boolean = true): string[] => {
  if (start.length > 7) start = start.slice(0, 7);
  if (end.length > 7) end = end.slice(0, 7);

  if (start === end || start > end) return [start];

  const dateKeys = [];
  let dateKeyIterator = addDeltaMonthsToDateKey(start, 1);
  let nbIterations = 0;
  while (dateKeyIterator !== end) {
    if (nbIterations > 1000) {
      // eslint-disable-next-line
      console.error("Reached maximum number of iterations for getAllDateKeysBetween (100)", start, end);
      break;
    }
    dateKeys.push(dateKeyIterator);
    dateKeyIterator = addDeltaMonthsToDateKey(dateKeyIterator, 1);
    nbIterations++;
  }

  if (inclusive) {
    dateKeys.unshift(start);
    dateKeys.push(end);
  }

  return dateKeys;
});

export const resolveDatasourceDates = memoize(
  (
    start: string | null,
    end: string | null,
    templateStart: string,
    templateEnd: string,
    lastMonthOfActuals: string,
    preserveFullDate: boolean = false,
  ): {start: string; end: string} => {
    // console.log(JSON.stringify([start, end, templateStart, templateEnd, lastMonthOfActuals]));
    if (!preserveFullDate && (start?.length ?? 0) >= 7 && (end?.length ?? 0) >= 7) {
      return {start: start!.slice(0, 7), end: end!.slice(0, 7)};
    } else if (preserveFullDate && (start?.length ?? 0) === 10 && (end?.length ?? 0) === 10) {
      return {start: start!, end: end!};
    }
    return resolveDaterange(`${start || templateStart}:${end || templateEnd}`, null, lastMonthOfActuals);
  },
);

export function resolveStartEndFromState(
  datasource: Datasource,
  state: RootState,
  templateOptionsOverride?: TemplateOptions,
) {
  const row = state.templateRows.entities[datasource?.row_id ?? ""];
  let templateOptions = templateOptionsOverride;
  if (!templateOptions) {
    const template = state.templates.entities[row?.template_id ?? ""];
    if (!template || !row) return {start: "2022-01", end: "2024-12"};
    templateOptions = {...template.options};

    templateOptions.start = template.options.start;
    templateOptions.end = template.options.end;
  }

  return resolveDatasourceDates(
    datasource.start ? datasource.start : null,
    datasource.end ? datasource.end : null,
    templateOptions.start,
    templateOptions.end,
    templateOptions.lastMonthOfActuals,
    true,
  );
}

/**
 * Checks if a given dateKey is included in the datasource range. Granularity level is month.
 *
 * IMPORTANT: this only checks for the range months. For instance, 2023-04 would be included in range 2023-04-30 - 2023-07-01
 *
 * @param dateKey The dateKey to check for
 * @param datasource The datasource to validate against
 * @param templateOptions Options for the template, or an object containing at least start, end and lastMonthOfActuals
 * @returns true or false - whether or not the dateKey is included in the datasource range
 */
export function isDateKeyInRange(
  dateKey: string,
  datasource: Datasource | null,
  templateOptions: Pick<TemplateOptions, "start" | "end" | "lastMonthOfActuals">,
) {
  if (!datasource) return false;
  let start = datasource.start;
  let end = datasource.end;

  if (start?.length === 10 && start.includes("-")) start = start.slice(0, 7);
  if (end?.length === 10 && end.includes("-")) end = end.slice(0, 7);

  const resolvedRangeDates = resolveDatasourceDates(
    start,
    end,
    templateOptions.start,
    templateOptions.end,
    templateOptions.lastMonthOfActuals,
  );
  const shortDateKey = dateKey.slice(0, 7);
  return (
    shortDateKey === resolvedRangeDates.start ||
    shortDateKey === resolvedRangeDates.end ||
    (shortDateKey > resolvedRangeDates.start && shortDateKey < resolvedRangeDates.end)
  );
}

type Interval = {start: string; end: string};
export function divideRangeIntoIntervals(
  start: string,
  end: string,
  intervalNumber: number,
  intervalType: "months" | "years" | "days" | "weeks",
) {
  const startDayJs = dayjs(start);
  const endDayJs = dayjs(end);
  const intervals: Interval[] = [];

  let cursorStart = startDayJs.clone();
  while (!cursorStart.isAfter(endDayJs)) {
    const intervalStart = cursorStart.format("YYYY-MM-DD");
    cursorStart = cursorStart.add(intervalNumber, intervalType);
    const intervalEnd =
      cursorStart.isAfter(endDayJs) || cursorStart.isSame(endDayJs)
        ? endDayJs.endOf("month").format("YYYY-MM-DD")
        : cursorStart.subtract(1, "day").format("YYYY-MM-DD");
    intervals.push({start: intervalStart, end: intervalEnd});
  }

  return intervals;
}
