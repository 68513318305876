import * as jp from "jsonpath-plus";
import get from "lodash.get";
import slugify from "slugify";

import type {DbTables} from "@shared/../server/lib/knex-types";
import type {FormulaDatasource, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {Integration, TemplateRow} from "@shared/types/db";
import type {FinancialComponent, Team} from "@shared/types/hiring-plan";

export type RenameSlugReturnType = {
  template_rows?: TemplateRow[];
  datasources?: (FormulaDatasource | HiringPlanFormulaDatasource)[];
  teams?: Team[];
  financial_components?: FinancialComponent[];
  integrations?: Integration[];
};

export default function getSlug(name: string, lower: boolean = true) {
  let str = name.slice(0, 125).replace(/&/g, "");
  const slug = slugify(str, {
    lower,
    replacement: "_",
    strict: true,
  });

  return slug;
}

export function getUniqueSlug(
  name: string,
  takenSlugsList: string[],
  currentName?: string,
): {slug: string; index?: number | null};
export function getUniqueSlug(
  name: string,
  takenSlugsObject: Record<string, any>,
  currentName?: string,
): {slug: string; index?: number | null};
export function getUniqueSlug(
  name: string,
  existing: string[] | Record<string, any>,
  currentName?: string,
): {slug: string; index?: number | null} {
  const takenNamesObject: Record<string, any> = Array.isArray(existing)
    ? Object.fromEntries(existing.map((existingSlug) => [existingSlug, true]))
    : existing;
  let slugName = getSlug(name);
  let i = 0;
  if (slugName !== currentName && takenNamesObject[slugName]) {
    i++;
    while (`${slugName}_${i}` !== currentName && !!takenNamesObject[`${slugName}_${i}`]) {
      i++;
    }
    slugName = `${slugName}_${i}`;
  }

  return {slug: slugName, index: i === 0 ? null : i};
}
export type DataRelationshipItem = {
  table: keyof DbTables;
  column: string; // name of the Postgres JSONB column
  jsonPaths: {
    keyPath: string; // JSONPath
    pgJsonPath: string; // Postgres-specific JSONPath
  }[];
  additionalUpdateLogic?: (entity: any, path: string[], oldValue: string, newValue: string) => void;
  referenceTo:
    | "row.name"
    | "row.id"
    | "team.name"
    | "team.id"
    | "template.name"
    | "template.id"
    | "vendor.name"
    | "vendor.id"
    | "department.name"
    | "department.id"
    | "financial_component.name"; // what the value at the key path references
  idColumn?: string;
};

export const jsonColumnDataRelationships: DataRelationshipItem[] = [
  {
    table: "datasources",
    column: "options",
    jsonPaths: [
      {
        keyPath: `$.references[?(@.row == "[OLD_VALUE]")].row`,
        pgJsonPath: `$.references[*].row ? (@ == "[OLD_VALUE]")`,
      },
    ],
    additionalUpdateLogic: (entity: any, path: string[], oldValue: string, newValue: string) => {
      const formula = get(entity, "options.formula");
      if (typeof formula !== "string") return;

      const pattern = `(=?[\\n\\r\\s]*\\[[\\n\\r\\s]*(?:(?:(?:row:)|(?:(?:[a-zA-Z0-9_]+[\\n\\r\\s]*!)?))[\\n\\r\\s]*))(${oldValue})([\\n\\r\\s]*(?:,|\\]))`;
      const replacement = `$1${newValue}$3`;
      const regex = new RegExp(pattern, "gis");

      entity.options.formula = formula.replace(regex, replacement);
      if (entity.options.references) {
        for (const ref of entity.options.references) {
          if (ref.refStr) {
            ref.refStr = ref.refStr.replace(regex, replacement);
          }
        }
      }
    },
    referenceTo: "row.name",
  },
  {
    table: "datasources",
    column: "options",
    jsonPaths: [
      {
        keyPath: `$.references[?(@.template == "[OLD_VALUE]")].template`,
        pgJsonPath: `$.references[*].template ? (@ == "[OLD_VALUE]")`,
      },
    ],
    referenceTo: "template.name",
  },
  {
    table: "datasources",
    column: "options",
    jsonPaths: [
      {
        keyPath: `$.references[?(@.department == "[OLD_VALUE]")].department`,
        pgJsonPath: `$.references[*].department ? (@ == "[OLD_VALUE]")`,
      },
    ],
    additionalUpdateLogic: (entity: any, path: string[], oldValue: string, newValue: string) => {
      const formula = get(entity, "options.formula");
      if (typeof formula !== "string") return;
      const pattern = `^((.*,.*,.*[^a-zA-Z0-9_])|(.*department:[^a-zA-Z0-9_]*))(${oldValue})([^a-zA-Z0-9_].*)$`;
      const replacement = `$1${newValue}$5`;
      const regex = new RegExp(pattern, "gis");

      entity.options.formula = formula.replace(regex, replacement);

      if (entity.options.references) {
        for (const ref of entity.options.references) {
          if (ref.refStr) {
            ref.refStr = ref.refStr.replace(regex, replacement);
          }
        }
      }
    },
    referenceTo: "department.name",
  },
  {
    table: "datasources",
    column: "options",
    jsonPaths: [
      {
        keyPath: `references..[?(@.compensation_name == "[OLD_VALUE]")]`,
        pgJsonPath: `$.references[*].tags.compensation_name ? (@ == "[OLD_VALUE]")"`,
      },
    ],
    additionalUpdateLogic: (entity: any, path: string[], oldValue: string, newValue: string) => {
      const formula = get(entity, "options.formula");
      if (typeof formula !== "string") return;

      const pattern = `^(.*compensation_name:[^a-zA-Z0-9_]*)(${oldValue})([^a-zA-Z0-9_]+.*)$`;
      const replacement = `$1${newValue}$3`;
      const regex = new RegExp(pattern, "gis");

      entity.options.formula = formula.replace(regex, replacement);

      if (entity.options.references) {
        for (const ref of entity.options.references) {
          if (ref.refStr) {
            ref.refStr = ref.refStr.replace(regex, replacement);
          }
        }
      }
    },
    referenceTo: "financial_component.name",
  },
  {
    table: "datasources",
    column: "options",
    jsonPaths: [
      {
        keyPath: `references..[?(@.team == "[OLD_VALUE]")].team`,
        pgJsonPath: `$.references[*].tags.team ? (@ == "[OLD_VALUE]")`,
      },
    ],
    additionalUpdateLogic: (entity: any, path: string[], oldValue: string, newValue: string) => {
      const formula = get(entity, "options.formula");
      if (typeof formula !== "string") return;

      const pattern = `^(.*team:[^a-zA-Z0-9_]*)(${oldValue})([^a-zA-Z0-9_]+.*)$`;
      const replacement = `$1${newValue}$3`;
      const regex = new RegExp(pattern, "gis");

      entity.options.formula = formula.replace(regex, replacement);

      if (entity.options.references) {
        for (const ref of entity.options.references) {
          if (ref.refStr) {
            ref.refStr = ref.refStr.replace(regex, replacement);
          }
        }
      }
    },
    referenceTo: "team.name",
  },
  {
    table: "template_rows",
    column: "tags",
    jsonPaths: [
      {
        keyPath: `$.[?(@ == "[OLD_VALUE]" && @property == "team")]`,
        pgJsonPath: `$.team ? (@ == "[OLD_VALUE]")`,
      },
    ],
    referenceTo: "team.name",
  },
  {
    table: "integrations",
    column: "data",
    jsonPaths: [
      {
        keyPath: `$.[?(@ == "[OLD_VALUE]" && @property == "defaultBankAccount")]`,
        pgJsonPath: `$.defaultBankAccount ? (@ == "[OLD_VALUE]")`,
      },
    ],
    referenceTo: "row.name",
  },
  {
    table: "hp_teams",
    column: "defaults",
    jsonPaths: [
      {
        keyPath: `$.row_mappings.*.[?(@ == "[OLD_VALUE]")]`,
        pgJsonPath: `$.row_mappings.*.* ? (@ == "[OLD_VALUE]")`,
      },
    ],
    referenceTo: "row.name",
  },
  {
    table: "hp_financial_components",
    column: "row_mappings",
    jsonPaths: [
      {
        keyPath: `$.[?(@ == "[OLD_VALUE]")]`,
        pgJsonPath: `$.* ? (@ == "[OLD_VALUE]")`,
      },
    ],
    referenceTo: "row.name",
    idColumn: "name",
  },
];

export function updateSlugInEntities<
  T extends DataRelationshipItem,
  E extends Record<string, any> & Record<T["column"], any>,
>({column, jsonPaths, additionalUpdateLogic}: T, entities: E[], oldValue: string, newValue: string) {
  for (const entity of entities) {
    for (const {keyPath} of jsonPaths) {
      const paths = jp.JSONPath({
        json: entity[column],
        path: keyPath.replace("[OLD_VALUE]", oldValue),
        resultType: "path",
      });

      if (!paths) continue;

      for (const path of paths) {
        const pathArray = jp.JSONPath.toPathArray(path);
        if (additionalUpdateLogic) {
          additionalUpdateLogic(entity, pathArray, oldValue, newValue);
        }
        const property = pathArray.pop();
        const parentObj = pathArray.length === 1 ? entity[column] : get(entity[column], pathArray.slice(1));

        if (property && parentObj && typeof parentObj === "object") {
          parentObj[property] = newValue;
        }
      }
    }
  }

  return entities;
}
