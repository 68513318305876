import {store} from "@app/client-store";
import colors from "@app/styles/colors";
import Checkbox from "@components/Checkbox";
import TextInput from "@components/TextInput";
import {getCacheKeyFromReadableKey} from "@shared/data-functions/cache/cache-utilities";
import {useState} from "react";

import useStyles from "./ActiveCell.jss";
import {CellDebugTools} from "./CellDebugTools";

export default function CacheKeyInspector({resolveIds}: {resolveIds: boolean}) {
  const styles = useStyles();
  const [cacheKey, setCacheKey] = useState("");
  const [isRawCacheKey, setIsRawCacheKey] = useState(false);

  let cacheKeyToUse = cacheKey;
  if (!isRawCacheKey) {
    const state = store.getState();
    cacheKeyToUse = getCacheKeyFromReadableKey(state, cacheKey);
  }

  return (
    <div className={styles.activeCellDebug}>
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: 10,
          }}
        >
          <TextInput
            value={cacheKey}
            onChange={(e) => setCacheKey(e.target.value)}
            placeholder="Enter cache key to inspect..."
          />
        </div>
        <div onKeyDown={(evt) => evt.stopPropagation()} onKeyUp={(evt) => evt.stopPropagation()}>
          <Checkbox checked={isRawCacheKey} onClick={() => setIsRawCacheKey(!isRawCacheKey)} text="Raw cache key" />
        </div>
      </div>
      {cacheKey?.length ? (
        <CellDebugTools resolveIds={resolveIds} context="cacheKeyInspector" cacheKey={cacheKeyToUse} />
      ) : (
        <span
          style={{
            color: colors.ui.lightBlue01,
            fontSize: 13,
          }}
        >
          No cache key selected
        </span>
      )}
    </div>
  );
}
