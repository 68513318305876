import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import hiringPlanAPI from "@shared/apis/hiring-plan";
import id from "@shared/lib/id";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getUniqueSlug} from "@shared/lib/slug";
import {getTeamsAdapter} from "@shared/state/entity-adapters";

import {api as hiringPlanApi} from "../employees/slice";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {TeamsState} from "@shared/state/entity-adapters";
import type {RootState} from "@shared/state/store";
import type {HiringPlanFormulaDataProvider} from "@shared/types/datasources";
import type {FcMappings, Team} from "@shared/types/hiring-plan";

export type BasePayload = {
  id: keyof TeamsState["entities"] & string;
};

export const api = {
  upsert: createAsyncThunk("teams/upsert", hiringPlanAPI.upsertTeam),
  delete: createAsyncThunk("teams/remove", hiringPlanAPI.removeTeam),
};

export const getSlice = () => {
  const teamsAdapter = getTeamsAdapter();
  return createSlice({
    name: "teams",
    initialState: teamsAdapter.getInitialState(),
    reducers: {
      upsertTeamsLocal: teamsAdapter.upsertMany,
      removeTeamsLocal: teamsAdapter.removeMany,
      teamAdded: (state, action: PayloadAction<BasePayload>) => {
        const newTeam: Team = {
          id: action.payload.id,
          name: id(),
          display_name: "",
          parent_id: null,
          defaults: {},
        };

        teamsAdapter.addOne(state, newTeam);
      },
      teamUpdated: <S extends TeamsState, T extends keyof Team>(
        state: S,
        action: PayloadAction<
          BasePayload & {
            key: T;
            value: Team[T];
          }
        >,
      ) => {
        const {id, key, value} = action.payload;

        const team = state.entities[id];
        if (!team) return;

        team[key] = value;

        if (key === "display_name") {
          team.name = getUniqueSlug(
            value as string,
            Object.values(state.entities).map((team) => team?.name || ""),
            team.name,
          ).slug;
        }
      },
      teamDeleted: (state, action: PayloadAction<BasePayload & Team>) => {
        teamsAdapter.removeOne(state, action.payload.id);
      },
      teamDefaultValueUpserted: (
        state,
        action: PayloadAction<
          BasePayload & {
            fcName: string;
            value: HiringPlanFormulaDataProvider["options"]["ui"]["value"];
            type: HiringPlanFormulaDataProvider["options"]["ui"]["type"];
            expressedAs?: HiringPlanFormulaDataProvider["options"]["ui"]["expressedAs"];
          }
        >,
      ) => {
        const team = state.entities[action.payload.id];
        if (!team) return;
        team.defaults.values ||= {};
        const uiOptions: HiringPlanFormulaDataProvider["options"]["ui"] = {
          value: action.payload.value,
          type: action.payload.type,
          expressedAs: action.payload.expressedAs,
        };

        const dataProvider: HiringPlanFormulaDataProvider = {
          type: "hiring-plan-formula",
          options: {
            formula: `=${uiOptions.value}${uiOptions.expressedAs === "yearly" ? " / 12" : ""}`,
            ui: uiOptions,
          },
        };
        team.defaults.values[action.payload.fcName] = [
          {
            // type: "static",
            start: null,
            end: null,
            dataProvider,
          },
        ];
      },
      teamDefaultMappingsUpdated: <S extends TeamsState, K extends keyof FcMappings>(
        state: S,
        action: PayloadAction<
          BasePayload & {
            fcName: string;
            mappingKey: K;
            mappingValue: FcMappings[K];
          }
        >,
      ) => {
        const {fcName, mappingKey, mappingValue} = action.payload;
        const team = state.entities[action.payload.id];
        if (!team) return;

        team.defaults.row_mappings ||= {};
        team.defaults.row_mappings[fcName] ||= {};

        team.defaults.row_mappings[fcName][mappingKey] = mappingValue;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(hiringPlanApi.fetchHiringPlanEntities.fulfilled, (state, action) => {
        teamsAdapter.setAll(state, action.payload.teams);
      });
    },
  });
};

const _slice = getSlice();

export const {upsertTeamsLocal, removeTeamsLocal} = _slice.actions;

export const {teamAdded, teamUpdated, teamDeleted, teamDefaultValueUpserted, teamDefaultMappingsUpdated} =
  createSyncedActionCreators(_slice.actions);

export const {
  selectById: selectTeamById,
  selectIds: selectTeamIds,
  selectEntities: selectTeamEntities,
  selectAll: selectAllTeams,
  selectTotal: selectTotalTeams,
} = getTeamsAdapter().getSelectors((state: RootState) => state.teams);
