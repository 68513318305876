import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  detailsMain: {},
  orangeButton: {
    marginTop: 10,
  },
  tags: {
    display: "flex",
    flexDirection: "column",
  },
  noTags: {
    color: colors.text.lightBlue01,
    width: "100%",
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 15,
  },
  buttonSection: {
    width: 80,
    display: "inner-flex",
  },
  tagsInner: {
    display: "flex",
    paddingBottom: 15,
  },
  tagInput: {
    "&:not(:last-child)": {
      marginBottom: 10,
    },
  },
  tagValueAndDelete: {
    display: "flex",
    alignItems: "center",

    "& > div:first-child": {
      marginRight: 8,
      flex: 1,
    },

    "& > div:last-child": {
      marginRight: -8,
    },
  },
});
