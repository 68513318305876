import hiringPlanListViewReducer from "@features/hiring-plan/ListView/slice";
import hiringPlanReducer from "@features/hiring-plan/slice";
import reportingReducer from "@features/reporting/slice";
import departmentsSettingsReducer from "@features/settings/Departments/slice";
import integrationSettingsReducer from "@features/settings/Integrations/slice";
import tooltipReducer from "@features/shared-views/FormulaTextEditor/Tooltip/slice";
import templatesViewReducer from "@features/templates/state/slice";
import {combineReducers} from "@reduxjs/toolkit";
import {fireActionInWorker} from "@shared/worker-fn-gateway";
import {registerAppListeners} from "@state/listeners-list";
import {getReducers} from "@state/reducers";
import {getStore, workerSyncMiddleware} from "@state/store";
import {isNode} from "browser-or-node";

import {registerClientListeners} from "./browser-only-listeners";
import uiStateReducer from "./ui-state/slice";
import clientWebsocketListeners from "./websockets/websocket-client-listeners";
import {websocketMiddleware} from "./websockets/websocket-redux-middleware";
import websocketSharedListeners from "./websockets/websocket-shared-listeners";
import {initWebsocketListeners} from "./websockets/websockets";

import type {AnyAction, ThunkDispatch} from "@reduxjs/toolkit";
import type {ThunkExtraArg} from "@shared/lib/fetch";

const sharedReducers = getReducers();

const clientReducers = combineReducers({
  ...sharedReducers,
  uiState: uiStateReducer,
  reporting: reportingReducer,
  hiringPlan: hiringPlanReducer,
  hiringPlanListView: hiringPlanListViewReducer,
  templatesView: templatesViewReducer,
  integrationSettings: integrationSettingsReducer,
  departmentsSettings: departmentsSettingsReducer,
  tooltip: tooltipReducer,
});

const clientMiddlewares = [websocketMiddleware, workerSyncMiddleware(fireActionInWorker)];

/**
 * Create a store that has the default state plus the client state
 */
export const store = getStore(clientReducers, clientMiddlewares);
export type ClientReduxStore = typeof store;
export type ClientRootState = ReturnType<typeof store.getState>;
export type ClientAppDispatch = typeof store.dispatch;
export type ClientThunkDispatch = ThunkDispatch<ClientRootState, void, AnyAction>;
export type ClientThunkDispatchWithExtraArg = ThunkDispatch<ClientRootState, ThunkExtraArg, AnyAction>;

type AsyncThunkConfig = {
  state?: ClientRootState;
  dispatch?: ClientThunkDispatch;
};
// export type ClientThunkAction = ThunkAction<void, ClientRootState, unknown, AnyAction>;
export type ClientThunkAction = (
  dispatch: ThunkDispatch<ClientRootState, ThunkExtraArg, AnyAction>,
  getState: () => ClientRootState,
  extraArgument: unknown,
) => void;

/**
 * Start listening to WebSocket events and register the Redux event listeners
 */
initWebsocketListeners(store, websocketSharedListeners, clientWebsocketListeners);
registerAppListeners(store.startListening);
registerClientListeners(store.startListening);

const originalGetState = store.getState;

/**
 * Wrap the client getState to make sure it's never used from the backend
 */
store.getState = () => {
  if (isNode) throw new Error("The global store should not be used by the backend");
  return originalGetState() as ClientRootState;
};

// @ts-ignore
// if (!isNode) store.dispatch = overloadDispatch(store.dispatch, "main thread", fireActionInWorker);

/**
 * Create a wrapper around dispatchInBothThreads that provides it with the client store
 * @returns ReturnType<typeof dispatchInBothThreads>
 */
export async function clientDispatchInBothThreads(action: Parameters<typeof fireActionInWorker>[0]) {
  store.dispatch(action);
  return fireActionInWorker(action);
}
