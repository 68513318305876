import {useAppSelector} from "@app/hooks";
import {selectSidebarOpen} from "@app/ui-state/slice";
import clsx from "clsx";

import Breadcrumbs from "./Breadcrumbs";
import NotificationsArea from "./NotificationsArea";
import useStyles from "./TopNav.jss";

export default function TopNav({}) {
  const styles = useStyles();
  const sidebarOpen = useAppSelector(selectSidebarOpen);

  const classes = clsx(styles.topBarMain, {
    [styles.hamburgerVisible]: !sidebarOpen,
  });

  return (
    <div className={classes}>
      <Breadcrumbs />
      <NotificationsArea />
    </div>
  );
}
