import {useAppSelector} from "@app/hooks";
import Dropdown from "@components/Dropdown";
import {selectAllDepartments} from "@state/departments/slice";

import {getDepartmentDropdownItems} from "../Navigation/breadcrumbs-utilities";

import type {DropdownItem} from "@components/Dropdown";

export interface DepartmentSelectorProps {
  onChange: (item: DropdownItem) => void;
  selectedKey?: string | null;
  disabled?: boolean;
  nullLabel?: string;
}

export default function DepartmentSelector({onChange, selectedKey, disabled, nullLabel}: DepartmentSelectorProps) {
  const departments = useAppSelector(selectAllDepartments);

  const departmentItems: DropdownItem[] = getDepartmentDropdownItems(
    departments,
    selectedKey ?? null,
    nullLabel ?? "None",
  );

  return (
    <Dropdown
      items={departmentItems}
      onSelect={onChange}
      selectedKey={selectedKey || null}
      buttonBold={false}
      buttonFill
      renderAsChild
      disabled={disabled}
    />
  );
}
