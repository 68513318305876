import {HiringPlanApiContracts} from "@shared/apis/contracts/hiring-plan.contracts";
import {apiWithContract} from "@shared/lib/fetch";
import type {ApiEndpoints} from "@shared/state/store";
import type {Employee} from "@shared/types/hiring-plan";

const endpoints = {
  fetchHiringPlanEntities(_arg, thunkAPI) {
    return apiWithContract<HiringPlanApiContracts["getAllHiringPlanData"]>(thunkAPI, {
      method: "get",
      path: "hiring-plan",
      body: null,
      query: null,
    });
  },
  upsertEmployee(employee: Employee, thunkAPI) {
    return apiWithContract<HiringPlanApiContracts["upsertEmployee"]>(thunkAPI, {
      method: "put",
      path: `hiring-plan/employees/${employee.id}`,
      body: employee,
      query: null,
    });
  },
  removeEmployees(employeeIds: string[], thunkAPI) {
    return apiWithContract<HiringPlanApiContracts["deleteEmployees"]>(thunkAPI, {
      method: "del",
      path: `hiring-plan/employees`,
      body: employeeIds,
      query: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
