import colors from "@app/styles/colors";
import {baseInputPadding, baseInputStyles, containerShadowStyles, labelStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export const sharedValues = {
  inputPadding: ((baseInputStyles.padding as [number, number])[1] || 0) + 27,
};

export default createUseStyles({
  inputWrapper: {
    position: "relative",
    userSelect: "none",
  },
  iconLeft: {
    position: "absolute",
    extend: getSvgColorStyles(colors.ui.lightBlue01),
    zIndex: 1,
    left: 9,
    top: 5,
  },
  iconRight: {
    position: "absolute",
    extend: getSvgColorStyles(colors.ui.lightBlue01),
    zIndex: 1,
    right: 9,
    top: 5,
  },
  openCalendarButton: {
    pointerEvents: "none",
    cursor: "default",
    opacity: 1,
  },
  disabled: {},
  inline: {},
  clearable: {},
  noBorder: {
    border: "0!important",
    outline: "0!important",
    boxShadow: "none!important",
    background: "transparent",
    ".react-datepicker-ignore-onclickoutside": {border: 0},
  },
  input: {
    extend: baseInputStyles,
    paddingLeft: sharedValues.inputPadding,
    width: `calc(100% - ${sharedValues.inputPadding}px - ${baseInputPadding[1]}px)`,
    minWidth: 80,

    "$clearable &": {
      // paddingRight: sharedValues.inputPadding,
    },
  },
  noLeftIcon: {
    paddingLeft: sharedValues.inputPadding - 27,
  },
  "@global": {
    ".react-datepicker-popper": {
      zIndex: 2,
    },
    ".react-datepicker": {
      fontFamily: "Mont",
      border: 0,
    },
    "label .react-datepicker": {
      extend: containerShadowStyles,
      paddingBottom: 10,
    },
    ".react-datepicker-ignore-onclickoutside": {
      outline: [1, "solid", colors.primary.gold],
    },
    ".react-datepicker__header": {
      background: "transparent",
      borderBottom: 0,
    },
    ".react-datepicker__header:not(label .react-datepicker__header)": {
      padding: 0,
    },
    ".react-datepicker__day-names:not(label .react-datepicker__day-names), .react-datepicker__month:not(label .react-datepicker__month)":
      {
        display: "none",
      },
    ".react-datepicker__day-names": {
      marginLeft: "0.4rem",
      marginRight: "0.4rem",
      display: "flex",
    },
    ".react-datepicker__week": {
      display: "flex",
    },
    ".react-datepicker__day, .react-datepicker__day:hover": {
      flex: 1,
      color: colors.text.darkBlue02,
      fontSize: 12,
      lineHeight: 24,
      fontWeight: 600,
      borderRadius: 9999,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: "auto 1 / 1",
    },
    ".react-datepicker__day:hover:not(.react-datepicker__day--disabled), .react-datepicker__day--keyboard-selected, .react-datepicker__day--keyboard-selected:hover":
      {
        background: colors.primary.lightOrange50,
        outline: "transparent",
        border: "transparent",
      },
    ".react-datepicker__day--selected, .react-datepicker__day--selected:hover": {
      color: colors.text.white,
      background: colors.primary.gold,
      outline: colors.primary.gold,
    },
    ".react-datepicker__day-name": {
      extend: labelStyles,
      fontSize: 11,
      flex: 1,
    },
    ".react-datepicker__triangle::before, .react-datepicker__triangle::after": {
      borderBottomColor: "white !important",
      borderTopColor: "white !important",
    },
    ".react-datepicker__day--disabled, .react-datepicker__day--disabled:hover": {
      color: colors.text.lightBlue03,
    },
  },
  calendarWrapper: {},
  arrowLeft: {
    marginRight: 8,

    "& svg path": {
      fill: colors.ui.lightBlue02,
    },
  },
  arrowRight: {
    marginLeft: 8,

    "& svg path": {
      fill: colors.ui.lightBlue02,
    },
    transform: "rotate(180deg)",
  },
  customCalendarHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "label &": {margin: 10},
  },
  monthDropdownWrapper: {
    marginLeft: 10,
  },
});
