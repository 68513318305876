import colors from "@app/styles/colors";
import {sharedButtonInputStyles} from "@app/styles/general-ui";
import {getBorderAsBoxShadow} from "@app/styles/styles-utilities";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  textInputDropdownComboMain: {
    display: "flex",
    // border: [1, "solid", colors.ui.lightBlue05],
    borderRadius: 5,
    alignItems: "center",

    "& > :not($separator)": {
      height: 40,

      "&:last-child": {
        marginLeft: -1,
      },
    },

    "& input": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderLeftColor: colors.ui.lightBlue05,
      borderTopColor: colors.ui.lightBlue05,
      borderBottomColor: colors.ui.lightBlue05,
      borderRightColor: "transparent",
      boxShadow: "none",
      height: 38,
      borderCollapse: "collapse",
    },
  },
  dropdownTrigger: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftColor: "transparent",
    borderTopColor: colors.ui.lightBlue05,
    borderRightColor: colors.ui.lightBlue05,
    borderBottomColor: colors.ui.lightBlue05,
    boxShadow: "none",
  },
  separator: {
    width: 1,
    height: 15,
    marginRight: -1,
    backgroundColor: colors.ui.lightBlue04,
  },
});
