import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

type BtnStyleProps = {white?: boolean};

export default createUseStyles({
  roundButtonMain: {
    userSelect: "none",
    cursor: "pointer",
    "&:focus, &:focus-within, &:active, &:hover": {
      outline: [1, "solid", "transparent"],
    },
    "& div svg path": {
      fill: colors.text.lightBlue01,

      "$white &": {
        fill: colors.text.white,
      },
    },
  },
  white: {},
  active: {},
  cssStatesEnabled: {},
  disabled: {
    pointerEvents: "none",
    opacity: 0.3,
  },
  roundIconCssStates: {
    transition: "all 75ms ease-in-out",

    "&:hover": {
      backgroundColor: colors.ui.lightBlue05,

      "$white &": {
        backgroundColor: colors.text.white,
      },
    },
    "&:active:hover, $active &, $active:hover &": {
      backgroundColor: colors.primary.gold,
      color: colors.text.white,

      "& svg path": {
        fill: colors.text.white + "!important",

        "$white &": {
          fill: colors.text.lightBlue01 + "!important",
        },
      },

      "$white &": {
        backgroundColor: colors.text.white,
        color: colors.text.lightBlue01,
      },
    },

    "&:active:hover": {
      transform: "scale(0.9)",
    },
  },
});
