import colors from "@app/styles/colors";
import {getSvgColorStylesFromProps} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

import type {ActualColors, IButton} from ".";

interface ButtonStyleVars extends IButton, ActualColors {}

const hoverStyles = {
  background: ({backgroundColor, hoverBackgroundColor}: ButtonStyleVars) => hoverBackgroundColor || backgroundColor,
  color: ({textColor, hoverTextColor}: ButtonStyleVars) => hoverTextColor || textColor,

  "& $iconRight, & $iconLeft": {
    extend: getSvgColorStylesFromProps("hoverTextColor", "textColor"),
  },
};

const sizeMapping: Record<NonNullable<ButtonStyleVars["size"]>, number> = {
  large: 45,
  normal: 40,
  small: 35,
  xsmall: 30,
  xxsmall: 26,
};

export default createUseStyles({
  buttonMain: {
    color: ({textColor}: ButtonStyleVars) => textColor,
    background: ({backgroundColor}: ButtonStyleVars) => backgroundColor,
    textAlign: ({dropdown, minimal}: ButtonStyleVars) => (dropdown || minimal ? "left" : "center"),
    display: "inline-flex",
    alignItems: "center",
    cursor: ({disabled}: ButtonStyleVars) => (disabled ? "default" : "pointer"),
    borderRadius: 5,
    height: ({size}: ButtonStyleVars) => (!!size ? sizeMapping[size] : 40),
    paddingLeft: ({dropdown, padding, size}: ButtonStyleVars) =>
      padding ? (size === "xxsmall" ? 15 : 20) : dropdown ? 15 : 0,
    paddingRight: ({dropdown, padding, size}: ButtonStyleVars) =>
      padding ? (dropdown ? 12 : size === "xxsmall" ? 15 : 20) : 0,
    fontWeight: ({bold}: ButtonStyleVars) => (bold ? 700 : 600),
    fontSize: ({dropdown, size}: ButtonStyleVars) => (size?.includes("small") ? 12 : dropdown ? 13 : 14),
    lineHeight: 25,
    borderColor: ({textColor}: ButtonStyleVars) => textColor,
    borderStyle: "solid",
    borderWidth: ({outline}: ButtonStyleVars) => (outline ? 1 : 0),
    userSelect: "none",
    // letterSpacing: ({dropdown, size}: ButtonStyleVars) =>
    //   size === "small" || size === "xxsmall" ? 12 / 175 : dropdown ? 13 / 175 : 14 / 175,

    "& span": {
      flex: 1,
      whiteSpace: "nowrap",
      marginTop: 2,
      textDecoration: "none",
    },

    "&:hover": {
      extend: hoverStyles,

      "& $rightText": {
        color: ({textColor, hoverTextColor}: ButtonStyleVars) => hoverTextColor || textColor,
      },
    },
  },

  tag: {
    borderRadius: ({size}: ButtonStyleVars) => (size ? sizeMapping[size] : 40) / 2,
  },

  active: {
    extend: hoverStyles,
    "& $rightText": {
      color: ({textColor, hoverTextColor}: ButtonStyleVars) => hoverTextColor || textColor,
    },
  },

  iconLeft: {
    extend: getSvgColorStylesFromProps("textColor"),
    marginRight: 4,
  },

  iconRight: {
    extend: getSvgColorStylesFromProps("textColor"),
    marginLeft: 16,
  },

  rightText: {
    color: colors.text.lightBlue01,
    fontWeight: 600,
  },
});
