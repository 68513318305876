import clsx from "clsx";
import React from "react";

import RoundIcon from "../RoundIcon/index";
import useStyles from "./styles.jss";

import type {RoundIconProps} from "../RoundIcon/index";

export interface RoundButtonProps extends RoundIconProps {
  disabled?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (evt: React.MouseEvent<HTMLElement>) => void;
  enableCssStates?: boolean;
  buttonClassName?: string;
  white?: boolean;
  active?: boolean;
}

export default function RoundButton({
  disabled,
  onClick,
  onMouseDown,
  enableCssStates = false,
  className,
  buttonClassName,
  white,
  active,
  ...roundIconProps
}: RoundButtonProps) {
  const styles = useStyles();

  const mainClasses = clsx(buttonClassName, styles.roundButtonMain, {
    [styles.disabled]: disabled,
    [styles.white]: white,
    [styles.active]: active,
  });

  const iconClasses = clsx(className, {[styles.roundIconCssStates]: enableCssStates});

  return (
    <div className={mainClasses} onClick={onClick} onMouseDown={onMouseDown}>
      <RoundIcon className={iconClasses} {...roundIconProps} />
    </div>
  );
}
