import {createSelector} from "@reduxjs/toolkit";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {projKey, projKeyOmitNulls} from "@state/utils";

import type {EntityId} from "@reduxjs/toolkit";
import type {HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {RootState} from "@state/store";

export const selectMatchingDatasources = createSelector(
  (state: RootState) => state.datasources,
  (
    _: RootState,
    args: {scenarioId: string | null; rowId: string; departmentId?: EntityId | null; vendor?: null | string},
  ) => args,
  (datasourcesState, {rowId, scenarioId, departmentId, vendor}) => {
    return !scenarioId
      ? []
      : mapEntitiesToIds(
          datasourcesState.entities,
          datasourcesState.idsByProjKey[projKeyOmitNulls(rowId, scenarioId, departmentId, vendor)],
        );
  },
);

export const selectEmployeeFcDatasources = createSelector(
  (state: RootState) => state.datasources,
  (_: RootState, args: {employeeId: string; fcName: string; scenarioId: string | null}) => args,
  (datasourcesState, {employeeId, fcName, scenarioId}) => {
    return mapEntitiesToIds(
      datasourcesState.entities,
      datasourcesState.idsByEmployeeIdFcNameScenarioId[projKey(employeeId, fcName, scenarioId || "undefined")],
    ) as HiringPlanFormulaDatasource[];
  },
);

export const selectEmployeeFcDatasourcesSorted = createSelector(selectEmployeeFcDatasources, (datasources) => {
  return datasources.sort((a, b) =>
    (!a.start ? "0000-00-00" : getFormattedFullDate(a.start, "start")).localeCompare(
      !b.start ? "0000-00-00" : getFormattedFullDate(b.start, "start"),
    ),
  );
});

export const selectFcDatasources = createSelector(
  (state: RootState) => state.datasources,
  (datasourcesState) => {
    const fcDatasources: HiringPlanFormulaDatasource[] = [];
    for (const id of datasourcesState.ids) {
      const datasource = datasourcesState.entities[id];
      if (datasource?.type !== "hiring-plan-formula") continue;
      fcDatasources.push(datasource);
    }
    return fcDatasources;
  },
);
