import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import templatesAPI from "@shared/apis/templates";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getTemplatesAdapter} from "@shared/state/entity-adapters";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "@shared/state/store";

export const fetchTemplates = createAsyncThunk("templates/fetchAllWithRows", templatesAPI.fetchAllWithRows);
export const upsertTemplateApiCall = createAsyncThunk("templates/upsert", templatesAPI.upsert);

export const getSlice = () => {
  const templatesAdapter = getTemplatesAdapter();
  return createSlice({
    name: "templates",
    initialState: templatesAdapter.getInitialState(),
    reducers: {
      upsertTemplate: templatesAdapter.upsertOne,
      upsertTemplates: templatesAdapter.upsertMany,
      upsertTemplatesLocal: templatesAdapter.upsertMany,
      removeTemplatesLocal: templatesAdapter.removeMany,
      changeLastMonthOfActuals(
        state,
        action: PayloadAction<{
          templateIds: string[];
          newLMOA: string;
          integrationIdToSyncAfter?: string | null;
        }>,
      ) {
        // For listener only
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchTemplates.fulfilled, (state, action) => {
        templatesAdapter.setAll(state, action.payload.templates);
      });
    },
  });
};

const _slice = getSlice();

export const {upsertTemplatesLocal, removeTemplatesLocal} = _slice.actions;
export const {upsertTemplate, upsertTemplates, changeLastMonthOfActuals} = createSyncedActionCreators(_slice.actions);

export type TemplateActions = typeof _slice.actions;

export const selectTemplates = (state: RootState) => state.templates;
export const selectTemplateOptions = (id: string) => (state: RootState) => state.templates.entities[id]?.options;

export const {
  selectById: selectTemplateById,
  selectIds: selectTemplateIds,
  selectEntities: selectTemplateEntities,
  selectAll: selectAllTemplates,
  selectTotal: selectTotalTemplates,
} = getTemplatesAdapter().getSelectors((state: RootState) => state.templates);
