import colors from "@app/styles/colors";
import {BasePopoverLayer} from "@components/Floating/shared";
import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import clsx from "clsx";
import React, {Children, cloneElement, isValidElement, useRef, useState} from "react";

import useStyles from "./styles.jss";

import type {Placement, UseHoverProps} from "@floating-ui/react";
import type {CSSProperties, FunctionComponent, ReactComponentElement, ReactElement} from "react";

interface _FloatingElementProps {
  children: ReactComponentElement<any>;
  content: ReactElement | FunctionComponent<any>;
  onFloatingElementSelect?: () => void; // Optional callback for when an item in the floating element is selected
  placement?: Placement;
  triggerClasses?: string;
  matchTriggerWidth?: boolean;
  withArrow?: boolean;
  isOpenClassName?: string;
  portal?: boolean;
  offset?: number;
  isOpen?: boolean;
  openOnClick?: boolean;
  openOnHover?: boolean;
  delay?: UseHoverProps["delay"];
  onOpenChange?: (isOpen: boolean) => void;
  closeOnClick?: boolean;
}

const DEFAULT_ARROW_WIDTH = 17;
const DEFAULT_ARROW_HEIGHT = 8;
const STATIC_OFFSET = 15;

const _FloatingElement: React.FC<_FloatingElementProps> = ({
  children,
  content: Content,
  onFloatingElementSelect,
  placement = "bottom" as Placement,
  triggerClasses,
  matchTriggerWidth,
  withArrow,
  isOpenClassName,
  portal,
  offset: popoverOffset = DEFAULT_ARROW_HEIGHT,
  isOpen = false,
  openOnClick = false,
  openOnHover = false,
  delay,
  onOpenChange,
  closeOnClick,
}) => {
  const [visible, setVisible] = useState(false);
  const arrowRef = useRef(null);
  const classes = useStyles();

  const handleSetVisibility = (newVisibility: boolean) => {
    setVisible(newVisibility);
    onOpenChange?.(newVisibility);
  };

  // if (visible) debugger;

  const floatingProps = useFloating({
    open: openOnClick || openOnHover ? visible : isOpen,
    onOpenChange: handleSetVisibility,
    middleware: [
      offset(popoverOffset),
      flip({padding: 5}),
      shift({padding: 5}),
      size({
        apply({rects, elements, availableHeight}) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(200, availableHeight)}px`,
            width: matchTriggerWidth ? `${rects.reference.width}px` : undefined,
          });
        },
      }),
      arrow({element: arrowRef}), // Use the arrow middleware even if withArrow is false to get the proper transform origin
    ],
    placement,
    whileElementsMounted: autoUpdate,
  });
  const {x, y, strategy, refs, context, middlewareData} = floatingProps;

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;

  const transformX = arrowX + DEFAULT_ARROW_WIDTH / 2;
  const transformY = arrowY + DEFAULT_ARROW_HEIGHT;

  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 100,
    initial: {
      transform: "scale(0.95)",
      opacity: 0,
    },
    common: ({side}) => {
      return {
        transformOrigin: {
          top: `${transformX + popoverOffset}px calc(100% + ${DEFAULT_ARROW_HEIGHT}px)`,
          bottom: `${transformX + popoverOffset}px ${-DEFAULT_ARROW_HEIGHT}px`,
          left: `calc(100% + ${DEFAULT_ARROW_HEIGHT}px) ${transformY + popoverOffset}px`,
          right: `${-DEFAULT_ARROW_HEIGHT}px ${transformY + popoverOffset}px`,
        }[side],
      };
    },
  });

  const hover = useHover(context, {delay, enabled: openOnHover});
  const dismiss = useDismiss(context, {enabled: !openOnHover});

  const {getReferenceProps, getFloatingProps} = useInteractions([hover, dismiss]);

  // Handle selection from the floating element
  const handleSelect = () => {
    handleSetVisibility(false);
    onFloatingElementSelect?.();
  };

  const handleClick = () => {
    if (closeOnClick) {
      handleSetVisibility(false);
    }
  };

  // If children is specified, ensure there's only one child
  if (children && Children.count(children) !== 1) {
    throw new Error("WithFloatingElement expects exactly one child");
  }

  let trigger: React.FunctionComponentElement<any> | null = null;
  if (children) {
    // Clone the child (trigger element) to add onClick handler and ref
    const childrenProps = children.props;
    const triggerStyles = {...childrenProps.style, userSelect: "none"};
    const childrenClasses = clsx(childrenProps.className, triggerClasses, {
      [classes.isOpen]: visible,
      [isOpenClassName ?? ""]: visible,
    });

    if (!isValidElement(children)) {
      trigger = children;
    } else if (typeof children.type === "string") {
      const childOnClick = childrenProps.onClick;
      trigger = cloneElement(children, {
        ref: refs.setReference,
        ...childrenProps,
        onClick: !openOnHover
          ? (evt: React.MouseEvent) => {
              if (!visible) evt.stopPropagation();
              handleSetVisibility(!visible);
              childOnClick?.(evt);
            }
          : childOnClick,
        style: triggerStyles,
        className: childrenClasses,
        ...getReferenceProps(),
      });
    } else {
      trigger = (
        <div
          ref={refs.setReference}
          onClick={
            !openOnHover
              ? (evt) => {
                  if (!visible) evt.stopPropagation();
                  handleSetVisibility(!visible);
                }
              : undefined
          }
          style={{userSelect: "none"}}
          className={childrenClasses}
          {...getReferenceProps()}
        >
          {children}
        </div>
      );
    }
  }

  let floatingEl: JSX.Element | null = null;

  if (isMounted) {
    // Clone floating element to pass handleSelect
    const floatingStyles: CSSProperties = {
      position: strategy,
      right: matchTriggerWidth ? 0 : undefined,
      zIndex: 1000,
      top: "0",
      left: "0",
      transform: `translate3d(${roundByDPR(x)}px, ${roundByDPR(y)}px, 0)`,
    };

    const contentElement = typeof Content === "function" ? <Content /> : Content;

    floatingEl = (
      <FloatingFocusManager context={context} modal={false}>
        <aside ref={refs.setFloating} style={floatingStyles} className={classes.floatingWrapper} onClick={handleClick}>
          <BasePopoverLayer
            style={transitionStyles}
            {...getFloatingProps()}
            className={!!portal ? classes.baseStyles : undefined}
          >
            {cloneElement(contentElement, {
              onSelect: handleSelect,
              style: {...contentElement.style, ...(matchTriggerWidth ? {right: 0} : {})},
            })}
            {
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={DEFAULT_ARROW_WIDTH}
                height={DEFAULT_ARROW_HEIGHT}
                fill={colors.ui.white}
                staticOffset={STATIC_OFFSET}
                style={{opacity: withArrow ? 1 : 0}}
              />
            }
          </BasePopoverLayer>
        </aside>
      </FloatingFocusManager>
    );
  }

  return (
    <>
      {trigger}
      {floatingEl && <>{portal ? <FloatingPortal preserveTabOrder>{floatingEl}</FloatingPortal> : floatingEl}</>}
    </>
  );
};

export type FloatingProps = Omit<_FloatingElementProps, "isOpen" | "openOnClick" | "openOnHover" | "delay"> & {
  isOpen: boolean;
};
export const Floating: React.FC<FloatingProps> = (props) => <_FloatingElement {...props} openOnClick={false} />;

export const WithFloatingElementOnClick: React.FC<
  Omit<_FloatingElementProps, "isOpen" | "openOnClick" | "openOnHover" | "delay">
> = (props) => <_FloatingElement {...props} openOnClick />;

export type WithFloatingElementOnHoverProps = Omit<_FloatingElementProps, "isOpen" | "openOnClick" | "openOnHover">;
export const WithFloatingElementOnHover: React.FC<WithFloatingElementOnHoverProps> = (props) => {
  return <_FloatingElement {...props} openOnHover />;
};

function roundByDPR(value: number) {
  const dpr = window.devicePixelRatio || 1;
  return Math.round(value * dpr) / dpr;
}
