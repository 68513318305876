import colors from "@app/styles/colors";
import {globalStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export const sharedValues = {
  sidebarPaddingX: 30,
  sidebarPaddingY: 16,
  width: 487,
};

export default createUseStyles({
  sidebarMain: {
    maxHeight: "100vh",
    height: "100vh",
    background: "white",
    position: "fixed",
    right: -globalStyles.mainViewPaddingX,
    top: globalStyles.topNavHeight,
    bottom: 0,
    width: sharedValues.width,
    padding: [
      sharedValues.sidebarPaddingY,
      sharedValues.sidebarPaddingX + globalStyles.mainViewPaddingX,
      sharedValues.sidebarPaddingY + 15,
      sharedValues.sidebarPaddingX,
    ],
    boxShadow: "0 40px 92px rgba(0, 0, 0, 0.13)",
    zIndex: 10,
  },
  expanded: {
    minWidth: 487,
    width: "calc(100% - 1050px)",
    "&$fullWidth": {
      width: "100%",

      "&$leftSidebarOpen": {
        width: `calc(100% - ${globalStyles.leftNavWidth - globalStyles.mainViewPaddingX}px)`,
      },
    },
  },
  fullWidth: {},
  leftSidebarOpen: {},
  sidebarBodyMain: {
    height: "100%",
    margin: [0, -sharedValues.sidebarPaddingX],
    padding: [0, sharedValues.sidebarPaddingX],
    overflow: "auto",
    paddingBottom: 30,
  },
  scroll: {
    overflowY: "scroll",
  },
  sidebarHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: 17,
    marginBottom: 22,

    "& h2": {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 30,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  sidebarHeaderActions: {
    display: "flex",
    marginRight: -11,
    ...getSvgColorStyles(colors.ui.lightBlue01),

    "& > div": {
      marginLeft: 10,
    },
  },
  crossIconContainer: {
    marginRight: 5,
    transform: "rotate(180deg)",
  },
  sidebarBody: {
    height: "calc(100% - 100px)",
    maxHeight: "calc(100% - 100px)",
    //   overflow: "auto",
  },
  sidebarSectionMain: {
    borderTop: [2, "solid", colors.ui.lightBlue07],
    padding: [0, sharedValues.sidebarPaddingX],
    margin: [0, -sharedValues.sidebarPaddingX],

    "&:first-child": {
      borderTop: 0,
    },
  },
  content: {
    paddingBottom: 23,
    // display: "flex",
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 24,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  titleText: {
    whiteSpace: "nowrap",
    paddingRight: 8,
  },
  titleTags: {
    display: "inline-flex",
    marginLeft: 15,
    flex: 1,

    "& > *": {
      marginRight: 6,
    },
  },
  flexFiller: {
    flex: 1,
  },
  summary: {
    color: colors.ui.lightBlue01,
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  rightButton: {
    marginRight: -5,
  },
  searchWrapper: {
    width: "100%",
    maxWidth: 400,
    paddingRight: 25,
    userSelect: "auto",
    pointerEvents: "auto",
  },
  iconWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    cursor: "pointer",

    "&:not(:last-child)": {
      marginRight: 10,
    },

    "& svg": {
      transition: "transform 100ms ease-out",

      "& path": {
        fill: colors.ui.lightBlue01,
      },
    },
  },

  collapsed: {
    "& $iconWrapper svg": {
      transform: "rotate(-90deg)",
    },
  },

  sectionCol: {
    "$content &": {
      boxSizing: "border-box",
      flex: 1,
    },

    "&$small": {
      flex: 0,
    },
  },
  small: {},
  sectionColInnerContainer: {
    height: "100%",

    "$sectionCol:not(:last-child) &": {
      marginRight: 20,
    },
  },
  fullWidthContent: {
    display: "flex",
    width: "100%",
    flex: 1,
  },
});
