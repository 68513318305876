import {useAppSelector} from "@app/hooks";
import Button from "@components/Button/V2";
import Checkbox from "@components/Checkbox";
import {selectors as globalSelectors} from "@state/global/slice";
import clsx from "clsx";
import {useState} from "react";

import {selectors} from "../state/selectors";
import DebugTabs from "./DebugTabs";
import useStyles from "./MainDebugPanel.jss";
export default function MainDebugPanel() {
  const styles = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [resolveIds, setResolveIds] = useState(true);

  const debugModeEnabled = useAppSelector(globalSelectors.debugModeEnabled);
  const templateId = useAppSelector(selectors.templateId);

  if (!debugModeEnabled) return null;

  return (
    <div className={clsx(styles.mainPanel, expanded && styles.expanded)}>
      {!templateId ? (
        <h1>No template selected</h1>
      ) : (
        <h1 style={{display: "flex"}}>
          <div style={{flex: 1}}>
            <span>Template Debug Panel</span>
          </div>
          <div className={styles.controls}>
            {expanded && (
              <Checkbox
                checked={resolveIds}
                onClick={() => setResolveIds(!resolveIds)}
                text="Resolve ids to human readable names"
              />
            )}
            <Button
              onClick={() => setExpanded(!expanded)}
              text={expanded ? "Collapse" : "Expand"}
              iconLeft={expanded ? "minusv4" : "plusv4"}
            />
          </div>
        </h1>
      )}
      <div className={clsx(styles.tabsWrapper, expanded && styles.expanded)}>
        <DebugTabs resolveIds={resolveIds} />
      </div>
    </div>
  );
}
