import {SidebarBody} from "@components/RightSidebar";
import Tabs from "@components/Tabs";
import {useState} from "react";

import Collaboration from "./Collaboration";
import CompensationAndTaxes from "./CompensationAndTaxes";
import Details from "./Details";

import type {Tab} from "@components/Tabs";

const TabsList: Tab[] = [
  {name: "details", value: "Details"},
  {name: "compensation", value: "Compensation"},
  // {name: "accounting", value: "Accounting"},
  //{name: "collaboration", value: "Collaboration", disabled: true},
];

export default function SidebarTabs() {
  const [selectedTab, setSelectedTab] = useState(TabsList[0]);

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
  };

  let tabComponent: JSX.Element | null = null;
  switch (selectedTab.name) {
    case "details": {
      tabComponent = <Details />;
      break;
    }
    case "compensation": {
      tabComponent = <CompensationAndTaxes />;
      break;
    }
    // case "accounting": {
    //   tabComponent = <Accounting />;
    //   break;
    // }
    case "collaboration": {
      tabComponent = <Collaboration />;
      break;
    }
  }

  return (
    <>
      <Tabs tabs={TabsList} selectedTab={selectedTab} onChange={handleTabChange} />
      <SidebarBody>{tabComponent}</SidebarBody>
    </>
  );
}
