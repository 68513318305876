import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import FormElement from "@components/FormElement";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import TextInput from "@components/TextInput";
import DatasourceSelector from "@features/shared-views/DatasourceSelector";
import DepartmentSelector from "@features/shared-views/DepartmentSelector";
import {useDebounce, useSelectRowAutoForecastDs, useSelectedRowDatasources} from "@features/templates/state/hooks";
import {selectSelectedRow, selectSelectedTemplate, selectors} from "@features/templates/state/selectors";
import {getDatasourceDiff, getInsertAutoRangeDatasourceDiff} from "@shared/lib/datasource-utilities";
import {log} from "@shared/lib/debug-provider";
import {applyDatasourceChanges, insertAutoRange} from "@state/datasources/slice";
import {selectScenarioId, selectVersionLocked} from "@state/global/slice";
import clsx from "clsx";
import debounce from "lodash.debounce";
import {useEffect, useState} from "react";

import ForecastDestination from "./ForecastDestination";
import ForecastType from "./ForecastGranularity";
import {useForecastType} from "./hooks";
import {
  formulaTypes,
  getForecastTypes,
  getFormulaSidebarRowInfos,
  isPreBuiltFormulaType,
  preBuiltFormulas,
} from "./shared";
import useStyles from "./styles.jss";

import type {DropdownItem, DropdownSelectHandler} from "@components/Dropdown/DropdownV2";
import type {DatasourceSelectorProps} from "@features/shared-views/DatasourceSelector";
import type {DepartmentSelectorProps} from "@features/shared-views/DepartmentSelector";
import {type AutoUiOptions, type Formula, type PreBuiltFormulaTypes} from "@shared/types/datasources";

import {optimisticUpdate} from "@/lib/optimistic-update";
import Button from "@components/Button/V2";
import Dropdown from "@components/Dropdown/DropdownV2";
import {addRowToForcedVisible} from "@features/templates/state/slice";

export interface FormulaBuilderTabProps {}

const preBuiltFormulasWithNullOption = [
  {key: null, value: "Select a formula...", iconLeft: "formula"},
  ...preBuiltFormulas,
] as const satisfies (DropdownItem & {
  key: null | PreBuiltFormulaTypes;
})[];

const formulaTypesWithNullOption = [
  {key: null, value: "Select a formula...", iconLeft: "formula"},
  ...formulaTypes,
] as const satisfies (DropdownItem & {
  key: null | "average" | "median" | "pct-of-target";
})[];

export default function FormulaBuilderTab({}: FormulaBuilderTabProps) {
  const styles = useStyles();
  const classes = clsx(styles.formulaBuilderTabMain);

  const scenarioId = useAppSelector(selectScenarioId);
  const dispatch = useAppDispatch();
  const [pendingNewType, setPendingNewType] = useState<string | null>(null);

  const {departmentId, vendor} = useAppSelector(selectors.activeCell);
  const row = useAppSelector(selectSelectedRow);
  const forecastType = useForecastType(row);
  const template = useAppSelector(selectSelectedTemplate);
  const datasources = useSelectedRowDatasources();
  const hasFormulas = datasources.some((ds) => ds.type === "formula");
  const autoForecastDatasourceFormula = useSelectRowAutoForecastDs();

  const departmentsState = useAppSelector((state) => state.departments);

  useEffect(() => {
    setPendingNewType(null);
  }, [row?.id]);

  const currentAutoForecastDatasourceTimePeriod =
    autoForecastDatasourceFormula?.options?.ui?.type === "auto"
      ? autoForecastDatasourceFormula?.options.ui?.timePeriod ?? null
      : null;

  const currentType = autoForecastDatasourceFormula?.options?.ui?.type ?? "custom";

  // To have good performance when clicking on + and - in auto forecast time period, we need to store the value in local state
  const [localTimePeriod, setLocalTimePeriod] = useState(() => {
    if (autoForecastDatasourceFormula?.options?.ui?.type !== "auto") return null;
    return getLookbackValueFromTimePeriod(autoForecastDatasourceFormula?.options.ui?.timePeriod || "-3a:-1a");
  });

  // Update local state when time period changes to keep the value in sync
  useEffect(() => {
    setLocalTimePeriod(getLookbackValueFromTimePeriod(currentAutoForecastDatasourceTimePeriod || "-3a:-1a"));
  }, [currentAutoForecastDatasourceTimePeriod]);

  // Debounce function to update the auto forecast time period
  const debouncedInsertAutoRange = useDebounce((newValue: string) => {
    if (!row || !scenarioId || !template) return;
    const newUiOptions = {
      ...autoForecastDatasourceFormula?.options.ui!,
      timePeriod: getTimePeriodFromLookbackValue(newValue),
    };
    dispatch(
      insertAutoRange({
        row,
        departmentId,
        vendor,
        scenarioId,
        templateOptions: template.options,
        uiOptions: newUiOptions,
      }),
    );
  }, 500);

  const isVersionLocked = useAppSelector(selectVersionLocked);

  if (!template || !scenarioId || !row || (row.type !== "generic" && row.type !== "account")) return null;

  const handleTypeSelect = async ({key}: {key: string | null}) => {
    if (key !== "custom" && key !== "auto" && key !== "pre-built") return;

    if (currentType === key) {
      return; // No change
    }

    if (!!autoForecastDatasourceFormula && key === "custom") {
      const state = store.getState();
      const datasourceDiff = getDatasourceDiff(
        datasources,
        [...datasources.filter((ds) => ds.id !== autoForecastDatasourceFormula.id)],
        state,
      );
      await dispatch(applyDatasourceChanges({datasourceDiff, reason: `FormulaBuilderTab handleTypeSelect -> ${key}`}));
    } else if (key === "auto" || key === "pre-built") {
      setPendingNewType(key);
      // dispatch(
      //   insertAutoRange({
      //     row,
      //     scenarioId,
      //     departmentId,
      //     vendor,
      //     templateOptions: template.options,
      //     uiOptions: {type: "auto", formulaType: "average", timePeriod: "-3a:-1a"},
      //   }),
      // );
      // } else if (key === "pre-built") {
      //   dispatch(
      //     insertAutoRange({
      //       row,
      //       scenarioId,
      //       departmentId,
      //       vendor,
      //       templateOptions: template.options,
      //       uiOptions: {type: "pre-built", formulaType: null},
      //     }),
      //   );
    }
  };

  const handleTargetChange = (
    changes: Partial<
      Pick<Formula["ui"] & {formulaType: "pct-of-target"}, "target" | "targetDepartment" | "targetVendor">
    >,
  ) => {
    const currentUiOptions: Formula["ui"] | null = autoForecastDatasourceFormula?.options.ui ?? null;

    if (currentUiOptions?.formulaType !== "pct-of-target") return;

    const newUiOptions = {
      ...currentUiOptions,
      ...changes,
    };

    dispatch(
      insertAutoRange({
        row,
        departmentId,
        vendor,
        scenarioId,
        templateOptions: template.options,
        uiOptions: newUiOptions,
      }),
    );
  };

  const handleFormulaTypeSelect = ({key}: {key: string}) => {
    const commonNewUiOptions = {
      ...autoForecastDatasourceFormula?.options.ui,
      timePeriod: currentAutoForecastDatasourceTimePeriod ?? "-3a:-1a",
      type: "auto",
      formulaType: key,
    } as const;

    let newUiOptions: AutoUiOptions;
    if (key === "pct-of-target") {
      newUiOptions = {
        ...(commonNewUiOptions as Exclude<AutoUiOptions, {formulaType: "pct-of-target"}>),
        formulaType: key,
        target: "revenue",
      };
    } else if (key === "average" || key === "median") {
      newUiOptions = {
        ...(commonNewUiOptions as Exclude<AutoUiOptions, {formulaType: "pct-of-target"}>),
      };
    } else {
      return;
    }

    dispatch(
      insertAutoRange({
        row,
        departmentId,
        vendor,
        scenarioId,
        templateOptions: template.options,
        uiOptions: newUiOptions,
      }),
    );

    setPendingNewType(null);
  };

  const handlePrebuiltFormulaSelect = ({key}: {key: string}) => {
    if (!isPreBuiltFormulaType(key)) return;

    dispatch(
      insertAutoRange({
        row,
        departmentId,
        vendor,
        scenarioId,
        templateOptions: template.options,
        uiOptions: {type: "pre-built", formulaType: key},
      }),
    );

    setPendingNewType(null);
  };

  const handleClearAllFormulasClick = () => {
    if (!row || !scenarioId || !template) return;

    const state = store.getState();
    const datasourceDiff = getDatasourceDiff(
      datasources,
      datasources.filter((ds) => ds.type !== "formula"),
      state,
    );
    dispatch(addRowToForcedVisible(row.id));
    dispatch(applyDatasourceChanges({datasourceDiff, reason: "FormulaBuilderTab handleClearAllFormulasClick"}));
  };

  const handleTargetDepartmentChange: DepartmentSelectorProps["onChange"] = (selectedDepartmentDDItem) => {
    const departmentName = departmentsState.entities[selectedDepartmentDDItem?.key ?? ""]?.name ?? null;
    handleTargetChange({targetDepartment: departmentName});
  };

  const handleTargetRowChange: DatasourceSelectorProps["onDatasourceSelect"] = (selectedRow) => {
    handleTargetChange({target: selectedRow?.name});
  };

  const handleTargetVendorChange: DropdownSelectHandler = (dropdownItem) => {
    handleTargetChange({targetVendor: dropdownItem.key});
  };

  const handleTimePeriodChange: React.ChangeEventHandler<HTMLInputElement> = async (evt) => {
    log("FormulaBuilder", "handleTimePeriodChange handler called");
    // Update local state immediately for better UI experience
    setLocalTimePeriod(evt.target.value);

    // Optimistic update
    debouncedOptimisticUpdate({
      row,
      scenarioId,
      dispatch,
      autoForecastDatasourceFormula,
      value: evt.target.value,
      template,
      departmentId,
      vendor,
    });

    // Use debounced function to update global state
    debouncedInsertAutoRange(evt.target.value);
  };

  const {forecastTypeSummary, formulaTypeSummary, rowCanBeForecasted, forecastGranularityHidden} =
    getFormulaSidebarRowInfos({
      row,
      autoForecastDatasourceFormula,
      departmentId,
      vendor,
    });

  const typeDropdownSelected = pendingNewType ?? currentType;

  return (
    <div className={classes}>
      {(rowCanBeForecasted || !forecastGranularityHidden) && (
        <Section title="Forecast Settings" summary={`${forecastTypeSummary} - ${formulaTypeSummary}`}>
          <SectionCol>
            {row.type === "account" && !forecastGranularityHidden && (
              <ForecastType template={template} row={row} forecastType={forecastType} />
            )}
            {rowCanBeForecasted ? (
              <>
                <FormElement label="Formula Type" tooltip="Templates::Sidebar::FormulaBuilder::FormulaType">
                  <Dropdown
                    disabled={isVersionLocked || (row.type === "account" && row.options.type === "Bank")}
                    items={getForecastTypes(row.type)}
                    onSelect={handleTypeSelect}
                    selectedKey={typeDropdownSelected}
                  />
                </FormElement>
                {!!autoForecastDatasourceFormula || pendingNewType ? (
                  <>
                    <FormElement label="Formula" tooltip="Templates::Sidebar::FormulaBuilder::Formula">
                      <Dropdown
                        items={
                          typeDropdownSelected === "pre-built"
                            ? !!pendingNewType
                              ? preBuiltFormulasWithNullOption
                              : preBuiltFormulas
                            : !!pendingNewType
                            ? formulaTypesWithNullOption
                            : formulaTypes
                        }
                        onSelect={
                          (typeDropdownSelected === "pre-built"
                            ? handlePrebuiltFormulaSelect
                            : handleFormulaTypeSelect) as DropdownSelectHandler
                        }
                        selectedKey={
                          pendingNewType
                            ? null
                            : typeDropdownSelected === "pre-built" || typeDropdownSelected === "auto"
                            ? autoForecastDatasourceFormula?.options.ui?.formulaType
                            : "average"
                        }
                        disabled={isVersionLocked}
                      />
                    </FormElement>
                    {!pendingNewType && autoForecastDatasourceFormula?.options.ui?.formulaType === "pct-of-target" ? (
                      <>
                        <FormElement label="Row Reference" tooltip="Templates::Sidebar::FormulaBuilder::RowReference">
                          <DatasourceSelector
                            position={["bottom", "top"]}
                            selectedRowName={autoForecastDatasourceFormula.options.ui?.target || "revenue"}
                            onDatasourceSelect={handleTargetRowChange}
                            disabled={isVersionLocked}
                          />
                        </FormElement>
                        <FormElement
                          label="Department Reference"
                          tooltip="Templates::Sidebar::FormulaBuilder::DepartmentReference"
                        >
                          <DepartmentSelector
                            onChange={handleTargetDepartmentChange}
                            selectedKey={
                              departmentsState.idsByName[
                                autoForecastDatasourceFormula.options.ui?.targetDepartment ?? ""
                              ] ?? null
                            }
                            nullLabel="All Departments"
                            disabled={isVersionLocked}
                          />
                        </FormElement>
                      </>
                    ) : null}
                    {!pendingNewType && currentType === "auto" && (
                      <FormElement label="Average Of" tooltip="Templates::Sidebar::FormulaBuilder::AverageOf">
                        <TextInput
                          plusMinusButtons
                          value={localTimePeriod}
                          formatter={getDisplayValueFromLookbackValue}
                          onChange={handleTimePeriodChange}
                          disabled={isVersionLocked}
                        />
                      </FormElement>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
            <Button
              color="red"
              text="Clear All Formulas"
              onClick={handleClearAllFormulasClick}
              disabled={!hasFormulas || isVersionLocked}
              className={styles.clearAllFormulasButton}
              iconLeft="trash"
            />
          </SectionCol>
        </Section>
      )}
      <ForecastDestination disabled={isVersionLocked} row={row} />
    </div>
  );
}

function getLookbackValueFromTimePeriod(timePeriod: string) {
  return timePeriod.split("a:")[0];
}

function getTimePeriodFromLookbackValue(value: string) {
  return value === "1" ? "1a" : `${value}a:-1a`;
}

function getDisplayValueFromLookbackValue(value: string) {
  return value === "-1" ? "1 month" : `${value.replace("-", "")} months`;
}

const debouncedOptimisticUpdate = debounce(
  async ({row, scenarioId, dispatch, autoForecastDatasourceFormula, value, template, departmentId, vendor}: any) => {
    const state = store.getState();
    const datasourceDiff = getInsertAutoRangeDatasourceDiff(state, {
      row,
      scenarioId,
      uiOptions: {
        ...autoForecastDatasourceFormula?.options.ui!,
        timePeriod: getTimePeriodFromLookbackValue(value),
      },
      templateOptions: template.options,
      departmentId,
      vendor,
    });

    if (datasourceDiff) {
      await optimisticUpdate({
        scenarioId,
        dispatch,
        datasourceDiff,
      });
    }
  },
  75,
);
