import {createUseStyles} from "react-jss";

export const sharedValues = {
  topBarPaddingTop: 7,
};

export default createUseStyles({
  topBarMain: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 25,
    paddingTop: sharedValues.topBarPaddingTop,
    alignItems: "center",
  },
  hamburgerVisible: {
    paddingLeft: 33,
  },
});
