import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import RoundButton from "@components/RoundButton";
import SVGIcon from "@components/SVGIcon";
import {FormulaTextEditor} from "@features/shared-views/FormulaTextEditor";
import clsx from "clsx";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import useStyles from "./FormulaBar.jss";
import {selectIsFormulaBarFocused} from "./state/selectors";
import {enableEditing} from "./state/thunks";

export const FORMULA_BAR_ID = "formula-bar";

export default function FormulaBar() {
  const styles = useStyles();
  const focused = useAppSelector(selectIsFormulaBarFocused);
  const dispatch = useAppThunkDispatch();

  const mainClasses = clsx(styles.formulaBarMain, {[styles.focused]: focused});

  // will drill down in its own children until it finds an input element, then focus it if it's not already focused
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!focused) {
      logUserEvent("focused-formula-bar");
      dispatch(enableEditing("formulaBar"));
    }
  };

  return (
    <div className={mainClasses} spellCheck={false}>
      <div className={styles.formulaBarContainer} onClick={handleClick}>
        <div className={styles.iconContainer}>
          <SVGIcon name="formula" />
        </div>
        <FormulaTextEditor component="formulaBar" />
      </div>
      <div className={styles.dropdownArrowContainer}>
        <RoundButton icon="caret" minimal onClick={() => null} />
      </div>
    </div>
  );
}
