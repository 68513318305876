import {DEBUG} from "@shared/lib/debug-provider";
import {isNode, isWebWorker} from "browser-or-node";

import {syncAlertDeletesWithAPI, syncAlertUpsertsWithAPI, syncDsAlertUpsertsWithAPI} from "./alerts/listeners";
import {clearAlertsForDatasource, deleteAlerts, upsertAlerts, upsertAlertsForDs} from "./alerts/slice";
import {
  applyChangesFromBackendListener,
  removeNonIntegrationKeysFromInitialCache,
  syncTxItemDeletesWithAPI,
  triggerAlgoFromListenerHandler,
} from "./cb-tx/listeners";
import {applyChangesFromBackend, removeCbTx, triggerAlgoFromListener, api as txItemsApi} from "./cb-tx/slice";
import {
  deleteTxForDeletedDatasources,
  insertAutoRangeListener,
  syncDatasourceChangesWithAPI,
  triggerComputeAfterDatasourceChanges,
} from "./datasources/listeners";
import {applyDatasourceChanges, insertAutoRange, removeDatasourcesLocal} from "./datasources/slice";
import {syncDepartmentDeletesWithAPI, syncDepartmentUpsertsWithAPI} from "./departments/listeners";
import {deleteDepartments, upsertDepartments} from "./departments/slice";
import {
  propagateEmployeeMappingAndTagChanges,
  removeEmployeesListener,
  syncUpdatedEmployeeWithAPI,
  triggerDatasourcesUpdate,
} from "./employees/listeners";
import {employeeScenarioPropertiesUpdated, employeeUpdated, removeEmployees} from "./employees/slice";
import {syncFcUpsertsWithAPI} from "./financial-components/api-listeners";
import {upsertFc} from "./financial-components/slice";
import {syncIntegrationDisconnectWithAPI, syncIntegrationUpsertsWithAPI} from "./integrations/listeners";
import {
  disconnectIntegration,
  fetchQboStatementRows,
  updateIntegrationQuery,
  upsertIntegration,
} from "./integrations/slice";
import {
  refetchMonthlyCacheAfterUpsertIfNecessary,
  syncMultipleItemLayoutsToAPI,
  syncReportItemDeletesToAPI,
  syncReportItemUpsertsToAPI,
} from "./report-items/listeners";
import {
  multipleLayoutsUpdated,
  reportItemAdded,
  reportItemDeleted,
  reportItemUpdated,
  api as reportItemsApi,
  upsertReportItem,
} from "./report-items/slice";
import {syncReportDeletesToAPI, syncReportUpsertsToAPI} from "./reports/listeners";
import {deleteReport, upsertReport} from "./reports/slice";
import {syncSanityCheckDeletesWithAPI, syncSanityCheckUpsertsWithAPI} from "./sanity-checks/listeners";
import {deleteSanityChecks, upsertSanityChecks} from "./sanity-checks/slice";
import {syncTeamDeletesWithAPI, syncTeamUpsertsWithAPI} from "./teams/listeners";
import {teamDefaultMappingsUpdated, teamDefaultValueUpserted, teamDeleted, teamUpdated} from "./teams/slice";
import {
  syncRowDeletesWithAPI,
  syncRowUpsertsWithAPI,
  updateRowIntegrationListener,
  updateTemplateOrdering,
} from "./template-rows/listeners";
import {addRows, removeRow, removeRows, updateRowIntegration, upsertRow, upsertRows} from "./template-rows/slice";
import {changeLastMonthOfActualsListener, syncTemplateUpsertsWithAPI} from "./templates/listeners";
import {changeLastMonthOfActuals, upsertTemplate, upsertTemplates} from "./templates/slice";
import {generateMonthlyCacheListener, runSanityChecksOnCacheUpdate} from "./transaction-items/listeners";
import {
  applyDiffToMonthlyCache,
  clearMonthlyCacheForDateKeys,
  generateMonthlyCache,
  monthlyCacheUpdated,
} from "./transaction-items/slice";

import type {
  ActionCreatorWithPayload,
  ActionCreatorWithPreparedPayload,
  AnyAction,
  ListenerEffect,
  ListenerEffectAPI,
  ThunkAction,
} from "@reduxjs/toolkit";
import type {ThunkExtraArg} from "@shared/lib/fetch";
import type {AppActionMeta, AppActionWithMeta, AppThunkDispatch, RootState} from "@state/store";
import type {AppStartListening} from "./listener-middleware";

// const listenersList = [
//   // Employees
//   // {
//   //   actionCreators: ["employeeUpdated"],
//   //   effect: [syncUpdatedEmployeeWithAPI],
//   //   thread: "worker",
//   // },
//   // {actionCreators: ["employeeUpdated"], effect: [propagateEmployeeMappingAndTagChanges], thread: "worker"},
//   // {
//   //   actionCreators: ["removeEmployees"],
//   //   effect: [removeEmployeesListener],
//   //   thread: "worker",
//   // },
//   // {
//   //   actionCreators: ["employeeScenarioPropertiesUpdated"],
//   //   effect: [syncUpdatedEmployeeWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // {actionCreators: ["employeeScenarioPropertiesUpdated"], effect: [triggerDatasourcesUpdate], thread: "worker"},
//   // Teams
//   // {actionCreators: ["teamDeleted"], effect: [syncTeamDeletesWithAPI], skipIfSyncedAction: true},
//   // {
//   //   actionCreators: ["teamUpdated", "teamDefaultValueUpserted", "teamDefaultMappingsUpdated"],
//   //   effect: [syncTeamUpsertsWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // Financial components
//   // {actionCreators: ["upsertFc"], effect: [syncFcUpsertsWithAPI], skipIfSyncedAction: true},
//   // Templates
//   // {
//   //   actionCreators: ["upsertTemplate", "upsertTemplates", "changeLastMonthOfActuals"],
//   //   effect: [syncTemplateUpsertsWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // Datasources
//   // {
//   //   actionCreators: ["applyDatasourceChanges"],
//   //   effect: [syncDatasourceChangesWithAPI],
//   //   skipIfSyncedAction: true,
//   //   thread: "worker",
//   // },
//   // {
//   //   actionCreators: ["applyDatasourceChanges"],
//   //   effect: [triggerComputeAfterDatasourceChanges],
//   //   thread: "worker",
//   // },
//   // {actionCreators: ["insertAutoRange"], effect: [insertAutoRangeListener], thread: "main"},
//   // Departments
//   // {
//   //   actionCreators: ["upsertDepartments"],
//   //   effect: [syncDepartmentUpsertsWithAPIAndWorker],
//   //   skipIfSyncedAction: true,
//   // },
//   // {
//   //   actionCreators: ["deleteDepartments"],
//   //   effect: [syncDepartmentDeletesWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // Integrations
//   // {
//   //   actionCreators: ["upsertIntegration", "updateIntegrationQuery"],
//   //   effect: [syncIntegrationUpsertsWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // {actionCreators: ["disconnectIntegration"], effect: [syncIntegrationDisconnectWithAPI], skipIfSyncedAction: true},
//   // Template rows
//   // {
//   //   actionCreators: ["addRows", "upsertRow", "upsertRows"],
//   //   effect: [syncRowUpsertsWithAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // {
//   //   actionCreators: ["removeRows", "removeRow", "removeRowsWithTx"],
//   //   effect: [syncRowDeletesWithAPI],
//   //   thread: "worker",
//   // },
//   // {actionCreators: ["addRows", "removeRowsWithTx", "removeRow"], effect: [updateTemplateOrdering], thread: "worker"},
//   // Transactions
//   // {
//   //   actionCreators: ["generateMonthlyCache"],
//   //   effect: [generateMonthlyCacheListener],
//   //   thread: "worker",
//   // },
//   // {
//   //   actionCreators: ["applyChangesFromBackend"],
//   //   effect: [applyChangesFromBackendListener],
//   //   thread: "worker",
//   // },
//   // {
//   //   actionCreators: ["txItemsApi.fetchAllCbTxTransactions.fulfilled"],
//   //   effect: [removeNonIntegrationKeysFromInitialCache],
//   //   thread: "worker",
//   // },
//   // {
//   //   actionCreators: ["deleteCbTxMatchingRowIds"],
//   //   effect: [syncTxItemDeletesWithAPI],
//   //   skipIfSyncedAction: true,
//   //   thread: "worker",
//   // },
//   // {actionCreators: ["triggerAlgoFromListener"], effect: [triggerAlgoFromListenerHandler], thread: "worker"},
//   // Reporting
//   // {
//   //   actionCreators: ["reportItemAdded", "reportItemUpdated"],
//   //   effect: [syncReportItemUpsertsToAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // {
//   //   actionCreators: ["multipleLayoutsUpdated"],
//   //   effect: [syncMultipleItemLayoutsToAPI],
//   //   skipIfSyncedAction: true,
//   // },
//   // {actionCreators: ["reportItemDeleted"], effect: [syncReportItemDeletesToAPI], skipIfSyncedAction: true},
// ] as const satisfies readonly ListOfEventsItem[];

// type ListOfEventsItem = {
//   actionCreators: readonly string[];
//   effect: readonly ActionCreatorEffect<any>[];
//   readonly thread?: "main" | "worker" | "both";
//   readonly skipIfSyncedAction?: boolean;
// };

// export const listOfEvents = {
//   ...{
//     employeeUpdated,
//     employeeScenarioPropertiesUpdated,
//     removeEmployees,
//   },
//   ...{teamUpdated, teamDeleted, teamDefaultValueUpserted, teamDefaultMappingsUpdated},
//   ...{upsertFc},
//   ...{
//     insertAutoRange,
//     applyDatasourceChanges,
//   },
//   ...{
//     addRows,
//     removeRow,
//     removeRows,
//     removeRowsWithTx,
//     upsertRow,
//     upsertRows,
//   },
//   ...{
//     upsertIntegration,
//     updateIntegrationQuery,
//     disconnectIntegration,
//   },
//   ...{
//     upsertDepartments,
//     deleteDepartments,
//   },
//   ...{upsertTemplate, upsertTemplates, changeLastMonthOfActuals},
//   ...{
//     monthlyCacheUpdated,
//     generateMonthlyCache,
//     clearMonthlyCacheForDateKeys,
//     upsertCbTx,
//     deleteTxItemsLocal: deleteCbTxLocal,
//     applyChangesFromBackend,
//     "txItemsApi.fetchAllCbTxTransactions.fulfilled": txItemsApi.fetchAllCbTxTransactions.fulfilled,
//     triggerAlgoFromListener,
//   },
//   ...{reportItemAdded, reportItemUpdated, reportItemDeleted, multipleLayoutsUpdated},
//   ...{deleteCbTxMatchingRowIds},
// } as const;

// export type ListOfEvents = typeof listOfEvents;

// export type ActionCreatorEffect<K extends keyof ListOfEvents> = ListenerEffect<
//   ReturnType<ListOfEvents[K]> & {
//     meta?: {
//       createdByWorkerSync?: boolean;
//       receivedFromWs?: boolean;
//       disableApiSync?: boolean;
//       ctx?: Context;
//       dispatchedFromListener?: boolean;
//     };
//   },
//   RootState,
//   AppThunkDispatch
// >;

// export default listenersList;

// export function registerAppListeners(startListening: AppStartListening) {
//   const registeredListeners: Record<string, string[]> = {};
//   let nbListeners: number = 0;
//   for (const item of listenersList) {
//     const listenerItem = item as typeof item & {skipIfSyncedAction?: boolean; thread?: "main" | "worker" | "both"};
//     for (const actionCreator of listenerItem.actionCreators) {
//       if (!registeredListeners[actionCreator]) registeredListeners[actionCreator] = [];
//       for (const effect of listenerItem.effect) {
//         const wrappedEffect = (...args: Parameters<ActionCreatorEffect<typeof actionCreator>>) => {
//           if (
//             (listenerItem.thread === "main" && isWebWorker) ||
//             (listenerItem.thread === "worker" && !isWebWorker && !isNode) ||
//             (listenerItem.skipIfSyncedAction &&
//               (args[0].meta?.receivedFromWs || args[0].meta?.disableApiSync || args[0].meta?.createdByWorkerSync))
//           )
//             return;
//           if (DEBUG)
//             // eslint-disable-next-line no-console
//             console.log(
//               `⚡ Fired listener effect "${effect.name}" following "${actionCreator}" event with following action:`,
//               args[0],
//             );
//           // console.log(`⚡ Fired listener effect "${effect.name}" following "${actionCreator}"`);

//           // Monkey patch dispatch to make sure any action dispatched from a listener carries forward whether or not it was received from a websocket
//           const originalDispatch = args[1].dispatch;
//           const newArgs = [...args];
//           newArgs[1] = {...args[1]};
//           newArgs[1].dispatch = (...params: Parameters<typeof originalDispatch>) => {
//             const action = params[0] as AnyAction | ThunkAction<any, RootState, ThunkExtraArg, AnyAction>;
//             // const action = params[0] as Parameters<ActionCreatorEffect<typeof actionCreator>>[0];
//             if (typeof action === "function") {
//               const originalThunkAction = action;
//               const wrappedAction = (...args: Parameters<typeof action>) => {
//                 const thunkExtra = args[2] as ThunkExtraArg;
//                 thunkExtra.dispatchedFromListener = true;
//                 // thunkExtra.
//                 return originalThunkAction(...args);
//               };

//               return originalDispatch(wrappedAction);
//             } else {
//               const patchedAction = {
//                 ...action,
//                 meta: {
//                   ...action.meta,
//                   dispatchedFromListener: true,
//                 },
//               };
//               return originalDispatch(patchedAction);
//             }
//           };

//           // @ts-ignore
//           effect(...newArgs);
//         };
//         startListening({
//           // @ts-ignore
//           actionCreator: listOfEvents[actionCreator],
//           // @ts-ignore
//           effect: wrappedEffect,
//         });
//         registeredListeners[actionCreator].push(effect.name);
//         nbListeners++;
//       }
//     }
//   }
//   // eslint-disable-next-line no-console
//   if (DEBUG) console.log(`Registered ${nbListeners} listeners:`, registeredListeners);
// }

type Options = {
  thread?: "main" | "worker" | "both";
  skipIfSyncedAction?: boolean;
};

const registeredListeners: Record<string, string[]> = {};
let nbListeners: number = 0;
type EffectActionCreator =
  | ActionCreatorWithPayload<any, string>
  | ActionCreatorWithPreparedPayload<any, any, string, unknown, AppActionMeta>;
const makeRegisterListener = <Type extends "api-save" | "local">(
  startListening: AppStartListening,
  listenerTypesRegistered: ("api-save" | "local")[],
  type: Type,
) =>
  function registerListener<
    A extends EffectActionCreator,
    E extends ListenerEffect<ReturnType<A>, RootState, AppThunkDispatch>,
    O extends Options & {thread: T},
    T extends Type extends "api-save" ? "worker" | "main" : "worker" | "main" | "both",
  >(actionCreator: A, effect: E, options: O) {
    const actionType = actionCreator.type;
    if (!registeredListeners[actionType]) registeredListeners[actionType] = [];

    const wrappedEffect = ((...args: Parameters<E>) => {
      const action = args[0] as AppActionWithMeta;
      // If this thread is not supposed to handle this action, return
      if ((options.thread === "main" && isWebWorker) || (options.thread === "worker" && !isWebWorker && !isNode)) {
        return;
      }

      // If this action has been received from the websocket and the effect is an API save, return
      if (type === "api-save" && "meta" in action && action.meta.initialDispatcher === "websocket") {
        console.log(`Skip API sync for action ${action.type} since it was received from the websocket sync`);
        return;
      }

      if (DEBUG)
        // eslint-disable-next-line no-console
        console.log(
          `⚡ Fired listener effect "${effect.name}" following "${actionCreator}" event with following action:`,
          action,
        );
      // console.log(`⚡ Fired listener effect "${effect.name}" following "${actionCreator}"`);

      // Monkey patch dispatch to make sure any action dispatched from a listener carries forward whether or not it was received from a websocket
      const originalDispatch = args[1].dispatch;
      const newArgs = [...args];
      newArgs[1] = {...args[1]};

      newArgs[1].dispatch = (...params: Parameters<typeof originalDispatch>) => {
        const localAction = params[0] as AppActionWithMeta | ThunkAction<any, RootState, ThunkExtraArg, AnyAction>;
        // const action = params[0] as Parameters<ActionCreatorEffect<typeof actionCreator>>[0];
        if (typeof localAction === "function") {
          const originalThunkAction = localAction;
          const wrappedAction = (...args: Parameters<typeof localAction>) => {
            // thunkExtra.
            return originalThunkAction(...args);
          };

          return originalDispatch(wrappedAction);
        } else {
          const newMeta: AppActionMeta = {
            ...localAction.meta,
            ...action.meta,
            listenerTypesRegistered,
          };
          if (newMeta._isThreadSync) delete newMeta._isThreadSync;

          const patchedAction: AppActionWithMeta = {
            ...localAction,
            meta: newMeta,
          };
          return originalDispatch(patchedAction);
        }
      };

      // @ts-ignore
      effect(...newArgs);
    }) as unknown as E;

    registeredListeners[actionType].push(effect.name);
    nbListeners++;

    startListening({
      actionCreator,
      effect: wrappedEffect,
    });
  };

export function registerAppListeners(
  startListening: AppStartListening,
  types: ("api-save" | "local")[] = ["api-save", "local"],
) {
  const registerLocalListener = makeRegisterListener(startListening, types, "local");
  const registerApiListener = makeRegisterListener(startListening, types, "api-save");

  const listOfWorkerApiEventsAndEffects = [
    // Hiring plan
    pair(employeeUpdated, syncUpdatedEmployeeWithAPI),
    pair(employeeScenarioPropertiesUpdated, syncUpdatedEmployeeWithAPI),
    pair(teamDeleted, syncTeamDeletesWithAPI),
    pair(teamUpdated, syncTeamUpsertsWithAPI),
    pair(teamDefaultValueUpserted, syncTeamUpsertsWithAPI),
    pair(teamDefaultMappingsUpdated, syncTeamUpsertsWithAPI),
    pair(upsertFc, syncFcUpsertsWithAPI),

    // Templates
    pair(upsertTemplate, syncTemplateUpsertsWithAPI),
    pair(upsertTemplates, syncTemplateUpsertsWithAPI),

    // Datasources
    pair(applyDatasourceChanges, syncDatasourceChangesWithAPI),

    // Departments
    pair(upsertDepartments, syncDepartmentUpsertsWithAPI),
    pair(deleteDepartments, syncDepartmentDeletesWithAPI),

    // Integrations
    pair(upsertIntegration, syncIntegrationUpsertsWithAPI),
    pair(updateIntegrationQuery, syncIntegrationUpsertsWithAPI),
    pair(disconnectIntegration, syncIntegrationDisconnectWithAPI),

    // Departments
    pair(upsertSanityChecks, syncSanityCheckUpsertsWithAPI),
    pair(deleteSanityChecks, syncSanityCheckDeletesWithAPI),

    // Template rows
    pair(addRows, syncRowUpsertsWithAPI),
    pair(upsertRow, syncRowUpsertsWithAPI),
    pair(upsertRows, syncRowUpsertsWithAPI),
    pair(removeRows, syncRowDeletesWithAPI),
    pair(removeRow, syncRowDeletesWithAPI),

    // Transactions
    pair(removeCbTx, syncTxItemDeletesWithAPI),

    // Reporting
    pair(reportItemAdded, syncReportItemUpsertsToAPI),
    pair(reportItemUpdated, syncReportItemUpsertsToAPI),
    pair(upsertReportItem, syncReportItemUpsertsToAPI),
    pair(upsertReport, syncReportUpsertsToAPI),
    pair(reportItemsApi.upsertReportItem.fulfilled, refetchMonthlyCacheAfterUpsertIfNecessary),
    pair(deleteReport, syncReportDeletesToAPI),
    pair(multipleLayoutsUpdated, syncMultipleItemLayoutsToAPI),
    pair(reportItemDeleted, syncReportItemDeletesToAPI),

    // Alerts
    pair(upsertAlertsForDs, syncDsAlertUpsertsWithAPI),
    pair(upsertAlerts, syncAlertUpsertsWithAPI),
    pair(clearAlertsForDatasource, syncAlertDeletesWithAPI),
    pair(deleteAlerts, syncAlertDeletesWithAPI),
  ] as const;

  const listOfWorkerLocalEventsAndEffects = [
    // Employees
    pair(employeeUpdated, propagateEmployeeMappingAndTagChanges),
    pair(employeeScenarioPropertiesUpdated, triggerDatasourcesUpdate),
    pair(removeEmployees, removeEmployeesListener),

    // Datasources
    pair(removeDatasourcesLocal, deleteTxForDeletedDatasources),
    pair(applyDatasourceChanges, triggerComputeAfterDatasourceChanges),
    // pair(applyDatasourceChanges, deleteTxForDeletedDatasources),

    // Template rows
    pair(addRows, updateTemplateOrdering),
    pair(removeRow, updateTemplateOrdering),
    pair(updateRowIntegration, updateRowIntegrationListener),

    // Templates
    pair(changeLastMonthOfActuals, changeLastMonthOfActualsListener),

    // Transactions
    pair(generateMonthlyCache, generateMonthlyCacheListener),
    pair(applyChangesFromBackend, applyChangesFromBackendListener),
    pair(triggerAlgoFromListener, triggerAlgoFromListenerHandler),
    pair(txItemsApi.fetchAllCbTxTransactions.fulfilled, removeNonIntegrationKeysFromInitialCache),
  ] as const;

  const listOfMainThreadLocalEventsAndEffects = [
    pair(insertAutoRange, insertAutoRangeListener),
    pair(applyDiffToMonthlyCache, runSanityChecksOnCacheUpdate),
    pair(monthlyCacheUpdated, runSanityChecksOnCacheUpdate),
    pair(clearMonthlyCacheForDateKeys, runSanityChecksOnCacheUpdate),
    pair(fetchQboStatementRows.fulfilled, runSanityChecksOnCacheUpdate),
  ] as const;

  if (types.includes("api-save")) {
    for (const [actionCreator, effect] of listOfWorkerApiEventsAndEffects) {
      const actionCreators = Array.isArray(actionCreator) ? actionCreator : [actionCreator];
      for (const actionCreator of actionCreators) {
        registerApiListener(actionCreator, effect, {
          thread: "worker",
        });
      }
    }

    for (const [actionCreator, effect] of listOfMainThreadLocalEventsAndEffects) {
      const actionCreators = Array.isArray(actionCreator) ? actionCreator : [actionCreator];
      for (const actionCreator of actionCreators) {
        registerLocalListener(actionCreator, effect, {
          thread: "main",
        });
      }
    }
  }

  for (const [actionCreator, effect] of listOfWorkerLocalEventsAndEffects) {
    const actionCreators = Array.isArray(actionCreator) ? actionCreator : [actionCreator];
    for (const actionCreator of actionCreators) {
      registerLocalListener(actionCreator, effect, {
        thread: "worker",
      });
    }
  }
}

type ActionAndEffectPair<A extends EffectActionCreator | EffectActionCreator[], E extends ActionCreatorEffect<A>> = [
  A,
  E,
];
function pair<A extends EffectActionCreator | EffectActionCreator[], E extends ActionCreatorEffect<A>>(
  actionCreator: A,
  effect: E,
): ActionAndEffectPair<A, E> {
  return [actionCreator, effect];
}

export type ActionCreatorEffect<A extends EffectActionCreator | EffectActionCreator[]> = (
  action: ReturnType<A extends any[] ? A[number] : A> & AppActionWithMeta,
  listenerEffectApi: ListenerEffectAPI<RootState, AppThunkDispatch, unknown>,
) => void;

export function createEffectHandler<A extends EffectActionCreator | EffectActionCreator[]>(
  _actionCreator: A,
  handler: ActionCreatorEffect<A>,
) {
  return handler;
}
