import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  settingsTabMain: {},
  moveUpDown: {
    paddingLeft: 15,
    "&$moveUpDown": {marginTop: 0},
  },
  upDownButtonsContainer: {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 30,

    "& > div:first-child": {
      marginRight: 5,
    },
  },
  formatPositionWrapper: {
    display: "flex",
  },
});
