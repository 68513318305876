import {apiWithContract} from "@shared/lib/fetch";
import type {ApiEndpoints} from "@shared/state/store";
import type {Scenario} from "@shared/types/db";
import type {ScenariosApiContracts} from "./contracts/scenarios.contracts";

const endpoints = {
  fetchAll(_args, thunkAPI) {
    return apiWithContract<ScenariosApiContracts["get"]>(thunkAPI, {
      method: "get",
      path: "scenarios",
      body: null,
      query: null,
    });
  },
  create({scenario, basedOn}: {basedOn?: string | null; scenario: Scenario}, thunkAPI) {
    return apiWithContract<ScenariosApiContracts["create"]>(thunkAPI, {
      method: "put",
      path: "scenarios/create",
      body: {scenario, basedOn: basedOn || undefined},
      query: null,
    });
  },
  upsert(scenario: Scenario, thunkAPI) {
    return apiWithContract<ScenariosApiContracts["upsert"]>(thunkAPI, {
      method: "put",
      path: `scenarios/${scenario.id}`,
      body: scenario,
      query: null,
    });
  },
  remove(id: string, thunkAPI) {
    return apiWithContract<ScenariosApiContracts["delete"]>(thunkAPI, {
      method: "del",
      path: `scenarios/${id}`,
      body: null,
      query: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
