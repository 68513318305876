// It's unfortunate, but we need Luxon solely for this purpose (intervals)
// - Doing it manually is a lot of work, full of edge-cases, and prone to errors
// - dayjs doesn't have functions for intervals or ranges
// - Other libs are old / obscure / barely used / unmaintained
// This adds about 90k to the bundle size, 20k gzipped
// TODO: see if it's possible to avoid loading it until it's actually needed
import {parseCacheKey} from "@shared/data-functions/cache/cache-utilities";
import {NO_DEPARTMENT_NAME, getRefsFromFormula} from "@shared/data-functions/formula/formula-utilities";
import {selectMatchingDatasources} from "@state/datasources/selectors";
import {projKey, projKeyOmitNulls} from "@state/utils";
import memoize from "fast-memoize";
import isEqual from "lodash.isequal";
import {DateTime, Interval} from "luxon";

import {
  addDeltaMonthsToDateKey,
  getAllDateKeysBetween,
  getFormattedFullDate,
  isDateKeyInRange,
  resolveDatasourceDates,
  resolveStartEndFromState,
} from "./date-utilities";
import {mapEntitiesToIds} from "./entity-functions";
import id from "./id";
import {itemIsNotFalsy} from "./misc";
import {getResolvedDateRangeFromDatasource, insertFormulaForDates} from "./row-utilities";

import type {Dictionary, EntityId} from "@reduxjs/toolkit";
import type {ParsedCacheKey} from "@shared/data-functions/cache/cache-utilities";
import type {DsError} from "@shared/types/alerts";
import type {Datasource, Formula, FormulaDatasource, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {TemplateOptions, TemplateRow} from "@shared/types/db";
import type {Employee} from "@shared/types/hiring-plan";
import type {DatasourcesState} from "@state/entity-adapters";
import type {RootState} from "@state/store";

export type TempDbDatasource = Datasource & {
  temp: true;
};

export type GlobalForecastDates = {
  start: string;
  end: string;
  lastMonthOfActuals: string;
};

export type InsertDatasourcesParams<D1 extends Datasource, D2 extends Datasource> = {
  existingDatasources: (D1 | TempDbDatasource)[];
  datasourcesToAdd: (D2 | TempDbDatasource)[];
  forecastDates: GlobalForecastDates;
  method?: "add" | "replace";
  extendToFillGaps?: boolean;
  debug?: boolean;
  dateKeysToClearAfterLmoaMove?: string[];
};

export const dynamicDsDateRegex = /(-?[1-9][0-9]*)(a|f)/;

export function insertDatasources<D1 extends Datasource, D2 extends Datasource>({
  existingDatasources,
  datasourcesToAdd,
  forecastDates,
  dateKeysToClearAfterLmoaMove = [],
  method = "add",
  extendToFillGaps = false,
  debug = false,
}: InsertDatasourcesParams<D1, D2>): {datasources: (D1 | D2)[]; deletedIds: string[]} {
  if (debug) console.log(JSON.stringify(arguments[0]));
  const deletedIds = new Set<string>();
  const newDatasources: (D1 | D2)[] = [];

  const lastLMOA = dateKeysToClearAfterLmoaMove?.length
    ? addDeltaMonthsToDateKey(dateKeysToClearAfterLmoaMove[0], -1)
    : null;

  const existingDatasourcesByProjKey = Object.groupBy(existingDatasources, (datasource) =>
    projKey(
      datasource.row_id,
      datasource.department_id ?? "all",
      datasource.dimensions?.vendor ?? "all",
      datasource.scenario_id,
    ),
  );
  let datasourcesToAddByProjKey = Object.groupBy(datasourcesToAdd, (datasource) =>
    projKey(
      datasource.row_id,
      datasource.department_id ?? "all",
      datasource.dimensions?.vendor ?? "all",
      datasource.scenario_id,
    ),
  );

  const templateOptionsFullEndDate =
    forecastDates.end.length === 7 ? getFormattedFullDate(forecastDates.end, "end") : forecastDates.end;
  const templateOptionsFullStartDate =
    forecastDates.start.length === 7 ? getFormattedFullDate(forecastDates.start, "start") : forecastDates.start;

  if (!datasourcesToAdd.length) {
    const key = projKey(
      existingDatasources[0]?.row_id,
      existingDatasources[0]?.department_id ?? "all",
      existingDatasources[0]?.dimensions?.vendor ?? "all",
      existingDatasources[0]?.scenario_id,
    );
    datasourcesToAddByProjKey = {
      [key]: [],
    };

    for (const dateKey of dateKeysToClearAfterLmoaMove) {
      const emptyDatasource = getEmptyDatasource(
        existingDatasources[0]?.row_id,
        existingDatasources[0]?.department_id,
        existingDatasources[0]?.dimensions?.vendor,
        existingDatasources[0]?.scenario_id,
        dateKey,
      );

      datasourcesToAddByProjKey[key].push(emptyDatasource);
    }
  }

  for (const [key, filteredDatasourcesToAdd] of Object.entries(datasourcesToAddByProjKey)) {
    const [rowId, departmentId, vendor, scenarioId] = key.split("::");
    for (const dateKey of dateKeysToClearAfterLmoaMove) {
      const emptyDatasource = getEmptyDatasource(
        rowId,
        departmentId === "all" ? undefined : departmentId,
        vendor === "all" ? undefined : vendor,
        scenarioId,
        dateKey,
      );

      filteredDatasourcesToAdd.push(emptyDatasource);
    }

    const filteredExistingDatasources = existingDatasourcesByProjKey[key] ?? [];
    const updatePayload: {interval: Interval; originalDatasource: Datasource | TempDbDatasource}[] = [];

    if (!filteredDatasourcesToAdd.length) {
      const intervals = getIntervalsForUpdatePayload(filteredExistingDatasources, forecastDates);
      if (intervals?.length) {
        updatePayload.push(...intervals);
      }
    } else {
      if (method === "replace") {
        // If method is replace, delete the existing ones so only the new ones are kept
        for (const newDatasource of filteredDatasourcesToAdd) {
          if ("temp" in newDatasource && newDatasource.temp) continue;

          const matchingExistingDatasourceIndex = filteredExistingDatasources.findIndex(
            (datasource) => datasource.id === newDatasource.id,
          );
          if (matchingExistingDatasourceIndex === -1) {
            console.error("Trying to replace datasource but its id can't be found in existing datasources", {
              existingDatasources: filteredExistingDatasources,
              datasourcesToAdd: filteredDatasourcesToAdd,
            });
            continue;
          }
          filteredExistingDatasources.splice(matchingExistingDatasourceIndex, 1);
        }
      }
      for (const type of ["existing", "new"] as const) {
        const datasources = type === "existing" ? filteredExistingDatasources : filteredDatasourcesToAdd;
        const intervals = getIntervalsForUpdatePayload(datasources, forecastDates);
        if (intervals?.length) {
          updatePayload.push(...intervals);
        }
      }
    }

    const usedIds = new Set<string>();

    const newIntervals: {interval: Interval; originalDatasource: Datasource}[] = [];
    do {
      // logIntervals(updatePayload.map(({interval}) => interval));
      const nextItem = updatePayload.shift();
      if (!nextItem) break;

      const alteredIntervals = updatePayload.length
        ? nextItem.interval.difference(...updatePayload.map(({interval}) => interval))
        : [nextItem.interval];

      if (!alteredIntervals.length && !("temp" in nextItem.originalDatasource)) {
        deletedIds.add(nextItem.originalDatasource.id);
      }

      for (const interval of alteredIntervals) {
        if (interval) {
          let atIndex = -1;
          let previousInterval = newIntervals.at(atIndex);
          if (previousInterval) {
            while (previousInterval.interval.isAfter(interval.start)) {
              atIndex--;
              previousInterval = newIntervals.at(atIndex);
              if (!previousInterval) break;
            }
          }

          const sameOptions = !previousInterval
            ? true
            : datasourcesHaveSameOptions(previousInterval.originalDatasource, nextItem.originalDatasource);

          const abutsEnd = !previousInterval ? false : interval.abutsEnd(previousInterval.interval);
          // const abutsEnd = !previousInterval ? false : interval.abutsEnd(previousInterval.interval);
          if (
            previousInterval &&
            sameOptions &&
            abutsEnd &&
            previousInterval.originalDatasource.type !== "hiring-plan-formula"
          ) {
            // If the datasource is the same as the previous one with simply different dates
            // extend the previous datasource to be adjascent to this one
            previousInterval.interval = previousInterval.interval.set({end: interval.end});
            previousInterval.originalDatasource.end = nextItem.originalDatasource.end;

            // If this previous datasource was not the last item in the newIntervals array
            // and the next item is the same as this one, remove the next item and update this one's dates
            const nextInterval = newIntervals.at(atIndex + 1);
            if (
              nextInterval &&
              datasourcesHaveSameOptions(previousInterval.originalDatasource, nextInterval.originalDatasource) &&
              nextInterval.interval.abutsEnd(previousInterval.interval)
            ) {
              previousInterval.interval = previousInterval.interval.set({end: nextInterval.interval.end});
              previousInterval.originalDatasource.end = nextInterval.originalDatasource.end;
              newIntervals.splice(atIndex + 1, 1);
            }

            continue;
          } else if (previousInterval && extendToFillGaps && !interval.abutsEnd(previousInterval.interval)) {
            // If the extendToFillGaps is aset to true and the last datasource is not adjascent
            // extend the previous datasource to be adjascent to this one
            previousInterval.interval = previousInterval.interval.set({end: interval.start});
          }
          const datasource = {...nextItem.originalDatasource};
          if (usedIds.has(datasource.id)) datasource.id = id();
          newIntervals.push({interval, originalDatasource: datasource});
          usedIds.add(datasource.id);
        }
      }
    } while (updatePayload.length);
    // logIntervals(newIntervals.map(({interval}) => interval));

    // Get LMOA as Luxon for use in loop
    let lmoaDateStr = forecastDates.lastMonthOfActuals;
    if (forecastDates.lastMonthOfActuals.length === 7) lmoaDateStr += "-01";
    const luxonLMOA = DateTime.fromISO(lmoaDateStr);

    for (const {interval, originalDatasource} of newIntervals.sort((a, b) =>
      (a.interval.start?.toISODate() ?? "").localeCompare(b.interval.start?.toISODate() ?? ""),
    )) {
      // If this was a temporary datasource, don't add it to the new datasources - it was only used for calculations
      if ("temp" in originalDatasource && originalDatasource.temp) continue;

      const dates: Record<"start" | "end", string | null> = {
        start: interval.start.toISODate(),
        end: interval.end.minus({days: 1}).toISODate(),
      };

      const {startIsDynamic, endIsDynamic} =
        getResolvedDateRangeFromDatasource(originalDatasource, forecastDates) ?? {};

      // If this is not an FC row, check if it's possible to make some keys dynamic
      // This will need to be updated when we fully support controlling the dynamic dates in the UI
      if (originalDatasource.type !== "hiring-plan-formula") {
        // Until we have proper "snapping", set end as dynamic if it's the LMOA
        if (startIsDynamic && luxonLMOA.plus({months: 1}).toISODate() === dates.start) dates.start = "1f";

        if (interval.start.startOf("month").toISODate() === dates.start)
          // If start is the first day of month or end is the last day of month, use short dateKey
          dates.start = dates.start.slice(0, 7);
        if (interval.end.minus({days: 1}).endOf("month").toISODate() === dates.end) dates.end = dates.end.slice(0, 7);

        // If it starts or ends with the templates start or end, use null values to reflect that
        if (dates.start === forecastDates.start || dates.start === templateOptionsFullStartDate) dates.start = null;
        if (dates.end === forecastDates.end || dates.end === templateOptionsFullEndDate) dates.end = null;

        // Until we have proper "snapping", set end as dynamic if it's the LMOA
        if (endIsDynamic && (dates.end === forecastDates.lastMonthOfActuals || (lastLMOA && dates.end === lastLMOA))) {
          dates.end = "-1a";
        }
      }

      const newDatasource = {...originalDatasource, ...dates} as D1 | D2;

      newDatasources.push(newDatasource);
    }
  }

  if (debug) console.log(JSON.stringify({datasources: newDatasources, deletedIds: [...deletedIds]}));
  return {datasources: newDatasources, deletedIds: [...deletedIds]};
}

export const resolveDatasourceAsLuxonDates = memoize(
  (
    start: string | null,
    end: string | null,
    templateStart: string,
    templateEnd: string,
    lastMonthOfActuals: string,
  ): Record<"start" | "end", DateTime | null> | null => {
    const luxonDates: Record<"start" | "end", DateTime | null> = {
      start: null,
      end: null,
    };

    for (const startOrEnd of ["start", "end"] as const) {
      const templateOptionsStartOrEnd = startOrEnd === "start" ? templateStart : templateEnd;

      let datasourceDate = startOrEnd === "start" ? start : end;
      datasourceDate = datasourceDate === "-1" ? "-1a" : datasourceDate;
      if (!datasourceDate) {
        // If it's null, use template boundaries
        let dateStr = templateOptionsStartOrEnd;
        if (templateOptionsStartOrEnd.length === 7) dateStr += "-01";
        if (startOrEnd === "start") {
          luxonDates[startOrEnd] = DateTime.fromISO(dateStr);
        } else {
          if (templateOptionsStartOrEnd.length === 10) {
            luxonDates[startOrEnd] = DateTime.fromISO(dateStr);
          } else {
            luxonDates[startOrEnd] = DateTime.fromISO(dateStr).endOf("month");
          }
        }
      } else if (datasourceDate.match(/\d{4}-\d{2}-\d{2}/)) {
        // If it's a full date, use it
        luxonDates[startOrEnd] = DateTime.fromISO(datasourceDate);
      } else if (datasourceDate.match(/\d{4}-\d{2}/)) {
        // If it's short dateKey, convert it to full date
        const luxonDate = DateTime.fromISO(`${datasourceDate}-01`);
        luxonDates[startOrEnd] = startOrEnd === "start" ? luxonDate : luxonDate.endOf("month");
      } else {
        // Resolve the dynamic date relative to the LMOA
        const match = datasourceDate.match(dynamicDsDateRegex);
        if (match) {
          let dateStr = lastMonthOfActuals;
          if (lastMonthOfActuals.length === 7) dateStr += "-01";
          const delta = parseInt(match[1], 10);
          let luxonDate: DateTime;
          if (delta > 0) {
            luxonDate = DateTime.fromISO(dateStr).plus({months: delta});
          } else if (delta === -1 || delta === 0) {
            luxonDate = DateTime.fromISO(dateStr);
          } else {
            luxonDate = DateTime.fromISO(dateStr).minus({months: delta + 1});
          }
          luxonDates[startOrEnd] = startOrEnd === "start" ? luxonDate : luxonDate.endOf("month");
        } else {
          console.warn(`Unrecognized date range ${startOrEnd}: ${datasourceDate}`);
        }
      }

      // Need to make sure all dates are the same hour so comparing two dates for adjacency
      // works (start and end of the two ranges needs to be identical)
      if (luxonDates[startOrEnd]) luxonDates[startOrEnd] = luxonDates[startOrEnd]!.endOf("day");
    }

    // Check to make sure all dates fit within the template boundaries
    const formatted = {
      start: luxonDates.start?.toISODate(),
      end: luxonDates.end?.toISODate(),
    };

    const luxonTemplateEnd =
      templateEnd.length === 7 ? DateTime.fromISO(templateEnd).endOf("month") : DateTime.fromISO(templateEnd);
    const formattedTemplateStart = getFormattedFullDate(templateStart, "start");
    if (formatted.start && formatted.start > getFormattedFullDate(templateEnd, "end")) return null;
    if (formatted.end && formatted.end < formattedTemplateStart) return null;
    if (formatted.end && formatted.end > templateEnd) luxonDates.end = luxonTemplateEnd;
    if (formatted.start && formatted.start < formattedTemplateStart)
      luxonDates.start = DateTime.fromISO(formattedTemplateStart);

    return luxonDates;
  },
);

export function getIntervalsForUpdatePayload(datasources: Datasource[], forecastDates: GlobalForecastDates) {
  const intervals: {interval: Interval; originalDatasource: Datasource}[] = [];
  for (const datasource of datasources) {
    // Parse into luxon dates
    const luxonDates = resolveDatasourceAsLuxonDates(
      datasource.start,
      datasource.end,
      forecastDates.start,
      forecastDates.end,
      forecastDates.lastMonthOfActuals,
    );

    if (!luxonDates) continue;

    if (luxonDates.end && luxonDates.start && luxonDates.end < luxonDates.start) {
      luxonDates.end = luxonDates.start.endOf("month");
    }

    intervals.push({
      originalDatasource: datasource,
      interval: Interval.fromDateTimes(
        luxonDates.start ?? DateTime.now(),
        // Add 1 day to "end" here - it will subtracted back later
        // It's needed to avoid one day gaps between months
        luxonDates.end?.plus({days: 1}) ?? DateTime.now(),
      ),
    });
  }
  return intervals.toSorted((a, b) =>
    (a.interval.start?.toISODate() ?? "").localeCompare(b.interval.start?.toISODate() ?? ""),
  );
}

function logIntervals(intervals: Interval | Interval[]) {
  const intervalsArray = !Array.isArray(intervals) ? [intervals] : intervals;
  console.log(intervalsArray.map((interval) => ({start: interval.start.toISODate(), end: interval.end.toISODate()})));
}

/**
 * Compares two datasources to tell whether or not they are the same, besides the dates
 *
 * @param {Datasource} d1 - First datasource to compare
 * @param {Datasource} d2 - Second datasource to compare
 * @returns {boolean} Whether or not the datasources are the same
 */
export function datasourcesHaveSameOptions(d1: Datasource | undefined, d2: Datasource | undefined) {
  if (!d1 || !d2) return false;

  const basePropsToCompare: (keyof Datasource)[] = ["department_id", "row_id", "scenario_id", "type"];
  for (const key of basePropsToCompare) {
    if (d1[key] !== d2[key]) {
      return false;
    }
  }

  if ((d1.dimensions?.vendor ?? null) !== (d2.dimensions?.vendor ?? null)) return false;

  if (d1.type === "formula" && d2.type === "formula") {
    return (
      d1.options.formula === d2.options.formula &&
      (d1.options.ui?.formulaType ?? null) === (d2.options.ui?.formulaType ?? null) &&
      (d1.options.ui?.timePeriod ?? null) === (d2.options.ui?.timePeriod ?? null) &&
      (d1.options.ui?.type ?? null) === (d2.options.ui?.type ?? null)
    );
  } else if (d1.type === "integration" && d2.type === "integration") {
    return d1.options.remoteId === d2.options.remoteId && d1.integration_id === d2.integration_id;
  } else if (d1.type === "hiring-plan-formula" && d2.type === "hiring-plan-formula") {
    return (
      d1.options.employee_id === d2.options.employee_id &&
      d1.options.fc_name === d2.options.fc_name &&
      d1.options.formula === d2.options.formula &&
      d1.options.ui.expressedAs === d2.options.ui.expressedAs &&
      (d1.options.ui.target ?? null) === (d2.options.ui.target ?? null) &&
      d1.options.ui.type === d2.options.ui.type &&
      d1.options.ui.value === d2.options.ui.value
    );
  }

  return false;
}

export function getDatasourcesForMonth(
  datasourcesOrDatasourcesState: DatasourcesState | Datasource[],
  {rowId, scenarioId, dateKey, departmentId = null, vendor = null}: ParsedCacheKey,
  templateOptions: Pick<TemplateOptions, "start" | "end" | "lastMonthOfActuals">,
): Datasource[] {
  const matchingDatasources: Datasource[] = [];
  const datasourcesArray = Array.isArray(datasourcesOrDatasourcesState)
    ? datasourcesOrDatasourcesState
    : mapEntitiesToIds(
        datasourcesOrDatasourcesState.entities,
        datasourcesOrDatasourcesState.idsByProjKey[projKeyOmitNulls(rowId, scenarioId, departmentId, vendor)] ?? [],
      );
  for (const datasource of datasourcesArray) {
    if ((datasource.department_id ?? null) !== departmentId) continue;
    if ((datasource.dimensions?.vendor?.toLowerCase() ?? null) !== vendor) continue;

    if (isDateKeyInRange(dateKey, datasource, templateOptions)) {
      matchingDatasources.push(datasource);
    }
  }

  return matchingDatasources;
}

export function getChangesForInsertFormulaForDates({
  dateKeyToFormulaMapping,
  row,
  scenarioId,
  departmentId,
  vendor,
  state,
}: {
  dateKeyToFormulaMapping: Record<string, string | null>;
  row: TemplateRow;
  scenarioId: string;
  state: RootState;
  departmentId?: string | null;
  vendor?: string | null;
}): DatasourceDiff {
  // debugger;
  // log("getChangesForInsertFormulaForDates", {dateKeyToFormulaMapping, row, scenarioId, departmentId, vendor});
  const rowDatasources = selectMatchingDatasources(state, {
    scenarioId,
    rowId: row.id,
    departmentId,
    vendor,
  });

  const templateOptions = state.templates.entities[row.template_id]?.options;
  if (!templateOptions) return getEmptyDatasourceDiff();

  const {datasources: upsertedDatasources} = insertFormulaForDates({
    dateKeyToFormulaMapping,
    row,
    scenarioId,
    templateOptions,
    datasources: rowDatasources,
    departmentId,
    vendor,
  });

  return getDatasourceDiff(rowDatasources, upsertedDatasources, state);
}

interface GetDatasourceChanges {
  oldDatasources: Datasource[] | Record<string, Datasource> | Dictionary<Datasource>;
  newDatasources: Datasource[];
}
/**
 * Returns a list of datasources that have changed between oldDatasources and newDatasources.
 * WARNING: it only handles changed or added datasources, not the removals. They must be gathered separately.
 *
 * @param {GetDatasourceChanges} params
 * @param {Datasource[] | Record<string, Datasource> | Dictionary<Datasource>} params.oldDatasources - The list of existing datasources. Can be a subset or all datasources
 * @param {Datasource[]} params.newDatasources - The list of updated datasources. If a subset, it must correspond to the same subset as the existing datasources
 * @returns {Datasource[]} The list of datasources that have changed between old and new
 */

export function getDatasourcesDiffWithoutDeletes({oldDatasources, newDatasources}: GetDatasourceChanges) {
  // console.log(JSON.stringify({oldDatasources, newDatasources}));
  if (Array.isArray(oldDatasources))
    oldDatasources = Object.fromEntries(oldDatasources.map((datasource) => [datasource.id, datasource]));
  if (!newDatasources.length) return [];
  const updatedDatasources: Datasource[] = [];

  for (const datasource of newDatasources) {
    const matchingOldDatasource = oldDatasources[datasource.id];
    if (!matchingOldDatasource) {
      updatedDatasources.push(datasource);
      continue;
    }
    const hasChanged = !isEqual(datasource, matchingOldDatasource);
    // let hasChanged =
    //   datasource.integration_id !== matchingOldDatasource.integration_id ||
    //   datasource.start !== matchingOldDatasource.start ||
    //   datasource.end !== matchingOldDatasource.end ||
    //   datasource.type !== matchingOldDatasource.type;

    // if (!hasChanged && datasource.type === "formula" && matchingOldDatasource.type === "formula") {
    //   hasChanged ||= datasource.options.formula !== matchingOldDatasource.options.formula;
    //   if (hasChanged)
    //     console.warn(
    //       "WARNING: datasource has the same id but a different formula after calling insertFormulaForDates",
    //     );
    // }

    if (hasChanged) updatedDatasources.push(datasource);
  }
  // console.log(JSON.stringify(updatedDatasources));
  return updatedDatasources;
}

// interface GetCacheKeysToClearParams {
//   state: RootState;
//   cacheKeys: string[];
// }
// /**
//  * Finds cacheKeys that contain a value in cache but are not covered by any datasource.
//  * This means they need to be cleared
//  *
//  * @param {GetCacheKeysToClearParams} params
//  * @param {RootState} params.state - The complete Redux state object
//  * @param {string[]} params.cacheKeys - The list of cacheKeys already covered by the update payload
//  * @returns {string[]} The list of cache keys that need to be cleared
//  */
// export function getCacheKeysToClearOutsideMapping({state, cacheKeys}: GetCacheKeysToClearParams) {
//   const keysWithoutDateToCheck: Record<string, ParsedCacheKey> = {};
//   const cacheKeysToClear: string[] = [];
//   for (const cacheKey of cacheKeys) {
//     const parsedKey = parseCacheKey(cacheKey);
//     const keyWithoutDate = projKeyOmitNulls(
//       parsedKey.rowId,
//       parsedKey.departmentId,
//       parsedKey.vendor,
//       parsedKey.scenarioId,
//     );
//     if (!keysWithoutDateToCheck[keyWithoutDate]) keysWithoutDateToCheck[keyWithoutDate] = parsedKey;
//   }

//   const dateKeysToCheckByTemplateId: Record<string, string[]> = {};

//   for (const {rowId, departmentId, vendor, scenarioId} of Object.values(keysWithoutDateToCheck)) {
//     const row = state.templateRows.entities[rowId];
//     const template = state.templates.entities[row?.template_id ?? ""];
//     if (!row || !template) continue;

//     dateKeysToCheckByTemplateId[template.id] ||= getAllDateKeysBetween(template.options.start, template.options.end);

//     for (const dateKey of dateKeysToCheckByTemplateId[template.id]) {
//       // If there's no value already, do nothing
//       const cacheKey = projKeyOmitNulls(rowId, departmentId, vendor, scenarioId, dateKey);
//       const valueInCache = state.transactionItems.valuesByRowIdDateKey[cacheKey];
//       if (!valueInCache) continue;

//       // If this cacheKey is part of the initial cachekeys submitted, no need to flag it again
//       if (cacheKeys.includes(cacheKey)) continue;

//       // If we're here, it means there is a value.
//       // Check if there's a datasource for that dateKey, and if not, it needs to be flagged
//       if (
//         !getDatasourcesForMonth(state.datasources, {rowId, departmentId, vendor, scenarioId, dateKey}, template.options)
//       )
//         cacheKeysToClear.push(cacheKey.toLowerCase());
//     }
//   }

//   return cacheKeysToClear;
// }

interface GetKeysCoveredByDatasourcesParams {
  state: RootState;
  datasources: Datasource[];
}
/**
 * Returns all the cacheKeys covered by a list of datasources
 *
 * @param {GetKeysCoveredByDatasourcesParams} params
 * @param {RootState} params.state - The complete Redux state object
 * @param {Datasource[]} params.datasources - The list of datasources to analyze
 * @returns {string[]} All the cacheKeys covered by the list of datasources
 */
export function getAllCacheKeysCoveredByDatasources({state, datasources}: GetKeysCoveredByDatasourcesParams) {
  const keysCovered: string[] = [];
  for (const datasource of datasources) {
    if (datasource) keysCovered.push(...getKeysCovered({state, datasource}));
  }

  return keysCovered;
}

interface GetDateKeysCoveredByIdsParams {
  state: RootState;
  ids: EntityId[];
  additionalEmployees?: Employee[];
}
/**
 * Returns all the cacheKeys covered by a list of datasource ids
 *
 * @param {GetDateKeysCoveredByIdsParams} params
 * @param {RootState} params.state - The complete Redux state object
 * @param {EntityId[]} params.ids - The list of datasource ids to analyze
 * @returns {string[]} All the cacheKeys covered by the list of datasource ids
 */
export function getAllCacheKeysCoveredByDatasourceIds({state, ids}: GetDateKeysCoveredByIdsParams) {
  const keysCovered: string[] = [];
  for (const id of ids) {
    const datasource = state.datasources.entities[id];

    if (datasource) keysCovered.push(...getKeysCovered({state, datasource}));
  }

  return keysCovered;
}
interface GetKeysCoveredParams {
  state: RootState;
  datasource: Datasource;
  resolvedDates?: {start: string; end: string};
}
/**
 * Returns all the cacheKeys covered by a datasource
 *
 * @param {GetKeysCoveredParams} params
 * @param {RootState} params.state - The complete Redux state object
 * @param {Datasource} params.datasource - The datasource to analyze
 * @returns {string[]} All the cacheKeys covered by the datasource
 */
function getKeysCovered({state, datasource, resolvedDates}: GetKeysCoveredParams) {
  const row = state.templateRows.entities[datasource?.row_id ?? ""];
  const template = state.templates.entities[row?.template_id ?? ""];
  if (!datasource || !row || !template) return [];

  let start: string;
  let end: string;
  if (!resolvedDates) {
    let templateStart = template.options.start;
    let templateEnd = template.options.end;

    const result = resolveDatasourceDates(
      datasource.start ? datasource.start.slice(0, 7) : null,
      datasource.end ? datasource.end.slice(0, 7) : null,
      templateStart,
      templateEnd,
      template.options.lastMonthOfActuals,
    );

    start = result.start;
    end = result.end;
  } else {
    start = resolvedDates.start;
    end = resolvedDates.end;
  }

  const noDeptId = state.departments.idsByName[NO_DEPARTMENT_NAME] ?? null;
  const noVendorName = "no_vendor";

  const dateKeys = getAllDateKeysBetween(start, end);
  const coveredCacheKeys: string[] = [];
  for (const dateKey of dateKeys) {
    coveredCacheKeys.push(
      projKeyOmitNulls(
        datasource.row_id,
        datasource.department_id ?? noDeptId,
        datasource.dimensions?.vendor?.toLowerCase(),
        datasource.scenario_id,
        dateKey,
      ),
      projKeyOmitNulls(datasource.row_id, datasource.scenario_id, dateKey),
    );
  }

  return coveredCacheKeys;
}

export function findDatasourcesToRemoveAfterForecastTypeChange(
  state: RootState,
  rowId: string,
  departmentId: string | null,
  newForecastType: "vendor" | "department" | "row",
) {
  const datasourceIdsForRow = state.datasources.idsByRowId[rowId];
  const datasourcesToRemove: Datasource[] = [];
  // if (newForecastType === "department") debugger;
  for (const datasourceId of datasourceIdsForRow ?? []) {
    const datasource = state.datasources.entities[datasourceId];
    if (datasource?.type !== "formula" || datasource.scenario_id !== state.global.scenarioId) continue;

    if (newForecastType === "vendor") {
      if ((!departmentId || datasource.department_id === departmentId) && !datasource.dimensions?.vendor) {
        // If the new forecast type is vendor, and the datasource is for the same department but without a vendor dimension, remove it
        // Also, if no datasource is selected, it means we go from row to vendor, so remove all forecasts in all departments without a vendor dimension
        datasourcesToRemove.push(datasource);
      }
    } else if (newForecastType === "department") {
      if (!!datasource.dimensions?.vendor || !datasource.department_id) {
        // If the new forecast type is department, and the datasource has a vendor dimension, remove it
        datasourcesToRemove.push(datasource);
      }
    } else {
      if (datasource.department_id || datasource.dimensions?.vendor) {
        // If the new forecast type is row, and the datasource has a department or vendor dimension, remove it
        datasourcesToRemove.push(datasource);
      }
    }
  }

  return datasourcesToRemove;
}

export function getDefaultFormulaAndReferences(
  row: TemplateRow,
  departmentName: string | null,
  vendor: string | null,
  uiOptions: NonNullable<Formula["ui"]>,
) {
  let formula: string;

  if (uiOptions.type === "auto") {
    const refDeptVendorDateKey = [uiOptions.timePeriod, departmentName, vendor].filter(itemIsNotFalsy);
    switch (uiOptions.formulaType) {
      case "average": {
        formula = `=AVERAGE([${row.name}, ${refDeptVendorDateKey.join(", ")}])`;
        break;
      }
      case "median": {
        formula = `=MEDIAN([${row.name}, ${refDeptVendorDateKey.join(", ")}])`;
        break;
      }
      case "pct-of-target": {
        const targetDeptVendorThisMonth = ["this_month", uiOptions.targetDepartment, uiOptions.targetVendor].filter(
          itemIsNotFalsy,
        );
        const targetDeptVendorTimePeriod = [
          uiOptions.timePeriod,
          uiOptions.targetDepartment,
          uiOptions.targetVendor,
        ].filter(itemIsNotFalsy);

        formula = `=AVERAGE([${row.name}, ${refDeptVendorDateKey.join(", ")}])/AVERAGE([${
          uiOptions.target
        }, ${targetDeptVendorTimePeriod.join(", ")}])*[${uiOptions.target}, ${targetDeptVendorThisMonth.join(", ")}]`;
        break;
      }
    }
  } else if (uiOptions.type === "pre-built") {
    //   export type PreBuiltFormulaTypes =
    // | "gross-profit"
    // | "net-operating-income"
    // | "net-income"
    // | "net-other-income"
    // | "net-income-bs"
    // | "net-cash-increase";
    switch (uiOptions.formulaType) {
      case "gross-profit": {
        formula = `=[revenue]-[cost_of_goods_sold]`;
        break;
      }
      case "net-operating-income": {
        formula = `=[gross_profit]-[expenses]`;
        break;
      }
      case "net-other-income": {
        formula = `=[other_income]-[other_expenses]`;
        break;
      }
      case "net-income": {
        formula = `=[net_operating_income]+[net_other_income]`;
        break;
      }
      case "net-income-bs": {
        formula = `=[balance_sheet!bs_net_income, -1]+[profit_and_loss!net_income]`;
        break;
      }
      case "net-cash-increase": {
        formula = `=[operating_activities]+[investing_activities]+[financing_activities]`;
        break;
      }
      case null: {
        formula = "";
      }
    }
  } else {
    formula = "";
  }

  return {
    formula,
    references: getRefsFromFormula(formula, row.name),
  };
}

export function getDefaultAutoDataSource({
  row,
  scenarioId,
  departmentId,
  departmentName,
  vendor,
  uiOptions,
}: {
  row: TemplateRow;
  scenarioId: EntityId;
  departmentId?: EntityId | null;
  departmentName: string | null;
  vendor?: string | null;
  uiOptions: NonNullable<Formula["ui"]>;
}): FormulaDatasource {
  const dimensions: Record<string, string> = {};
  if (vendor) dimensions.vendor = vendor;

  const start = uiOptions.type === "pre-built" ? null : "1f";

  return {
    id: id(),
    start,
    end: null,
    row_id: row.id,
    scenario_id: scenarioId.toString(),
    type: "formula",
    options: {
      ...getDefaultFormulaAndReferences(row, departmentName ?? null, vendor ?? null, uiOptions),
      ui: uiOptions,
    },
    department_id: departmentId?.toString() ?? null,
    dimensions,
  };
}

export function getDatasourceMappingForDatasources(state: RootState, datasources: Datasource[] | Datasource) {
  const mapping: Record<string, Datasource> = {};
  const datasourcesArray = Array.isArray(datasources) ? datasources : [datasources];
  for (const datasource of datasourcesArray) {
    const cacheKeys = getKeysCovered({state, datasource});
    for (const cacheKey of cacheKeys) {
      const dateKey = cacheKey.slice(-7);
      mapping[dateKey] = datasource;
    }
  }
  return mapping;
}

export function getReferencedEntitiesAndTags(datasource: Datasource): string[] {
  if (datasource.type !== "formula" && datasource.type !== "hiring-plan-formula") return [];

  const keys: string[] = [];

  if (datasource.options.references) {
    for (const ref of datasource.options.references) {
      if (ref.row) keys.push(`row:${ref.row}`);
      if (ref.department) keys.push(`department:${ref.department}`);
      if (ref.vendor) keys.push(`vendor:${ref.vendor}`);
      if (ref.tags) {
        for (const [tag, value] of Object.entries(ref.tags)) {
          keys.push(`${tag}:${value}`);
        }
      }
    }
  }

  return keys;
}

export type DatasourceDiff<T extends Datasource = Datasource> = {
  deletes: T[];
  cacheKeysUpdatedPerDsId: Record<EntityId, string[]>;
  cacheKeysRemovedPerDsId: Record<EntityId, string[]>;
  upserts: T[];
  upsertedPreviousVersions: T[];
  resolvedDates: {
    old: Record<EntityId, {start: string; end: string}>;
    new: Record<EntityId, {start: string; end: string}>;
  };
};

export function getEmptyDatasourceDiff<T extends Datasource = Datasource>(): DatasourceDiff<T> {
  return {
    cacheKeysRemovedPerDsId: {},
    cacheKeysUpdatedPerDsId: {},
    deletes: [],
    upserts: [],
    upsertedPreviousVersions: [],
    resolvedDates: {
      old: {},
      new: {},
    },
  };
}

export function getDatasourceDiff<D1 extends Datasource, D2 extends Datasource>(
  oldDatasources: D1[],
  newDatasources: D2[],
  state: RootState,
  newTemplateOptions?: TemplateOptions,
  oldTemplateOptions?: TemplateOptions,
  monthsToForceMarkAsChanged?: string[],
): DatasourceDiff<D1 | D2> {
  const datasourceDiff: DatasourceDiff<D1 | D2> = getEmptyDatasourceDiff<D1 | D2>();

  const upsertedIds: string[] = [];

  const datasourcesMapping = {
    oldById: Object.fromEntries(oldDatasources.map((ds) => [ds.id, ds])),
    newById: Object.fromEntries(newDatasources.map((ds) => [ds.id, ds])),
  };

  const cacheKeysMapping: Record<"old" | "new", Record<string, string[]>> = {
    old: {},
    new: {},
  };

  const datasourcesAreTheSameCache: Record<string, boolean> = {};

  const dsResolvedDates: Record<"old" | "new", Record<string, {start: string; end: string}>> = {
    old: {},
    new: {},
  };

  // Generate cacheKeysMapping
  for (const type of ["old", "new"] as const) {
    for (const datasource of type === "old" ? oldDatasources : newDatasources) {
      const {start, end} = resolveStartEndFromState(
        datasource,
        state,
        type === "new" ? newTemplateOptions : oldTemplateOptions,
      );

      datasourceDiff.resolvedDates[type][datasource.id] = {start, end};

      const cacheKeys = getKeysCovered({state, datasource, resolvedDates: {start, end}});
      dsResolvedDates[type][datasource.id] = {start, end};

      if (type === "old" && !datasourcesMapping.newById[datasource.id]) {
        // If the datasource is not in the new datasources, it was deleted
        if (!datasourceDiff.deletes) datasourceDiff.deletes = [];
        datasourceDiff.deletes.push(datasource);
      }

      for (const cacheKey of cacheKeys) {
        if (!cacheKeysMapping[type][cacheKey]) cacheKeysMapping[type][cacheKey] = [];
        cacheKeysMapping[type][cacheKey].push(datasource.id);
      }
    }
  }

  // Compare old and new cacheKeysMapping
  for (const cacheKey of Object.keys(cacheKeysMapping.old)) {
    for (const oldDatasourceId of cacheKeysMapping.old[cacheKey]) {
      const oldDatasourceForCacheKey = datasourcesMapping.oldById[oldDatasourceId];

      // If the datasource is not in the new datasources for that cache key, it was deleted
      const datasourceHasBeenRemovedForCacheKey = !cacheKeysMapping.new[cacheKey]?.includes(oldDatasourceId);
      if (datasourceHasBeenRemovedForCacheKey) {
        if (!datasourceDiff.cacheKeysRemovedPerDsId) datasourceDiff.cacheKeysRemovedPerDsId = {};
        if (!datasourceDiff.cacheKeysRemovedPerDsId[oldDatasourceId])
          datasourceDiff.cacheKeysRemovedPerDsId[oldDatasourceId] = [];
        datasourceDiff.cacheKeysRemovedPerDsId[oldDatasourceId].push(cacheKey);
        if (!datasourceDiff.upsertedPreviousVersions) datasourceDiff.upsertedPreviousVersions = [];
        if (!datasourceDiff.upsertedPreviousVersions.some((ds) => ds.id === oldDatasourceForCacheKey.id))
          datasourceDiff.upsertedPreviousVersions.push(oldDatasourceForCacheKey);
      } else {
        const matchingNewDatasource = datasourcesMapping.newById[oldDatasourceId];

        // Check if the datasource has changed for that dateKey (compare options and dates)
        datasourcesAreTheSameCache[oldDatasourceId] =
          datasourcesHaveSameOptions(oldDatasourceForCacheKey, matchingNewDatasource) &&
          dsResolvedDates.old[oldDatasourceId].start === dsResolvedDates.new[oldDatasourceId].start &&
          dsResolvedDates.old[oldDatasourceId].end === dsResolvedDates.new[oldDatasourceId].end;

        // If the datasource has changed, the cache keys need to be marked as upserted
        if (!datasourcesAreTheSameCache[oldDatasourceId]) {
          if (!datasourceDiff.cacheKeysUpdatedPerDsId) datasourceDiff.cacheKeysUpdatedPerDsId = {};
          if (!datasourceDiff.cacheKeysUpdatedPerDsId[oldDatasourceId])
            datasourceDiff.cacheKeysUpdatedPerDsId[oldDatasourceId] = [];
          datasourceDiff.cacheKeysUpdatedPerDsId[oldDatasourceId].push(cacheKey);
          // ========
          //   Notes: when editing just jan 2023 for instance(adding), it marks every single month as changed
          // But on second thought, it might make sense. Need to figure out then why the values are empty following a recalc for those cells (maybe the order of update / delete?)

          // Also mark the datasource as updated
          if (!upsertedIds.includes(oldDatasourceId)) {
            upsertedIds.push(oldDatasourceId);
            if (!datasourceDiff.upserts) datasourceDiff.upserts = [];
            if (!datasourceDiff.upsertedPreviousVersions) datasourceDiff.upsertedPreviousVersions = [];
            datasourceDiff.upserts.push(matchingNewDatasource);
            datasourceDiff.upsertedPreviousVersions.push(oldDatasourceForCacheKey);
          }
        }
      }
    }
  }

  // Compare new and old cacheKeysMapping
  for (const cacheKey of Object.keys(cacheKeysMapping.new)) {
    for (const newDatasourceId of cacheKeysMapping.new[cacheKey]) {
      if (!cacheKeysMapping.old[cacheKey]?.includes(newDatasourceId)) {
        const newDatasource = datasourcesMapping.newById[newDatasourceId];
        if (!datasourceDiff.cacheKeysUpdatedPerDsId) datasourceDiff.cacheKeysUpdatedPerDsId = {};
        if (!datasourceDiff.cacheKeysUpdatedPerDsId[newDatasourceId])
          datasourceDiff.cacheKeysUpdatedPerDsId[newDatasourceId] = [];
        datasourceDiff.cacheKeysUpdatedPerDsId[newDatasourceId].push(cacheKey);
        if (!upsertedIds.includes(newDatasourceId)) {
          upsertedIds.push(newDatasourceId);
          if (!datasourceDiff.upserts) datasourceDiff.upserts = [];
          datasourceDiff.upserts.push(newDatasource);
        }
      }
    }
  }

  if (monthsToForceMarkAsChanged?.length) {
    for (const ds of newDatasources) {
      for (const dateKey of monthsToForceMarkAsChanged) {
        if (!datasourceDiff.cacheKeysUpdatedPerDsId) datasourceDiff.cacheKeysUpdatedPerDsId = {};
        if (!datasourceDiff.cacheKeysUpdatedPerDsId[ds.id]) datasourceDiff.cacheKeysUpdatedPerDsId[ds.id] = [];
        const cacheKey = projKeyOmitNulls(
          ds.row_id,
          ds.department_id,
          ds.dimensions?.vendor?.toLowerCase(),
          ds.scenario_id,
          dateKey,
        );
        if (!datasourceDiff.cacheKeysUpdatedPerDsId[ds.id].includes(cacheKey)) {
          datasourceDiff.cacheKeysUpdatedPerDsId[ds.id].push(cacheKey);
        }

        if (!upsertedIds.includes(ds.id)) {
          upsertedIds.push(ds.id);
          if (!datasourceDiff.upserts) datasourceDiff.upserts = [];
          if (!datasourceDiff.upsertedPreviousVersions) datasourceDiff.upsertedPreviousVersions = [];
          datasourceDiff.upserts.push(ds);
          datasourceDiff.upsertedPreviousVersions.push(datasourcesMapping.oldById[ds.id]);
        }
      }
    }
  }

  return datasourceDiff;
}

type ExtractDatasourceTypeFromDiff<T> = T extends DatasourceDiff<infer T> ? T : never;
export function mergeDatasourceDiffObjects<
  Diff1 extends DatasourceDiff,
  Diff2 extends DatasourceDiff,
  T extends ExtractDatasourceTypeFromDiff<Diff1 | Diff2>,
>(currentDiffs: Diff1, diffToMerge: Diff2) {
  const mergedDiffs: DatasourceDiff = getEmptyDatasourceDiff<T>();

  if (currentDiffs.upserts) mergedDiffs.upserts = currentDiffs.upserts;
  if (currentDiffs.upsertedPreviousVersions)
    mergedDiffs.upsertedPreviousVersions = currentDiffs.upsertedPreviousVersions;
  if (currentDiffs.deletes) mergedDiffs.deletes = currentDiffs.deletes;
  if (currentDiffs.cacheKeysUpdatedPerDsId) mergedDiffs.cacheKeysUpdatedPerDsId = currentDiffs.cacheKeysUpdatedPerDsId;
  if (currentDiffs.cacheKeysRemovedPerDsId) mergedDiffs.cacheKeysRemovedPerDsId = currentDiffs.cacheKeysRemovedPerDsId;
  if (currentDiffs.resolvedDates) mergedDiffs.resolvedDates = currentDiffs.resolvedDates;

  // Merge resolved dates
  for (const type of ["old", "new"] as const) {
    for (const [dsId, dates] of Object.entries(diffToMerge.resolvedDates[type])) {
      if (!mergedDiffs.resolvedDates[type][dsId]) mergedDiffs.resolvedDates[type][dsId] = dates;
    }
  }

  // Merge upserts
  if (diffToMerge.upserts) {
    if (!mergedDiffs.upserts) mergedDiffs.upserts = [];
    for (const upsert of diffToMerge.upserts) {
      if (!mergedDiffs.upserts.some((ds) => ds.id === upsert.id)) mergedDiffs.upserts.push(upsert);
    }
  }

  // Merge upsertedPreviousVersions
  if (diffToMerge.upsertedPreviousVersions) {
    if (!mergedDiffs.upsertedPreviousVersions) mergedDiffs.upsertedPreviousVersions = [];
    for (const upsert of diffToMerge.upsertedPreviousVersions) {
      if (!mergedDiffs.upsertedPreviousVersions.some((ds) => ds.id === upsert.id))
        mergedDiffs.upsertedPreviousVersions.push(upsert);
    }
  }

  // Merge deletes
  if (diffToMerge.deletes) {
    if (!mergedDiffs.deletes) mergedDiffs.deletes = [];
    for (const del of diffToMerge.deletes) {
      if (!mergedDiffs.deletes.some((ds) => ds.id === del.id)) mergedDiffs.deletes.push(del);
    }
  }

  // Merge cacheKeysRemovedPerDsId
  if (diffToMerge.cacheKeysUpdatedPerDsId) {
    for (const [dsId, cacheKeys] of Object.entries(diffToMerge.cacheKeysUpdatedPerDsId)) {
      for (const cacheKey of cacheKeys) {
        if (mergedDiffs.cacheKeysRemovedPerDsId?.[dsId]?.includes(cacheKey)) {
          mergedDiffs.cacheKeysRemovedPerDsId[dsId] = mergedDiffs.cacheKeysRemovedPerDsId[dsId].filter(
            (key) => key !== cacheKey,
          );
        }
      }
    }
  }

  // Merge cacheKeysUpdatedPerDsId
  if (diffToMerge.cacheKeysUpdatedPerDsId) {
    if (!mergedDiffs.cacheKeysUpdatedPerDsId) mergedDiffs.cacheKeysUpdatedPerDsId = {};
    mergedDiffs.cacheKeysUpdatedPerDsId = {
      ...mergedDiffs.cacheKeysUpdatedPerDsId,
      ...diffToMerge.cacheKeysUpdatedPerDsId,
    };
  }

  // Merge cacheKeysRemovedPerDsId
  if (diffToMerge.cacheKeysRemovedPerDsId) {
    if (!mergedDiffs.cacheKeysRemovedPerDsId) mergedDiffs.cacheKeysRemovedPerDsId = {};
    mergedDiffs.cacheKeysRemovedPerDsId = {
      ...mergedDiffs.cacheKeysRemovedPerDsId,
      ...diffToMerge.cacheKeysRemovedPerDsId,
    };
  }

  return mergedDiffs;
}

export function mergeMultipleDatasourceDiffObjects(...diffs: DatasourceDiff[]) {
  let mergedDiffs = getEmptyDatasourceDiff();

  for (const diff of diffs) {
    mergedDiffs = mergeDatasourceDiffObjects(mergedDiffs, diff);
  }

  return mergedDiffs;
}

export function getDatasourceMappingFromDiff(datasourceDiff: DatasourceDiff) {
  // scenarioId -> rowId -> dateKey -> datasource
  const mapping: Record<EntityId, Record<EntityId, Record<string, Datasource>>> = {};
  const upsertsById = Object.fromEntries(datasourceDiff.upserts.map((ds) => [ds.id, ds]));
  for (const [dsId, cacheKeys] of Object.entries(datasourceDiff.cacheKeysUpdatedPerDsId)) {
    for (const cacheKey of cacheKeys) {
      const {dateKey, rowId, scenarioId} = parseCacheKey(cacheKey);

      if (!mapping[scenarioId]) mapping[scenarioId] = {};
      if (!mapping[scenarioId][rowId]) mapping[scenarioId][rowId] = {};

      mapping[scenarioId][rowId][dateKey] = upsertsById[dsId];
    }
  }
  return mapping;
}

export function getEmptyDatasource(
  rowId: EntityId,
  departmentId: EntityId | null | undefined,
  vendor: string | null | undefined,
  scenarioId: EntityId,
  dateKey: string,
): Datasource & {temp: true} {
  return {
    id: "__EMPTY_TEMP_DATASOURCE__",
    department_id: departmentId?.toString() ?? null,
    dimensions: vendor ? {vendor} : undefined,
    start: dateKey,
    end: dateKey,
    type: "formula",
    options: {
      formula: "",
    },
    row_id: rowId.toString(),
    scenario_id: scenarioId.toString(),
    integration_id: null,
    temp: true,
  };
}

export function getInsertAutoRangeDatasourceDiff(
  state: RootState,
  {
    row,
    scenarioId,
    uiOptions,
    templateOptions,
    departmentId,
    vendor,
  }: {
    row: TemplateRow;
    scenarioId: EntityId;
    uiOptions: NonNullable<Formula["ui"]>;
    templateOptions: TemplateOptions;
    departmentId?: EntityId | null;
    vendor?: string | null;
  },
) {
  if (row?.type !== "account" && row?.type !== "generic") return;
  const departmentName = departmentId ? state.departments.entities[departmentId]?.name ?? null : null;
  const updatedDatasource = getDefaultAutoDataSource({
    row,
    uiOptions,
    scenarioId,
    departmentId,
    departmentName,
    vendor,
  });
  updatedDatasource.options.ui = uiOptions;

  const existingDatasources = mapEntitiesToIds(
    state.datasources.entities,
    state.datasources.idsByProjKey[projKeyOmitNulls(row.id, scenarioId, departmentId, vendor)] ?? [],
  );

  const insertResult = insertDatasources({
    datasourcesToAdd: [updatedDatasource],
    forecastDates: templateOptions,
    existingDatasources,
  });

  const datasourceDiff = getDatasourceDiff(existingDatasources, insertResult.datasources, state);

  return datasourceDiff;
}

export type PartialDsError = Omit<DsError, "id">;
export function findFormulaDsErrors<T extends FormulaDatasource | HiringPlanFormulaDatasource>(
  datasource: T,
  rowIdByName: Record<string, string> | Dictionary<string>,
  departmentIdByName: Record<string, string> | Dictionary<string>,
): PartialDsError[] {
  const errors: PartialDsError[] = [];
  for (const ref of datasource.options.references ?? []) {
    if (ref.department && !departmentIdByName[ref.department.toLowerCase()]) {
      errors.push({
        created_at: new Date().toISOString(),
        datasource_id: datasource.id,
        type: "INVALID_REF",
        details: {
          refType: "department",
          invalidRefStr: ref.department,
        },
      });
    }
    if (ref.row && !rowIdByName[ref.row.split("!").at(-1)!.toLowerCase()]) {
      errors.push({
        created_at: new Date().toISOString(),
        datasource_id: datasource.id,
        type: "INVALID_REF",
        details: {
          refType: "row",
          invalidRefStr: ref.row,
        },
      });
    }
  }

  return errors;
}
