import {defaultMotionFadeIn} from "@app/styles/general-ui";
import {notificationModalPresets} from "@app/ui-state/presets";
import Button from "@components/Button/V2";
import useConfirmStyles from "@components/Confirm/styles.jss";
import RoundButton from "@components/RoundButton";
import Stepper from "@components/Stepper";
import clsx from "clsx";
import {lazy, Suspense} from "react";
import {createPortal} from "react-dom";

import useStyles from "./styles.jss";

import type {NotificationModalProps} from "@app/ui-state/slice";
import type {CSSProperties} from "react";

const AnimatePresence = lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));
const MotionDiv = lazy(async () => ({default: (await import("framer-motion")).motion.div}));

export default function NotificationModal(originalProps: NotificationModalProps) {
  // Preset is a Partial<NotificationModalProps> that can be passed in to set default values for the modal
  // Apply them here if they exist
  const preset = originalProps.preset ? notificationModalPresets[originalProps.preset] ?? {} : {};
  const props = {...preset, ...originalProps};

  const {
    backgroundImage,
    title,
    text,
    actionButtonText,
    actionButtonIcon,
    actionButtonIconSize,
    cancelButtonIcon,
    cancelButtonIconSize,
    onConfirm,
    onCancel,
    closeButton = true,
    cancelButton = true,
    confirmButton = true,
    progress,
    width,
    height,
    mainColor,
  } = props;

  const styles = useStyles({mainColor});
  const confirmStyles = useConfirmStyles({mainColor});
  const classes = clsx(styles.notificationModalMain);

  const domElement = document.querySelector("#root");

  if (!domElement) return null;

  const mainStyles: CSSProperties = {
    backgroundImage: `url('${backgroundImage}')`,
  };

  if (height) mainStyles.height = `${height}px`;
  if (width) mainStyles.width = `${width}px`;

  return createPortal(
    <div className={confirmStyles.backdrop}>
      <Suspense>
        <AnimatePresence>
          <MotionDiv {...defaultMotionFadeIn} className={classes} style={mainStyles}>
            {closeButton ? (
              <div className={styles.closeButton} onClick={onCancel}>
                <RoundButton enableCssStates onClick={onCancel} icon="cross" iconSize={22} white />
              </div>
            ) : null}
            <div className={styles.title}>{title}</div>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
            <div className={styles.actionButtons}>
              {progress ? <Stepper progress={progress} /> : null}
              {cancelButton ? (
                <Button
                  isLink
                  text="Cancel"
                  onClick={onCancel}
                  iconLeft={cancelButtonIcon}
                  iconLeftSize={cancelButtonIconSize}
                />
              ) : null}
              {confirmButton ? (
                <Button
                  color={mainColor}
                  text={actionButtonText}
                  iconLeft={actionButtonIcon}
                  iconLeftSize={actionButtonIconSize}
                  onClick={onConfirm}
                />
              ) : null}
            </div>
          </MotionDiv>
        </AnimatePresence>
      </Suspense>
    </div>,
    domElement,
  );
}
