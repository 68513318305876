import colors from "@app/styles/colors";
import color from "color";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  integrationsTabMain: {},
  transactionsTable: {},
  timePeriodRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    color: colors.text.lightBlue01,
    fontWeight: 700,
    fontSize: 12,
    paddingBottom: 25,

    "& > :first-child, & > :last-child": {
      flex: 1,
    },

    "& > :nth-child(2)": {
      padding: [0, 8],
    },
  },
  txTimePeriod: {
    maxWidth: 400,
  },
  integrationsFirstSection: {
    marginBottom: 25,
  },
  transactionsTabMain: {
    "& table": {
      lineHeight: 25,
      fontSize: 12,
      marginTop: 25,
      textAlign: "left",
      width: "100%",
      tableLayout: "auto",
    },

    "& th": {
      fontWeight: 700,
      color: colors.ui.darkBlue02,
      cursor: "pointer",
      userSelect: "none",

      "&:hover": {
        color: color(colors.ui.darkBlue02).darken(0.3).rgb().string(),
      },

      "& > div": {
        marginLeft: 4,
        marginTop: -1,
      },
    },

    "& td, th": {
      minWidth: 75,
      padding: 8,

      "&:first-child": {
        paddingLeft: 0,
      },

      "&:nth-child(2)": {
        textAlign: "right",
        paddingRight: 20,
      },
    },
  },
  transactionRow: {
    "& td": {
      fontWeight: 500,
    },
  },
  datePickersWrapper: {
    display: "flex",
    paddingTop: 20,
  },
  to: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.ui.lightBlue01,
    fontWeight: 700,
    padding: [0, 15, 0, 10],
    flex: 1,
  },
  creditDebitIconBase: {
    width: 18,
    height: 18,
    borderRadius: 9999,
    fontSize: 11,
    fontWeight: 500,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 6,
    userSelect: "none",
    cursor: "default",

    "&:hover": {
      color: colors.text.white,
    },

    "& > span": {
      marginTop: 2,
    },

    transition: ["all", "100ms", "ease-out"],
  },

  creditIcon: {
    extend: "creditDebitIconBase",
    color: colors.other.darkBlue01,
    background: colors.tertiary.lightBlue06,

    "&:hover": {
      background: colors.tertiary.blue,
    },
  },

  debitIcon: {
    extend: "creditDebitIconBase",
    color: colors.primary.gold,
    background: colors.primary.lightGold05,

    "&:hover": {
      background: colors.primary.gold,
    },
  },

  creditDebitPopover: {
    display: "inline",
  },

  expanded: {},

  // Cells
  cell: {},
  date: {
    "$expanded &": {width: 100},
  },
  amount: {
    textAlign: "right",
    whiteSpace: "nowrap",
    "$expanded &": {width: 100},

    "& >  div": {
      display: "inline",
    },
  },
  vendor: {
    display: "none",
    whiteSpace: "nowrap",

    "$expanded &": {
      display: "table-cell",
    },
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "50%",

    "$expanded &": {width: "initial"},
  },
  department: {
    display: "none",

    "$expanded &": {
      whiteSpace: "nowrap",
      display: "table-cell",
    },
  },
  rowName: {
    display: "none",

    "$expanded &": {
      whiteSpace: "nowrap",
      display: "table-cell",
    },
  },
  source: {
    display: "none",

    "$expanded &": {
      width: 100,
      display: "table-cell",
    },
  },
});
