import {logCacheFromObj} from "@shared/tests/test-utilities";
import chalk from "chalk";
import numeral from "numeral";
import stripColor from "strip-color";

import {NO_DEPARTMENT_NAME} from "../formula/formula-utilities";

import type {ScenariosState} from "@state/entity-adapters";
import type {RootState} from "@state/store";
import type {DependencyCache} from "./dependency-cache";

export function makeDependencyKeyHumanReadable(
  key: string,
  state: Pick<RootState, "departments" | "templateRows"> & {scenarios?: ScenariosState},
  color?: boolean,
  showBalanceAndTotal?: boolean,
) {
  let isBalance = false;
  let isTotal = false;

  if (key.endsWith("::total")) {
    key = key.slice(0, -7);
    isTotal = true;
  }
  if (key.endsWith("::balance")) {
    key = key.slice(0, -9);
    isBalance = true;
  }

  const exploded = key.split("::");
  // First item is the row id
  const valueRowId = exploded.shift();
  // Last item is the date key
  let valueDateKey = exploded.pop();
  // Next to last item is the scenario id
  const valueScenarioId = exploded.pop();

  // If we still have items left, it's the departmentId first and then maybe the vendor
  let valueDepartmentId: string | null = null;
  let valueVendor: string | null = null;
  if (exploded.length) valueDepartmentId = exploded.shift() ?? null;
  if (exploded.length) valueVendor = exploded.shift() ?? null;

  let rowName = valueRowId ? state.templateRows.entities[valueRowId]?.name : null;
  let valueScenario = state.scenarios?.entities[valueScenarioId || ""]?.name ?? "scenario";
  let departmentName = valueDepartmentId
    ? state.departments?.entities[valueDepartmentId || ""]?.name ?? NO_DEPARTMENT_NAME
    : null;
  if (color) {
    rowName = chalk.cyan(rowName);

    if (departmentName === NO_DEPARTMENT_NAME) {
      departmentName = chalk.italic.magentaBright(departmentName);
    } else {
      departmentName = departmentName ? chalk.magentaBright(departmentName) : null;
    }

    valueVendor = valueVendor ? chalk.yellow(valueVendor) : null;
    valueScenario = chalk.gray(valueScenario);
    valueDateKey = chalk.whiteBright(valueDateKey);
  }

  const parts = [rowName || "[unresolved_row]"];
  if (departmentName) parts.push(departmentName);
  if (valueVendor) parts.push(valueVendor);

  parts.push(valueScenario, valueDateKey || "dateKey");
  if (showBalanceAndTotal && isBalance) parts.push(color ? chalk.italic("balance") : "balance");
  if (showBalanceAndTotal && isTotal) parts.push(color ? chalk.italic("total") : "total");
  return parts.join("::");
}

export function logDependencyCache(
  cache: DependencyCache,
  state: Pick<RootState, "templateRows" | "scenarios" | "departments">,
  typeFilter?: "cellToDependencies" | "dependencyToCells" | "orderedCacheKeys",
  log?: boolean,
  rowNameFilter?: string | ((key: string) => boolean),
  color?: boolean,
) {
  const humanReadableDepCache: {
    dependencyToCells: Record<string, string[]>;
    cellToDependencies: Record<string, string[]>;
    orderedCacheKeys: string[];
  } = {dependencyToCells: {}, cellToDependencies: {}, orderedCacheKeys: []};
  for (const type of ["cellToDependencies", "dependencyToCells", "orderedCacheKeys"] as const) {
    if (typeFilter && typeFilter !== type) continue;
    const keys = type === "orderedCacheKeys" ? cache[type] : Object.keys(cache[type]);
    for (const key of keys) {
      const humanReadableKey = makeDependencyKeyHumanReadable(key, state, color, true);
      if (rowNameFilter) {
        const keyWithoutyColor = stripColor(humanReadableKey);
        if (typeof rowNameFilter === "string" && !keyWithoutyColor.includes(rowNameFilter)) continue;
        if (typeof rowNameFilter === "function" && !rowNameFilter(keyWithoutyColor)) continue;
      }
      if (type !== "orderedCacheKeys") {
        const values = cache[type][key];
        humanReadableDepCache[type][humanReadableKey] = [...values].map((key) =>
          makeDependencyKeyHumanReadable(key, state, color, true),
        );
      } else {
        humanReadableDepCache[type].push(humanReadableKey);
      }
    }
  }

  // eslint-disable-next-line
  const lines: string[] = [];
  if (log) {
    for (const type of ["cellToDependencies", "dependencyToCells", "orderedCacheKeys"] as const) {
      if (typeFilter && typeFilter !== type) continue;
      lines.push(`[DEPENDENCY CACHE: ${type}]`);
      const keys = type === "orderedCacheKeys" ? humanReadableDepCache[type] : Object.keys(humanReadableDepCache[type]);
      for (const key of keys) {
        if (type === "orderedCacheKeys") {
          lines.push(key);
        } else {
          const values = humanReadableDepCache[type][key];
          lines.push(`${key} (${values.length})`);
          for (const value of values) {
            lines.push(`  ${value}`);
          }
        }
      }
    }
    // eslint-disable-next-line no-console
    console.log(lines.join("\n"));
  }

  return humanReadableDepCache;
}

export function logDependenciesTreeForKey(
  cache: DependencyCache,
  key: string,
  state: RootState,
  color?: boolean,
  monthlyCache?: Record<string, number>,
  maxDepth?: number,
  returnString?: boolean,
) {
  const humanReadableValuesCache: Record<string, number> | null = monthlyCache
    ? (logCacheFromObj(monthlyCache, state, "returnObject") as Record<string, number> | null)
    : null;
  const humanReadableCache = logDependencyCache(cache, state, "cellToDependencies", false, undefined, color);
  const lines: string[] = [];

  function recursive(key: string, depth: number) {
    if (maxDepth && depth > maxDepth) return;
    const deps = humanReadableCache.cellToDependencies[key];
    if (!deps) return;
    for (const [i, dep] of deps.entries()) {
      const withoutColor = stripColor(dep);
      let prefix = "│ ".repeat(depth);
      let value =
        humanReadableValuesCache && humanReadableValuesCache[withoutColor]
          ? numeral(humanReadableValuesCache[withoutColor]).format("0,0.00")
          : numeral(0).format("0,0.00");
      if (color) {
        prefix = chalk.gray(prefix);
        value = chalk.cyan.italic(value);
      }
      lines.push(`${prefix}${dep}${monthlyCache ? `${chalk.gray(" -> ")}${value}` : ""}`);
      recursive(dep, depth + 1);
    }
  }
  let initialKeyWithColor: string | null = null;
  for (const cacheKey of Object.keys(humanReadableCache.cellToDependencies)) {
    const withoutColor = stripColor(cacheKey);
    if (key === withoutColor) initialKeyWithColor = cacheKey;
  }
  lines.push(
    `${initialKeyWithColor ?? key}${
      humanReadableValuesCache
        ? `${chalk.gray(" -> ")}${chalk.cyan.italic(numeral(humanReadableValuesCache[key]).format("0,0.00"))}`
        : ""
    }`,
  );
  if (initialKeyWithColor) recursive(initialKeyWithColor, 1);

  if (!returnString) {
    // eslint-disable-next-line no-console
    console.log(lines.join("\n"));
  } else {
    return lines.join("\n");
  }
}
