import {callWorkerFn} from "@app/worker";
import {applyDiffToMonthlyCache} from "@state/transaction-items/slice";

import type {MonthlyCacheDiff} from "@shared/data-functions/cache/cache-utilities";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {AppDispatch} from "@state/store";

export async function optimisticUpdate({
  scenarioId,
  dispatch,
  datasourceDiff,
}: {
  scenarioId: string;
  dispatch: AppDispatch;
  datasourceDiff: DatasourceDiff;
}): Promise<MonthlyCacheDiff> {
  const diff = await callWorkerFn("optimisticUpdateInWorker", [
    {
      scenarioId,
      datasourceDiff,
    },
  ]);

  if (diff) {
    const action = applyDiffToMonthlyCache({diff});
    dispatch(action);
  }

  return (
    diff ?? {
      updatedCacheKeys: {},
      deletedCacheKeys: [],
    }
  );
}
