import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  checkboxMain: {
    display: "flex",
    cursor: "pointer",
    lineHeight: 25,
    color: colors.text.darkBlue01,
    fontSize: 12,
    fontWeight: 500,
    userSelect: "none",

    "& svg": {
      "& path": {
        fill: "transparent",
      },
      "& path, & rect": {
        transition: "all ease 100ms",
      },
    },

    "&:hover $iconContainer": {
      "& svg": {
        "& rect": {
          fill: colors.primary.lightGold04,
          stroke: colors.primary.gold,
        },
        "& path": {
          // fill: colors.primary.gold,
        },
      },
    },

    "&:active $iconContainer": {
      transform: "scale(0.9)",
    },

    "&:active $iconContainer, &$checked $iconContainer": {
      "& svg": {
        "& rect": {
          fill: colors.primary.gold + "!important",
          stroke: colors.primary.gold + "!important",
        },
        "& path": {
          fill: colors.ui.white + "!important",
        },
      },
    },
  },
  iconContainer: {
    marginRight: 10,
    marginTop: 3,
    userSelect: "none",
    transition: "transform ease 65ms",
  },
  checked: {},
  disabled: {
    cursor: "default",
    opacity: 0.5,
    userSelect: "none",
    pointerEvents: "none",
  },
});
