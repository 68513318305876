import colors from "@app/styles/colors";
import {globalStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  tooltipMain: {
    background: colors.ui.white,
    minWidth: 350,
    maxWidth: 500,
    paddingTop: 10,
    fontFamily: globalStyles.text.fontFamily,
    fontSize: 12,
    fontWeight: 500,
    boxShadow: "0px 7px 30px 0px #00000024",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    overflow: "hidden",
    cursor: "pointer",
  },
  suggestionsContainer: {
    "&:not(:first-child)": {
      borderTop: `1px solid ${colors.ui.lightBlue05}`,
    },
  },
  tooltipItem: {
    display: "flex",
    height: 21,
    padding: [0, 20],
    alignItems: "center",
  },

  suggestion: {
    display: "flex",
    height: 35,
    padding: [0, 20],
    alignItems: "center",

    "&$formulaFunction": {
      color: colors.text.darkBlue01,

      "& $name": {
        textTransform: "uppercase",
        letterSpacing: "0.06em",
        fontSize: 12,
        lineHeight: 20,
      },
    },

    "& > $textAndIconContainer": {
      display: "inline-flex",
      flex: 1,
      // extend: getSvgColorStyles(colors.other.darkGreen02),
      paddingTop: 2,

      "& svg": {
        marginRight: 8,
        marginTop: -2,
      },
    },

    "& $rightText": {
      color: colors.text.lightBlue01,
      fontWeight: 500,
    },

    "&:last-child": {
      marginBottom: 10,
    },
  },

  hasSubtext: {},

  highlighted: {
    background: colors.secondary.lightGreen09,
    borderRight: `2px solid ${colors.other.darkGreen02}`,

    "&$hasSubtext": {
      height: 60,
    },

    "& $rightText": {
      marginRight: -2,
    },

    "& > $textAndIconContainer": {
      extend: getSvgColorStyles(colors.other.darkGreen02),

      "& $name": {
        color: colors.other.darkGreen02,
      },
    },
  },

  textAndIconContainer: {},
  rightText: {},
  name: {
    "$highlighted:not($formulaFunction) &": {
      fontWeight: 600,
    },
  },
  subtext: {
    marginTop: 5,
    color: colors.text.darkBlue01,

    "$formulaFunction &": {
      marginTop: 0,
    },
  },
  formulaFunction: {},

  formulaInputWrapper: {
    width: "100%",
    height: "100%",
  },
  borderTop: {
    borderTop: `1px solid ${colors.ui.lightBlue05}`,
    paddingTop: 10,
  },
  lastSuggestionOfType: {
    paddingBottom: 10,
  },
});
