import Tabs from "@components/Tabs";
import {useState} from "react";

import ActiveCell from "./ActiveCell";
import CacheKeyInspector from "./CacheKeyInspector";
import useStyles from "./MainDebugPanel.jss";
import RowDebugTools from "./RowDebugTools";
import RowsDebugTools from "./RowsDebugTools";

import type {Tab} from "@components/Tabs";

const TabsList: Tab[] = [
  {name: "ActiveCell", value: "ActiveCell"},
  {name: "CacheKeyInspector", value: "Cache Key Inspector"},
  {name: "Rows for Display", value: "Rows for Display"},
  {name: "Selected Row", value: "Selected Row"},
];

export default function DebugTabs({resolveIds}: {resolveIds: boolean}) {
  const [selectedTab, setSelectedTab] = useState(TabsList[0]);
  const styles = useStyles();

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
  };

  let tabComponent: JSX.Element | null = null;
  switch (selectedTab.name) {
    case "ActiveCell": {
      tabComponent = <ActiveCell resolveIds={resolveIds} />;
      break;
    }
    case "CacheKeyInspector": {
      tabComponent = <CacheKeyInspector resolveIds={resolveIds} />;
      break;
    }
    case "Rows for Display": {
      tabComponent = <RowsDebugTools resolveIds={resolveIds} />;
      break;
    }
    case "Selected Row": {
      tabComponent = <RowDebugTools resolveIds={resolveIds} />;
      break;
    }
  }

  return (
    <>
      <Tabs tabs={TabsList} selectedTab={selectedTab} onChange={handleTabChange} />
      <div className={styles.scrollable}>{tabComponent}</div>
    </>
  );
}
