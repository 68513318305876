import colors from "@app/styles/colors";
import {containerShadowStyles, minimalShadowStyles} from "@app/styles/general-ui";
import {createUseStyles} from "react-jss";

import type {PopoverGenericProps} from ".";

export interface PopoverStyleVars {
  width?: PopoverGenericProps["width"];
  position: PopoverGenericProps["position"];
}

export default createUseStyles({
  actualPopover: {
    zIndex: 5,
    color: colors.text.darkBlue02,
    fontFamily: "Mont",
    fontWeight: 600,
    fontSize: 12,
  },
  minimal: {},
  selectionDisabled: {
    userSelect: "none",
  },
  contentWrapper: {
    extend: containerShadowStyles,

    "$minimal &": {
      extend: minimalShadowStyles,
    },

    "& > div": {
      position: "relative",
      transformOrigin: [0, 0],
    },
    background: colors.ui.white,
    borderRadius: 5,
  },
});
