import {store} from "@app/client-store";
import {callWorkerFn} from "@app/worker";
import {getStateSizeStats} from "@shared/lib/misc";
import {formatNumber} from "@shared/lib/templates-utilities";
import {useEffect, useState} from "react";

import useStyles from "./styles.jss";

export interface StatsProps {}

export default function Companies({}: StatsProps) {
  const styles = useStyles();

  const state = store.getState();
  const [workerStateStats, setWorkerStateStats] = useState<ReturnType<typeof getStateSizeStats> | null>();

  const browserStateStats = getStateSizeStats(state);

  useEffect(() => {
    callWorkerFn("getStateSizeStats", []).then((stats) => {
      setWorkerStateStats(stats);
    });
  }, []);

  // A function to render a table for state size stats
  const renderTable = (stats: ReturnType<typeof getStateSizeStats>) => (
    <table className={styles.cacheStatsTable}>
      <thead>
        <tr>
          <th>Entity Type</th>
          <th>Number of Entries</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(stats).map(([entityType, numberOfEntries]) => (
          <tr key={entityType}>
            <td>{entityType}</td>
            <td>
              <strong>{formatNumber(numberOfEntries)}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className={styles.stats}>
      <h2>Main thread state size stats</h2>
      {renderTable(browserStateStats)}
      <br />
      <br />
      <h2>Worker thread state size stats</h2>
      {!workerStateStats ? <div>Loading...</div> : renderTable(workerStateStats)}
    </div>
  );
}
