import colors from "@app/styles/colors";
import {containerShadowStyles, globalStyles} from "@app/styles/general-ui";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  baseStyles: {
    fontFamily: globalStyles.text.fontFamily,
    fontSize: 12,
    lineHeight: 24,
  },
  floatingWrapper: {
    "&:active, &:focus, &:focus-visible": {
      outline: "none",
    },
  },
  basePopoverLayer: {
    "&:active, &:focus, &:focus-visible": {
      outline: "none",
    },

    "& > div": {
      extend: containerShadowStyles,

      width: "100%",
      backgroundColor: colors.ui.white,
      borderRadius: 5,
      boxSizing: "border-box",
      color: colors.text.darkBlue01,
      fontWeight: 500,
      outline: "none",

      "&:active, &:focus, &:focus-visible": {
        outline: "none",
      },
    },
  },
  isOpen: {},
});
