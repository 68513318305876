import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@shared/state/store";
import type {AppAlert} from "@shared/types/alerts";
import type {Datasource} from "@shared/types/datasources";
import type {Template, TemplateRow} from "@shared/types/db";

const endpoints = {
  fetchAllWithRows(_args, thunkAPI) {
    return api.get<{
      templates: Template[];
      templateRows: TemplateRow[];
      datasources: Datasource[];
      rowIdToVendorsMapping: Record<string, string[]>;
      alerts: AppAlert[];
    }>(thunkAPI, "templates", {includeRows: "true"});
  },
  upsert(template: Template, thunkAPI) {
    return api.put<Template>(thunkAPI, `templates/${template.id}`, template);
  },
} satisfies ApiEndpoints;

export default endpoints;
