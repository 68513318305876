import {wrap} from "comlink";

export const hasWorkerSupport = typeof Worker !== "undefined";

let actualWorkerSingleton: Worker | null;
let wrappedWorkerSingleton: ReturnType<typeof wrapWorkerThread> | null = null;

export function getActualWorker() {
  actualWorkerSingleton ||= (
    hasWorkerSupport ? new Worker(new URL("./worker-thread/index.ts", import.meta.url), {type: "module"}) : {}
  ) as Worker;

  return actualWorkerSingleton;
}

export function getWrappedWorker() {
  wrappedWorkerSingleton ||= wrapWorkerThread();
  return wrappedWorkerSingleton;
}

function wrapWorkerThread() {
  const actualWorker = getActualWorker();

  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  const wrapped = wrap<import("./worker-thread").WorkerFnWrapper>(actualWorker);
  return wrapped;
}
