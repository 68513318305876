import {getDatasourcesForMonth, getEmptyDatasource} from "@shared/lib/datasource-utilities";
import {DEBUG} from "@shared/lib/debug-provider";
import {projKey} from "@shared/state/utils";

import {calculateBalanceForDateKey, calculateTotalForDateKey, parseCacheKey} from "../cache/cache-utilities";
import {makeDependencyKeyHumanReadable} from "../cache/dependency-cache-debug-utilities";
import {getValueForLookupKey} from "../transactions";
import {setNewValueInDiffAndCache} from "./bubble-up-refresh-utilities";
import {flagDirtyCells} from "./flag-dirty-cells";
import {parseFormula} from "./processor";

import type {EntityId} from "@reduxjs/toolkit";
import type {WorkerOptions} from "@shared/../client/app/worker/call-worker";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {Datasource} from "@shared/types/datasources";
import type {CbTx} from "@shared/types/db";
import type {BasicStore, RootState} from "@state/store";
import type {ParsedCacheKey} from "../cache/cache-utilities";
import type {ParseFormulaReturnType} from "./processor";

export type BubbleUpRefreshReturnType = {
  upsertedTransactions: CbTx[];
  deletedTransactions: CbTx[];
  updatedCacheKeys: Record<string, number>;
  deletedCacheKeys: string[];
};

export type BaseBubbleUpRefreshParams = {
  storeInstance: BasicStore | RootState;
  recalcTriggerCells?: boolean;
  rowIdsWithDepartmentsToResolve?: EntityId[];
  executionId?: string;
  removedCacheKeysToRecompute?: string[];
  forOptimisticUpdate?: boolean;
};

export type BubbleUpRefreshForTags = {
  basedOn: "tags";
  tags: string[];
  dateKeys?: string[];
  scenarioId: EntityId;
};

export type BubbleUpRefreshParamsForRowIds = {
  basedOn: "rowIds";
  rowIds: string[];
  dateKeys?: string[];
  scenarioId: EntityId;
};

export type BubbleUpRefreshParamsForTxItemIds = {
  basedOn: "txItems";
  txItems: CbTx[];
};

export type BubbleUpRefreshParamsForCacheKeys = {
  basedOn: "datasourceDiff";
  datasourceDiff: DatasourceDiff;
};

export type BubbleUpRefreshParams = BaseBubbleUpRefreshParams &
  (
    | BubbleUpRefreshForTags
    | BubbleUpRefreshParamsForRowIds
    | BubbleUpRefreshParamsForTxItemIds
    | BubbleUpRefreshParamsForCacheKeys
  );

export function bubbleUpRefresh(
  payload: BubbleUpRefreshParams & {
    affectedRows: Record<string, Record<string, true>>;
  },
  workerOptions?: WorkerOptions,
): BubbleUpRefreshReturnType {
  const {
    recalcTriggerCells = false,
    affectedRows,
    storeInstance,
    removedCacheKeysToRecompute,
    forOptimisticUpdate,
  } = payload;

  const state = "getState" in storeInstance ? storeInstance.getState() : storeInstance;

  // logCacheFromObj(lastGeneratedMonthlyCache, state, "printData");
  const debugEnabled = DEBUG;
  // const debugEnabled = DEBUG || workerOptions?.DEBUG || process.env.NODE_ENV === "development";

  // Final list of scenario -> rowId -> datekeys for which we need to check for changes
  // const affectedRows = getAffectedRows(payload);

  const upsertedTransactions: CbTx[] = [];
  const deletedTransactions: CbTx[] = [];
  const diff = {
    updatedCacheKeys: {} as Record<string, number>,
    deletedCacheKeys: [] as string[],
  };
  const processedKeys: string[] = [];
  const processedKeysAsObj: Record<string, boolean> = {};
  const removedCacheKeysToRecomputeAsObj: Record<string, boolean> = Object.fromEntries(
    (removedCacheKeysToRecompute ?? []).map((key) => [key, true]),
  );
  for (const [scenarioId, affectedKeys] of Object.entries(affectedRows)) {
    const algoCacheForScenario = forOptimisticUpdate
      ? {...state.transactionItems.valuesByRowIdDateKey}
      : state.global.algoCache[scenarioId];
    // Actually, the dependencyCache should be up to date already at this point, so we can skip this step
    // if (DEBUG) time("BubbleUp", `Build dependency cache (scenario: ${scenarioId})`);
    // const dependencyCache = buildDependencyCache({scenarioId, state, rowIdsWithDepartmentsToResolve});
    // if (DEBUG) timeEnd("BubbleUp", `Build dependency cache (scenario: ${scenarioId})`);
    const dependencyCache = state.global.dependencyCache[scenarioId];

    const dirtyCells = flagDirtyCells({
      scenarioId,
      cacheKeys: Object.keys(affectedKeys),
      state,
      recalcTriggerCells,
      dependencyCache,
      limitToAffectedRows: forOptimisticUpdate,
    });

    if (removedCacheKeysToRecompute) {
      for (const cacheKey of removedCacheKeysToRecompute) {
        dirtyCells[cacheKey] = new Set();
      }
    }

    const dirtyCacheKeys = Object.keys(dirtyCells);
    const numberOfDirtyKeys = dirtyCacheKeys.length;
    let iterator = 0;
    let numberOfDirtyRowsChecked = 0;

    // logDirty(dirtyCells, state, false, null, "paypal_sales");
    // logDirty(dirtyCells, state, false, null, "partnership_revenue");

    // logDirtyCellsWithDependencies(state, dirtyCells, "rd_credit_card");

    // const readableFiltersToApply = ["revenue::scenario::2023-04", "aws::scenario::2023-04"];
    // const readableOrderedCacheKeys = dependencyCache.orderedCacheKeys
    //   .map((key) => makeDependencyKeyHumanReadable(key, state, false, true))
    //   .filter((key) => key === readableFiltersToApply[0] || key.includes(readableFiltersToApply[1]));
    // const readableAffectedKeys = Object.keys(affectedKeys)
    //   .map((key) => makeDependencyKeyHumanReadable(key, state, false, true))
    //   .filter((key) => key === readableFiltersToApply[0] || key.includes(readableFiltersToApply[1]));

    // console.log({readableOrderedCacheKeys, readableAffectedKeys});

    for (const cacheKey of [
      ...dependencyCache.orderedCacheKeys,
      ...Object.keys(affectedKeys),
      ...(removedCacheKeysToRecompute ?? []),
    ]) {
      if ((!dirtyCells[cacheKey] && !removedCacheKeysToRecomputeAsObj[cacheKey]) || processedKeysAsObj[cacheKey])
        continue;

      let forceEmptyFormula = false;
      if (removedCacheKeysToRecomputeAsObj[cacheKey]) forceEmptyFormula = true;

      if (forceEmptyFormula) {
        console.log("Forcing empty formula for cache key", cacheKey);
      }

      let components: ParsedCacheKey;
      if (dependencyCache.fullList[cacheKey]) {
        components = dependencyCache.fullList[cacheKey];
      } else {
        components = parseCacheKey(cacheKey);
      }
      const {rowId, departmentId, vendor, dateKey, total, balance} = components;

      if (iterator >= dirtyCacheKeys.length) iterator = 0;

      // Keep track of the existing value for this cache key so we can compare it to the new value to see if it changed
      const existingValueForDateKey = algoCacheForScenario[cacheKey];

      if (total && !forceEmptyFormula) {
        const newTotal = calculateTotalForDateKey(
          state,
          algoCacheForScenario,
          rowId,
          departmentId,
          scenarioId,
          dateKey,
          balance === true,
        );

        setNewValueInDiffAndCache(cacheKey, newTotal, existingValueForDateKey, diff, algoCacheForScenario);
      } else if (balance && !forceEmptyFormula) {
        const newBalance = calculateBalanceForDateKey(
          algoCacheForScenario,
          rowId,
          departmentId,
          vendor,
          scenarioId,
          dateKey,
        );

        setNewValueInDiffAndCache(cacheKey, newBalance, existingValueForDateKey, diff, algoCacheForScenario);
      } else {
        const row = state.templateRows.entities[rowId];
        if (!row) continue;

        const template = state.templates.entities[row.template_id];
        if (!template) continue;

        let templateOptions = template.options;

        let datasources: Datasource[] = forceEmptyFormula
          ? [getEmptyDatasource(rowId, departmentId, vendor, scenarioId, dateKey)]
          : getDatasourcesForMonth(state.datasources, components, templateOptions);

        // If it's not a balance or total and no datasources can be found, it's likely a row forecasted on a dept / vendor level
        // The value should be the sum of all its transactions
        // TODO: what will that look like when a department is globally selected? As it is now it would grab all transactions as it should, but the updated cache would now not match the globally selected department
        if (!datasources?.length) {
          const newValue = getValueForLookupKey(
            row,
            state.departments,
            projKey(row.mirror_of ?? rowId, scenarioId, dateKey),
            state.cbTx,
            departmentId,
            vendor,
          );

          setNewValueInDiffAndCache(cacheKey, newValue, existingValueForDateKey, diff, algoCacheForScenario);
        } else {
          if (datasources.length > 1) {
            if (datasources[0].type === "hiring-plan-formula") {
              // If it's a hiring plan formula, temporarily just use the last datasource in the month
              // (in this case the raise) until we can properly prorate salaries
              const lastDatasource = datasources.at(-1);
              if (lastDatasource) datasources = [lastDatasource];
            } else {
              console.log("WARNING: more than one datasource found for the same cell!", {
                cacheKey,
                readableKey: makeDependencyKeyHumanReadable(cacheKey, state, false, true),
                datasources,
                // formulas: datasources.map((ds) => ds.options.formula),
              });
            }
          }
          for (const datasource of datasources) {
            if (datasource.type !== "formula" && datasource.type !== "hiring-plan-formula") continue;

            const result: ParseFormulaReturnType = parseFormula(
              state,
              row,
              {[dateKey]: datasource},
              scenarioId,
              algoCacheForScenario,
              !!forOptimisticUpdate,
            );

            // Update upToDateStatesForParseFormula with the latest values returned
            upsertedTransactions.push(...result.upsertedTransactions);
            deletedTransactions.push(...result.deletedTransactions);

            // Keep track of which cache keys were updated by parseFormula
            diff.deletedCacheKeys.push(...result.deletedCacheKeys);
            for (const [key, value] of Object.entries(result.updatedCacheKeys)) {
              diff.updatedCacheKeys[key] = value;
            }
            // Remove the cache keys that were deleted from the updatedCacheKeys to avoid overwriting them
            for (const key of result.deletedCacheKeys) {
              if (diff.updatedCacheKeys[key]) delete diff.updatedCacheKeys[key];
            }
          }
        }
        // dirtyCacheKeys.splice(iterator, 1);

        // dirty[cacheKey] = null;
        processedKeys.push(cacheKey);
        processedKeysAsObj[cacheKey] = true;
      }
      // else {
      //   if (false && numberOfDirtyRowsChecked < 1000)
      //     console.log(
      //       `❌ Dirty cell ${templateRowsState.entities[rowId]?.name} for ${dateKey} has dirty dependencies. Skipping for now.`,
      //     );
      // }
      // numberOfDirtyRowsChecked++;
      // if (++iterator >= dirtyCacheKeys.length) iterator = 0;
    }

    // eslint-disable-next-line no-console
    if (debugEnabled)
      console.log(`ℹ️ Done recalcing ${numberOfDirtyKeys} dirty keys (${numberOfDirtyRowsChecked} iterations)`);
  }
  const returnedPartialStateMutations: BubbleUpRefreshReturnType = {
    upsertedTransactions,
    deletedTransactions,
    updatedCacheKeys: diff.updatedCacheKeys,
    deletedCacheKeys: diff.deletedCacheKeys,
  };
  // console.log(
  //   processedKeys
  //     // .slice(0, 30)
  //     .map((key) => makeDependencyKeyHumanReadable(key, state, true, true))
  //     .filter((key) => key.includes("wages::re") && key.includes("2023-02"))
  //     .join("\n"),
  // );

  return returnedPartialStateMutations;
}
