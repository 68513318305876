import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@shared/state/store";
import type {Datasource} from "@shared/types/datasources";
import type {CbTx, Integration, IntegrationDataQboStatementRow} from "@shared/types/db";

export type SyncReturnType = {
  updatedIntegration: Integration;
  upserts: CbTx[];
  deletes: string[];
};

const endpoints = {
  fetchAll(_args, thunkAPI) {
    return api.get<Integration[]>(thunkAPI, "integrations");
  },
  fetchQboStatementRows({integrationId}: {integrationId: string}, thunkAPI) {
    return api.get<IntegrationDataQboStatementRow[]>(thunkAPI, `integrations/${integrationId}/qbo-statement-rows`);
  },
  sync(
    {
      id,
      initial,
      type,
      from,
      to,
    }: {id: string; initial?: boolean; type: "accounting" | "datawarehouse"; from?: string; to?: string},
    thunkAPI,
  ) {
    return api.get<SyncReturnType>(
      thunkAPI,
      `integrations/${type}/${id}/pull?${initial ? "initialSync=true" : ""}${from && to ? "&from=" + from : ""}${
        from && to ? "&to=" + to : ""
      }`,
    );
  },
  syncForRow(
    {
      id,
      initial,
      type,
      rowId,
      datasource,
    }: {
      id: string;
      initial?: boolean;
      type: "accounting" | "datawarehouse";
      rowId: string;
      datasource: Datasource;
    },
    thunkAPI,
  ) {
    return api.put<SyncReturnType>(
      thunkAPI,
      `integrations/${type}/${id}/sync-for-row/${rowId}${initial ? "?initialSync=true" : ""}`,
      {datasource},
    );
  },
  reconnect(id: string, thunkAPI) {
    return api.get<void>(thunkAPI, `connect/intuit/${id}`);
  },
  disconnect(id: string, thunkAPI) {
    return api.del<void>(thunkAPI, `connect/intuit/${id}`);
  },
  upsert(integration: Integration, thunkAPI) {
    return api.put<Integration>(
      thunkAPI,
      `integrations/${integration.id === "snowflake" ? "datawarehouse" : "accounting"}/${integration.id}`,
      integration,
    );
  },
} satisfies ApiEndpoints;

export default endpoints;
