import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import Button from "@components/Button/V2";
import Section from "@components/RightSidebar/Section";
import {getDatasourceDiff} from "@shared/lib/datasource-utilities";
import {addNewFcDatasource, getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import {selectEmployeeFcDatasourcesSorted} from "@state/datasources/selectors";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {selectFinancialComponentById} from "@state/financial-components/slice";
import {selectScenarioId, selectVersionLocked} from "@state/global/slice";
import {selectTemplateByName} from "@state/templates/selectors";

import {getSummary} from "../utilities";
import useStyles from "./CompensationAndTaxes.jss";
import CompensationRange from "./CompensationRange";

import type {HiringPlanTemplateRow} from "@shared/types/db";
import type {Employee, Team} from "@shared/types/hiring-plan";

export default function CompensationItem({
  fcRow,
  termDate,
  employee,
  team,
}: {
  fcRow: HiringPlanTemplateRow;
  termDate: string | null;
  employee: Employee;
  team: Team;
}) {
  const styles = useStyles();
  const isVersionLocked = useAppSelector(selectVersionLocked);
  const dispatch = useAppDispatch();
  const scenarioId = useAppSelector(selectScenarioId);
  const datasources = useAppSelector((state) =>
    selectEmployeeFcDatasourcesSorted(state, {employeeId: employee.id, fcName: fcRow.options.fc_name, scenarioId}),
  );
  const hiringPlanTemplate = useAppSelector((state) => selectTemplateByName(state, "hiring_plan"));

  const financialComponent = useAppSelector((state) => selectFinancialComponentById(state, fcRow.options.fc_name));

  if (!financialComponent || !hiringPlanTemplate) return null;

  // console.log(datasources);

  const handleAddFutureChangeClick = () => {
    if (!scenarioId) return;
    const newDatasources = addNewFcDatasource(
      fcRow.id,
      scenarioId,
      team.department_id ?? null,
      employee.id,
      datasources,
      employee.scenario_properties[scenarioId ?? ""].hire_date || "",
      employee.scenario_properties[scenarioId ?? ""].term_date,
      hiringPlanTemplate.options,
      financialComponent,
    );

    const state = store.getState();
    const datasourceDiff = getDatasourceDiff(datasources, newDatasources, state);
    // console.log({newDatasources, datasourceDiff});
    dispatch(
      applyDatasourceChanges({
        datasourceDiff,
        reason: `Add ${financialComponent.display_name} change for ${getEmployeeDisplayName(employee)}}`,
      }),
    );
  };

  return (
    <Section
      key={fcRow.id}
      title={financialComponent.display_name || ""}
      summary={getSummary(datasources, financialComponent.type)}
      wrapContent={false}
      collapsed={financialComponent.type !== "salary"}
    >
      {datasources.length ? (
        datasources
          .sort((a, b) => (a.start || "0").localeCompare(b.start || "0"))
          .map((range, i) => (
            <CompensationRange key={i} fcRow={fcRow} datasource={range} rangeIndex={i} employee={employee} />
          ))
      ) : (
        <div className={styles.noCompensationRange}>
          Compensation &ldquo;{financialComponent.display_name}&rdquo; is empty in this scenario (no value for any
          period of time).
        </div>
      )}
      {!isVersionLocked ? (
        <div className={styles.newCompRangeWrapper}>
          <Button
            text="New"
            iconLeft="plus"
            color={"orange"}
            onClick={handleAddFutureChangeClick}
            disabled={
              datasources.sort((a, b) => (a.start || "0000-00-00").localeCompare(b.start || "0000-00-00")).at(-1)
                ?.start === (termDate || hiringPlanTemplate.options.end)
            }
          />
        </div>
      ) : null}
    </Section>
  );
}
