import {useAppSelector} from "@app/hooks";
import Checkbox from "@components/Checkbox";
import SVGIcon from "@components/SVGIcon";
import {getPrettyPrintedSelectedFormulaHtml} from "@features/shared-views/FormulaTextEditor/pretty-print-provider";
import {default as useFormulaStyles} from "@features/shared-views/FormulaTextEditor/styles.jss";
import {resolveDatasourceDates} from "@shared/lib/date-utilities";
import {selectIntegrationEntities} from "@state/integrations/slice";
import {useState} from "react";

import {selectDatasourcesForSelectedRow, selectSelectedTemplate} from "../state/selectors";
import useStyles from "./Datasources.jss";
import {debugHighlightColors} from "./shared";

import type {IconNames} from "@app/IconNames";
import type {Datasource} from "@shared/types/datasources";

export default function DatasourceDebugTools({resolveIds}: {resolveIds: boolean}) {
  const datasources = useAppSelector(selectDatasourcesForSelectedRow);
  const [highlightDatasources, setHighlightDatasources] = useState(false);
  const styles = useStyles();

  return (
    <>
      <h2 className={styles.title}>
        Datasources
        <Checkbox
          checked={highlightDatasources}
          onClick={() => setHighlightDatasources(!highlightDatasources)}
          text="Highlight datasources"
        />
      </h2>
      <div className={styles.datasourcesDebugToolsMain}>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Start</th>
              <th>End</th>
              <th>Formula / settings</th>
              <th>Formula Builder</th>
            </tr>
          </thead>
          <tbody>
            {datasources
              .toSorted((a, b) => (a.start ?? "000-00-00").localeCompare(b.start ?? "000-00-00"))
              .map((datasource, index) => (
                <DatasourceRow
                  key={datasource.id}
                  datasource={datasource}
                  index={index}
                  highlight={highlightDatasources}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function DatasourceRow({datasource, index, highlight}: {datasource: Datasource; index: number; highlight?: boolean}) {
  const generatedWithFormulaBuilder = datasource.type === "formula" && datasource.options.ui?.type === "auto";
  const formulaStyles = useFormulaStyles();
  const styles = useStyles();

  const template = useAppSelector(selectSelectedTemplate);

  const integrations = useAppSelector(selectIntegrationEntities);

  if (!template) return null;

  let integrationIcon: null | IconNames = null;
  if (datasource.type === "integration") {
    const integration = integrations[datasource.integration_id];
    if (integration) {
      integrationIcon = integration.provider === "quickbooks-online" ? "qbLogo" : "snowflake";
    }
  }

  const prettyPrintedFormula = getPrettyPrintedSelectedFormulaHtml({styles: formulaStyles});

  const resolvedDates = resolveDatasourceDates(
    datasource.start,
    datasource.end,
    template.options.start,
    template.options.end,
    template.options.lastMonthOfActuals,
  );

  return (
    <tr
      style={{
        backgroundColor: highlight ? debugHighlightColors[index % debugHighlightColors.length].bg : "transparent",
      }}
    >
      <td>
        <div className={styles.typeCell}>
          {highlight && (
            <div
              className={styles.colorCircle}
              style={{
                backgroundColor: debugHighlightColors[index % debugHighlightColors.length].border,
              }}
            />
          )}
          {datasource.type}&nbsp;{integrationIcon && <SVGIcon name={integrationIcon} />}
        </div>
      </td>
      <td className={styles.dateCell}>
        <span>{datasource.start ?? "null"}</span>
        <span>({resolvedDates.start})</span>
      </td>
      <td className={styles.dateCell}>
        <span>{datasource.end ?? "null"}</span>
        <span>({resolvedDates.end})</span>
      </td>
      <td>
        {datasource.type === "integration" ? (
          JSON.stringify(datasource.options)
        ) : (
          <span dangerouslySetInnerHTML={{__html: prettyPrintedFormula}} />
        )}
      </td>
      <td>{generatedWithFormulaBuilder ? "Yes" : ""}</td>
    </tr>
  );
}
