import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "@shared/state/store";
import type {TemplateOrdering} from "@shared/types/db";

export const selectTemplateByName = createSelector(
  (state: RootState) => state.templates,
  (_: RootState, name: string) => name,
  (templatesState, name) => Object.values(templatesState.entities)?.find((template) => template?.name === name) || null,
);

export type RowTypeForUI = "values" | "total" | "header" | "sanityCheckSource" | "sanityCheckDiff";

export type FlattenedOrderingItem = {
  rowId: string;
  hasChildren: boolean;
  depth: number;
  total: boolean;
  type: RowTypeForUI;
};

export const selectFlatTemplateOrdering = createSelector(
  (state: RootState) => state.templates,
  (_state: RootState, templateId: string) => templateId,
  (templatesState, templateId) => {
    const template = templatesState.entities[templateId];
    if (!template) return [];

    const flattenedRows: FlattenedOrderingItem[] = [];

    const processedRowIds: Set<string> = new Set();
    const processedTotalRowIds: Set<string> = new Set();

    function recursive(ordering: TemplateOrdering[], depth: number): boolean {
      let localHasValues = false;
      for (const orderingItem of ordering) {
        let {rowId, children} = orderingItem;

        if (processedRowIds.has(rowId)) {
          continue;
        }

        processedRowIds.add(rowId);
        flattenedRows.push({rowId, hasChildren: !!children?.length, depth, total: false, type: "values"});

        // Then add all children or generate blue rows if needed
        if (children?.length) {
          recursive(children, depth + 1);
        }

        // Add the total row if needed
        if (!processedTotalRowIds.has(rowId) && children?.length) {
          flattenedRows.push({rowId, hasChildren: true, depth, total: true, type: "total"});
          processedTotalRowIds.add(rowId);
        }
      }
      return localHasValues;
    }
    recursive(template.ordering, 0);

    return flattenedRows;
  },
);
