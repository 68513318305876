import {createSlice} from "@reduxjs/toolkit";
import id from "@shared/lib/id";
import {generateClientSelectors} from "@shared/lib/misc";
import {setAutoFreeze} from "immer";

import type {ClientRootState, ClientThunkAction} from "@app/client-store";
import type {ActionCreator, PayloadAction} from "@reduxjs/toolkit";
import type {IconNames} from "client/app/IconNames";
import type {ReactElement} from "react";
import type {confirmPresets, headsUpNotifPresets, notificationModalPresets} from "./presets";

setAutoFreeze(false);

export interface ConfirmBaseProps {
  title?: string;
  text: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  backgroundImage?: string;
  mainColor?: "red" | "orange" | "blue" | "green";
  actionButtonText?: string;
  cancelButton?: boolean;
  confirmButton?: boolean | ReactElement;
  closeButton?: boolean;
  actionButtonIcon?: IconNames;
  cancelButtonIcon?: IconNames;
  actionButtonIconSize?: number;
  cancelButtonIconSize?: number;
  closeOnConfirm?: boolean;
}

export interface HeadsUpNotifProps {
  id: string;
  title?: string;
  text: string;
  backgroundImage?: string;
  mainColor?: "red" | "orange" | "blue" | "green";
  preset?: keyof typeof headsUpNotifPresets;
}

export interface ConfirmProps extends ConfirmBaseProps {
  preset?: keyof typeof confirmPresets;
}

export interface NotificationModalProps extends ConfirmBaseProps {
  preset?: keyof typeof notificationModalPresets;
  width?: number;
  height?: number;
  progress?: null | {
    currentStep: number;
    totalSteps: number;
  };
}

export interface UiState {
  sidebarOpen: boolean;
  headsUpNotifications: HeadsUpNotifProps[];
  actionConfirmation: ConfirmProps | null;
  notification: NotificationModalProps | null;
  saveVersionModalOpen: boolean;
}

const initialState: UiState = {
  sidebarOpen: true,
  headsUpNotifications: [],
  actionConfirmation: null,
  notification: null,
  saveVersionModalOpen: false,
};

export const getSlice = () => {
  return createSlice({
    name: "uiState",
    initialState,
    reducers: {
      updateUiState: (state, action: PayloadAction<Partial<UiState>>) => ({
        ...state,
        ...action.payload,
      }),
      toggle: (state) => {
        state.sidebarOpen = !state.sidebarOpen;
      },
      askForConfirmation: (state, action: PayloadAction<ConfirmProps | null>) => {
        state.actionConfirmation = action.payload;
      },
      removeHeadsUpNotification: (state, action: PayloadAction<string>) => {
        state.headsUpNotifications = state.headsUpNotifications.filter((item) => item.id !== action.payload);
      },
      setNotificationContent: (state, action: PayloadAction<NotificationModalProps | null>) => {
        state.notification = action.payload;
      },
      setActionConfirmation: (state, action: PayloadAction<ConfirmProps | null>) => {
        state.actionConfirmation = action.payload;
      },
    },
    extraReducers: (builder) => {},
  });
};

const _slice = getSlice();

export const addHeadsUpNotification: ActionCreator<ClientThunkAction> =
  (notification: Omit<HeadsUpNotifProps, "id"> & {id?: string}) => async (dispatch, getState) => {
    const state = getState();
    const notifWithId = {...notification, id: notification.id ?? id()};
    const newHeadsUpNotificationsList = [...state.uiState.headsUpNotifications, notifWithId];
    dispatch(updateUiState({headsUpNotifications: newHeadsUpNotificationsList}));
    setTimeout(() => {
      dispatch(removeHeadsUpNotification(notifWithId.id));
    }, 5000);
  };

export const {
  toggle,
  setNotificationContent,
  askForConfirmation,
  removeHeadsUpNotification,
  updateUiState,
  setActionConfirmation,
} = _slice.actions;

export const selectors = generateClientSelectors("uiState", _slice.getInitialState());

export const selectSidebarOpen = (state: ClientRootState) => state.uiState.sidebarOpen;

export default _slice.reducer;
