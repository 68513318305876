import {createEffectHandler} from "../listeners-list";
import {api, teamDefaultMappingsUpdated, teamDefaultValueUpserted, teamDeleted, teamUpdated} from "./slice";

export const syncTeamUpsertsWithAPI = createEffectHandler(
  [teamUpdated, teamDefaultValueUpserted, teamDefaultMappingsUpdated],
  (action, {getState, dispatch}) => {
    const team = getState().teams.entities[action.payload.id];
    if (team) dispatch(api.upsert(team));
  },
);

export const syncTeamDeletesWithAPI = createEffectHandler(teamDeleted, (action, {getState, dispatch}) => {
  dispatch(api.delete(action.payload));
});
