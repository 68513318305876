import {store} from "@app/client-store";
import {isBrowser} from "browser-or-node";

import type {HumanReadableMonthlyCache} from "@shared/types/misc";

if (isBrowser) {
  window.getMonthlyCache = () => {
    const state = store.getState();

    const monthlyCache: HumanReadableMonthlyCache = {};

    for (const [key, value] of Object.entries(state.transactionItems.valuesByRowIdDateKey)) {
      const [rowId, scenarioId, dateKey, ...rest] = key.split("::");
      const rowName = state.templateRows.entities[rowId]?.name || "ROW_NOT_FOUND";
      const scenarioName = state.scenarios.entities[scenarioId]?.name || "SCENARIO_NOT_FOUND";

      monthlyCache[scenarioName] ||= {};
      monthlyCache[scenarioName][rowName] ||= {};
      monthlyCache[scenarioName][rowName][dateKey] ||= {};

      if (!rest.length) monthlyCache[scenarioName][rowName][dateKey].value = value;
      if (rest[0] === "total") monthlyCache[scenarioName][rowName][dateKey].total = value;
      if (rest[0] === "balance") monthlyCache[scenarioName][rowName][dateKey].balance = value;
      if (rest[0] === "total" && rest[1] === "balance")
        monthlyCache[scenarioName][rowName][dateKey].balanceTotal = value;
    }
    return monthlyCache;
  };
}

declare global {
  // eslint-disable-next-line no-var
  var setDebug: (enabled?: boolean) => void;
  // eslint-disable-next-line no-var
  var setTiming: (enabled?: boolean) => void;
  // eslint-disable-next-line no-var
  var getMonthlyCache: (options?: {scenario?: string; template?: string}) => HumanReadableMonthlyCache;
}
