import {makeDependencyKeyHumanReadable} from "@shared/data-functions/cache/dependency-cache-debug-utilities";
import {getRefsFromFormula} from "@shared/data-functions/formula/formula-utilities";
import {getDatasourceDiff} from "@shared/lib/datasource-utilities";
import id from "@shared/lib/id";
import {getUniqueSlug} from "@shared/lib/slug";
import {generateMonthlyCache} from "@shared/state/transaction-items/slice";
import {upsertCbTxLocal} from "@state/cb-tx/slice";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {upsertRowsLocal} from "@state/template-rows/slice";
import dayjs from "dayjs";

import type {AppReduxStore, RootState} from "@shared/state/store";
import type {Datasource} from "@shared/types/datasources";
import type {CbTx, GenericTemplateRow, Template, TemplateRow} from "@shared/types/db";

export function getRandomTx(
  rowId: string,
  scenarioId: string,
  dateKey = "2021-01",
  amountType: "credit" | "debit" | null = "credit",
  amount = 67,
) {
  return {
    id: id(),
    row_id: rowId,
    scenario_id: scenarioId,
    name: "Manually added transaction",
    description: "Manually added transaction",
    tx_type: "accounting",
    amount_type: amountType,
    amount,
    tags: {},
    date: dayjs(dateKey, "YYYY-MM").format("YYYY-MM-DD"),
  } as CbTx;
}

type AddTestRowOptions = {
  template?: Template;
  templateId?: string;
  formulaRange?: {
    from: string;
    to: string;
    formula: string | null;
  };
  tags?: Record<string, string>;
};
export function addTestRow(store: AppReduxStore, options: AddTestRowOptions = {}) {
  let state = store.getState();
  const scenarioId = state.scenarios.ids[0] as string;

  let template =
    options.template ||
    Object.values(state.templates.entities).find((item) =>
      options.templateId ? item?.id === options.templateId : item?.name === "profit_and_loss",
    );
  if (!template) template = Object.values(state.templates.entities).find((item) => item?.name === "profit_and_loss");
  const row: GenericTemplateRow = {
    id: id(),
    type: "generic",
    name: getUniqueSlug("Test row", Object.keys(state.templateRows.idsByName)).slug,
    display_name: "Test row",
    template_id: template!.id,
    formatting: {},
    options: {},
    tags: {},
  };

  if (options.tags) row.tags = options.tags;

  store.dispatch(upsertRowsLocal([row]));

  if (options.formulaRange) {
    // Create dateranges and insert
    const formula = options.formulaRange.formula;
    if (formula) {
      const datasource: Datasource = {
        id: id(),
        department_id: null,
        end: options.formulaRange.to,
        start: options.formulaRange.from,
        row_id: row.id,
        scenario_id: scenarioId,
        type: "formula",
        options: {
          formula,
          references: getRefsFromFormula(formula, row.name),
        },
      };

      const datasourceDiff = getDatasourceDiff([], [datasource], state);

      if (formula) {
        store.dispatch(applyDatasourceChanges({datasourceDiff, reason: `Added test row ${row.name}`}));
      }
    }
  }

  return row;
}

export function logCache(store: AppReduxStore, filters?: {dateKeys?: string[]; rowNames?: string[]}) {
  if (!store) return;

  const state = store.getState();

  logCacheFromObj(state.transactionItems.valuesByRowIdDateKey, state, "printData", filters);
}

export function logCacheFromObj(
  valuesByRowIdDateKey: Record<string, number>,
  state: RootState,
  mode: "printData" | "returnData" | "returnObject",
  filters?: {dateKeys?: string[]; rowNames?: string[]},
) {
  const copy = {...valuesByRowIdDateKey};

  const keys = Object.keys(copy).sort();

  const lines: string[] = [];

  for (const key of keys) {
    const total = key.includes("::total");
    const balance = key.includes("::balance");
    const rowId = key.split("::")[0];
    const dateKey = key.split("::").at(-1)!;

    const rowName = state.templateRows.entities[rowId]?.name || "ROW_NOT_FOUND";
    if (filters) {
      if (filters.dateKeys && !filters.dateKeys.includes(dateKey)) continue;
      if (filters.rowNames && !filters.rowNames.includes(rowName)) continue;
    }

    let humanReadableKey = makeDependencyKeyHumanReadable(
      key.replace("::balance", "").replace("::total", ""),
      state,
      false,
      true,
    );

    if (balance) humanReadableKey += "::balance";
    if (total) humanReadableKey += "::total";

    lines.push(`${humanReadableKey}: ${copy[key]?.toFixed(2) ?? "0"}`);
  }

  // eslint-disable-next-line no-console
  if (lines.length) {
    if (mode === "returnData") {
      return lines.sort((a, b) => a.localeCompare(b));
    } else if (mode === "returnObject") {
      const obj: Record<string, number> = {};
      for (const line of lines) {
        const [key, value] = line.split(": ");
        obj[key] = parseFloat(value);
      }
      return obj;
    } else {
      // eslint-disable-next-line no-console
      console.log(lines.join("\n"));
    }
  } else {
    if (mode === "returnData") {
      return [];
    } else {
      // eslint-disable-next-line no-console
      console.log("Nothing to log matching filters");
    }
  }
}

export function addTxToCache(
  store: AppReduxStore,
  row: TemplateRow,
  dateKey: string,
  scenarioId: string,
  amountType: "credit" | "debit" | null,
  value?: number,
) {
  const randomTx = getRandomTx(row.id, scenarioId, dateKey, amountType, value);
  store.dispatch(upsertCbTxLocal([randomTx]));

  store.dispatch(
    generateMonthlyCache({
      sendToMainThread: false,
    }),
  );

  return {state: store.getState(), randomTx};
}

export function generateDatasourceForFormula(
  row: TemplateRow,
  scenarioId: string,
  formula: string,
  from: string,
  to: string,
): Datasource {
  const options = {
    formula,
    references: getRefsFromFormula(formula, row.name),
  };

  return {
    id: id(),
    department_id: null,
    end: to,
    start: from,
    row_id: row.id,
    scenario_id: scenarioId,
    type: "formula",
    options,
  };
}
