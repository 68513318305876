import {useAppSelector} from "@app/hooks";
import {selectors} from "@features/templates/state/selectors";

import type {TemplateRow} from "@shared/types/db";
import type {forecastTypeDropdownItems} from "./ForecastGranularity";

export function useForecastType(row?: TemplateRow | null) {
  const {departmentId} = useAppSelector(selectors.activeCell);

  if (row?.type !== "account") return "row";

  const isCurrentDepVendorForecasted =
    row.options.forecastType === "department" && !!row.options.vendorLevelDepartments?.includes(departmentId ?? "");
  const isVendorLevelForecast = departmentId
    ? isCurrentDepVendorForecasted
    : !!row.options.vendorLevelDepartments?.length;

  const selectedKey: (typeof forecastTypeDropdownItems)[number]["key"] = !row.options.forecastType
    ? "row"
    : isVendorLevelForecast
    ? "vendor"
    : row.options.forecastType;

  return selectedKey;
}
