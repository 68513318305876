import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import id from "@shared/lib/id";

import {createEffectHandler} from "../listeners-list";
import {
  api,
  clearAlertsForDatasource,
  deleteAlerts,
  upsertAlerts,
  upsertAlertsForDs,
  upsertAlertsForDsLocal,
  upsertAlertsLocal,
} from "./slice";

import type {DsError} from "@shared/types/alerts";

export const syncDsAlertUpsertsWithAPI = createEffectHandler(
  [upsertAlertsForDs, upsertAlertsForDsLocal],
  (action, {getState, dispatch}) => {
    if (!action.payload) return;
    const state = getState();
    const actualUpserts: DsError[] = [];
    for (const error of action.payload) {
      const existingErrorsForDs = mapEntitiesToIds(
        state.alerts.entities,
        state.alerts.idsByDatasourceId[error.datasource_id],
      );
      const errorMatchingType = existingErrorsForDs.find((e) => e.type === error.type);

      if (!errorMatchingType) {
        const errorToPush = {
          id: error.id ?? id(),
          ...error,
        } as DsError;

        actualUpserts.push(errorToPush);
      } else {
        const merged = {...errorMatchingType, ...error, id: errorMatchingType.id} as DsError;
        actualUpserts.push(merged);
      }
    }
    if (actualUpserts.length) {
      dispatch(upsertAlertsLocal(actualUpserts));
      if (upsertAlertsForDs.match(action)) dispatch(api.upsertAlerts(actualUpserts));
    }
  },
);

export const syncAlertUpsertsWithAPI = createEffectHandler(upsertAlerts, (action, {getState, dispatch}) => {
  if (action.payload?.length) {
    dispatch(api.upsertAlerts([...action.payload]));
  }
});

export const syncAlertDeletesWithAPI = createEffectHandler(
  [clearAlertsForDatasource, deleteAlerts],
  (action, {getOriginalState, dispatch}) => {
    const idsToRemove = clearAlertsForDatasource.match(action)
      ? getOriginalState().alerts.idsByDatasourceId[action.payload]
      : (action.payload ?? []).map(String);
    if (idsToRemove?.length) {
      dispatch(api.deleteAlerts(idsToRemove));
    }
  },
);
