import {useAppDispatch, useAppSelector} from "@app/hooks";
import Button from "@components/Button/V2";
import Dropdown from "@components/Dropdown";
import {generateNewFcRow} from "@shared/lib/employee-utilities";
import {selectFinancialComponentEntities} from "@shared/state/financial-components/slice";
import {selectAllScenarios} from "@shared/state/scenarios/slice";
import {selectTeamById} from "@shared/state/teams/slice";
import {selectTemplateByName} from "@shared/state/templates/selectors";
import {upsertTemplate} from "@shared/state/templates/slice";
import {selectVersionLocked} from "@state/global/slice";
import {upsertRows} from "@state/template-rows/slice";
import clsx from "clsx";
import {startTransition} from "react";

import useStyles from "./CompensationAndTaxes.jss";
import CompensationItem from "./CompensationItem";

import type {DropdownItem} from "@components/Dropdown";
import type {FinancialComponent} from "@shared/types/hiring-plan";
import type {useSelectedEmployeeDetails} from "../../hooks";

export const expressedAsItems = [
  {key: "yearly", value: "Per Year"},
  {key: "monthly", value: "Per Month"},
] as const;

export default function CompensationAndTaxesShared({
  details,
}: {
  details: NonNullable<ReturnType<typeof useSelectedEmployeeDetails>>;
}): JSX.Element | null {
  const styles = useStyles();

  const financialComponents = useAppSelector(selectFinancialComponentEntities);
  const dispatch = useAppDispatch();
  const scenarios = useAppSelector(selectAllScenarios);
  const team = useAppSelector((state) => selectTeamById(state, details.entity?.team_id || ""));
  const templateRowsState = useAppSelector((state) => state.templateRows);
  const hiringPlanTemplate = useAppSelector((state) => selectTemplateByName(state, "hiring_plan"));
  const {scenarioId, entity: employee, scenarioProperties} = details;
  const isVersionLocked = useAppSelector(selectVersionLocked);

  const employeeFcRowIds = useAppSelector((state) => state.templateRows.idsByEmployeeId[employee?.id || ""]);

  const classes = clsx(styles.compensationMain);

  if (!hiringPlanTemplate) return null;
  if (!employee?.scenario_properties[scenarioId]?.hire_date) {
    return (
      <div className={classes}>
        <div className={styles.noCompensationText}>
          This employee doesn&apos;t have a hire date. Set it before adding compensation.
        </div>
      </div>
    );
  }

  const employeeFcRows = (employeeFcRowIds || [])
    .map((id) => templateRowsState.entities[id])
    .filter((templateRow) => templateRow);

  const addCompensationDropdownItems = Object.values(financialComponents).map((item) => ({
    key: item?.name || "",
    value: item?.display_name || "",
    disabled: employeeFcRows.some((row) => row?.type === "hiring-plan" && row.options.fc_name === item?.name) || false,
  }));

  const handleAddNewComponent = ({key}: DropdownItem) => {
    startTransition(() => {
      const baseFc = financialComponents[key as string] as FinancialComponent;

      const result = generateNewFcRow(
        employee,
        hiringPlanTemplate,
        baseFc,
        team ?? null,
        scenarios,
        scenarioId,
        templateRowsState.idsByName,
      );
      dispatch(upsertRows([result.row]));
      dispatch(upsertTemplate(result.template));

      // Now handled through the template rows store listener

      // const datasourceDiff = getDatasourceDiff([], result.datasources, state);

      // dispatch(
      //   applyDatasourceChanges({
      //     datasourceDiff,
      //     reason: `Add ${baseFc.display_name} fc to ${getEmployeeDisplayName(employee)}`,
      //   }),
      // );
    });
  };

  return (
    <div className={classes}>
      {!team ? (
        <div className={styles.noCompensationText}>
          This employee needs to be assigned to a team before their compensation can be edited.
        </div>
      ) : null}
      {!employeeFcRows?.length ? (
        <div className={styles.noCompensationText}>No compensation or taxes have been added yet.</div>
      ) : null}
      {employeeFcRows.map((fcRow) =>
        fcRow?.type !== "hiring-plan" || !team ? null : (
          <CompensationItem
            key={fcRow.id}
            fcRow={fcRow}
            termDate={employee.scenario_properties[scenarioId]?.term_date ?? null}
            employee={employee}
            team={team}
          />
        ),
      )}
      {false && !isVersionLocked ? (
        <>
          <div className={styles.filler} />
          {addCompensationDropdownItems.some((item) => !item.disabled) ? (
            <div className={styles.buttonSection}>
              <Dropdown
                items={addCompensationDropdownItems}
                onSelect={handleAddNewComponent}
                position={["bottom", "top"]}
              >
                <Button color="grey" size="small" text="Compensation" iconLeft="plus" />
              </Dropdown>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
