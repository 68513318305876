import {globalStyles} from "./general-ui";

export function getScrollingTableStyles(headerHeight: number) {
  return {
    height: `calc(100vh - ${globalStyles.topNavHeight}px - ${headerHeight}px)`, // 119px is templateHeader height + formulaBar height
    overflowY: "scroll",
    marginRight: navigator.platform.includes("Win")
      ? `calc(-${globalStyles.mainViewPaddingX}px - 17px)`
      : -globalStyles.mainViewPaddingX,
    paddingRight: navigator.platform.includes("Win")
      ? `calc(${globalStyles.mainViewPaddingX}px + 16px)`
      : globalStyles.mainViewPaddingX,
  };
}

type BorderLocations = "top" | "right" | "bottom" | "left";
export function getBorderAsBoxShadow(
  border: (BorderLocations | "all") | BorderLocations[],
  width: number,
  color: string,
) {
  const shadowComponents = [0, 0, 0, 0, color];
  if (border.includes("top")) shadowComponents[1] = -width;
  if (border.includes("bottom")) shadowComponents[1] = width;
  if (border.includes("left")) shadowComponents[0] = -width;
  if (border.includes("right")) shadowComponents[0] = width;
  if (border === "all") shadowComponents[3] = width;
  return shadowComponents;
}
