import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import reportingAPI from "@shared/apis/reporting";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getReportItemsAdapter} from "@shared/state/entity-adapters";

import type {EntityId, PayloadAction} from "@reduxjs/toolkit";
import type {ReportItemsState} from "@shared/state/entity-adapters";
import type {RootState} from "@shared/state/store";
import type {ReportItem} from "@shared/types/reporting";

export const api = {
  fetchReportReportItems: createAsyncThunk("reports/items", reportingAPI.fetchReportReportItems),
  upsertReportItem: createAsyncThunk("reports/items/upsert", reportingAPI.upsertReportItem),
  deleteReportItem: createAsyncThunk("reports/items/delete", reportingAPI.deleteReportItem),
  updateMultipleItemLayouts: createAsyncThunk("reports/items/upsert-layouts", reportingAPI.updateMultipleItemLayouts),
};

export const getSlice = () => {
  const reportItemsAdapter = getReportItemsAdapter();
  return createSlice({
    name: "reportItems",
    initialState: {
      ...reportItemsAdapter.getInitialState(),
      reportIdsLoading: [] as EntityId[],
      reportIdsWithItemsLoaded: [] as EntityId[],
    },
    reducers: {
      upsertReportItem: (state, action: PayloadAction<ReportItem>) => {
        reportItemsAdapter.upsertOne(state, action.payload);
      },
      reportItemAdded: (state, action: PayloadAction<ReportItem>) => {
        reportItemsAdapter.addOne(state, action.payload);
      },
      reportItemUpdated: <K extends keyof ReportItem>(
        state: ReportItemsState,
        action: PayloadAction<{
          id: keyof ReportItemsState["entities"];
          key: K;
          value: ReportItem[K];
          refetchCache?: boolean;
        }>,
      ) => {
        const {id, key, value} = action.payload;

        const reportItem = state.entities[id];
        if (!reportItem) return;

        const updated = {...JSON.parse(JSON.stringify(reportItem)), [key]: value};

        reportItemsAdapter.upsertOne(state, updated);
      },
      reportItemDeleted: (state, action: PayloadAction<ReportItem>) => {
        reportItemsAdapter.removeOne(state, action.payload.id);
      },
      multipleLayoutsUpdated: (state, action: PayloadAction<ReportItem["layout"][]>) => {
        for (const layout of action.payload) {
          const matchingItem = state.entities[layout.i];
          if (!matchingItem) continue;
          reportItemsAdapter.upsertOne(state, {...matchingItem, layout});
        }
      },
    },
    extraReducers: (builder) => {
      // builder.addCase(reportsApi.fetchReports.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(reportsApi.fetchReports.rejected, (state) => {
      //   state.loading = false;
      // });
      // builder.addCase(reportsApi.fetchReports.fulfilled, (state, action) => {
      //   reportItemsAdapter.setAll(state, action.payload.reportItems);
      //   state.loading = false;
      // });
      builder.addCase(api.fetchReportReportItems.pending, (state, action) => {
        state.reportIdsLoading.push(action.meta.arg);
      });
      builder.addCase(api.fetchReportReportItems.rejected, (state, action) => {
        state.reportIdsLoading = state.reportIdsLoading.filter((id) => id !== action.meta.arg);
      });
      builder.addCase(api.fetchReportReportItems.fulfilled, (state, action) => {
        reportItemsAdapter.setAll(state, action.payload);
        state.reportIdsLoading = state.reportIdsLoading.filter((id) => id !== action.meta.arg);
        if (!state.reportIdsWithItemsLoaded.includes(action.meta.arg))
          state.reportIdsWithItemsLoaded.push(action.meta.arg);
      });
    },
  });
};

const selectReportIdsLoading = (state: RootState) => state.reportItems.reportIdsLoading;
const selectReportIdsLoaded = (state: RootState) => state.reportItems.reportIdsWithItemsLoaded;

export const makeSelectReportItemsAreLoading = () =>
  createSelector(
    selectReportIdsLoading,
    (_: RootState, reportId: string) => reportId,
    (reportIdsLoading, reportId) => reportIdsLoading.includes(reportId),
  );

export const makeSelectReportItemsInitialLoadDone = () =>
  createSelector(
    selectReportIdsLoaded,
    (_: RootState, reportId: string) => reportId,
    (reportIdsLoaded, reportId) => reportIdsLoaded.includes(reportId),
  );

const _slice = getSlice();
export const {reportItemUpdated, reportItemAdded, multipleLayoutsUpdated, reportItemDeleted, upsertReportItem} =
  createSyncedActionCreators(_slice.actions);
export type ReportItemsActions = typeof _slice.actions;

export const makeSelectReportItemsByReportId = () =>
  createSelector(
    (state: RootState) => state.reportItems,
    (_: RootState, reportId: string) => reportId,
    (reportItemsState, reportId) => {
      const reportItemIds = reportItemsState.idsByReportId[reportId];
      if (!reportItemIds) return [];
      return mapEntitiesToIds(reportItemsState.entities, reportItemIds);
    },
  );

export const {
  selectById: selectReportItemById,
  selectIds: selectReportItemIds,
  selectEntities: selectReportItemEntities,
  selectAll: selectAllReportItems,
  selectTotal: selectTotalReportItems,
} = getReportItemsAdapter().getSelectors((state: RootState) => state.reportItems);
