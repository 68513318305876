// @ts-ignore
import {addDeltaMonthsToDateKey} from "@shared/lib/date-utilities";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {isBalance} from "@shared/lib/row-utilities";
import {projKey, projKeyOmitNulls} from "@shared/state/utils";
// @ts-ignore
import {getCbTxStateAdapter} from "@shared/state/entity-adapters";

import {mutateMonthlyCache} from "../transactions-upsert-utilities";
import {arrayOnlyFnsRegex, getParser, resolveFormulaAndRefs} from "./formula-utilities";
import {generateTransactionsFromFormulaResult} from "./generate-transactions";

import type {
  CbTxState,
  DatasourcesState,
  DepartmentsState,
  TemplateRowsState,
  TemplatesState,
} from "@shared/state/entity-adapters";
import type {Datasource} from "@shared/types/datasources";
import type {CbTx, TemplateRow} from "@shared/types/db";
import type {ParsedCacheKey} from "../cache/cache-utilities";

// This is only used to be able to call projection-aware adapter functions directly on the mutable state
const {removeMany, upsertMany} = getCbTxStateAdapter();

const DEBUG_PERFORMANCE = false;

export type ParseFormulaPartialState = {
  readonly templateRows: TemplateRowsState;
  readonly templates: TemplatesState;
  readonly departments: DepartmentsState;
  readonly datasources: DatasourcesState;
  cbTx: CbTxState;
};

export type ParseFormulaReturnType = {
  monthlyCache: Record<string, number>;
  upsertedTransactions: CbTx[];
  deletedTransactions: CbTx[];
  updatedCacheKeys: Record<string, number>;
  deletedCacheKeys: string[];
};

export type ParseFormulaParams = Parameters<typeof parseFormula>;

export function parseFormula(
  states: ParseFormulaPartialState,
  row: TemplateRow,
  datasourceMapping: Record<string, string | null | Datasource>,
  scenarioId: string,
  monthlyCache: Record<string, number>,
  readOnly?: boolean,
): ParseFormulaReturnType {
  // if (isBrowser && process.env.NODE_ENV !== "test")
  //   throw new Error("parseFormula should not be run from the browser main thread");
  if (!row) throw new Error("parseFormula was called without a row specified");
  const upsertedTransactions: CbTx[] = [];
  const deletedTransactions: CbTx[] = [];
  let updatedCacheKeys: Record<string, number> = {};
  const deletedCacheKeys: string[] = [];

  const {
    cbTx,
    departments: departmentsState,
    datasources: datasourcesState,
    templateRows: templateRowsState,
    templates: {entities: templateEntities},
  } = states;
  const {entities: templateRowsEntities, idsByName, idsByParentRowId, idsByMirrorOf} = templateRowsState;
  const parser = getParser();

  const {lastMonthOfActuals} = templateEntities[row.template_id]!.options;
  const sortedDateKeys = Object.keys(datasourceMapping).sort((a, b) => a.localeCompare(b));
  for (const dateKey of sortedDateKeys) {
    const datasource =
      typeof datasourceMapping[dateKey] === "string"
        ? datasourcesState.entities[datasourceMapping[dateKey] as string]
        : typeof datasourceMapping[dateKey] === "object"
        ? (datasourceMapping[dateKey] as Datasource)
        : null;
    const departmentId = datasource?.department_id ?? null;
    const vendor = datasource?.dimensions?.vendor ?? null;

    let value: number;

    if (datasource && "formula" in datasource.options && datasource.options.formula?.length) {
      const {references, formula} = datasource?.options;

      const {resolvedFormula, parserVariables} = resolveFormulaAndRefs({
        lastMonthOfActuals,
        dateKey,
        templateRowsState,
        departmentsState,
        references: references ?? [],
        formula,
        monthlyCache,
        scenarioId,
      });

      for (const [variable, value] of Object.entries(parserVariables)) {
        parser.setVariable(variable, value);
      }

      // For all fns that only accept arrays as params, replace their names with the customer fn name (surround with __)
      // @ts-ignore
      const finalFormula = resolvedFormula?.replaceAll(arrayOnlyFnsRegex, "CB$1CB$2");
      if (DEBUG_PERFORMANCE) time("Processor", "parser parse");
      const {error, result} = parser.parse(finalFormula);

      if (DEBUG_PERFORMANCE)
        //if (error) console.log(`Error: ${error} (${row.name}) - formula: ${formula}`);
        timeEnd("Processor", "parser parse");

      // if (row.name === "hosting" && dateKey === "2023-06") debugger;
      if (!Number.isFinite(result)) {
        value = 0;
      } else if (Array.isArray(result)) {
        // If the returned result is an array of numbers, we need to error out
        console.log(`Formula result returned a list of values - they probably should have been aggregated`, result);
        value = 0;
      } else {
        value = result ?? 0;
      }

      // If the value has not changed, skip the rest and simply return
      if (value === monthlyCache[projKeyOmitNulls(row.id, departmentId, vendor, scenarioId, dateKey)]) continue;
    } else {
      value = 0;
    }

    // If this is a balance, we actually want the balance for that month to get to that value
    // and not generate a tx of the amount resolved
    if (DEBUG_PERFORMANCE) time("Processor", "Balance formula check");
    if (isBalance(row)) {
      const previousMonthBalance =
        monthlyCache[
          projKeyOmitNulls(row.id, scenarioId, departmentId, vendor, addDeltaMonthsToDateKey(dateKey, -1), "balance")
        ] || 0;

      // TODO: make sure this works for both assets and liabilities. Order of the subtract should probable dependent on this
      const neededDeltaToGetToResult =
        row.type === "account" ? previousMonthBalance - value : value - previousMonthBalance;

      value = neededDeltaToGetToResult;
    }
    if (DEBUG_PERFORMANCE) timeEnd("Processor", "Balance formula check");

    const valueIsEmpty = !datasource || ["0", "", null].includes(value?.toString() ?? null);

    if (readOnly) {
      const cacheKey = projKeyOmitNulls(row.id, departmentId, vendor, scenarioId, dateKey);
      if (!valueIsEmpty) {
        updatedCacheKeys[cacheKey] = value;
        monthlyCache[cacheKey] = value;
      } else {
        deletedCacheKeys.push(cacheKey);
        if (monthlyCache[cacheKey]) delete monthlyCache[cacheKey];
      }
      continue;
    }

    const changes = {
      deletes: [] as CbTx[],
      upserts: [] as CbTx[],
    };
    if (valueIsEmpty) {
      if (DEBUG_PERFORMANCE) time("Processor", "getBufferDeleteMutations");
      const txItemsToDelete = mapEntitiesToIds(
        cbTx.entities,
        datasource ? cbTx.idsByDsIdDateKey[projKey(datasource.id, dateKey)] ?? [] : [],
      );
      changes.deletes.push(...txItemsToDelete);

      if (DEBUG_PERFORMANCE) timeEnd("Processor", "getBufferDeleteMutations");
    } else {
      if (DEBUG_PERFORMANCE) time("Processor", "generateTransactionsFromFormulaResult");
      const {
        txItems: generatedTxItems,
        txItemsChanged,
        txItemsToDelete,
      } = generateTransactionsFromFormulaResult(
        row,
        datasource,
        scenarioId,
        dateKey,
        value,
        {
          entities: templateRowsEntities,
          idsByName,
        },
        cbTx,
      );
      for (const [i, txItem] of generatedTxItems.entries()) {
        if (txItemsChanged[i]) changes.upserts.push(txItem);
      }

      // TODO: make sure in the future keeping only a single buffer transaction is not a problem
      // if (row.type === "hiring-plan" || row.type === "generic") {
      //   changes.deletes.push(...findExtraBufferTxItemsFromGenericRow(row, generatedTxItems, scenarioId, cbTx));
      // }

      changes.deletes.push(...txItemsToDelete);

      if (DEBUG_PERFORMANCE) timeEnd("Processor", "generateTransactionsFromFormulaResult");
    }

    if (changes.deletes.length) {
      // Perform the actual removals
      if (DEBUG_PERFORMANCE) time("Processor", "removeMany");

      removeMany(
        cbTx,
        changes.deletes.map(({id}) => id),
      );
      deletedTransactions.push(
        ...changes.deletes
          .sort((a, b) => (!!a.source_tx_id === !!b.source_tx_id ? 0 : !!a.source_tx_id ? -1 : 1))
          .reverse(),
      );
      if (DEBUG_PERFORMANCE) timeEnd("Processor", "removeMany");
    }

    if (changes.upserts.length) {
      // Perform the actual upserts
      if (DEBUG_PERFORMANCE) time("Processor", "upsertMany");
      upsertMany(cbTx, changes.upserts);

      upsertedTransactions.push(...changes.upserts);
      if (DEBUG_PERFORMANCE) timeEnd("Processor", "upsertMany");
    }

    // Take care of updating the cache and the balances if necessary, and finally the totals
    if (DEBUG_PERFORMANCE) time("Processor", "mutateMonthlyCache");
    const cleanedUpChanges: ParsedCacheKey[] = [];

    // If the value is 0, we need to make sure we delete the cache key for that month whether or not we found a transaction
    if (valueIsEmpty) {
      cleanedUpChanges.push({
        rowId: row.id,
        dateKey,
        scenarioId,
        balance: false,
        total: false,
        departmentId,
        vendor,
      });
    }

    for (const txItem of [...changes.upserts, ...changes.deletes]) {
      const dateKey = txItem.date.slice(0, 7);
      cleanedUpChanges.push({
        rowId: txItem.row_id,
        dateKey,
        scenarioId,
        balance: false,
        total: false,
        departmentId: txItem.department_id,
        vendor: txItem.tags.qbo_vendor_id,
      });
    }

    const mutateResult = mutateMonthlyCache(
      cleanedUpChanges,
      {entities: templateRowsEntities, idsByParentRowId, idsByMirrorOf},
      monthlyCache,
      cbTx,
      departmentsState,
    );

    updatedCacheKeys = {...updatedCacheKeys, ...mutateResult.updatedCacheKeys};
    deletedCacheKeys.push(...mutateResult.deletedCacheKeys);

    if (DEBUG_PERFORMANCE) timeEnd("Processor", "mutateMonthlyCache");
  }

  return {
    monthlyCache,
    upsertedTransactions,
    deletedTransactions,
    updatedCacheKeys,
    deletedCacheKeys,
  };
}
