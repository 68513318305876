import api from "@shared/lib/fetch";

import type {FinancialComponent} from "@shared/types/hiring-plan";
import type {ApiEndpoints} from "@state/store";

const endpoints = {
  upsertFc(fc: FinancialComponent, thunkAPI) {
    return api.put<FinancialComponent>(thunkAPI, `hiring-plan/financial-components/${fc.name}`, [fc]);
  },
} satisfies ApiEndpoints;

export default endpoints;
