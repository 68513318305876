import {createUseStyles} from "react-jss";

import {labelStyles} from "@app/styles/general-ui";
import colors from "@app/styles/colors";

export default createUseStyles({
  formElementMain: {
    width: "100%",

    "& > div:first-child > label": {
      extend: labelStyles,
      color: colors.text.black,
      fontWeight: 600,
      display: "inline-flex",
      paddingRight: 8,
      paddingTop: 1,
      marginBottom: -1,
    },

    "& + $formElementMain": {
      marginTop: 25,
    },
  },

  labelFlex: {
    flex: 1,
  },

  subtext: {
    fontWeight: 500,
    paddingLeft: 5,
  },

  firstRow: {
    alignItems: "center",
    display: "flex",
  },
  secondRow: {
    marginTop: 5,
  },
  tooltipIcon: {},
  popover: {
    marginLeft: 10,
    marginTop: -4,
  },
});
