import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import integrationsAPI from "@shared/apis/integrations";
import {getClientSocket} from "@shared/fn-gateway";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getIntegrationsAdapter} from "@state/entity-adapters";
import {projKey} from "@state/utils";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {Integration, IntegrationDataQboStatementRow} from "@shared/types/db";
import type {RootState} from "@state/store";
import type {PulledQboVendor} from "server/services/integrations/accounting/quickbooks/types";

export const fetchIntegrations = createAsyncThunk("integrations/fetchAll", integrationsAPI.fetchAll);
export const syncIntegration = createAsyncThunk("integrations/sync", integrationsAPI.sync);
export const syncForRow = createAsyncThunk("integrations/sync-for-row", integrationsAPI.syncForRow);
export const upsertIntegrationApiCall = createAsyncThunk("integrations/upsert", integrationsAPI.upsert);
export const reconnectIntegration = createAsyncThunk("integrations/reconnect", integrationsAPI.reconnect);
export const disconnectIntegrationApiCall = createAsyncThunk("integrations/disconnect", integrationsAPI.disconnect);
export const fetchQboStatementRows = createAsyncThunk(
  "integrations/fetchQboStatementRows",
  integrationsAPI.fetchQboStatementRows,
);

export const getSlice = () => {
  const integrationsAdapter = getIntegrationsAdapter();
  return createSlice({
    name: "integrations",
    initialState: {
      ...integrationsAdapter.getInitialState(),
      fetchingIntegrations: false,
      pendingSyncs: [] as string[],
      qboStatementRows: [] as IntegrationDataQboStatementRow[],
      loadingQboStatementRows: false,
    },
    reducers: {
      upsertIntegrationsLocal: integrationsAdapter.upsertMany,
      upsertIntegration: integrationsAdapter.upsertOne,
      removeIntegrationsLocal: integrationsAdapter.removeMany,
      updateIntegrationQuery: (state, action: PayloadAction<{id: string; query: string}>) => {
        const integration = state.entities[action.payload.id];
        if (integration?.provider !== "snowflake") return;
        integration.settings.query = action.payload.query;
      },
      disconnectIntegration: (state, action: PayloadAction<string>) => {
        const integration = state.entities[action.payload];
        if (integration?.provider !== "quickbooks-online") return;
        delete integration.data.companyName;
        delete integration.data.companyStartDate;
        delete integration.data.lastSync;
      },
      markPendingSyncAsDone: (state, action: PayloadAction<Integration>) => {
        const index = state.pendingSyncs.indexOf(action.payload.id);
        state.pendingSyncs.splice(index, 1);
        if (!!action.payload.id) integrationsAdapter.upsertOne(state, action.payload);
      },
      triggerSync: (state, action: PayloadAction<string>) => {},
    },
    extraReducers: (builder) => {
      builder.addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.fetchingIntegrations = false;
        integrationsAdapter.setAll(state, action.payload);
      });
      builder.addCase(fetchIntegrations.pending, (state, action) => {
        state.fetchingIntegrations = true;
      });
      builder.addCase(fetchIntegrations.rejected, (state) => {
        state.fetchingIntegrations = false;
      });
      builder.addCase(syncIntegration.pending, (state, action) => {
        state.pendingSyncs.push(action.meta.arg.id);
      });
      builder.addCase(syncIntegration.rejected, (state, action) => {
        const index = state.pendingSyncs.indexOf(action.meta.arg.id);
        state.pendingSyncs.splice(index, 1);
        getClientSocket().then((socket) => socket.off("integrations-sync"));
      });
      builder.addCase(fetchQboStatementRows.pending, (state) => {
        state.loadingQboStatementRows = true;
      });
      builder.addCase(fetchQboStatementRows.rejected, (state) => {
        state.loadingQboStatementRows = false;
      });
      builder.addCase(fetchQboStatementRows.fulfilled, (state, action) => {
        state.loadingQboStatementRows = false;
        state.qboStatementRows = action.payload;
      });
    },
  });
};

export const selectLoadingQboStatementRows = (state: RootState) => state.integrations.loadingQboStatementRows;

export const selectQboStatementRows = (state: RootState) => state.integrations.qboStatementRows;
export const selectQboStatementRowsByIdenfitierMapping = createSelector(selectQboStatementRows, (qboStatementRows) => {
  return Object.fromEntries(
    qboStatementRows.map((row) => [projKey(projKey(row.integration_id, row.data.statement, row.data.account)), row]),
  );
});

export const selectIsIntegrationBeingSynced = createSelector(
  [(state: RootState) => state.integrations, (_state: RootState, id: string) => id],
  (integrationsState, id: string) => integrationsState.pendingSyncs.includes(id),
);

export const selectQboIntegrationVendors = createSelector(
  (state: RootState) => state.integrations,
  (integrationsState) => {
    const qboIntegrationId = integrationsState.ids.find(
      (id) => integrationsState.entities[id]?.provider === "quickbooks-online",
    );

    if (!qboIntegrationId) return {};

    const integration = integrationsState.entities[qboIntegrationId];
    if (integration?.provider !== "quickbooks-online" || !integration.data.vendors) return {};
    return Object.fromEntries(integration.data.vendors.map((vendor: PulledQboVendor) => [vendor.qbId, vendor]));
  },
);

const _slice = getSlice();

export const {upsertIntegration, updateIntegrationQuery, disconnectIntegration, markPendingSyncAsDone} =
  createSyncedActionCreators(_slice.actions);

export const {upsertIntegrationsLocal, removeIntegrationsLocal, triggerSync} = _slice.actions;

export const {
  selectById: selectIntegrationById,
  selectIds: selectIntegrationIds,
  selectEntities: selectIntegrationEntities,
  selectAll: selectAllIntegrations,
  selectTotal: selectTotalIntegrations,
} = getIntegrationsAdapter().getSelectors((state: RootState) => state.integrations);
