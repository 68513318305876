import colors from "@app/styles/colors";
import {containerShadowStyles} from "@app/styles/general-ui";
import color from "color";
import {createUseStyles} from "react-jss";

import type {ConfirmProps} from "@app/ui-state/slice";

export const sharedValues = {};

export default createUseStyles({
  confirmMain: {
    extend: containerShadowStyles,
    width: 400,
    padding: [275, 29.5, 41, 29.5],
    borderRadius: 3,
    backgroundColor: colors.ui.white,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    zIndex: 6,
    background: color(colors.ui.darkBlue01).alpha(0.7).rgb().string(),
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 20,
  },
  title: {
    fontWeight: 700,
    lineHeight: 36,
    fontSize: 26,
    color: colors.ui.black,
    paddingBottom: 35,
  },
  text: {
    color: colors.ui.darkBlue01,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 24,
    textAlign: "center",

    "& > b": {
      color: (props: {mainColor?: ConfirmProps["mainColor"]}) => colors.states[props.mainColor || "red"],
      fontWeight: 700,
    },

    "& > strong": {
      fontWeight: 700,
    },
  },
  actionButtons: {
    marginTop: 35,
    display: "flex",
    alignItems: "center",

    "& > div:first-child": {
      marginRight: 22,
    },
  },
});
