import {bubbleUpRefreshAndSave} from "@shared/data-functions/formula/compute";
import {dispatchInMainThread} from "@shared/fn-gateway";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {itemIsNotFalsy} from "@shared/lib/misc";
import {queues} from "@state/datasources/listeners";
import {applyDiffToMonthlyCache} from "@state/transaction-items/slice";
import {isWebWorker} from "browser-or-node";

import {createEffectHandler} from "../listeners-list";
import {generateMonthlyCache} from "../transaction-items/slice";
import {
  api,
  applyChangesFromBackend,
  deleteCbTxLocal,
  removeCbTx,
  triggerAlgoFromListener,
  upsertCbTxLocal,
} from "./slice";

import type {MonthlyCacheDiff} from "@shared/data-functions/cache/cache-utilities";
import type {CbTx} from "@shared/types/db";

export const applyChangesFromBackendListener = createEffectHandler(
  applyChangesFromBackend,
  (action, {getState, dispatch}) => {
    if (!isWebWorker) throw new Error("This can't be called from the main thread");
    let state = getState();
    const changedCbTx: CbTx[] = [];
    const rowIds: string[] = [];

    if (action.payload.upserts.length) {
      dispatch(upsertCbTxLocal(action.payload.upserts));
      changedCbTx.push(...action.payload.upserts);
      for (const txItem of action.payload.upserts) {
        if (txItem && !rowIds.includes(txItem.row_id)) rowIds.push(txItem.row_id);
      }
    }

    if (action.payload.deletes.length) {
      const deletedCbTx = action.payload.deletes.map((id) => state.cbTx.entities[id]).filter(itemIsNotFalsy);
      changedCbTx.push(...deletedCbTx);
      dispatch(deleteCbTxLocal(deletedCbTx.map(({id}) => id)));
    }

    state = getState();

    dispatch(
      generateMonthlyCache({
        sendToMainThread: true,
      }),
    );

    if (action.payload.recalc) {
      const diff = bubbleUpRefreshAndSave(
        {
          basedOn: "txItems",
          txItems: changedCbTx,
          storeInstance: {getState, dispatch},
        },
        {persistResultsToAPI: !action.meta?.receivedFromWs},
      );

      const monthlyCacheUpdatedAction = applyDiffToMonthlyCache({diff});
      dispatch(monthlyCacheUpdatedAction);
      if (isWebWorker) {
        dispatchInMainThread(monthlyCacheUpdatedAction);
      }
    }
  },
);

export const syncTxItemDeletesWithAPI = createEffectHandler(removeCbTx, (action, {dispatch}) => {
  dispatch<any>(api.delete(action.payload));
});

export const removeNonIntegrationKeysFromInitialCache = createEffectHandler(
  api.fetchAllCbTxTransactions.filled,
  (_action, {dispatch}) => {
    dispatch(
      generateMonthlyCache({
        sendToMainThread: true,
        fullCache: true,
      }),
    );
  },
);

export const triggerAlgoFromListenerHandler = createEffectHandler(
  triggerAlgoFromListener,
  (action, {getState, dispatch}) => {
    log(`TriggerAlgo listener (exec ${action.payload.execId})`, "Starting triggerAlgoFromListenerHandler");
    time(`TriggerAlgo listener (exec ${action.payload.execId})`, "Completed triggerAlgoFromListenerHandler");

    const apiListenerNotRegistered =
      action.meta.listenerTypesRegistered?.length && !action.meta.listenerTypesRegistered.includes("api-save");
    const persistResultsToAPI =
      !apiListenerNotRegistered && (!action.meta.initialDispatcher || action.meta.initialDispatcher === "user");

    const result = bubbleUpRefreshAndSave(
      {
        basedOn: "datasourceDiff",
        storeInstance: {getState, dispatch},
        datasourceDiff: action.payload.datasourceDiff,
        recalcTriggerCells: true,
      },
      {
        persistResultsToAPI,
        regenerateDependencyCache: true,
        dependencyCacheScenarioIdsImpacted: action.payload.scenarioIdsImpacted,
        deletedDatasourceIds: action.payload.deletedDatasourceIds,
        upsertedCbTx: action.payload.optimisticDiff?.upsertedCbTx,
        deletedCbTx: action.payload.optimisticDiff?.deletedCbTx,
        sendOptimisticUpdate: action.payload.sendOptimisticUpdate,
        originalTemplateOptions: action.payload.originalTemplateOptions,
        disableAddingPreviousDependencies: action.payload.disableAddingPreviousDependencies,
        isGlobalRecalc: action.payload.isGlobalRecalc,
      },
    );

    let diff: MonthlyCacheDiff = result;
    // if (action.payload.optimisticDiff) {
    //   log(`TriggerAlgo listener (exec ${action.payload.execId})`, "Applying optimistic diff", action.payload.optimisticDiff);
    //   diff = {
    //     updatedCacheKeys: {
    //       ...result.updatedCacheKeys,
    //       ...action.payload.optimisticDiff.updatedCacheKeys,
    //     },
    //     deletedCacheKeys: [...(result.deletedCacheKeys ?? []), ...(action.payload.optimisticDiff.deletedCacheKeys ?? [])],
    //   };
    // }
    const userId = getState().session.user?.id ?? "";
    log(
      `TriggerAlgo listener (exec ${action.payload.execId})`,
      "Queue length before applying diff",
      queues[userId].length,
    );
    dispatch({
      ...applyDiffToMonthlyCache({
        diff,
      }),
      meta: {sendToOtherThreadFirst: true},
    });
    timeEnd(`TriggerAlgo listener (exec ${action.payload.execId})`, "Completed triggerAlgoFromListenerHandler");
  },
);
