import {store} from "@app/client-store";
import {useAppSelector} from "@app/hooks";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import useFormulaStyles from "@features/shared-views/FormulaTextEditor/styles.jss";
import {useSelectedRowDatasources} from "@features/templates/state/hooks";
import {selectSelectedTemplate} from "@features/templates/state/selectors";
import {prettyPrintFormulaToHtmlString} from "@shared/data-functions/formula/pretty-print";
import {resolveStartEndFromState} from "@shared/lib/date-utilities";
import {selectIntegrationEntities} from "@state/integrations/slice";
import clsx from "clsx";

import useStyles from "./datasources.jss";

import type {Datasource} from "@shared/types/datasources";

type DatasourceForDisplay = {
  ds: Datasource;
  formulaOrAccount: string;
  startText: string;
  endText: string;
  effectiveStart: string;
  effectiveEnd: string;
  type: string;
};
export default function Datasources({expanded}: {expanded: boolean}) {
  const styles = useStyles();
  const formulaStyles = useFormulaStyles();
  const state = store.getState();
  const datasources = useSelectedRowDatasources();
  const template = useAppSelector(selectSelectedTemplate);
  const integrations = useAppSelector(selectIntegrationEntities);

  if (!template) return null;

  const datasourcesForDisplay: DatasourceForDisplay[] = datasources
    .map((ds) => {
      const resolvedDates = resolveStartEndFromState(ds, state);
      let formulaOrAccount = "";
      let type = "";
      let startText = ds.start?.toString() ?? "null";
      let endText = ds.end?.toString() ?? "null";

      if (!ds.start || ds.start?.includes("a") || ds.start?.includes("f")) {
        startText += ` (${resolvedDates.start})`;
      }

      if (!ds.end || ds.end?.includes("a") || ds.end?.includes("f")) {
        endText += ` (${resolvedDates.end})`;
      }

      if (ds.type === "integration") {
        const integration = integrations[ds.integration_id];
        if (integration) {
          type = integration.provider === "quickbooks-online" ? "QBO" : "Snowflake";
          formulaOrAccount =
            integration.provider === "quickbooks-online"
              ? `QBO account ID ${ds.options.remoteId}`
              : `Snowflake column ${ds.options.remoteId}`;
        }
      } else {
        formulaOrAccount = ds.options.formula ?? "";
        type = "Formula";
      }

      return {
        ds,
        formulaOrAccount,
        startText,
        endText,
        effectiveStart: resolvedDates.start,
        effectiveEnd: resolvedDates.end,
        type,
      };
    })
    .toSorted((a, b) => a.effectiveStart.localeCompare(b.effectiveStart));

  return (
    <Section title="Datasources" wrapContent={false}>
      <SectionCol>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Start</th>
              <th>End</th>
              <th>Formula / Account</th>
            </tr>
          </thead>
          <tbody>
            {datasourcesForDisplay.map((dsForDisplay) => (
              <tr key={dsForDisplay.ds.id}>
                <td>{dsForDisplay.type}</td>
                <td>{dsForDisplay.startText}</td>
                <td>{dsForDisplay.endText}</td>
                <td>
                  <span className={clsx({[styles.formula]: dsForDisplay.ds.type !== "integration"})}>
                    {dsForDisplay.ds.type === "integration" ? (
                      dsForDisplay.formulaOrAccount
                    ) : (
                      <span className={clsx(styles.formulaWrapper, formulaStyles.formulaPrettyPrinted)}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: prettyPrintFormulaToHtmlString(dsForDisplay.ds.options.formula).html,
                          }}
                        />
                      </span>
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </SectionCol>
    </Section>
  );
}
