import {store} from "@app/client-store";
import {getActiveFcDatasource} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import {getEmployeeDisplayName, getEmployeeStatus} from "@shared/lib/employee-utilities";
import getSlug from "@shared/lib/slug";
import {selectEmployeeFcDatasourcesSorted} from "@state/datasources/selectors";
import dayjs from "dayjs";
import Rfdc from "rfdc";

import {generateCsvString, triggerCsvDownload} from "./export-utilities";
const rfdc = Rfdc();

export function exportHiringPlan({format = "csv"}: {format?: "csv" | "json"}) {
  const state = store.getState();

  const version = state.global.version ? state.versions.entities[state.global.version] ?? null : null;

  const employees = Object.values(state.employees.entities);
  const scenarioId = state.global.scenarioId;
  const hiringPlanTemplate = Object.values(state.templates.entities).find(
    (template) => template?.type === "hiring_plan",
  );
  if (!scenarioId || !hiringPlanTemplate) return;
  let headers: string[] = [];
  let allCompensationHeaders: Set<string> = new Set();
  const exportedEmployees: any[] = [];
  for (const employee of employees) {
    if (!employee) continue;
    const exportedEmployee: any = {...employee, ...rfdc(employee.scenario_properties?.[scenarioId] || {})};
    delete exportedEmployee.team_id;
    delete exportedEmployee.scenario_properties;
    delete exportedEmployee.created_at;
    delete exportedEmployee.updated_at;
    const employeeFcIds = state.templateRows.idsByEmployeeId[employee.id];

    exportedEmployee.display_name = getEmployeeDisplayName(employee) || "Unnamed Employee";
    exportedEmployee.status = getEmployeeStatus(employee, scenarioId);

    const team = state.teams.entities[employee.team_id || ""];
    const parentTeam = state.teams.entities[team?.parent_id || ""];
    exportedEmployee.team_current = team?.display_name ?? "unassigned";
    exportedEmployee.team_current_parent = parentTeam?.display_name ?? null;

    const customTags = Object.entries(employee.custom_tags ?? {});
    if (customTags.length) {
      for (const [key, value] of customTags) {
        exportedEmployee[getSlug(`custom_prop_${key}`)] = value;
      }
    }
    delete exportedEmployee.custom_tags;

    const exportedEmployeeKeys = Object.keys(exportedEmployee);
    for (const key of exportedEmployeeKeys) {
      if (!headers.includes(key)) headers.push(key);
    }

    if (employeeFcIds?.length) {
      for (const employeeFcId of employeeFcIds) {
        const employeeFc = state.templateRows.entities[employeeFcId];
        if (employeeFc?.type !== "hiring-plan") continue;
        const matchingFc = state.financialComponents.entities[employeeFc.options.fc_name];
        if (!matchingFc) continue;
        const sortedEmployeeDatasources = selectEmployeeFcDatasourcesSorted(state, {
          employeeId: employee.id,
          fcName: matchingFc.name,
          scenarioId,
        });
        const department = state.departments.entities[team?.department_id || ""];

        exportedEmployee[`${matchingFc.name}_department`] = department?.display_name ?? "";
        const activeDatasource = getActiveFcDatasource(sortedEmployeeDatasources);
        if (activeDatasource) {
          exportedEmployee[`${matchingFc.name}_current_value`] = activeDatasource.options.ui.value;
          exportedEmployee[`${matchingFc.name}_current_interval`] = activeDatasource.options.ui.expressedAs;
          allCompensationHeaders.add(`${matchingFc.name}_current_value`);
          allCompensationHeaders.add(`${matchingFc.name}_current_interval`);
        }
        for (const [i, datasource] of sortedEmployeeDatasources.entries()) {
          exportedEmployee[`${matchingFc.name}_${i + 1}_start`] = getFormattedFullDate(
            datasource.start || exportedEmployee.hire_date,
            "start",
          );
          allCompensationHeaders.add(`${matchingFc.name}_${i + 1}_start`);
          exportedEmployee[`${matchingFc.name}_${i + 1}_end`] = getFormattedFullDate(
            datasource.end || exportedEmployee.term_date || hiringPlanTemplate.options.end,
            "end",
          );
          allCompensationHeaders.add(`${matchingFc.name}_${i + 1}_end`);
          exportedEmployee[`${matchingFc.name}_${i + 1}_value`] = datasource.options.ui.value;
          allCompensationHeaders.add(`${matchingFc.name}_${i + 1}_value`);
          exportedEmployee[`${matchingFc.name}_${i + 1}_interval`] = datasource.options.ui.expressedAs;
          allCompensationHeaders.add(`${matchingFc.name}_${i + 1}_interval`);
        }
      }
    }

    exportedEmployees.push(exportedEmployee);
  }

  const headersWithoutCustomProps = headers.filter((key) => !key.includes("custom_prop_"));
  const headersWithCustomProps = headers.filter((key) => key.includes("custom_prop_"));

  const sortedHeaders = [...headersWithoutCustomProps, ...headersWithCustomProps, ...allCompensationHeaders];

  for (const employeeToExport of exportedEmployees) {
    for (const key of sortedHeaders) {
      employeeToExport[key] = employeeToExport[key] ?? "";
    }
  }

  const filenameVersion = !version ? "latest-version" : getSlug(version.display_name);
  const filenameDate = dayjs(version?.created_at).format("YYYY.MM.DD.HH.mm.ss");

  if (format === "csv") {
    const csvStr = generateCsvString({headers: sortedHeaders, items: exportedEmployees});
    triggerCsvDownload({
      content: csvStr,
      filename: `cloudberry-hiring-plan-export-${filenameVersion}-${filenameDate}.csv`,
    });
  } else {
    console.log(exportedEmployees);
    console.error("JSON export not implemented yet");
  }
}
