import {useAppSelector} from "@app/hooks";
import {SidebarBody} from "@components/RightSidebar";
import Tabs from "@components/Tabs";
import {useState} from "react";

import {selectSelectedRow, selectors} from "../state/selectors";
import DataTab from "./Data";
import FormulaBuilderTab from "./FormulaBuilder";
import SettingsTab from "./SettingsTab";

import type {Tab} from "@components/Tabs";

export default function SidebarTabs({expanded, onTabChange}: {expanded: boolean; onTabChange: (tab: string) => void}) {
  const row = useAppSelector(selectSelectedRow);
  const {departmentId, vendor} = useAppSelector(selectors.activeCell);

  const tabsList: Tab[] = [];
  if (!row?.mirror_of && !expanded) tabsList.push({name: "formula-builder", value: "Formula Builder"});
  tabsList.push({name: "data", value: "Data"});

  if (!expanded && !departmentId && !vendor) tabsList.push({name: "settings", value: "Settings"});
  const [selectedTab, setSelectedTab] = useState(tabsList[0]);

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
    onTabChange(tab.name);
  };

  let tabComponent: JSX.Element | null = null;
  switch (selectedTab.name) {
    case "formula-builder": {
      tabComponent = <FormulaBuilderTab />;
      break;
    }
    case "data": {
      tabComponent = <DataTab expanded={expanded} />;
      break;
    }
    case "settings": {
      tabComponent = <SettingsTab />;
      break;
    }
  }

  return (
    <>
      <Tabs tabs={tabsList} selectedTab={selectedTab} onChange={handleTabChange} />
      <SidebarBody>{tabComponent}</SidebarBody>
    </>
  );
}
