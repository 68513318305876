import {useAppDispatch, useAppSelector} from "@app/hooks";
import CreateNewScenario from "@features/shared-views/CreateScenario";
import {selectTemplateByName} from "@state/templates/selectors";
import {selectAllTemplates} from "@state/templates/slice";
import qs from "query-string";
import {useEffect, useMemo} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import Template from "./Template";
import {selectors} from "./state/selectors";
import {updateTemplatesViewState} from "./state/slice";

export default function Templates({}) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlTemplateName = params.templateName || qs.parse(location.search).statement?.toString();
  const templateName = urlTemplateName?.toString() ?? "profit_and_loss";

  useEffect(() => {
    if (!urlTemplateName) {
      navigate(`/financial-statements?statement=profit_and_loss`, {replace: true});
    }
  }, [urlTemplateName, navigate]);

  const template = useAppSelector((state) => selectTemplateByName(state, templateName as string));
  const createNewScenarioOpen = useAppSelector(selectors.createScenarioOpen);

  return (
    <>
      {template ? <Template template={template} /> : null}
      {createNewScenarioOpen ? (
        <CreateNewScenario onClose={() => dispatch(updateTemplatesViewState({createScenarioOpen: false}))} />
      ) : null}
    </>
  );
}

export function WorksheetsIndexRedirect() {
  const templates = useAppSelector(selectAllTemplates);
  const navigate = useNavigate();

  const sortedTemplates = useMemo(
    () => templates.filter(({type}) => type === "generic").toSorted((a, b) => a.name.localeCompare(b.name)),
    [templates],
  );

  const params = useParams();
  const urlTemplateName = params.templateName || qs.parse(location.search).statement?.toString();

  useEffect(() => {
    if (!urlTemplateName) {
      navigate(`/worksheets/${sortedTemplates[0].name}`, {replace: true});
    }
  }, [sortedTemplates, navigate, urlTemplateName]);

  return null;
}
