import Dropdown from "@components/Dropdown";
import TextInput from "@components/TextInput";

import useStyles from "./style.jss";

import type {DropdownItem, DropdownWithoutTriggerProps} from "@components/Dropdown";
import type {TextInputProps} from "@components/TextInput";

interface TextDropdownComboProps<T> {
  textInputProps: TextInputProps;
  dropdownProps: DropdownWithoutTriggerProps<T>;
}

export default function TextInputDropdownCombo<T extends DropdownItem>(props: TextDropdownComboProps<T>) {
  const styles = useStyles();

  return (
    <div className={styles.textInputDropdownComboMain}>
      <div>
        <TextInput {...props.textInputProps} />
      </div>
      <div className={styles.separator} />
      <div>
        <Dropdown
          {...props.dropdownProps}
          openTriggerClassName={`${props.dropdownProps.openTriggerClassName || ""} ${styles.dropdownTrigger}`}
        />
      </div>
    </div>
  );
}
