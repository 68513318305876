import {isBrowser, isWebWorker} from "browser-or-node";
import memoize from "fast-memoize";

import type {ActionCreatorWithPreparedPayload} from "@reduxjs/toolkit";
import type {ClientRootState} from "@shared/../client/app/client-store";
import type {DependencyCache} from "@shared/data-functions/cache/dependency-cache";
import type {RootState} from "@shared/state/store";
import type {WorkerRootState} from "client/app/worker/worker-thread/worker-store";

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toCamelCase(s: string) {
  return s.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace("-", "").replace("_", ""));
}

export function itemIsNotFalsy<T>(item: T | undefined | null): item is T {
  return !!item;
}

export function valueIsNotFalsy<T>(entry: [string, T | undefined | null]): entry is [string, T] {
  return !!entry[1];
}

export function ensureNotBrowser() {
  if (process.env.NODE_ENV === "development" && isBrowser && !isWebWorker) {
    throw new Error("This function must only be called from within the web worker");
  }
}

const wrapActionWithSyncTrue = <T extends Array<any>, U extends {meta?: any}>(fn: (...args: T) => U) => {
  const newFn = (...args: T): U & {meta: {sync: true}} => {
    const result = fn(...args);
    return {...result, meta: {...(result?.meta || {}), sync: true}};
  };
  newFn.toString = fn.toString;
  // @ts-ignore
  newFn.match = fn.match;

  // @ts-ignore
  newFn.type = fn.type;
  return newFn;
};

type AnyFunction = (...args: any[]) => any;

type SyncedActionCreator<T> = T extends AnyFunction
  ? ActionCreatorWithPreparedPayload<Parameters<T>, Parameters<T>[0], string, never, {sync: true}>
  : T;
type ActionCreatorsList<T extends Record<string, AnyFunction>> = {
  [P in keyof T]: ActionCreatorWithPreparedPayload<Parameters<T[P]>, Parameters<T[P]>[0], string, never, {sync: true}>;
};

export function createSyncedActionCreators<T extends Record<string, AnyFunction>>(
  originalActionCreators: T,
): ActionCreatorsList<T> {
  const syncedActionCreators = {} as ActionCreatorsList<T>;
  for (const key in originalActionCreators) {
    if (Object.prototype.hasOwnProperty.call(originalActionCreators, key)) {
      syncedActionCreators[key] = wrapActionWithSyncTrue(originalActionCreators[key] as any) as any;
    }
  }
  return syncedActionCreators;
}

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type GenericSelectors<T extends keyof S, S extends RootState | ClientRootState> = {
  [P in keyof S[T]]: (rootState: S) => S[T][P];
};

export function generateClientSelectors<T extends keyof ClientRootState, S extends NonNullable<ClientRootState[T]>>(
  sliceName: T,
  initialState: S,
) {
  return Object.fromEntries(
    Object.keys(initialState).map((propertyName) => [
      propertyName,
      (rootState: any) => rootState[sliceName][propertyName],
    ]),
  ) as GenericSelectors<T, ClientRootState>;
}

export function generateWorkerSelectors<T extends keyof WorkerRootState, S extends NonNullable<WorkerRootState[T]>>(
  sliceName: T,
  initialState: S,
) {
  return Object.fromEntries(
    Object.keys(initialState).map((propertyName) => [
      propertyName,
      (rootState: any) => rootState[sliceName][propertyName],
    ]),
  ) as GenericSelectors<T, WorkerRootState>;
}

export function generateSelectors<T extends keyof RootState, S extends NonNullable<RootState[T]>>(
  sliceName: T,
  initialState: S,
) {
  return Object.fromEntries(
    Object.keys(initialState).map((propertyName) => [
      propertyName,
      (rootState: any) => rootState[sliceName][propertyName],
    ]),
  ) as GenericSelectors<T, RootState>;
}

// Taken directly from here: https://github.com/lovasoa/fast_array_intersect/blob/master/index.ts
function default_hash<T>(x: T): any {
  return x;
}

/**
 * Takes an array of arrays and optionnally a hash function,
 * and returns the elements that are present in all the arrays.
 * When intersecting arrays of objects, you should use a custom
 * hash function that returns identical values when given objects
 * that should be considered equal in your application.
 * The default hash function is the identity function.
 * When performance is not critical, a handy hash function can be `JSON.stringify`.
 */
export function intersect<T>(arrays: ReadonlyArray<T>[], hash = default_hash): T[] {
  if (arrays.length === 0) return [];

  // Put the smallest array in the beginning
  for (let i = 1; i < arrays.length; i++) {
    if (arrays[i].length < arrays[0].length) {
      let tmp = arrays[0];
      arrays[0] = arrays[i];
      arrays[i] = tmp;
    }
  }

  // Create a map associating each element to its current count
  const set = new Map();
  for (const elem of arrays[0]) {
    set.set(hash(elem), 1);
  }
  for (let i = 1; i < arrays.length; i++) {
    let found = 0;
    for (const elem of arrays[i]) {
      const hashed = hash(elem);
      const count = set.get(hashed);
      if (count === i) {
        set.set(hashed, count + 1);
        found++;
      }
    }
    // Stop early if an array has no element in common with the smallest
    if (found === 0) return [];
  }

  // Output only the elements that have been seen as many times as there are arrays
  return arrays[0].filter((e) => {
    const hashed = hash(e);
    const count = set.get(hashed);
    if (count !== undefined) set.set(hashed, 0);
    return count === arrays.length;
  });
}

export type EntityWithDepth<E> = {depth: number; entity: E};
function getFlatEntitiesWithDepthFn<E extends {id: string}>(entities: E[], parentKey: keyof E): EntityWithDepth<E>[];
function getFlatEntitiesWithDepthFn<E extends {id: string; parent?: string | null | undefined}>(
  entities: E[],
): EntityWithDepth<E>[];

function getFlatEntitiesWithDepthFn<E extends {id: string}>(entities: E[], parentKey?: keyof E): EntityWithDepth<E>[] {
  const items: EntityWithDepth<E>[] = [];

  const parentKeyResolved = (parentKey || "parent") as keyof E;

  function recursive(parent: string | null, depth: number) {
    const children = entities.filter((entity) => (entity[parentKeyResolved] ?? null) === parent);

    for (const child of children) {
      items.push({
        entity: child,
        depth,
      });
      recursive(child.id, depth + 1);
    }
  }
  recursive(null, 0);

  return items;
}

export const getFlatEntitiesWithDepth = memoize(getFlatEntitiesWithDepthFn);

export const getStateSizeStats = memoize((state: RootState) => {
  const result: Record<string, number | null> = {};
  for (const [entityType, entityState] of Object.entries(state)) {
    result[entityType] = "ids" in entityState && entityState.ids ? entityState.ids.length : null;
  }
  result.monthlyCache = Object.keys(state.transactionItems.valuesByRowIdDateKey).length;

  let totalCountOfOrderedCacheKeys = 0;

  for (const key of Object.keys(state.global.dependencyCache)) {
    const dependencyCache: DependencyCache = state.global.dependencyCache[key];
    totalCountOfOrderedCacheKeys += dependencyCache.orderedCacheKeys.length;
  }

  result.dependencyCache = totalCountOfOrderedCacheKeys;

  return result;
});
