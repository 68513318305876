import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  tabsMain: {
    display: "flex",
    userSelect: "none",
    borderBottom: [2, "solid", colors.ui.lightBlue07],
  },
  tab: {
    lineHeight: 24,
    position: "relative",
    paddingBottom: 8,
    left: 0,
    bottom: -2,
    cursor: "pointer",
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: "nowrap",
    // paddingRight: 25,

    "&:not(:first-child)": {
      display: "flex",
      justifyContent: "center",
      paddingLeft: 25,
    },
    "&:not(:last-child)": {},
  },
  active: {
    fontWeight: 700,
  },
  disabled: {
    cursor: "default",
    color: colors.text.lightBlue02,
  },
  tabBorderBottom: {
    // position: "absolute",
    // borderBottom: `2px solid ${colors.ui.lightBlue07}`,
    // width: "calc(100% + 3px)",
    // bottom: 0,
  },
  activeTabMarker: {
    position: "absolute",
    borderBottom: [2, "solid", colors.primary.gold],
    width: "calc(100% + 14px - 25px)",
    bottom: 0,
    zIndex: 1,

    "$tab:first-child &": {
      width: "calc(100% + 7px)",
    },
  },
});
