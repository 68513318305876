// Entity reducers

import adminReducer, {adminSlice} from "@state/admin/slice";
import {getSlice as getAlertsSlice} from "@state/alerts/slice";
import {getSlice as getCbTxSlice} from "@state/cb-tx/slice";
import {getSlice as getCompaniesSlice} from "@state/companies/slice";
import {getSlice as getDatasourcesSlice} from "@state/datasources/slice";
import {getSlice as getDepartmentsSlice} from "@state/departments/slice";
import {getSlice as getEmployeesSlice} from "@state/employees/slice";
import {getSlice as getFinancialComponentsSlice} from "@state/financial-components/slice";
import {getSlice as getGlobalSlice} from "@state/global/slice";
import {getSlice as getIntegrationsSlice} from "@state/integrations/slice";
import {getSlice as getReportItemsSlice} from "@state/report-items/slice";
import {getSlice as getReportsSlice} from "@state/reports/slice";
import {getSlice as getSanityChecksSlice} from "@state/sanity-checks/slice";
import {getSlice as getScenariosSlice} from "@state/scenarios/slice";
import sessionReducer, {slice} from "@state/session/slice";
import {getSlice as getTeamsSlice} from "@state/teams/slice";
import {getSlice as getTemplateRowsSlice} from "@state/template-rows/slice";
import {getSlice as getTemplatesSlice} from "@state/templates/slice";
import {getSlice as getTxItemsSlice} from "@state/transaction-items/slice";
import {getSlice as getVersionsSlice} from "@state/versions/slice";

export const getAllActionTypes = () =>
  ({
    [getAlertsSlice().name]: Object.values(getAlertsSlice().actions).map((action) => action.type),
    [getGlobalSlice().name]: Object.values(getGlobalSlice().actions).map((action) => action.type),
    [getTemplatesSlice().name]: Object.values(getTemplatesSlice().actions).map((action) => action.type),
    [getTemplateRowsSlice().name]: Object.values(getTemplateRowsSlice().actions).map((action) => action.type),
    [getTxItemsSlice().name]: Object.values(getTxItemsSlice().actions).map((action) => action.type),
    [getCbTxSlice().name]: Object.values(getCbTxSlice().actions).map((action) => action.type),
    [getScenariosSlice().name]: Object.values(getScenariosSlice().actions).map((action) => action.type),
    [getDepartmentsSlice().name]: Object.values(getDepartmentsSlice().actions).map((action) => action.type),
    [getSanityChecksSlice().name]: Object.values(getSanityChecksSlice().actions).map((action) => action.type),
    [getDatasourcesSlice().name]: Object.values(getDatasourcesSlice().actions).map((action) => action.type),
    [getVersionsSlice().name]: Object.values(getVersionsSlice().actions).map((action) => action.type),
    [getIntegrationsSlice().name]: Object.values(getIntegrationsSlice().actions).map((action) => action.type),
    [getTeamsSlice().name]: Object.values(getTeamsSlice().actions).map((action) => action.type),
    [getEmployeesSlice().name]: Object.values(getEmployeesSlice().actions).map((action) => action.type),
    [getFinancialComponentsSlice().name]: Object.values(getFinancialComponentsSlice().actions).map(
      (action) => action.type,
    ),
    [getReportsSlice().name]: Object.values(getReportsSlice().actions).map((action) => action.type),
    [getReportItemsSlice().name]: Object.values(getReportItemsSlice().actions).map((action) => action.type),
    // session: Object.values(slice.actions).map((action) => action.type),
    // admin: Object.values(adminSlice.actions).map((action) => action.type),
    [getCompaniesSlice().name]: Object.values(getCompaniesSlice().actions).map((action) => action.type),
  } as const);

export const getAllActions = () =>
  ({
    [getAlertsSlice().name]: getAlertsSlice().actions,
    [getGlobalSlice().name]: getGlobalSlice().actions,
    [getTemplatesSlice().name]: getTemplatesSlice().actions,
    [getTemplateRowsSlice().name]: getTemplateRowsSlice().actions,
    [getTxItemsSlice().name]: getTxItemsSlice().actions,
    [getCbTxSlice().name]: getCbTxSlice().actions,
    [getScenariosSlice().name]: getScenariosSlice().actions,
    [getDepartmentsSlice().name]: getDepartmentsSlice().actions,
    [getSanityChecksSlice().name]: getSanityChecksSlice().actions,
    [getDatasourcesSlice().name]: getDatasourcesSlice().actions,
    [getVersionsSlice().name]: getVersionsSlice().actions,
    [getIntegrationsSlice().name]: getIntegrationsSlice().actions,
    [getTeamsSlice().name]: getTeamsSlice().actions,
    [getEmployeesSlice().name]: getEmployeesSlice().actions,
    [getFinancialComponentsSlice().name]: getFinancialComponentsSlice().actions,
    [getReportsSlice().name]: getReportsSlice().actions,
    [getReportItemsSlice().name]: getReportItemsSlice().actions,
    session: slice.actions,
    admin: adminSlice.actions,
    [getCompaniesSlice().name]: getCompaniesSlice().actions,
  } as const);

export const getReducers = () => ({
  [getAlertsSlice().name]: getAlertsSlice().reducer,
  [getGlobalSlice().name]: getGlobalSlice().reducer,
  [getTemplatesSlice().name]: getTemplatesSlice().reducer,
  [getTemplateRowsSlice().name]: getTemplateRowsSlice().reducer,
  [getTxItemsSlice().name]: getTxItemsSlice().reducer,
  [getCbTxSlice().name]: getCbTxSlice().reducer,
  [getScenariosSlice().name]: getScenariosSlice().reducer,
  [getDepartmentsSlice().name]: getDepartmentsSlice().reducer,
  [getSanityChecksSlice().name]: getSanityChecksSlice().reducer,
  [getDatasourcesSlice().name]: getDatasourcesSlice().reducer,
  [getVersionsSlice().name]: getVersionsSlice().reducer,
  [getIntegrationsSlice().name]: getIntegrationsSlice().reducer,
  [getTeamsSlice().name]: getTeamsSlice().reducer,
  [getEmployeesSlice().name]: getEmployeesSlice().reducer,
  [getFinancialComponentsSlice().name]: getFinancialComponentsSlice().reducer,
  [getReportsSlice().name]: getReportsSlice().reducer,
  [getReportItemsSlice().name]: getReportItemsSlice().reducer,
  session: sessionReducer,
  admin: adminReducer,
  [getCompaniesSlice().name]: getCompaniesSlice().reducer,
});

export const sliceNamesToDisableWorkerSyncFor = ["cbTx", "admin", "workerAndBackend"];
