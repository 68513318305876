export const formulaFunctions = [
  //  {name: "ABS", description: "Returns the absolute value of a number.", example: "ABS(-10) // Returns 10"},
  //  {
  //   name: "ACCRINT",
  //   description: "Calculates the accrued interest for a security that pays periodic interest.",
  //   example: "ACCRINT(issue, first_interest, settlement, rate, par, frequency, [basis])",
  // },
  // {
  //   name: "AGGREGATE",
  //   description: "Returns an aggregate in a list or database.",
  //   example: "AGGREGATE(9, 6, A1:A10) // Returns the sum of A1:A10, ignoring errors",
  // },
  {
    name: "AND",
    description: "Returns TRUE if all of its arguments are TRUE.",
    example: "AND(A1>10, B1<5) // Returns TRUE if A1>10 and B1<5",
  },
  {
    name: "AVERAGE",
    description: "Returns the average (arithmetic mean) of its arguments.",
    example: "AVERAGE(A1:A10) // Returns the average of A1:A10",
    showWhenEmpty: true,
  },
  {
    name: "AVERAGEIF",
    description: "Returns the average (arithmetic mean) of all the cells in a range that meet a given criteria.",
    example: 'AVERAGEIF(A1:A10, ">5") // Returns the average of cells greater than 5',
  },
  {
    name: "AVERAGEIFS",
    description: "Returns the average (arithmetic mean) of all cells that meet multiple criteria.",
    example: 'AVERAGEIFS(A1:A10, B1:B10, ">5", C1:C10, "<10")',
  },
  {
    name: "CEILING",
    description: "Rounds a number up to the nearest integer or to the nearest multiple of significance.",
    example: "CEILING(4.3, 2) // Returns 6",
  },
  {
    name: "CHOOSE",
    description: "Chooses a value from a list of values.",
    example: 'CHOOSE(2, "Red", "Green", "Blue") // Returns "Green"',
  },
  {
    name: "CORREL",
    description: "Returns the correlation coefficient between two data sets.",
    example: "CORREL(A1:A10, B1:B10) // Returns the correlation coefficient",
  },
  {
    name: "COUNT",
    description: "Counts the number of cells that contain numbers.",
    example: "COUNT(A1:A10) // Returns the count of cells with numbers",
  },
  {
    name: "COUNTA",
    description: "Counts the number of cells that are not empty.",
    example: "COUNTA(A1:A10) // Returns the count of non-empty cells",
  },
  {
    name: "COUNTIF",
    description: "Counts the number of cells within a range that meet a single condition.",
    example: 'COUNTIF(A1:A10, ">5") // Returns the count of cells greater than 5',
  },
  {
    name: "COUNTIFS",
    description: "Counts the number of cells specified by a set of conditions or criteria.",
    example: 'COUNTIFS(A1:A10, ">5", B1:B10, "<10")',
  },
  {
    name: "COVARIANCE.P",
    description: "Calculates the population covariance of two data sets.",
    example: "COVARIANCE.P(A1:A10, B1:B10) // Returns the population covariance",
  },
  {
    name: "COVARIANCE.S",
    description: "Calculates the sample covariance of two data sets.",
    example: "COVARIANCE.S(A1:A10, B1:B10) // Returns the sample covariance",
  },
  // {
  //    name: "DATE",
  //   description: "Returns the serial number of a particular date.",
  //   example: "DATE(2020, 5, 20) // Returns the date 20-May-2020",
  // },
  //  {
  //   name: "DAY",
  //   description: "Returns the day of a date, represented by a serial number.",
  //   example: 'DAY("20-May-2020") // Returns 20',
  // },
  // {
  //    name: "DAYS",
  //   description: "Returns the number of days between two dates.",
  //   example: 'DAYS("2020-05-20", "2020-05-10") // Returns 10',
  // },
  // {
  //    name: "DAYS360",
  //   description: "Calculates the number of days between two dates based on a 360-day year.",
  //   example: 'DAYS360("2020-01-01", "2020-12-31") // Returns 360',
  // },
  //  {
  //   name: "EDATE",
  //   description:
  //     "Returns the serial number of the date that is the indicated number of months before or after the start date.",
  //   example: 'EDATE("2020-01-01", 1) // Returns the date 1-Feb-2020',
  // },
  //  {
  //   name: "EOMONTH",
  //   description:
  //     "Returns the serial number for the last day of the month that is the indicated number of months before or after start_date.",
  //   example: 'EOMONTH("2020-01-01", 1) // Returns the date 29-Feb-2020',
  // },
  {name: "EVEN", description: "Rounds a number up to the nearest even integer.", example: "EVEN(3) // Returns 4"},
  {
    name: "FORECAST",
    description: "Returns a value along a linear trend.",
    example: "FORECAST(10, A1:A10, B1:B10) // Returns the forecasted value at x=10",
  },
  {
    name: "FV",
    description: "Returns the future value of an investment.",
    example: "FV(0.05/12, 12*10, -100, -1000, 0) // Returns the future value",
  },
  {
    name: "IF",
    description: "Specifies a logical test to perform.",
    example: 'IF(A1>10, "Pass", "Fail") // Returns "Pass" if A1>10, otherwise "Fail"',
  },
  {
    name: "IPMT",
    description: "Returns the interest payment for an investment for a given period.",
    example: "IPMT(0.05/12, 1, 60, 1000) // Returns the interest payment for the first month",
  },
  {
    name: "IRR",
    description: "Returns the internal rate of return for a series of cash flows.",
    example: "IRR(A1:A10) // Returns the internal rate of return",
  },
  //  {
  //   name: "ISNUMBER",
  //   description: "Checks whether a value is a number, and returns TRUE or FALSE.",
  //   example: "ISNUMBER(A1) // Returns TRUE if A1 is a number, otherwise FALSE",
  // },
  {name: "LN", description: "Returns the natural logarithm of a number.", example: "LN(10) // Returns 2.302585"},
  {
    name: "LOG",
    description: "Returns the logarithm of a number to a specified base.",
    example: "LOG(100, 10) // Returns 2",
  },
  {name: "LOG10", description: "Returns the base-10 logarithm of a number.", example: "LOG10(100) // Returns 2"},
  //  {
  //   name: "MATCH",
  //   description: "Returns the relative position of an item in an array that matches a specified value.",
  //   example: "MATCH(10, A1:A10, 0) // Returns the position of 10 in A1:A10",
  // },
  {
    name: "MAX",
    description: "Returns the maximum value in a list of arguments.",
    example: "MAX(A1:A10) // Returns the maximum value in A1:A10",
    showWhenEmpty: true,
  },
  {
    name: "MEDIAN",
    description: "Returns the median of the given numbers.",
    example: "MEDIAN(A1:A10) // Returns the median of A1:A10",
    showWhenEmpty: true,
  },
  {
    name: "MIN",
    description: "Returns the minimum value in a list of arguments.",
    example: "MIN(A1:A10) // Returns the minimum value in A1:A10",
    showWhenEmpty: true,
  },
  //  {name: "MINUTE", description: "Returns the minute of a time value.", example: 'MINUTE("12:30:45 PM") // Returns 30'},
  //  {
  //   name: "MONTH",
  //   description: "Returns the month of a date represented by a serial number.",
  //   example: 'MONTH("20-May-2020") // Returns 5',
  // },
  {
    name: "NPV",
    description:
      "Calculates the net present value of an investment based on a series of periodic cash flows and a discount rate.",
    example: "NPV(0.05, A1:A10) // Returns the net present value",
  },
  {
    name: "PERCENTILE.EXC",
    description: "Returns the k-th percentile of values in a range, where k is in the range 0..1, exclusive.",
    example: "PERCENTILE.EXC(A1:A10, 0.5) // Returns the 50th percentile",
  },
  {
    name: "PERCENTILE.INC",
    description: "Returns the k-th percentile of values in a range, where k is in the range 0..1, inclusive.",
    example: "PERCENTILE.INC(A1:A10, 0.5) // Returns the 50th percentile",
  },
  {
    name: "PMT",
    description: "Calculates the payment for a loan based on constant payments and a constant interest rate.",
    example: "PMT(0.05/12, 60, 1000) // Returns the monthly payment",
  },
  {
    name: "PV",
    description: "Returns the present value of an investment.",
    example: "PV(0.05/12, 60, -100, -1000, 0) // Returns the present value",
  },
  {
    name: "QUARTILE.EXC",
    description: "Returns the quartile of the data set, based on percentile values from 0..1, exclusive.",
    example: "QUARTILE.EXC(A1:A10, 1) // Returns the 1st quartile",
  },
  {
    name: "QUARTILE.INC",
    description: "Returns the quartile of the data set, based on percentile values from 0..1, inclusive.",
    example: "QUARTILE.INC(A1:A10, 1) // Returns the 1st quartile",
  },
  {
    name: "RANK.AVG",
    description: "Returns the rank of a number in a list of numbers, using average ranking for ties.",
    example: "RANK.AVG(A1, A1:A10) // Returns the rank of A1 in A1:A10",
  },
  {
    name: "RANK.EQ",
    description: "Returns the rank of a number in a list of numbers.",
    example: "RANK.EQ(A1, A1:A10) // Returns the rank of A1 in A1:A10",
  },
  {
    name: "RATE",
    description: "Returns the interest rate per period of an annuity.",
    example: "RATE(60, -100, 1000, 0) // Returns the interest rate per period",
  },
  {
    name: "SUM",
    description: "Adds its arguments.",
    example: "SUM(A1:A10) // Returns the sum of A1:A10",
    showWhenEmpty: true,
  },
  {
    name: "SUMIF",
    description: "Adds the cells specified by a given condition or criteria.",
    example: 'SUMIF(A1:A10, ">5") // Returns the sum of cells greater than 5',
  },
  {
    name: "SUMIFS",
    description: "Adds the cells in a range that meet multiple criteria.",
    example: 'SUMIFS(A1:A10, B1:B10, ">5", C1:C10, "<10")',
  },
  {
    name: "SUMPRODUCT",
    description: "Returns the sum of the products of corresponding ranges or arrays.",
    example: "SUMPRODUCT(A1:A10, B1:B10) // Returns the sum of products of A1:A10 and B1:B10",
  },
  //  {
  //   name: "VLOOKUP",
  //   description:
  //     "Looks for a value in the leftmost column of a table and returns a value in the same row from a column you specify.",
  //   example:
  //     'VLOOKUP("Smith", A1:C10, 2, FALSE) // Returns the value in the second column of the row where the first column matches "Smith"',
  // },
  {
    name: "XIRR",
    description: "Returns the internal rate of return for a schedule of cash flows that is not necessarily periodic.",
    example: "XIRR(A1:A10, B1:B10) // Returns the internal rate of return for non-periodic cash flows",
  },
  {
    name: "XNPV",
    description: "Returns the net present value for a schedule of cash flows that is not necessarily periodic.",
    example: "XNPV(0.05, A1:A10, B1:B10) // Returns the net present value for non-periodic cash flows",
  },
  //  {
  //   name: "YEAR",
  //   description: "Returns the year corresponding to a date.",
  //   example: 'YEAR("20-May-2020") // Returns 2020',
  // },
  //  {
  //   name: "YEARFRAC",
  //   description: "Returns the year fraction representing the number of whole days between start_date and end_date.",
  //   example: 'YEARFRAC("2020-01-01", "2020-12-31") // Returns the year fraction',
  // },
];

export const formulaFunctionsByName = {
  ...Object.fromEntries(formulaFunctions.map((f) => [f.name, f])),
  ...Object.fromEntries(formulaFunctions.map((f) => [f.name.toLowerCase(), f])),
};

export function getMatchingFunctions(search?: string) {
  if (typeof search === "undefined") return [];
  if (!search.length) return formulaFunctions.filter((fn) => fn.showWhenEmpty);

  const cleanedSearch = search.trim().toLowerCase();
  const functionsStartingWithSearch = formulaFunctions.filter(
    (f) => !cleanedSearch.length || f.name.toLowerCase().startsWith(search.toLowerCase()),
  );
  const functionsContainingSearch = formulaFunctions.filter(
    (f) => !functionsStartingWithSearch.includes(f) && f.name.toLowerCase().includes(search.toLowerCase()),
  );

  return [...functionsStartingWithSearch, ...functionsContainingSearch];
}
