import {store} from "@app/client-store";
import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import FormElement from "@components/FormElement";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import RoundButton from "@components/RoundButton";
import TextInput from "@components/TextInput";
import Formatting from "@features/shared-views/Formatting";
import {selectSelectedRow, selectSelectedTemplate} from "@features/templates/state/selectors";
import {getUniqueSlug} from "@shared/lib/slug";
import {upsertRow} from "@shared/state/template-rows/slice";
import {upsertTemplate} from "@shared/state/templates/slice";
import {selectVersionLocked} from "@state/global/slice";
import clsx from "clsx";

import SanityChecks from "./SanityChecks";
import useStyles from "./styles.jss";

import type {TemplateRow} from "@shared/types/db";

export interface SettingsTabProps {}

export default function SettingsTab({}: SettingsTabProps) {
  const styles = useStyles();
  const classes = clsx(styles.settingsTabMain);
  const dispatch = useAppThunkDispatch();
  const template = useAppSelector(selectSelectedTemplate);
  const row = useAppSelector(selectSelectedRow);
  const isVersionLocked = useAppSelector(selectVersionLocked);

  if (!row || !template) return null;

  const rowIndexInOrdering = template.ordering.findIndex((ordering) => ordering.rowId === row.id);

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    const rowIdsByName = store.getState().templateRows.idsByName;
    const newSlug = getUniqueSlug(evt.target.value, rowIdsByName, row.name).slug;
    if (newSlug !== row.name) {
      dispatch(upsertRow({...row, display_name: evt.target.value, name: newSlug}));
    }
  };

  const handleFormattingOptionsChange = (formatting: TemplateRow["formatting"]) => {
    dispatch(upsertRow({...row, formatting}));
  };

  const handleMoveRowClick =
    (direction: "up" | "down"): React.MouseEventHandler =>
    () => {
      const newOrdering = [...template.ordering];
      const removedItemFromOrdering = newOrdering.splice(rowIndexInOrdering, 1);
      if (direction === "up") {
        newOrdering.splice(rowIndexInOrdering - 1, 0, removedItemFromOrdering[0]);
      } else {
        newOrdering.splice(rowIndexInOrdering + 1, 0, removedItemFromOrdering[0]);
      }
      dispatch(upsertTemplate({...template, ordering: newOrdering}));
    };

  return (
    <>
      <Section title="Name & Slug" className={classes}>
        <SectionCol>
          <FormElement label="Row Name">
            <TextInput
              changeTrigger="blur"
              onChange={handleNameChange}
              value={row.display_name}
              disabled={template?.type !== "generic" || isVersionLocked}
            />
          </FormElement>
          <FormElement label="Slug Shortcut" subtext="(advanced users)">
            <TextInput disabled onChange={handleNameChange} value={row.name} />
          </FormElement>
          <FormElement label="Slug" subtext="(advanced users)">
            <TextInput disabled onChange={handleNameChange} value={`${template?.name}!${row.name}`} />
          </FormElement>
        </SectionCol>
      </Section>
      <Section title="Formatting">
        <SectionCol>
          <div className={styles.formatPositionWrapper}>
            <FormElement label="Row Format" tooltip="Templates::Sidebar::Settings::Formatting">
              <Formatting
                disabled={isVersionLocked}
                enabledOptions={[
                  "bold",
                  "italic",
                  "borderTop",
                  "borderBottom",
                  "indentation",
                  "decimals",
                  "percentage",
                  "currency",
                ]}
                onChange={handleFormattingOptionsChange}
                selectedOptions={row.formatting}
              />
            </FormElement>
            {template?.type === "generic" ? (
              <FormElement label="Position" className={styles.moveUpDown}>
                <div className={styles.upDownButtonsContainer}>
                  <RoundButton
                    icon="arrow"
                    mainColor="orange"
                    rotate={270}
                    iconColor="orange"
                    enableCssStates
                    containerSize={26}
                    disabled={rowIndexInOrdering === template.ordering.length - 1}
                    onClick={handleMoveRowClick("down")}
                  />
                  <RoundButton
                    icon="arrow"
                    mainColor="orange"
                    rotate={90}
                    iconColor="orange"
                    enableCssStates
                    containerSize={26}
                    disabled={rowIndexInOrdering === 0}
                    onClick={handleMoveRowClick("up")}
                  />
                </div>
              </FormElement>
            ) : null}
          </div>
        </SectionCol>
      </Section>
      <SanityChecks />
    </>
  );
}
