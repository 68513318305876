import color from "color";

const colors = {
  ui: {
    black: "#24232B",
    white: "#FFF",
    lightBlue01: "#7372A3",
    lightBlue02: "#9796BF",
    lightBlue03: "#B5B5D7",
    lightBlue04: "#E6E6F2",
    lightBlue05: "#E6E6F2",
    lightBlue06: "#E6E6F2",
    lightBlue07: "#F0F0F7",
    lightBlue08: "rgba(240, 240, 247, 0.7)",
    lightBlue09: "#E8E8FF",
    darkBlue01: "#323253",
    darkBlue02: "#474683",
  },
  primary: {
    gold: "#F9962C",
    lightGold01: "#FFAF59",
    lightGold03: "#FFDFBD",
    lightGold04: "#FFEDD9",
    lightGold05: "#FFF2E4",
    lightGold06: color("#FFDFBD").alpha(0.4).rgb().string(),
    lightOrange50: "rgba(255, 223, 189, 0.5)",
  },
  secondary: {
    green: "#2EBAB2",
    lightGreen: "#90DED9",
    lightGreen01: "#4BA19D",
    lightGreen02: "#2EBAB2",
    lightGreen03: "#96D9D6",
    lightGreen04: "#AACDCB",
    lightGreen05: "#ACE5E3",
    lightGreen08: "#D5F0EE",
    lightGreen09: color("#C3DEDC").alpha(0.3).rgb().string(),
    green50Opacity: "#C7EEEC",
  },
  tertiary: {
    blue: "#2A8EEB",
    blue01: "#566DBD",
    blue02: "#2A8EEB",
    darkBlue01: "#566DBD",
    lightBlue01: "#96C8F6",
    lightBlue02: "#DEECFF",
    lightBlue06: "#EAF4FE",
    lightBlue07: "#F4FAFF",
    customDarkBlue01: "#4F64B0",
    customDarkBlue02: "#657BC9",
  },
  reds: {
    red: "#FF6E6E",
    lightRed01: "#FF8C8C",
    lightRed02: "#FBA4A4",
    lightRed04: "#FFD4D4",
    lightRed05: "#FEE8E9",
  },
  text: {
    black: "#24232B",
    white: "#FFF",
    lightBlue01: "#7372A3",
    lightBlue02: "#9796BF",
    lightBlue03: "#D5D5E5",
    lightBlue04: "#E6E6F2",
    darkBlue01: "#323253",
    darkBlue02: "#474683",
  },
  other: {
    darkBlue01: "#566DBD",
    darkGreen02: "#2B827D",
  },
};

const states = {
  red: colors.reds.red,
  orange: colors.primary.gold,
  blue: colors.tertiary.darkBlue01,
  green: colors.secondary.green,
};

export default {...colors, states} as const;
