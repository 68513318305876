import {expose} from "comlink";
import {store} from "@app/client-store";
import {DEBUG} from "@shared/lib/debug-provider";
import {isWebWorker} from "browser-or-node";
import {startTransition} from "react";

import {getActualWorker, hasWorkerSupport} from "./singletons";

import type {AnyAction} from "@reduxjs/toolkit";
if (isWebWorker) console.log("!!!!!!!!!!!!!!! SHOULD NOT BE LOADED FROM WEB WORKER !!!!!!!!!!!!!!!");

export async function fireAction(action: AnyAction) {
  if (DEBUG) console.log(`Received action ${action.type} from worker in main thread`);
  startTransition(() => {
    store.dispatch(action);
  });
}

if (hasWorkerSupport && !isWebWorker) {
  const actualWorker = getActualWorker();
  expose({fireAction}, actualWorker);
}
