import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getCompaniesAdapter} from "@shared/state/entity-adapters";

import companiesAPI from "./api";

import type {RootState} from "@shared/state/store";

export const api = {
  fetchAll: createAsyncThunk("companies/fetchAll", companiesAPI.fetchAll),
  reset: createAsyncThunk("companies/reset", companiesAPI.reset),
  create: createAsyncThunk("companies/create", companiesAPI.create),
};

export const getSlice = () => {
  const companiesAdapter = getCompaniesAdapter();

  return createSlice({
    name: "companies",
    initialState: {
      ...companiesAdapter.getInitialState(),
      fetchingCompanies: false,
    },
    reducers: {
      upsertCompaniesLocal: companiesAdapter.upsertMany,
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchAll.fulfilled, (state, action) => {
        state.fetchingCompanies = false;
        companiesAdapter.upsertMany(state, action.payload);
      });
      builder.addCase(api.fetchAll.pending, (state) => {
        state.fetchingCompanies = true;
      });
      builder.addCase(api.fetchAll.rejected, (state) => {
        state.fetchingCompanies = false;
      });
    },
  });
};

const _slice = getSlice();
export const {upsertCompaniesLocal} = _slice.actions;

export const {
  selectById: selectCompanyById,
  selectIds: selectCompanyIds,
  selectEntities: selectCompanyEntities,
  selectAll: selectAllCompanies,
  selectTotal: selectTotalCompanies,
} = getCompaniesAdapter().getSelectors((state: RootState) => state.companies);
