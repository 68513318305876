import {store} from "@app/client-store";
import {parseCacheKey} from "@shared/data-functions/cache/cache-utilities";
import {isDsError, type AppAlert} from "@shared/types/alerts";
import {selectQboIntegrationVendors} from "@state/integrations/slice";

export function getErrorMessageHtml(error: AppAlert, short?: boolean) {
  let errorHtml = "";
  switch (error.type) {
    case "INVALID_REF": {
      errorHtml = `<b>Formula Error:</b> ${error.details.refType} with name <em>${error.details.invalidRefStr}</em> does not exist.`;
      break;
    }
    case "FORMULA_PARSE_ERROR": {
      errorHtml = `<b>Formula Error:</b> the formula syntax is invalid.`;
      break;
    }
    case "CIRCULAR_REF": {
      const state = store.getState();
      let cycleStrElements = [`this cell`];
      for (const item of error.details.cycle.slice(1)) {
        const parsedKey = parseCacheKey(item);
        const rowName = state.templateRows.entities[parsedKey.rowId ?? ""]?.name ?? null;
        const departmentName = parsedKey.departmentId ? state.departments.entities[parsedKey.departmentId]?.name : null;
        const vendorName = parsedKey.vendor ?? null;
        const dateKey = parsedKey.dateKey;
        let asStr = `[row: ${rowName}`;
        if (departmentName) asStr += `, department: ${departmentName}`;
        if (vendorName) asStr += `, vendor: ${vendorName}`;
        asStr += `, date: ${dateKey}]`;
        cycleStrElements.push(asStr);
      }
      cycleStrElements.push(`<b>this cell</b>`);

      // If the cycle elements has more than 4 elements, we'll just show the first two, then [... n more ...], then the last two
      if (cycleStrElements.length > 5) {
        cycleStrElements = [
          ...cycleStrElements.slice(0, 2),
          `[...${cycleStrElements.length - 4} more ...]`,
          ...cycleStrElements.slice(-2),
        ];
      }

      const cycleStr = cycleStrElements.join(" -> ");
      errorHtml = `<b>Formula Error:</b> circular reference detected: ${cycleStr}`;
      break;
    }
    default: {
      errorHtml = `<b>Error:</b> an unknown error occurred.`;
      break;
    }
  }

  return <span dangerouslySetInnerHTML={{__html: errorHtml}} />;
}

export function getErrorMessageText(error: AppAlert, short?: boolean) {
  const state = store.getState();
  let errorStr = "";
  switch (error.type) {
    case "INVALID_REF": {
      errorStr = `Formula Error: ${error.details.refType} with name "${error.details.invalidRefStr}" does not exist.`;
      break;
    }
    case "FORMULA_PARSE_ERROR": {
      errorStr = `Formula Error: The formula syntax is invalid.`;
      break;
    }
    case "CIRCULAR_REF": {
      errorStr = "Formula Error: Circular reference detected.";
      break;
    }
    case "SANITY_CHECK_FAILING": {
      let message = "A sanity check is failing.";
      const sanityCheck = state.sanityChecks.entities[error.sanity_check_id];
      const row = state.templateRows.entities[sanityCheck?.check_entity_identifier ?? ""];
      if (sanityCheck && row) {
        const numberOfMonthsFailing = error.details.months.length;
        const monthsStr = numberOfMonthsFailing === 1 ? "month" : "months";
        message = `Sanity check for row "${row.display_name}" is failing (check did not pass for ${numberOfMonthsFailing} ${monthsStr}).`;
      }
      errorStr = message;
      break;
    }

    default: {
      errorStr = "Error: An unknown error occurred.";
      break;
    }
  }

  return errorStr;
}

export function getErrorMessageSubtext(error: AppAlert, short?: boolean) {
  const state = store.getState();
  let subtextStr = "";
  const vendorsMapping = selectQboIntegrationVendors(state);
  let impactedCellsLocation: null | string = null;
  const datasource = isDsError(error) ? state.datasources.entities[error.datasource_id] ?? null : null;
  if (datasource) {
    const locationStrings: string[] = [];
    locationStrings.push(`Row: ${state.templateRows.entities[datasource.row_id]?.display_name ?? "N/A"}`);
    if (datasource.department_id) {
      locationStrings.push(
        `Department: ${state.departments.entities[datasource.department_id]?.display_name ?? "N/A"}`,
      );
    }
    if (datasource.dimensions?.vendor) {
      locationStrings.push(`Vendor: ${vendorsMapping[datasource.dimensions.vendor]?.displayName ?? "N/A"}`);
    }
    // Finally add the impacted range
    locationStrings.push(`Range: ${datasource.start ?? "N/A"} - ${datasource.end ?? "N/A"}`);
    if (locationStrings.length) {
      impactedCellsLocation = locationStrings.join(" | ");
    }
  }
  switch (error.type) {
    case "INVALID_REF":
    case "FORMULA_PARSE_ERROR":
    case "CIRCULAR_REF": {
      if (impactedCellsLocation) subtextStr = impactedCellsLocation;
      break;
    }

    default: {
      break;
    }
  }

  return subtextStr;
}

export function getErrorTemplateName(error: AppAlert) {
  const state = store.getState();
  let templateName = "error";
  if (isDsError(error)) {
    const datasource = state.datasources.entities[error.datasource_id];
    if (datasource) {
      const row = state.templateRows.entities[datasource.row_id];
      if (row) {
        const template = state.templates.entities[row.template_id];
        if (template) {
          templateName = template.display_name;
        }
      }
    }
  } else if (error.type === "SANITY_CHECK_FAILING") {
    const sanityCheck = state.sanityChecks.entities[error.sanity_check_id];
    if (sanityCheck) {
      const row = state.templateRows.entities[sanityCheck.check_entity_identifier];
      if (row) {
        const template = state.templates.entities[row.template_id];
        if (template) {
          templateName = template.display_name;
        }
      }
    }
  }
  return templateName;
}
