import {globalStyles} from "@app/styles/general-ui";
import dedent from "dedent";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  layout: {
    extend: globalStyles.text,
    display: "grid",
    height: "100vh",
    maxHeight: "100vh",
    gap: 0,
    gridTemplateRows: `${globalStyles.topNavHeight}px auto`,
    gridTemplateAreas: dedent`
    "leftNav topNav"
    "leftNav mainView"
    `,

    gridTemplateColumns: `0px auto`,
    "&$sidebarOpen": {gridTemplateColumns: `${globalStyles.leftNavWidth}px auto`},

    background: "white", // TODO: properly handle this once we decide what we do with different backgrounds
    "&$isReporting": {background: "#F5F9FC"},
  },
  leftNavGridArea: {
    height: "100vh",
    gridArea: "leftNav",
    position: "relative",
  },
  topNavGridArea: {
    background: "white",
    "$isReporting &": {background: "#F5F9FC"},
    gridArea: "topNav",
    zIndex: 2,
  },
  mainViewGridArea: {
    background: "white",
    "$isReporting &": {background: "#F5F9FC"},
    gridArea: "mainView",
    overflowY: "hidden",
    overflowX: "hidden",
    paddingRight: globalStyles.mainViewPaddingX,
    zIndex: 1,

    // "&$rightSidebarIsOpen": {
    //   transition: "transform 0.3s",
    //   "& > div:first-child, & > table": {
    //     // paddingRight: sidebarSharedValues.width,
    //     transform: `translateX(-${sidebarSharedValues.width - 25}px)`,
    //     // paddingLeft: sidebarSharedValues.width - 25,
    //   },
    // },
  },
  rightSidebarIsOpen: {},
  sidebarOpen: {},
  isReporting: {},
});
