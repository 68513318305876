import {addDeltaMonthsToDateKey} from "@shared/lib/date-utilities";

import daterangeShortcuts from "./daterange-shortcuts";

interface ResolvedDaterange {
  start: string;
  end: string;
  startIsDynamic: boolean;
  endIsDynamic: boolean;
}

type RangeType = "dynamic" | "static";

// TODO: when locales are customizable, update this
const dateRegex = /^(\d{4})\-(0[1-9]|1[012])(?:\-(0[1-9]|[12][0-9]|3[01]))?$/i;

const dateRangeComponentsRegexes: {
  [rangeType in RangeType]: {regex: RegExp; getDelta: (regexMatchResult: RegExpMatchArray) => number};
} = {
  static: {
    regex: /^(-?\d+)$/i,
    getDelta: (regexMatchResult) => parseInt(regexMatchResult[1], 10),
  },
  dynamic: {
    regex: /^-?(\d+)(f|a)$/i,
    getDelta: (regexMatchResult) => {
      const nb = parseInt(regexMatchResult[1], 10);
      return regexMatchResult[2] === "a" ? nb * -1 : nb;
    },
  },
} as const;

export function resolveDaterange(
  rangeStr: string,
  currentDateKey: string | null,
  lastMonthOfActuals: string,
): ResolvedDaterange;
export function resolveDaterange(
  start: string,
  end: string | null,
  currentDateKey: string | null,
  lastMonthOfActuals: string,
): ResolvedDaterange;
export function resolveDaterange(
  startOrRangeStr: string,
  endOrCurrentDateKey: string | null,
  currentDateKeyOrLmoa: string | null,
  lastMonthOfActualsOrUnused?: string | undefined,
): ResolvedDaterange {
  if (!startOrRangeStr?.length) {
    // throw Error("received empty range string (or start string)");
    startOrRangeStr = "this_month";
  }

  const range: Partial<ResolvedDaterange> = {};
  const currentDateKey = typeof lastMonthOfActualsOrUnused === "string" ? currentDateKeyOrLmoa : endOrCurrentDateKey;
  const lastMonthOfActuals =
    typeof lastMonthOfActualsOrUnused === "string" ? lastMonthOfActualsOrUnused : (currentDateKeyOrLmoa as string);

  const rangeComponents =
    typeof lastMonthOfActualsOrUnused === "string"
      ? [startOrRangeStr, endOrCurrentDateKey ?? startOrRangeStr]
      : startOrRangeStr.split(":");
  if (!rangeComponents[1]) rangeComponents[1] = rangeComponents[0];

  for (const startOrEnd of ["start", "end"] as const) {
    const startOrEndIsDynamic = `${startOrEnd}IsDynamic` as const;
    // If we have already resolved the "end" and we're in the "end" iteration, skip it.
    if (startOrEnd === "end" && !!range.end) break;

    const rangeComponentIndex = startOrEnd === "start" ? 0 : 1;
    // If this is the "end" loop iteration and there's no second component to the range
    const rangeComponent = rangeComponents[rangeComponentIndex];

    // If it's already a date, don't go further and just use it
    const regexMatch = rangeComponent.match(dateRegex);

    if (regexMatch) {
      // TODO: this will need to be updated to match days maybe? Only matches months
      range[startOrEnd] = `${regexMatch[1]}-${regexMatch[2]}`;
      if (regexMatch[3]) range[startOrEnd] += `-${regexMatch[3]}`;
    } else {
      // First, make sure we're working with a range specifier like -3:-1, -3a:-1a, etc.
      let delta = null;
      let rangeType: "dynamic" | "static" | null = null;

      if (delta === null && daterangeShortcuts[rangeComponent]) {
        const {range: shortcutRange, rangeType: key} = daterangeShortcuts[rangeComponent];

        // If this returned a dateRange with multiple components, restart the loop with that range instead
        if (shortcutRange?.includes(":")) {
          return resolveDaterange(shortcutRange, currentDateKey, lastMonthOfActuals);
        }
        const matchResult = shortcutRange.match(dateRangeComponentsRegexes[key].regex);
        if (matchResult) {
          delta = dateRangeComponentsRegexes[key].getDelta(matchResult);
          rangeType = key as RangeType;
          range[startOrEndIsDynamic] ||= key === "dynamic";
        }
      }

      if (delta === null) {
        for (const [key, {regex, getDelta}] of Object.entries(dateRangeComponentsRegexes)) {
          const rangeMatchResult = rangeComponent.match(regex);
          if (rangeMatchResult) {
            delta = getDelta(rangeMatchResult);
            rangeType = key as RangeType;
            range[startOrEndIsDynamic] ||= key === "dynamic";
            continue;
          }
        }
      }

      if (delta === null) {
        if (!daterangeShortcuts[rangeComponent]) {
          throw Error(`Daterange shortcut is invalid: "${rangeComponent}" (full range: "${startOrRangeStr}")`);
        } else {
          throw Error("Resolved delta was null");
        }
      }

      // If no currentDateey is provided but the range is static, fallback on the lastMonthOfActuals
      let dateKey: string;
      if (rangeType === "dynamic") {
        dateKey = lastMonthOfActuals;
      } else {
        if (currentDateKey) {
          dateKey = currentDateKey;
        } else {
          dateKey = lastMonthOfActuals;
        }
      }

      // Perf test I made to parse date and increment month: https://jsben.ch/EwCWe
      const newDate = addDeltaMonthsToDateKey(
        dateKey,
        rangeType === "dynamic" && delta < 0 ? delta + 1 : delta, // +1 when we reference actuals since dates are relative to last month of actuals
      );
      range[startOrEnd] = newDate;
    }
  }

  return range as ResolvedDaterange;
}
