import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  title: {
    display: "flex",
    alignItems: "center",

    "& > *": {
      marginLeft: 15,
    },
  },
  datasourcesDebugToolsMain: {
    padding: "10px",
    border: `1px solid ${colors.ui.lightBlue01}`,
    borderRadius: 5,
    marginTop: "10px",
    marginBottom: "10px",
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      "& thead > tr": {
        fontWeight: "bold",
        padding: 5,
      },
      "& th": {
        textAlign: "left",
        borderBottom: `1px solid ${colors.ui.lightBlue01}`,
        padding: 5,
      },
      "& td": {
        padding: 5,
        height: 30,
        borderBottom: `1px solid ${colors.ui.lightBlue01}`,
        verticalAlign: "middle",

        "& > div": {
          display: "flex",
          alignItems: "center",
        },
      },
      "& tr:last-child td": {
        borderBottom: 0,
        paddingBottom: 0,
      },
    },
  },
  dateCell: {
    "& > span:first-child": {fontWeight: 600},

    "& > span + span": {
      marginLeft: 8,
      color: colors.text.lightBlue01,
    },
  },
  colorCircle: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    marginRight: 10,
  },
  typeCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
