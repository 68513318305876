import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {isBalance} from "@shared/lib/row-utilities";
import {monthlyCacheProjKey, projKey, projKeyOmitNulls} from "@state/utils";

import {NO_DEPARTMENT_NAME} from "../formula/formula-utilities";
import {getAmountForDisplay} from "../transactions";
import {
  getDeptIdToChildrenMapping,
  getDeptIdToListOfParentsMapping,
  getRowIdToListOfParentsMapping,
} from "./algo-values-cache";
import {getQboClassToDeptIdMapping, noClassIdentifier, setValuesInCache} from "./cache-utilities";

import type {EntityId} from "@reduxjs/toolkit";
import type {CbTx, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";

export function generateDisplayValuesCache({
  state,
  algoValuesCache = {},
  departmentLevelRowIds,
  vendorLevelRowIdsDepartmentIds,
  globalDepartmentId = null,
  rowIds,
  calculateTotals = true,
  scenarioIds,
}: {
  state: RootState;
  algoValuesCache?: Record<string, number>;
  departmentLevelRowIds?: EntityId[];
  vendorLevelRowIdsDepartmentIds?: {rowId: EntityId; departmentId: EntityId}[];
  globalDepartmentId?: EntityId | null;
  rowIds?: EntityId[];
  calculateTotals?: boolean;
  scenarioIds?: EntityId[];
}) {
  // Initialize the cache to what we received from algoValuesCache
  const cache: Record<string, number> = {...algoValuesCache};

  const templateIdToDateKeysMapping: Record<EntityId, string[]> = {};

  const noClassDepartmentId = state.departments.idsByName[NO_DEPARTMENT_NAME] ?? "";
  const rowIdToListOfParentsMapping = getRowIdToListOfParentsMapping(state.templateRows);
  const deptIdToListOfParentsMapping = getDeptIdToListOfParentsMapping(state.departments);
  const deptIdToListOfChildrenMapping = getDeptIdToChildrenMapping(state.departments);

  const globalDepartmentIds = globalDepartmentId
    ? [globalDepartmentId, ...(deptIdToListOfChildrenMapping[globalDepartmentId] ?? [])]
    : [null];

  const qboClassToDeptIdMapping = getQboClassToDeptIdMapping(state.departments);

  // Generate a mapping of row ids to the departments in that row for which we have vendor level values
  const vendorLevelRowIdsMapping = Object.groupBy(vendorLevelRowIdsDepartmentIds ?? [], (item) => item.rowId);

  const rowIdToDepartmentLevelMapping = Object.fromEntries((departmentLevelRowIds ?? []).map((rowId) => [rowId, true]));

  rowIds ||= state.templateRows.ids;

  for (const scenarioId of scenarioIds ?? state.scenarios.ids) {
    for (const rowId of rowIds) {
      const row = state.templateRows.entities[rowId];
      if (!row) continue;
      const template = state.templates.entities[row.template_id];
      if (!template) continue;

      const isBalanceRow = isBalance(row);
      const showDepartmentLevelValues = rowIdToDepartmentLevelMapping[row.id];
      const isParent = !!state.templateRows.idsByParentRowId[row.id]?.length;

      // Generate a mapping of departments in this row for which we have vendor level values
      const departmentsWithVendorLevelValues = Object.fromEntries(
        vendorLevelRowIdsMapping[row.id]?.map((item) => [item.departmentId, true]) ?? [],
      );

      // Keep track of which sub rows we have values for (for balances)
      const balancePrefixesToTrack: Record<string, {start: string; end: string; deptOrVendorLevel: boolean}> = {};

      templateIdToDateKeysMapping[template.id] ||= getAllDateKeysBetween(template.options.start, template.options.end);
      for (const dateKey of templateIdToDateKeysMapping[template.id]) {
        for (const globalDeptIdFromMapping of globalDepartmentIds) {
          const cacheKey = projKeyOmitNulls(row.id, globalDeptIdFromMapping, scenarioId, dateKey);

          if (algoValuesCache[cacheKey] && isBalanceRow) {
            // If we already have a value for this cache key here, it means it was computed by the algo and we don't need to recompute it
            // We will still need to validate that it has a balance later though, so update the start and end dates nonetheless
            const key = projKeyOmitNulls(row.id, globalDeptIdFromMapping);
            updateBalancePrefixesToTrack(balancePrefixesToTrack, key, dateKey, false);
          }
          const rowCacheKeyExists = !!algoValuesCache[cacheKey];

          if (!rowCacheKeyExists || showDepartmentLevelValues) {
            const rowDateKeyCbTxIds = state.cbTx.idsByRowIdDateKey[monthlyCacheProjKey(row.id, scenarioId, dateKey)];
            for (const cbTxId of rowDateKeyCbTxIds ?? []) {
              const cbTx = state.cbTx.entities[cbTxId];
              if (!cbTx) continue;

              // If the whole cache is scoped to a department, make sure the transaction matches it
              let txDepartmentId: string | null | undefined = cbTx.department_id;
              if (!txDepartmentId)
                txDepartmentId = cbTx.tags.qbo_class
                  ? qboClassToDeptIdMapping[cbTx.tags.qbo_class || noClassIdentifier] ?? noClassDepartmentId
                  : noClassDepartmentId;
              if (globalDeptIdFromMapping && txDepartmentId !== globalDeptIdFromMapping) continue;

              const isParentDept = !!txDepartmentId && !!state.departments.idsByParent[txDepartmentId]?.length;

              const valueBelongsToExpandedDeptLevelSubRow = showDepartmentLevelValues;

              if (rowCacheKeyExists && !valueBelongsToExpandedDeptLevelSubRow) continue;
              // Get the value to add to the cache for this transaction
              // Stop loop iteration if there's no value or it's 0
              const value = getCacheValueFromTx(cbTx, row);
              if (!value) continue;

              // If the value wasn't present in the cache, add it here
              if (!rowCacheKeyExists) {
                setValuesInCache({
                  cache,
                  keyFilter: null,
                  keysToSkip: null,
                  value,
                  rowId: cbTx.row_id,
                  departmentIdToTagWith: null,
                  txDepartmentId: null,
                  vendor: null,
                  scenarioId: cbTx.scenario_id,
                  dateKey,
                  isParentRow: isParent,
                  isParentDept,
                  rowIdToListOfParentsMapping,
                  deptIdToListOfParentsMapping,
                  balance: false,
                  // omitTotal: !calculateTotals,
                  omitTotal: true,
                });

                // We have a value for this row, so keep track of the start and end dates if it's a balance row
                if (isBalanceRow) {
                  const key = projKeyOmitNulls(row.id, globalDeptIdFromMapping);
                  updateBalancePrefixesToTrack(balancePrefixesToTrack, key, dateKey, false);
                }
              }

              // If this is a row for which we want to display department-level values,
              // add this transaction's value to its department scoped cache key here
              // Make sure to avoid adding it a second time if it's the same department than the globalDeptIdFromMapping

              if (valueBelongsToExpandedDeptLevelSubRow) {
                setValuesInCache({
                  cache,
                  keyFilter: null,
                  keysToSkip: null,
                  value,
                  rowId: cbTx.row_id,
                  departmentIdToTagWith: txDepartmentId,
                  txDepartmentId,
                  vendor: null,
                  scenarioId: cbTx.scenario_id,
                  dateKey,
                  isParentRow: isParent,
                  isParentDept,
                  rowIdToListOfParentsMapping,
                  deptIdToListOfParentsMapping,
                  balance: false,
                  omitTotal: true,
                });
                if (isBalanceRow) {
                  const key = projKeyOmitNulls(row.id, txDepartmentId);
                  updateBalancePrefixesToTrack(balancePrefixesToTrack, key, dateKey, true);
                }

                // If we also want to display vendor-level values for this department, add it here if the tx has a vendor
                // skip is value is in parent dept - this is not supported yet
                if (departmentsWithVendorLevelValues[txDepartmentId]) {
                  setValuesInCache({
                    cache,
                    keyFilter: null,
                    keysToSkip: null,
                    value,
                    rowId: cbTx.row_id,
                    departmentIdToTagWith: txDepartmentId,
                    txDepartmentId,
                    vendor: cbTx.tags.qbo_vendor_id ?? "no_vendor",
                    scenarioId: cbTx.scenario_id,
                    dateKey,
                    isParentRow: isParent,
                    isParentDept,
                    rowIdToListOfParentsMapping,
                    deptIdToListOfParentsMapping,
                    balance: false,
                    omitTotal: true,
                  });
                  if (isBalanceRow) {
                    const key = projKeyOmitNulls(row.id, txDepartmentId, cbTx.tags.qbo_vendor_id);
                    updateBalancePrefixesToTrack(balancePrefixesToTrack, key, dateKey, true);
                  }
                }
              }
            }
          }
        }
      }

      // Create balances for accounts that are balances
      if (isBalanceRow) {
        for (const [prefix, {start, end, deptOrVendorLevel}] of Object.entries(balancePrefixesToTrack)) {
          let prevBalance: number | null = null;

          const dateKeys = getAllDateKeysBetween(start ?? template.options.start, end ?? template.options.end);
          for (const dateKey of dateKeys) {
            const lookupKey = projKey(prefix, scenarioId, dateKey);
            const balanceLookupKey = `${lookupKey}::balance`;

            let sanitizedNewBalance = 0;
            if (typeof algoValuesCache[balanceLookupKey] === "undefined") {
              const ownValue = cache[lookupKey] ?? 0;

              const newBalance: number = (prevBalance ?? 0) + ownValue;

              // Remove rounding annoyances, for example values like 0.0000000000001
              sanitizedNewBalance = newBalance && (newBalance > 0.0000001 || newBalance < -0.0000001) ? newBalance : 0;

              if (sanitizedNewBalance) {
                const newValue = !cache[balanceLookupKey]
                  ? sanitizedNewBalance
                  : cache[balanceLookupKey] + sanitizedNewBalance;
                if (newValue) {
                  cache[balanceLookupKey] = newValue;
                } else if (cache[balanceLookupKey]) {
                  delete cache[balanceLookupKey];
                }
              } else {
                sanitizedNewBalance = algoValuesCache[balanceLookupKey];
              }

              // If this is not an expanded subrow (dept / vendor), propagate the balance to the parents
              if (sanitizedNewBalance && !deptOrVendorLevel && rowIdToListOfParentsMapping[rowId]?.length) {
                for (const id of rowIdToListOfParentsMapping[rowId]) {
                  for (const globalDeptIdFromMapping of globalDepartmentIds) {
                    let rowLevelLookupKeyTotal = projKeyOmitNulls(
                      id,
                      globalDeptIdFromMapping,
                      null,
                      scenarioId,
                      dateKey,
                      "balance",
                      "total",
                    );
                    cache[rowLevelLookupKeyTotal] = !cache[rowLevelLookupKeyTotal]
                      ? sanitizedNewBalance
                      : cache[rowLevelLookupKeyTotal] + sanitizedNewBalance;
                  }
                }
              }
            } else {
              sanitizedNewBalance = algoValuesCache[balanceLookupKey];
            }

            prevBalance = sanitizedNewBalance;
          }
        }
      }
    }
  }

  if (calculateTotals) {
    addMissingTotalsToCache(cache, state, templateIdToDateKeysMapping, scenarioIds ?? state.scenarios.ids);
  }

  return {...cache, ...algoValuesCache};
}

function addMissingTotalsToCache(
  cache: Record<string, number>,
  state: RootState,
  templateIdToDateKeysMapping: Record<string, string[]>,
  scenarioIds: EntityId[],
) {
  const resolvedCacheKeys: Record<string, boolean> = {};

  // Add missing totals to the cache
  function recursive(rowIds: string[]) {
    for (const rowId of rowIds) {
      const row = state.templateRows.entities[rowId];
      if (!row) continue;
      const template = state.templates.entities[row.template_id];
      if (!template) continue;

      const rowChildrenIds = state.templateRows.idsByParentRowId[row.id] ?? [];
      if (rowChildrenIds.length) recursive(rowChildrenIds);

      const rowIsBalance = isBalance(row);

      templateIdToDateKeysMapping[template.id] ||= getAllDateKeysBetween(template.options.start, template.options.end);
      const dateKeys = templateIdToDateKeysMapping[template.id];

      for (const scenarioId of scenarioIds) {
        for (const dateKey of dateKeys) {
          const cacheKey = projKeyOmitNulls(row.id, scenarioId, dateKey, rowIsBalance ? "balance" : null, "total");
          let totalValue = cache[cacheKey];

          if (typeof totalValue === "undefined" && !resolvedCacheKeys[cacheKey]) {
            // If we don't have a total for this row, add it to the cache
            const selfValueCacheKey = projKeyOmitNulls(row.id, scenarioId, dateKey, rowIsBalance ? "balance" : null);
            totalValue = cache[selfValueCacheKey] ?? 0;
            for (const childId of rowChildrenIds) {
              const childRow = state.templateRows.entities[childId];
              const childRowIsBalance = isBalance(childRow);
              const childTotalCacheKey = projKeyOmitNulls(
                childId,
                scenarioId,
                dateKey,
                childRowIsBalance ? "balance" : null,
                "total",
              );
              const childTotalValue = cache[childTotalCacheKey];
              if (typeof childTotalValue !== "undefined") totalValue += childTotalValue;
            }
            if (totalValue) {
              cache[cacheKey] = totalValue;
            } else if (cache[cacheKey]) {
              delete cache[cacheKey];
            }
          }

          resolvedCacheKeys[cacheKey] = true;
        }
      }
    }
  }

  recursive(state.templateRows.idsByParentRowId["null"] ?? []);
}

function updateBalancePrefixesToTrack(
  balancePrefixesToTrack: Record<string, {start: string; end: string; deptOrVendorLevel: boolean}>,
  key: string,
  dateKey: string,
  deptOrVendorLevel: boolean,
) {
  balancePrefixesToTrack[key] ||= {start: dateKey, end: dateKey, deptOrVendorLevel};
  if (dateKey < balancePrefixesToTrack[key].start) balancePrefixesToTrack[key].start = dateKey;
  if (dateKey > balancePrefixesToTrack[key].end) balancePrefixesToTrack[key].end = dateKey;
}

function getCacheValueFromTx(cbTx: CbTx, row: TemplateRow) {
  const value = getAmountForDisplay({
    amount: cbTx.amount,
    classification: row.type === "account" ? row.options.classification : undefined,
    reverseSign: !!(row.type === "account" && row.options.reverseSign),
    type: cbTx.amount_type ?? null,
  });
  const sanitizedValue = value && (value > 0.0000001 || value < -0.0000001) ? value : 0;
  return sanitizedValue;
}
