import {getFormattedKey, getUpdatedUtilityFunctions} from "./projection-aware-utility-fns";

import type {
  Comparer,
  EntityAdapter,
  EntitySelectors,
  EntityState,
  EntityStateAdapter,
  IdSelector,
  Dictionary as ReduxToolkitDictionary,
} from "@reduxjs/toolkit";
import type {SnakeToCamelCase} from "@shared/types/misc";

type Filter<T, U> = T extends U ? T : never;
type Diff<T, U> = T extends U ? never : T;

// This should be a dictionary of EntityId or EntityId[] but for this use-case
// it's more convenient to just use string
// type StateReturnType<P, K, B, M> = M extends false
//   ? Dictionary<
//       ReduxToolkitDictionary<string>,
//       B extends true ? `by${Capitalize<SnakeToCamelCase<string & K>>}` : string & K
//     >
//   : Dictionary<
//       ReduxToolkitDictionary<string[]>,
//       B extends true ? `by${Capitalize<SnakeToCamelCase<string & K>>}` : string & K
//     >;

type StateReturnType<K, B, M> = M extends false
  ? {
      [key in B extends true
        ? `idsBy${Capitalize<SnakeToCamelCase<string & K>>}`
        : string & K]: ReduxToolkitDictionary<string>;
    }
  : {
      [key in B extends true ? `idsBy${Capitalize<SnakeToCamelCase<string & K>>}` : string & K]: ReduxToolkitDictionary<
        string[]
      >;
    };
const getEmptyAdditionalState = <
  P extends
    | ({name?: N; keyToProject: KP | KP[]} & CacheProjection<E>)
    | ({name: N} & CacheProjectionWithKeyProvider<E>),
  E,
  K,
  B extends boolean,
  N extends string,
  KP extends keyof E & string,
  M extends boolean,
>(
  filteredProjections: P[],
): StateReturnType<K, B, M> => {
  const obj: any = {};
  for (const projection of filteredProjections) {
    const key =
      typeof projection.keyToProject === "string" && !projection.name
        ? getFormattedKey("idsBy", projection.keyToProject)
        : (projection.name as string);
    obj[key] = {};
  }
  const typedObj: StateReturnType<K, B, M> = obj as StateReturnType<K, B, M>;

  return typedObj;
};

type ProjectionType = "one-to-one" | "one-to-many";
export type CacheProjection<E> = {
  name?: string;
  type: ProjectionType;
  keyToProject: (keyof E & string) | (keyof E & string);
  ignoreNulls?: boolean;
};

export type CacheProjectionWithKeyProvider<E> = {
  name: string;
  type: ProjectionType;
  keyToProject: (entity: E) => string | string[] | undefined | null;
  ignoreNulls?: boolean;
};

export type MergedCacheProjectionTypes<E, K = keyof E & string, N = string> =
  | ({name?: N; keyToProject: K} & CacheProjection<E>)
  | ({name: N} & CacheProjectionWithKeyProvider<E>);

export function addCacheProjectionsToEntityAdapter<
  E,
  P extends MergedCacheProjectionTypes<E, K, N>,
  N extends string,
  K extends keyof E & string,
>(entityAdapter: EntityAdapter<E>, projections: readonly P[]) {
  const originalGetInitialState = entityAdapter.getInitialState;

  const oneToOneProjections = projections.filter((item) => item.type === "one-to-one");
  const oneToManyProjections = projections.filter((item) => item.type === "one-to-many");
  const oneToOneNamedProjections = projections.filter((item) => item.type === "one-to-one" && !!item.name);
  const oneToManyNamedProjections = projections.filter((item) => item.type === "one-to-many" && !!item.name);

  type OneToOneAll = Filter<P, {type: "one-to-one"}>;
  type OneToManyAll = Filter<P, {type: "one-to-many"}>;
  type OneToOneWithoutName = Diff<OneToOneAll, Filter<P, {name: string}>>["keyToProject"];
  type OneToManyWithoutName = Diff<OneToManyAll, Filter<P, {name: string}>>["keyToProject"];
  type OneToOneWithName = Filter<OneToOneAll, {name: string}>["name"];
  type OneToManyWithName = Filter<OneToManyAll, {name: string}>["name"];

  const additionalOneToOneState = getEmptyAdditionalState<
    P,
    E,
    OneToOneWithoutName extends boolean ? never : OneToOneWithoutName,
    true,
    N,
    K,
    false
  >(oneToOneProjections);
  const additionalOneToManyState = getEmptyAdditionalState<
    P,
    E,
    OneToManyWithoutName extends boolean ? never : OneToManyWithoutName,
    true,
    N,
    K,
    true
  >(oneToManyProjections);
  const additionalNamedOneToOneState = getEmptyAdditionalState<
    P,
    E,
    OneToOneWithName extends boolean ? never : OneToOneWithName,
    false,
    N,
    K,
    false
  >(oneToOneNamedProjections);
  const additionalNamedOneToManyState = getEmptyAdditionalState<
    P,
    E,
    OneToManyWithName extends boolean ? never : OneToManyWithName,
    false,
    N,
    K,
    true
  >(oneToManyNamedProjections);

  // const debug1 = {} as OneToOneWithName;
  // const debug2 = {} as OneToManyWithoutName;
  // const debug3 = {} as OneToManyWithName;

  type UnifiedStateType = EntityState<E> &
    typeof additionalOneToOneState &
    typeof additionalOneToManyState &
    typeof additionalNamedOneToOneState &
    typeof additionalNamedOneToManyState;

  function getInitialState(): UnifiedStateType {
    return originalGetInitialState({
      ...additionalOneToOneState,
      ...additionalOneToManyState,
      ...additionalNamedOneToOneState,
      ...additionalNamedOneToManyState,
    }) as UnifiedStateType;
  }

  interface EntityAdapterWithProjections<T> extends EntityStateAdapter<T> {
    selectId: IdSelector<T>;
    sortComparer: false | Comparer<T>;
    getInitialState(): UnifiedStateType;
    getInitialState<S extends object>(state: S): UnifiedStateType & S;
    getSelectors(): EntitySelectors<T, UnifiedStateType>;
    getSelectors<V>(selectState: (state: V) => UnifiedStateType): EntitySelectors<T, V>;
  }

  const updatedUtilityFns = getUpdatedUtilityFunctions<E, K, N, UnifiedStateType, MergedCacheProjectionTypes<E, K, N>>(
    entityAdapter,
    projections,
  );

  return {
    ...entityAdapter,
    getInitialState,
    ...updatedUtilityFns,
    getSelectors: entityAdapter.getSelectors,
    // additionalOneToOneState,
    // additionalOneToManyState,
    // additionalNamedOneToOneState,
    // additionalNamedOneToManyState,
    // debug1,
    // debug2,
    // debug3,
  } as EntityAdapterWithProjections<E>;
}

/*const baseAdapter = createEntityAdapter<Employee>();

const employeeAdapter = addCacheProjectionsToEntityAdapter(baseAdapter, [
  {keyToProject: "role", type: "one-to-many"},
  //{keyToProject: "slug", type: "one-to-one", name: "bar"},
  {keyToProject: "team_id", type: "one-to-one"},
  //{keyToProject: "first_name", type: "one-to-many", name: "baz"},
  //{keyToProject: (entity: Employee) => entity.middle_name as string, type: "one-to-many", name: "middleNNName"},
]);

type debug1 = typeof employeeAdapter.debug1;
type debug2 = typeof employeeAdapter.debug2;
type debug3 = typeof employeeAdapter.debug3;

type additionalOneToOneState = typeof employeeAdapter.additionalOneToOneState;
type additionalOneToManyState = typeof employeeAdapter.additionalOneToManyState;
type additionalNamedOneToOneState = typeof employeeAdapter.additionalNamedOneToOneState;
type additionalNamedOneToManyState = typeof employeeAdapter.additionalNamedOneToManyState;

const initialState = employeeAdapter.getInitialState();

const ttt = {} as Pick<typeof initialState, "">;

initialState.idsByRole;
initialState.idsByTeamId;
initialState.idsBySlug;
initialState.idsByBar;
initialState.bar;
initialState.idsByBaz;
initialState.idsByFirstName;
initialState.baz;
initialState.middleNNName;*/
