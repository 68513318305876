import {useAppSelector} from "@app/hooks";
import {selectors} from "@app/ui-state/slice";
import HeadsUpNotification from "@components/HeadsUpNotification";
import {lazy, Suspense} from "react";
import {createPortal} from "react-dom";

import useStyles from "./styles.jss";

const AnimatePresence = lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));

export default function HeadsUpNotifications() {
  const styles = useStyles();
  const notifs = useAppSelector(selectors.headsUpNotifications);

  const domElement = document.querySelector("#root");

  if (!domElement) return null;

  return createPortal(
    <Suspense>
      <div className={styles.headsUpNotificationsMain}>
        <div className={styles.listWrapper}>
          <AnimatePresence>
            {notifs.map((notif) => (
              <HeadsUpNotification key={notif.id} {...notif} />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </Suspense>,
    domElement,
  );
}
