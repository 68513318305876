import {useAppSelector} from "@app/hooks";
import {itemIsNotFalsy} from "@shared/lib/misc";
import {selectEmployeeById} from "@shared/state/employees/slice";
import {selectScenarioId} from "@shared/state/global/slice";
import {selectTeamById} from "@shared/state/teams/slice";

import type {RootState} from "@shared/state/store";
import type {HiringPlanTemplateRow, TemplateRow} from "@shared/types/db";
import type {Employee, ScenarioSpecificEmployeeProps, Team} from "@shared/types/hiring-plan";

export function useSelectedEmployeeDetails() {
  const {selectedEmployeeId} = useAppSelector((state) => state.hiringPlan);
  const scenarioId = useAppSelector(selectScenarioId);
  const employee = useAppSelector((state: RootState) => selectEmployeeById(state, selectedEmployeeId || ""));

  return useEntityDetails(employee || null, scenarioId);
}
export function useEmployeeDetailsFromId(employeeId: string) {
  const scenarioId = useAppSelector(selectScenarioId);
  const employee = useAppSelector((state: RootState) => selectEmployeeById(state, employeeId));

  return useEntityDetails(employee || null, scenarioId);
}

export function useSelectedTeamDetails() {
  const {selectedTeamId} = useAppSelector((state) => state.hiringPlan);
  const scenarioId = useAppSelector(selectScenarioId);
  const team = useAppSelector((state: RootState) => selectTeamById(state, selectedTeamId || ""));

  return useEntityDetails(team || null, scenarioId);
}

export function useEntityDetails(
  entity: Employee | null,
  scenarioId: string | null,
): {
  entity: Employee | null;
  scenarioProperties: ScenarioSpecificEmployeeProps;
  scenarioId: string;
} | null;
export function useEntityDetails(
  entity: Team | null,
  scenarioId: string | null,
): {
  entity: Team | null;
  scenarioProperties: null;
  scenarioId: string;
} | null;
export function useEntityDetails(
  entity: any | null,
  scenarioId: string | null,
): {
  entity: Team | Employee | null;
  scenarioProperties: null | ScenarioSpecificEmployeeProps;
  scenarioId: string;
} | null {
  const type = typeof entity?.team_id === "undefined" ? "team" : "employee";

  let scenarioProperties = null;
  if (type === "employee") scenarioProperties = entity?.scenario_properties[scenarioId || ""];

  if (!entity || !scenarioId) return null;

  return {entity, scenarioProperties, scenarioId};
}

export function useSelectedEmployee() {
  const {selectedEmployeeId} = useAppSelector((state) => state.hiringPlan);

  const employee = useAppSelector((state: RootState) => selectEmployeeById(state, selectedEmployeeId || ""));

  return employee;
}

export function useSelectedTeam() {
  const {selectedTeamId} = useAppSelector((state) => state.hiringPlan);

  const team = useAppSelector((state: RootState) => selectTeamById(state, selectedTeamId || ""));

  return team;
}

function isHiringPlanRow(item: TemplateRow | undefined): item is HiringPlanTemplateRow {
  return itemIsNotFalsy(item) && item.type === "hiring-plan";
}

export function useEmployeeFcRows(employeeId: string) {
  const templateRowsState = useAppSelector((state) => state.templateRows);

  const employeeFcRowIds = useAppSelector((state) => state.templateRows.idsByEmployeeId[employeeId]);

  const employeeFcRows = (employeeFcRowIds || []).map((id) => templateRowsState.entities[id]).filter(isHiringPlanRow);

  return employeeFcRows;
}
