import {useAppDispatch} from "@app/hooks";
import {getMotionFadeIn} from "@app/styles/general-ui";
import {headsUpNotifPresets} from "@app/ui-state/presets";
import {removeHeadsUpNotification} from "@app/ui-state/slice";
import RoundButton from "@components/RoundButton";
import clsx from "clsx";
import {lazy} from "react";

import useStyles from "./styles.jss";

import type {HeadsUpNotifProps} from "@app/ui-state/slice";
import type {CSSProperties} from "react";

const MotionDiv = lazy(async () => ({default: (await import("framer-motion")).motion.div}));

export default function HeadsUpNotification({id, backgroundImage, preset, mainColor, title, text}: HeadsUpNotifProps) {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  if (preset) {
    title ||= headsUpNotifPresets[preset].title;
    backgroundImage ||= headsUpNotifPresets[preset].backgroundImage;
    mainColor ||= headsUpNotifPresets[preset].mainColor;
  }

  const classes = clsx(styles.headsUpNotificationMain, styles[mainColor || "blue"]);

  const mainStyles: CSSProperties = {
    backgroundImage: `url('${backgroundImage}')`,
  };

  const handleCloseClick = () => {
    dispatch(removeHeadsUpNotification(id));
  };

  return (
    <MotionDiv className={classes} style={mainStyles} {...getMotionFadeIn(0.25)}>
      <div className={styles.closeButton} onClick={handleCloseClick}>
        <RoundButton enableCssStates onClick={handleCloseClick} icon="cross" iconSize={22} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </MotionDiv>
  );
}
