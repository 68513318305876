import {useAppSelector} from "@app/hooks";
import Tabs from "@components/Tabs";
import {selectTemplateById} from "@shared/state/templates/slice";
import {useState} from "react";

import ActualsSyncTab from "./ActualsSync";
import DatePickerTab from "./DatePickerTab";
import useStyles from "./styles.jss";

import type {Tab} from "@components/Tabs";
import type {RootState} from "@shared/state/store";

const tabsList: Tab[] = [
  {name: "date-picker", value: "Date Period"},
  // {name: "settings", value: "Settings"},
  {name: "actuals-sync", value: "Actuals Sync"},
];

export default function TemplateOptions() {
  const styles = useStyles();
  const [selectedTab, setSelectedTab] = useState(tabsList[0]);
  const {templateId} = useAppSelector((state) => state.templatesView);
  const template = useAppSelector((state: RootState) => selectTemplateById(state, templateId || ""));

  if (!template) return null;

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
  };

  let tabComponent: JSX.Element | null = null;
  switch (selectedTab.name) {
    case "date-picker": {
      tabComponent = <DatePickerTab template={template} />;
      break;
    }
    // case "settings": {
    //   tabComponent = <SettingsTab />;
    //   break;
    // }
    case "actuals-sync": {
      tabComponent = <ActualsSyncTab template={template} />;
      break;
    }
  }

  return (
    <div className={styles.templateOptionsMain}>
      <Tabs tabs={tabsList} selectedTab={selectedTab} onChange={handleTabChange} />
      {tabComponent}
      <div className={styles.buttons}>
        {/*<Button color="orange" text="Apply" width={112} />*/}
        {/*<Button color="grey" onClick={onClose} text="Cancel" minimal />*/}
      </div>
    </div>
  );
}
