import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getFinancialComponentsAdapter} from "@shared/state/entity-adapters";

import {api as employeesAPI} from "../employees/slice";
import fcAPi from "./api";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {FinancialComponentsState} from "@shared/state/entity-adapters";
import type {FinancialComponent} from "@shared/types/hiring-plan";
import type {RootState} from "@state/store";

export type BasePayload = {
  name: keyof FinancialComponentsState["entities"] & string;
};

export const api = {
  upsert: createAsyncThunk("financial-components/upsert", fcAPi.upsertFc),
};

export const getSlice = () => {
  const financialComponentsAdapter = getFinancialComponentsAdapter();
  return createSlice({
    name: "financialComponents",
    initialState: financialComponentsAdapter.getInitialState(),
    reducers: {
      upsertFinancialComponentsLocal: financialComponentsAdapter.upsertMany,
      removeFinancialComponentsLocal: financialComponentsAdapter.removeMany,
      upsertFc: (state, action: PayloadAction<BasePayload & Partial<FinancialComponent>>) => {
        financialComponentsAdapter.upsertOne(state, action.payload as FinancialComponent);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(employeesAPI.fetchHiringPlanEntities.fulfilled, (state, action) => {
        financialComponentsAdapter.setAll(state, action.payload.financialComponents);
      });
    },
  });
};

const _slice = getSlice();

export const {upsertFc} = createSyncedActionCreators(_slice.actions);
export const {upsertFinancialComponentsLocal, removeFinancialComponentsLocal} = _slice.actions;

export const {
  selectById: selectFinancialComponentById,
  selectIds: selectFinancialComponentIds,
  selectEntities: selectFinancialComponentEntities,
  selectAll: selectAllfinancialComponents,
  selectTotal: selectTotalfinancialComponents,
} = getFinancialComponentsAdapter().getSelectors((state: RootState) => state.financialComponents);
