import {isNode, isWebWorker} from "browser-or-node";
import chalk from "chalk";
import prettyTime from "pretty-time";

export let DEBUG = false;
export let TIMING = process.env.NODE_ENV === "development";

export function setDebug(enabled?: boolean) {
  const newDebugValue = typeof enabled !== "undefined" ? enabled : !DEBUG;
  DEBUG = newDebugValue;
  if (DEBUG) return "Debugging: ENABLED";
  if (!DEBUG) return "Debugging: DISABLED";
}

export function setTiming(enabled?: boolean) {
  const newDebugValue = typeof enabled !== "undefined" ? enabled : !TIMING;
  TIMING = newDebugValue;
  if (TIMING) return "Timing: ENABLED";
  if (!TIMING) return "Timing: DISABLED";
}

const timers: Record<string, number> = {};
export function time(context: string, label: string) {
  if (!TIMING) return;
  if (timers[`${context}::${label}`]) {
    console.warn(`Timer already exists in context "${context}" with label "${label}". Overriding`);
  }
  const start = performance.now();
  timers[`${context}::${label}`] = start;
}

function getLogText(timer: boolean, context: string, text: string | null, time?: string) {
  const styles = [];
  let str = "";

  // Thread tag + context tag
  str += isNode ? `[${chalk.blueBright(context)}]` : `%c${isWebWorker ? "⚙ Worker" : "✪ Main"}%c %c⚑ ${context}`;
  if (!isNode) {
    styles.push(
      `color: white; padding: 1px 5px; background: ${
        isWebWorker ? "#7A0BC0" : "#AF0171"
      }; border-radius: 7px;  margin-left: ${timer ? "2px" : "0"}; font-weight: bold`,
      "color: white",
      "color: white; padding: 1px 5px; background: #2F58CD; border-radius: 7px; margin-left: 2px; font-weight: bold",
    );
  }

  // Timer if applicable
  if (timer && time) {
    str += `%c %c⧗ ${time}`;
    styles.push(
      "color: white",
      "color: white; padding: 1px 5px; background: #B85C38; border-radius: 7px; font-weight: bold",
    );
  }

  // Log text
  if (text) {
    str += `%c ${text}`;
    styles.push("color: #fff; font-weight: 500");
  }

  return [str, ...styles];
}

export function timeEnd(context: string, label: string, labelOverride?: string) {
  if (!TIMING) return;
  const start = timers[`${context}::${label}`];
  if (!start) {
    console.warn(`No timer found in context "${context}" with "${label}"`);
    return;
  }
  const duration = performance.now() - start;
  const timeStr = formatLogDuration(duration);

  // const {text, styles} = logPrefix(true);

  console.log(...getLogText(true, context, labelOverride ?? label, timeStr));
  timeClear(context, label);
}

const isTestNotInDebugMode = process.env.NODE_ENV === "test" && !process.env.TEST_DEBUG;
const isProd = process.env.NODE_ENV === "production";
export function log(context: string, text: any, ...extraArgs: any[]) {
  if (isTestNotInDebugMode || isProd) return;
  const extraDataToLog = typeof text !== "string" ? [text, ...extraArgs] : extraArgs;
  console.log(...getLogText(false, context, typeof text === "string" ? text : null), ...extraDataToLog);
}

export const timeClear = (context: string, label: string) => {
  if (!TIMING) return;
  if (timers[`${context}::${label}`]) delete timers[`${context}::${label}`];
};

export function formatLogDuration(ms: number, perfNow?: [number, number]) {
  const [milliseconds = "0", decimals] = ms.toFixed(6).split(".");
  const seconds = milliseconds.slice(0, -3) || "0";
  const microseconds = decimals.slice(0, 3);
  const nanoseconds = decimals.slice(-3);

  const secondsAsNumber = parseInt(seconds);
  const millisecondsAsNumber = parseInt(milliseconds);
  const microsecondsAsNumber = parseInt(microseconds);
  const nanosecondsAsNumber = parseInt(nanoseconds);

  if (secondsAsNumber === 0 && millisecondsAsNumber === 0 && microsecondsAsNumber === 0) {
    return `${nanosecondsAsNumber.toFixed(0)}ns`;
  } else if (secondsAsNumber === 0 && millisecondsAsNumber === 0) {
    return `${roundDecimals(parseFloat(`${microseconds}.${nanoseconds}`))}μs`;
  } else if (secondsAsNumber === 0) {
    return `${roundDecimals(parseFloat(`${milliseconds}.${microseconds}`))}ms`;
  } else if (secondsAsNumber < 60) {
    return `${roundDecimals(parseFloat(`${secondsAsNumber}.${milliseconds}`))}s`;
  } else {
    const precision = secondsAsNumber < 60 ? "microseconds" : "milliseconds";
    return prettyTime(perfNow || [secondsAsNumber, microsecondsAsNumber], precision);
  }
}

export function getDuration(perfStart: number) {
  const perfNow = performance.now();
  return formatLogDuration(perfNow - perfStart);
}

function roundDecimals(num: number) {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
}

export type TraceLog = Record<string, TraceLogEntry[]> | null | undefined;
type TraceLogEntry = {time: number; value: LogTypes};

type LogTypes = Record<string, any> | number | boolean | string | null | undefined;
export function addToTraceLog(traceLog: TraceLog, stepName: string | string[], value: LogTypes | (() => LogTypes)) {
  if (!traceLog) return;
  if (typeof value === "function") value = value();
  const stepNameStr = Array.isArray(stepName) ? stepName.join(":") : stepName;
  if (!traceLog[stepNameStr]) traceLog[stepNameStr] = [];
  traceLog[stepNameStr].push({time: performance.now(), value});
}

// Function to display trace log with collapsible steps for easier reading when there are too many sub steps
export function logTraceLog(traceLog: TraceLog) {
  if (!traceLog) {
    console.log("Trace log is empty");
    return;
  }
  const log = (stepName: string, entries: TraceLogEntry[]) => {
    const lastEntry = entries.at(-1);
    if (!lastEntry) return;
    const duration = lastEntry.time - entries[0].time;
    console.log(`%c${stepName} (${formatLogDuration(duration)})`, "color: #fff; font-weight: 500");
    for (let i = 0; i < entries.length; i++) {
      const timeSincePreviousEntry = entries[i].time - (entries[i - 1]?.time || entries[0].time);
      console.log(
        `%c(+${formatLogDuration(timeSincePreviousEntry)}%c) ${entries[i].value}`,
        "color: #fff; font-weight: 500",
        "color: #fff; font-weight: 300",
      );
    }
  };

  for (const [stepName, entries] of Object.entries(traceLog)) {
    log(stepName, entries);
  }
}
