import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  alertListMain: {
    padding: [17, 21],
    fontSize: 12,
  },

  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 14,

    "& > h3": {
      fontWeight: 700,
      lineHeight: 25,
      color: colors.ui.darkBlue01,
    },
  },
  alerts: {
    "& > * + *": {
      marginTop: 10,
    },
  },

  noAlerts: {
    color: colors.text.lightBlue01,
  },

  loading: {
    color: colors.text.lightBlue01,
    extend: getSvgColorStyles(colors.text.lightBlue01),
  },

  alertItem: {
    display: "flex",
    alignItems: "center",
  },

  alertItemIcon: {
    marginRight: 10,
    marginBottom: -5,

    extend: getSvgColorStyles(colors.reds.red),
  },

  errorText: {display: "inline-flex", flex: 1},

  errorSubtext: {
    color: colors.text.lightBlue01,
    fontSize: 11,
    lineHeight: 12,
  },

  textWrapper: {
    flex: 1,
  },

  goToErrorIcon: {
    marginLeft: 10,
    marginRight: -5,
    marginTop: -5,
  },
});
