import {getActiveFcDatasource} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";

import type {HiringPlanFormula, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {FinancialComponent} from "@shared/types/hiring-plan";

export function getTextFromExpressedAs(expressedAs: HiringPlanFormula["ui"]["expressedAs"]) {
  switch (expressedAs) {
    case "monthly":
      return "Per month";
    case "yearly":
      return "Per year";
    default:
      return "";
  }
}

export function getValueForDisplay(value: string, type: HiringPlanFormula["ui"]["type"]) {
  switch (type) {
    case "amount":
      return formatNumber(parseFloat(value), true) || "$0";
    case "pct-of-target":
      return `${value}%`;
    default:
      return "";
  }
}

export function getSummary(datasources: HiringPlanFormulaDatasource[], fcType?: FinancialComponent["type"]) {
  const activeRange = getActiveFcDatasource(datasources);
  return getSummaryForRange(activeRange, fcType);
}

export function getSummaryForRange(
  datasource: Pick<HiringPlanFormulaDatasource, "options"> | undefined | null,
  fcType?: FinancialComponent["type"],
) {
  if (!datasource) {
    if (!fcType || fcType === "salary") {
      return "No Salary";
    } else {
      return "Empty";
    }
  }
  switch (datasource.options.ui.type) {
    case "amount":
      return `${getValueForDisplay(datasource.options.ui.value, datasource.options.ui.type)} ${getTextFromExpressedAs(
        datasource.options.ui.expressedAs,
      ).toLowerCase()}`;
    case "pct-of-target":
      return `${getValueForDisplay(datasource.options.ui.value, datasource.options.ui.type)} of salary`; // TODO: resolve actual target
    default:
      return "";
  }
}
