// declare global {
//   interface Set<T> {
//     union<T>(set: Set<T>): Set<T>;
//     intersection<T>(set: Set<T>): Set<T>;
//     difference<T>(set: Set<T>): Set<T>;
//     symmetricDifference<T>(set: Set<T>): Set<T>;
//   }

//   interface ObjectConstructor {
//     groupBy<T, K extends string | number | symbol>(
//       items: Iterable<T>,
//       callbackfn: (value: T, index: number) => K,
//     ): {[key in K]: Array<T>};
//   }

//   interface Array<T> {
//     /**
//      * Returns a copy of an array with its elements reversed.
//      */
//     toReversed(): T[];

//     /**
//      * Returns a copy of an array with its elements sorted.
//      * @param compareFn Function used to determine the order of the elements. It is expected to return
//      * a negative value if the first argument is less than the second argument, zero if they're equal, and a positive
//      * value otherwise. If omitted, the elements are sorted in ascending, ASCII character order.
//      * ```ts
//      * [11, 2, 22, 1].toSorted((a, b) => a - b) // [1, 2, 11, 22]
//      * ```
//      */
//     toSorted(compareFn?: (a: T, b: T) => number): T[];

//     /**
//      * Copies an array and removes elements and, if necessary, inserts new elements in their place. Returns the copied array.
//      * @param start The zero-based location in the array from which to start removing elements.
//      * @param deleteCount The number of elements to remove.
//      * @param items Elements to insert into the copied array in place of the deleted elements.
//      * @returns The copied array.
//      */
//     toSpliced(start: number, deleteCount: number, ...items: T[]): T[];

//     /**
//      * Copies an array and removes elements while returning the remaining elements.
//      * @param start The zero-based location in the array from which to start removing elements.
//      * @param deleteCount The number of elements to remove.
//      * @returns A copy of the original array with the remaining elements.
//      */
//     toSpliced(start: number, deleteCount?: number): T[];
//   }
// }

export type HumanReadableMonthlyCache = Record<
  string,
  Record<string, Record<string, {value?: number; total?: number; balance?: number; balanceTotal?: number}>>
>;

export type SnakeToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
  : S;

export type DbEntity<Entity extends Record<string, any>> = {id: string} & {
  [Key in keyof Entity]: Entity[Key] extends Record<any, any> ? string : Entity[Key];
};

// -------------------- Object.entries -------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~ Utils ~~~~~~~~~~~~~~~~~~~~~~~~

type ObjectType = Record<PropertyKey, unknown>;
type PickByValue<OBJ_T, VALUE_T> = // From https://stackoverflow.com/a/55153000
  Pick<OBJ_T, {[K in keyof OBJ_T]: OBJ_T[K] extends VALUE_T ? K : never}[keyof OBJ_T]>;
type ObjectEntries<OBJ_T> = // From https://stackoverflow.com/a/60142095
  {[K in keyof OBJ_T]: [keyof PickByValue<OBJ_T, OBJ_T[K]>, OBJ_T[K]]}[keyof OBJ_T][];

// ~~~~~~~~~~~~~~~~~~~~ Typed Function ~~~~~~~~~~~~~~~~~~~~

export function typedObjectEntries<OBJ_T extends ObjectType>(obj: OBJ_T): ObjectEntries<OBJ_T> {
  return Object.entries(obj) as ObjectEntries<OBJ_T>;
}

export function typedObjectFromEntries<ARR_T extends EntriesType>(arr: ARR_T): EntriesToObject<ARR_T> {
  return Object.fromEntries(arr) as EntriesToObject<ARR_T>;
}

// ------------------ Object.fromEnties ------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~ Utils ~~~~~~~~~~~~~~~~~~~~~~~~

// Data Types
type EntriesType = [PropertyKey, unknown][] | ReadonlyArray<readonly [PropertyKey, unknown]>;

// Existing Utils
type DeepWritable<OBJ_T> = {-readonly [P in keyof OBJ_T]: DeepWritable<OBJ_T[P]>};
type UnionToIntersection<UNION_T> = // From https://stackoverflow.com/a/50375286
  (UNION_T extends any ? (k: UNION_T) => void : never) extends (k: infer I) => void ? I : never;

// New Utils
type UnionObjectFromArrayOfPairs<ARR_T extends EntriesType> = DeepWritable<ARR_T> extends (infer R)[]
  ? R extends [infer key, infer val]
    ? {[prop in key & PropertyKey]: val}
    : never
  : never;
type MergeIntersectingObjects<ObjT> = {[key in keyof ObjT]: ObjT[key]};
type EntriesToObject<ARR_T extends EntriesType> = MergeIntersectingObjects<
  UnionToIntersection<UnionObjectFromArrayOfPairs<ARR_T>>
>;
