import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  breadcrumbs: {
    paddingLeft: 25,
    color: colors.text.lightBlue01,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 35,
    flex: 1,

    "& > span:not($separator)": {
      paddingRight: 4,

      "&:not(:first-child)": {
        paddingLeft: 4,
      },
    },
  },

  scenarioSelector: {
    paddingLeft: 8,
    flex: 1,
  },
  dropdown: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
    padding: [0, 4],

    "$breadcrumbs > div:first-child > div:first-child > &": {
      paddingLeft: 0,
    },

    "&:hover, $active &": {
      "& span": {
        color: colors.text.darkBlue01,
      },

      "& svg": {
        "& path": {
          fill: colors.text.darkBlue01,
        },
      },
    },

    "$active &": {
      //boxShadow: getBorderAsBoxShadow("all", 1, colors.ui.lightBlue03),
      borderRadius: 9999,
    },
  },
  separator: {
    color: colors.text.lightBlue02,
    padding: [0, 4],
  },
  arrow: {
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    paddingLeft: "8px",
    color: colors.text.lightBlue02,
  },
  active: {
    "& svg": {
      "& path": {
        fill: colors.text.darkBlue01,
      },
    },
  },
  versionBcItem: {
    ".earlier-version &": {
      fontWeight: 700,
      color: colors.tertiary.blue,
    },

    ".earlier-version div.popover-trigger-wrapper:not($active):not(:hover) > &$dropdown": {
      ...getSvgColorStyles(colors.tertiary.blue),
    },
  },
  rightButton: {
    extend: getSvgColorStyles(colors.text.lightBlue01),
    marginTop: -3,
  },
});
