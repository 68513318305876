import "@/lib/debug-helpers";
import {store} from "@app/client-store";
import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {
  selectSidebarOpen,
  setNotificationContent,
  selectors as uiStateSelectors,
  updateUiState,
} from "@app/ui-state/slice";
import {socket} from "@app/websockets/websockets";
import Admin from "@features/admin";
import SaveVersion from "@features/shared-views/CreateVersion";
import HeadsUpNotifications from "@features/shared-views/HeadsUpNotifications";
import LeftNav from "@features/shared-views/Navigation/LeftNav";
import TopNav from "@features/shared-views/Navigation/TopNav";
import Splash from "@features/shared-views/Splash";
import MainDebugPanel from "@features/templates/DebugTools/MainDebugPanel";
import {selectors as templatesViewSelectors} from "@features/templates/state/selectors";
import {selectors, selectVersionId, selectVersionUnlocked, updateGlobalState} from "@state/global/slice";
import {fetchSession, selectCurrentUser, selectFetchingSession} from "@state/session/slice";
import "@state/store";
import clsx from "clsx";
import {useEffect, useRef, useState} from "react";
import {useIdleTimer} from "react-idle-timer";
import {useLocation} from "react-router-dom";
import {useKey} from "react-use";
import WebFont from "webfontloader";

import ActionConfirmation from "./features/shared-views/ActionConfirmation";
import NotificationModalSingleton from "./features/shared-views/NotificationModalSingleton";
import {loadGlobalData} from "./lib/data-loading";
import {cmdKey} from "./lib/misc";

import useStyles from "@/App.jss";
import AppContext from "@/AppContext";
import {AuthRouter, CbRouter} from "@/Router";
import {logUserEvent} from "@app/websockets/websocket-action-logger";

function App() {
  const firstLoad = useRef(true);
  const [showAdmin, setShowAdmin] = useState(false);
  const [ready, setReady] = useState(false);
  const dispatch = useAppThunkDispatch();
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const currentUser = useAppSelector(selectCurrentUser);
  const fetchingSession = useAppSelector(selectFetchingSession);
  const initialLoadDone = useAppSelector(selectors.initialLoadDone);
  const debugModeEnabled = useAppSelector(selectors.debugModeEnabled);
  const location = useLocation();
  const selectedVersionId = useAppSelector(selectVersionId);
  const versionUnlocked = useAppSelector(selectVersionUnlocked);
  const saveVersionModalOpen = useAppSelector(uiStateSelectors.saveVersionModalOpen);
  const rightSidebarIsOpen = !!useAppSelector(templatesViewSelectors.activeCell)?.rowId;

  // Set a ref for the popover, pointing to the root div, and pass it through the context to be used by all popovers
  const rootElementRef = useRef(null);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Mont"],
        urls: ["/assets/mont-all.css"],
      },
    });
    dispatch(fetchSession(null));
    setReady(true);
  }, [dispatch]);

  useEffect(() => {
    socket.on("logout", () => {
      dispatch(
        setNotificationContent({
          preset: "loggedOut",
          cancelButton: false,
          closeButton: false,
          text: `Your session has expired or has been logged out from somewhere else. The page must be reloaded to continue.`,
          onConfirm: () => {
            window.location.reload();
          },
        }),
      );
    });
  }, [dispatch]);

  useKey(
    (evt) =>
      (currentUser?.role === "superuser" && evt.key.toLowerCase() === "u" && evt[cmdKey] && evt.shiftKey) ||
      (evt.key === "Escape" && showAdmin),
    () => setShowAdmin(!showAdmin),
  );

  useKey(
    (evt) => currentUser?.role === "superuser" && evt.key.toLowerCase() === "i" && evt[cmdKey] && evt.shiftKey,
    (evt) => {
      evt.preventDefault();
      dispatch(updateGlobalState({debugModeEnabled: !debugModeEnabled}));
      console.log(`Debug mode is now ${debugModeEnabled ? "off" : "on"}`);
    },
  );

  useEffect(() => {
    if (!currentUser) return;
    if (firstLoad.current) logUserEvent("app-load", {entrypoint: location.pathname});
    loadGlobalData({store, firstLoad: firstLoad.current, markInitialLoadAsNotDone: true})?.then(() => {
      if (firstLoad.current) {
        firstLoad.current = false;
      }
    });
  }, [selectedVersionId, currentUser, dispatch]);

  const onIdle = () => {
    const {templateId} = store.getState().templatesView;
    if (templateId) {
      socket.emit("user-selected-cell", {
        templateId,
        dateKey: null,
        rowId: null,
      });
    }
  };

  const onActive = () => {
    const {templateId, activeCell} = store.getState().templatesView;

    if (templateId && activeCell?.column && activeCell.rowId) {
      socket.emit("user-selected-cell", {
        templateId,
        dateKey: activeCell.column,
        rowId: activeCell.rowId,
      });
    }
  };

  useIdleTimer({onIdle, onActive, timeout: 1000 * 60 * 10});

  const styles = useStyles();

  const layoutClasses = clsx(styles.layout, {
    [styles.sidebarOpen]: sidebarOpen,
    [styles.isReporting]: location.pathname.includes("/reports") || location.pathname === "/",
  });

  const rootClasses = clsx(!!selectedVersionId ? "earlier-version" : "current-version", {
    unlocked: !!selectedVersionId && versionUnlocked,
    locked: !!selectedVersionId && !versionUnlocked,
  });

  return (
    <div ref={rootElementRef} style={{height: "100%"}} className={rootClasses}>
      {showAdmin ? <Admin /> : null}
      {!ready || fetchingSession ? (
        <Splash />
      ) : (
        <AppContext.Provider value={{rootElementRef}}>
          {!currentUser ? (
            <AuthRouter />
          ) : (
            <div className={layoutClasses}>
              {initialLoadDone ? (
                <>
                  <MainDebugPanel />
                  <ActionConfirmation />
                  <HeadsUpNotifications />
                  <NotificationModalSingleton />
                  {saveVersionModalOpen ? (
                    <SaveVersion onClose={() => dispatch(updateUiState({saveVersionModalOpen: false}))} />
                  ) : null}
                  <div className={styles.leftNavGridArea}>
                    <LeftNav />
                  </div>
                  <div className={styles.topNavGridArea}>
                    <TopNav />
                  </div>
                  <div className={clsx(styles.mainViewGridArea, {[styles.rightSidebarIsOpen]: rightSidebarIsOpen})}>
                    <CbRouter />
                  </div>
                </>
              ) : (
                <Splash />
              )}
            </div>
          )}
        </AppContext.Provider>
      )}
    </div>
  );
}

export default App;
