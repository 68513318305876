import {checkKeysForCircularDependencies} from "@shared/lib/alert-utilities";
import {getDatasourceMappingFromDiff} from "@shared/lib/datasource-utilities";
import {addToTraceLog, log, time, timeEnd} from "@shared/lib/debug-provider";
import {getCbTxStateAdapter, type CbTxState} from "@shared/state/entity-adapters";
import {deleteAlerts, upsertAlertsForDs} from "@state/alerts/slice";
import {api as txItemsApi} from "@state/cb-tx/slice";
import {applyDiffToMonthlyCache} from "@state/transaction-items/slice";
import {projKey} from "@state/utils";
import {isWebWorker} from "browser-or-node";
import {setAutoFreeze} from "immer";

import {generateAlgoValuesCache} from "../cache/algo-values-cache";
import {
  extractFromCacheKey,
  getEmptyMonthlyCacheAndCbTxDiff,
  getMonthlyCacheDiff,
  parseCacheKey,
} from "../cache/cache-utilities";
import {buildDependencyCache} from "../cache/dependency-cache";
import {applyDsDiffToDependencyCache} from "../cache/dependency-cache-utilities";
import {generateDisplayValuesCache} from "../cache/display-monthly-cache";
import {bubbleUpRefresh} from "./bubble-up-refresh";
import {getAffectedRows} from "./bubble-up-refresh-utilities";
import {parseFormula} from "./processor";

import type {EntityId} from "@reduxjs/toolkit";
import type {WorkerOptions} from "@shared/../client/app/worker/call-worker";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {TraceLog} from "@shared/lib/debug-provider";
import type {FormulaReference} from "@shared/types/datasources";
import type {CbTx, TemplateOptions} from "@shared/types/db";
import type {BasicStore, RootState} from "@state/store";
import type {MonthlyCacheAndCbTxDiff, MonthlyCacheDiff} from "../cache/cache-utilities";
import type {DependencyCache} from "../cache/dependency-cache";
import type {DependencyCacheDiff} from "../cache/dependency-cache-diff";
import type {BubbleUpRefreshParams} from "./bubble-up-refresh";

const {removeMany: removeManyCbTx} = getCbTxStateAdapter();

export type ComputeReturnType = {
  formulaReferencesMapping?: Record<string, FormulaReference[]>;
  valuesByRowIdDateKey: Record<string, number> | null;
};

type BubbleUpAndSaveOptions = {
  scenarioIds?: string[];
  workerOptions?: WorkerOptions;
  cbTxState?: CbTxState;
  skipMonthlyCacheUpdateAfterBubbleUp?: boolean;
  persistResultsToAPI: boolean;
  regenerateDependencyCache?: boolean;
  dependencyCacheScenarioIdsImpacted?: EntityId[];
  deletedDatasourceIds?: EntityId[];
  returnTxDiff?: boolean;
  upsertedCbTx?: CbTx[];
  deletedCbTx?: string[];
  sendOptimisticUpdate?: boolean;
  originalTemplateOptions?: Record<EntityId, TemplateOptions>;
  disableAddingPreviousDependencies?: boolean;
  iterative?: boolean;
};

const isDev = process.env.NODE_ENV === "development";

export function bubbleUpRefreshAndSave<
  P extends BubbleUpRefreshParams & {storeInstance: BasicStore},
  O extends BubbleUpAndSaveOptions & {returnTxDiff: true},
>(params: P, options: O): MonthlyCacheAndCbTxDiff;

export function bubbleUpRefreshAndSave<
  P extends BubbleUpRefreshParams & {storeInstance: BasicStore},
  O extends BubbleUpAndSaveOptions,
>(params: P, options: O): MonthlyCacheDiff;

export function bubbleUpRefreshAndSave<
  P extends BubbleUpRefreshParams & {storeInstance: BasicStore},
  O extends BubbleUpAndSaveOptions,
>(params: P, options: O) {
  const traceLog: TraceLog = isDev ? {} : null;
  const iterative = options.iterative ?? true;
  addToTraceLog(traceLog, "Algo", `Starting bubble up refresh and save`);
  const store = params.storeInstance;

  setAutoFreeze(false);
  const cacheDiff: MonthlyCacheAndCbTxDiff = getEmptyMonthlyCacheAndCbTxDiff();

  if (!store) {
    throw new Error("bubbleUpRefreshAndSave called without a store");
  }

  let state = store.getState();

  const scenarioIds = options.scenarioIds ||
    options.dependencyCacheScenarioIdsImpacted || [
      params.basedOn !== "txItems" && params.basedOn !== "datasourceDiff" ? params.scenarioId : "",
    ];

  addToTraceLog(
    traceLog,
    "Algo",
    () =>
      `Values will be (partially) calculated for scenario${scenarioIds.length > 1 ? "s" : ""} ${scenarioIds
        .map((id) => state.scenarios.entities[id]?.name)
        .join(", ")}`,
  );

  // If the iterative option is set to true, we first need to bring back the datasources state to what it was before the upserts and deletes using the datasourceDiff
  // Then, we generate the original dependency cache using that state, then re-apply the upserts and deletes and regenerate the dependency cache
  // const previousDependencyCaches: Record<string, DependencyCache> = {};
  // time(
  //   "bubbleUpRefreshAndSave",
  //   `Generated previous dependency caches for ${scenarioIds.length} scenarios (iterative=${iterative})`,
  // );
  // if (iterative && params.basedOn === "datasourceDiff") {
  //   addToTraceLog(traceLog, "Algo", `Iterative is set to true, generating previous dependency caches`);
  //   addToTraceLog(
  //     traceLog,
  //     "Algo",
  //     `Temporarily removing ${params.datasourceDiff.upserts.length} and adding back ${
  //       params.datasourceDiff.upsertedPreviousVersions.length + params.datasourceDiff.deletes.length
  //     } datasources to the state`,
  //   );

  //   // revertDsChangesToState(state, params.datasourceDiff);

  //   // state = store.getState();

  //   for (const scenarioId of scenarioIds) {
  //     addToTraceLog(
  //       traceLog,
  //       "Algo",
  //       `Generating previous dependency cache for scenario ${state.scenarios.entities[scenarioId]?.name}`,
  //     );
  //     const {dependencyCache} = applyDsDiffToDependencyCache(
  //       state,
  //       state.global.dependencyCache[scenarioId],
  //       params.datasourceDiff,
  //       scenarioId,
  //       options.originalTemplateOptions,
  //     );
  //     // const dependencyCache = buildDependencyCache({
  //     //   scenarioId,
  //     //   state,
  //     //   rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
  //     //   templateOptionsOverride: options.originalTemplateOptions,
  //     //   resolvedDates: params.datasourceDiff.resolvedDates.old,
  //     // });

  //     previousDependencyCaches[scenarioId] = dependencyCache;
  //   }

  //   addToTraceLog(
  //     traceLog,
  //     "Algo",
  //     `Re-applying ${params.datasourceDiff.upserts.length} and removing ${params.datasourceDiff.deletes.length} datasources to the state`,
  //   );

  //   // applyDsChangesToState(state, params.datasourceDiff);

  //   // state = store.getState();
  // }
  let removedCacheKeysToRecompute: Record<string, string[]> | null = null;
  // timeEnd(
  //   "bubbleUpRefreshAndSave",
  //   `Generated previous dependency caches for ${scenarioIds.length} scenarios (iterative=${iterative})`,
  // );

  if (options.regenerateDependencyCache) {
    time(
      "Algo",
      `Option "regenerateDependencyCache" is set to true, regenerating dependency caches for the ${scenarioIds.length} scenarios impacted`,
    );
    const dependencyCacheDiffs: Record<EntityId, DependencyCacheDiff> = regenerateDependencyCaches(
      store,
      scenarioIds,
      params.basedOn === "datasourceDiff" ? params.datasourceDiff.resolvedDates.new : undefined,
      params.basedOn === "datasourceDiff" ? params.datasourceDiff : undefined,
    );

    state = store.getState();

    // If this is an iterative recalc, compute the diff between the previous and current dependency cache
    if (iterative) {
      addToTraceLog(
        traceLog,
        "Algo",
        `Iterative is set to true, computing the diff between the previous and current dependency cache`,
      );
      for (const [scenarioId, datasourceDiff] of Object.entries(dependencyCacheDiffs)) {
        addToTraceLog(
          traceLog,
          "Algo",
          `Computing the diff between the previous and current dependency cache for scenario ${state.scenarios.entities[scenarioId]?.name}`,
        );

        if (datasourceDiff.cellToDependencies.deletes.length) {
          addToTraceLog(
            traceLog,
            "Algo",
            `Found ${datasourceDiff.cellToDependencies.deletes.length} cacheKeys to recompute for scenario ${state.scenarios.entities[scenarioId]?.name}`,
          );
          removedCacheKeysToRecompute ||= {};

          // Loop through each key in 'deletes' to check if the key itself should be removed.
          for (const [key, value] of Object.entries(datasourceDiff.cellToDependencies.deletes)) {
            if (value === "DELETE_KEY") {
              // Add the key to the list of keys to be deleted.
              removedCacheKeysToRecompute[scenarioId] = removedCacheKeysToRecompute[scenarioId] || [];
              removedCacheKeysToRecompute[scenarioId].push(key);
            }
          }
        }
      }
    }
  }

  // If we have been provided a datasourceDiff, we must first take care of deleting the cacheKeys for which a datasource has been removed
  const deletedCacheKeys: string[] = [];
  if (params.basedOn === "datasourceDiff" && params.datasourceDiff.cacheKeysRemovedPerDsId) {
    time("Algo", `Deleted cacheKeys and tx for removed datasources`);
    addToTraceLog(traceLog, "Algo", `Deleting cacheKeys and tx for removed datasources`);
    const {cacheKeysToDelete, cbTxIdsToDelete, cbTxIdsToDeleteLocal} = getCacheKeysAndTxDeletes(
      store.getState(),
      params.datasourceDiff,
      traceLog,
    );

    if (cbTxIdsToDelete.length || cbTxIdsToDeleteLocal.length) {
      removeManyCbTx(state.cbTx, [...cbTxIdsToDelete, ...cbTxIdsToDeleteLocal]);
      cacheDiff.deletedCbTx.push(...cbTxIdsToDelete, ...cbTxIdsToDeleteLocal);
    }

    state = store.getState();

    if (!options.regenerateDependencyCache) {
      for (const cacheKey of cacheKeysToDelete) {
        const scenarioId = extractFromCacheKey(cacheKey, "scenarioId");
        if (state.global.algoCache[scenarioId]?.[cacheKey]) {
          delete state.global.algoCache[scenarioId][cacheKey];
          deletedCacheKeys.push(cacheKey);
        }
      }
    }
    timeEnd("Algo", "Deleted cacheKeys and tx for removed datasources");
  }

  if (options.regenerateDependencyCache) {
    // trackKeyForDebugging(
    //   "BEFORE regenerating algo cache",
    //   null,
    //   state.global.dependencyCache["e14ea420-108a-47a2-965e-bdb81b3dfcc4"],
    // );
    regenerateAlgoCaches(store, scenarioIds);
  }

  if (options.sendOptimisticUpdate && params.basedOn === "datasourceDiff" && isWebWorker) {
    const datasourceMappings = getDatasourceMappingFromDiff(params.datasourceDiff);

    for (const [scenarioId, rowsMapping] of Object.entries(datasourceMappings)) {
      for (const [rowId, datasourceMapping] of Object.entries(rowsMapping)) {
        const row = state.templateRows.entities[rowId];
        if (!row) continue;
        const result = parseFormula(state, row, datasourceMapping, scenarioId, {
          ...state.transactionItems.valuesByRowIdDateKey,
        });

        if (result.deletedTransactions.length) {
          cacheDiff.deletedCbTx.push(...result.deletedTransactions.map((tx) => tx.id));
        }
        if (result.upsertedTransactions.length) {
          cacheDiff.upsertedCbTx.push(...result.upsertedTransactions);
        }
        if (result.deletedCacheKeys.length) {
          cacheDiff.deletedCacheKeys.push(...result.deletedCacheKeys);
        }
        if (result.updatedCacheKeys) {
          cacheDiff.updatedCacheKeys = {...cacheDiff.updatedCacheKeys, ...result.updatedCacheKeys};
        }
      }
    }

    log("Algo", "Sending optimistic update", cacheDiff);
    store.dispatch(applyDiffToMonthlyCache({diff: cacheDiff}));
  }

  let affectedRows: ReturnType<typeof getAffectedRows> = {};
  if (params.basedOn === "datasourceDiff") {
    const paramsToUse: BubbleUpRefreshParams & {basedOn: "datasourceDiff"} = {...params};
    if (removedCacheKeysToRecompute) {
      paramsToUse.datasourceDiff = {
        ...params.datasourceDiff,
      };
      for (const [scenarioId, cacheKeys] of Object.entries(removedCacheKeysToRecompute)) {
        paramsToUse.datasourceDiff.cacheKeysRemovedPerDsId[scenarioId] = cacheKeys;
      }
    }
    affectedRows = getAffectedRows(paramsToUse);
  } else if (params.basedOn === "txItems") {
    affectedRows = getAffectedRows(params);
  } else {
    for (const scenarioId of scenarioIds) {
      affectedRows = {...affectedRows, ...getAffectedRows({...params, scenarioId})};
    }
  }

  const finalParams: Parameters<typeof bubbleUpRefresh>[0] = {
    ...params,
    affectedRows,
  };

  // logDependenciesTreeForKey(
  //   state.global.dependencyCache[state.scenarios.ids[0]],
  //   "subscription_revenue::Base-Case::2023-09",
  //   state,
  //   true,
  //   state.transactionItems.valuesByRowIdDateKey,
  // );

  time("Algo", `Completed bubble up refresh`);
  const result = bubbleUpRefresh(finalParams, options?.workerOptions);
  timeEnd("Algo", `Completed bubble up refresh`);

  // Update cacheDiff
  if (result.deletedTransactions.length) {
    cacheDiff.deletedCbTx.push(...result.deletedTransactions.map((tx) => tx.id));
  }
  if (result.upsertedTransactions.length) {
    cacheDiff.upsertedCbTx.push(...result.upsertedTransactions);
  }

  // Whether to save updated and deleted CbTx to API or not
  if (options.persistResultsToAPI) {
    if (cacheDiff.upsertedCbTx.length) {
      store.dispatch<any>(txItemsApi.upsert(cacheDiff.upsertedCbTx));
    }
    if (cacheDiff.deletedCbTx.length) {
      store.dispatch<any>(txItemsApi.delete(cacheDiff.deletedCbTx));
    }
  }

  time("Algo", `Generated a fresh display values cache`);
  state = store.getState();

  const vendorLevelRowIdsDepartmentIds: {
    rowId: EntityId;
    departmentId: EntityId;
  }[] = [];
  for (const [rowId, departmentIds] of Object.entries(state.global.expandedVendors)) {
    for (const departmentId of departmentIds) {
      vendorLevelRowIdsDepartmentIds.push({rowId, departmentId});
    }
  }

  time("Algo", `Regenerated algo cache`);
  regenerateAlgoCaches(store, scenarioIds);
  timeEnd("Algo", `Regenerated algo cache`);

  const displayValuesCache = generateDisplayValuesCache({
    state,
    algoValuesCache:
      state.global.selectedDepartmentId === null
        ? Object.fromEntries(Object.values(state.global.algoCache).flatMap((cache) => Object.entries(cache)))
        : {},
    departmentLevelRowIds: state.global.departmentsExpandedRowIds,
    vendorLevelRowIdsDepartmentIds,
    globalDepartmentId: state.global.selectedDepartmentId,
    scenarioIds,
  });
  timeEnd("Algo", `Generated a fresh display values cache`);

  time("Algo", `Computed the diff between the previous and current display values cache`);
  const previousDisplayValuesCache = state.transactionItems.valuesByRowIdDateKey;
  const displayCacheDiff = getMonthlyCacheDiff(previousDisplayValuesCache, displayValuesCache, scenarioIds);
  state.transactionItems.valuesByRowIdDateKey = displayValuesCache;
  timeEnd("Algo", `Computed the diff between the previous and current display values cache`);

  log(
    "Algo",
    `Number of deleted cache keys: ${displayCacheDiff.deletedCacheKeys.length}; Number of upserted cache keys: ${
      Object.keys(displayCacheDiff.updatedCacheKeys).length
    }`,
  );
  // const readableDiff = {
  //   updatedCacheKeys: Object.fromEntries(
  //     Object.entries(displayCacheDiff.updatedCacheKeys).map(([key, value]) => [
  //       makeDependencyKeyHumanReadable(key, state, false, true),
  //       value,
  //     ]),
  //   ),
  //   deletedCacheKeys: displayCacheDiff.deletedCacheKeys.map((key) =>
  //     makeDependencyKeyHumanReadable(key, state, false, true),
  //   ),
  // };
  // console.log({readableDiff});
  // @ts-ignore
  // logDependenciesTreeForKey(
  //   state.global.dependencyCache[scenarioIds[0]],
  //   "snowflake_col_1::base test::2022-09",
  //   state,
  //   false,
  //   state.transactionItems.valuesByRowIdDateKey,
  // );
  // logTraceLog(traceLog);

  // Analyze the updated cacheKeys for circular dependencies
  setTimeout(() => {
    if (params.basedOn === "datasourceDiff") {
      for (const scenarioId of scenarioIds) {
        const cacheKeys = Object.values(params.datasourceDiff.cacheKeysUpdatedPerDsId)
          .flat()
          .filter((cacheKey) => cacheKey.includes(scenarioId.toString()));
        const checkResult = checkKeysForCircularDependencies({
          dependencyCache: state.global.dependencyCache[scenarioId],
          state,
          cacheKeys,
        });
        if (checkResult.upserts.length > 0) {
          store.dispatch(upsertAlertsForDs(checkResult.upserts));
        }
        if (checkResult.deletes.length > 0) {
          store.dispatch(deleteAlerts(checkResult.deletes.map(({id}) => id)));
        }
      }
    }
  }, 10);

  return options.returnTxDiff === true
    ? {...displayCacheDiff, upsertedCbTx: cacheDiff.upsertedCbTx, deletedCbTx: cacheDiff.deletedCbTx}
    : displayCacheDiff;
}

export function trackKeyForDebugging(label: string, scenarioId: EntityId | null, dependencyCache: DependencyCache) {
  if (!scenarioId || scenarioId === "e14ea420-108a-47a2-965e-bdb81b3dfcc4") {
    console.log(`\n`);
    console.log(`[${label}] Looking for the removed key in the cache...`);
    console.log(
      `First, list the dependencies of cell ae99d7a5-e7a4-4259-808b-5399e2262250::e14ea420-108a-47a2-965e-bdb81b3dfcc4::2023-09`,
    );
    const dependencies =
      dependencyCache.cellToDependencies[
        "ae99d7a5-e7a4-4259-808b-5399e2262250::e14ea420-108a-47a2-965e-bdb81b3dfcc4::2023-09"
      ];
    if (!dependencies?.size) {
      console.log(`No dependencies found`);
    } else {
      for (const key of dependencies) {
        console.log(`- ${key}`);
      }
    }
    const key = "ae99d7a5-e7a4-4259-808b-5399e2262250::e14ea420-108a-47a2-965e-bdb81b3dfcc4::2022-12";
    const inFullList = dependencyCache.fullList[key];
    const inFullListCollection = dependencyCache.fullListCollection.find(
      (k) => k.rowId === "ae99d7a5-e7a4-4259-808b-5399e2262250" && k.dateKey === "2022-12" && !k.total,
    );
    const inDependencyToCell = dependencyCache.dependencyToCells[key];
    const inOrderedCacheKeys = dependencyCache.orderedCacheKeys.includes(key);
    console.log(`In fullList: ${!!inFullList}`, inFullList);
    console.log(`In fullListCollection: ${!!inFullListCollection}`, inFullListCollection);
    console.log(`In orderedCacheKeys: ${!!inOrderedCacheKeys}`);
    console.log(`In dependencyToCell: ${!!inDependencyToCell}`, inDependencyToCell);
    console.log(`\n`);
  }
}

export function getCacheKeysAndTxDeletes(
  state: RootState,
  {cacheKeysRemovedPerDsId, deletes}: DatasourceDiff,
  traceLog: TraceLog,
) {
  const cbTxIdsToDelete: string[] = [];
  const cbTxIdsToDeleteLocal: string[] = [];
  const cacheKeysToDelete: string[] = [];
  if (!cacheKeysRemovedPerDsId) return {cbTxIdsToDelete, cbTxIdsToDeleteLocal, cacheKeysToDelete};
  const deletedIds = new Set(deletes.map((ds) => ds.id));
  for (const [dsId, cacheKeys] of Object.entries(cacheKeysRemovedPerDsId)) {
    const dsHasBeenDeleted = deletedIds.has(dsId);
    for (const cacheKey of cacheKeys) {
      const {dateKey} = parseCacheKey(cacheKey);

      const matchingIds = state.cbTx.idsByDsIdDateKey[projKey(dsId, dateKey)];
      if (matchingIds?.length) {
        if (dsHasBeenDeleted) {
          // If the ds has been completely deleted, we don't want to send the tx deletes to the API - they have already been deleted server-side
          cbTxIdsToDeleteLocal.push(...matchingIds);
        } else {
          cbTxIdsToDelete.push(...matchingIds);
        }
        cacheKeysToDelete.push(cacheKey);
      }
    }
  }

  return {cbTxIdsToDeleteLocal, cbTxIdsToDelete, cacheKeysToDelete};
}

function regenerateAlgoCaches(store: BasicStore, scenarioIds: EntityId[]) {
  const state = store.getState();
  for (const scenarioId of scenarioIds) {
    time("regenerateAlgoCache", `Regenerated algo cache for scenario ${state.scenarios.entities[scenarioId]?.name}`);
    const algoCache = generateAlgoValuesCache(scenarioId, store);

    state.global.algoCache[scenarioId] = algoCache;

    timeEnd("regenerateAlgoCache", `Regenerated algo cache for scenario ${state.scenarios.entities[scenarioId]?.name}`);
  }
}

function regenerateDependencyCaches(
  store: BasicStore,
  scenarioIds: EntityId[],
  resolvedDates?: DatasourceDiff["resolvedDates"]["new" | "old"],
  datasourceDiff?: DatasourceDiff,
) {
  const state = store.getState();
  const dependencyCacheDiffs: Record<EntityId, DependencyCacheDiff> = {};
  for (const scenarioId of scenarioIds) {
    time(
      "regenerateDependencyCaches",
      `Regenerated dependency cache for scenario ${state.scenarios.entities[scenarioId]?.name}`,
    );

    // Dependency cache diff
    let dependencyCache: DependencyCache;
    // trackKeyForDebugging(
    //   "in regenerate dependency caches, BEFORE applyDsDiffToDependencyCache",
    //   scenarioId,
    //   state.global.dependencyCache[scenarioId],
    // );
    if (false && datasourceDiff && state.global.dependencyCache[scenarioId]) {
      const result = applyDsDiffToDependencyCache(
        state,
        state.global.dependencyCache[scenarioId],
        datasourceDiff,
        scenarioId,
      );
      dependencyCache = result.dependencyCache;
      dependencyCacheDiffs[scenarioId] = result.dependencyCacheDiff;
    } else {
      dependencyCache = buildDependencyCache({
        scenarioId,
        state,
        rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
        resolvedDates,
      });
    }

    state.global.dependencyCache[scenarioId] = dependencyCache;
    // trackKeyForDebugging(
    //   "in regenerate dependency caches, AFTER applyDsDiffToDependencyCache",
    //   scenarioId,
    //   dependencyCache,
    // );
    timeEnd(
      "regenerateDependencyCaches",
      `Regenerated dependency cache for scenario ${state.scenarios.entities[scenarioId]?.name}`,
    );
  }

  return dependencyCacheDiffs;
}
