import Dropdown from "@components/Dropdown";
import Popover from "@components/Popover";
import RoundButton from "@components/RoundButton";
import {addDeltaMonthsToDateKey} from "@shared/lib/date-utilities";
import dayjs from "dayjs";

import useStyles from "./styles.jss";

import type {DropdownItem, DropdownWithoutTriggerProps} from "@components/Dropdown";
import type {PopoverGenericProps} from "@components/Popover";

type DatePickerProps = PopoverGenericProps & {
  selectedDate: string;
  onDateChange: (date: string) => void;
  disabled?: boolean;
};

const currentYear = new Date().getFullYear();
const years: string[] = [];
for (let i = currentYear - 10; i < currentYear + 10; i++) {
  years.push(i.toString());
}

export const monthsDropdownItems = dayjs
  .months()
  .map((month, i) => ({key: (i + 1).toString().padStart(2, "0"), value: month}));
export const yearsDropdownItems = years.map((year) => ({key: year, value: year}));

export default function DatePicker({
  children: trigger,
  selectedDate,
  onDateChange,
  disabled,
  ...popoverProps
}: DatePickerProps) {
  const styles = useStyles();

  const dayjsSelectedDate = dayjs(selectedDate, "YYYY-MM");

  const handleItemChange = (type: "month" | "year") => (item: DropdownItem) => {
    if (type === "month") {
      onDateChange(
        dayjs(dayjsSelectedDate)
          [type](parseInt(item.key || "1", 10) - 1)
          .format("YYYY-MM"),
      );
    } else {
      onDateChange(
        dayjs(dayjsSelectedDate)
          .year(parseInt(item.key || "1", 10))
          .format("YYYY-MM"),
      );
    }
  };

  const handleIncrementDecrement = (delta: -1 | 1) => () => {
    onDateChange(addDeltaMonthsToDateKey(selectedDate, delta));
  };

  return (
    <Popover
      {...popoverProps}
      arrow
      disableSelection
      content={
        <div className={styles.datepickerMain}>
          <div className={styles.header}>
            <div className={styles.arrowLeft}>
              <RoundButton icon="arrow" onClick={handleIncrementDecrement(-1)} minimal disabled={disabled} />
            </div>
            <div className={styles.monthDropdown}>
              <MonthSelector
                onChange={handleItemChange("month")}
                selectedKey={(dayjsSelectedDate.month() + 1).toString().padStart(2, "0")}
                disabled={disabled}
              />
            </div>
            <div className={styles.yearDropdown}>
              <YearSelector
                onChange={handleItemChange("year")}
                selectedKey={dayjsSelectedDate.year().toString()}
                disabled={disabled}
              />
            </div>
            <div className={styles.arrowRight}>
              <RoundButton icon="arrow" onClick={handleIncrementDecrement(1)} minimal disabled={disabled} />
            </div>
          </div>
        </div>
      }
    >
      {trigger}
    </Popover>
  );
}

export function MonthSelector({
  onChange,
  selectedKey,
  ...props
}: {
  selectedKey: string;
  onChange: (month: DropdownItem) => void;
} & Partial<DropdownWithoutTriggerProps<(typeof monthsDropdownItems)[number]>>) {
  return (
    <Dropdown
      {...props}
      items={monthsDropdownItems}
      onSelect={onChange}
      selectedKey={selectedKey}
      buttonBold={false}
      renderAsChild
      buttonWidth={130}
    />
  );
}

export function YearSelector({
  onChange,
  selectedKey,
  disabled,
}: {
  selectedKey: string;
  onChange: (year: DropdownItem) => void;
  disabled?: boolean;
}) {
  return (
    <Dropdown
      disabled={disabled}
      items={yearsDropdownItems}
      onSelect={onChange}
      selectedKey={selectedKey}
      buttonBold={false}
      renderAsChild
    />
  );
}
