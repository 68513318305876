import {Link} from "react-router-dom";

import useStyles from "./styles.jss";

export default function PageNotFound({entity}: {entity?: string}) {
  const styles = useStyles();

  const lowercaseEntity = entity?.toLowerCase() ?? "page";
  const capitalizedEntity = lowercaseEntity.charAt(0).toUpperCase() + lowercaseEntity.slice(1);

  return (
    <div className={styles.pageNotFoundMain}>
      <h1>{capitalizedEntity} Not Found</h1>
      <p>
        No {lowercaseEntity} can be found matching this URL.
        <br />
        <Link to="/">Return to home page</Link>
      </p>
    </div>
  );
}
