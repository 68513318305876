interface AlertCommon<Type extends AlertType, DetailsType extends Record<string, any>> {
  id: string;
  type: Type;
  details: DetailsType;
  created_at: string;
}

// Common error type for client API errors
type ClientApiErrorCommon<Type extends AlertType, DetailsType extends Record<string, any>> = AlertCommon<
  Type,
  DetailsType
>;

// Common error type for sanity check errors
type SanityCheckAlertCommon<Type extends AlertType, DetailsType extends Record<string, any>> = AlertCommon<
  Type,
  DetailsType
> & {
  sanity_check_id: string;
};

export type SanityCheckFailingAlert = SanityCheckAlertCommon<
  "SANITY_CHECK_FAILING",
  {
    months: string[];
  }
>;

// Common error type for datasource errors
type DsErrorCommon<Type extends AlertType, DetailsType extends Record<string, any>> = AlertCommon<Type, DetailsType> & {
  datasource_id: string;
};

// Datasource specific error types
export type DsInvalidRefError = DsErrorCommon<
  "INVALID_REF",
  {
    refType: "row" | "department" | "vendor";
    invalidRefStr: string;
  }
>;

export type DsFormulaParseError = DsErrorCommon<
  "FORMULA_PARSE_ERROR",
  {
    formula: string;
  }
>;

export type DsCircularRefError = DsErrorCommon<
  "CIRCULAR_REF",
  {
    cycle: string[];
  }
>;

// Union of all datasource error types
export type DsError = DsInvalidRefError | DsFormulaParseError | DsCircularRefError;

export type SanityCheckAlert = SanityCheckFailingAlert;

// Union of all error types
export type AppAlert = DsError | SanityCheckFailingAlert;

// Error types as a union of string literals
export type AlertType = "INVALID_REF" | "FORMULA_PARSE_ERROR" | "CIRCULAR_REF" | "SANITY_CHECK_FAILING";

// Type guards
export function isDsError(error: AppAlert): error is DsError {
  return (error as DsError)?.datasource_id !== undefined;
}

export function isSanityCheckAlert(error: AppAlert): error is SanityCheckAlert {
  return (error as SanityCheckAlert).sanity_check_id !== undefined;
}
