import {createSelector, createSlice} from "@reduxjs/toolkit";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {ClientRootState} from "@app/client-store";

type IntegrationsViewState = {
  selectedEntityId: null | string;
  sidebarOpen: boolean;
};

const initialState: IntegrationsViewState = {
  selectedEntityId: null,
  sidebarOpen: false,
};

export const slice = createSlice({
  name: "integrationSettings",
  initialState,
  reducers: {
    toggleSidebarOpen: (state, action: PayloadAction<boolean | undefined>) => {
      state.sidebarOpen = typeof action.payload !== "undefined" ? action.payload : !state.sidebarOpen;
    },
    setSelectedEntityId: (state, action: PayloadAction<(typeof initialState)["selectedEntityId"]>) => {
      state.selectedEntityId = action.payload;
    },
  },
});

export const selectCollapsedTeams = createSelector(
  (state: ClientRootState) => state.hiringPlan,
  (hiringPlanState) => hiringPlanState.collapsedTeams,
);

export const {setSelectedEntityId, toggleSidebarOpen} = slice.actions;

const reducer = slice.reducer;

export default reducer;
