import {useAppDispatch, useAppSelector} from "@app/hooks";
import Button from "@components/Button/V2";
import FormElement from "@components/FormElement";
import {SectionCol} from "@components/RightSidebar/Section";
import RoundButton from "@components/RoundButton";
import TextInput from "@components/TextInput";
import {employeeUpdated} from "@state/employees/slice";
import {selectVersionLocked} from "@state/global/slice";

import useStyles from "./styles.jss";

import type {Employee} from "@shared/types/hiring-plan";

interface TagsProps {
  employee: Employee;
}
export default function Tags({employee}: TagsProps) {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const isVersionLocked = useAppSelector(selectVersionLocked);

  const handleAddTag = async () => {
    const keyValuePairs = Object.entries(employee.custom_tags);
    keyValuePairs.push(["", ""]);
    dispatch(employeeUpdated({id: employee.id, key: "custom_tags", value: Object.fromEntries(keyValuePairs)}));
  };

  const handleUpdateTag =
    (type: "value" | "key", index: number): React.ChangeEventHandler<HTMLInputElement> =>
    async (evt) => {
      const keyValuePairs = Object.entries(employee.custom_tags);
      keyValuePairs[index][type === "key" ? 0 : 1] = evt.target.value || "";
      dispatch(employeeUpdated({id: employee.id, key: "custom_tags", value: Object.fromEntries(keyValuePairs)}));
    };

  const handleRemoveTag = (index: number) => async () => {
    const keyValuePairs = Object.entries(employee.custom_tags);
    if (keyValuePairs[index]) keyValuePairs.splice(index, 1);
    dispatch(employeeUpdated({id: employee.id, key: "custom_tags", value: Object.fromEntries(keyValuePairs)}));
  };

  return (
    <div>
      <div className={styles.tagsInner}>
        {!Object.entries(employee.custom_tags).length ? (
          <div className={styles.noTags}>No Custom Properties have been set for this employee</div>
        ) : (
          <>
            <SectionCol>
              <FormElement label="Column Name" tooltip="HiringPlan::Employees::Sidebar::TagKey">
                {Object.entries(employee.custom_tags).map(([tagKey, tagValue], i) => (
                  <TextInput
                    value={tagKey}
                    onChange={handleUpdateTag("key", i)}
                    changeTrigger="blur"
                    onChangeDelay={1000}
                    key={tagKey + tagValue + i}
                    className={styles.tagInput}
                    disabled={isVersionLocked}
                  />
                ))}
              </FormElement>
            </SectionCol>
            <SectionCol>
              <FormElement label="Value" tooltip="HiringPlan::Employees::Sidebar::TagValue">
                {Object.entries(employee.custom_tags).map(([tagKey, tagValue], i) => (
                  <div key={tagKey + tagValue + i} className={styles.tagValueAndDelete}>
                    <TextInput
                      value={tagValue}
                      onChange={handleUpdateTag("value", i)}
                      changeTrigger="blur"
                      onChangeDelay={1000}
                      className={styles.tagInput}
                      disabled={isVersionLocked}
                    />
                    {!isVersionLocked ? (
                      <RoundButton icon="smallCross" enableCssStates onClick={handleRemoveTag(i)} />
                    ) : null}
                  </div>
                ))}
              </FormElement>
            </SectionCol>
          </>
        )}
      </div>
      {!isVersionLocked ? (
        <div className={styles.buttonSection}>
          <Button color="orange" text="New" iconLeft="plus" fill={false} onClick={handleAddTag} size="small" />
        </div>
      ) : null}
    </div>
  );
}
