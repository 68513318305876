import colors from "@app/styles/colors";

export const debugHighlightColors = [
  {
    bg: colors.primary.lightGold06,
    border: colors.primary.gold,
  },
  {
    bg: colors.tertiary.lightBlue06,
    border: colors.tertiary.blue,
  },
  {
    bg: colors.reds.lightRed05,
    border: colors.reds.red,
  },
];
