import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getSanityChecksAdapter} from "@shared/state/entity-adapters";

import sanityChecksAPI from "./api";

import type {RootState} from "@state/store";

export const api = {
  fetchSanityChecks: createAsyncThunk("sanityChecks/fetchAll", sanityChecksAPI.fetchAll),
  upsertSanityChecks: createAsyncThunk("sanityChecks/upsert", sanityChecksAPI.upsert),
  deleteSanityChecks: createAsyncThunk("sanityChecks/remove", sanityChecksAPI.remove),
};

export const getSlice = () => {
  const sanityChecksAdapter = getSanityChecksAdapter();

  return createSlice({
    name: "sanityChecks",
    initialState: {
      ...sanityChecksAdapter.getInitialState(),
      lastRun: null as null | number,
    },
    reducers: {
      upsertSanityChecks: sanityChecksAdapter.upsertMany,
      upsertSanityChecksLocal: sanityChecksAdapter.upsertMany,
      deleteSanityChecks: sanityChecksAdapter.removeMany,
      setLastRun: (state, action) => {
        state.lastRun = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchSanityChecks.fulfilled, sanityChecksAdapter.setAll);
    },
  });
};

export const selectSanityChecksLastRun = (state: RootState) => state.sanityChecks.lastRun;

const _slice = getSlice();
export const {upsertSanityChecksLocal, setLastRun} = _slice.actions;
export const {upsertSanityChecks, deleteSanityChecks} = createSyncedActionCreators(_slice.actions);
export const {
  selectById: selectSanityCheckById,
  selectIds: selectSanityCheckIds,
  selectEntities: selectSanityCheckEntities,
  selectAll: selectAllSanityChecks,
  selectTotal: selectTotalSanityChecks,
} = getSanityChecksAdapter().getSelectors((state: RootState) => state.sanityChecks);
