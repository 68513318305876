import {callWorkerFn} from "@app/worker";
import {DEBUG} from "@shared/lib/debug-provider";
import {api as hiringPlanApi} from "@state/employees/slice";
import {api as globalApi, selectVersionId, setInitialLoadDone} from "@state/global/slice";
import {fetchIntegrations, fetchQboStatementRows} from "@state/integrations/slice";
import {api as reportsApi} from "@state/reports/slice";
import {api as sanityChecksApi} from "@state/sanity-checks/slice";
import {api as versionsApi} from "@state/versions/slice";

import type {AppReduxStore, AppThunkDispatch} from "@state/store";

export let initialLoadExecutionCounter = 0;

export function loadGlobalData({
  store,
  firstLoad,
  markInitialLoadAsNotDone,
}: {
  store: AppReduxStore;
  firstLoad: boolean;
  markInitialLoadAsNotDone?: boolean;
}) {
  const dispatch = store.dispatch as AppThunkDispatch;
  const state = store.getState();

  const user = state.session.user;
  if (!user) return;

  if (markInitialLoadAsNotDone) dispatch(setInitialLoadDone(false));

  const selectedVersionId = selectVersionId(state);

  if (firstLoad) {
    dispatch(versionsApi.fetchAll(null));
  }

  return Promise.all([
    callWorkerFn("initialLoad", [user, selectedVersionId], {
      executionId: initialLoadExecutionCounter++,
      DEBUG,
    }),
    dispatch(hiringPlanApi.fetchHiringPlanEntities(null)),
    dispatch(reportsApi.fetchReports(null)),
    dispatch(fetchIntegrations(null)),
    dispatch(globalApi.fetchLastMessageId()),
    dispatch(sanityChecksApi.fetchSanityChecks()),
  ]).then(() => {
    const state = store.getState();

    for (const integration of Object.values(state.integrations.entities)) {
      if (integration?.provider === "quickbooks-online") {
        dispatch(fetchQboStatementRows({integrationId: integration.id}));
      }
    }
  });
}
