import {WithFloatingElementOnHover} from "@components/Floating";
import clsx from "clsx";

import useStyles from "./Simple.jss";

import type {Placement, UseHoverProps} from "@floating-ui/react";
import type {ReactComponentElement} from "react";

export interface SimpleTooltipProps {
  text: string;
  children: ReactComponentElement<any>;
  delay?: UseHoverProps["delay"];
  placement?: Placement;
  popoverDivClassName?: string;
  offset?: number;
}

export default function SimpleTooltip({
  children,
  text,
  placement,
  popoverDivClassName,
  delay = {open: 200, close: 50},
  offset,
}: SimpleTooltipProps) {
  const styles = useStyles();

  const classes = clsx(styles.simpleTooltipMain);

  // return (
  //   <Popover
  //     align={align || "center"}
  //     position={position || ["bottom", "top", "left", "right"]}
  //     isOpen={isOpen}
  //     arrow
  //     disableSelection
  //     closeOnClick
  //     minimal
  //     openOnClick={false}
  //     content={<div className={classes}>{text}</div>}
  //     rootClass={popoverDivClassName}
  //   >
  //     <span
  //       onMouseEnter={resetTimeout}
  //       onMouseMove={throttledReset}
  //       onClick={!isOpen ? resetTimeout : undefined}
  //       onMouseLeave={handleMouseLeave}
  //     >
  //       {children}
  //     </span>
  //   </Popover>
  // );

  return (
    <WithFloatingElementOnHover
      content={<div className={classes}>{text}</div>}
      portal
      placement={placement}
      delay={delay}
      offset={offset}
    >
      {children}
    </WithFloatingElementOnHover>
  );
}
