import icons from "@app/icons";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {CSSProperties} from "react";

export type IconNames = keyof typeof icons;

export interface SVGIconProps {
  name: IconNames;
  rotate?: number;
  className?: string;
  size?: number;
}

export default function SVGIcon({size, name, rotate, className}: SVGIconProps) {
  const styles = useStyles();
  const classes = clsx(styles.svgIconMain, className);
  const additionalStyles: CSSProperties = {};
  if (rotate) additionalStyles.transform = `rotate(${rotate || 0}deg)`;
  if (size) additionalStyles.height = `${size}px`;
  if (size) additionalStyles.width = `${size}px`;

  return (
    <div className={classes} style={additionalStyles}>
      {icons[name]}
    </div>
  );
}

export function getSvgColorStylesFromProps<T extends Record<string, any>, M extends Record<string, string>>(
  key: string & keyof T,
  fallbackKey?: (string & keyof T) | null,
  mapping?: M,
) {
  return {
    "& svg:not(.no-color)": {
      "& .only.fill, & path:not(.only):not(.no-color)": {
        fill: (styleVars: T) => {
          const resolvedMapping = mapping || {[styleVars[key]]: styleVars[key]};

          return fallbackKey
            ? resolvedMapping[styleVars[key]] || resolvedMapping[styleVars[fallbackKey]]
            : resolvedMapping[styleVars[key]];
        },
      },
      "& .only.stroke": {
        stroke: (styleVars: T) => {
          const resolvedMapping = mapping || {[styleVars[key]]: styleVars[key]};

          return fallbackKey
            ? resolvedMapping[styleVars[key]] || resolvedMapping[styleVars[fallbackKey]]
            : resolvedMapping[styleVars[key]];
        },
      },
    },
  };
}

export function getSvgColorStyles(color: string) {
  return {
    "& svg": {
      "& path.only.fill, & path:not(.only):not(.no-color)": {
        fill: color,
      },
      "& path.only.stroke": {
        stroke: color,
      },
    },
  };
}
