import {projKey, projKeyOmitNulls} from "@state/utils";
import Rfdc from "rfdc";

import type {Dictionary, EntityId} from "@reduxjs/toolkit";
import type {AccountTemplateRow, Template, TemplateOrdering, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";
import type {DatasourceDiff} from "./datasource-utilities";

const rfdc = Rfdc();

export function getVisibleFlatOrdering(
  templateOrdering: Template["ordering"],
  collapsedItems: string[],
  rowEntities: Dictionary<TemplateRow>,
) {
  const rowIds: string[] = [];
  function recursive(ordering: TemplateOrdering[]) {
    for (const orderingItem of ordering) {
      const row = rowEntities[orderingItem.rowId] as AccountTemplateRow;
      if (!row) continue;
      rowIds.push(orderingItem.rowId);
      if (orderingItem.children?.length && !collapsedItems.includes(orderingItem.rowId))
        recursive(orderingItem.children);
    }
  }

  recursive(templateOrdering);

  return rowIds;
}

export function findAllTransactionIdsToClearForLMOAChange(
  state: RootState,
  dateKeysToClear: string[],
  templateIds: string[],
  datasourceDiff: DatasourceDiff,
) {
  const txIds = new Set<string>();
  const cbTxIds: string[] = [];

  const rowIds: string[] = [];

  for (const templateId of templateIds) {
    rowIds.push(...(state.templateRows.idsByTemplateId[templateId] ?? []));
  }

  for (const scenarioId of state.scenarios.ids) {
    for (const rowId of rowIds) {
      for (const dateKey of dateKeysToClear) {
        for (const cbTxId of state.cbTx.idsByRowIdDateKey[projKey(rowId, scenarioId, dateKey)] ?? []) {
          const cbTx = state.cbTx.entities[cbTxId];
          if (!cbTx) continue;
          if (cbTx.tx_id) {
            txIds.add(cbTx.tx_id ?? cbTxId);
          } else {
            cbTxIds.push(cbTxId);
          }
          const cacheKey = projKeyOmitNulls(
            cbTx.row_id,
            cbTx.department_id,
            cbTx.tags.qbo_vendor_id,
            scenarioId,
            dateKey,
          );
          datasourceDiff.cacheKeysUpdatedPerDsId[cbTx.ds_id] ??= [];
          if (!datasourceDiff.cacheKeysUpdatedPerDsId[cbTx.ds_id].includes(cacheKey)) {
            datasourceDiff.cacheKeysUpdatedPerDsId[cbTx.ds_id].push(cacheKey);
          }
        }
      }
    }
  }

  for (const txId of txIds) {
    const cbTxIdsMatchingTxId = state.cbTx.idsByTxId[txId] ?? [];
    cbTxIds.push(...cbTxIdsMatchingTxId);
  }
  return {cbTxIds, datasourceDiff};
}

const forcedChildOrderingByParentName: Record<string, string[]> = {
  null: ["assets", "liabilities_and_equity"],
  liabilities_and_equity: ["liabilities", "equity"],
  current_assets: ["bank_accounts", "accounts_receivable"],
  equity: ["common_stock", "retained_earnings", "net_income"],
} as const;

export type PartialTemplateRow = Pick<TemplateRow, "id" | "name" | "display_name">;
export function reorderTemplateRows(rows: PartialTemplateRow[], ordering: TemplateOrdering[]): TemplateOrdering[] {
  const rowsById = Object.fromEntries(rows.map((row) => [row.id, row]));

  function sortChildren(children: TemplateOrdering[], parentName: string): TemplateOrdering[] {
    if (!children || children.length === 0) return [];

    const forcedOrder = forcedChildOrderingByParentName[parentName] || [];
    const forcedOrderSet = new Set(forcedOrder);

    const forcedChildren: TemplateOrdering[] = [];
    const otherChildren: TemplateOrdering[] = [];

    // Separating forcedChildren and otherChildren
    for (const child of children) {
      const childName = rowsById[child.rowId]?.name;
      if (forcedOrderSet.has(childName ?? "")) {
        forcedChildren.push(child);
      } else {
        otherChildren.push(child);
      }
    }

    // Sort forcedChildren according to the order in forcedOrder
    forcedChildren.sort((a, b) => {
      const nameA = rowsById[a.rowId]?.name ?? "";
      const nameB = rowsById[b.rowId]?.name ?? "";
      return forcedOrder.indexOf(nameA) - forcedOrder.indexOf(nameB);
    });

    // Sort otherChildren alphabetically by their names
    otherChildren.sort((a, b) => {
      const nameA = rowsById[a.rowId]?.name ?? "";
      const nameB = rowsById[b.rowId]?.name ?? "";
      return nameA.localeCompare(nameB);
    });

    // Recursively sort children of each child
    const sortedChildren = forcedChildren
      .map((child) => {
        const childName = rowsById[child.rowId]?.name;
        const returnedChild = {...child};
        if (child.children) {
          returnedChild.children = sortChildren(child.children, childName ?? "");
        }
        return returnedChild;
      })
      .concat(
        otherChildren.map((child) => {
          const childName = rowsById[child.rowId]?.name;
          const returnedChild = {...child};
          if (child.children) {
            returnedChild.children = sortChildren(child.children, childName ?? "");
          }
          return returnedChild;
        }),
      );

    return sortedChildren;
  }

  return sortChildren(ordering, "null");
}

export function getUpdatedOrderingForDeletedRowIds(rowIds: EntityId[], template: Template) {
  const updatedOrdering = rfdc(template.ordering);
  for (const rowId of rowIds) {
    function recursive(orderings: TemplateOrdering[]) {
      const indexesToSplice: number[] = [];
      for (const [i, ordering] of orderings.entries()) {
        if (ordering.rowId === rowId) indexesToSplice.push(i);

        if (ordering?.children?.length) {
          recursive(ordering.children);
        }
      }
      for (const idx of indexesToSplice) {
        orderings.splice(idx, 1);
      }
    }

    recursive(updatedOrdering);
  }

  return {...template, ordering: updatedOrdering};
}
