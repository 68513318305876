import {createSelector, createSlice} from "@reduxjs/toolkit";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {getChildIdsRecursively} from "@shared/lib/department-utilities";
import {generateClientSelectors, getFlatEntitiesWithDepth, itemIsNotFalsy} from "@shared/lib/misc";
import {selectFcDatasources} from "@state/datasources/selectors";
import {selectEmployeeEntities} from "@state/employees/slice";
import {selectDepartmentId, selectScenarioId} from "@state/global/slice";
import {selectAllTeams} from "@state/teams/slice";

import type {ClientRootState} from "@app/client-store";
import type {Dictionary, PayloadAction} from "@reduxjs/toolkit";
import type {HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {Employee, Team} from "@shared/types/hiring-plan";
import type {RootState} from "@state/store";
import type {ColumnDefinition} from "./ListView/Columns";

export type HiringPlanState = {
  collapsedTeams: string[];
  selectedEmployeeId: string | null;
  selectedTeamId: string | null;
  selectedFcName: string | null;
  filterText: string | null;
  sidebarOpen: boolean;
  filters: null | Record<string, string[] | null>;
  sort: {column: string; order: "asc" | "desc"};
  columns: ColumnDefinition[];
};

const initialState: HiringPlanState = {
  collapsedTeams: [],
  selectedEmployeeId: null,
  selectedTeamId: null,
  selectedFcName: null,
  filterText: null,
  sidebarOpen: false,
  filters: {status: ["Termination Pending", "Future Hire", "Current Team"]},
  sort: {column: "first_name", order: "asc"},
  columns: [],
};

export const slice = createSlice({
  name: "hiringPlan",
  initialState,
  reducers: {
    updateHiringPlanState: (state, action: PayloadAction<Partial<HiringPlanState>>) => ({
      ...state,
      ...action.payload,
    }),
    toggleTeamCollapsedState: (state, action: PayloadAction<string>) => {
      const collapsedTeamIndex = state.collapsedTeams.indexOf(action.payload);
      if (collapsedTeamIndex > -1) {
        state.collapsedTeams.splice(collapsedTeamIndex, 1);
      } else {
        state.collapsedTeams.push(action.payload);
      }
    },

    setSelectedEmployeeId: (state, action: PayloadAction<(typeof initialState)["selectedEmployeeId"]>) => {
      state.selectedEmployeeId = action.payload;
      if (state.selectedTeamId) state.selectedTeamId = null;
      if (state.selectedFcName) state.selectedFcName = null;
      if (!!action.payload) state.sidebarOpen = true;
    },

    setSelectedTeamId: (state, action: PayloadAction<(typeof initialState)["selectedTeamId"]>) => {
      state.selectedTeamId = action.payload;
      if (state.selectedEmployeeId) state.selectedEmployeeId = null;
      if (state.selectedFcName) state.selectedFcName = null;
      if (!!action.payload) state.sidebarOpen = true;
    },

    setSidebarOpen: (state, action: PayloadAction<(typeof initialState)["sidebarOpen"]>) => {
      state.sidebarOpen = action.payload;
    },

    setSelectedFinancialComponent: (state, action: PayloadAction<(typeof initialState)["selectedFcName"]>) => {
      state.selectedFcName = action.payload;
    },

    setFilterText: (state, action: PayloadAction<(typeof initialState)["filterText"]>) => {
      state.filterText = action.payload;
    },

    setFiltersForCol: (state, action: PayloadAction<{colName: string; selectedValues: null | string[]}>) => {
      const newFilters = state.filters || {};
      newFilters[action.payload.colName] = action.payload.selectedValues;
      state.filters = newFilters;
    },
  },
});

export const selectors = generateClientSelectors("hiringPlan", slice.getInitialState());

export const selectCollapsedTeams = createSelector(
  (state: ClientRootState) => state.hiringPlan,
  (hiringPlanState) => hiringPlanState.collapsedTeams,
);

export const selectColumnsByName = createSelector(selectors.columns, (columns) =>
  Object.fromEntries(columns.map((col) => [col.name, col])),
);

export const selectEmployeeRowIsSelected = createSelector(
  selectors.sidebarOpen,
  selectors.selectedEmployeeId,
  (_state: ClientRootState, employeeId: string) => employeeId,
  (sidebarOpen, selectedEmployeeId, employeeId) => selectedEmployeeId === employeeId && sidebarOpen,
);

export const selectHpTemplateOptions = createSelector(
  (state: ClientRootState) => state.templates,
  (templatesState) =>
    Object.values(templatesState.entities).find((template) => template?.name === "hiring_plan")?.options,
);

export const selectEmployeeFcRows = createSelector(
  (state: ClientRootState) => state.templateRows,
  (_state: ClientRootState, employeeId: string) => employeeId,
  (templateRowsState, employeeId) =>
    Object.values(templateRowsState.entities).filter(
      (row) => row?.type === "hiring-plan" && row.options.employee_id === employeeId,
    ),
);

export const selectAllFcRows = createSelector(
  (state: ClientRootState) => state.templateRows,
  (templateRowsState) => Object.values(templateRowsState.entities).filter((row) => row?.type === "hiring-plan"),
);

export type ResolvedEmployeeForDisplay = {
  display: Record<string, string | null>;
  value: Record<string, string | number | null>;
  employee: Employee;
};

export const selectResolvedEmployeesByIdResolver = (
  _scenarioId: string | null,
  _fcDatasources: HiringPlanFormulaDatasource[],
  _fcRows: ReturnType<typeof selectAllFcRows>,
  employeeEntities: Dictionary<Employee>,
  columnsByName: Record<string, ColumnDefinition>,
  columns: ColumnDefinition[],
  forSummaryView: boolean,
) => {
  if (!columns?.length) return {};
  time("HiringPlanSlice", "Computing selectResolvedEmployeesForDisplayById");
  const resolvedEmployees: Record<string, ResolvedEmployeeForDisplay> = {};
  for (const employee of Object.values(employeeEntities)) {
    if (!employee) continue;
    const resolvedEmployee: ResolvedEmployeeForDisplay = {employee, display: {}, value: {}};
    for (const col of columns) {
      if (forSummaryView && col.availableIn === "listView") continue;
      resolvedEmployee.display[col.name] = columnsByName[col.name].displayResolver(-1, employee);
      resolvedEmployee.value[col.name] = columnsByName[col.name].valueResolver(-1, employee);
    }

    resolvedEmployee.display.slug = employee.slug;
    resolvedEmployee.display.id = employee.id;
    resolvedEmployee.value.slug = employee.slug;
    resolvedEmployee.value.id = employee.id;

    resolvedEmployees[employee.id] = resolvedEmployee;
  }

  timeEnd("HiringPlanSlice", "Computing selectResolvedEmployeesForDisplayById");
  return resolvedEmployees;
};

export const selectResolvedEmployeesForSummaryById = createSelector(
  selectScenarioId,
  selectFcDatasources,
  selectAllFcRows,
  selectEmployeeEntities,
  selectColumnsByName,
  selectors.columns,
  () => true,
  selectResolvedEmployeesByIdResolver,
);
export const selectResolvedEmployeesForListViewById = createSelector(
  selectScenarioId,
  selectFcDatasources,
  selectAllFcRows,
  selectEmployeeEntities,
  selectColumnsByName,
  selectors.columns,
  () => false,
  selectResolvedEmployeesByIdResolver,
);

export const selectFilteredAndSortedEmployeesForListView = createSelector(
  selectResolvedEmployeesForListViewById,
  selectors.filters,
  selectors.sort,
  selectors.filterText,
  selectDepartmentId,
  selectAllTeams,
  (resolvedEmployees, filters, sort, filterText, globalDepartmentId, teams) => {
    time("HiringPlanSlice", "Computing selectFilteredAndSortedEmployees");
    const teamsById = Object.fromEntries(teams.map((team) => [team.id, team]));
    const filtered: ResolvedEmployeeForDisplay[] = [];
    for (const resolvedEmployee of Object.values(resolvedEmployees)) {
      const employeeTeam = teamsById[resolvedEmployee.employee.team_id ?? ""];
      if (globalDepartmentId && employeeTeam?.department_id !== globalDepartmentId) continue;
      if (filterText?.length) {
        const cleanedFilterText = filterText.toLowerCase().trim();
        const cleanedEmployeeMatchStr = [
          resolvedEmployee.display.first_name,
          resolvedEmployee.display.last_name,
          resolvedEmployee.display.role,
        ]
          .join(" ")
          .toLowerCase()
          .trim();
        if (!cleanedEmployeeMatchStr.includes(cleanedFilterText)) continue;
      }

      let employeeMatchesFilters = true;
      for (const [colName, colFilter] of Object.entries(filters || {})) {
        if (colFilter && !colFilter.includes(resolvedEmployee.display[colName] || "")) {
          employeeMatchesFilters = false;
          break;
        }
      }
      if (!employeeMatchesFilters) continue;

      filtered.push(resolvedEmployee);
    }

    const list = filtered.sort(sortFn(sort));
    const indexMapping: Record<string, number> = {};
    for (const [i, item] of list.entries()) {
      indexMapping[item.employee.id] = i;
    }
    timeEnd("HiringPlanSlice", "Computing selectFilteredAndSortedEmployees");

    return {list, indexMapping};
  },
);

export const selectResolvedEmployeesForSummaryByTeamId = createSelector(
  selectResolvedEmployeesForSummaryById,
  (state: RootState) => state.employees.idsByTeamId,
  (resolvedEmployeesById, employeeIdsByTeamId) => {
    time("HiringPlanSlice", "Computing selectResolvedEmployeesForSummaryByTeamId");
    const returnedObj: Record<string, ResolvedEmployeeForDisplay[]> = {};
    for (const [teamId, employeeIds] of Object.entries(employeeIdsByTeamId)) {
      if (!employeeIds || !teamId) continue;
      returnedObj[teamId] ||= [];
      returnedObj[teamId].push(...employeeIds.map((id) => resolvedEmployeesById[id]).filter(itemIsNotFalsy));
    }

    timeEnd("HiringPlanSlice", "Computing selectResolvedEmployeesForSummaryByTeamId");
    return returnedObj;
  },
);

export const selectFilteredAndSortedEmployeesByTeamIdForSummary = createSelector(
  selectResolvedEmployeesForSummaryByTeamId,
  selectors.filters,
  selectors.sort,
  selectors.filterText,
  selectColumnsByName,
  (resolvedEmployeesByTeamId, filters, sort, filterText, columnsByName) => {
    time("HiringPlanSlice", "Computing selectFilteredAndSortedEmployeesByTeamId");
    const filtered: typeof resolvedEmployeesByTeamId = {};
    for (const [teamId, resolvedEmployees] of Object.entries(resolvedEmployeesByTeamId)) {
      if (!resolvedEmployees) continue;
      for (const resolvedEmployee of resolvedEmployees) {
        if (filterText?.length) {
          const cleanedFilterText = filterText.toLowerCase().trim();
          const cleanedEmployeeMatchStr = [
            resolvedEmployee.display.first_name,
            resolvedEmployee.display.last_name,
            resolvedEmployee.display.role,
          ]
            .join(" ")
            .toLowerCase()
            .trim();
          if (!cleanedEmployeeMatchStr.includes(cleanedFilterText)) continue;
        }

        let employeeMatchesFilters = true;
        for (const [colName, colFilter] of Object.entries(filters || {})) {
          // console.log({
          //   colName,
          //   colFilter,
          //   display: resolvedEmployee.display[colName],
          //   resolvedDisplay: columnsByName[colName]?.displayResolver(-1, resolvedEmployee.employee),
          //   columnsByName,
          // });
          const employeeValueForFilter =
            typeof resolvedEmployee.display[colName] === "undefined"
              ? columnsByName[colName]?.displayResolver(-1, resolvedEmployee.employee) || ""
              : resolvedEmployee.display[colName] || "";
          if (colFilter && !colFilter.includes(employeeValueForFilter)) {
            employeeMatchesFilters = false;
            break;
          }
        }
        if (!employeeMatchesFilters) continue;

        filtered[teamId] ||= [];
        filtered[teamId].push(resolvedEmployee);
      }
      if (filtered[teamId]) filtered[teamId].sort(sortFn(sort));
    }
    timeEnd("HiringPlanSlice", "Computing selectFilteredAndSortedEmployeesByTeamId");
    return filtered;
  },
);

const sortFn =
  (sort: {column: string; order: "asc" | "desc"}) => (a: ResolvedEmployeeForDisplay, b: ResolvedEmployeeForDisplay) => {
    let firstValue = (sort.order === "asc" ? a : b).value[sort.column] ?? "";
    let secondValue = (sort.order === "asc" ? b : a).value[sort.column] ?? "";
    if (sort.column.includes("::summary")) {
      const firstValueAsNumber = typeof firstValue === "string" ? parseInt(firstValue, 10) : firstValue;
      const secondValueAsNumber = typeof secondValue === "string" ? parseInt(secondValue, 10) : secondValue;
      return firstValueAsNumber > secondValueAsNumber ? 1 : firstValueAsNumber < secondValueAsNumber ? -1 : 0;
    } else {
      return firstValue.toString().localeCompare(secondValue.toString());
    }
  };

type EntityWithDepth<E> = {
  entity: E;
  depth: number;
};

function assignOrderToTeams(
  teamsWithDepth: EntityWithDepth<Team>[],
  parentId: string | null = null,
  order = 0,
  orderMap = new Map<string, number>(),
): number {
  const childTeams = teamsWithDepth
    .filter(({entity}) => entity.parent_id === parentId)
    .sort((a, b) => (a.entity.display_name ?? "").localeCompare(b.entity.display_name ?? ""));

  for (const {entity} of childTeams) {
    orderMap.set(entity.id, order++);
    order = assignOrderToTeams(teamsWithDepth, entity.id, order, orderMap);
  }

  return order;
}

export const selectAllTeamsWithDepth = createSelector(
  (state: RootState) => state.teams,
  (state: RootState) => state.departments,
  selectDepartmentId,
  (teamsState, departmentsState, departmentId): EntityWithDepth<Team>[] => {
    let filteredTeams = Object.values(teamsState.entities).filter(itemIsNotFalsy);
    if (departmentId) {
      const idsWithChildren = getChildIdsRecursively(departmentsState, departmentId);
      filteredTeams = filteredTeams.filter(
        (item) => item?.department_id && idsWithChildren.includes(item.department_id),
      );
    }
    const unprocessedTeamsById = Object.fromEntries(filteredTeams.map((item) => [item.id, item]));

    const teamsWithDepth = getFlatEntitiesWithDepth(filteredTeams, "parent_id");
    for (const {entity} of teamsWithDepth) {
      if (unprocessedTeamsById[entity.id]) delete unprocessedTeamsById[entity.id];
    }

    let unprocessedTeamIds = Object.keys(unprocessedTeamsById);
    while (unprocessedTeamIds.length) {
      for (const id of unprocessedTeamIds) {
        const team = unprocessedTeamsById[id];
        if (!team || (team.parent_id && unprocessedTeamsById[team.parent_id])) {
          continue;
        } else {
          const teamWithParentRemoved = {...team, parent_id: null};
          const teams = Object.values(unprocessedTeamsById).filter((item) => item.id !== team.id);
          const result = getFlatEntitiesWithDepth([...teams, teamWithParentRemoved], "parent_id");
          for (const teamWithDepth of result) {
            if (unprocessedTeamsById[teamWithDepth.entity.id]) delete unprocessedTeamsById[teamWithDepth.entity.id];
            teamsWithDepth.push(teamWithDepth);
          }
        }
      }
      unprocessedTeamIds = Object.keys(unprocessedTeamsById);
    }

    const orderMap = new Map<string, number>();
    assignOrderToTeams(teamsWithDepth, null, 0, orderMap);

    return teamsWithDepth.sort((a, b) => (orderMap.get(a.entity.id) || 0) - (orderMap.get(b.entity.id) || 0));
  },
);

export const {
  setSelectedEmployeeId,
  setSelectedTeamId,
  setSelectedFinancialComponent,
  toggleTeamCollapsedState,
  setFilterText,
  setSidebarOpen,
  updateHiringPlanState,
  setFiltersForCol,
} = slice.actions;

export default slice.reducer;
