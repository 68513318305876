import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {IconNames} from "@components/SVGIcon";
import type {FormattingOptions} from "@shared/types/db";

type LayoutOptions = {
  icon: IconNames;
  flipHorizontally?: boolean;
  flipVertically?: boolean;
  rotate?: number;
  variant?: "increase" | "decrease";
  size?: number;
};

const layoutOptions: Record<keyof FormattingOptions, LayoutOptions[]> = {
  bold: [{icon: "bold"}],
  borderBottom: [{icon: "borderBottom"}],
  borderTop: [{icon: "borderBottom", rotate: 180}],
  italic: [{icon: "italic"}],
  percentage: [{icon: "percentage"}],
  currency: [{icon: "dollarSign", size: 8}],
  indentation: [
    {icon: "indent", variant: "increase"},
    {icon: "indent", flipHorizontally: true, variant: "decrease"},
  ],
  decimals: [
    {icon: "decimalsDecrease", variant: "decrease"},
    {icon: "decimalsIncrease", variant: "increase"},
  ],
  condensed: [{icon: "resizeVertical"}],
  smallText: [{icon: "textSize"}],
};

export interface FormattingProps {
  enabledOptions: (keyof FormattingOptions)[];
  selectedOptions?: FormattingOptions;
  onChange: (options: FormattingOptions) => void;
  disabled?: boolean;
}

export default function Formatting({enabledOptions, selectedOptions, onChange, disabled}: FormattingProps) {
  const styles = useStyles();
  const classes = clsx(styles.formattingMain, {[styles.disabled]: disabled});

  const handleButtonClick = (key: keyof FormattingOptions, variant?: LayoutOptions["variant"]) => () => {
    if (disabled) return;
    const newSelectedOptions = {...(selectedOptions || {})};
    switch (key) {
      case "bold":
      case "italic":
      case "borderTop":
      case "percentage":
      case "currency":
      case "condensed":
      case "smallText":
      case "borderBottom": {
        newSelectedOptions[key] = !newSelectedOptions[key] ? true : false;
        break;
      }
      case "indentation":
      case "decimals": {
        const previousValue = newSelectedOptions[key] || 0;
        let newValue = variant === "increase" ? previousValue + 1 : previousValue - 1;
        if (newValue < 0) newValue = 0;
        if (newValue > 6) newValue = 6;
        newSelectedOptions[key] = newValue;
        break;
      }
      default: {
        // todo
      }
    }

    onChange(newSelectedOptions);
  };

  return (
    <div className={classes}>
      {enabledOptions.map((option) =>
        layoutOptions[option].map(({icon, flipHorizontally, flipVertically, variant, size, rotate}) => (
          <div
            className={clsx(styles.formattingOption, {[styles.active]: selectedOptions?.[option] === true})}
            key={`${option}${variant}`}
            onClick={handleButtonClick(option, variant)}
            style={
              flipHorizontally || flipVertically ? {transform: `scale${flipHorizontally ? "X" : "Y"}(-1)`} : undefined
            }
          >
            <SVGIcon name={icon} size={size || undefined} rotate={rotate || 0} />
          </div>
        )),
      )}
    </div>
  );
}
