import {findFormulaDsErrors, getDatasourceDiff, insertDatasources} from "@shared/lib/datasource-utilities";
import {getAllDateKeysBetween, resolveDatasourceDates} from "@shared/lib/date-utilities";
import {time, timeEnd} from "@shared/lib/debug-provider";
import {getIdsFromPayload, mapEntitiesToIds} from "@shared/lib/entity-functions";
import id from "@shared/lib/id";
import {getUpdatedOrderingForDeletedRowIds} from "@shared/lib/template-utilities";
import {upsertAlertsForDs} from "@state/alerts/slice";
import {deleteCbTxLocal, upsertCbTxLocal} from "@state/cb-tx/slice";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {syncForRow} from "@state/integrations/slice";
import {clearMonthlyCacheForDateKeys} from "@state/transaction-items/slice";

import {createEffectHandler} from "../listeners-list";
import {upsertTemplate} from "../templates/slice";
import {addRows, api, removeRow, removeRows, updateRowIntegration, upsertRow, upsertRows} from "./slice";

import type {EntityId} from "@reduxjs/toolkit";
import type {SyncReturnType} from "@shared/apis/integrations";
import type {DbIntegrationDatasource} from "@shared/types/datasources";
import type {TemplateOrdering, TemplateRow} from "@shared/types/db";

export const syncRowUpsertsWithAPI = createEffectHandler(
  [addRows, upsertRow, upsertRows],
  (action, {getState, dispatch}) => {
    const rowsToUpsert: TemplateRow[] = [];
    const ids = upsertRows.match(action)
      ? action.payload.map((item) => item.id)
      : addRows.match(action)
      ? action.payload.rows.map((item) => item.id)
      : upsertRow.match(action)
      ? [action.payload?.id || ""]
      : [];

    const state = getState();

    for (const id of ids) {
      const row = state.templateRows.entities[id];
      if (row) rowsToUpsert.push(row);
    }
    if (rowsToUpsert.length) {
      setTimeout(() => {
        dispatch<any>(api.upsert(rowsToUpsert));
      }, 1);
    }
  },
);

export const syncRowDeletesWithAPI = createEffectHandler([removeRow, removeRows], (action, {dispatch}) => {
  if (action.payload) dispatch<any>(api.delete(getIdsFromPayload(action.payload)));
});

export const updateTemplateOrdering = createEffectHandler(
  [addRows, removeRow, removeRows],
  (action, {getState, dispatch, getOriginalState}) => {
    const state = getState();
    if (addRows.match(action)) {
      const template = state.templates.entities[action.payload.templateId];
      if (!template) return;
      const newOrdering = [...template.ordering];
      const newOrderingItems: TemplateOrdering[] = [];
      for (const row of action.payload.rows) {
        newOrderingItems.push({rowId: row.id});
      }
      if (typeof action.payload.index !== "undefined") {
        newOrdering.splice(action.payload.index, 0, ...newOrderingItems);
      } else {
        newOrdering.push(...newOrderingItems);
      }

      dispatch({...upsertTemplate({...template, ordering: newOrdering})});
    } else {
      const originalState = getOriginalState();
      const rowIds = getIdsFromPayload(action.payload);

      // Check for errors
      for (const row of mapEntitiesToIds(originalState.templateRows.entities, rowIds)) {
        const datasourcesImpacted = mapEntitiesToIds(
          state.datasources.entities,
          state.datasources.idsByReferencedEntityOrTag[`row:${row.name}`],
        );
        for (const datasource of datasourcesImpacted) {
          if (datasource.type === "formula" || datasource.type === "hiring-plan-formula") {
            const dsErrors = findFormulaDsErrors(datasource, state.templateRows.idsByName, state.departments.idsByName);

            dispatch(upsertAlertsForDs(dsErrors));
          }
        }
      }

      const rowsIdsByTemplateId: Record<string, EntityId[]> = {};
      for (const rowId of rowIds) {
        const row = originalState.templateRows.entities[rowId];
        if (!row) return;
        rowsIdsByTemplateId[row.template_id] ||= [];
        rowsIdsByTemplateId[row.template_id].push(rowId);
      }

      for (const [templateId, deletedRowIdsInTemplate] of Object.entries(rowsIdsByTemplateId)) {
        const template = state.templates.entities[templateId];
        if (!template) continue;
        const updatedOrdering = getUpdatedOrderingForDeletedRowIds(deletedRowIdsInTemplate, template);
        dispatch({...upsertTemplate(updatedOrdering), meta: action.meta});
      }
    }
  },
);

export const updateRowIntegrationListener = createEffectHandler(
  updateRowIntegration,
  async (action, {getState, dispatch}) => {
    const state = getState();
    // debugger;
    const {integrationId, rowId, type, value} = action.payload;

    const scenarioId = state.global.scenarioId;
    const row = state.templateRows.entities[rowId];
    if (!row || !scenarioId) return;
    const template = state.templates.entities[row.template_id];
    if (!template) return;

    const rowDatasources = mapEntitiesToIds(state.datasources.entities, state.datasources.idsByRowId[rowId]).filter(
      (ds) => ds.scenario_id === scenarioId,
    );

    const actualsDatasource =
      (rowDatasources.find((ds) => !ds.start && ds.end === "-1a" && ds.type === "integration") as
        | DbIntegrationDatasource
        | undefined) ?? null;

    const selectedIntegration =
      type === "col" ? state.integrations.entities[integrationId ?? ""] : state.integrations.entities[value ?? ""];

    if (type === "col" && !selectedIntegration) return;

    const datasourceDates = resolveDatasourceDates(
      null,
      "-1a",
      template.options.start,
      template.options.end,
      template.options.lastMonthOfActuals,
    );

    const dateKeys = getAllDateKeysBetween(datasourceDates.start, datasourceDates.end);
    // dispatch(toggleRowIdPendingState({rowId: row.id, dateKeys}));
    dispatch(clearMonthlyCacheForDateKeys({dateKeys, rowId: row.id, scenarioId, removeVirtualTx: true}));
    if (value && selectedIntegration) {
      if (selectedIntegration?.provider !== "snowflake") return;

      const remoteId = type === "col" ? value : selectedIntegration.data.availableColumns[0];

      // Generate new datasource right away as it will be needed to generate the new aggregate cbtx
      let newDatasource: DbIntegrationDatasource;
      if (actualsDatasource) {
        newDatasource = {
          ...actualsDatasource,
          integration_id: selectedIntegration.id,
          options: {
            remoteId,
          },
        };
      } else {
        newDatasource = {
          id: id(),
          row_id: row.id,
          scenario_id: scenarioId,
          department_id: null,
          start: null,
          end: "-1a",
          type: "integration",
          integration_id: selectedIntegration.id,
          options: {
            remoteId,
          },
        };
      }

      time("syncForRow", `Synced integration for row ${row.name} to ${selectedIntegration.name}, column ${remoteId}`);
      const syncResult = (
        await dispatch(
          syncForRow({id: selectedIntegration.id, rowId: row.id, type: "datawarehouse", datasource: newDatasource}),
        )
      ).payload as SyncReturnType;
      timeEnd(
        "syncForRow",
        `Synced integration for row ${row.name} to ${selectedIntegration.name}, column ${remoteId}`,
      );

      if (syncResult.deletes) {
        dispatch(deleteCbTxLocal(syncResult.deletes));
      }

      if (syncResult.upserts) {
        dispatch(upsertCbTxLocal(syncResult.upserts));
      }

      const result = insertDatasources({
        existingDatasources: rowDatasources,
        datasourcesToAdd: [newDatasource],
        forecastDates: template.options,
      });

      const datasourceDiff = getDatasourceDiff(rowDatasources, result.datasources, state);

      dispatch(
        applyDatasourceChanges({
          datasourceDiff,
          reason: `Set integration for row ${row.name} to ${selectedIntegration.name}, column ${remoteId}`,
        }),
      );
    } else if (type === "integration" && !selectedIntegration && actualsDatasource) {
      const datasourceDiff = getDatasourceDiff(
        rowDatasources,
        rowDatasources.filter((ds) => ds.id !== actualsDatasource.id),
        state,
      );

      await dispatch(applyDatasourceChanges({datasourceDiff, reason: `Removed integration for row ${row.name}`}));
      // dispatch(
      //   api.clearRanges([
      //     {
      //       start: getFormattedFullDate(datasourceDates.start, "start"),
      //       end: getFormattedFullDate(datasourceDates.end, "end"),
      //       rowId: row.id,
      //       scenarioId,
      //     },
      //   ]),
      // ).then(() => dispatch(toggleRowIdPendingState({rowId: row.id, dateKeys: null})));
      // dispatch(toggleRowIdPendingState({rowId: row.id, dateKeys: null}));
    }
  },
);
