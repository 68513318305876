import {getDsDiffAfterEmployeeDatesChange} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import {getDatasourceDiff} from "@shared/lib/datasource-utilities";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import {getIdsFromPayload, mapEntitiesToIds} from "@shared/lib/entity-functions";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {removeRows, upsertRows} from "@state/template-rows/slice";

import {createEffectHandler} from "../listeners-list";
import {api, employeeScenarioPropertiesUpdated, employeeUpdated, removeEmployees} from "./slice";

import type {EntityId} from "@reduxjs/toolkit";
import type {Datasource, HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {HiringPlanTemplateRow} from "@shared/types/db";

export const syncUpdatedEmployeeWithAPI = createEffectHandler(employeeUpdated, (action, {getState, dispatch}) => {
  const employee = getState().employees.entities[action.payload.id];
  if (!employee) return;
  if (
    employee.team_id &&
    employee.team_id !== "null" &&
    Object.values(employee.scenario_properties).some((scenarioProp) => scenarioProp.hire_date)
  ) {
    dispatch(api.upsertEmployee(employee));
  }
});

export const removeEmployeesListener = createEffectHandler(removeEmployees, (action, {getState, dispatch}) => {
  (async () => {
    const state = getState();
    const employeeIds = getIdsFromPayload(action.payload);

    const rowsIdsToBeRemoved: string[] = [];
    for (const id of employeeIds) {
      const employeeFcRowIds = state.templateRows.idsByEmployeeId[id];
      if (employeeFcRowIds?.length) rowsIdsToBeRemoved.push(...employeeFcRowIds);
    }

    const datasourceIdsToRemove: EntityId[] = [];
    const previousDatasources: Datasource[] = [];
    for (const id of rowsIdsToBeRemoved) {
      const datasourceIdsForRow = state.datasources.idsByRowId[id];
      previousDatasources.push(...mapEntitiesToIds(state.datasources.entities, datasourceIdsForRow));
      if (datasourceIdsForRow?.length) datasourceIdsToRemove.push(...datasourceIdsForRow);
    }
    if (datasourceIdsToRemove?.length) {
      log("removeEmployeesListener", "Dispatching applyDatasourceChanges");
      time("removeEmployeesListener", "Done running applyDatasourceChanges");
      const datasourceDiff = getDatasourceDiff(previousDatasources, [], state);
      await dispatch(applyDatasourceChanges({datasourceDiff, reason: "Employee(s) removed"}));
      timeEnd("removeEmployeesListener", "Done running applyDatasourceChanges");
    }
    if (rowsIdsToBeRemoved?.length) dispatch(removeRows(rowsIdsToBeRemoved));
    dispatch(api.removeEmployees(employeeIds));
  })();
});

export const triggerDatasourcesUpdate = createEffectHandler(
  employeeScenarioPropertiesUpdated,
  (action, {getState, getOriginalState, dispatch}) => {
    const state = getState();
    const currentEmployee = state.employees.entities[action.payload.id];
    const originalEmployee = getOriginalState().employees.entities[action.payload.id];
    if (!currentEmployee || !originalEmployee) return;

    const oldValue = originalEmployee.scenario_properties[action.payload.scenarioId]?.[action.payload.key];
    const newValue = currentEmployee.scenario_properties[action.payload.scenarioId][action.payload.key];
    if (oldValue === newValue || (!oldValue && !newValue)) return;

    if (action.payload.key === "hire_date" || action.payload.key === "term_date") {
      const {deletedIds, upsertedDatasources, datasourceDiff} = getDsDiffAfterEmployeeDatesChange({
        state,
        employeeId: action.payload.id,
      });

      if (deletedIds.length || upsertedDatasources.length)
        dispatch(
          applyDatasourceChanges({
            datasourceDiff,
            reason: `Property ${action.payload.key} changed for employee ${getEmployeeDisplayName(currentEmployee)}`,
          }),
        );
    }
  },
);

export const propagateEmployeeMappingAndTagChanges = createEffectHandler(
  employeeUpdated,
  (action, {getState, getOriginalState, dispatch}) => {
    if (action.payload.key !== "team_id") return;
    const state = getState();
    const originalState = getOriginalState();

    const employee = state.employees.entities[action.payload.id];
    const oldEmployee = originalState.employees.entities[action.payload.id];
    if (!employee || !oldEmployee) return;

    const team = state.teams.entities[employee.team_id || ""];
    const oldTeam = state.teams.entities[oldEmployee.team_id || ""];

    const fcRowIds = state.templateRows.idsByEmployeeId[employee.id];
    const fcRowsToUpsert: HiringPlanTemplateRow[] = [];
    const impactedRefsAndTags: Set<string> = new Set();

    impactedRefsAndTags.add(`team:${oldTeam?.name || "unassigned"}`);
    impactedRefsAndTags.add(`team:${team?.name || "unassigned"}`);

    const upsertedOriginalDatasources: HiringPlanFormulaDatasource[] = [];
    const upsertedDatasources: Datasource[] = [];

    for (const fcRowId of fcRowIds || []) {
      const matchingfcRow = state.templateRows.entities[fcRowId];
      if (matchingfcRow?.type !== "hiring-plan") continue;

      const fcRowToUpsert: HiringPlanTemplateRow = {
        ...matchingfcRow,
        tags: {
          ...matchingfcRow.tags,
          team: team?.name || "unassigned",
        },
      };

      fcRowsToUpsert.push(fcRowToUpsert);

      // Update the department_id of the datasource if the team changed, if needed
      if (oldTeam?.department_id !== team?.department_id) {
        for (const datasourceId of state.datasources.idsByRowId[fcRowId] || []) {
          const datasource = state.datasources.entities[datasourceId];
          if (datasource?.type !== "hiring-plan-formula" || datasource.options.employee_id !== employee.id) continue;

          upsertedOriginalDatasources.push(datasource);

          const updatedDatasource = {...datasource, department_id: team?.department_id || null};
          upsertedDatasources.push(updatedDatasource);
        }
      }
    }

    dispatch({
      ...upsertRows(fcRowsToUpsert),
      meta: action.meta,
    });

    for (const impactedTag of impactedRefsAndTags) {
      const datasourceIdsReferencingTag = state.datasources.idsByReferencedEntityOrTag[impactedTag];
      for (const datasourceId of datasourceIdsReferencingTag || []) {
        const datasource = state.datasources.entities[datasourceId];
        if (datasource) upsertedDatasources.push(datasource);
      }
    }

    const datasourceDiff = getDatasourceDiff(upsertedOriginalDatasources, upsertedDatasources, state);
    dispatch(
      applyDatasourceChanges({
        datasourceDiff,
        reason: `Moved employee ${getEmployeeDisplayName(employee)} from team ${
          oldTeam?.name || "unassigned"
        } to team ${team?.name || "unassigned"}`,
      }),
    );
  },
);
