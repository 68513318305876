import {createEffectHandler} from "../listeners-list";
import {api, deleteReport, upsertReport} from "./slice";

export const syncReportUpsertsToAPI = createEffectHandler([upsertReport], (action, {getState, dispatch}) => {
  dispatch(api.upsertReport(action.payload));
});

export const syncReportDeletesToAPI = createEffectHandler([deleteReport], (action, {getState, dispatch}) => {
  dispatch(api.deleteReport(action.payload));
});
