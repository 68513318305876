import {useAppDispatch, useAppSelector} from "@app/hooks";
import {socket} from "@app/websockets/websockets";
import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {selectScenarioId} from "@shared/state/global/slice";
import {selectAllScenarios} from "@shared/state/scenarios/slice";
import clsx from "clsx";
import {lazy, startTransition, useEffect} from "react";
import Helmet from "react-helmet";
import {useMeasure} from "react-use";

import HiringPlanSidebar from "../hiring-plan/Sidebar";
import {setSelectedEmployeeId, setSidebarOpen} from "../hiring-plan/slice";
import {DragStartSensor} from "./DragSensor";
import FormulaBar from "./FormulaBar";
import Sidebar from "./Sidebar";
import {useTemplateShortcuts} from "./shortcuts";
import {selectors} from "./state/selectors";
import {
  otherUserSelectedACell,
  setColumnDateKeys,
  setSelectedTemplateId,
  updateTemplatesViewState,
} from "./state/slice";
import useStyles from "./styles.jss";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import type {Template} from "@shared/types/db";

const Rows = lazy(() => import("./Rows"));

export default function Templates({template}: {template: Template}) {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [ref, {width}] = useMeasure<HTMLDivElement>();

  const scenarios = useAppSelector(selectAllScenarios);
  const scenarioId = useAppSelector(selectScenarioId);
  const templateIdInState = useAppSelector(selectors.templateId);
  const rowNameWidth = useAppSelector(selectors.rowNameWidth);
  const sidebarOpen = useAppSelector(selectors.activeCell)?.rowId;

  const isDragging = useAppSelector(selectors.draggingFrom) !== null;

  useTemplateShortcuts();

  useEffect(() => {
    if (!template.id) return;
    socket.on(`user-selected-cell:${template.id}`, (data: any) => {
      dispatch(otherUserSelectedACell(data.rowId ? data : null));
    });

    return () => {
      socket.off(`user-selected-cell:${template.id}`);
    };
  }, [template.id, dispatch]);

  useEffect(() => {
    // On mount or
    if (template?.id) {
      const columnDateKeys = getAllDateKeysBetween(template.options.visibleStart, template.options.visibleEnd);
      startTransition(() => {
        dispatch(setSelectedTemplateId(template.id));
        dispatch(setColumnDateKeys(columnDateKeys));
      });
    }

    // On unmount
    return () => {
      dispatch(setSelectedEmployeeId(null));
      dispatch(
        updateTemplatesViewState({
          activeCell: {rowId: null, column: null},
          caller: "Templates->useEffect->unmount",
        }),
      );
    };
  }, [dispatch, template.options, template.id]);

  useEffect(() => {
    logUserEvent("view-template", {templateId: template.id});
  }, [template.id]);

  useEffect(() => {
    dispatch(setSidebarOpen(false));
    if (template?.type !== "hiring_plan") dispatch(setSelectedEmployeeId(null));
  }, [template, dispatch]);

  if (!scenarioId || !template || !templateIdInState) return null;

  const templateClasses = clsx(styles.templateMain, {
    [styles.dragging]: isDragging,
    // [styles.sidebarOpen]: sidebarOpen,
  });

  return (
    <div className={templateClasses} ref={ref}>
      <Helmet>
        <title>{template.display_name} &middot; Cloudberry</title>
      </Helmet>
      <FormulaBar />
      <DragStartSensor />
      <Rows />
      {template.type === "hiring_plan" ? <HiringPlanSidebar context="templatesView" /> : <Sidebar />}
    </div>
  );
}
