import {createUseStyles} from "react-jss";

export default createUseStyles({
  debugPanelItem: {
    marginBottom: 25,
  },
  debugPanelItemTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,

    "& > strong": {
      alignSelf: "center",
      marginRight: 10,
      display: "inline-flex",
      alignItems: "center",
    },
  },
});
