import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {IconNames} from "@components/SVGIcon";

export interface RoundIconProps {
  animate?: boolean;
  minimal?: boolean;
  compact?: boolean;
  containerSize?: number;
  iconSize?: number;
  text?: string;
  icon?: IconNames;
  rotate?: number;
  mainColor?:
    | "green"
    | "orange"
    | "fullOrange"
    | "blue"
    | "tertiaryBlue"
    | "purple"
    | "grey"
    | "darkGrey"
    | "white"
    | "red";
  className?: string;
  iconColor?:
    | "green"
    | "orange"
    | "fullOrange"
    | "blue"
    | "tertiaryBlue"
    | "purple"
    | "grey"
    | "darkGrey"
    | "white"
    | "red";
  title?: string;
}

export default function RoundIcon({
  animate,
  containerSize = 30,
  iconSize,
  mainColor,
  text,
  icon,
  iconColor,
  minimal,
  rotate,
  className,
  compact,
  title,
}: RoundIconProps) {
  const styles = useStyles({iconSize, containerSize, mainColor, iconColor, minimal, text});

  const content = !!text ? text : !!icon ? <SVGIcon name={icon} rotate={rotate} /> : "";

  const classes = clsx(styles.roundIconMain, className, {[styles.compact]: compact, [styles.animate]: animate});

  return (
    <div className={classes} title={title} style={{width: containerSize, height: containerSize}}>
      {content}
    </div>
  );
}
