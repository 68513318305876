import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  datasourceItem: {},
  table: {
    fontWeight: 500,
    marginTop: "0 !important",

    "& td, & th": {
      whiteSpace: "nowrap",
    },
  },
  formula: {
    fontFamily: "monospace",
  },
  formulaWrapper: {
    background: colors.tertiary.lightBlue02,
    borderRadius: 3,
    display: "inline-flex",
  },
});
