import {useAppDispatch, useAppSelector} from "@app/hooks";
import {setNotificationContent} from "@app/ui-state/slice";
import NotificationModal from "@components/NotificationModal";

export default function NotificationModalSingleton() {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((state) => state.uiState.notification);

  const handleCancel = () => {
    dispatch(setNotificationContent(null));
    if (notification?.onCancel) notification.onCancel();
  };

  const handleConfirm = () => {
    dispatch(setNotificationContent(null));
    if (notification?.onConfirm) notification?.onConfirm();
  };

  return !!notification ? (
    <NotificationModal {...notification} onCancel={handleCancel} onConfirm={handleConfirm} />
  ) : null;
}
