import {useAppSelector} from "@app/hooks";
import {selectScenarioId} from "@state/global/slice";
import {projKeyOmitNulls} from "@state/utils";

import {selectors} from "../state/selectors";
import useStyles from "./ActiveCell.jss";
import {CellDebugTools} from "./CellDebugTools";

export default function ActiveCell({resolveIds}: {resolveIds: boolean}) {
  const styles = useStyles();

  const activeCell = useAppSelector(selectors.activeCell);
  const scenarioId = useAppSelector(selectScenarioId);

  if (!activeCell)
    return (
      <div className={styles.activeCellDebug}>
        <h2>Active Cell</h2>
        <span>No active cell</span>
      </div>
    );
  const cacheKey =
    activeCell.rowId && activeCell.column
      ? projKeyOmitNulls(activeCell.rowId, activeCell.departmentId, activeCell.vendor, scenarioId, activeCell.column)
      : null;

  return (
    <div className={styles.activeCellDebug}>
      {cacheKey ? (
        <CellDebugTools resolveIds={resolveIds} context="activeCell" cacheKey={cacheKey} />
      ) : (
        <span>No cache key selected</span>
      )}
    </div>
  );
}
