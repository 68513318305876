import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {IconNames} from "@components/SVGIcon";

export type TagProps = {
  text: string;
  color: "green" | "blue" | "orange" | "red";
  iconLeft?: IconNames;
  iconRight?: IconNames;
  hover?: boolean;
  onClick?: () => void;
  active?: boolean;
  size?: "small" | "medium";
};

export default function Tag({active, text, color, iconLeft, iconRight, hover, onClick, size = "medium"}: TagProps) {
  const styles = useStyles({color, hover});
  const classes = clsx(styles.tagMain, styles[size], {[styles.active]: active});

  return (
    <div className={classes} onClick={onClick}>
      {iconLeft ? <SVGIcon name={iconLeft} size={size === "small" ? 12 : 16} /> : null}
      <span>{text}</span>
      {iconRight ? <SVGIcon name={iconRight} /> : null}
    </div>
  );
}
