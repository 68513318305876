import {store} from "@app/client-store";
import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import RoundButton from "@components/RoundButton";
import SVGIcon from "@components/SVGIcon";
import SimpleTooltip from "@components/Tooltip/Simple";
import {updateTemplatesViewState} from "@features/templates/state/slice";
import {resolveStartEndFromState} from "@shared/lib/date-utilities";
import {isDsError, isSanityCheckAlert, type AppAlert} from "@shared/types/alerts";
import {selectAllAlerts} from "@state/alerts/slice";
import {selectors} from "@state/global/slice";
import {selectAllFailingSanityChecks} from "@state/sanity-checks/selectors";
import {selectSanityChecksLastRun} from "@state/sanity-checks/slice";
import {useNavigate} from "react-router-dom";

import {getErrorMessageSubtext, getErrorMessageText, getErrorTemplateName} from "./alert-client-utilities";
import useStyles from "./styles.jss";

import {logUserEvent} from "@app/websockets/websocket-action-logger";
import type {ActiveCell} from "@features/templates/state/slice";
import type {Template} from "@shared/types/db";
import {useEffect} from "react";

export default function AlertsPanel() {
  const allAlerts = useAppSelector(selectAllAlerts);
  const sanityChecks = useAppSelector(selectAllFailingSanityChecks);
  const fullCacheLoaded = useAppSelector(selectors.fullCacheLoadDone);
  const sanityChecksLastRun = useAppSelector(selectSanityChecksLastRun);

  const styles = useStyles();

  useEffect(() => {
    logUserEvent("opened-alerts-panel");
  }, []);

  let content = null;
  if (!fullCacheLoaded || (!allAlerts.length && !sanityChecksLastRun)) {
    content = <div className={styles.loading}>Initializing checks...</div>;
  } else if (allAlerts.length) {
    content = <AlertsList alerts={allAlerts} />;
  } else {
    content = <div className={styles.noAlerts}>No Errors to Display</div>;
  }

  return (
    <div className={styles.alertListMain}>
      <div className={styles.header}>
        <h3>Errors & Sanity Checks</h3>
      </div>
      <div className={styles.alerts}>{content}</div>
    </div>
  );
}

function AlertsList({alerts}: {alerts: AppAlert[]}) {
  const styles = useStyles();

  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const handleGoToError = (error: AppAlert) => {
    const state = store.getState();
    logUserEvent("go-to-error", {error});
    let url: string | null = null;
    let activeCellToSet: ActiveCell | null = null;
    if (isDsError(error)) {
      const datasource = state.datasources.entities[error.datasource_id];
      const row = state.templateRows.entities[datasource?.row_id ?? ""];
      const template = state.templates.entities[row?.template_id ?? ""];
      if (!datasource || !row || !template) return;

      const datasourceDates = resolveStartEndFromState(datasource, state);
      const startIsWithinVisibleRange =
        datasourceDates.start >= template.options.visibleStart && datasourceDates.start <= template.options.visibleEnd;

      url = getUrl(template);

      if (startIsWithinVisibleRange) {
        activeCellToSet = {
          column: datasourceDates.start.slice(0, 7),
          rowId: row.id,
          departmentId: datasource.department_id ?? null,
          vendor: datasource.dimensions?.vendor ?? null,
        };
      }
    } else if (isSanityCheckAlert(error)) {
      const sanityCheck = state.sanityChecks.entities[error.sanity_check_id];
      const row = state.templateRows.entities[sanityCheck?.check_entity_identifier ?? ""];
      const template = state.templates.entities[row?.template_id ?? ""];
      if (!sanityCheck || !row || !template) return;

      url = getUrl(template);

      // Find the first failing month (in details.months) that's within the visible range
      const firstFailingMonthWithinRange = Object.keys(error.details.months).find(
        (month) => month >= template.options.visibleStart && month <= template.options.visibleEnd,
      );

      if (firstFailingMonthWithinRange) {
        activeCellToSet = {
          column: firstFailingMonthWithinRange,
          rowId: row.id,
          departmentId: null,
          vendor: null,
        };
      }
    }

    if (url) navigate(url);
    if (activeCellToSet) {
      setTimeout(() => {
        dispatch(updateTemplatesViewState({activeCell: activeCellToSet}));
      }, 100);
    }
  };

  return (
    <>
      {alerts.map((alert) => (
        <div key={alert.id} className={styles.alertItem}>
          <div className={styles.alertItemIcon}>
            <SVGIcon name="errorExclamationPoint" />
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.errorText}>{getErrorMessageText(alert)}</div>
            <div className={styles.errorSubtext}>{getErrorMessageSubtext(alert)}</div>
          </div>
          <div className={styles.goToErrorIcon}>
            <SimpleTooltip text={`Go to ${getErrorTemplateName(alert)}`} placement="left">
              <RoundButton icon="arrow" rotate={180} enableCssStates onClick={() => handleGoToError(alert)} />
            </SimpleTooltip>
          </div>
        </div>
      ))}
    </>
  );
}

function getUrl(template: Template) {
  const templateUrlPrefix = template.type === "financial_statement" ? "/financial-statements" : "/worksheets";
  const path = template.type === "financial_statement" ? "" : `/${template.name}`;
  const searchParams = template.type === "financial_statement" ? `?statement=${template.name}` : ``;
  return `${templateUrlPrefix}${path}${searchParams}`;
}
