import {useAppSelector} from "@app/hooks";
import {callWorkerFn} from "@app/worker";
import DatePicker from "@components/Datepicker/RDP";
import FormElement from "@components/FormElement";
import Section, {SectionCol} from "@components/RightSidebar/Section";
import SVGIcon from "@components/SVGIcon";
import {selectors} from "@features/templates/state/selectors";
import {TimePeriodAliasDropdown} from "@features/templates/TemplateOptions/DatePickerTab";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import {selectScenarioId} from "@shared/state/global/slice";
import {selectTemplateRowById} from "@shared/state/template-rows/slice";
import {selectTemplateById} from "@shared/state/templates/slice";
import clsx from "clsx";
import dayjs from "dayjs";
import {useEffect, useState} from "react";

import useStyles from "./styles.jss";
import {cols, getTransactionsItemsForDisplay} from "./transaction-utilities";

import type {
  DisplayAsTimePeriod,
  TimePeriodAliasDropdownProps,
} from "@features/templates/TemplateOptions/DatePickerTab";
import type {CbTx, IntegrationTx, Template} from "@shared/types/db";
import type {TransactionRow} from "./transaction-utilities";

export type TxSort = {col: (typeof cols)[number]; order: "asc" | "desc"};
export default function Transactions({expanded}: {expanded: boolean}) {
  const [sort, setSort] = useState<TxSort>({
    col: cols[0],
    order: "asc",
  });
  const [search, setSearch] = useState("");
  const [txLoading, setTxLoading] = useState(false);
  const {rowId, column, departmentId, vendor} = useAppSelector(selectors.activeCell);
  const templateId = useAppSelector(selectors.templateId);
  const template = useAppSelector((state) => selectTemplateById(state, templateId || "")) || null;
  const selectedRow = useAppSelector((state) => selectTemplateRowById(state, rowId ?? null));
  const styles = useStyles();
  const initialSelected = getInitialSelectedMonth(template, column ?? null);
  const [timePeriod, setTimePeriod] = useState<TimePeriodAliasDropdownProps["selectedTimePeriod"]>(
    initialSelected.timePeriod,
  );
  const [dates, setDates] = useState<{from: string; to: string}>(initialSelected);
  const [transactions, setTransactions] = useState<(CbTx | IntegrationTx)[] | null>(null);
  const scenarioId = useAppSelector(selectScenarioId);

  useEffect(() => {
    setTxLoading(true);
    callWorkerFn("selectTxItemsMatchingRowIdScenarioId", [
      selectedRow?.mirror_of ? selectedRow.mirror_of : selectedRow?.id || "",
      scenarioId || "",
      dates.from,
      dates.to,
      departmentId,
      vendor,
    ]).then((result) => {
      const typedItems = result as Awaited<typeof result>;
      if (typedItems) setTransactions([...typedItems.cbTx, ...typedItems.integrationTx]);
      setTxLoading(false);
    });
  }, [setTransactions, selectedRow, scenarioId, dates.from, dates.to, departmentId, vendor]);

  useEffect(() => {
    setSearch("");
  }, [setSearch, selectedRow, scenarioId]);

  if (!selectedRow || !scenarioId || !template) return null;

  const handleSort =
    (col: (typeof cols)[number]): React.MouseEventHandler =>
    (evt) => {
      let order = sort.order;
      if (sort.col.key === col.key && sort.order === "asc") order = "desc";
      if (sort.col.key === col.key && sort.order === "desc") order = "asc";
      setSort({col, order});
    };

  const handleTimePeriodChange: TimePeriodAliasDropdownProps["onChange"] = ({timePeriod, from, to}) => {
    setTimePeriod(timePeriod);
    if (from && to) setDates({from, to});
  };

  const transactionRows = transactions
    ? getTransactionsItemsForDisplay(selectedRow, transactions, dates, template, sort, search, expanded)
    : [];

  const handleSearchChange = (searchStr: string) => {
    setSearch(searchStr);
  };

  return (
    <Section title="Transactions" wrapContent={false} searchText={search} onSearchChange={handleSearchChange}>
      <SectionCol>
        <div className={styles.txTimePeriod}>
          <FormElement label="Time Period">
            <TimePeriodAliasDropdown
              onChange={handleTimePeriodChange}
              selectedTimePeriod={timePeriod}
              lastMonthOfActuals={template.options.lastMonthOfActuals}
            />
          </FormElement>
          <div className={styles.datePickersWrapper}>
            <DatePicker
              onChange={(value) =>
                handleTimePeriodChange({timePeriod: "custom", from: value || undefined, to: dates.to})
              }
              value={getFormattedFullDate(dates.from || template.options.start, "start", "YYYY-MM-DD")}
              min={template.options.start}
              max={template.options.end}
            />
            <div className={styles.to}>to</div>
            <DatePicker
              onChange={(value) =>
                handleTimePeriodChange({timePeriod: "custom", from: dates.from, to: value || undefined})
              }
              value={getFormattedFullDate(dates.to || template.options.end, "end", "YYYY-MM-DD")}
              min={template.options.start}
              max={template.options.end}
            />
          </div>
        </div>

        <table className={styles.transactionsTable}>
          <thead>
            <tr>
              {cols.map((col) => (
                <th
                  key={col.key}
                  onClick={handleSort(col)}
                  className={clsx(col.cellClass ? styles[col.cellClass] : undefined)}
                >
                  {col.title}
                  {sort.col.key === col.key ? (
                    <SVGIcon name="chevron" rotate={sort.col.key === col.key && sort.order === "asc" ? 180 : 0} />
                  ) : null}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {txLoading ? (
              <tr>
                <td colSpan={cols.length}>Loading...</td>
              </tr>
            ) : (
              transactionRows.map((txRow) => <TxRow key={txRow.id} txRow={txRow} />)
            )}
          </tbody>
        </table>
      </SectionCol>
    </Section>
  );
}

function TxRow({txRow}: {txRow: TransactionRow}) {
  const styles = useStyles();

  return (
    <tr className={styles.transactionRow}>
      {cols.map((col) => (
        <td
          key={col.key}
          className={clsx(styles.cell, col.cellClass ? styles[col.cellClass] : undefined)}
          title={col.cellTitle?.(txRow) ?? undefined}
        >
          {col.render ? col.render(txRow, styles) : txRow[col.key]}
        </td>
      ))}
    </tr>
  );
}

function getInitialSelectedMonth(
  template: Template | null,
  selectedColumn: string | null,
): {timePeriod: DisplayAsTimePeriod; from: string; to: string} {
  if (!!selectedColumn) {
    return {timePeriod: "custom", from: selectedColumn, to: selectedColumn};
  } else if (!!template) {
    return {
      timePeriod: "last_month",
      from: dayjs(template.options.lastMonthOfActuals, "YYYY-MM").startOf("month").format("YYYY-MM-DD"),
      to: dayjs(template.options.lastMonthOfActuals, "YYYY-MM").endOf("month").format("YYYY-MM-DD"),
    };
  } else {
    const todayDateKey = dayjs().format("YYYY-MM-DD");
    return {timePeriod: "custom", from: todayDateKey, to: todayDateKey};
  }
}
