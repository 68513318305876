import {useAppSelector} from "@app/hooks";

import selectTemplateUiData from "../state/select-template-ui-data";
import {selectSelectedTemplate} from "../state/selectors";
import useStyles from "./RowsDebugTools.jss";

import type {TemplateRow} from "@shared/types/db";
import type {RowForDisplay} from "../state/select-template-ui-data";

export default function RowsDebugTools({resolveIds}: {resolveIds: boolean}) {
  const styles = useStyles();

  const template = useAppSelector(selectSelectedTemplate);
  const {rowsForDisplay} = useAppSelector((state) => selectTemplateUiData(state, template?.id ?? ""));

  return (
    <div className={styles.rowsDebugToolsMain}>
      <table>
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Slug</th>
            <th>Type</th>
            <th>Entity Type</th>
            <th>Depth</th>
            <th>Collapsed</th>
            <th>Expanded</th>
            <th>Empty</th>
          </tr>
        </thead>
        <tbody>
          {rowsForDisplay.map((row, i) => (
            <tr key={i}>
              <td>{row.cells[0]?.value}</td>
              <td>{row.row.name}</td>
              <td>{row.type}</td>
              <td>{getRowEntityType(row)}</td>
              <td>{row.depth}</td>
              <td>{row.collapsed && "Row collapsed"}</td>
              <td>{row.expanded && "Row expanded"}</td>
              <td>{row.empty && "Marked as empty"}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function getRowEntityType(row: RowForDisplay<TemplateRow>) {
  if (row.vendor) {
    return "Vendor";
  } else if (row.departmentId) {
    return "Department";
  } else if (row.sanityCheck) {
    return "Sanity Check";
  } else {
    return "Template Row";
  }
}
