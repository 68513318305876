import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  splashMain: {
    color: colors.text.white,
    fontWeight: 500,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: colors.ui.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    "& img": {
      width: 65,
      height: 65,
    },
  },
  loadingText: {
    fontSize: 18,
    marginTop: 15,
    paddingLeft: 4,
  },
  versionText: {
    marginTop: 15,
    paddingLeft: 4,
    fontSize: 16,
  },
});
