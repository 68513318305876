import type {ClientRootState} from "@app/client-store";
import type {Middleware} from "@reduxjs/toolkit";
import {log} from "@shared/lib/debug-provider";
import type {AppActionMeta} from "@state/store";
import {socket} from "./websockets";

export const websocketMiddleware: Middleware<{}> =
  (store) =>
  (next) =>
  ({type, payload, meta}) => {
    const newMeta: AppActionMeta = {...(meta ?? {})};
    if (!newMeta.initialDispatcher) newMeta.initialDispatcher = "user";

    const state: ClientRootState = store.getState();
    // Do not sync events if we're looking at a version, if it's not marked as syncable, if it has been received from a sync or if it has already been synced to the server
    const actionNeedsToBeSynced =
      !state.global.version &&
      !!newMeta.sync &&
      !newMeta.hasBeenSyncedToServer &&
      newMeta.initialDispatcher !== "websocket";

    if (actionNeedsToBeSynced) {
      log("WebsocketMiddleware", `Dispatching WebSocket action to server: "${type}"`, {
        type,
        payload,
        meta,
      });
      socket.emit("sync", {instanceId: state.global.instanceId, type, payload});
      newMeta.hasBeenSyncedToServer = true;
    }

    next({type, payload, meta: newMeta});
  };
