import {store} from "@app/client-store";
import {useAppSelector} from "@app/hooks";
import colors from "@app/styles/colors";
import Button from "@components/Button/V2";
import {
  logDependenciesTreeForKey,
  makeDependencyKeyHumanReadable,
} from "@shared/data-functions/cache/dependency-cache-debug-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";
import {useState} from "react";

import {selectors} from "../state/selectors";
import useStyles from "./CellDebugTools.jss";

export function CellDebugTools({
  resolveIds,
  context,
  cacheKey,
}: {
  resolveIds: boolean;
  cacheKey: string;
  context: "activeCell" | "cacheKeyInspector";
}) {
  const styles = useStyles();
  const [shownPanels, setShownPanels] = useState([] as string[]);
  const dependencyCache = useAppSelector((state) => state.global.dependencyCache[state.global.scenarioId ?? ""]);
  const monthlyCache = useAppSelector((state) => state.transactionItems.valuesByRowIdDateKey);

  const handleTogglePanel = (panel: string) => () => {
    if (shownPanels.includes(panel)) {
      setShownPanels(shownPanels.filter((p) => p !== panel));
    } else {
      setShownPanels([...shownPanels, panel]);
    }
  };

  const activeCell = useAppSelector(selectors.activeCell);

  let dependencyTree: string | null = null;
  const state = store.getState();
  const humanReadableKey = makeDependencyKeyHumanReadable(cacheKey, state, false);

  if (shownPanels.includes("dependencyTree")) {
    dependencyTree =
      logDependenciesTreeForKey(
        dependencyCache,
        humanReadableKey,
        state,
        false,
        state.transactionItems.valuesByRowIdDateKey,
        undefined,
        true,
      ) ?? "";
  }

  let dependents: string | null = null;
  if (shownPanels.includes("dependents")) {
    const dependentsHumanReadable = !resolveIds
      ? [...(dependencyCache.dependencyToCells[cacheKey] ?? [])]
      : [...(dependencyCache.dependencyToCells[cacheKey] ?? [])].map((item) =>
          makeDependencyKeyHumanReadable(item, state, false),
        );
    dependents = JSON.stringify(dependentsHumanReadable, null, 2);
  }

  return (
    <div>
      <div className={styles.debugPanelItem}>
        <strong>Cache key</strong>
        <pre>{resolveIds ? humanReadableKey : cacheKey}</pre>
      </div>
      {context === "activeCell" && activeCell && (
        <div className={styles.debugPanelItem}>
          <strong>Raw activeCell in state</strong>
          <pre>{JSON.stringify(activeCell, null, 2)}</pre>
        </div>
      )}
      <div className={styles.debugPanelItem}>
        <strong>Cache key value in state</strong>
        <pre>
          {typeof monthlyCache[cacheKey] !== "undefined" ? (
            <>
              {formatNumber(monthlyCache[cacheKey], false, 2)} &nbsp;&nbsp;&nbsp;&nbsp;
              {"("}
              {monthlyCache[cacheKey]}
              {")"}
            </>
          ) : (
            <span
              style={{
                color: colors.ui.lightBlue01,
                fontSize: 13,
                fontStyle: "italic",
              }}
            >
              undefined
            </span>
          )}
        </pre>
      </div>
      <div className={styles.debugPanelItem}>
        <div className={styles.debugPanelItemTitle}>
          <strong>Depency tree (what it depends on)</strong>
          <Button
            iconLeft={shownPanels.includes("dependencyTree") ? "minus" : "plus"}
            text={shownPanels.includes("dependencyTree") ? "Hide" : "Show"}
            onClick={handleTogglePanel("dependencyTree")}
            size="xsmall"
          />
        </div>
        {shownPanels.includes("dependencyTree") && <pre>{dependencyTree}</pre>}
      </div>
      <div className={styles.debugPanelItem}>
        <div className={styles.debugPanelItemTitle}>
          <strong>Dependents (cache keys directly dependending on it)</strong>
          <Button
            iconLeft={shownPanels.includes("dependents") ? "minus" : "plus"}
            text={shownPanels.includes("dependents") ? "Hide" : "Show"}
            onClick={handleTogglePanel("dependents")}
            size="xsmall"
          />
        </div>
        {shownPanels.includes("dependents") && <pre>{dependents}</pre>}
      </div>
    </div>
  );
}
