import {createUseStyles} from "react-jss";

export default createUseStyles({
  rowsDebugToolsMain: {
    "& > table": {
      borderCollapse: "collapse",
      width: "100%",

      "& strong": {
        fontWeight: 600,
      },

      "& th": {
        borderBottom: "1px solid #ddd",
        padding: 10,
        textAlign: "left",
      },

      "& td": {
        borderBottom: "1px solid #ddd",
        padding: 10,
      },

      "& tr:nth-child(even)": {
        background: "#f4f4f4",
      },
    },
  },
});
