import {addHeadsUpNotification, setActionConfirmation} from "@app/ui-state/slice";
import {resumeListener} from "@app/websockets/websockets";
import id from "@shared/lib/id";
import {api} from "@state/versions/slice";

import type {ClientWebsocketListener} from "@app/websockets/websockets";
import type {Version} from "@shared/types/db";

const handler: ClientWebsocketListener<"create-version-progress"> = (store) => (version: Version) => {
  if (version.status === "initializing") {
    store.dispatch(
      setActionConfirmation({
        cancelButton: false,
        confirmButton: false,
        backgroundImage: "/assets/images/ui/modals/notifs/blue-default.svg",
        mainColor: "blue",
        title: "Saving Version...",
        closeButton: false,
        text: "A new version is being saved. This can take up to 2-3 minutes. Model editing is locked during saving to ensure there are no conflicting changes in the model. <br /><br />You'll see a notification once the version has been saved and you can resume work.",
      }),
    );
  } else if (version.status === "ready") {
    store.dispatch<any>(api.fetchAll(null));
    store.dispatch<any>(
      addHeadsUpNotification({
        id: id(),
        text: `A version <b>${version.display_name}</b> has been created.`,
        preset: "success",
      }),
    );
    store.dispatch(setActionConfirmation(null));
    resumeListener("out-of-sync");
  }
};

export default handler;
