import {getRefStrsFromFormula} from "@shared/data-functions/formula/formula-utilities";
import {parseRefStr} from "@shared/data-functions/formula/pretty-print"; // @ts-ignore-next-line
import {getTokens} from "excel-formula";
import memoizeOne from "memoize-one";

export function getChunkAtCursor(chunks: FormulaChunkWithIndexes[], cursorPosition: number) {
  const chunkIndex = chunks.findIndex(
    (chunk) => cursorPosition >= chunk.startIndex && cursorPosition <= chunk.endIndex,
  );

  return {chunk: chunks[chunkIndex], index: chunkIndex};
}

// export function analyzeFormula(formula: string | null) {
export const analyzeFormula = memoizeOne((formula: string | null) => {
  if (formula === null) return [];
  // Surround refStrs with quotes to avoid parsing issues
  const refStrs = getRefStrsFromFormula(formula, true, true);
  let cleanedFormula = formula;

  let lastReplacedIndex = 0;
  for (const refStr of refStrs) {
    const startPos = cleanedFormula.indexOf(refStr, lastReplacedIndex);
    if (startPos === -1) {
      console.error(`refStr not found in formula: ${refStr}`);
      continue;
    }
    cleanedFormula = cleanedFormula.slice(0, startPos) + `"${refStr}"` + cleanedFormula.slice(startPos + refStr.length);
    lastReplacedIndex = startPos + refStr.length + 2;
  }

  // Temp fix: if the formula ends with space(s), the getTokens function will throw an error. So remove the spaces, call the tokens, and add them back after
  const spacesAtEnd = cleanedFormula.match(/\s+$/);
  if (spacesAtEnd) {
    cleanedFormula = cleanedFormula.slice(0, -spacesAtEnd[0].length);
  }

  // Tokenize the formula and add start and end indexes for each token
  let chunks: FormulaChunkWithIndexes[] = [];
  try {
    const tokens = getTokens(cleanedFormula) ?? [];

    // Temp fix for when a token is "[[" - it should be a single bracket, but the getTokens function adds another one
    for (const token of tokens) {
      if (token.value === "[[") {
        token.value = "[";
      }
    }

    if (spacesAtEnd) {
      cleanedFormula += spacesAtEnd[0];
    }

    chunks = addPositions(formula, tokens);

    // Merge back together words separated by spaces when they're the same type and subtype
    chunks = mergeBrokenUpChunks(chunks);
  } catch (e) {
    // console.error("Error parsing formula", e);
    chunks = [
      {
        value: formula,
        type: "extra",
        subtype: "invalid",
        startIndex: 0,
        endIndex: formula.length - 1,
      },
    ];
  }

  // console.log("Parsed chunks:", chunks);

  // Loop through the chunks and parse the refStrs, making sure they are actually refStrs and not just text
  let refStrIndex = 0;
  let refStrIdx = 1;
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];

    const isRefStr = chunk.type === "operand" && chunk.subtype === "text" && refStrIndex < refStrs.length;
    const isRefStart = chunk.type === "unknown" && chunk.value === "[";
    if (isRefStr) {
      const currentRefStr = refStrs[refStrIndex];
      if (chunk.value === currentRefStr) {
        // Parse the refStr

        const chunksToAdd = parseRefStr(currentRefStr, chunk.startIndex);
        refStrIdx++;

        // Replace the current chunk with the parsed chunks
        chunks.splice(i, 1, ...chunksToAdd);

        // Position cursor at the end of the parsed refStr
        i = i + chunksToAdd.length - 1;

        // Move to the next refStr
        refStrIndex++;
      }
    } else if (isRefStart) {
      chunk.type = "cb_ref";
      chunk.subtype = "start";
    }
  }

  return chunks;
});

/**
 * Loops through the chunks and tries to find two chunks of the same type separated by a chunk of type extra and subtype extra with a value that's only space(s).
 * If found, merges the three chunks into one.
 */
function mergeBrokenUpChunks(chunks: FormulaChunkWithIndexes[]): FormulaChunkWithIndexes[] {
  // Result array to store merged chunks
  const mergedChunks: FormulaChunkWithIndexes[] = [];

  // Loop through the chunks
  for (let i = 0; i < chunks.length; i++) {
    // Check if the current chunk and the next two chunks exist
    if (i < chunks.length - 2) {
      const currentChunk = chunks[i];
      const nextChunk = chunks[i + 1];
      const nextNextChunk = chunks[i + 2];

      // Check if the chunks match the criteria
      if (
        currentChunk.type === nextNextChunk.type &&
        nextChunk.type === "extra" &&
        nextChunk.subtype === "extra" &&
        /^\s*$/.test(nextChunk.value)
      ) {
        // Merge the chunks
        const mergedChunk: FormulaChunkWithIndexes = {
          value: currentChunk.value + nextChunk.value + nextNextChunk.value,
          type: currentChunk.type,
          subtype: currentChunk.subtype,
          startIndex: currentChunk.startIndex,
          endIndex: nextNextChunk.endIndex,
        };

        // Add the merged chunk to the result array
        mergedChunks.push(mergedChunk);

        // Skip the next two chunks
        i += 2;
        continue;
      }
    }

    // If no merge, add the current chunk to the result array
    mergedChunks.push(chunks[i]);
  }

  return mergedChunks;
}

export interface FormulaChunk {
  value: string;
  type: string;
  subtype: string;
}

export interface FormulaChunkWithIndexes extends FormulaChunk {
  startIndex: number;
  endIndex: number;
}

function addPositions(str: string, chunks: FormulaChunk[]): FormulaChunkWithIndexes[] {
  let result: FormulaChunkWithIndexes[] = [];
  let currentIndex = 0;

  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];
    const chunkIndex = str.indexOf(chunk.value, currentIndex);

    if (chunkIndex === -1) {
      // console.log({chunk, currentIndex, str});
      throw new Error(`Value "${chunk.value}" not found in string at expected position.`);
    }

    // Check if there are extra characters between the current and previous chunk
    if (chunkIndex > currentIndex) {
      let extraValue = str.slice(currentIndex, chunkIndex);

      const prevChunk = result.at(-1);

      // Handle function start and end better than the getTokens function does
      if (extraValue.startsWith("(") && result.length > 0 && prevChunk?.type === "function") {
        prevChunk.subtype = "name";

        // Extract the parenthesis from the extra value and create a new chunk for it
        result.push({
          value: extraValue.slice(0, 1),
          type: "function",
          subtype: "start",
          startIndex: currentIndex,
          endIndex: currentIndex,
        });

        extraValue = extraValue.slice(1);
        currentIndex++;
      } else if (
        // If the previous chunk was a function stop and the extra value starts with a parenthesis
        prevChunk?.type === "function" &&
        prevChunk.subtype === "stop" &&
        extraValue.startsWith(")") &&
        prevChunk.value === ""
      ) {
        prevChunk.value = ")";
        prevChunk.startIndex = currentIndex;
        prevChunk.endIndex = currentIndex;

        extraValue = extraValue.slice(1);
        currentIndex++;
      }

      if (extraValue.length) {
        result.push({
          value: extraValue,
          type: "extra",
          subtype: "extra",
          startIndex: currentIndex,
          endIndex: currentIndex + extraValue.length - 1,
        });
      }
    }

    if (chunk.value.length === 0) {
      // get the value at the current index
      const nextChar = str[chunkIndex];
    }

    result.push({
      ...chunk,
      startIndex: chunkIndex,
      endIndex: chunkIndex + chunk.value.length - 1,
    });

    currentIndex = chunkIndex + chunk.value.length;
  }

  // Add extra characters after the last occurrence
  if (currentIndex < str.length) {
    let extraValue = str.slice(currentIndex);
    const prevChunk = result.at(-1);

    // If the extra value starts with a closing parenthesis, add it to the previous function stop chunk
    if (
      prevChunk?.type === "function" &&
      prevChunk.subtype === "stop" &&
      extraValue.startsWith(")") &&
      prevChunk.value === ""
    ) {
      prevChunk.value = ")";
      prevChunk.startIndex = currentIndex;
      prevChunk.endIndex = currentIndex;
      extraValue = extraValue.slice(1);
      currentIndex++;
    }

    // If there's still extra value left, add it as an extra chunk
    if (extraValue.length > 0) {
      result.push({
        value: str.slice(currentIndex),
        type: "extra",
        subtype: "extra",
        startIndex: currentIndex,
        endIndex: str.length,
      });
    }
  }

  return result;
}
