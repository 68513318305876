import {createSelector} from "@reduxjs/toolkit";
import {getCachedValueForMonth} from "@shared/data-functions/transactions";
import {monthlyCacheProjKey} from "@shared/state/utils";

import type {RootState} from "@shared/state/store";

export const selectValuesByRowIdDateKey = (state: RootState) => state.transactionItems.valuesByRowIdDateKey;

export const makeSelectCachedValueForMonth = () => {
  const selectCachedValueForMonth = createSelector(
    selectValuesByRowIdDateKey,
    (
      _state: RootState,
      rowId: string,
      scenarioId: string | undefined | null,
      dateKey: string,
      isTotal: boolean = false,
      isBalance: boolean = false,
    ) => ({rowId, scenarioId, dateKey, isTotal, isBalance}),
    (monthlyCache, params) =>
      !params.scenarioId
        ? null
        : getCachedValueForMonth(
            monthlyCache,
            params.rowId,
            params.scenarioId,
            params.dateKey,
            params.isTotal,
            params.isBalance,
          ),
  );
  return selectCachedValueForMonth;
};

export const selectIsRowEmpty =
  (rowId: string, scenarioId: string, dateKeys: string[], isTotal: boolean = false, isBalance: boolean = false) =>
  (state: RootState) => {
    for (const dateKey of dateKeys) {
      if (
        typeof state.transactionItems.valuesByRowIdDateKey[
          monthlyCacheProjKey(rowId, scenarioId, dateKey, isBalance ? "balance" : null, isTotal ? "total" : null)
        ] !== "undefined"
      )
        return false;
    }
    return true;
  };

export const selectRowTotal =
  (
    rowId: string,
    scenarioId: string,
    dateKeys: string[],
    isTotal: boolean = false,
    skip: boolean = false,
    isBalance: boolean = false,
  ) =>
  (state: RootState) => {
    if (skip) return null;
    let total = 0;
    for (const dateKey of dateKeys) {
      total +=
        state.transactionItems.valuesByRowIdDateKey[
          monthlyCacheProjKey(rowId, scenarioId, dateKey, isBalance ? "balance" : null, isTotal ? "total" : null)
        ] || 0;
    }
    return total;
  };
