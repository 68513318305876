import {createEntityAdapter} from "@reduxjs/toolkit";
import {noClassIdentifier} from "@shared/data-functions/cache/cache-utilities";
import {getReferencedEntitiesAndTags} from "@shared/lib/datasource-utilities";
import {addCacheProjectionsToEntityAdapter} from "@shared/lib/entity-adapter-with-cache-projections";
import {getMergedTags} from "@shared/lib/row-utilities";
import {isDsError, isSanityCheckAlert, type AppAlert} from "@shared/types/alerts";

import {projKey, projKeyOmitNulls} from "./utils";

import type {Datasource} from "@shared/types/datasources";
import type {
  CbTx,
  Company,
  Department,
  Integration,
  SanityCheck,
  Scenario,
  Template,
  TemplateRow,
  Version,
} from "@shared/types/db";
import type {Employee, FinancialComponent, Team} from "@shared/types/hiring-plan";
import type {Report, ReportItem} from "@shared/types/reporting";

export const getAlertsAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<AppAlert>(), [
    {keyToProject: "type", type: "one-to-many"},
    {
      keyToProject: (alert) => (isDsError(alert) ? alert.datasource_id : "null"),
      name: "idsByDatasourceId",
      type: "one-to-many",
    },
    {
      keyToProject: (alert) => (isSanityCheckAlert(alert) ? alert.sanity_check_id : "null"),
      name: "idsBySanityCheckId",
      type: "one-to-many",
    },
  ]);
export const getEmployeesAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<Employee>(), [
    {keyToProject: (employee) => employee.team_id || "null", name: "idsByTeamId", type: "one-to-many"},
    {keyToProject: "slug", type: "one-to-one"},
  ]);
export const getFinancialComponentsAdapter = () =>
  addCacheProjectionsToEntityAdapter(
    createEntityAdapter<FinancialComponent>({
      selectId: (item) => item.name,
    }),
    [{keyToProject: "type", type: "one-to-many"}],
  );
export const getScenariosAdapter = () => createEntityAdapter<Scenario>();

export const getDepartmentsAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<Department>(), [
    {keyToProject: "parent", type: "one-to-many"},
    {keyToProject: "name", type: "one-to-one"},
    {
      keyToProject: (department) => {
        const classes: string[] = [];
        for (const filter of department.filters) {
          if (filter.property === "tags" && filter.key === "qbo_class") {
            classes.push(filter.value ?? noClassIdentifier);
          }
        }
        return classes;
      },
      name: "idByQboClass",
      type: "one-to-one",
    },
  ]);
export const getSanityChecksAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<SanityCheck>(), [
    {keyToProject: "check_entity_identifier", type: "one-to-many"},
  ]);
export const getVersionsAdapter = () => createEntityAdapter<Version>();
export const getCompaniesAdapter = () => createEntityAdapter<Company>();
export const getIntegrationsAdapter = () => createEntityAdapter<Integration>();
export const getTeamsAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<Team>(), [
    //{keyToProject: "parent_id", type: "one-to-many"},
  ]);
export const getDatasourcesAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<Datasource>(), [
    {keyToProject: "integration_id", type: "one-to-many"},
    {keyToProject: "department_id", type: "one-to-many"},
    {keyToProject: "row_id", type: "one-to-many"},
    {
      keyToProject: (datasource: Datasource) =>
        projKeyOmitNulls(
          datasource.row_id,
          datasource.scenario_id,
          datasource.department_id,
          datasource.dimensions?.vendor?.toLowerCase(),
        ),
      type: "one-to-many",
      name: "idsByProjKey",
    },
    {
      keyToProject: (datasource) => getReferencedEntitiesAndTags(datasource),
      name: "idsByReferencedEntityOrTag",
      type: "one-to-many",
    },
    {
      keyToProject: (datasource: Datasource) =>
        datasource.type === "hiring-plan-formula" &&
        datasource.options.employee_id &&
        datasource.options.fc_name &&
        datasource.scenario_id
          ? [
              datasource.options.employee_id ?? "undefined",
              datasource.options.fc_name ?? "undefined",
              datasource.scenario_id ?? "undefined",
            ].join("::")
          : null,
      type: "one-to-many",
      name: "idsByEmployeeIdFcNameScenarioId",
      ignoreNulls: true,
    },
  ]);
export const getTemplateRowsAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<TemplateRow>(), [
    {
      keyToProject: (templateRow) =>
        templateRow.type === "hiring-plan" ? templateRow.options.employee_id || "undefined" : "null",
      name: "idsByEmployeeId",
      type: "one-to-many",
    },
    {
      keyToProject: (templateRow) => getMergedTags(templateRow),
      name: "idsByTag",
      type: "one-to-many",
    },
    {keyToProject: "parent_row_id", type: "one-to-many"},
    {keyToProject: "template_id", type: "one-to-many"},
    {keyToProject: "mirror_of", type: "one-to-many"},
    {keyToProject: "name", type: "one-to-one"},
  ]);
export const getReportsAdapter = () => createEntityAdapter<Report>();
export const getReportItemsAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<ReportItem>(), [
    {keyToProject: "report_id", type: "one-to-many"},
  ]);
export const getTemplatesAdapter = () => createEntityAdapter<Template>();

export const getCbTxStateAdapter = () =>
  addCacheProjectionsToEntityAdapter(createEntityAdapter<CbTx>(), [
    {
      keyToProject: (tx: CbTx) => projKey(tx.row_id, tx.scenario_id),
      type: "one-to-many",
      name: "idsByRowId",
    },
    {
      keyToProject: (tx: CbTx) => projKey(tx.row_id, tx.scenario_id, tx.date.slice(0, 7)),
      type: "one-to-many",
      name: "idsByRowIdDateKey",
    },
    {
      keyToProject: (tx: CbTx) => tx.source_tx_id,
      type: "one-to-many",
      name: "idsBySourceTxId",
    },
    {
      keyToProject: (tx: CbTx) => tx.tx_id,
      type: "one-to-many",
      name: "idsByTxId",
    },
    {
      keyToProject: (tx: CbTx) => tx.ds_id,
      type: "one-to-many",
      name: "idsByDsId",
    },
    {
      keyToProject: (tx: CbTx) => projKey(tx.ds_id, tx.date.slice(0, 7)),
      type: "one-to-many",
      name: "idsByDsIdDateKey",
    },
  ]);

export type EmployeesState = ReturnType<ReturnType<typeof getEmployeesAdapter>["getInitialState"]>;
export type FinancialComponentsState = ReturnType<ReturnType<typeof getFinancialComponentsAdapter>["getInitialState"]>;
export type ScenariosState = ReturnType<ReturnType<typeof getScenariosAdapter>["getInitialState"]>;
export type DepartmentsState = ReturnType<ReturnType<typeof getDepartmentsAdapter>["getInitialState"]>;
export type SanityChecksState = ReturnType<ReturnType<typeof getSanityChecksAdapter>["getInitialState"]>;
export type DatasourcesState = ReturnType<ReturnType<typeof getDatasourcesAdapter>["getInitialState"]>;
export type VersionsState = ReturnType<ReturnType<typeof getVersionsAdapter>["getInitialState"]>;
export type IntegrationssState = ReturnType<ReturnType<typeof getIntegrationsAdapter>["getInitialState"]>;
export type TeamsState = ReturnType<ReturnType<typeof getTeamsAdapter>["getInitialState"]>;
export type TemplateRowsState = ReturnType<ReturnType<typeof getTemplateRowsAdapter>["getInitialState"]> & {
  rowIdToVendorsMapping: Record<string, string[]>;
};
export type TemplatesState = ReturnType<ReturnType<typeof getTemplatesAdapter>["getInitialState"]>;
export type ReportsState = ReturnType<ReturnType<typeof getReportsAdapter>["getInitialState"]>;
export type ReportItemsState = ReturnType<ReturnType<typeof getReportItemsAdapter>["getInitialState"]>;
export type CbTxState = ReturnType<ReturnType<typeof getCbTxStateAdapter>["getInitialState"]>;
export type CompaniesState = ReturnType<ReturnType<typeof getCompaniesAdapter>["getInitialState"]>;
export type AlertsState = ReturnType<ReturnType<typeof getAlertsAdapter>["getInitialState"]>;
