import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  fallback: {
    color: colors.text.lightBlue01,
    fontSize: 16,
    marginLeft: 30,
    marginTop: 30,
    fontWeight: 500,
  },
});
