import colors from "./colors";

import type {JssStyle} from "jss";

export const globalStyles = {
  mainViewPaddingX: 25,
  leftNavWidth: 250,
  topNavHeight: 50,

  text: {
    fontFamily: "Mont",
    color: colors.ui.black,
    fontWeight: 600,
  },
};

export const labelStyles: JssStyle = {
  color: colors.text.darkBlue02,
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: 24,
  textAlign: "left",
} as const;

export const containerShadowStyles: JssStyle = {
  boxShadow: "0 6px 52px rgba(6, 10, 43, 0.21)",
} as const;

export const minimalShadowStyles: JssStyle = {
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.14)",
} as const;

export const sharedButtonStyles: JssStyle = {
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 5,
  height: 35,
  maxHeight: 35,
  padding: [9, 12, 6, 12],
  lineHeight: 25,
  fontSize: 12,
  fontWeight: 500,
  display: "inline-flex",
  alignItems: "center",
};

export const sharedButtonInputStyles = {
  border: [1, "solid", colors.ui.lightBlue05],
  borderRadius: 5,
  // boxShadow: getBorderAsBoxShadow("all", 1, colors.ui.lightBlue05),
  height: 40,
  maxHeight: 40,
  display: "inline-flex",
  alignItems: "center",
  color: colors.ui.darkBlue02,
} as const;

type Sizes = "sm" | "md" | "lg";

export type GetBaseInputStylesOptions = {
  size?: Sizes;
};

type GetBaseInputStyles = (options?: GetBaseInputStylesOptions) => JssStyle;
export const baseInputPadding = [0, 15];
export const getBaseInputStyles: GetBaseInputStyles = (options) => {
  const {} = options || {};
  const styles = {
    ...sharedButtonInputStyles,
    fontFamily: "Mont",
    padding: baseInputPadding,
    fontSize: 13,
    lineHeight: 25,
    fontWeight: 500,

    "&::placeholder": {
      fontFamily: "Mont",
    },

    "&:focus": {
      border: [1, "solid", colors.primary.gold],
      // boxShadow: getBorderAsBoxShadow("all", 1, colors.primary.gold),
      outline: "none",
      //outline: colors.primary.orange,
    },

    "&:not($disabled):focus-within": {
      color: [1, "solid", colors.primary.gold],
      // boxShadow: getBorderAsBoxShadow("all", 1, colors.primary.gold),
      //border: [1, "solid", colors.primary.orange],
    },
  } as JssStyle;
  if (options?.size === "sm") {
    styles.height = 36;
    styles.maxHeight = 36;
  } else if (options?.size === "lg") {
    styles.height = 44;
    styles.maxHeight = 44;
  }
  return styles;
};

export const baseInputStyles = getBaseInputStyles();

export const defaultMotionFadeIn = getMotionFadeIn(0.1);

export function getMotionFadeIn(duration: number = 0.1) {
  return {
    initial: {
      opacity: 0,
      scale: 0.9,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: {
        duration,
      },
    },
  };
}
