import {store} from "@app/client-store";
import {useAppDispatch, useAppSelector} from "@app/hooks";
import {addDeltaMonthsToDateKey} from "@shared/lib/date-utilities";
import {useCallback, useEffect, useRef} from "react";
import {useMouse} from "react-use";

import useStyles from "./Row.jss";
import {selectSelectedRow, selectors} from "./state/selectors";
import {updateTemplatesViewState} from "./state/slice";
import {applyFormulaToCells} from "./state/thunks";

import {isCellDisabledClient} from "@/lib/row-utilities-client";

// @ts-ignore
const isFirefox = typeof InstallTrigger !== "undefined";
export const DragStartSensor = () => {
  const draggingFrom = useAppSelector(selectors.draggingFrom);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleMouseUp = () => {
      dispatch(updateTemplatesViewState({draggingFrom: null}));
    };

    if (draggingFrom !== null) {
      // If draggingFrom is not null, set a global event listener for a mouseup event
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      // Remove the listener if it's null
      window.removeEventListener("mouseup", handleMouseUp);
      dispatch<any>(applyFormulaToCells("dragged"));
    }

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggingFrom, dispatch]); // Only re-run the effect if draggingFrom changes

  return draggingFrom ? <MoveSensor /> : null;
};

function MoveSensor() {
  const styles = useStyles();

  const initialPosition = useRef<number | null>(null);
  const ref = useRef(document.body);
  const dispatch = useAppDispatch();

  const {docX} = useMouse(ref);

  const handleDragOverCells = useCallback(
    (delta: number) => {
      const state = store.getState();
      const {cellWidth, draggingOverCols, draggingFrom} = state.templatesView;
      const row = selectSelectedRow(state);
      if (!cellWidth || !draggingFrom) return;

      const roundedNbCells = Math.ceil(delta / cellWidth);

      const nbSelectedCells = draggingOverCols?.length ?? 0;

      if (roundedNbCells !== nbSelectedCells) {
        const cellIsEditable = !isCellDisabledClient(row, draggingFrom);
        const newDraggingOverCols = [];
        // if (cellIsEditable) newDraggingOverCols.push(draggingFrom);

        for (let i = 1; i <= Math.abs(roundedNbCells); i++) {
          const currentOffset = roundedNbCells < 0 ? -i : i;
          const currentDateKey = addDeltaMonthsToDateKey(draggingFrom, currentOffset);
          const cellIsEditable = !isCellDisabledClient(row, currentDateKey);
          if (cellIsEditable) newDraggingOverCols.push(currentDateKey);
        }
        const sortedNewDateKeys = [...newDraggingOverCols.sort((a, b) => a.localeCompare(b))];
        dispatch(
          updateTemplatesViewState({
            draggingOverCols: sortedNewDateKeys,
            dragDirection: sortedNewDateKeys[0] > draggingFrom ? "right" : "left",
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!initialPosition.current) {
      initialPosition.current = docX;
    } else {
      handleDragOverCells(docX - initialPosition.current);
    }
  }, [initialPosition, docX, ref, handleDragOverCells]);

  return <div className={styles.mouseMoveListener} />;
}
