import api from "@shared/lib/fetch";

import type {UtilityScriptNames} from "@shared/lib/utility-scripts";
import type {ApiEndpoints} from "@shared/state/store";
import type {User, UserInvite} from "@shared/types/db";

type UtilityResolvers =
  | "regenerate-team-slugs"
  | "extend-ranges-until-end"
  | "remove-missing-rows"
  | "invalidate-cache"
  | "refresh-materialized-view"
  | "regenerate-formula-references"
  | "recalc";

export const endpoints = {
  runUtility(
    {utility, dryRun, allSchemas}: {utility: UtilityScriptNames; dryRun: boolean; allSchemas: boolean},
    thunkAPI,
  ) {
    return api.get<string>(thunkAPI, `utilities/run?utility=${utility}&dryRun=${dryRun === true}`);
  },
  fetchAllUsers(_: any, thunkAPI) {
    return api.get<User[]>(thunkAPI, `admin/users`);
  },
  fetchAllInvites(_: any, thunkAPI) {
    return api.get<UserInvite[]>(thunkAPI, `admin/invites`);
  },
  deleteUser({userId}: {userId: string}, thunkAPI) {
    return api.delete(thunkAPI, `admin/users/${userId}`);
  },
  createUser: (user: User, thunkAPI) => api.post<User>(thunkAPI, `admin/users`, user),
} satisfies ApiEndpoints;

export default endpoints;
