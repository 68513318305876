import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getDepartmentsAdapter} from "@shared/state/entity-adapters";

import departmentsAPI from "./api";

import type {RootState} from "@state/store";

export const api = {
  fetchDepartments: createAsyncThunk("departments/fetchAll", departmentsAPI.fetchAll),
  upsertDepartments: createAsyncThunk("departments/upsert", departmentsAPI.upsert),
  deleteDepartments: createAsyncThunk("departments/remove", departmentsAPI.remove),
};

export const getSlice = () => {
  const departmentsAdapter = getDepartmentsAdapter();
  return createSlice({
    name: "departments",
    initialState: departmentsAdapter.getInitialState(),
    reducers: {
      upsertDepartments: departmentsAdapter.upsertMany,
      upsertDepartmentsLocal: departmentsAdapter.upsertMany,
      deleteDepartments: departmentsAdapter.removeMany,
    },
    extraReducers: (builder) => {
      builder.addCase(api.fetchDepartments.fulfilled, departmentsAdapter.setAll);
    },
  });
};

const _slice = getSlice();
export const {upsertDepartmentsLocal} = _slice.actions;
export const {upsertDepartments, deleteDepartments} = createSyncedActionCreators(_slice.actions);
export const {
  selectById: selectDepartmentById,
  selectIds: selectDepartmentIds,
  selectEntities: selectDepartmentEntities,
  selectAll: selectAllDepartments,
  selectTotal: selectTotalDepartments,
} = getDepartmentsAdapter().getSelectors((state: RootState) => state.departments);
