export default {
  this_month: {rangeType: "static", range: "0"},
  last_month: {rangeType: "static", range: "-1"},
  last_closed_month: {rangeType: "dynamic", range: "-1a"},
  next_month: {rangeType: "static", range: "1"},
  one_month_ago: {rangeType: "static", range: "-1"},
  two_months_ago: {rangeType: "static", range: "-2"},
  three_months_ago: {rangeType: "static", range: "-3"},
  four_months_ago: {rangeType: "static", range: "-4"},
  five_months_ago: {rangeType: "static", range: "-5"},
  six_months_ago: {rangeType: "static", range: "-6"},
  seven_months_ago: {rangeType: "static", range: "-7"},
  eight_months_ago: {rangeType: "static", range: "-8"},
  nine_months_ago: {rangeType: "static", range: "-9"},
  ten_months_ago: {rangeType: "static", range: "-10"},
  eleven_months_ago: {rangeType: "static", range: "-11"},
  twelve_months_ago: {rangeType: "static", range: "-12"},
  one_month_ahead: {rangeType: "static", range: "1"},
  two_months_ahead: {rangeType: "static", range: "2"},
  three_months_ahead: {rangeType: "static", range: "3"},
  four_months_ahead: {rangeType: "static", range: "4"},
  five_months_ahead: {rangeType: "static", range: "5"},
  six_months_ahead: {rangeType: "static", range: "6"},
  seven_months_ahead: {rangeType: "static", range: "7"},
  eight_months_ahead: {rangeType: "static", range: "8"},
  nine_months_ahead: {rangeType: "static", range: "9"},
  ten_months_ahead: {rangeType: "static", range: "10"},
  eleven_months_ahead: {rangeType: "static", range: "11"},
  twelve_months_ahead: {rangeType: "static", range: "12"},
  last_three_months: {rangeType: "static", range: "-3:-1"},
} as {[key: string]: {rangeType: "static" | "dynamic"; range: string}};
