import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./Tooltip.jss";

import type {IconNames} from "@components/SVGIcon";

export type FormulaSuggestion = {
  displayName: string;
  slug: string;
  rightText?: string;
  subtext?: string;
  type: "row" | "department" | "vendor" | "formulaFunction" | "range";
};

const entityTypeToIconMapping: Record<string, IconNames> = {
  row: "addRow",
  department: "department",
  vendor: "vendor",
} as const;

type TooltipSuggestionsProps = {
  suggestions: FormulaSuggestion[];
  getItemProps: (suggestion: FormulaSuggestion, i: number) => Record<string, unknown>;
  activeTooltipIndex: number | null;
};

export default function TooltipSuggestions({suggestions, getItemProps, activeTooltipIndex}: TooltipSuggestionsProps) {
  const styles = useStyles();
  let lastSuggestion: FormulaSuggestion | null = null;
  return (
    <>
      {suggestions.length > 0 && (
        <ul className={styles.suggestionsContainer}>
          {suggestions.map((suggestion, i) => {
            const firstSuggestionOfType = lastSuggestion && lastSuggestion.type !== suggestion.type;
            const lastSuggestionOfType = suggestions[i + 1] && suggestions[i + 1].type !== suggestion.type;
            lastSuggestion = suggestion;
            return (
              <li
                key={suggestion.slug}
                className={clsx(styles.tooltipItem, styles.suggestion, {
                  [styles.highlighted]: i === activeTooltipIndex,
                  [styles.formulaFunction]: suggestion.type === "formulaFunction",
                  [styles.hasSubtext]: Boolean(suggestion.subtext),
                  [styles.borderTop]: firstSuggestionOfType,
                  [styles.lastSuggestionOfType]: lastSuggestionOfType,
                })}
                {...getItemProps(suggestion, i)}
              >
                <div className={styles.textAndIconContainer}>
                  {entityTypeToIconMapping[suggestion.type] ? (
                    <SVGIcon name={entityTypeToIconMapping[suggestion.type]} />
                  ) : null}
                  <div>
                    <div className={styles.name}>{suggestion.displayName}</div>
                    {i === activeTooltipIndex ? <div className={styles.subtext}>{suggestion.subtext}</div> : null}
                  </div>
                </div>

                {suggestion.rightText && <div className={styles.rightText}>{suggestion.rightText}</div>}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
