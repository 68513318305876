import {useAppSelector} from "@app/hooks";
import {selectSidebarOpen} from "@app/ui-state/slice";
import clsx from "clsx";
import * as React from "react";
import {Suspense, useState} from "react";

import SidebarHeader from "./Header";
import useStyles from "./styles.jss";

import type {HTMLMotionProps} from "framer-motion";

const AnimatePresence = React.lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));
const MotionDiv = React.lazy(async () => ({default: (await import("framer-motion")).motion.div}));

export const sidebarAnimationDuration = 0.4;

export default function RightSidebar({
  animate = false,
  title,
  onClose,
  children,
  scroll = true,
  isOpen = true,
  topRightButtons,
  expandable = false,
  expandToFullWidth = false,
  onExpandChange,
  titleTags,
  ...motionProps
}: {
  animate?: boolean;
  title: string | null;
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  topRightButtons?: React.ReactNode;
  titleTags?: React.ReactNode;
  scroll?: boolean;
  expandable?: boolean;
  expandToFullWidth?: boolean;
  onExpandChange?: (expanded: boolean) => void;
} & HTMLMotionProps<"div">) {
  const [expanded, setExpanded] = useState(false);
  const leftSidebarOpen = useAppSelector(selectSidebarOpen);
  const styles = useStyles();

  const classes = clsx(styles.sidebarMain, {
    [styles.expanded]: expanded,
    [styles.fullWidth]: expanded && expandToFullWidth,
    [styles.leftSidebarOpen]: leftSidebarOpen,
  });

  const resolvedMotionProps: HTMLMotionProps<"div"> = {
    ...motionProps,
    initial: {x: "100%"},
    animate: {x: 0},
    exit: {x: "100%"},
    transition: {type: "spring", bounce: 0.25, duration: sidebarAnimationDuration},
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
    onExpandChange?.(!expanded);
  };

  return (
    <Suspense>
      <AnimatePresence>
        {isOpen ? (
          <MotionDiv className={classes} {...resolvedMotionProps}>
            <SidebarHeader
              onClose={onClose}
              title={title || ""}
              topRightButtons={topRightButtons}
              onExpandClick={handleExpandToggle}
              expanded={expanded}
              expandable={expandable}
              titleTags={titleTags}
            />
            <div className={styles.sidebarBody}>{children}</div>
          </MotionDiv>
        ) : null}
      </AnimatePresence>
    </Suspense>
  );
}

export function SidebarBody({children}: {children: React.ReactNode}) {
  const styles = useStyles();

  return <div className={styles.sidebarBodyMain}>{children}</div>;
}
