import {createEffectHandler} from "../listeners-list";
import {
  disconnectIntegration,
  disconnectIntegrationApiCall,
  updateIntegrationQuery,
  upsertIntegration,
  upsertIntegrationApiCall,
} from "./slice";

import type {Integration} from "@shared/types/db";

export const syncIntegrationUpsertsWithAPI = createEffectHandler(
  [upsertIntegration, updateIntegrationQuery],
  (action, {getState, dispatch}) => {
    let integration: Integration | null = null;
    if (updateIntegrationQuery.match(action)) {
      integration = getState().integrations.entities[action.payload.id] || null;
    } else if (upsertIntegration.match(action)) {
      integration = action.payload;
    }
    if (!integration) return;
    dispatch(upsertIntegrationApiCall(integration));
  },
);

export const syncIntegrationDisconnectWithAPI = createEffectHandler(
  disconnectIntegration,
  (action, {getState, dispatch}) => {
    dispatch(disconnectIntegrationApiCall(action.payload));
  },
);
