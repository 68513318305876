import Tabs from "@components/Tabs";
import {useState} from "react";

import CacheDebugTools from "./CacheDebugTools";
import AdminChecks from "./Checks";
import Companies from "./Companies";
import Stats from "./Stats";
import TemplateSettings from "./TemplateSettings";
import Utilities from "./Utilities";
import useStyles from "./styles.jss";

import type {Tab} from "@components/Tabs";

const TabsList: Tab[] = [
  {name: "checks", value: "Checks"},
  {name: "utilities", value: "Utility scripts"},
  {name: "companies", value: "Companies"},
  {name: "stats", value: "Stats"},
  {name: "template-settings", value: "Template Settings"},
  // {name: "cache-debug-tools", value: "Cache Debug Tools"},
];

export default function AdminTabs() {
  const [selectedTab, setSelectedTab] = useState(TabsList[0]);

  const styles = useStyles();

  const handleTabChange = (tab: Tab) => {
    setSelectedTab(tab);
  };

  let tabComponent: JSX.Element | null = null;
  switch (selectedTab.name) {
    case "companies": {
      tabComponent = <Companies />;
      break;
    }
    case "utilities": {
      tabComponent = <Utilities />;
      break;
    }

    case "checks": {
      tabComponent = <AdminChecks />;
      break;
    }

    case "stats": {
      tabComponent = <Stats />;
      break;
    }

    case "template-settings": {
      tabComponent = <TemplateSettings />;
      break;
    }

    case "cache-debug-tools": {
      tabComponent = <CacheDebugTools />;
      break;
    }
  }

  return (
    <>
      <Tabs tabs={TabsList} selectedTab={selectedTab} onChange={handleTabChange} />
      <div className={styles.content}>{tabComponent}</div>
    </>
  );
}
