import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  searchBarMain: {
    position: "relative",
    paddingLeft: 46,
    marginLeft: 25,
    display: "flex",
    flex: 1,
    alignItems: "center",
    height: 40,
    borderRadius: 20,
    border: [1, "solid", colors.ui.lightBlue03],

    "&:hover": {
      background: colors.ui.lightBlue08,
    },

    "&:focus-within": {
      borderColor: colors.primary.gold,
      ...getSvgColorStyles(colors.primary.gold),

      "&:hover": {
        background: colors.ui.white,
      },
    },

    ...getSvgColorStyles(colors.ui.lightBlue02),

    "& > input": {
      fontWeight: 500,
      border: 0,
      fontSize: 13,
      fontFamily: "Mont",
      background: "transparent",
      color: colors.text.darkBlue02,
      height: "100%",
      marginLeft: -47,
      paddingLeft: 47,
      flex: 1,

      "&:focus": {
        outline: "none",
      },

      "&::placeholder": {
        color: colors.text.lightBlue02,
        fontFamily: "Mont",
      },
    },
  },
  searchIconWrapper: {
    position: "absolute",
    left: 15.5,
    cursor: "text",
  },
  small: {
    height: 30,
  },
  normal: {},
  noLeftMargin: {marginLeft: 0},
  clearIcon: {
    position: "absolute",
    right: 5,
  },
});
