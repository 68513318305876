import colors from "@app/styles/colors";
import {getMotionFadeIn} from "@app/styles/general-ui";
import {capitalizeFirstLetter} from "@shared/lib/misc";
import clsx from "clsx";
import * as React from "react";
import {cloneElement, forwardRef, Suspense, useContext, useRef, useState} from "react";
import {ArrowContainer, Popover as TinyPopover} from "react-tiny-popover";

import useStyles from "./styles.jss";

import type {PopoverWrapperComponentProps, PopoverProps as TinyPopoverProps} from "react-tiny-popover";

import AppContext from "@/AppContext";

const AnimatePresence = React.lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));
const MotionDiv = React.lazy(async () => ({default: (await import("framer-motion")).motion.div}));

const inversePositionsMapping: Record<Positions, Positions> = {
  top: "bottom",
  bottom: "top",
  left: "right",
  right: "left",
} as const;

export type AlignOptions = "left" | "center" | "right";

export type Positions = "top" | "right" | "bottom" | "left";

export type PopoverGenericProps = {
  align?: TinyPopoverProps["align"];
  boundaryRef?: TinyPopoverProps["boundaryElement"];
  children: JSX.Element;
  disableSelection?: boolean;
  isOpen?: boolean;
  minimal?: boolean;
  onClose?: () => void;
  openTriggerClassName?: string;
  openOnClick?: boolean;
  openOnRender?: boolean;
  padding?: number;
  position?: Positions | Positions[];
  renderAsChild?: boolean;
  rootClass?: string;
  width?: number | "matchTrigger";
  wrapperClass?: string;
  renderAsChildOf?: HTMLElement;
};

export type PopoverProps = PopoverGenericProps & {
  arrow?: boolean;
  closeOnClick?: boolean;
  content?: React.ReactElement | null;
};

export type PopoverContentProps = {
  onClose?: () => void;
};

const WrapperComponent: React.FC<PopoverWrapperComponentProps> = ({children}: PopoverWrapperComponentProps) => (
  <AnimatePresence>{children}</AnimatePresence>
);

export default function Popover({
  align = "end",
  arrow,
  boundaryRef,
  children: trigger,
  closeOnClick,
  content,
  disableSelection = false,
  isOpen: forcedIsOpen,
  minimal = false,
  onClose,
  openOnClick = true,
  openOnRender = false,
  openTriggerClassName,
  padding = 0,
  position = "bottom",
  renderAsChild = false,
  renderAsChildOf,
  rootClass,
  width,
  wrapperClass,
}: PopoverProps) {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(openOnRender);
  const {rootElementRef} = useContext(AppContext);

  const containerRef = useRef<HTMLDivElement>(null);
  content ||= <div />;

  const handleTriggerClick = (forcedState?: boolean) => (evt: MouseEvent | React.MouseEvent | undefined) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    if (typeof forcedIsOpen === "undefined") {
      if (isOpen && onClose) onClose();
      setIsOpen(typeof forcedState !== "undefined" ? forcedState : !isOpen);
    } else if (forcedIsOpen && onClose) {
      onClose();
    }
  };

  const PopoverContentWithInjectedProps = cloneElement(content, {
    ...content.props,
    onClose: handleTriggerClick(false),
    key: content.key,
  });

  const triggerWithInjectedProps =
    trigger.type === "function" && openOnClick
      ? cloneElement(trigger, {...trigger.props, popoverOpen: isOpen, key: trigger.key})
      : trigger;

  const WrappedTrigger = openOnClick
    ? forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(function CustomComponent(
        props: React.ComponentPropsWithoutRef<"div"> & {onClick?: React.MouseEventHandler<HTMLDivElement>},
        ref,
      ) {
        const classes = clsx("popover-trigger-wrapper", {[openTriggerClassName || "isOpen"]: isOpen});
        return (
          <div ref={ref} onClick={openOnClick ? props.onClick : undefined} className={classes}>
            {triggerWithInjectedProps}
          </div>
        );
      })
    : null;

  const arrowContainerProps = {
    arrowColor: colors.ui.white,
    arrowSize: 6,
    style: {padding: 0},
  };

  const classes = clsx(wrapperClass, styles.actualPopover, {
    [styles.selectionDisabled]: disableSelection,
    [styles.minimal]: minimal,
  });

  const actualIsOpen = typeof forcedIsOpen === "undefined" ? isOpen : forcedIsOpen;

  return (
    <div ref={containerRef} className={rootClass}>
      <Suspense>
        <TinyPopover
          align={align}
          boundaryInset={25}
          boundaryElement={boundaryRef || rootElementRef?.current || undefined}
          containerClassName={classes}
          isOpen={actualIsOpen}
          onClickOutside={handleTriggerClick(false)}
          padding={padding}
          parentElement={renderAsChildOf || (renderAsChild && containerRef.current ? containerRef.current : undefined)}
          positions={Array.isArray(position) ? position : [position]}
          // @ts-ignore
          wrapperComponent={WrapperComponent}
          content={({position, childRect, popoverRect}) => (
            <MotionDiv
              {...getMotionFadeIn(0.075)}
              key="popover"
              className={styles.contentWrapper}
              style={{
                [`margin${capitalizeFirstLetter(inversePositionsMapping[position || "bottom"])}`]: "6px",
                ...(width === "matchTrigger"
                  ? {width: childRect.width}
                  : {
                      minWidth: !width ? "auto" : width,
                      transformOrigin: inversePositionsMapping[position || "bottom"],
                    }),
              }}
              onClick={closeOnClick ? handleTriggerClick() : () => null}
            >
              {arrow && false ? (
                <ArrowContainer
                  {...{position, childRect, popoverRect, ...arrowContainerProps}}
                  arrowStyle={{
                    ...arrowContainerProps.style,
                    [inversePositionsMapping[position || "bottom"]]: "-6px",
                  }}
                >
                  {PopoverContentWithInjectedProps}
                </ArrowContainer>
              ) : (
                PopoverContentWithInjectedProps
              )}
            </MotionDiv>
          )}
        >
          {openOnClick && WrappedTrigger ? <WrappedTrigger onClick={handleTriggerClick()} /> : trigger}
        </TinyPopover>
      </Suspense>
    </div>
  );
}
