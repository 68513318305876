import RoundButton from "@components/RoundButton";
import SimpleTooltip from "@components/Tooltip/Simple";

import useStyles from "./styles.jss";

export default function Header({
  title,
  onClose,
  onExpandClick,
  topRightButtons,
  expanded,
  expandable,
  titleTags,
}: {
  title: string | null;
  onClose: () => void;
  onExpandClick: () => void;
  topRightButtons?: React.ReactNode;
  titleTags?: React.ReactNode;
  expanded: boolean;
  expandable: boolean;
}) {
  const styles = useStyles();

  return !!title ? (
    <div className={styles.sidebarHeader}>
      <h2 title={title}>
        <span>{title}</span>
      </h2>
      <div className={styles.titleTags}>{titleTags}</div>
      <div className={styles.sidebarHeaderActions}>
        {!!topRightButtons ? topRightButtons : null}
        {expandable ? (
          <SimpleTooltip
            key="expand"
            text={`${expanded ? "Condense" : "Expand"} Sidebar`}
            placement="left"
            delay={{open: 200, close: 50}}
          >
            <RoundButton enableCssStates icon="collapse" onClick={onExpandClick} rotate={expanded ? 180 : 0} />
          </SimpleTooltip>
        ) : null}
        <SimpleTooltip key="close" text="Close" placement="left" delay={{open: 200, close: 50}}>
          <div className={styles.crossIconContainer}>
            <RoundButton icon="smallCross" minimal onClick={onClose} enableCssStates />
          </div>
        </SimpleTooltip>
      </div>
    </div>
  ) : null;
}
