import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import reportingAPI from "@shared/apis/reporting";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getReportsAdapter} from "@shared/state/entity-adapters";
import {api as reportItemsApi} from "@state/report-items/slice";

import type {EntityId} from "@reduxjs/toolkit";
import type {RootState} from "@shared/state/store";

export const api = {
  fetchReports: createAsyncThunk("reports/fetchAll", reportingAPI.fetchAll),
  fetchReportCache: createAsyncThunk("reports/fetchReportCache", reportingAPI.fetchReportCache),
  fetchReportItemMontlyCache: createAsyncThunk(
    "reports/fetchReportItemMontlyCache",
    reportingAPI.fetchReportItemMontlyCache,
  ),
  upsertReport: createAsyncThunk("reports/upsertReport", reportingAPI.upsertReport),
  deleteReport: createAsyncThunk("reports/deleteReport", reportingAPI.deleteReport),
};

export const getSlice = () => {
  const reportsAdapter = getReportsAdapter();
  const initialState = {
    ...reportsAdapter.getInitialState(),
    reportsMonthlyCache: {} as Record<string, number>,
    reportIdsCacheLoading: [] as EntityId[],
    reportIdsWithCacheLoaded: [] as EntityId[],
  };
  return createSlice({
    name: "reports",
    initialState,
    reducers: {
      upsertReport: reportsAdapter.upsertOne,
      deleteReport: reportsAdapter.removeOne,
    },
    extraReducers: (builder) => {
      // Report fetching
      builder.addCase(api.fetchReports.fulfilled, (state, action) => {
        reportsAdapter.setAll(state, action.payload);
      });

      // Report cache fetching
      builder.addCase(api.fetchReportCache.pending, (state, action) => {
        state.reportIdsCacheLoading.push(action.meta.arg);
      });
      builder.addCase(api.fetchReportCache.rejected, (state, action) => {
        state.reportIdsCacheLoading = state.reportIdsCacheLoading.filter((id) => id !== action.meta.arg);
      });
      builder.addCase(api.fetchReportCache.fulfilled, (state, action) => {
        state.reportsMonthlyCache = {...state.reportsMonthlyCache, ...action.payload};
        state.reportIdsCacheLoading = state.reportIdsCacheLoading.filter((id) => id !== action.meta.arg);
        if (!state.reportIdsWithCacheLoaded.includes(action.meta.arg))
          state.reportIdsWithCacheLoaded.push(action.meta.arg);
      });

      // Report items cache fetching
      builder.addCase(api.fetchReportItemMontlyCache.fulfilled, (state, action) => {
        state.reportsMonthlyCache = {...state.reportsMonthlyCache, ...action.payload};
      });

      // Report items cache updating after upsert
      builder.addCase(reportItemsApi.upsertReportItem.fulfilled, (state, action) => {
        if (action.payload.cache) {
          state.reportsMonthlyCache = {...state.reportsMonthlyCache, ...action.payload.cache};
        }
      });
    },
  });
};

const _slice = getSlice();

export const {upsertReport, deleteReport} = createSyncedActionCreators(_slice.actions);
export type ReportActions = typeof _slice.actions;

const selectReportIdsLoading = (state: RootState) => state.reports.reportIdsCacheLoading;
export const makeSelectIsReportCacheLoading = () =>
  createSelector(
    selectReportIdsLoading,
    (_state: RootState, id: string) => id,
    (ids, id) => ids.includes(id),
  );

const selectReportIdsLoaded = (state: RootState) => state.reports.reportIdsWithCacheLoaded;
export const makeSelectReportCacheInitialLoadDone = () =>
  createSelector(
    selectReportIdsLoaded,
    (_state: RootState, id: string) => id,
    (ids, id) => ids.includes(id),
  );

export const {
  selectById: selectReportById,
  selectIds: selectReportIds,
  selectEntities: selectReportEntities,
  selectAll: selectAllReports,
  selectTotal: selectTotalReports,
} = getReportsAdapter().getSelectors((state: RootState) => state.reports);
