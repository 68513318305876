import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {socket} from "@app/websockets/websockets";
import Button from "@components/Button/V2";
import {WithFloatingElementOnClick} from "@components/Floating";
import Menu from "@components/Menu";
import RoundButton from "@components/RoundButton";
import hiringPlanTopNavItems from "@features/hiring-plan/top-nav-items";
import reportingTopNavItems from "@features/reporting/top-nav-items";
import departmentsTopNavItems from "@features/settings/Departments/top-nav-items";
import templatesTopNavItems from "@features/templates/top-nav-items";
import {selectLastRequestCompletedAt, selectSaving, selectVersionLocked, selectors} from "@shared/state/global/slice";
import {deleteSession, selectCurrentUser} from "@shared/state/session/slice";
import {selectAllAlerts} from "@state/alerts/slice";
import {selectLoadingQboStatementRows} from "@state/integrations/slice";
import clsx from "clsx";
import dayjs from "dayjs";
import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

import AlertsPanel from "../AlertsList";
import useStyles from "./NotificationsArea.jss";

import type {MenuElement} from "@components/Menu";

export type TopBarMenuElement = MenuElement & {viewOnlyEnabled?: boolean};

export type NewMenuElementsProvider = (pathname: string) => {
  elements: MenuElement[];
  onChange: (item: MenuElement) => void;
  width?: number;
};
export type SettingsItemsProvider = (pathname: string) => TopBarMenuElement[];

const newMenuElementsProviderMapping: Record<string, NewMenuElementsProvider> = {
  "/hiring-plan": hiringPlanTopNavItems.getNewMenuElements,
  "/reports": reportingTopNavItems.getNewMenuElements,
  "/financial-statements": templatesTopNavItems.getNewMenuElements,
  "/templates": templatesTopNavItems.getNewMenuElements,
  "/worksheets": templatesTopNavItems.getNewMenuElements,
  "/settings/departments": departmentsTopNavItems.getNewMenuElements,
};

const settingsMenuElementsProviderMapping: Record<string, SettingsItemsProvider> = {
  "/hiring-plan": hiringPlanTopNavItems.getSettingsItems,
  "/reports": reportingTopNavItems.getSettingsItems,
  "/financial-statements": templatesTopNavItems.getSettingsItems,
  "/worksheets": templatesTopNavItems.getSettingsItems,
  "/templates": templatesTopNavItems.getSettingsItems,
};

export default function NotificationsArea({}) {
  const styles = useStyles();

  const location = useLocation();

  const saving = useAppSelector(selectSaving);
  const lastSync = useAppSelector(selectors.lastSync);
  const statusText = useAppSelector(selectors.statusText);
  const [displayedStatusText, setDisplayedStatusText] = useState<string | null>(null);
  const lastRequestCompletedAt = useAppSelector(selectLastRequestCompletedAt);
  const user = useAppSelector(selectCurrentUser)!;
  const isVersionLocked = useAppSelector(selectVersionLocked);

  const dispatch = useAppThunkDispatch();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const prevLastSync = useRef(lastSync);

  // TODO: overhaul saving status behavior
  // useEffect(() => {
  //   setDisplayedStatusText(lastSync ? `Saved by ${lastSync.name} on ${lastSync.time}` : null);
  //   if (timeout.current) clearTimeout(timeout.current);
  //   timeout.current = setTimeout(() => {
  //     setDisplayedStatusText(null);
  //   }, 5000);
  //   prevLastSync.current = lastSync;
  //   return () => {
  //     if (timeout.current) clearTimeout(timeout.current);
  //   };
  // }, [lastSync]);

  useEffect(() => {
    setDisplayedStatusText(statusText ? statusText : null);
    if (timeout.current) clearTimeout(timeout.current);
  }, [statusText]);

  // Get "New" button if it's defined for this path
  const newMenuElementKeys = Object.keys(newMenuElementsProviderMapping);
  const matchingNewElementKey = newMenuElementKeys.find((key) => location.pathname.startsWith(key));
  const newMenuElementsProvider = matchingNewElementKey
    ? newMenuElementsProviderMapping[matchingNewElementKey](location.pathname)
    : null;

  // Get page specific settings if it's defined for this path
  const settingsMenuElementKeys = Object.keys(settingsMenuElementsProviderMapping);
  const matchingSettingsElementKey = settingsMenuElementKeys.find((key) => location.pathname.startsWith(key));
  const settingsMenuElementsProvider = matchingSettingsElementKey
    ? settingsMenuElementsProviderMapping[matchingSettingsElementKey](location.pathname)
    : null;

  let onMenuElementClick = newMenuElementsProvider?.onChange;
  if (!onMenuElementClick) onMenuElementClick = () => null;

  const handleUserMenuElementSelect = (item: MenuElement) => {
    if (item.text === "Sign Out") {
      socket.off("logout");
      dispatch(deleteSession());
    }
  };

  const handleSettingsMenuElementSelect = (item: MenuElement) => {
    // console.log("handle settings menu item select", item);
  };

  const userInitials = user.name
    .split(" ")
    .map((word) => word[0])
    .join("");

  const userMenuElements: MenuElement[] = [
    {
      type: "header",
      text: user.name,
      subtext: "Admin", // TODO: make this dynamic when proper access rights are implemented
    },
    // {
    //   type: "item",
    //   iconText: userInitials,
    //   text: "My Profile",
    //   link: `/profile`,
    // },
    {
      type: "item",
      icon: "signOut",
      text: "Sign Out",
    },
  ];

  const settingsMenuElements: MenuElement[] = isVersionLocked
    ? []
    : [
        {
          type: "header",
          text: "Company Settings",
        },
        {
          type: "item",
          icon: "departments",
          text: "Departments",
          link: "/settings/departments",
        },
        {
          type: "item",
          icon: "integrations",
          text: "Integrations",
          link: "/settings/integrations",
        },
        {
          type: "item",
          icon: "defaults",
          text: "Defaults",
          link: "/settings/defaults",
        },
        // {
        //   type: "item",
        //   icon: "userAccess",
        //   text: "User Access",
        // },
      ];

  if (settingsMenuElementsProvider) {
    settingsMenuElements.unshift(
      {
        type: "header",
        text: "Page Settings",
      },
      ...settingsMenuElementsProvider.filter((item) => !isVersionLocked || item.viewOnlyEnabled),
    );
  }

  const saveStatustext = saving
    ? "Saving..."
    : !lastRequestCompletedAt
    ? "No Changes"
    : `Last Saved ${dayjs(lastRequestCompletedAt).format("hh:MM:ss A")}`;

  return (
    <div className={styles.notificationsArea}>
      {isVersionLocked ? (
        <div className={styles.viewOnlyText}>
          <span>View Only</span>
        </div>
      ) : null}
      {!isVersionLocked && displayedStatusText ? <div>{displayedStatusText}</div> : null}
      {isVersionLocked || displayedStatusText ? <div className={styles.separator}>|</div> : null}

      <div className={styles.icons}>
        {!isVersionLocked && newMenuElementsProvider?.elements?.length ? (
          <Menu
            align="end"
            elements={newMenuElementsProvider.elements}
            onSelect={onMenuElementClick}
            position="bottom"
            renderAsChild
            minWidth={125}
            menuTriggerWrapperClass={styles.newPopoverWrapper}
          >
            <Button className={styles.newButtonWrapper} color="orange" iconLeft="plus" text="New" size="xsmall" />
          </Menu>
        ) : null}
        {/*<div className={styles.helpMenuWrapper}>
        <Dropdown align="end" items={[]} onSelect={() => null} position="bottom">
          <RoundButton icon="help" minimal onClick={() => null} />
        </Dropdown>
      </div>*/}
        <AlertsButton />
        {settingsMenuElements.some((item) => item.type === "item" || item.type === "submenu") ? (
          <div className={styles.settingsMenuWrapper}>
            <Menu
              align="end"
              elements={settingsMenuElements}
              onSelect={handleSettingsMenuElementSelect}
              position="bottom"
              minWidth={150}
            >
              <RoundButton icon="cog" minimal enableCssStates onClick={() => null} />
            </Menu>
          </div>
        ) : null}
      </div>
      <div className={styles.userMenuWrapper}>
        <Menu
          align="end"
          elements={userMenuElements}
          onSelect={handleUserMenuElementSelect}
          position="bottom"
          width={170}
        >
          <RoundButton onClick={() => null} enableCssStates mainColor="blue" text={userInitials} />
        </Menu>
      </div>
    </div>
  );
}

function AlertsButton() {
  const allAlerts = useAppSelector(selectAllAlerts);
  const qboStatementRowsLoading = useAppSelector(selectLoadingQboStatementRows);
  const fullCacheLoadDone = useAppSelector(selectors.fullCacheLoadDone);

  const [isOpen, setIsOpen] = useState(false);

  const styles = useStyles();

  const hasAlerts = !qboStatementRowsLoading && allAlerts.length > 0;

  return (
    <WithFloatingElementOnClick content={<AlertsPanel />} placement="bottom-end" portal onOpenChange={setIsOpen}>
      <RoundButton
        icon={!fullCacheLoadDone ? "spinner" : hasAlerts ? "bellRinging" : "bell"}
        minimal
        enableCssStates
        className={clsx(hasAlerts ? styles.hasAlerts : null)}
        active={isOpen}
      />
    </WithFloatingElementOnClick>
  );
}
