import clsx from "clsx";

import ScenarioSelector from "../ScenarioSelector";
import BreadcrumbItemComponent from "./BreadcrumbItem";
import useStyles from "./Breadcrumbs.jss";
import {useBcItems} from "./hooks";

export default function Breadcrumbs() {
  const styles = useStyles();

  const breadcrumbItems = useBcItems();

  const classes = clsx(styles.breadcrumbs);

  return (
    <div className={classes}>
      {breadcrumbItems.map((item, i) => (
        <BreadcrumbItemComponent addSeparator={i !== breadcrumbItems.length - 1} key={item.text} item={item} />
      ))}

      {breadcrumbItems.length > 1 ? (
        <div className={styles.scenarioSelector}>{breadcrumbItems.length ? <ScenarioSelector /> : null}</div>
      ) : null}
    </div>
  );
}
