import {store} from "@app/client-store";
import {exportHiringPlan} from "@features/shared-views/Navigation/exports/hiring-plan";
import {exportTemplateAllData} from "@features/shared-views/Navigation/exports/templates-all-data";
import id from "@shared/lib/id";
import {teamAdded} from "@shared/state/teams/slice";

import {setSidebarOpen as setListViewSidebarOpen} from "./ListView/slice";
import {addEmployee} from "./add-employee-handler";
import {setFilterText, setSelectedEmployeeId, setSelectedTeamId, setSidebarOpen} from "./slice";

import type {MenuElement} from "@components/Menu";
import type {BreadcrumbItem} from "@features/shared-views/Navigation/BreadcrumbItem";
import type {TopBarMenuElement} from "@features/shared-views/Navigation/NotificationsArea";
import type {BreadcrumbsProvider} from "@features/shared-views/Navigation/hooks";

const subpageNameMapping: Record<string, string> = {
  "compensation-and-taxes": "Compensation & Taxes",
  teams: "Teams",
  monthly: "Monthly",
  "list-view": "List View",
};

export const useBreadcrumbItems: BreadcrumbsProvider = ({location, navigate}) => {
  const breadcrumbs: BreadcrumbItem[] = [
    {
      text: "Hiring Plan",
    },
  ];

  const locationParts = location.pathname.split("/");

  const secondLevelItem: BreadcrumbItem = {
    text: !locationParts[2] ? "Summary" : subpageNameMapping[locationParts[2]],
    dropdownItems: [
      {
        key: "summary",
        value: "Summary",
        selected: !locationParts[2],
      },
      {
        key: "monthly",
        value: "Monthly",
        selected: locationParts[2] === "monthly",
      },
      {
        key: "list-view",
        value: "List View",
        selected: locationParts[2] === "list-view",
      },
    ],
    dropdownWidth: 125,
    onDropdownChange: (item) => {
      if (item.key === "monthly" && item.key !== locationParts[2]) navigate("/hiring-plan/monthly");
      if (item.key === "list-view" && item.key !== locationParts[2]) navigate("/hiring-plan/list-view");
      if (item.key === "summary" && !!locationParts[2]) navigate("/hiring-plan");
      store.dispatch(setSelectedEmployeeId(null));
      store.dispatch(setSidebarOpen(false));
      store.dispatch(setFilterText(null));
    },
  };

  breadcrumbs.push(secondLevelItem);

  /*const selectedSecondLevelItemIndex = secondLevelItems.findIndex((item) => pathname.split("/")[2] === item.key) || 0;

  const secondLevelItem: BreadcrumbItem =g h {
    text: secondLevelItems[0].value as string,
    dropdownItems: secondLevelItems.map((item, index) => ({...item, selected: selectedSecondLevelItemIndex === index})),
    dropdownWidth: 175,
    onDropdownChange: (item: DropdownItem) => navigate(`${history.location.pathname}?$statement=${item.key}`),
  };

  breadcrumbs.push(secondLevelItem);*/

  return breadcrumbs;
};

export function getSettingsItems(pathname: string): TopBarMenuElement[] {
  const elements: TopBarMenuElement[] = [
    // {
    //   text: "Compensation Settings",
    //   selected: pathname.split("/")[2] === "compensation-and-taxes",
    //   type: "item",
    //   link: "/hiring-plan/compensation-and-taxes",
    // },
  ];
  if (pathname.includes("list-view")) {
    elements.push({
      text: "Edit Columns",
      icon: "editColumns",
      type: "item",
      onClick: () => store.dispatch(setListViewSidebarOpen(!store.getState().hiringPlanListView.sidebarOpen)),
    });
  }
  elements.push(
    {
      text: "Export as CSV",
      icon: "export",
      type: "item",
      onClick: () =>
        pathname.includes("/monthly")
          ? exportTemplateAllData({templateId: store.getState().templatesView.templateId!, format: "csv"})
          : exportHiringPlan({format: "csv"}),
      viewOnlyEnabled: true,
    },
    // {
    //   text: "Edit Teams",
    //   selected: pathname.split("/")[2] === "teams",
    //   type: "element",
    //   icon: "team",
    //   link: `/hiring-plan/teams`,
    // },
  );

  return elements;
}

export function getNewMenuElements(): {elements: MenuElement[]; onChange: (element: MenuElement) => void} {
  const onChange = (element: MenuElement) => {
    const state = store.getState();
    if (element.text === "Employee") {
      // const employeeId = id();
      // const scenario_properties: {[scenarioId: string]: ScenarioSpecificEmployeeProps} = {};
      // const todayFormatted = dayjs().format("YYYY-MM-DD");
      // for (const scenarioId of state.scenarios.ids) {
      //   scenario_properties[scenarioId] = {};
      //   if (state.global.scenarioId === scenarioId) scenario_properties[scenarioId].hire_date = todayFormatted;
      // }
      // store.dispatch(employeeAdded({id: employeeId, team_id: null, scenario_properties}));
      // store.dispatch(setSelectedEmployeeId(employeeId));
      addEmployee(null);
    } else if (element.text === "Team") {
      const teamId = id();
      store.dispatch(teamAdded({id: teamId}));
      store.dispatch(setSelectedTeamId(teamId));
    }
  };

  return {
    onChange,
    elements: [
      {
        type: "item",
        text: "Employee",
      },
      {
        type: "item",
        text: "Team",
      },
    ],
  };
}

export default {
  useBreadcrumbItems,
  getSettingsItems,
  getNewMenuElements,
};

// const secondLevelItems: DropdownItem[] = [
//   {
//     key: "salaries",
//     value: "Salaries",
//   },
//   {
//     key: "benefits",
//     value: "Benefits",
//   },
//   {
//     key: "payroll_taxes",
//     value: "Payroll Taxes",
//   },
// ];
