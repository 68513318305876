import clsx from "clsx";

import useStyles from "./styles.jss";

export interface CollaborationProps {}

export default function Collaboration({}: CollaborationProps) {
  const styles = useStyles();
  const classes = clsx(styles.collaborationMain);

  return <div className={classes}>Content of Collaboration component.</div>;
}
