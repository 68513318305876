// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import colors from "@app/styles/colors";
import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";
import color from "color";
import React from "react";

import useStyles from "./styles.jss";

import type { IconNames } from "@components/SVGIcon";



export interface IButton {
  active?: boolean;
  alignText?: "center" | "right" | "left";
  bold?: boolean;
  className?: string;
  color?: Colors;
  disabled?: boolean;
  dropdown?: boolean;
  fill?: boolean;
  iconLeft?: IconNames;
  iconRight?: IconNames;
  minimal?: boolean;
  onClick?: React.MouseEventHandler;
  outline?: boolean;
  padding?: boolean;
  rightText?: string;
  size?: ButtonSizes;
  tag?: boolean;
  text?: string;
  width?: number;
}

type ButtonSizes = "large" | "normal" | "small" | "xsmall" | "xxsmall";

export type Colors = "orange" | "lightOrange" | "grey" | "lightOrangeDarker" | "red" | null;

export type ActualColors = {
  textColor: string;
  backgroundColor: string;
  hoverBackgroundColor?: string;
  hoverTextColor?: string;
};

export function getActualColorsFromPassedColor(
  passedColor: Colors | null,
  disabled: boolean,
  minimal = false,
): ActualColors {
  switch (passedColor) {
    case "lightOrange": {
      return {
        textColor: colors.primary.gold,
        backgroundColor: color(colors.primary.lightGold03).alpha(0.5).rgb().string(),
      };
    }
    case "lightOrangeDarker": {
      return {
        textColor: colors.primary.gold,
        backgroundColor: colors.primary.lightGold03,
      };
    }
    case "red": {
      return {
        textColor: colors.text.white,
        backgroundColor: colors.states.red,
      };
    }
    case "grey": {
      const btnColors: ActualColors = {
        textColor: minimal ? colors.text.lightBlue01 : colors.text.darkBlue02,
        backgroundColor: minimal ? "transparent" : colors.ui.lightBlue08,
        hoverBackgroundColor: color(colors.ui.lightBlue08).alpha(0.8).rgb().string(),
        //hoverTextColor: colors.text.lightBlue02,
      };

      if (disabled) {
        btnColors.textColor = color(btnColors.textColor).alpha(0.5).rgb().string();
        btnColors.backgroundColor = minimal
          ? "transparent"
          : color(btnColors.backgroundColor).alpha(0.5).rgb().string();
        btnColors.hoverBackgroundColor = minimal
          ? "transparent"
          : color(btnColors.backgroundColor).alpha(0.5).rgb().string();
      }

      return btnColors;
    }
    case "orange": {
      const btnColors: ActualColors = {
        textColor: minimal ? colors.primary.gold : colors.text.white,
        backgroundColor: minimal ? "transparent" : colors.primary.gold,
        hoverBackgroundColor: colors.primary.gold,
        hoverTextColor: colors.text.white,
      };

      if (disabled) {
        btnColors.textColor = color(btnColors.textColor).alpha(0.5).rgb().string();
        btnColors.backgroundColor = minimal
          ? "transparent"
          : color(btnColors.backgroundColor).alpha(0.5).rgb().string();
        delete btnColors.hoverBackgroundColor;
        delete btnColors.hoverTextColor;
      }

      return btnColors;
    }
    default: {
      const btnColors: ActualColors = {
        textColor: colors.ui.lightBlue01,
        backgroundColor: "transparent",
      };
      if (disabled) {
        btnColors.textColor = color(btnColors.textColor).alpha(0.5).rgb().string();
        btnColors.backgroundColor = minimal
          ? "transparent"
          : color(btnColors.backgroundColor).alpha(0.5).rgb().string();
      }
      return btnColors;
    }
  }
}

export default function Button({
  active = false,
  alignText = "center",
  bold = true,
  className,
  color,
  disabled = false,
  dropdown,
  fill,
  iconLeft,
  iconRight,
  minimal,
  onClick,
  outline,
  padding = false,
  rightText,
  size = "normal",
  tag = false,
  text,
  width,
}: IButton) {
  const actualColors = getActualColorsFromPassedColor(color || null, disabled, minimal);
  const styles = useStyles({
    disabled,
    outline,
    fill,
    bold,
    ...actualColors,
    dropdown,
    minimal,
    padding,
    size,
    alignText,
  });

  const classes = clsx(className, styles.buttonMain, {
    [styles.active]: active,
    [styles.tag]: tag,
  });

  const buttonOnClick = minimal && !outline ? () => null : onClick;
  const textOnClick = minimal && !outline ? onClick : () => null;

  return (
    <div className={classes} onClick={buttonOnClick} style={{width: fill ? "100%" : width ? width : "auto"}}>
      {iconLeft ? <SVGIcon className={styles.iconLeft} name={iconLeft} /> : null}
      <span onClick={textOnClick}>{text || ""}</span>
      {rightText ? <div className={styles.rightText}>{rightText}</div> : null}
      {iconRight ? <SVGIcon className={styles.iconRight} name={iconRight} /> : null}
    </div>
  );
}
