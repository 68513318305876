import api from "@shared/lib/fetch";

import type {EntityId} from "@reduxjs/toolkit";
import type {ApiEndpoints} from "@shared/state/store";
import type {Datasource} from "@shared/types/datasources";

const endpoints = {
  upsert(datasources: Datasource[], thunkAPI) {
    return api.put<void>(thunkAPI, "datasources", datasources);
  },
  remove(ids: EntityId[], thunkAPI) {
    return api.del<void>(thunkAPI, "datasources", ids);
  },
} satisfies ApiEndpoints;

export default endpoints;
