import {store} from "@app/client-store";
import {selectSelectedRow} from "@features/templates/state/selectors";
import {getMatchingFunctions} from "@shared/data-functions/formula/formula-functions-list";
import {selectAllDepartments} from "@state/departments/slice";
import {selectQboIntegrationVendors} from "@state/integrations/slice";
import {selectAllTemplateRows} from "@state/template-rows/slice";
import {selectTemplateByName} from "@state/templates/selectors";
import memoizeOne from "memoize-one";

import {analyzeFormula, getChunkAtCursor} from "../../../../../shared/data-functions/formula/parse-utilities";
import {rangeSuggestions} from "./range-suggestions";

import type {ClientRootState} from "@app/client-store";
import type {TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";
import type {FormulaChunkWithIndexes} from "../../../../../shared/data-functions/formula/parse-utilities";
import type {FormulaSuggestion} from "./Suggestions";

export type SuggestionsResult = ReturnType<typeof getSuggestions>;

export const getSuggestions = memoizeOne(
  (
    formula: string,
    cursorPosition?: number | null,
  ): {
    currentValueIsValid: boolean;
    suggestions: FormulaSuggestion[];
    chunkIndex: number | null;
    chunks: FormulaChunkWithIndexes[];
  } => {
    const chunks = analyzeFormula(formula);
    const adjustedPosition =
      cursorPosition === formula.length ? cursorPosition - 1 : cursorPosition ?? Math.max(formula.length - 1, 0);
    const {chunk: chunkAtCursor, index} = getChunkAtCursor(chunks, adjustedPosition);

    let nextChunk = chunks[index + 1];
    let prevChunk = chunks[index - 1];

    const state = store.getState();

    if (!chunkAtCursor) return {currentValueIsValid: false, suggestions: [], chunkIndex: null, chunks};

    // If this is generic type and the last typed character is a letter
    let suggestions: FormulaSuggestion[] = [];
    let currentValueIsValid = false;

    if (chunkAtCursor) {
      let {suggestionType, chunk} = getSuggestionType(chunkAtCursor, nextChunk);

      switch (suggestionType) {
        case "formula_fn_and_row":
          const formulaSuggestionsResult = getFormulaFnSuggestions(chunk);
          const rowSuggestionsResult = getRowSuggestions(chunk, state, 5);
          suggestions = [...formulaSuggestionsResult.suggestions, ...rowSuggestionsResult.suggestions];
          break;
        case "range":
          ({suggestions, currentValueIsValid} = getRangeSuggestions(chunk, state, 10, nextChunk));
          break;
        case "row":
          // Adjust the chunk if we're in the template or template_delimiter subtype - we want chunk to be the row
          const indexOffset = chunk.subtype === "template" ? 2 : chunk.subtype === "template_delimiter" ? 1 : 0;
          chunk = chunks[index + indexOffset];
          prevChunk = chunks[index + indexOffset - 1];
          nextChunk = chunks[index + indexOffset + 1];
          ({suggestions, currentValueIsValid} = getRowSuggestions(chunk, state, 10, prevChunk, nextChunk));
          break;
        case "vendor":
          ({suggestions, currentValueIsValid} = getVendorSuggestions(chunk, state));
          break;
        case "department":
          ({suggestions, currentValueIsValid} = getDepartmentSuggestions(chunk, state));
          break;
        default:
        // console.log(
        //   `No suggestions for suggestion type: ${suggestionType} (chunk type: ${chunk?.type}, subtype ${chunk?.subtype})`,
        // );
      }
    }

    // console.log({suggestions, currentValueIsValid, chunkAtCursor, index, chunks});

    return {currentValueIsValid, suggestions, chunkIndex: index, chunks};
  },
);

function getSuggestionType(chunkAtCursor: FormulaChunkWithIndexes, nextChunk: FormulaChunkWithIndexes | null) {
  const chunk =
    nextChunk &&
    (chunkAtCursor.type === "extra" ||
      (isAnyCbRef(chunkAtCursor) &&
        (chunkAtCursor.subtype?.endsWith("comma") || chunkAtCursor.subtype === "range_delimiter")))
      ? nextChunk
      : chunkAtCursor;

  const cleanedSubtype = chunk.subtype?.replace(/_value$/, "") as string | null | undefined;

  let type = "none";

  if (
    (chunk.type === "function" && cleanedSubtype === "name") ||
    (chunk.type === "operand" && cleanedSubtype === "range") ||
    (chunk.type === "extra" && cleanedSubtype === "extra" && chunk.value.startsWith("="))
  ) {
    type = "formula_fn_and_row";
  }
  if (isAnyCbRef(chunk) && ["template", "template_delimiter", "row", "start"].includes(cleanedSubtype ?? "")) {
    type = "row";
  }
  if (isAnyCbRef(chunk) && cleanedSubtype === "vendor") {
    type = "vendor";
  }
  if (isAnyCbRef(chunk) && cleanedSubtype === "department") {
    type = "department";
  }
  if (isAnyCbRef(chunk) && cleanedSubtype?.startsWith("range")) {
    type = "range";
  }

  return {suggestionType: type, chunk};
}

export function isAnyCbRef(chunk?: FormulaChunkWithIndexes | null): chunk is FormulaChunkWithIndexes {
  return chunk?.type === "cb_ref" || chunk?.type === "cb_advanced_ref";
}

export function substituteSuggestionInFormula(
  formula: string | null,
  chunks: FormulaChunkWithIndexes[],
  chunkIndex: number,
  suggestion: FormulaSuggestion,
) {
  if (formula === null) formula = "";
  const chunk = chunks[chunkIndex];
  let newValue = suggestion.slug;
  let substitutionStartIndex = chunk.startIndex;
  let substitutionEndIndex = chunk.endIndex;

  // If this is a formula function and it's not followed by "(", add the opening parenthesis
  // Also, if it's the last chunk, add the opening bracket (so "([" appended to value)
  if (suggestion.type === "formulaFunction") {
    const nextChunk = chunks[chunkIndex + 1];
    if (!nextChunk) {
      newValue += "([";
    } else if (!nextChunk.value.startsWith("(")) {
      newValue += "(";
    }
  } else if (suggestion.type === "vendor") {
    const nextChunk = chunks[chunkIndex + 1];
    if (!nextChunk || !nextChunk.value.trimStart().startsWith("]")) {
      newValue += "]";
    }
  } else if (suggestion.type === "row") {
    // Adjustments to make sure to replace the whole row reference
    if (chunk.subtype === "row") {
      if (chunks[chunkIndex - 2]?.subtype === "template") {
        substitutionStartIndex = chunks[chunkIndex - 2].startIndex;
      } else if (
        chunks[chunkIndex - 1]?.subtype === "template" ||
        chunks[chunkIndex - 1]?.subtype === "template_delimiter"
      ) {
        substitutionStartIndex = chunks[chunkIndex - 1].startIndex;
      }
    } else if (chunk.subtype === "template") {
      if (chunks[chunkIndex + 2]?.subtype === "row") {
        substitutionEndIndex = chunks[chunkIndex + 2].endIndex;
      } else if (
        chunks[chunkIndex + 1]?.subtype === "row" ||
        chunks[chunkIndex + 1]?.subtype === "template_delimiter"
      ) {
        substitutionEndIndex = chunks[chunkIndex + 1].endIndex;
      }
    } else if (chunk.subtype === "template_delimiter") {
      if (chunks[chunkIndex + 1]?.subtype === "row") {
        substitutionEndIndex = chunks[chunkIndex + 1].endIndex;
      }
      if (chunks[chunkIndex - 1]?.subtype === "template") {
        substitutionStartIndex = chunks[chunkIndex - 1].startIndex;
      }
    }
  }

  if (["department", "vendor"].includes(suggestion.type)) {
    const prevChunk = chunks[chunkIndex - 1];
    if (prevChunk && !prevChunk.value.endsWith(" ")) {
      newValue = ` ${newValue}`;
    }
  }

  const newFormula = formula.slice(0, substitutionStartIndex) + newValue + formula.slice(substitutionEndIndex + 1);
  return newFormula;
}

function getRangeSuggestions(
  chunk: FormulaChunkWithIndexes,
  state: RootState,
  max = 10,
  nextChunk?: FormulaChunkWithIndexes,
): {currentValueIsValid: boolean; suggestions: FormulaSuggestion[]} {
  const cleanedStr = chunk.value.toLowerCase().trim();

  const suggestions: FormulaSuggestion[] = [];
  let currentValueIsValid = false;

  for (const suggestion of rangeSuggestions) {
    if (suggestions.length >= max) break;

    // Filter out ranges whose slugs don't start with the cleaned string and the display name doesn't include it
    if (
      cleanedStr.length &&
      cleanedStr !== ":" &&
      !suggestion.slug.toLowerCase().startsWith(cleanedStr) &&
      !suggestion.displayName.toLowerCase().includes(cleanedStr)
    ) {
      continue;
    }

    const suggestionToPush = {...suggestion};

    if (suggestionToPush.slug === cleanedStr) {
      currentValueIsValid = true;
    }

    if (!nextChunk || nextChunk.value.trim().startsWith("]")) {
      suggestionToPush.slug = `${suggestionToPush.slug}`;
      if (chunk.subtype === "range_start") {
        // suggestionToPush.slug += ":";
      } else if (chunk.subtype === "range_delimiter") {
        suggestionToPush.slug = `:${suggestionToPush.slug}`;
      }
    }

    suggestions.push(suggestionToPush);
  }

  return {currentValueIsValid, suggestions};
}

function getFormulaFnSuggestions(
  chunk: FormulaChunkWithIndexes,
  max = 5,
): {currentValueIsValid: boolean; suggestions: FormulaSuggestion[]} {
  const lastWord = chunk?.value?.replace("=", "").split(" ")?.at(-1) ?? "";
  const matchingFormulaFns = getMatchingFunctions(lastWord);

  // TODO: make some show up first when there's nothing typed yet (not 10, but 5 functions and 2 rows)
  // - AVERAGE, MAX, MEDIAN, MIN, SUM
  // - self, revenue

  const suggestions: FormulaSuggestion[] = [];
  let currentValueIsValid = false;

  for (const fn of matchingFormulaFns) {
    if (suggestions.length >= max) break;

    if (fn.name === lastWord) {
      currentValueIsValid = true;
    }

    suggestions.push({
      displayName: fn.name,
      slug: fn.name,
      subtext: fn.description,
      type: "formulaFunction",
    });
  }

  return {currentValueIsValid, suggestions};
}

type MinimalTemplateRow = Pick<TemplateRow, "display_name" | "name" | "template_id">;
function getRowSuggestions(
  chunk: FormulaChunkWithIndexes,
  state: ClientRootState,
  max = 10,
  prevChunk?: FormulaChunkWithIndexes,
  nextChunk?: FormulaChunkWithIndexes,
): {currentValueIsValid: boolean; suggestions: FormulaSuggestion[]} {
  const allRows: MinimalTemplateRow[] = selectAllTemplateRows(state);
  const templatesById = state.templates.entities;

  const selectedRow = selectSelectedRow(state);

  const prefixExtractRegex = /^(=?\[?\s*)(.*)/;
  const matches = chunk.value.match(prefixExtractRegex);
  let {1: prefix, 2: str} = matches ?? ["", ""];

  // If there is not bracket at all preceding the current chunk or at the start of it, add one
  if (chunk.subtype !== "row" && !prefix.includes("[") && (!isAnyCbRef(prevChunk) || prevChunk.subtype === "start")) {
    prefix += "[";
  }
  let cleanedStr = str.toLowerCase().trim();

  // Template handling if specified
  let templateIdFilter: string | null = null;
  if (cleanedStr.includes("!")) {
    const parts = cleanedStr.split("!");
    cleanedStr = parts[1];
    const template = selectTemplateByName(state, cleanedStr.split("!")[0]);
    if (template) {
      templateIdFilter = template.id;
    }
  }

  // Build the list of rows that start with the cleaned string and the ones that include it
  const rowsStartingWithStr: MinimalTemplateRow[] = [];
  const rowsIncludingStr: MinimalTemplateRow[] = [];
  for (const row of allRows) {
    if (!row.display_name?.length) continue;
    if (templateIdFilter && row.template_id !== templateIdFilter) continue;
    if (
      (!cleanedStr.length && row.name === "revenue") ||
      (cleanedStr.length &&
        (row.display_name.toLowerCase().startsWith(cleanedStr) || row.name.toLowerCase().startsWith(cleanedStr)))
    ) {
      rowsStartingWithStr.push(row);
    } else if (
      cleanedStr.length &&
      (row.display_name.toLowerCase().includes(cleanedStr) || row.name.toLowerCase().includes(cleanedStr))
    ) {
      rowsIncludingStr.push(row);
    }
  }

  // Add the "self" entry if the current row is selected (which should always be the casw, in theory)
  if (selectedRow) {
    const selfRowEntry = {
      display_name: "Self (This Row)",
      name: "self",
      template_id: selectedRow.template_id,
    };

    if (
      (cleanedStr.length === 1 && str === "[") ||
      cleanedStr.length === 0 ||
      "self".startsWith(cleanedStr) ||
      "this row".startsWith(cleanedStr)
    ) {
      rowsStartingWithStr.unshift(selfRowEntry);
    }
  }

  const suggestions: FormulaSuggestion[] = [];
  let currentValueIsValid = false;

  for (const row of [...rowsStartingWithStr, ...rowsIncludingStr]) {
    if (suggestions.length >= max) break;
    if (row.display_name === str) {
      currentValueIsValid = true;
    }
    const template = templatesById[row.template_id ?? ""];
    const baseSlug =
      template && template.id !== selectedRow?.template_id && row.name !== "self"
        ? `${template.name}!${row.name}`
        : row.name;
    let slug = baseSlug;
    if (prefix.length) {
      slug = `${prefix}${slug}`;
    }
    // if (!nextChunk?.value?.length) {
    //   slug += ", ";
    // }

    suggestions.push({
      displayName: row.display_name,
      slug,
      rightText: template?.display_name,
      subtext: `[${baseSlug}]`,
      type: "row",
    });
  }

  return {currentValueIsValid, suggestions};
}
function getDepartmentSuggestions(
  chunk: FormulaChunkWithIndexes,
  state: RootState,
  max = 10,
): {currentValueIsValid: boolean; suggestions: FormulaSuggestion[]} {
  const allDepartments = selectAllDepartments(state);

  const cleanedStr = chunk.value.toLowerCase().trim();

  const departmentsStartingWithStr = allDepartments.filter(
    (department) =>
      department.name.toLowerCase().startsWith(cleanedStr) ||
      department.display_name.toLowerCase().startsWith(cleanedStr),
  );
  const departmentsIncludingStr = allDepartments.filter(
    (department) =>
      !departmentsStartingWithStr.includes(department) &&
      (department.name.toLowerCase().includes(cleanedStr) ||
        department.display_name.toLowerCase().includes(cleanedStr)),
  );

  const suggestions: FormulaSuggestion[] = [];
  let currentValueIsValid = false;

  for (const department of departmentsStartingWithStr) {
    if (suggestions.length >= max) break;
    if (department.display_name === chunk.value) {
      currentValueIsValid = true;
    }
    suggestions.push({
      displayName: department.display_name,
      slug: department.name,
      type: "department",
    });
  }

  for (const department of departmentsIncludingStr) {
    if (suggestions.length >= max) break;
    if (department.display_name === chunk.value) {
      currentValueIsValid = true;
    }
    suggestions.push({
      displayName: department.display_name,
      slug: department.name,
      type: "department",
    });
  }

  return {currentValueIsValid, suggestions};
}
function getVendorSuggestions(
  chunk: FormulaChunkWithIndexes,
  state: RootState,
  max = 10,
): {currentValueIsValid: boolean; suggestions: FormulaSuggestion[]} {
  const allVendors = Object.values(selectQboIntegrationVendors(state));

  const cleanedStr = chunk.value.toLowerCase().trim();

  const vendorsStartingWithStr = allVendors.filter(
    (vendor) => vendor.displayName.toLowerCase().startsWith(cleanedStr) || vendor.qbId.toLowerCase() === cleanedStr,
  );
  const vendorsIncludingStr = allVendors.filter(
    (vendor) => !vendorsStartingWithStr.includes(vendor) && vendor.displayName.toLowerCase().includes(cleanedStr),
  );

  const suggestions: FormulaSuggestion[] = [];
  let currentValueIsValid = false;

  for (const vendor of vendorsStartingWithStr) {
    if (suggestions.length >= max) break;
    if (vendor.displayName === chunk.value) {
      currentValueIsValid = true;
    }
    suggestions.push({
      displayName: vendor.displayName,
      slug: vendor.qbId,
      type: "vendor",
    });
  }

  for (const vendor of vendorsIncludingStr) {
    if (suggestions.length >= max) break;
    if (vendor.displayName === chunk.value) {
      currentValueIsValid = true;
    }
    suggestions.push({
      displayName: vendor.displayName,
      slug: vendor.qbId,
      type: "vendor",
    });
  }

  return {currentValueIsValid, suggestions};
}
