import id from "@shared/lib/id";
import {getUniqueSlug} from "@shared/lib/slug";
import numeral from "numeral";

import {getDatasourcesForMonth} from "./datasource-utilities";

import type {GenericTemplateRow, Template, TemplateRow} from "@shared/types/db";
import type {DatasourcesState, TemplateRowsState} from "@state/entity-adapters";
import type {RootState} from "@state/store";

export function isCellDisabled(row: TemplateRow | null, dateKey: string | null, state: RootState): boolean;
export function isCellDisabled(
  row: TemplateRow | null,
  dateKey: string | null,
  scenarioId: string | null,
  template: Template,
  datasourcesState: DatasourcesState,
): boolean;
export function isCellDisabled(
  row: TemplateRow | null,
  dateKey: string | null,
  stateOrScenarioId: RootState | string | null,
  template?: Template,
  datasourcesState?: DatasourcesState,
): boolean {
  let scenarioId: string | null;
  if (typeof stateOrScenarioId !== "string" && stateOrScenarioId !== null) {
    template = stateOrScenarioId.templates.entities[row?.template_id ?? ""];
    datasourcesState = stateOrScenarioId.datasources;
    scenarioId = stateOrScenarioId.global.scenarioId;
  } else {
    scenarioId = stateOrScenarioId;
  }
  if (!row || !dateKey || !scenarioId || !template || !datasourcesState) return false;

  if (row.type === "hiring-plan") return true;
  const datasources = getDatasourcesForMonth(datasourcesState, {rowId: row.id, scenarioId, dateKey}, template.options);
  // TODO: update for multiple datasources in the future?
  const datasource = datasources[0];
  const isDisabled =
    !!row.mirror_of ||
    (template.name === "cash_flow_statement" && row.type === "account") ||
    (datasource?.type !== "integration" && row.type === "account" && row.options.type === "Bank") ||
    (!!datasource && datasource.type !== "formula");

  return isDisabled;
}

export function formatNumber(
  value: number | null,
  currency = false,
  decimals: number = 0,
  percentage: boolean = false,
) {
  if (
    Number.isNaN(value) ||
    value === null ||
    typeof value === "undefined" ||
    value === 0 ||
    (!percentage &&
      ((decimals === 0 && value < 0.5 && value > -0.5) ||
        (decimals === 1 && value < 0.05 && value > -0.05) ||
        (decimals === 2 && value < 0.005 && value > -0.005) ||
        (decimals === 3 && value < 0.0005 && value > -0.0005) ||
        (decimals === 4 && value < 0.00005 && value > -0.00005) ||
        (decimals === 5 && value < 0.000005 && value > -0.000005) ||
        (decimals === 6 && value < 0.0000005 && value > -0.0000005) ||
        (decimals === 7 && value < 0.00000005 && value > -0.00000005)))
  )
    return "";
  if (percentage) value = value * 100;
  let formatString = "(0,";
  if (decimals) {
    formatString += "0.";
    for (let i = 0; i < decimals; i++) {
      formatString += "0";
    }
  }
  formatString += ")";
  let textValue = numeral(value).format(formatString);
  if (percentage) textValue += "%";
  return currency ? `$${textValue}` : textValue;
}

export interface GetEmptyRowParams {
  templateId: string;
  templateRowsState: TemplateRowsState;
}
export function getEmptyRow({templateId, templateRowsState}: GetEmptyRowParams) {
  const row: GenericTemplateRow = {
    id: id(),
    template_id: templateId,
    type: "generic",
    display_name: "",
    formatting: {},
    name: getUniqueSlug("new row", templateRowsState.idsByName).slug,
    tags: {},
    options: {},
  };
  return row;
}
