import {store} from "@app/client-store";
import SimpleTooltip from "@components/Tooltip/Simple";
import {getQboClassToDeptIdMapping} from "@shared/data-functions/cache/cache-utilities";
import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";
import {selectQboIntegrationVendors} from "@state/integrations/slice";
import dayjs from "dayjs";

import type {CbTx, IntegrationTx, Template, TemplateRow} from "@shared/types/db";
import type useStyles from "./styles.jss";
import type {TxSort} from "./transactions";

export interface TransactionRow {
  id: string;
  date: string;
  amount: number;
  amountType: "credit" | "debit";
  description: string | null;
  vendor: string | null;
  department: string | null;
  txRef: CbTx | IntegrationTx;
  source: "Forecast" | "Snowflake" | "QuickBooks";
  rowName: string;
}

export function getTransactionsItemsForDisplay(
  row: TemplateRow,
  transactions: (CbTx | IntegrationTx | undefined)[],
  dates: {from?: string; to?: string},
  template: Template,
  sort: TxSort,
  search: string,
  expandedView: boolean,
): TransactionRow[] {
  const state = store.getState();
  const rows: TransactionRow[] = [];

  const qboClassToDeptIdMapping = getQboClassToDeptIdMapping(state.departments);
  const vendorsMapping = selectQboIntegrationVendors(state);
  const visibleDateKeys = getAllDateKeysBetween(dates.from || template.options.start, dates.to || template.options.end);

  for (const item of transactions) {
    if (!item) continue;

    const isCbTx = "row_id" in item;

    const dateKey = item.date.slice(0, 7);
    if (!visibleDateKeys.includes(dateKey)) continue;

    const departmentId =
      (isCbTx ? item.department_id : item.tags.qbo_class ? qboClassToDeptIdMapping[item.tags.qbo_class] : null) ?? null;
    const vendorId = (isCbTx ? item.tags.qbo_vendor_id : item.tags.vendor_id) ?? null;

    let rowName = row.display_name;
    const source = isCbTx ? "Forecast" : "QuickBooks";
    let description = item.description ?? null;
    const department = departmentId ? state.departments.entities[departmentId]?.display_name ?? null : null;
    const vendor = vendorId ? vendorsMapping[vendorId]?.displayName ?? null : null;
    const lowercaseSearch = search.toLowerCase();
    if (
      lowercaseSearch.length &&
      !item.amount.toString().startsWith(lowercaseSearch) &&
      !formatNumber(item.amount, false, 2).toString().startsWith(lowercaseSearch) &&
      !item.description?.toLowerCase().includes(lowercaseSearch) &&
      !vendor?.toLowerCase().includes(lowercaseSearch) &&
      !department?.toLowerCase().includes(lowercaseSearch)
    ) {
      continue;
    }

    if (isCbTx) {
      const datasource = state.datasources.entities[item.ds_id];
      if (datasource?.type === "formula") {
        const datasourceRow = state.templateRows.entities[datasource.row_id];
        if (datasourceRow) {
          rowName = datasourceRow.display_name;
        }
      }

      if (source === "Forecast" && vendor) {
        description = `${vendor} Forecast`;
      } else if (source === "Forecast" && department) {
        description = `${department} Forecast`;
      } else {
        description = `${rowName} Forecast`;
      }
    }

    rows.push({
      id: item.id,
      date: dayjs(item.date).format("MM/DD/YY"),
      amount: item.amount,
      amountType: item.amount_type ?? "debit",
      vendor,
      department,
      description,
      txRef: item,
      source,
      rowName,
    });
  }

  if (sort.order === "asc") {
    rows.sort(txSortFn(sort));
  } else {
    rows.sort((a, b) => txSortFn(sort)(b, a));
  }

  return rows;
}

const txSortFn = (sort: TxSort) => (a: TransactionRow, b: TransactionRow) => {
  const valueA =
    typeof a[sort.col.key] === "number"
      ? (a[sort.col.key] as number).toFixed(2).padStart(20, "0")
      : a[sort.col.key]?.toString() ?? "";
  const valueB =
    typeof b[sort.col.key] === "number"
      ? (b[sort.col.key] as number).toFixed(2).padStart(20, "0")
      : b[sort.col.key]?.toString() ?? "";

  return valueA.localeCompare(valueB);
};

type AvailableClasses = ReturnType<typeof useStyles>;

type Col = Readonly<{
  readonly title: string;
  readonly key: keyof TransactionRow;
  readonly showInCompactView: boolean;
  readonly cellClass?: keyof AvailableClasses;
  readonly render?: (txRow: TransactionRow, styles: ReturnType<typeof useStyles>) => JSX.Element | string;
  readonly cellTitle?: (txRow: TransactionRow) => string;
}>;
export const cols = [
  {
    title: "Date",
    key: "date",
    showInCompactView: true,
    cellClass: "date",
  },
  {
    title: "Amount",
    key: "amount",
    showInCompactView: true,
    cellClass: "amount",
    render: (txRow: TransactionRow, styles: ReturnType<typeof useStyles>) => (
      <>
        {formatNumber(Math.abs(txRow.amount), false, 2)}
        <SimpleTooltip
          popoverDivClassName={styles.creditDebitPopover}
          text={txRow.amountType === "credit" ? "Credit" : "Debit"}
          placement="right"
          delay={0}
        >
          <span
            title={txRow.amountType === "credit" ? "Credit" : "Debit"}
            className={styles[txRow.amountType === "credit" ? "creditIcon" : "debitIcon"]}
          >
            <span>{txRow.amountType === "credit" ? "C" : "D"}</span>
          </span>
        </SimpleTooltip>
      </>
    ),
  },
  {
    title: "Vendor",
    key: "vendor",
    showInCompactView: false,
    cellClass: "vendor",
  },
  {
    title: "Department",
    key: "department",
    showInCompactView: false,
    cellClass: "department",
  },
  {
    title: "Row",
    key: "rowName",
    showInCompactView: false,
    cellClass: "rowName",
  },
  {
    title: "Source",
    key: "source",
    showInCompactView: false,
    cellClass: "source",
  },
  {
    title: "Description",
    key: "description",
    showInCompactView: true,
    cellClass: "description",
    cellTitle: (txRow: TransactionRow) =>
      (txRow.txRef.tx_type !== "accounting" ? txRow.description : txRow.description) ?? "",
    render: (txRow: TransactionRow, styles: ReturnType<typeof useStyles>) =>
      (txRow.txRef.tx_type !== "accounting" ? txRow.description : txRow.description) ?? "",
  },
] satisfies Col[];
