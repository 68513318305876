import colors from "@app/styles/colors";
import {globalStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  leftNavContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.ui.black,
    height: "100%",
    width: `calc(${globalStyles.leftNavWidth}px + ${navigator.platform.includes("Win") ? 17 : 0}px)`,
    overflow: "hidden",
    overflowY: "auto",

    ".earlier-version &": {
      backgroundColor: colors.ui.darkBlue01,
    },

    "& ul": {
      margin: 0,
      padding: 0,
      listStyleType: "none",
    },
  },
  svgLogo: {
    width: 150,
    maxHeight: 30,
  },
  stagingBanner: {
    top: 0,
    left: 0,
    right: 0,
    height: 25,
    background: "red",
    fontWeight: 600,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
  },
  collapsed: {
    width: 0,

    "& $leftNavHeader, ul": {
      display: "none",
    },
  },
  hamburger: {
    position: "absolute",
    right: -46,
    top: 9,
    zIndex: 3,
  },
  leftNavHeader: {
    paddingTop: 30,
    display: "flex",
    alignItems: "center",
    marginBottom: 26,
    paddingRight: 25,
    paddingLeft: 8,
  },
  logoContainer: {
    flex: 1,
  },
  collapseIconContainer: {
    marginTop: -5,
  },
  mainNav: {
    flex: 1,
  },
  iconAndText: {
    alignItems: "center",
    flexDirection: "row",
    fontSize: 12,
    display: "flex",
    fontWeight: 500,

    "& > span": {
      lineHeight: 13,
    },
  },
  leftNavItem: {
    backgroundImage: "url('/assets/images/ui/nav-item-bullet.svg')",
    color: colors.text.lightBlue04,
    lineHeight: 25,
    padding: [10.5, 17, 5.5, 24],
    textAlign: "left",
    userSelect: "none",

    "&:hover": {
      borderRight: `2px solid ${colors.primary.gold}`,
      // cursor: "pointer",
      backgroundColor: "#563818",

      "& $iconAndText": {
        color: colors.primary.gold,
        fontWeight: 500,
      },

      ...getSvgColorStyles(colors.primary.gold),
    },

    "& svg": {
      maxWidth: 20,
      marginRight: 14,

      "& path": {
        fill: colors.secondary.lightGreen01,
      },
    },
  },
  leftNavItemWithChildren: {
    alignItems: "flex-start",
    composes: "$leftNavItem",
    flexDirection: "column",
  },
  activeItem: {
    borderRight: `2px solid ${colors.primary.gold}`,
    "& $iconAndText, &:hover $iconAndText": {
      color: colors.primary.gold,
      fontWeight: 600,
    },

    ...getSvgColorStyles(colors.primary.gold),
  },
  secondLevel: {
    paddingLeft: 30 + 27,
  },
  icon: {
    marginRight: 15,
    width: 16,
    height: 16,
    overflow: "hidden",

    "& svg": {
      width: 16,
      height: 16,
    },
  },
  docsLink: {
    height: "auto",
  },
  questionMarkIconWrapper: {
    marginRight: 10,
    width: 15,
    height: 15,
    marginBottom: 2,

    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
});
