import Popover from "@components/Popover";
import RoundButton from "@components/RoundButton";
import Tooltip from "@components/Tooltip";
import tooltipTexts from "@components/Tooltip/texts";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {GetSubstitutions, TooltipTexts} from "@components/Tooltip/texts";

type BaseProps = {
  label: string;
  subtext?: string;
  className?: string;
  children: React.ReactNode;
  tooltipIconAlignRight?: boolean;
};

function FormElementBuilder<T extends TooltipTexts>(_tooltipTextsScoped: T) {
  function FormElement<
    K extends keyof T,
    Subs extends GetSubstitutions<T, K, "mainText"> | GetSubstitutions<T, K, "subsection">,
  >(
    props: [Subs] extends [never] ? BaseProps & {tooltip: K} : BaseProps & {tooltip: K; tooltipVariables: Subs},
  ): JSX.Element;
  function FormElement(props: BaseProps): JSX.Element;
  function FormElement<
    K extends keyof T,
    Subs extends GetSubstitutions<T, K, "mainText"> | GetSubstitutions<T, K, "subsection">,
  >(
    props: [Subs] extends [never] ? BaseProps & {tooltip?: K} : BaseProps & {tooltip?: K; tooltipVariables?: Subs},
  ): JSX.Element {
    const styles = useStyles();

    const classes = clsx(styles.formElementMain, props.className);
    let tooltip: null | JSX.Element = null;
    if (props.tooltip) {
      if ("tooltipVariables" in props) {
        // TODO: This is valid but Typescript doesn't let it pass
        // @ts-ignore
        tooltip = <Tooltip id={props.tooltip} variables={props.tooltipVariables as Subs} />;
      } else {
        // @ts-ignore
        tooltip = <Tooltip id={props.tooltip} />;
      }
    }

    return (
      <div className={classes}>
        <div className={styles.firstRow}>
          <label className={props.tooltipIconAlignRight ? styles.labelFlex : ""}>
            {props.label}
            {props.subtext ? <span className={styles.subtext}>{props.subtext}</span> : null}
          </label>
          {tooltip ? (
            <Popover position={["right", "left"]} content={tooltip} align="start" arrow wrapperClass={styles.popover}>
              <div className={styles.tooltipIcon}>
                <RoundButton mainColor="grey" compact containerSize={15} icon="questionMark" enableCssStates />
              </div>
            </Popover>
          ) : null}
        </div>
        <div className={styles.secondRow}>{props.children}</div>
      </div>
    );
  }

  return FormElement;
}

const fn = FormElementBuilder(tooltipTexts);
export default fn;
