import {apiWithContract} from "@shared/lib/fetch";

import type {AppAlert} from "@shared/types/alerts";
import type {ApiEndpoints} from "@state/store";
import type {AlertsApiContracts} from "./api.contracts";

const endpoints = {
  fetchAll(_arg: undefined, thunkAPI) {
    return apiWithContract<AlertsApiContracts["get"]>(thunkAPI, {
      method: "get",
      path: `alerts`,
      query: null,
      body: null,
    });
  },
  upsert(alerts: AppAlert[], thunkAPI) {
    return apiWithContract<AlertsApiContracts["upsert"]>(thunkAPI, {
      method: "put",
      path: `alerts`,
      body: alerts,
      query: null,
    });
  },
  remove(ids: string[], thunkAPI) {
    return apiWithContract<AlertsApiContracts["delete"]>(thunkAPI, {
      method: "del",
      path: `alerts`,
      body: ids,
      query: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
