import {createSelector} from "@reduxjs/toolkit";

import type {RootState} from "@shared/state/store";

export const selectTemplateRowsState = (state: RootState) => state.templateRows;
export const selectTemplateRows = selectTemplateRowsState;
export const selectAllRowIdsByParentId = (state: RootState) => state.templateRows.idsByParentRowId;
export const selectRowIdsByTemplateId = (id: string) => (state: RootState) =>
  state.templateRows.idsByTemplateId[id] ?? [];

export const selectRowIdsByParentId = (id: string) => (state: RootState) =>
  state.templateRows.idsByParentRowId[id] ?? null;
export const selectTemplateRowById = createSelector(
  (state: RootState) => state.templateRows.entities,
  (_state: RootState, rowId: string | null) => rowId,
  (rowEntities, rowId) => (!rowId ? null : rowEntities[rowId] || null),
);
export const selectRowIdsByParentIdMapping = (state: RootState) => state.templateRows.idsByParentRowId;

export const selectHasChildRows = createSelector(
  (state: RootState) => state.templateRows,
  (_state: RootState, rowId: string) => rowId,
  (templateRowsState, rowId) => !!templateRowsState.idsByParentRowId[rowId]?.length,
);

export const selectRowByName = createSelector(
  (state: RootState) => state.templateRows,
  (_: RootState, name: string) => name,
  (templateRowsState, name) => templateRowsState.entities[templateRowsState.idsByName[name] || ""] || null,
);
