import {apiWithContract} from "@shared/lib/fetch";

import type {EntityId} from "@reduxjs/toolkit";
import type {ApiEndpoints} from "@shared/state/store";
import type {Report, ReportItem} from "@shared/types/reporting";
import type {ReportsApiContracts} from "./contracts/reporting.contracts";

const endpoints = {
  fetchAll(_arg, thunkAPI) {
    return apiWithContract<ReportsApiContracts["get"]>(thunkAPI, {
      method: "get",
      path: `reports`,
      body: null,
      query: null,
    });
  },
  fetchReportReportItems(id: EntityId, thunkAPI) {
    return apiWithContract<ReportsApiContracts["getReportReportItems"]>(thunkAPI, {
      method: "get",
      path: `reports/${id}/items`,
      body: null,
      query: null,
    });
  },
  fetchReportCache(id: EntityId, thunkAPI) {
    return apiWithContract<ReportsApiContracts["getReportCache"]>(thunkAPI, {
      method: "get",
      path: `reports/${id}/cache`,
      body: null,
      query: null,
    });
  },
  upsertReport(report: Report, thunkAPI) {
    return apiWithContract<ReportsApiContracts["upsertReport"]>(thunkAPI, {
      method: "put",
      path: `reports/${report.id}`,
      query: null,
      body: report,
    });
  },
  deleteReport(id: EntityId, thunkAPI) {
    return apiWithContract<ReportsApiContracts["deleteReport"]>(thunkAPI, {
      method: "del",
      path: `reports/${id}`,
      query: null,
      body: null,
    });
  },
  upsertReportItem({reportItem, refetchCache}: {reportItem: ReportItem; refetchCache: boolean}, thunkAPI) {
    return apiWithContract<ReportsApiContracts["upsertItem"]>(thunkAPI, {
      method: "put",
      path: `reports/items/${reportItem.id}`,
      query: {refetchCache: refetchCache === true ? "true" : undefined},
      body: reportItem,
    });
  },
  fetchReportItemMontlyCache(reportItemId: string, thunkAPI) {
    return apiWithContract<ReportsApiContracts["fetchCacheForReportItem"]>(thunkAPI, {
      method: "get",
      path: `reports/items/${reportItemId}/cache`,
      query: null,
      body: null,
    });
  },
  updateMultipleItemLayouts(layouts: ReportItem["layout"][], thunkAPI) {
    return apiWithContract<ReportsApiContracts["updateLayouts"]>(thunkAPI, {
      method: "put",
      path: `reports/update-layouts`,
      query: null,
      body: layouts,
    });
  },
  deleteReportItem(id: string, thunkAPI) {
    return apiWithContract<ReportsApiContracts["deleteItem"]>(thunkAPI, {
      method: "del",
      path: `reports/items/${id}`,
      query: null,
      body: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
