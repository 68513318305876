import {current, isDraft, type Dictionary, type EntityAdapter, type EntityId, type EntityState} from "@reduxjs/toolkit";
import {capitalizeFirstLetter, toCamelCase} from "@shared/lib/misc";

import type {
  CacheProjection,
  CacheProjectionWithKeyProvider,
  MergedCacheProjectionTypes,
} from "./entity-adapter-with-cache-projections";

function deleteByForLoop(array: string[], element: string | number) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === element) {
      array.splice(i, 1);
      break;
    }
  }
}

export function getUpdatedUtilityFunctions<
  E,
  K,
  N,
  S extends EntityState<E>,
  P extends ({name?: N; keyToProject: K | K[]} & CacheProjection<E>) | ({name: N} & CacheProjectionWithKeyProvider<E>),
>(entityAdapter: EntityAdapter<E>, projections: readonly P[]) {
  const originalFns = {
    addMany: entityAdapter.addMany,
    addOne: entityAdapter.addOne,
    removeAll: entityAdapter.removeAll,
    removeMany: entityAdapter.removeMany,
    removeOne: entityAdapter.removeOne,
    upsertMany: entityAdapter.upsertMany,
    upsertOne: entityAdapter.upsertOne,
    setAll: entityAdapter.setAll,
  };

  const projectionsWithResolvedKey = projections.map((proj) => ({
    ...proj,
    key:
      proj.name ||
      (typeof proj.keyToProject === "string" ? getFormattedKey("idsBy", proj.keyToProject) : "UNNAMED_PROJECTION"),
  }));

  type PayloadOrAction<EntityType> = EntityType | {payload: EntityType; type: string};
  type NewFns = {
    addOne: (state: S, entity: PayloadOrAction<E>, skipProjectionUpdates?: boolean) => S;
    addMany: (state: S, entities: PayloadOrAction<readonly E[]>, skipProjectionUpdates?: boolean) => S;
    removeOne: (state: S, entityId: PayloadOrAction<EntityId>, skipProjectionUpdates?: boolean) => S;
    removeMany: (state: S, entityIds: PayloadOrAction<readonly EntityId[]>, skipProjectionUpdates?: boolean) => S;
    upsertOne: (state: S, entity: PayloadOrAction<E>, skipProjectionUpdates?: boolean) => S;
    upsertMany: (state: S, entities: PayloadOrAction<readonly E[]>, skipProjectionUpdates?: boolean) => S;
    removeAll: (state: S, skipProjectionUpdates?: boolean) => S;
    setAll: (state: S, entities: PayloadOrAction<readonly E[]>, skipProjectionUpdates?: boolean) => S;
  };

  function getPayload<T>(entitiesOrActionWithPayload: PayloadOrAction<T>): T {
    if (
      !(
        entitiesOrActionWithPayload &&
        typeof entitiesOrActionWithPayload === "object" &&
        "payload" in entitiesOrActionWithPayload &&
        "type" in entitiesOrActionWithPayload
      ) &&
      isDraft(entitiesOrActionWithPayload)
    ) {
      console.log(`entityAdapter method called with draft state`, current(entitiesOrActionWithPayload));
    }
    const entities =
      entitiesOrActionWithPayload &&
      typeof entitiesOrActionWithPayload === "object" &&
      "payload" in entitiesOrActionWithPayload &&
      "type" in entitiesOrActionWithPayload
        ? entitiesOrActionWithPayload.payload
        : isDraft(entitiesOrActionWithPayload)
        ? current(entitiesOrActionWithPayload)
        : entitiesOrActionWithPayload;

    return entities;
  }

  const baseNewFns = {
    addMany(state, entitiesOrActionWithPayload, skipProjectionUpdates = false) {
      const untypedState = state as any;

      const entities = getPayload(entitiesOrActionWithPayload);

      if (!entities) return untypedState as S;

      untypedState.entities ||= {} as Dictionary<E>;
      untypedState.ids ||= [] as string[];

      for (const entity of entities) {
        const entityId = entityAdapter.selectId(entity);
        untypedState.entities[entityId] = entity;
        untypedState.ids.push(entityId);

        if (!skipProjectionUpdates) {
          let cacheKeys;
          for (const proj of projectionsWithResolvedKey) {
            cacheKeys = getCacheKey(proj, entity);
            if (!cacheKeys) continue;
            for (const cacheKey of cacheKeys) {
              untypedState[proj.key] ||= {};
              if (proj.type === "one-to-many") {
                untypedState[proj.key][cacheKey] ||= [];
                untypedState[proj.key][cacheKey].push(entityId);
              } else if (proj.type === "one-to-one") {
                untypedState[proj.key][cacheKey] = entityId;
              }
            }
          }
        }
      }

      return untypedState as S;
    },
    removeMany(state, entitiesOrActionWithPayload, skipProjectionUpdates = false) {
      const untypedState = state as any;

      const entityIds = getPayload(entitiesOrActionWithPayload);

      if (!entityIds.length) return untypedState as S;

      // Cache projections
      if (!skipProjectionUpdates) {
        let cacheKeys;
        for (const entityId of entityIds) {
          const entity = state.entities[entityId];
          if (!entity) continue;
          for (const proj of projectionsWithResolvedKey) {
            if (!untypedState[proj.key]) continue;
            cacheKeys = getCacheKey(proj, entity);
            if (!cacheKeys) continue;
            for (const cacheKey of cacheKeys) {
              if (!untypedState[proj.key][cacheKey]) continue;
              if (proj.type === "one-to-many") {
                deleteByForLoop(untypedState[proj.key][cacheKey], entityId);
              } else if (proj.type === "one-to-one") {
                delete untypedState[proj.key][cacheKey];
              }
            }
          }
        }
      }

      // Basic update (needs to be done after)
      for (const entityId of entityIds) {
        if (untypedState.entities[entityId]) {
          delete untypedState.entities[entityId];
        }
      }

      if (entityIds.length > 40) {
        untypedState.ids = Object.keys(untypedState.entities);
      } else {
        for (let i = 0; i < untypedState.ids.length; i++) {
          if (entityIds.includes(untypedState.ids[i])) {
            untypedState.ids.splice(i, 1);
            i--;
          }
        }
      }

      return untypedState as S;
    },
    removeAll(state, skipProjectionUpdates = false) {
      const untypedState = state as any;
      if (!skipProjectionUpdates) {
        for (const {key} of projectionsWithResolvedKey) {
          untypedState[key] = {};
        }
      }
      return originalFns.removeAll(untypedState as S);
    },
    upsertMany(state, entitiesOrActionWithPayload, skipProjectionUpdates = false) {
      const untypedState = state as any;

      const entities = getPayload(entitiesOrActionWithPayload);

      // Cache projections
      if (!skipProjectionUpdates) {
        let cacheKeys;
        let existingCacheKeys;

        for (const proj of projectionsWithResolvedKey) {
          if (!untypedState[proj.key]) untypedState[proj.key] = {};
          for (const entity of entities) {
            const entityId = entityAdapter.selectId(entity);

            const entityAlreadyExists = !!state.entities[entityId];
            existingCacheKeys = entityAlreadyExists ? getCacheKey(proj, state.entities[entityId] as E) : [];
            cacheKeys = getCacheKey(proj, entity);
            if (!cacheKeys) continue;
            for (const cacheKey of cacheKeys) {
              if (proj.type === "one-to-many") {
                if (!untypedState[proj.key][cacheKey]) untypedState[proj.key][cacheKey] = [];
                if (!untypedState[proj.key][cacheKey].includes(entityId)) {
                  untypedState[proj.key][cacheKey].push(entityId);
                }
              } else if (proj.type === "one-to-one") {
                untypedState[proj.key][cacheKey] = entityId;
              }
            }
            // Take care of invalidated cache keys
            if (!existingCacheKeys) continue;
            for (const existingCacheKey of existingCacheKeys) {
              if (!cacheKeys.includes(existingCacheKey as string & E[keyof E & string])) {
                if (proj.type === "one-to-many") {
                  deleteByForLoop(untypedState[proj.key][existingCacheKey], entityId);
                } else if (proj.type === "one-to-one" && untypedState[proj.key][existingCacheKey]) {
                  delete untypedState[proj.key][existingCacheKey];
                }
              }
            }
          }
        }
      }

      // Basic update (done after to be able to compare old projections with new ones)
      for (const entity of entities) {
        const entityId = entityAdapter.selectId(entity);
        if (!untypedState.entities[entityId]) {
          untypedState.entities[entityId] = entity;
          untypedState.ids.push(entityId);
        } else {
          untypedState.entities[entityId] = {...untypedState.entities[entityId], ...entity};
        }
      }

      return untypedState as S;
    },
  } satisfies Partial<NewFns>;

  const newFns: NewFns = {
    ...baseNewFns,
    addOne: (state, entity, skipProjectionUpdates = false) =>
      newFns.addMany(state, [getPayload(entity)], skipProjectionUpdates),
    upsertOne: (state, entity, skipProjectionUpdates = false) =>
      newFns.upsertMany(state, [getPayload(entity)], skipProjectionUpdates),
    removeOne: (state, entityId, skipProjectionUpdates = false) =>
      newFns.removeMany(state, [getPayload(entityId)], skipProjectionUpdates),
    setAll: (state, entities, skipProjectionUpdates = false) => {
      if (state.ids.length && newFns.removeAll) {
        newFns.removeAll(state, skipProjectionUpdates);
      }
      return newFns.addMany(state, entities, skipProjectionUpdates);
    },
  };

  return newFns;
}

export const getFormattedKey = (prefix: string, key: string) => `${prefix}${capitalizeFirstLetter(toCamelCase(key))}`;

const getCacheKey = <P extends MergedCacheProjectionTypes<E>, E>(
  projection: P,
  entity: E,
): string[] | null | undefined => {
  if (typeof projection.keyToProject === "function") {
    const resolved = (projection as CacheProjectionWithKeyProvider<E>).keyToProject(entity);
    if (projection.ignoreNulls && !resolved) return [];
    return typeof resolved === "string" ? [resolved] : resolved;
  } else {
    return entity[projection.keyToProject] || !projection.ignoreNulls
      ? ([entity[projection.keyToProject] ?? "null"] as string[])
      : [];
  }
};
