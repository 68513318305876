import colors from "@app/styles/colors";
import color from "color";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  tooltipMain: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 24,
    padding: 20,
    maxWidth: 270,
    marginLeft: -6,
    "& b": {fontWeight: 700},
    "& p:not(:last-child)": {
      paddingBottom: 10,
    },
  },
  mainText: {},
  highlight: {
    fontWeight: 700,
    color: colors.primary.gold,
  },
  subsection: {
    borderRadius: 5,
    backgroundColor: color(colors.ui.lightBlue08).alpha(0.7).rgb().string(),
    padding: [9, 16],
    marginTop: 13,
    color: colors.text.darkBlue02,
  },
});
