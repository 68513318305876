import colors from "@app/styles/colors";
import {baseInputStyles} from "@app/styles/general-ui";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

const sharedIconButtonStyles = {
  position: "absolute",
  top: 5,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...getSvgColorStyles(colors.ui.lightBlue01),
};

export default createUseStyles({
  textInputMain: {
    display: "flex",
    alignItems: "center",
    position: "relative",

    "& input, & textarea": {
      extend: baseInputStyles,
      width: "100%",
    },

    "& textarea": {
      height: "100%",
      maxHeight: "100%",
      paddingTop: 8,
    },
  },
  disabled: {
    "& input, & textarea": {
      backgroundColor: "transparent",
      color: colors.ui.lightBlue02,
    },
  },
  rightButtonWrapper: {
    paddingRight: 6,
  },
  hasPlusMinusButtons: {
    "& input, & $absoluteWrapper": {
      pointerEvents: "none",
      paddingLeft: 30,
      paddingRight: 30,
      textAlign: "center",
    },
  },
  plus: {
    extend: sharedIconButtonStyles,
    right: 5,
    paddingLeft: 5,
    borderLeft: [1, "solid", colors.ui.lightBlue05],
  },
  minus: {
    extend: sharedIconButtonStyles,
    left: 5,
    paddingRight: 5,
    borderRight: [1, "solid", colors.ui.lightBlue05],
  },
  absoluteWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
  },
  shadowValue: {
    extend: baseInputStyles,
    color: "transparent",
    background: "transparent",
    border: 0,
    boxShadow: "none",
    userSelect: "none",
  },
});
