import {apiWithContract} from "@shared/lib/fetch";

import type {EntityId} from "@reduxjs/toolkit";
import type {ApiEndpoints} from "@shared/state/store";
import type {TemplateRow} from "@shared/types/db";
import type {TemplateRowsApiContracts} from "./contracts/template-rows.contracts";

const endpoints = {
  async upsert(rows: TemplateRow[], thunkAPI) {
    if (rows.length === 1) {
      const result = await apiWithContract<TemplateRowsApiContracts["upsert"]>(thunkAPI, {
        method: "put",
        path: `template-rows/${rows[0].id}`,
        body: rows[0],
        query: null,
      });

      return [result];
    } else {
      return apiWithContract<TemplateRowsApiContracts["batchUpsert"]>(thunkAPI, {
        method: "put",
        path: `template-rows/batch-upsert`,
        body: rows,
        query: null,
      });
    }
  },
  delete(id: EntityId | EntityId[], thunkAPI) {
    if (!Array.isArray(id)) {
      return apiWithContract<TemplateRowsApiContracts["deleteOne"]>(thunkAPI, {
        method: "del",
        path: `template-rows/${id}`,
        query: null,
        body: null,
      });
    } else {
      return apiWithContract<TemplateRowsApiContracts["batchDelete"]>(thunkAPI, {
        method: "del",
        path: `template-rows/batch-delete`,
        body: id,
        query: null,
      });
    }
  },
} satisfies ApiEndpoints;

export default endpoints;
