import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@shared/state/store";
import type {AuthCredentials} from "@shared/types/api/auth";
import type {User} from "@shared/types/db";

const endpoints = {
  fetchSession(_args, thunkAPI) {
    return api.get<User>(thunkAPI, "session");
  },
  logout(_args: void, thunkAPI) {
    return api.del<null>(thunkAPI, `session`);
  },
  async authLocal(creds: AuthCredentials, thunkAPI) {
    const result = await api.post<User>(thunkAPI, `auth/local`, creds);
    return result;
  },
  acceptInvite({inviteCode, password}: {inviteCode: string; password: string}, thunkAPI) {
    return api.put<User>(thunkAPI, `auth/local/accept-invite`, {inviteCode, password});
  },
} satisfies ApiEndpoints;

export default endpoints;
