import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@state/store";

const endpoints = {
  fetchLastMessageId(_arg: void, thunkAPI) {
    return api.get<string | null>(thunkAPI, `session/last-message-id`);
  },
} satisfies ApiEndpoints;

export default endpoints;
