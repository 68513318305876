import {updateGlobalState} from "@state/global/slice";
import {projKey} from "@state/utils";
import memoizeOne from "memoize-one";

import {getAmountForDisplay} from "../transactions";
import {
  calculateBalanceForDateKey,
  calculateTotalForDateKey,
  getDepartmentIdForCbTx,
  getQboClassToDeptIdMapping,
  getStringKeyFromParsedKey,
  setValuesInCache,
} from "./cache-utilities";
import {buildDependencyCache} from "./dependency-cache";

import type {EntityId} from "@reduxjs/toolkit";
import type {DepartmentsState, TemplateRowsState} from "@state/entity-adapters";
import type {BasicStore} from "@state/store";

export function generateAlgoValuesCache(scenarioId: EntityId, store: BasicStore) {
  // time("generateDeptVendorMonthlyCache", "Generating the whole cache, department and vendor level");
  const state = store.getState();
  const cache: Record<string, number> = {};
  const allDateKeys: Record<string, true> = {};
  const processedTxIds: Record<string, true> = {};

  let dependencyCache = state.global.dependencyCache[scenarioId];
  if (!dependencyCache) {
    dependencyCache = buildDependencyCache({
      scenarioId,
      state,
      rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
    });
    store.dispatch(
      updateGlobalState({
        dependencyCache: {...state.global.dependencyCache, [scenarioId]: dependencyCache},
      }),
    );
  }

  const qboClassToDeptIdMapping = getQboClassToDeptIdMapping(state.departments);

  const rowIdToListOfParentsMapping = getRowIdToListOfParentsMapping(state.templateRows);
  const deptIdToListOfParentsMapping = getDeptIdToListOfParentsMapping(state.departments);

  for (const dependencyCacheKey of dependencyCache.orderedCacheKeys) {
    const keyComponents = dependencyCache.fullList[dependencyCacheKey];

    if (!keyComponents) {
      console.log(`WARNING: dependency cache key ${dependencyCacheKey} not found in dependency cache`);
    }

    const row = state.templateRows.entities[keyComponents.rowId];
    if (!row) continue;

    const isParentRow = !!state.templateRows.idsByParentRowId[row.id]?.length;

    if (keyComponents.total) {
      // If this is a total cache key, since the dependency cache is ordered
      // we can assume its dependencies have been calculated already
      const totalValue = calculateTotalForDateKey(
        state,
        cache,
        keyComponents.rowId,
        keyComponents.departmentId,
        scenarioId,
        keyComponents.dateKey,
        keyComponents.balance === true,
      );

      const sanitizedValue = totalValue && (totalValue > 0.0000001 || totalValue < -0.0000001) ? totalValue : 0;
      if (sanitizedValue) {
        allDateKeys[keyComponents.dateKey] = true;
        const totalCacheKey = getStringKeyFromParsedKey(keyComponents);
        cache[totalCacheKey] = sanitizedValue;
        //   if (row.name === "travel" && keyComponents.dateKey === "2012-12") {
        //     const humanReadableKey = makeDependencyKeyHumanReadable(totalCacheKey, state, false, true);
        //     logDependenciesTreeForKey(dependencyCache, humanReadableKey, state, false, cache);
        //   }
      }
      continue;
    }

    if (keyComponents.balance) {
      // If this is a total cache key, since the dependency cache is ordered
      // we can assume its dependencies have been calculated already

      const balanceValue = calculateBalanceForDateKey(
        cache,
        keyComponents.rowId,
        keyComponents.departmentId,
        keyComponents.vendor,
        scenarioId,
        keyComponents.dateKey,
      );

      const sanitizedValue = balanceValue && (balanceValue > 0.0000001 || balanceValue < -0.0000001) ? balanceValue : 0;
      if (sanitizedValue) {
        allDateKeys[keyComponents.dateKey] = true;
        const balanceCacheKey = getStringKeyFromParsedKey(keyComponents);
        cache[balanceCacheKey] = sanitizedValue;
      }
      continue;
    }

    const rowDateKeyCbTxIds =
      state.cbTx.idsByRowIdDateKey[projKey(keyComponents.rowId, keyComponents.scenarioId, keyComponents.dateKey)];
    for (const id of rowDateKeyCbTxIds ?? []) {
      const cbTx = state.cbTx.entities[id];
      if (!cbTx) continue;

      // Set the resolved department id fs or that tx
      // If the dependency cache key isn't scoped to a department, set it to null as we don't care about the department
      const cbTxDeptId = getDepartmentIdForCbTx(cbTx, qboClassToDeptIdMapping);
      const cacheKeyDepartmentId = keyComponents.departmentId ? cbTxDeptId : null;

      const isParentDept = !!cbTxDeptId && !!state.departments.idsByParent[cbTxDeptId]?.length;

      // Set the vendor for that tx
      // If the dependency cache key isn't scoped to a vendor, set it to null as we don't care about the vendor
      const txVendor = cbTx.tags.qbo_vendor_id && keyComponents.vendor ? cbTx.tags.qbo_vendor_id?.toLowerCase() : null;

      // If this is a department scoped dependency, only keep that departments values
      if (keyComponents.departmentId && cacheKeyDepartmentId !== keyComponents.departmentId) continue;

      // If this is a vendor scoped dependency, only keep that vendors values
      // if (keyComponents.vendor === "no_vendor") debugger;
      const isNoVendorForecast = keyComponents.vendor === "no_vendor";
      if (
        keyComponents.vendor &&
        ((keyComponents.vendor === "no_vendor" && txVendor) ||
          (keyComponents.vendor !== "no_vendor" && txVendor !== keyComponents.vendor))
      )
        continue;

      const value = getAmountForDisplay({
        amount: cbTx.amount,
        classification: row.type === "account" ? row.options.classification : undefined,
        reverseSign: !!((row.type === "account" || row.type === "generic") && row.options.reverseSign),
        type: cbTx.amount_type ?? null,
      });

      const sanitizedValue = value && (value > 0.0000001 || value < -0.0000001) ? value : 0;
      allDateKeys[keyComponents.dateKey] = true;

      if (sanitizedValue) {
        setValuesInCache({
          cache,
          keyFilter: dependencyCache.fullList,
          keysToSkip: null,
          value: sanitizedValue,
          rowId: cbTx.row_id,
          departmentIdToTagWith: cacheKeyDepartmentId,
          txDepartmentId: cbTxDeptId,
          vendor: txVendor ?? (isNoVendorForecast ? "no_vendor" : null),
          scenarioId: cbTx.scenario_id,
          dateKey: keyComponents.dateKey,
          isParentRow,
          isParentDept,
          rowIdToListOfParentsMapping: deptIdToListOfParentsMapping,
          deptIdToListOfParentsMapping: rowIdToListOfParentsMapping,
          balance: false,
          omitTotal: !!processedTxIds[id],
        });
        processedTxIds[id] = true;
      }
    }
  }

  // timeEnd("generateDeptVendorMonthlyCache", "Generating the whole cache, department and vendor level");
  // console.log("Cache: ", Object.keys(cache).length);
  return cache;
}

export const getRowIdToListOfParentsMapping = memoizeOne((templateRowsState: TemplateRowsState) => {
  const mapping: Record<string, string[]> = {};

  function recursive(id: EntityId, parentIds: string[] = []) {
    const templateRow = templateRowsState.entities[id];
    if (!templateRow?.parent_row_id) return;

    parentIds.push(templateRow.parent_row_id);

    // Shortcut if the parents have already been computed for that branch
    if (mapping[templateRow.parent_row_id]) {
      parentIds.push(...mapping[templateRow.parent_row_id]);
    } else {
      recursive(templateRow.parent_row_id, parentIds);
    }

    // If this row is mirrored somewhere, mark those parents as parents of this row as well
    for (const mirrorRowId of templateRowsState.idsByMirrorOf[templateRow.id] ?? []) {
      const mirrorRow = templateRowsState.entities[mirrorRowId];
      if (mirrorRow?.parent_row_id) {
        parentIds.push(mirrorRow.parent_row_id);

        if (mapping[mirrorRow.parent_row_id]) {
          parentIds.push(...mapping[mirrorRow.parent_row_id]);
        } else {
          recursive(mirrorRow.parent_row_id, parentIds);
        }
      }
    }

    return parentIds;
  }

  for (const id of templateRowsState.ids) {
    if (!id) continue;
    const parentsList = recursive(id);
    if (parentsList) mapping[id] = parentsList;
  }

  return mapping;
});

export const getDeptIdToListOfParentsMapping = memoizeOne((departmentsState: DepartmentsState) => {
  const mapping: Record<string, string[]> = {};

  function recursive(id: EntityId, parentIds: string[] = []) {
    const department = departmentsState.entities[id];
    if (!department?.parent) return;

    parentIds.push(department.parent);

    // Shortcut if the parents have already been computed for that branch
    if (mapping[department.parent]) {
      parentIds.push(...mapping[department.parent]);
    } else {
      recursive(department.parent, parentIds);
    }

    return parentIds;
  }

  for (const id of departmentsState.ids) {
    if (!id) continue;
    const parentsList = recursive(id);
    if (parentsList) mapping[id] = parentsList;
  }

  return mapping;
});

export const getDeptIdToChildrenMapping = memoizeOne(
  (departmentsState: DepartmentsState): Record<EntityId, EntityId[]> => {
    const mapping: Record<EntityId, EntityId[]> = {};

    function findChildren(parentId: EntityId): EntityId[] {
      // Filter departments to find children of the current parent
      return departmentsState.ids.filter((id) => {
        const department = departmentsState.entities[id];
        return department && department.parent === parentId;
      });
    }

    function recursive(id: EntityId): EntityId[] {
      let childIds: EntityId[] = [];

      // Get direct children
      const directChildren = findChildren(id);
      childIds = [...directChildren];

      // Recursively get children of the children
      for (const childId of directChildren) {
        childIds.push(...recursive(childId));
      }

      return childIds;
    }

    for (const id of departmentsState.ids) {
      if (!id) continue;
      if (!mapping[id]) {
        // Only compute if not already done
        mapping[id] = recursive(id);
      }
    }

    return mapping;
  },
);
