import type {TemplateRowsState} from "@shared/state/entity-adapters";
import type {AccountClassification, TemplateRow} from "@shared/types/db";

// Order of the impacted rows. First item is the immediate impact of the tx. Other items are cash impacts.
// Each item has a left side, which is always the debit, and one or more right sides (the credits)
// If the provided value is negative, those will be reversed (the debit becomes the credit and vice-versa)
// The provided value always ends up being a positive number on the txItem
export function getTransactionRows(
  templateRowsState: Pick<TemplateRowsState, "entities" | "idsByName">,
  row: TemplateRow,
  cashImpactDate: string | null,
  // additionalOptions?: ParseFormulaAdditionalOptions,
) {
  let otherSideOfSourceTxItem: TemplateRow;

  if (row.type === "account" && row.options.destinationRow) {
    otherSideOfSourceTxItem =
      templateRowsState.entities[templateRowsState.idsByName[row.options?.destinationRow] || ""]!;
  } else {
    otherSideOfSourceTxItem = templateRowsState.entities[templateRowsState.idsByName["checking"] || ""]!;
  }

  let rows: TemplateRow[][] = [[]];

  // if (additionalOptions?.txDebitRowId && additionalOptions.txCreditRowId) {
  //   // TODO: this is for hiring plan. make it work with normal account options
  //   const debitRow = templateRowsState.entities[additionalOptions.txDebitRowId];
  //   const creditRow = templateRowsState.entities[additionalOptions.txCreditRowId];
  //   if (debitRow && creditRow) {
  //     rows = [[debitRow, creditRow]];
  //   }
  //   if (additionalOptions.cashImpactCreditRowId && additionalOptions.cashImpactDebitRowId) {
  //     const debitRow = templateRowsState.entities[additionalOptions.cashImpactDebitRowId];
  //     const creditRow = templateRowsState.entities[additionalOptions.cashImpactCreditRowId];
  //     if (debitRow && creditRow) {
  //       rows = [[debitRow, creditRow]];
  //     }
  //   }
  // } else {
  rows = [[otherSideOfSourceTxItem, row]];
  if (cashImpactDate) {
    // TODO: this is only used in tests for now - cash impact will be handled differently, on a per-formula basis ultimately
    rows = [
      [otherSideOfSourceTxItem, row],
      [templateRowsState.entities[templateRowsState.idsByName["checking"] || ""]!, otherSideOfSourceTxItem],
    ];
  } else {
    rows = [[otherSideOfSourceTxItem, row]];
  }
  if (row.type === "account") {
    switch (<keyof typeof AccountClassification>row.options.classification) {
      case "revenue": {
        // Default value for rows
        break;
      }
      case "expense":
        {
          if (cashImpactDate) {
            rows = [
              [row, otherSideOfSourceTxItem],
              [otherSideOfSourceTxItem, templateRowsState.entities[templateRowsState.idsByName["checking"] || ""]!],
            ];
          } else {
            rows = [[row, otherSideOfSourceTxItem]];
          }
        }
        break;

      case "asset": {
        rows = [[otherSideOfSourceTxItem, row]];
        break;
      }
      case "liability":
      case "equity":
      default: {
        rows = [[row, otherSideOfSourceTxItem]];
      }
    }

    if (!rows[0][0] || !rows[0][1] || (cashImpactDate && !rows[1][0]) || (cashImpactDate && !rows[1][1])) {
      console.error("WARNING: some rows for transaction items are undefined!");
      console.log({row, rows, cashImpactDate});
    }
  } else {
    rows = [[row]];
  }
  // }

  return {sourceTxItemRows: rows[0], cashImpactTxItemRows: rows[1]};
}
