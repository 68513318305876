import type {EntityId} from "@reduxjs/toolkit";

export function monthlyCacheProjKey(
  rowId: EntityId,
  scenarioId: EntityId,
  dateKey: string,
  ...extraKeyParts: (string | undefined | null)[]
): string;
export function monthlyCacheProjKey([rowId, scenarioId, dateKey, ...extraKeyParts]: [
  rowId: EntityId,
  scenarioId: EntityId,
  dateKey: string,
  ...extraKeyParts: string[],
]): string;
export function monthlyCacheProjKey(...args: any): string {
  const [rowId, scenarioId, dateKey, ...extraKeyParts]: string[] = Array.isArray(args[0]) ? args[0] : args;
  let key = `${rowId}::${scenarioId}::${dateKey.length === 7 ? dateKey : dateKey.slice(0, 7)}`;
  if (dateKey) {
    key += ``;
  }
  if (extraKeyParts.length) {
    for (const extraKeyPart of extraKeyParts) {
      if (extraKeyPart) key += `::${extraKeyPart}`;
    }
  }
  return key;
}

export function monthlyCacheWithDeptVendorProjKey(
  rowId: EntityId,
  department: string | null | undefined,
  vendor: string | null | undefined,
  scenarioId: EntityId,
  dateKey: string,
  ...extraKeyParts: (string | undefined | null)[]
): string;
export function monthlyCacheWithDeptVendorProjKey([rowId, department, vendor, scenarioId, dateKey, ...extraKeyParts]: [
  rowId: EntityId,
  department: string | null | undefined,
  vendor: string | null | undefined,
  scenarioId: EntityId,
  dateKey?: string,
  ...extraKeyParts: string[],
]): string;
export function monthlyCacheWithDeptVendorProjKey(...args: any): string {
  const [rowId, department, vendor, scenarioId, dateKey, ...extraKeyParts]: string[] = Array.isArray(args[0])
    ? args[0]
    : args;
  let key = `${rowId}::${department ?? "null"}::${vendor ?? "null"}::${scenarioId}::${
    dateKey.length === 7 ? dateKey : dateKey.slice(0, 7)
  }::`;
  if (extraKeyParts.length) {
    for (const extraKeyPart of extraKeyParts) {
      if (extraKeyPart) key += `::${extraKeyPart}`;
    }
  }
  return key;
}

export function projKey(keyComponents: (string | EntityId)[]): string;
export function projKey(...keyComponents: (string | EntityId)[]): string;
export function projKey(...args: (EntityId | EntityId[] | string | string[])[]): string {
  const keyItems: string[] = Array.isArray(args[0]) ? (args[0] as string[]) : (args as string[]);
  return keyItems.join("::");
}

export function projKeyOmitNulls(...keyComponents: (string | EntityId | null | undefined)[]): string {
  let key = "";
  for (const item of keyComponents) {
    if (item !== null && typeof item !== "undefined") key += key === "" ? item : `::${item}`;
  }
  return key;
}

/**
 * Benchmarks I created to measure the impact of using cache projections:
 *
 * Small array (25 items): https://www.measurethat.net/Benchmarks/Show/16898/2/perf-difference-when-using-looping-over-collection-vs-c
 * Link to result: https://www.measurethat.net/Benchmarks/ShowResult/261148
 *
 * Medium array (800 items): https://www.measurethat.net/Benchmarks/Show/16900/0/perf-difference-when-using-looping-over-collection-vs-c
 * Link to result: https://www.measurethat.net/Benchmarks/ShowResult/261152
 *
 * Large array (5000 items): https://www.measurethat.net/Benchmarks/Show/16901/0/perf-difference-when-using-looping-over-collection-vs-c
 * Link to result: https://www.measurethat.net/Benchmarks/ShowResult/261155
 *
 * Conclusion: looping over the array to find an element takes longer and longer, pretty much in a linear fashion, as the number
 * of items in the array increases. The projection, on the other hand, stays very fast with no statistically significant difference
 * whether the array has 25 or 5000 items.
 *
 */
