import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {log} from "@shared/lib/debug-provider";
import {getIdsFromPayload} from "@shared/lib/entity-functions";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getDatasourcesAdapter} from "@shared/state/entity-adapters";
import {fetchTemplates} from "@state/templates/slice";

import datasourcesAPI from "./api";

import type {EntityId, PayloadAction} from "@reduxjs/toolkit";
import type {MonthlyCacheAndCbTxDiff} from "@shared/data-functions/cache/cache-utilities";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {Datasource, Formula} from "@shared/types/datasources";
import type {TemplateOptions, TemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";

export const api = {
  remove: createAsyncThunk("datasources/remove", datasourcesAPI.remove),
  upsert: createAsyncThunk("datasources/upsert", datasourcesAPI.upsert),
};

export type ApplyDatasourceChangesPayload = {
  reason: string;
  execId?: string;
  datasourceDiff: DatasourceDiff;
  optimisticDiff?: MonthlyCacheAndCbTxDiff;
  sendOptimisticUpdate?: boolean;
  originalTemplateOptions?: Record<EntityId, TemplateOptions>;
  disableAddingPreviousDependencies?: boolean;
  isGlobalRecalc?: boolean;
};

export const getSlice = () => {
  const datasourcesAdapter = getDatasourcesAdapter();
  return createSlice({
    name: "datasources",
    initialState: datasourcesAdapter.getInitialState(),
    reducers: {
      applyDatasourceChanges(state, action: PayloadAction<ApplyDatasourceChangesPayload>) {
        const idsToDelete = action.payload.datasourceDiff.deletes
          ? getIdsFromPayload(action.payload.datasourceDiff.deletes)
          : [];
        if (idsToDelete.length) datasourcesAdapter.removeMany(state, idsToDelete);
        if (action.payload.datasourceDiff.upserts?.length)
          datasourcesAdapter.upsertMany(state, action.payload.datasourceDiff.upserts);
        log(
          "ApplyDatasourceChanges reducer",
          `Done applying changes to datasources (upserts: ${action.payload.datasourceDiff.upserts?.length}, deletes: ${idsToDelete.length})`,
        );
      },

      insertAutoRange(
        state,
        action: PayloadAction<{
          scenarioId: string;
          row: TemplateRow;
          departmentId?: EntityId | null;
          vendor?: string | null;
          uiOptions: NonNullable<Formula["ui"]>;
          templateOptions: TemplateOptions;
        }>,
      ) {
        // For listener
      },
      upsertDatasourcesLocal(state, action: PayloadAction<Datasource[]>) {
        if (action.payload) datasourcesAdapter.upsertMany(state, action.payload);
      },
      removeDatasourcesLocal(state, action: PayloadAction<string[]>) {
        if (action.payload) datasourcesAdapter.removeMany(state, action.payload);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchTemplates.fulfilled, (state, action) => {
        // ensureWebWorker();

        datasourcesAdapter.setAll(state, action.payload.datasources);
      });
    },
  });
};

const _slice = getSlice();
export const {upsertDatasourcesLocal, removeDatasourcesLocal, insertAutoRange} = _slice.actions;
export const {applyDatasourceChanges} = createSyncedActionCreators(_slice.actions);

export const {
  selectById: selectDatasourceById,
  selectIds: selectDatasourceIds,
  selectEntities: selectDatasourceEntities,
  selectAll: selectAllDatasources,
  selectTotal: selectTotalDatasources,
} = getDatasourcesAdapter().getSelectors((state: RootState) => state.datasources);
