import {useAppSelector} from "@app/hooks";
import {selectVersionId} from "@state/global/slice";
import {selectVersionById} from "@state/versions/slice";
import clsx from "clsx";

import useStyles from "./styles.jss";

export interface SplashProps {}

export default function Splash({}: SplashProps) {
  const styles = useStyles();
  const classes = clsx(styles.splashMain);

  const selectedVersionId = useAppSelector(selectVersionId);
  const version = useAppSelector((state) => selectVersionById(state, selectedVersionId || ""));

  return (
    <div className={classes}>
      <div className={styles.content}>
        <img src="/assets/images/cloudberry-logo.svg" />
        <div className={styles.loadingText}>Loading...</div>
        {version ? (
          <div className={styles.versionText}>
            Version: <b>{version.display_name}</b>
          </div>
        ) : null}
      </div>
    </div>
  );
}
