import {useAppDispatch, useAppSelector} from "@app/hooks";
import {selectSidebarOpen, toggle} from "@app/ui-state/slice";
import RoundButton from "@components/RoundButton";
import SVGIcon from "@components/SVGIcon";
import {itemIsNotFalsy} from "@shared/lib/misc";
import {selectAllTemplates} from "@shared/state/templates/slice";
import clsx from "clsx";

import useStyles from "./LeftNav.jss";
import LeftNavItem from "./LeftNavItem";
import SvgLogo from "./SvgLogo";

import type {IconNames} from "@components/SVGIcon";

export interface LeftNavMenuItem {
  text: string;
  icon?: IconNames;
  children?: LeftNavMenuItem[];
  path: string;
  activeStateResolver?: (path: string) => boolean;
}

const settingsItem: LeftNavMenuItem = {
  text: "Settings",
  icon: "cog",
  path: "settings",
};

export default function LeftNav({}) {
  const styles = useStyles();
  const sidebarOpen = useAppSelector(selectSidebarOpen);
  const dispatch = useAppDispatch();
  const templates = useAppSelector(selectAllTemplates);
  const templatesByName = Object.fromEntries(templates.map((item) => [item.name, item]));
  const reportsById = useAppSelector((state) => state.reports.entities);

  const handleCollapseClick = () => {
    dispatch(toggle());
  };

  const classes = clsx(styles.leftNavContainer, {
    [styles.collapsed]: !sidebarOpen,
  });
  const menuItems: LeftNavMenuItem[] = [
    //{text: "Home", icon: "home", path: ""},
    //{text: "Inbox", icon: "mail", path: "inbox"},
    {
      text: "Reports",
      icon: "trend",
      path: "reports",
      children: Object.values(reportsById)
        .filter(itemIsNotFalsy)
        .toSorted((a, b) => (a?.display_name ?? "").localeCompare(b?.display_name ?? ""))
        .map((item) => ({
          text: item.display_name ?? "Unnamed Report",
          path: item.name,
        })),
    },
    {
      text: "Hiring Plan",
      icon: "hiringPlan",
      path: "hiring-plan",
      activeStateResolver: (fullPath) => fullPath.startsWith("/hiring-plan"),
    },
    //{text: "Favorites", icon: "star", path: "favorites"},
    {
      text: "Financial Statements",
      icon: "template",
      path: "financial-statements",
      children: [
        {text: templatesByName.profit_and_loss?.display_name ?? "profit_and_loss", path: "?statement=profit_and_loss"},
        {text: templatesByName.balance_sheet?.display_name ?? "balance_sheet", path: "?statement=balance_sheet"},
        {
          text: templatesByName.cash_flow_statement?.display_name ?? "cash_flow_statement",
          path: "?statement=cash_flow_statement",
        },
      ],
    },
  ];

  const additionalTemplates = templates.filter((item) => item.type === "generic");
  if (additionalTemplates.length) {
    const templatesSection: LeftNavMenuItem = {
      text: "Worksheets",
      icon: "inbox",
      path: "worksheets",
      children: [],
    };
    for (const template of additionalTemplates.toSorted((a, b) => a.display_name.localeCompare(b.display_name))) {
      templatesSection.children?.push({text: template.display_name, path: `${template.name}`});
    }
    menuItems.push(templatesSection);
  }

  return (
    <>
      {!sidebarOpen ? (
        <RoundButton className={styles.hamburger} icon="hamburger" onClick={handleCollapseClick} minimal />
      ) : null}
      <div className={classes}>
        {window.location.hostname.includes("staging.") ? (
          <div className={styles.stagingBanner}>STAGING ENVIRONMENT</div>
        ) : null}
        <div className={styles.leftNavHeader}>
          <div className={styles.logoContainer}>
            <SvgLogo className={styles.svgLogo} />
          </div>
          <div className={styles.collapseIconContainer}>
            <RoundButton icon="collapse" onClick={handleCollapseClick} enableCssStates minimal iconColor="blue" />
          </div>
        </div>
        <ul className={styles.mainNav}>
          {menuItems.map((topLevelItem) => (
            <LeftNavItem item={topLevelItem} key={topLevelItem.text} />
          ))}
        </ul>
        <a href="https://cloudberry.co/docs" title="Cloudberry Documentation" target="_blank" rel="noreferrer">
          <div className={clsx(styles.leftNavItem, styles.docsLink)}>
            <div className={styles.iconAndText}>
              <div className={styles.icon}>
                <SVGIcon name="help" />
              </div>
              <span>Documentation</span>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
