import colors from "@app/styles/colors";
import {getSvgColorStyles} from "@components/SVGIcon";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  rowDebugToolsMain: {
    width: 1280,
  },
  selectedRowInfos: {
    display: "flex",
    marginBottom: 30,

    "& > li": {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      fontSize: 13,
      padding: [0, 10],
      paddingRight: 20,

      "&:not:last-child": {
        borderRight: `1px solid ${colors.ui.lightBlue05}`,
      },

      "& > $title": {
        fontWeight: 700,
        color: colors.text.darkBlue01,
        display: "inline-flex",
        lineHeight: 16,
        marginBottom: 5,
      },

      "& > $value": {
        color: colors.text.darkBlue01,
        fontWeight: 600,
        display: "flex",
        flexDirection: "column",
        lineHeight: 12,

        "& > $slug": {
          color: colors.text.lightBlue02,
          fontSize: 12,
          marginTop: 5,
          lineHeight: 12,
          fontStyle: "italic",
        },
      },

      "& svg": {
        extend: getSvgColorStyles(colors.text.darkBlue01),
        marginRight: 5,
        marginTop: -2,
      },
    },
  },
  title: {},
  value: {},
  slug: {},
});
