import {createUseStyles} from "react-jss";

import colors from "@app/styles/colors";
import color from "color";
import {getSvgColorStyles} from "@components/SVGIcon";

export const sharedValues = {};

export default createUseStyles({
  formattingMain: {
    display: "inline-flex",
    background: color(colors.ui.lightBlue08).alpha(0.7).rgb().string(),
    alignItems: "center",
    height: 40,
    padding: [0, 0],
    borderRadius: 5,
  },
  formattingOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 33,
    height: 40,
    cursor: "pointer",
    color: colors.text.darkBlue02,
    userSelect: "none",

    "$disabled &": {
      cursor: "default",
    },

    ...getSvgColorStyles(colors.text.darkBlue02),
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: "none",
  },
  active: {
    background: colors.primary.lightGold06,
    borderBottom: [2, "solid", colors.primary.gold],
    "& > div": {
      marginBottom: -2,
    },
  },
});
