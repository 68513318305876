import {useAppSelector} from "@app/hooks";
import SVGIcon from "@components/SVGIcon";
import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";
import {selectAllScenarios} from "@state/scenarios/slice";
import {selectRowByName} from "@state/template-rows/selectors";
import {selectTemplateByName} from "@state/templates/selectors";
import {selectValuesByRowIdDateKey} from "@state/transaction-items/selectors";
import {projKey} from "@state/utils";
import clsx from "clsx";

import useStyles from "./styles.jss";

import type {Scenario, Template, TemplateRow} from "@shared/types/db";

export default function AdminChecks() {
  const styles = useStyles();
  const assetsRow = useAppSelector((state) => selectRowByName(state, "assets"));
  const liabilitiesAndEquityRow = useAppSelector((state) => selectRowByName(state, "liabilities_and_equity"));
  const scenarios = useAppSelector(selectAllScenarios);

  const balanceSheet = useAppSelector((state) => selectTemplateByName(state, "balance_sheet"));
  const monthlyCache = useAppSelector(selectValuesByRowIdDateKey);

  if (!assetsRow || !liabilitiesAndEquityRow || !balanceSheet) return null;

  const checkResult = balanceSheetBalanceCheck({
    assetsRow,
    liabilitiesAndEquityRow,
    scenarios,
    balanceSheet,
    monthlyCache,
  });

  return (
    (<div>
      {checkResult.map((scenarioCheckResult) => (
        <div className={styles.scenarioChecks} key={scenarioCheckResult.scenario.id}>
          <div
            className={clsx(styles.checkTitle, {
              [styles.balances]: scenarioCheckResult.allBalance,
              [styles.doesNotBalance]: !scenarioCheckResult.allBalance,
            })}
          >
            <h2>{scenarioCheckResult.scenario.name}</h2>
            <span>
              {scenarioCheckResult.allBalance ? <SVGIcon name="checkmark" /> : <SVGIcon name="errorExclamationPoint" />}
            </span>
            <span>
              {"("}
              {scenarioCheckResult.balances.filter((balance) => balance).length}/{scenarioCheckResult.balances.length}
              {")"}
            </span>
          </div>
          <table>
            <thead>
              <tr>
                <th />
                {scenarioCheckResult.months.map((month) => (
                  <th key={month}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assets</td>
                {scenarioCheckResult.assetsValues.map((value, i) => (
                  <td key={scenarioCheckResult.months[i]}>{value}</td>
                ))}
              </tr>
              <tr>
                <td>Liabilities & Equity</td>
                {scenarioCheckResult.liabilitiesAndEquityValues.map((value, i) => (
                  <td key={scenarioCheckResult.months[i]}>{value}</td>
                ))}
              </tr>
              <tr>
                <td>Delta</td>
                {scenarioCheckResult.deltas.map((value, i) => (
                  <td
                    key={scenarioCheckResult.months[i]}
                    className={clsx({
                      [styles.balances]: scenarioCheckResult.balances[i],
                      [styles.doesNotBalance]: !scenarioCheckResult.balances[i],
                    })}
                  >
                    {value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>)
  );
}

export type BsBalanceCheckResult = {
  scenario: Scenario;
  months: string[];
  assetsValues: string[];
  liabilitiesAndEquityValues: string[];
  balances: boolean[];
  deltas: string[];
  allBalance: boolean;
};
function balanceSheetBalanceCheck({
  balanceSheet,
  liabilitiesAndEquityRow,
  assetsRow,
  scenarios,
  monthlyCache,
}: {
  balanceSheet: Template;
  assetsRow: TemplateRow;
  liabilitiesAndEquityRow: TemplateRow;
  scenarios: Scenario[];
  monthlyCache: Record<string, number>;
}) {
  const result: BsBalanceCheckResult[] = [];
  for (const scenario of scenarios) {
    const scenarioResult: BsBalanceCheckResult = {
      scenario,
      months: [],
      assetsValues: [],
      liabilitiesAndEquityValues: [],
      balances: [],
      deltas: [],
      allBalance: true,
    };
    const months = getAllDateKeysBetween(balanceSheet.options.start, balanceSheet.options.end);

    for (const month of months) {
      scenarioResult.months.push(month);

      const assetsForMonth = monthlyCache[projKey(assetsRow.id, scenario.id, month, "balance", "total")];
      const liabilitiesAndEquityForMonth =
        monthlyCache[projKey(liabilitiesAndEquityRow.id, scenario.id, month, "balance", "total")];

      const assetsForMonthFormatted = formatNumber(assetsForMonth);
      const liabilitiesAndEquityForMonthFormatted = formatNumber(liabilitiesAndEquityForMonth);
      const delta = Math.abs(assetsForMonth - liabilitiesAndEquityForMonth);

      const balances = !delta || delta < 0.01;

      scenarioResult.assetsValues.push(assetsForMonthFormatted);
      scenarioResult.liabilitiesAndEquityValues.push(liabilitiesAndEquityForMonthFormatted);
      scenarioResult.deltas.push(delta && delta > 0.01 ? formatNumber(delta, false, 2) : "");
      scenarioResult.balances.push(balances);

      if (!balances) scenarioResult.allBalance = false;
    }
    result.push(scenarioResult);
  }

  return result;
}
