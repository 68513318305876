import colors from "@app/styles/colors";
import color from "color";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  formulaBarMain: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    cursor: "text",
  },
  disabled: {
    pointerEvents: "none",
  },
  focused: {
    "& $formulaBarContainer": {
      borderColor: color(colors.secondary.lightGreen).alpha(0.5).rgb().string(),
    },
    "& $iconContainer": {
      background: color(colors.secondary.lightGreen).alpha(0.5).rgb().string(),

      "& svg": {
        "& path": {
          fill: colors.secondary.green,
        },
      },
    },
  },
  formulaBarContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    flexDirection: "row",
    flex: 1,
    height: 40,
    marginLeft: 24,
    paddingLeft: 42,
    border: `1px solid ${colors.ui.lightBlue04}`,
    borderRadius: 5,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: colors.ui.lightBlue05,
    position: "absolute",
    top: 0,
    left: 0,
    width: 42,
    height: 38,

    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,

    "& svg": {
      "& path": {
        fill: colors.text.darkBlue02,
      },
    },
  },

  dropdownArrowContainer: {
    display: "none",
    paddingLeft: 10,
    marginRight: -10,
  },
});
