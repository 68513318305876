import {createSelector, createSlice} from "@reduxjs/toolkit";
import {generateClientSelectors} from "@shared/lib/misc";
import {selectReportItemEntities} from "@state/report-items/slice";

import type {PayloadAction} from "@reduxjs/toolkit";

type ReportingState = {
  activeSidebarTab: string | null;
  selectedReportId: string | null;
  selectedLineIndex: number | null;
  selectedColIndex: number | null;
  selectedReportItemId: string | null;
  reportModalStatus: "edit" | "create" | null;
};

const initialState: ReportingState = {
  activeSidebarTab: null,
  selectedReportId: null,
  selectedLineIndex: null,
  selectedColIndex: null,
  selectedReportItemId: null,
  reportModalStatus: null,
};

export const slice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    updateReportingState: (state, action: PayloadAction<Partial<ReportingState>>) => {
      return {...state, ...action.payload};
    },
    setSelectedReportId: (state, action: PayloadAction<(typeof initialState)["selectedReportId"] | null>) => {
      state.selectedReportId = action.payload;
    },

    setSelectedReportItemId: (state, action: PayloadAction<(typeof initialState)["selectedReportItemId"] | null>) => {
      state.selectedReportItemId = action.payload;
    },

    setSelectedLineIndex: (state, action: PayloadAction<(typeof initialState)["selectedLineIndex"] | null>) => {
      state.selectedLineIndex = action.payload;
    },

    setSelectedColIndex: (state, action: PayloadAction<(typeof initialState)["selectedColIndex"] | null>) => {
      state.selectedColIndex = action.payload;
    },

    setActiveSidebarTab: (state, action: PayloadAction<(typeof initialState)["activeSidebarTab"] | null>) => {
      state.activeSidebarTab = action.payload;
    },
  },
});

export const selectors = generateClientSelectors("reporting", initialState);

export const {
  setSelectedReportId,
  setSelectedReportItemId,
  setSelectedLineIndex,
  setActiveSidebarTab,
  setSelectedColIndex,
  updateReportingState,
} = slice.actions;

export const selectSelectedReportItem = createSelector(
  selectors.selectedReportItemId,
  selectReportItemEntities,
  (reportItemId, reportItemEntities) => reportItemEntities[reportItemId ?? ""],
);

const reducer = slice.reducer;

export default reducer;
