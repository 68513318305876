import {log} from "@shared/lib/debug-provider";
import {setLastSync} from "@state/global/slice";
import dayjs from "dayjs";

import {typeWebsocketListeners} from "./websockets";

import type {AppActionWithMeta} from "@state/store";

export default typeWebsocketListeners({
  sync:
    (store) =>
    ({type, payload}, {name, id, messageId}) => {
      const state = store.getState();
      // Do not sync events if we're looking at a version
      if (state.global.version) return;
      const action = {type, payload, meta: {initialDispatcher: "websocket"}} as AppActionWithMeta;
      log("WebsocketMiddleware", `Dispatching action from WebSocket "${type}"`, action);
      store.dispatch(action);
      const currentUserId = state.session.user?.id;
      store.dispatch(
        setLastSync({
          name: currentUserId === id ? "you" : name,
          time: dayjs().format("MMMM D, YYYY [at] h:mm A"),
          messageId,
        }),
      );
    },
} as const);
