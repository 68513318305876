import colors from "@app/styles/colors";
import {containerShadowStyles} from "@app/styles/general-ui";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  headsUpNotificationMain: {
    extend: containerShadowStyles,
    width: 460,
    height: 190,
    boxSizing: "border-box",
    borderRadius: 5,
    backgroundColor: colors.ui.white,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    paddingTop: 36,
    paddingLeft: 180,
    paddingRight: 40,
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 20,
  },
  title: {
    fontWeight: 700,
    lineHeight: 35,
    fontSize: 20,
    color: colors.ui.black,
  },
  text: {
    marginTop: 15,
    color: colors.ui.darkBlue02,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 24,
    "& > b": {
      fontWeight: 600,
    },
  },
  blue: {},
  red: {},
  green: {},
  orange: {},
});
