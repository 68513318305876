import clsx from "clsx";

import useStyles from "./styles.jss";
import AdminTabs from "./tabs";

export interface AdminProps {}

export default function Admin({}: AdminProps) {
  const styles = useStyles();
  const classes = clsx(styles.adminMain);

  return (
    <div className={classes}>
      <div className={styles.tabs}>
        <AdminTabs />
      </div>
    </div>
  );
}
