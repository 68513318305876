import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react";
import {useEffect, useMemo, useRef} from "react";

import {handleSuggestionSelect, keyDownHandler} from "../event-handlers";
import TooltipSuggestions from "./Suggestions";
import useStyles from "./Tooltip.jss";
import {useCursorPosition} from "./hooks";
import {closeTooltip, openTooltip, setActiveItemIndex, updateTooltipState} from "./slice";
import {getSuggestions} from "./suggestion-utilities";

import type {ReactComponentElement} from "react";
import type {FormulaSuggestion} from "./Suggestions";

export default function FormulaTooltip({
  component,
  children,
  isTheActiveComponent,
  inputRef,
  formula,
  onFormulaChange,
}: {
  component: "formulaBar" | "cell";
  children: ReactComponentElement<any>;
  isTheActiveComponent: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  formula: string | null;
  onFormulaChange: (formula: string, tooltipOpenOverride?: boolean) => void;
}) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();

  const {activeItemIndex, suggestionsResult, isOpen: _isOpen} = useAppSelector((state) => state.tooltip);

  const isOpen = _isOpen && isTheActiveComponent;

  const suggestions = useMemo(() => suggestionsResult?.suggestions ?? [], [suggestionsResult]);

  const cursorPosition = useCursorPosition(inputRef, isTheActiveComponent);

  useEffect(() => {
    if (formula !== null) {
      const newSuggestionsResult = getSuggestions(formula, cursorPosition);
      dispatch(
        updateTooltipState({
          suggestionsResult: newSuggestionsResult,
          activeItemIndex: 0,
          isOpen: newSuggestionsResult.suggestions.length > 0,
        }),
      );
    } else {
      dispatch(updateTooltipState({suggestionsResult: null, activeItemIndex: null, isOpen: false}));
    }
  }, [cursorPosition, dispatch, formula]);

  // Focus the newly active item
  useEffect(() => {
    if (activeItemIndex !== null && isOpen) {
      listRef.current[activeItemIndex]?.focus();
    }
  }, [activeItemIndex, isOpen]);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const {refs, floatingStyles, context} = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (isOpen, event, reason) => {
      const shouldOpen = isTheActiveComponent && suggestions.length > 0;
      if (shouldOpen && !isOpen) {
        dispatch(openTooltip());
      } else if (!shouldOpen && isOpen) {
        dispatch(closeTooltip());
      }
    },
    middleware: [
      offset({mainAxis: 8}),
      flip({padding: 10}),
      size({
        apply({rects, availableHeight, elements}) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const {isMounted, styles: transitionStyles} = useTransitionStyles(context, {
    duration: 100,
    initial: {
      transform: "scale(0.95)",
      opacity: 0,
    },
    common: ({side}) => {
      return {
        transformOrigin: {
          top: `8px`,
          bottom: `8px`,
          left: `8px`,
          right: `8px`,
        }[side],
      };
    },
  });

  const role = useRole(context, {role: "listbox"});
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex: activeItemIndex ?? 0,
    onNavigate: (index) => dispatch(setActiveItemIndex(index)),
    virtual: true,
    loop: true,
  });

  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([role, dismiss, listNav]);

  const wrappedGetItemProps = (suggestion: FormulaSuggestion, i: number) =>
    getItemProps({
      key: suggestion.slug,
      ref: (node) => (listRef.current[i] = node),
      onMouseDown(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        refs.domReference.current?.focus();
        handleSuggestionSelect(onFormulaChange, i);
      },
    });

  return (
    <>
      <div
        {...getReferenceProps({
          ref: refs.setReference,
        })}
        onKeyDown={keyDownHandler(component, "formulaInputWrapper", onFormulaChange, inputRef)}
        className={styles.formulaInputWrapper}
      >
        {/* This is the formula input */}
        {children}
      </div>
      <FloatingPortal>
        {!!suggestionsResult?.suggestions?.length && isMounted && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  zIndex: 1000,
                },
              })}
            >
              <div style={transitionStyles}>
                <div className={styles.tooltipMain}>
                  <TooltipSuggestions
                    activeTooltipIndex={activeItemIndex}
                    getItemProps={wrappedGetItemProps}
                    suggestions={suggestions}
                  />
                </div>
              </div>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
