import {store} from "@app/client-store";
import {
  selectFormulaForSelectedCell,
  selectSelectedRow,
  selectSelectedTemplate,
  selectUiDatasourceForSelectedCell,
  selectors,
} from "@features/templates/state/selectors";
import {analyzeFormula, type FormulaChunkWithIndexes} from "@shared/data-functions/formula/parse-utilities";
import {prettyPrintChunksToHtmlString} from "@shared/data-functions/formula/pretty-print";
import {isCellDisabled} from "@shared/lib/templates-utilities";
import {selectAllDepartments} from "@state/departments/slice";
import {selectIntegrationEntities, selectQboIntegrationVendors} from "@state/integrations/slice";
import {selectRowByName} from "@state/template-rows/selectors";
import {selectTemplateByName} from "@state/templates/selectors";

import {isAnyCbRef} from "./Tooltip/suggestion-utilities";

import type {Classes} from "jss";

interface GetPrettyPrintedFormulaPartsHtml {
  styles: Classes<"generic" | "department" | "disabled" | "alignRight" | "rowName" | "range" | "refFunction">;
}

export function getPrettyPrintedSelectedFormulaHtml({styles}: GetPrettyPrintedFormulaPartsHtml) {
  const state = store.getState();

  const vendorsMapping = selectQboIntegrationVendors(state);

  const datasource = selectUiDatasourceForSelectedCell(state);
  const cellBeingEdited = selectors.cellBeingEdited(state);
  if (datasource?.type === "text") {
    const text = cellBeingEdited ? cellBeingEdited.formula : datasource.value;
    return prettyPrintChunksToHtmlString([
      {
        type: "extra",
        subtype: "extra",
        value: text,
        startIndex: 0,
        endIndex: text.length - 1,
      },
    ]);
  }

  const activeDatasourceFormula = selectFormulaForSelectedCell(state);

  const {column, vendor, departmentId} = selectors.activeCell(state);
  const template = selectSelectedTemplate(state);
  const selectedRow = selectSelectedRow(state);
  const integrations = selectIntegrationEntities(state);

  const disabled = isCellDisabled(selectedRow, column ?? null, state);

  const formulaToBePrettyPrinted =
    typeof cellBeingEdited?.formula !== "undefined" ? cellBeingEdited?.formula : activeDatasourceFormula;
  // const formulaParts = formulaToBePrettyPrinted ? prettyPrint(formulaToBePrettyPrinted) : [];
  // const formulaChunks = analyzeFormula(formulaToBePrettyPrinted);
  let prettyPrintedFormula = "";
  if (disabled) {
    if (
      selectedRow?.type === "account" &&
      selectedRow.options.type === "Bank" &&
      datasource?.value.type !== "integration"
    ) {
      prettyPrintedFormula = prettyPrintChunksToHtmlString([
        {
          type: "extra",
          subtype: "extra",
          value: `[Bank account balance]`,
          startIndex: 0,
          endIndex: `[Bank account balance]`.length - 1,
        },
      ]);
    } else if (!!selectedRow?.mirror_of && template?.name === "cash_flow_statement") {
      let text = selectedRow.display_name;
      if (departmentId) text += state.departments.entities[departmentId]?.name ?? "Unknown Department";
      if (vendor) text += vendorsMapping[vendor]?.displayName ?? "Unknown Vendor";
      const firstTextPart = `[Increase (Decrease) in `;
      prettyPrintedFormula = prettyPrintChunksToHtmlString([
        {type: "extra", subtype: "extra", value: firstTextPart, startIndex: 0, endIndex: firstTextPart.length - 1},
        {
          type: "cb_ref",
          subtype: "row",
          value: text,
          startIndex: firstTextPart.length,
          endIndex: firstTextPart.length + text.length - 1,
        },
        {
          type: "extra",
          subtype: "extra",
          value: `]`,
          startIndex: firstTextPart.length + text.length,
          endIndex: firstTextPart.length + text.length + 1,
        },
      ]);
    } else if (selectedRow && column && datasource?.value.type === "integration") {
      const text = `[${
        integrations[datasource.value.integration_id]?.provider === "quickbooks-online"
          ? "QuickBooks Online"
          : "Snowflake"
      } integration]`;
      prettyPrintedFormula = prettyPrintChunksToHtmlString([
        {
          type: "extra",
          subtype: "extra",
          value: text,
          startIndex: 0,
          endIndex: text.length - 1,
        },
      ]);
    } else {
      // prettyPrintedFormula = prettyPrintFormulaToHtmlString(formulaToBePrettyPrinted);
      const chunks = analyzeFormula(formulaToBePrettyPrinted);
      const validatedChunks = validateReferences(chunks);
      prettyPrintedFormula = prettyPrintChunksToHtmlString(validatedChunks);
    }
  } else {
    const chunks = analyzeFormula(formulaToBePrettyPrinted);
    const validatedChunks = validateReferences(chunks);
    prettyPrintedFormula = prettyPrintChunksToHtmlString(validatedChunks);
  }
  return prettyPrintedFormula;
}

export type ValidatedChunk = FormulaChunkWithIndexes & {isValidRef?: boolean};

export const validateReferences = (chunks: FormulaChunkWithIndexes[]) => {
  const state = store.getState();
  const validatedChunks: ValidatedChunk[] = [];
  for (const chunk of chunks) {
    if (isAnyCbRef(chunk) && (chunk.subtype === "template" || chunk.subtype === "row_template_value")) {
      const templateName = chunk.value.trim().toLowerCase();
      const template = selectTemplateByName(state, templateName);
      validatedChunks.push({...chunk, isValidRef: !!template});
    } else if (isAnyCbRef(chunk) && chunk.subtype.startsWith("row")) {
      const rowName = chunk.value.trim().toLowerCase();
      if (rowName === "self") {
        validatedChunks.push({...chunk, isValidRef: true});
      } else {
        const row = selectRowByName(state, rowName);
        validatedChunks.push({...chunk, isValidRef: !!row});
      }
    } else if (isAnyCbRef(chunk) && chunk.subtype.startsWith("department")) {
      const departmentName = chunk.value.trim().toLowerCase();
      const department = selectAllDepartments(state).find((d) => d.name === departmentName);
      validatedChunks.push({...chunk, isValidRef: !!department});
    } else if (isAnyCbRef(chunk) && chunk.subtype.startsWith("vendor")) {
      const vendorName = chunk.value.trim();
      const vendorsMapping = selectQboIntegrationVendors(state);
      validatedChunks.push({...chunk, isValidRef: !!vendorsMapping[vendorName]});
    } else {
      validatedChunks.push(chunk);
    }
  }

  return validatedChunks;
};
