import {getValueForLookupKey} from "@shared/data-functions/transactions";
import {projKey, projKeyOmitNulls} from "@shared/state/utils";

import type {EntityId} from "@reduxjs/toolkit";
import type {CbTxState, DepartmentsState, TemplateRowsState} from "@shared/state/entity-adapters";
import type {TxItemsBaseState} from "@shared/state/transaction-items/slice";
import type {CbTx} from "@shared/types/db";
import type {ParsedCacheKey} from "./cache/cache-utilities";

/**
 * Accepts a rowId and dateKey for which the buffer transaction has been deleted and returns the mutations
 *
 * @param rowId - The rowId for which the buffer transaction must be deleted
 * @param dateKey - The dateKey of the txItem
 * @param state - The ids, entities, txItemIdsByRowId and txItemIdsByRowIdDateKey
 * @returns The entities to be deleted and the projections mutations
 */
export function getBufferDeleteMutations(
  txItemsState: CbTxState,
  rowId: string,
  scenarioId: string,
  dateKey: string,
  departmentId: EntityId | null | undefined = null,
  vendor: EntityId | null | undefined = null,
) {
  const {
    foundCbTx: {source, linked},
  } = findAllCbTxRelatedToFormulaForDateKey(txItemsState, rowId, scenarioId, dateKey, departmentId, vendor);

  const txItemsToDelete = [...source, ...linked];

  return {txItemsToDelete};
}

export type Changes = [rowId: string, dateKey: string][];

export function mutateMonthlyCache(
  changes: ParsedCacheKey[],
  templateRowsState: Pick<TemplateRowsState, "entities" | "idsByParentRowId" | "idsByMirrorOf">,
  valuesByRowIdDateKey: TxItemsBaseState["valuesByRowIdDateKey"],
  txItemsState: Pick<CbTxState, "entities" | "idsByRowId" | "idsByRowIdDateKey">,
  departmentsState: DepartmentsState,
): {deletedCacheKeys: string[]; updatedCacheKeys: Record<string, number>} {
  const deletedCacheKeys: string[] = [];
  const updatedCacheKeys: Record<string, number> = {};

  for (const {rowId, departmentId, vendor, scenarioId, dateKey} of changes) {
    const rowIdScenarioIdDateKey = projKey(rowId, scenarioId, dateKey);
    const lookupKey = projKeyOmitNulls(rowId, departmentId, vendor?.toLowerCase(), scenarioId, dateKey);

    const newValue = getValueForLookupKey(
      templateRowsState.entities[rowId],
      departmentsState,
      rowIdScenarioIdDateKey,
      txItemsState,
      departmentId,
      vendor,
    );
    if (!newValue) {
      if (typeof valuesByRowIdDateKey[lookupKey] !== "undefined") delete valuesByRowIdDateKey[lookupKey];
      deletedCacheKeys.push(lookupKey);
    } else {
      valuesByRowIdDateKey[lookupKey] = newValue;
      updatedCacheKeys[lookupKey] = newValue;
    }
  }

  return {deletedCacheKeys, updatedCacheKeys};
}

export function findAllCbTxRelatedToFormulaForDateKey(
  cbTxState: Pick<CbTxState, "ids" | "entities" | "idsByRowIdDateKey" | "idsByTxId" | "idsBySourceTxId">,
  rowId: string,
  scenarioId: string,
  dateKey: string,
  departmentId: EntityId | null | undefined = null,
  vendor: EntityId | null | undefined = null,
) {
  const toDelete: CbTx[] = [];
  const foundCbTx: {
    source: CbTx[];
    linked: CbTx[];
  } = {source: [], linked: []};

  // There can only be one buffer tx that is the source (source_tx_id === null). Find it
  const rowLookupKey = projKeyOmitNulls(rowId, scenarioId, dateKey);
  let existingSourceFormulaCbTx: CbTx | null = null;

  for (const txItemId of cbTxState.idsByRowIdDateKey[rowLookupKey] || []) {
    const matchingEntity = cbTxState.entities[txItemId];
    if (
      !matchingEntity ||
      (matchingEntity.department_id ?? null) !== departmentId ||
      (matchingEntity.tags?.qbo_vendor_id ?? null) !== vendor
    ) {
      continue;
    }
    if (matchingEntity.source === "formula" && !matchingEntity.source_tx_id) {
      if (existingSourceFormulaCbTx !== null) {
        toDelete.push(matchingEntity);
        console.log("Setting extra buffer transaction up for deletion", matchingEntity);
      } else {
        existingSourceFormulaCbTx = matchingEntity;
      }
    }
  }

  if (!existingSourceFormulaCbTx) return {foundCbTx, toDelete};

  const dbTxIdsWithSourceTxIdMatchingFormula = cbTxState.idsBySourceTxId[existingSourceFormulaCbTx.tx_id || ""];
  const dbTxIdsWithTxIdMatchingFormula = cbTxState.idsByTxId[existingSourceFormulaCbTx.tx_id || ""];

  for (const txItemId of [...(dbTxIdsWithTxIdMatchingFormula || []), ...(dbTxIdsWithSourceTxIdMatchingFormula || [])]) {
    const matchingEntity = cbTxState.entities[txItemId];
    if (!matchingEntity) continue;
    // If we found a match for tx_id, it's a tx item that's part of the source tx
    // If it has a source_tx_id, it's a tx item that's part of a linked tx
    if (matchingEntity.source_tx_id) {
      foundCbTx.linked.push(matchingEntity);
    } else {
      foundCbTx.source.push(matchingEntity);
    }
  }

  return {foundCbTx, toDelete};
}

// export function findAllCbTxForDatasourceAndDateKey(cbTxState: CbTxState, datasource: DbDatasource, dateKey: string) {
//   const toDelete: CbTx[] = [];
//   const foundCbTx: {
//     source: CbTx[];
//     linked: CbTx[];
//   } = {source: [], linked: []};

//   let existingSourceFormulaCbTx: CbTx | null = null;

//   const datasourceTxIds = cbTxState.idsByDsId[datasource.id] || [];
//   if (datasourceTxIds.length) {
//     // console.log();
//   }
//   for (const txItemId of datasourceTxIds) {
//     const matchingEntity = cbTxState.entities[txItemId];
//     if (!matchingEntity?.source_tx_id) {
//       console.log();
//     }
//     if (matchingEntity?.source === "formula" && !matchingEntity.source_tx_id) {
//       if (existingSourceFormulaCbTx !== null) {
//         toDelete.push(matchingEntity);
//         console.log("Setting extra buffer transaction up for deletion", matchingEntity);
//       } else {
//         existingSourceFormulaCbTx = matchingEntity;
//       }
//     }
//   }

//   if (!existingSourceFormulaCbTx) return {foundCbTx, toDelete};

//   const dbTxIdsWithSourceTxIdMatchingFormula = cbTxState.idsBySourceTxId[existingSourceFormulaCbTx.tx_id || ""];
//   const dbTxIdsWithTxIdMatchingFormula = cbTxState.idsByTxId[existingSourceFormulaCbTx.tx_id || ""];

//   for (const txItemId of [...(dbTxIdsWithTxIdMatchingFormula || []), ...(dbTxIdsWithSourceTxIdMatchingFormula || [])]) {
//     const matchingEntity = cbTxState.entities[txItemId];
//     if (!matchingEntity) continue;
//     // If we found a match for tx_id, it's a tx item that's part of the source tx
//     // If it has a source_tx_id, it's a tx item that's part of a linked tx
//     if (matchingEntity.source_tx_id) {
//       foundCbTx.linked.push(matchingEntity);
//     } else {
//       foundCbTx.source.push(matchingEntity);
//     }
//   }

//   return {foundCbTx, toDelete};
// }

// export function findExtraBufferTxItemsFromGenericRow(
//   row: GenericTemplateRow | HiringPlanTemplateRow,
//   txItemsToKeep: CbTx[],
//   scenarioId: string,
//   mutableTxItemsState: CbTxState,
//   datasource?: Datasource,
// ) {
//   const departmentId = datasource?.department_id ?? null;
//   const vendor = datasource?.dimensions?.vendor ?? null;
//   const toDelete: CbTx[] = [];
//   for (const txItem of txItemsToKeep) {
//     const txItemIdsForMonth = mutableTxItemsState.idsByRowIdDateKey[projKey(row.id, scenarioId, txItem.date)];
//     for (const cbTxItemId of txItemIdsForMonth || []) {
//       const matchingCbTxForMonth = mutableTxItemsState.entities[cbTxItemId];
//       if (
//         !matchingCbTxForMonth ||
//         cbTxItemId === txItem.id ||
//         departmentId === (matchingCbTxForMonth.department_id ?? null) ||
//         vendor === (matchingCbTxForMonth.tags.qbo_vendor_id ?? null)
//       )
//         continue;

//       if (matchingCbTxForMonth.source === "formula") toDelete.push(matchingCbTxForMonth);
//     }
//   }

//   return toDelete;
// }
