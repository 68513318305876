import {apiWithContract} from "@shared/lib/fetch";

import type {DepartmentsApiContracts} from "@shared/apis/contracts/departments.contracts";
import type {Department} from "@shared/types/db";
import type {ApiEndpoints} from "@state/store";

const endpoints = {
  fetchAll(_arg: undefined, thunkAPI) {
    return apiWithContract<DepartmentsApiContracts["get"]>(thunkAPI, {
      method: "get",
      path: `departments`,
      query: null,
      body: null,
    });
  },
  upsert(departments: Department[], thunkAPI) {
    return apiWithContract<DepartmentsApiContracts["upsert"]>(thunkAPI, {
      method: "put",
      path: `departments`,
      body: departments,
      query: null,
    });
  },
  remove(ids: string[], thunkAPI) {
    return apiWithContract<DepartmentsApiContracts["delete"]>(thunkAPI, {
      method: "del",
      path: `departments`,
      body: ids,
      query: null,
    });
  },
} satisfies ApiEndpoints;

export default endpoints;
