import {isValidDate} from "@shared/lib/date-utilities";
import clsx from "clsx";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactDatepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {monthsDropdownItems} from ".";
import Dropdown from "../Dropdown";
import RoundButton from "../RoundButton";
import useStyles from "./RDP.jss";

type Sizes = "sm" | "md" | "lg";

export type RDPDatePickerProps = {
  onChange?: (date: string | null) => void;
  value: string;
  className?: string;
  onBlur?: (evt: {target: {value: string | null}}) => void;
  onClose?: () => void;
  disabled?: boolean;
  min?: string;
  max?: string;
  size?: Sizes;
  inline?: boolean;
  clearable?: boolean;
  focusOnRender?: boolean;
  noBorder?: boolean;
  showCalendarIcon?: boolean;
};

export default function RDPDatePicker({
  disabled = false,
  className,
  inline = false,
  max = "2050-12-31",
  min = "1980-01-01",
  onBlur,
  onChange,
  onClose,
  size = "md",
  value,
  clearable = false,
  focusOnRender = false,
  noBorder = false,
  showCalendarIcon = true,
}: RDPDatePickerProps) {
  const [rawValue, setRawValue] = useState<string>(isValidDate(value) ? dayjs(value).format("MM/DD/YYYY") : value);
  const [localDate, setLocalDate] = useState<Date | null>(
    value?.length && isValidDate(value) ? dayjs(value).toDate() : null,
  );
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles();

  const localDateRef = useRef(localDate);
  useEffect(() => {
    if (localDateRef) localDateRef.current = localDate;
  }, [localDate]);

  useEffect(() => {
    setLocalDate(value?.length && isValidDate(value) ? dayjs(value).toDate() : null);
    setRawValue(isValidDate(value) ? dayjs(value).format("MM/DD/YYYY") : value);
  }, [value]);

  const callOnChangeOrBlur = useCallback(
    (type: "change" | "blur", newLocalDate?: Date | null, actualValue?: string) => {
      const local = typeof newLocalDate !== "undefined" ? newLocalDate : localDate;
      const originalDate = value?.length ? dayjs(value).format("YYYY-MM-DD") : null;
      const currentDate = local ? dayjs(local).format("YYYY-MM-DD") : null;

      if (originalDate !== currentDate) {
        if (type === "change" && onChange) onChange(actualValue || currentDate);
        if (type === "blur" && onBlur) onBlur({target: {value: actualValue || currentDate}});
      } else if (type === "blur" && onBlur) onBlur({target: {value: originalDate}});
    },
    [localDate, onBlur, onChange, value],
  );

  useEffect(
    () => () => {
      callOnChangeOrBlur("blur", localDateRef.current);
    },
    [callOnChangeOrBlur],
  );

  const handleOnBlur = () => {
    callOnChangeOrBlur("blur");
  };

  const handleChange = (date: Date, evt: React.KeyboardEvent) => {
    if (evt.key === "Enter") {
      handleOnBlur();
    } else {
      setLocalDate(date);
    }
  };

  const handleChangeRaw = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setRawValue(evt.target.value);
    evt.stopPropagation();
  };

  const handleCalendarClose = (timeout: boolean = true) => {
    setTimeout(() => {
      setIsOpen(false);
      if (onClose) onClose();
    }, 5);
    callOnChangeOrBlur("change");
    callOnChangeOrBlur("blur");
  };

  const handleClear: React.MouseEventHandler = (evt) => {
    setLocalDate(null);
    if (!isOpen) callOnChangeOrBlur("blur", null);
    evt.preventDefault();
  };

  const handleHeaderMonthYearChange = (date: Date) => {
    if (inline) {
      setLocalDate(date);
      callOnChangeOrBlur("change", date);
    }
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key.includes("Arrow")) evt.stopPropagation();
  };

  const classes = clsx(
    {
      [styles.input]: !inline,
      [styles.noBorder]: noBorder,
      [styles.noLeftIcon]: !showCalendarIcon,
    },
    className,
  );

  const wrapperClassName = clsx(styles.calendarWrapper, {[styles.clearable]: clearable});

  const maxDate = !!max ? dayjs(max).toDate() : null;
  const minDate = dayjs(min).toDate();

  const minYear = minDate.getUTCFullYear();
  const maxYear = maxDate?.getUTCFullYear() || 2024;

  const years: number[] = [];

  for (let year = minYear; year <= maxYear; year++) {
    years.push(year);
  }

  const actualDatePicker = (
    <ReactDatepicker
      adjustDateOnChange={false}
      className={classes}
      wrapperClassName={wrapperClassName}
      disabled={disabled}
      inline={inline}
      maxDate={maxDate}
      minDate={minDate}
      onBlur={handleOnBlur}
      onChange={handleChange}
      onChangeRaw={handleChangeRaw}
      onMonthChange={handleHeaderMonthYearChange}
      onYearChange={handleHeaderMonthYearChange}
      onCalendarOpen={() => setTimeout(() => setIsOpen(true), 10)}
      onCalendarClose={handleCalendarClose}
      selected={localDate}
      autoFocus={focusOnRender}
      onSelect={handleChange}
      onKeyDown={handleKeyDown}
      disabledKeyboardNavigation
      value={rawValue}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const years: string[] = [];
        for (let i = minYear; i <= maxYear; i++) {
          years.push(i.toString());
        }
        const yearsDropdownItems = years.map((year) => ({key: year, value: year}));
        return (
          <div className={styles.customCalendarHeader}>
            <div className={styles.arrowLeft}>
              <RoundButton
                icon="arrow"
                onClick={decreaseMonth}
                disabled={disabled || prevMonthButtonDisabled}
                enableCssStates
              />
            </div>

            <Dropdown
              items={monthsDropdownItems}
              onSelect={({key: month}: {key: string}) => changeMonth(parseInt(month, 10) - 1)}
              selectedKey={(date.getMonth() + 1).toString().padStart(2, "0")}
              buttonWidth={120}
              buttonSize="small"
              renderAsChild
              disabled={disabled}
            />

            <div className={styles.monthDropdownWrapper}>
              <Dropdown
                items={yearsDropdownItems}
                onSelect={({key: year}) => changeYear(parseInt(year, 10))}
                selectedKey={date.getFullYear().toString()}
                buttonWidth={80}
                buttonSize="small"
                renderAsChild
                disabled={disabled}
              />
            </div>

            <div className={styles.arrowRight}>
              <RoundButton icon="arrow" onClick={increaseMonth} disabled={disabled || nextMonthButtonDisabled} />
            </div>
          </div>
        );
      }}
    />
  );
  return !inline ? (
    <div className={styles.inputWrapper}>
      <label>
        <div className={styles.iconLeft} style={{pointerEvents: isOpen ? "none" : undefined}}>
          {showCalendarIcon ? (
            <RoundButton
              buttonClassName={isOpen ? styles.openCalendarButton : undefined}
              enableCssStates={!isOpen && !disabled}
              icon="calendar"
              disabled={disabled}
            />
          ) : null}
        </div>
        {clearable ? (
          <div className={styles.iconRight}>
            <RoundButton icon="smallCross" onClick={handleClear} enableCssStates={!disabled} disabled={disabled} />
          </div>
        ) : null}
        {actualDatePicker}
      </label>
    </div>
  ) : (
    <div className={styles.inline}>{actualDatePicker}</div>
  );
}
