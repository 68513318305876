import {useAppDispatch, useAppSelector} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import Confirm from "@components/Confirm";

export default function ConfirmationSingleton() {
  const dispatch = useAppDispatch();
  const actionConfirmation = useAppSelector((state) => state.uiState.actionConfirmation);

  const handleCancel = () => {
    dispatch(askForConfirmation(null));
    if (actionConfirmation?.onCancel) actionConfirmation.onCancel();
  };

  const handleConfirm = () => {
    if (actionConfirmation?.closeOnConfirm !== false) dispatch(askForConfirmation(null));
    if (actionConfirmation?.onConfirm) actionConfirmation.onConfirm();
  };

  return !!actionConfirmation ? (
    <Confirm {...actionConfirmation} onCancel={handleCancel} onConfirm={handleConfirm} />
  ) : null;
}
