import {dispatchInMainThread} from "@shared/fn-gateway";
import {getEmptyDatasourceDiff, mergeDatasourceDiffObjects} from "@shared/lib/datasource-utilities";
import {log} from "@shared/lib/debug-provider";
import {moveLastMonthOfActuals} from "@shared/lib/row-utilities";
import {removeCbTx} from "@state/cb-tx/slice";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {triggerSync} from "@state/integrations/slice";
import {isWebWorker} from "browser-or-node";

import {createEffectHandler} from "../listeners-list";
import {changeLastMonthOfActuals, upsertTemplate, upsertTemplateApiCall, upsertTemplates} from "./slice";

import type {EntityId} from "@reduxjs/toolkit";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {Template, TemplateOptions} from "@shared/types/db";

export const syncTemplateUpsertsWithAPI = createEffectHandler(
  [upsertTemplate, upsertTemplates],
  (action, {dispatch}) => {
    const actionTemplates = upsertTemplates.match(action) ? action.payload : [action.payload];
    if (!Array.isArray(actionTemplates)) return;

    for (const template of actionTemplates) {
      dispatch(upsertTemplateApiCall(template));
    }
  },
);

export const changeLastMonthOfActualsListener = createEffectHandler(
  changeLastMonthOfActuals,
  async (action, {getState, dispatch}) => {
    if (!isWebWorker) return;
    const {newLMOA, templateIds, integrationIdToSyncAfter} = action.payload;
    const state = getState();

    let lastTemplateOptions: Record<EntityId, TemplateOptions> | undefined = undefined;
    let mergedDatasourceDiff: DatasourceDiff = getEmptyDatasourceDiff();

    const allTxIdsToDelete: string[] = [];
    const templatesToUpsert: Template[] = [];
    for (const templateId of templateIds) {
      const template = state.templates.entities[templateId];
      if (!template) continue;

      const {txIdsToDelete, datasourceDiff} = moveLastMonthOfActuals({
        template,
        state,
        newLMOA,
      });

      log("LMOA Change", `Marking ${datasourceDiff.cacheKeysRemovedPerDsId.length} cacheKeys as removed`);
      log("LMOA Change", `Clearing ${txIdsToDelete.length} transactions for template ${template.name}`);

      mergedDatasourceDiff = mergeDatasourceDiffObjects(mergedDatasourceDiff, datasourceDiff);

      allTxIdsToDelete.push(...txIdsToDelete);
      templatesToUpsert.push({...template, options: {...template.options, lastMonthOfActuals: newLMOA}});

      lastTemplateOptions ||= {};
      lastTemplateOptions[template.id] = template.options;
    }

    await dispatch(upsertTemplates(templatesToUpsert));
    await dispatch(removeCbTx(allTxIdsToDelete));

    // const cacheKeysOfDeletedTransactions = totalTxIdsToDelete.map((cbTxId) => {
    //   const tx = state.cbTx.entities[cbTxId];
    //   if (!tx) return null;
    //   let cacheKey = projKey(tx.row_id, tx.tags.qbo_class, tx.tags.qbo_vendor_id, tx.scenario_id, tx.date.slice(0, 7));
    //   if (cacheKey.includes(NO_DEPARTMENT_NAME) && tx.tags.qbo_class)
    //     cacheKey = cacheKey.replace(NO_DEPARTMENT_NAME, tx.tags.qbo_class);
    //   return `${makeDependencyKeyHumanReadable(cacheKey, state, false, true)}`;
    // });
    // console.log("Deleted cb tx before recalc", cacheKeysOfDeletedTransactions);

    await dispatch(
      applyDatasourceChanges({
        datasourceDiff: mergedDatasourceDiff,
        reason: `Moved last month of actuals to ${newLMOA}`,
        originalTemplateOptions: lastTemplateOptions,
      }),
    );

    if (integrationIdToSyncAfter) {
      dispatchInMainThread(triggerSync(integrationIdToSyncAfter));
    }
  },
);
