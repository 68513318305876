import {createListenerMiddleware} from "@reduxjs/toolkit";

import type {TypedStartListening} from "@reduxjs/toolkit";
import type {AppThunkDispatch, RootState} from "@state/store";

export type AppStartListening = TypedStartListening<RootState, AppThunkDispatch>;

export const getListenerMiddleware = () => {
  const listenerMiddleware = createListenerMiddleware();
  return {
    listenerMiddleware,
    startListening: listenerMiddleware.startListening as AppStartListening,
  };
};
