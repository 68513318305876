import colors from "@app/styles/colors";
import {containerShadowStyles} from "@app/styles/general-ui";
import color from "color";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  modalMain: {
    extend: containerShadowStyles,
    borderRadius: 5,
    backgroundColor: colors.ui.white,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    zIndex: 5,
    background: color(colors.ui.darkBlue01).alpha(0.7).rgb().string(),
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 20,
  },
});
