import colors from "@app/styles/colors";
import {minimalShadowStyles} from "@app/styles/general-ui";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  monthPickerMain: {
    maxWidth: 248,
    minWidth: 246,
    userSelect: "none",
    padding: 20,
    extend: minimalShadowStyles,
    background: colors.ui.white,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: [6, 0],
  },
  currentYear: {
    lineHeight: 25,
    fontSize: 14,
    fontWeight: 700,
    display: "inline-flex",
    margin: [0, 52],
  },
  monthsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(50px, 1fr))",
    gridAutoRows: "1fr",
    gap: 2,

    "&::before": {
      content: '""',
      width: 0,
      paddingBottom: "100%",
      gridRow: "1 / 1",
      gridColumn: "1 / 1",
    },

    "& > *:first-child": {
      gridRow: "1 / 1",
      gridColumn: "1 / 1",
    },
  },
  monthContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 24,
    borderRadius: 999,
    cursor: "pointer",
    transition: "background-color 0.1s",
    fontSize: 12,

    "&:hover, &:focus": {
      backgroundColor: colors.primary.lightGold04,
      outline: "none",
    },

    "&$selected, &:active": {
      backgroundColor: colors.primary.gold,
      color: colors.ui.white,
    },

    "&$disabled": {
      cursor: "default",
      color: colors.text.lightBlue02,
      opacity: 0.8,
      pointerEvents: "none",
    },
  },
  selected: {},
  disabled: {},
  triggerWrapper: {
    width: "100%",
  },
});
