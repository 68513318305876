import {store} from "@app/client-store";
import selectTemplateUiData from "@features/templates/state/select-template-ui-data";
import {selectTemplateById} from "@state/templates/slice";

import {generateCsvString, getFilename, triggerCsvDownload} from "./export-utilities";

export async function exportTemplateCurrentView({
  format = "csv",
  templateId,
}: {
  format?: "csv" | "json";
  templateId: string;
}) {
  const state = store.getState();

  const template = selectTemplateById(state, templateId);
  if (!template) return;

  const version = state.global.version ? state.versions.entities[state.global.version] ?? null : null;
  const globalDepartment = state.global.selectedDepartmentId
    ? state.departments.entities[state.global.selectedDepartmentId] ?? null
    : null;

  const {rowsForDisplay} = selectTemplateUiData(state, template.id);

  const exportedRows: any[] = [];

  const headers = ["Row", ...rowsForDisplay[0].cells.slice(1).map((cell) => cell.dateKey)];
  for (const row of rowsForDisplay) {
    if (row.hidden || (row.empty && row.sanityCheck?.passed === false)) continue;
    if (row.type === "sanityCheckSource" || row.type === "sanityCheckDiff") continue;
    const indentationSpaces = Array(row.depth).fill("  ").join("");
    exportedRows.push(
      Object.fromEntries([
        ["Row", `${indentationSpaces}${row.cells[0].formattedValue}`],
        ...row.cells.slice(1).map((cell) => [cell.dateKey, cell.formattedValue]),
      ]),
    );
  }

  if (format === "csv") {
    const csvStr = generateCsvString({headers, items: exportedRows});
    triggerCsvDownload({
      content: csvStr,
      filename: `${getFilename(template, version, globalDepartment)}.csv`,
    });
  } else {
    console.log(exportedRows);
    console.error("JSON export not implemented yet");
  }
}
