// import {typedObjectEntries} from "@shared/types/misc";

export type TooltipText = {
  mainText: string;
  subsection?: string;
  variables?: string[];
};

/*************************************

  Each item in that object has the following structure:

  - a key, which is the identifier of that string (eg. HiringPlan::Employees::Sidebar::Role). If you want to add a tooltip where none is present, create a new entry with the same naming convention and ping me so I reference it in the code.
  - a mainText property, which is the first block of text
  - an optional subsection property, which is the text inside the darker background (usually used for examples)

  The mainText is required. subsection can be omitted if no subsection is needed
  Line returns are supported, see HiringPlan::FinancialComponents::Sidebar::CompensationName → subsection for an example.

  3 formatting options are available:

  - ***TEXT*** formats a text as a gold and bold highlight
  - **TEXT**  formats a text as bold
  - *TEXT* formats a text as italic

  To use dynamic variables, simply choose a variable name and surround it with double curly braces (eg. {{employeeName}}). If you add a variable that was not there before, ping me so pass it down from the code to the text. Dynamic variables can also be bold / italic / highlighted.

  For an example how to use variables, see HiringPlan::Teams::Sidebar::DebitExpenseMapping.


*************************************/

const tooltipTexts = {
  "HiringPlan::FinancialComponents::Sidebar::CompensationType": {
    mainText: `***Compensation Type*** is either a Salary, Payroll Tax or Benefit.`,
    subsection: `For example, if you’re setting up a new state unemployment insurance tax that’s applied on top of a team member’s salary, select *Payroll Tax*.`,
  },
  "HiringPlan::FinancialComponents::Sidebar::CompensationName": {
    mainText: `***Compensation Name*** is used to differentiate multiple components from each other.`,
    subsection: `For example, larger companies may offer multiple types of benefits, such as Medical, Dental, Vision or 401k.
For more accurate forecasting, you could create a Compensation Component for each of them, and use the *Name* field to differentiate each benefit from each other.`,
  },
  "HiringPlan::FinancialComponents::Sidebar::PaymentType": {
    mainText: `***Payment Type*** is either a Recurring Payment, Percentage of Salary, or One-Time Payment.`,
    subsection: `For example, if you’re creating a new monthly benefit with a fixed fee, you’d select recurring payment. For a benefit proportional to salary such as 401k, select Percentage of Salary. One-time payments are handy for creating bonuses.`,
  },
  "HiringPlan::FinancialComponents::Sidebar::EmployeePaySchedule": {
    mainText: `***Employee Pay Schedule*** is the interval your company uses to pay employees.`,
  },
  "HiringPlan::FinancialComponents::Sidebar::DebitExpenseMapping": {
    mainText: `***Profit and Loss Mapping (Debit)*** maps the expense to your Profit and Loss. You can change this for each team.`,
    subsection: `For example, if you’re editing defaults for your salaries, select PnL account called *Salaries*.
If you have multiple Salary accounts on your PnL for different teams, you can map each team individually. This is just the default.`,
  },
  "HiringPlan::FinancialComponents::Sidebar::CreditExpenseMapping": {
    mainText: `***Default Balance Sheet Mapping (Credit)*** maps the compensation component to your Balance Sheet. You can change this for each team.`,
    subsection: `In most cases, map this to the bank account you use to pay off your payroll.`,
  },
  "HiringPlan::Teams::Sidebar::DebitExpenseMapping": {
    mainText: `***Profit and Loss Mapping (Debit)*** maps the {{fcName}} expense to your Profit and Loss.`,
    subsection: `For example, if you’re mapping the salary component, select a PnL row/account called something along the lines of *Salaries* or *Wages*.
If you have more than one Salaries row on your PnL, you can individually map each team. `,
  },
  "HiringPlan::Teams::Sidebar::CreditExpenseMapping": {
    mainText: `***Balance Sheet (Credit)*** maps the {{fcName}} to your Balance Sheet.`,
    subsection: `In most cases, map this to the bank account you use to pay off your payroll.`,
  },
  "HiringPlan::Teams::Sidebar::TeamName": {
    mainText: `Descriptive name for you team.`,
    subsection: `For example, Marketing or Engineering.`,
  },
  "HiringPlan::Teams::Sidebar::ParentTeam": {
    mainText: `Give your teams hierarchy by organizing multiple teams under one parent-level team.`,
    subsection: `For example, you could create two smaller teams called Sales and another one called Marketing, and place them under a parent-level team called Sales & Marketing`,
  },
  "HiringPlan::Teams::Sidebar::Department": {
    mainText: `Setting a ***Department*** for a given team determines who has access to a said team.`,
    subsection: `For example, say you set your Engineering team to R&D Department.
Now the Engineering team and its employees are visible only to the company Admins and anyone with R&D Department access.`,
  },
  "HiringPlan::Teams::Sidebar::Compensation::Amount": {
    mainText: `Set a default salary, benefits or payroll taxes for every new hire in this team.`,
  },
  "HiringPlan::Employees::Sidebar::FirstName": {
    mainText: `Optional, feel free to leave empty for future hires.`,
  },
  "HiringPlan::Employees::Sidebar::LastName": {
    mainText: `Optional, feel free to leave empty for future hires.`,
  },
  "HiringPlan::Employees::Sidebar::HireDate": {
    mainText: `Required. Use approximate date if you don't know the exact one.`,
  },
  "HiringPlan::Employees::Sidebar::Status": {
    mainText: `Select whether employee is a current team member, future hire, or inactive. `,
    subsection: `For example, you could set Bilbo Baggins to a status Current Team, Frodo Baggins to Future Hire, and Gollum to Inactive starting December 1`,
  },
  "HiringPlan::Employees::Sidebar::TerminationDate": {
    mainText: `Optional. Can be set to a future date.`,
  },
  "HiringPlan::Employees::Sidebar::Role": {
    mainText: `Required. What your team member is going to do.`,
    subsection: `For example, VP of Sales or Software Engineer.`,
  },
  "HiringPlan::Employees::Sidebar::RoleStart": {
    mainText: `Same as the Hire Date for the employee's first Role.`,
  },
  "HiringPlan::Employees::Sidebar::Team": {
    mainText: `Required. What your team member is going to do.`,
    subsection: `For example, VP of Sales or Software Engineer.`,
  },
  "HiringPlan::Employees::Sidebar::TeamStart": {
    mainText: `Same as the Hire Date for the employee's first Team.`,
  },
  "HiringPlan::Employees::Sidebar::EmploymentType": {
    mainText: `Set as Employee or Contractor for easier filtering of your team members.`,
  },
  "HiringPlan::Employees::Sidebar::TagKey": {
    mainText: `***Column Name*** is the name of the new Column you want to see in your Hiring Plan.`,
    subsection: `For example, if you want to categorize your employees by experience, you could create a Column Name *Level*.
    
    The idea is that you'd create the same Column Name for every employee with different Column Values.`,
  },
  "HiringPlan::Employees::Sidebar::TagValue": {
    mainText: `***Value*** is the employee specific value of a Column Name.`,
    subsection: `If your Column Name is called *Level* to describe the employee's experience, the Value here could be *Level 3* or just *3*`,
  },
  "HiringPlan::Employees::Sidebar::CompensationAmount": {
    mainText: `Set your employee's {{fcName}} and the payment interval. Use the plus icon to add changes in the future.`,
  },
  "HiringPlan::Employees::Sidebar::CompensationStart": {
    mainText: `The initial compensation start date defaults to the employee hire date, but it's editable. Use the plus icon to add a future change in the employee salary or benefits.`,
  },
  "HiringPlan::Employees::Sidebar::DebitExpenseMapping": {
    mainText: `This is where the employee's {{fcName}} gets mapped in the Profit and Loss statement.
You can edit this on the team settings.`,
  },
  "HiringPlan::Employees::Sidebar::CreditExpenseMapping": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Login::Invite::ChooseAPassword": {
    mainText: `Set the password for your new account.`,
  },
  "Login::Invite::ConfirmPassword": {
    mainText: `Confirm your password.`,
  },
  "Login::EmailInputField": {
    mainText: `Enter your email`,
  },
  "Reporting::Sidebar::Chart::Title": {
    mainText: `Enter the title of your chart`,
    subsection: `For example, Revenue Forecast or Expense Breakdown.`,
  },
  "Reporting::Sidebar::Chart::Type": {
    mainText: `Select the type of your chart.`,
    subsection: `For example, a Line Chart is often used to plot your revenue or a bank balance, whereas a Stacked Bar Chart is great for displaying your MRR Breakdown.`,
  },
  "Reporting::Sidebar::Chart::Series": {
    mainText: `This is the data series displayed in your chart.`,
    subsection: `For example, a chart displaying you total revenue would have just one series.`,
  },
  "Reporting::Sidebar::Chart::VerticalAxis::Bounds": {
    mainText: `Your upper and lower bounds are set dynamically based on the data. 
  
  Switch the dropdown to *Custom* to set your own lower and upper bounds. Use whole integers to describe the bounds, not numbers formatted to thousands or millions.`,
    subsection: `For example, you might want to set a lower bound of 0 for a chart with your bank account balance to quickly see your runway.`,
  },
  "Reporting::Sidebar::Chart::VerticalAxis::NumberFormat": {
    mainText: `Often used to make the chart look cleaner.`,
    subsection: `For example, if you have a business making millions each month (an actual cash cow!), you might want to display the chart in thousands or millions.`,
  },
  "Reporting::Sidebar::Chart::VerticalAxis::Interval": {
    mainText: `Enter the desired interval between ticks. Use whole integers, not numbers formatted to thousands or millions.`,
    subsection: `For example, if you're editing a chart extending to 1.5M and you want to display intervals for every 250k, enter 250000.`,
  },
  "Reporting::Sidebar::Header::Title": {
    mainText: `Report or Section Title.`,
    subsection: `For example, Q4 Management Report`,
  },
  "Reporting::Sidebar::Header::Subtitle": {
    mainText: `Brief subtitle for additional information`,
    subsection: `For example, "Record quarter with a new product launch."`,
  },
  "Reporting::Sidebar::Table::Title": {
    mainText: `Table Title`,
    subsection: `For example, Financials Summary or Profit and Loss`,
  },
  "Reporting::Sidebar::Table::Subtitle": {
    mainText: `Brief subtitle for additional information`,
    subsection: `For example, "2022-2023 by Quarter"`,
  },
  "Reporting::Sidebar::Table::RowName": {
    mainText: `Row name`,
  },
  "Reporting::Sidebar::Table::ColumnName": {
    mainText: `Column name`,
  },
  "Reporting::Sidebar::Table::ColTextFormat": {
    mainText: `Bold or italicize the entire column`,
  },
  "Reporting::Sidebar::Table::RowTextFormat": {
    mainText: `Change the formatting of the row, add a border, or change indentation.`,
  },
  "Reporting::Sidebar::Table::TableFormat": {
    mainText: `Change the size of the font and expand or condense row height`,
  },
  "Reporting::Sidebar::Textbox::Title": {
    mainText: `Text box title`,
    subsection: `"Q3 Performance Summary"`,
  },
  "Reporting::Sidebar::Textbox::Text": {
    mainText: `A longer description of your past performance or future goals.`,
  },
  "Reporting::Sidebar::Textbox::TextFormat": {
    mainText: `Change the size of the font`,
  },
  "Reporting::Sidebar::ChartAndTable::Col::StartMonth": {
    mainText: `Time period start for the column.`,
    subsection: `Reference with *=[start_month] * or *=[{{colVarName}}]*.`,
  },
  "Reporting::Sidebar::ChartAndTable::Col::EndMonth": {
    mainText: `Time period end for the column.`,
    subsection: `Reference with *=[end_month] * or *=[{{colVarName}}]*.`,
  },
  "Reporting::Sidebar::TimePeriod::StartMonth": {
    mainText: `Time period start for the {{type}}.`,
    subsection: `Reference with *=[start_month]*.`,
  },
  "Reporting::Sidebar::TimePeriod::EndMonth": {
    mainText: `Time period end for the {{type}}.`,
    subsection: `Reference with *=[end_month]*.`,
  },
  "Reporting::Sidebar::ChartAndTable::ActualsDateBasedOn": {
    mainText: `The statement or worksheet to pull the last month of actuals date from.`,
  },
  "Reporting::Sidebar::ChartAndTable::TimePeriodInterval": {
    mainText: `Choose from months, quarters or years.`,
  },
  "Reporting::Sidebar::ChartAndTable::TimePeriodFormat": {
    mainText: `Select the time period display format.`,
    subsection: `Options:
mmm-yy, e.g. Jan-23
mmm yyyy, e.g. Jan 2023
mmmm yyyy, e.g. January 2023
`,
  },
  "Reporting::ReportModal::ReportName": {
    mainText: `***Report Name*** appears under the Reports on the left sidebar.`,
    subsection: `For example, **Marketing Funnel**`,
  },
  "Reporting::ReportModal::ReportDescription": {
    mainText: "***Description*** for the Report you’re about to create.",
    subsection: `For example, *“Company-wide update on monthly performance. View-only access by everyone in the leadership team.”*`,
  },
  "Settings::Integrations::Details::LastSync": {
    mainText: `Last time data for this integration was pulled from the source`,
  },
  "Settings::Integrations::Snowflake::AccessKey": {
    mainText: `TBD`,
  },
  "Settings::Integrations::Snowflake::SecretKey": {
    mainText: `TBD`,
  },
  "Settings::Integrations::QBO::ActualsEnd": {
    mainText: `The most recent month that gets pulled in from QuickBooks`,
    subsection: `Typically the most recently closed month by your accountant.`,
  },
  "Settings::Integrations::QBO::CustomTimePeriod": {
    mainText: `Choose the time period you want to keep in sync.`,
    subsection: `If you make historical changes to your QuickBooks, make sure to extend the sync time period on your next pull of actuals.`,
  },
  "Settings::Integrations::QBO::AccountNumbers": {
    mainText: `Prefix account names with their QBO account number`,
  },
  "Settings::Integrations::QBO::ConnectedCompany": {
    mainText: `The QuickBooks company this integration connects to`,
  },
  "Templates::Sidebar::FormulaBuilder::ForecastGranularityAccount": {
    mainText: `Determine the forecast granularity: forecast for the entire account, department-by-department, or create a vendor-specific forecast.`,
  },
  "Templates::Sidebar::FormulaBuilder::ForecastGranularityDepartment": {
    mainText: `Select the forecast granularity for this department: either forecast the entire department or on a per-vendor basis`,
  },
  "Templates::Sidebar::FormulaBuilder::FormulaType": {
    mainText: `The type of formula to use for this forecast.`,
  },
  "Templates::Sidebar::FormulaBuilder::Formula": {
    mainText: `The formula to use to forecast this row.`,
  },
  "Templates::Sidebar::FormulaBuilder::AverageOf": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::FormulaBuilder::RowReference": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::FormulaBuilder::DepartmentReference": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::FormulaBuilder::VendorReference": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::FormulaBuilder::ApplyFrom": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::FormulaBuilder::ApplyTo": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Sidebar::Settings::Formatting": {
    mainText: `Row formatting and borders.`,
  },
  "Templates::Sidebar::Integrations::Integration": {
    mainText: `TBD`,
  },
  "Templates::Sidebar::Integrations::DataSource": {
    mainText: `TBD`,
  },
  "Templates::Sidebar::Integrations::TimePeriod": {
    mainText: `TBD`,
  },
  "Templates::Sidebar::Settings::SanityChecks::CompareToRow": {
    mainText: `***Compare to Row*** lets you compare the current row value to another row in your financial model.`,
    subsection:
      "For example, you should know if your balance sheet balances by  comparing Assets to Liabilities & Equity.",
  },
  "Templates::Sidebar::Settings::SanityChecks::ComparisonSource": {
    mainText: `***Compare to QuickBooks*** enables you to compare a formula to the same value calculated by QuickBooks.`,
    subsection: `For example, you can compare your Net Income for your Actuals to the value calculated by QuickBooks Profit and Loss report (rounded to 2 decimals).

      These values should be the same, but a sanity check eliminates the chance of a typo or a row deleted by accident.`,
  },
  "Templates::Options::DisplayTimePeriod::TimePeriod": {
    mainText: `Use preselected time periods or set your own using *Custom*`,
  },
  "Templates::Options::DisplayTimePeriod::From": {
    mainText: `Select the first month to display.`,
  },
  "Templates::Options::DisplayTimePeriod::To": {
    mainText: `Select the last month to display.`,
  },
  "Templates::Options::DisplayTimePeriod::ActualsEnd": {
    mainText: `Select the last month of actuals.`,
  },
  "Templates::Options::DisplayTimePeriod::DisplayColumnsBy": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Options::DisplayTimePeriod::TotalColumn": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Templates::Options::Settings::DisplayActualsForecast": {
    mainText: `REPLACE_WITH_ACTUAL_TEXT`,
  },
  "Versions::SaveVersionModal::VersionName": {
    mainText: `***Version Name*** should be descriptive enough for you to quickly see what historical version of the model you’re looking at.`,
    subsection: "For example, Board Approved Budget 2022.",
  },
  "Settings::Departments::Details::Name": {
    mainText: `Name of the Department`,
  },
  "Settings::Departments::Details::Parent": {
    mainText: `Parent Department`,
  },
  "Settings::Departments::Details::ClassName": {
    mainText: `Name of the QuickBooks class assigned to this department`,
  },
} as const;

export type TooltipTexts = Record<string, {mainText: string; subsection?: string}>;

export type GetTemplatedVariables<S extends string, Acc = never> = S extends `${string}{{${infer K}}}${infer Rest}`
  ? GetTemplatedVariables<Rest, Acc | K>
  : Acc;

export type GetSubstitutions<T extends TooltipTexts, K extends keyof T, P extends keyof T[K]> = T[K][P] extends infer S
  ? GetTemplatedVariables<S extends string ? S : never> extends infer Keys
    ? // If there aren't any templated variables, `Keys` type is `never`. So we need to validate and propogate it
      // out to the function input type to allow us to make the substitutions field optional.
      [Keys] extends [never]
      ? never
      : // an obligatory typeguard to narrow `Keys` back to `string`
        {[K in Keys extends string ? Keys : never]: string}
    : never
  : never;

// type FnProps = {foo: "bar"};

// function displayStrBuilder<T extends TooltipTexts>(textStrings: T) {
//   function fn<
//     K extends keyof T,
//     Subs extends GetSubstitutions<T, K, "mainText"> | GetSubstitutions<T, K, "subsection">,
//   >(props: [Subs] extends [never] ? FnProps & {tooltip: K} : FnProps & {tooltip: K; variables: Subs}): string;
//   function fn(props: FnProps): string;
//   function fn<
//     K extends keyof T,
//     Subs extends GetSubstitutions<T, K, "mainText"> | GetSubstitutions<T, K, "subsection">,
//   >(props: [Subs] extends [never] ? FnProps & {tooltip?: K} : FnProps & {tooltip?: K; variables?: Subs}): string {
//     const tooltip = props.tooltip;
//     let str = tooltip ? `${textStrings[tooltip].mainText}` : "";
//     if ("variables" in props) {
//       for (const [key, value] of typedObjectEntries(props.variables || ({} as Subs))) {
//         str = str.replace(`{{${key}}}`, value);
//       }
//     }
//     return str;
//   }

//   return fn;
// }

// const fn = displayStrBuilder(tooltipTexts);

// fn({foo: "bar", tooltip: "HiringPlan::Employees::Sidebar::CompensationAmount", variables: {fcName: ""}});

export default tooltipTexts;
