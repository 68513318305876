import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  stepper: {
    display: "flex",
    alignItems: "center",
  },
  stepConnector: {
    flex: 1,
    height: 1,
    width: 20,
    borderRadius: 3,
    backgroundColor: colors.tertiary.lightBlue01,
    opacity: 0.5,
  },
  stepComplete: {opacity: 1},
  step: {
    backgroundColor: colors.tertiary.lightBlue01,
    width: 10,
    height: 10,
    margin: [0, 10],
    borderRadius: "50%",
    opacity: 0.5,
    transition: "all 0.3s ease",
  },
  completedStep: {
    width: 16,
    height: 16,
    backgroundColor: "transparent",
    border: [5, "solid", colors.tertiary.lightBlue01],
    opacity: 1,
  },
  inactiveStep: {},
  currentStep: {
    animation: "$pulse 1.25s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "50%": {
      transform: "scale(1.15)",
      opacity: 0.7,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 1,
    },
  },
});
