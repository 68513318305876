import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getAlertsAdapter} from "@state/entity-adapters";
import {fetchTemplates} from "@state/templates/slice";

import alertsAPI from "./api";

import type {PayloadAction} from "@reduxjs/toolkit";
import type {PartialDsError} from "@shared/lib/datasource-utilities";
import type {DsError} from "@shared/types/alerts";
import type {RootState} from "@state/store";

export const api = {
  fetchAlerts: createAsyncThunk("alerts/fetchAll", alertsAPI.fetchAll),
  upsertAlerts: createAsyncThunk("alerts/upsert", alertsAPI.upsert),
  deleteAlerts: createAsyncThunk("alerts/remove", alertsAPI.remove),
};

type DsErrorOptionalId = Omit<DsError, "id"> & {id?: string};
export const getSlice = () => {
  const alertsAdapter = getAlertsAdapter();
  return createSlice({
    name: "alerts",
    initialState: alertsAdapter.getInitialState(),
    reducers: {
      upsertAlertsForDs(state, action: PayloadAction<DsErrorOptionalId[]>) {
        // Needs to be done in listener
      },
      upsertAlertsForDsLocal(state, action: PayloadAction<DsErrorOptionalId[]>) {
        // Needs to be done in listener
      },
      upsertAlerts: alertsAdapter.upsertMany,
      /**
       * Given a datasourceId and a map of error types to DsError pr PartialDsError objects, sets them in the state for that datasource. This could be called for instance to clear errors of one type and remove errors of some of the types (by setting them to null)
       *
       * @param datasourceId
       * @param alerts
       *
       * @example
       * setAlertsForDatasource("1", {
       *  "errorType1": {message: "Error message"},
       *  "errorType2": null,
       *  "errorType3": {message: "Error message"},
       * })
       *
       **/
      setAlertsForDatasourceAndGetDiff: (
        state,
        action: PayloadAction<{
          datasourceId: string;
          alerts: {[errorType: string]: DsError | PartialDsError | null};
        }>,
      ) => {
        // Needs to be done in listener
      },
      clearAlertsForDatasource: (state, action: PayloadAction<string>) => {
        const idsToRemove = state.idsByDatasourceId[action.payload];
        if (idsToRemove?.length) {
          alertsAdapter.removeMany(state, idsToRemove);
        }
      },
      upsertAlertsLocal: alertsAdapter.upsertMany,
      deleteAlerts: alertsAdapter.removeMany,
    },
    extraReducers: (builder) => {
      builder.addCase(fetchTemplates.fulfilled, (state, action) => {
        alertsAdapter.setAll(state, action.payload.alerts);
      });
    },
  });
};

const _slice = getSlice();
export const {upsertAlertsLocal, upsertAlertsForDsLocal, clearAlertsForDatasource} = _slice.actions;
export const {upsertAlertsForDs, deleteAlerts, upsertAlerts} = createSyncedActionCreators(_slice.actions);
export const {
  selectById: selectAlertById,
  selectIds: selectAlertIds,
  selectEntities: selectAlertEntities,
  selectAll: selectAllAlerts,
  selectTotal: selectTotalAlerts,
} = getAlertsAdapter().getSelectors((state: RootState) => state.alerts);
