import {
  getDatasourceDiff,
  getEmptyDatasourceDiff,
  insertDatasources,
  mergeDatasourceDiffObjects,
} from "@shared/lib/datasource-utilities";
import {getFormattedFullDate} from "@shared/lib/date-utilities";
import id from "@shared/lib/id";
import {selectEmployeeFcDatasources} from "@state/datasources/selectors";
import {projKey} from "@state/utils";
import dayjs from "dayjs";

import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {HiringPlanFormulaDatasource} from "@shared/types/datasources";
import type {Template, TemplateOptions} from "@shared/types/db";
import type {Employee, FinancialComponent, Team} from "@shared/types/hiring-plan";
import type {RootState} from "@state/store";

interface FcChangesAfterHireTermDateUpdateParams {
  state: RootState;
  employeeId: string;
}
export function getDsDiffAfterEmployeeDatesChange({state, employeeId}: FcChangesAfterHireTermDateUpdateParams) {
  const scenarioId = state.global.scenarioId ?? "";
  const hiringPlanTemplate = Object.values(state.templates.entities).find(
    (template) => template?.type === "hiring_plan",
  );
  const employee = state.employees.entities[employeeId];
  const scenarioProps = employee?.scenario_properties[scenarioId];

  if (!employee || !hiringPlanTemplate || !scenarioProps) {
    return {deletedIds: [], upsertedDatasources: [], datasourceDiff: getEmptyDatasourceDiff()};
  }

  // Normalize dates to YYYY-MM-DD
  const newDatesResolved = {
    term_date: getFormattedFullDate(scenarioProps.term_date || hiringPlanTemplate.options.end, "end"),
    hire_date: getFormattedFullDate(scenarioProps.hire_date || hiringPlanTemplate.options.start, "start"),
  };

  const fcRowIds = state.templateRows.idsByTag[projKey("employee_id", employeeId)] ?? [];

  const deletedIds: string[] = [];
  const upsertedDatasources: HiringPlanFormulaDatasource[] = [];
  let datasourceDiff: DatasourceDiff = getEmptyDatasourceDiff();

  for (const id of fcRowIds) {
    const fcRow = state.templateRows.entities[id];
    if (!fcRow || fcRow.type !== "hiring-plan") continue;

    const fcDatasources = selectEmployeeFcDatasources(state, {
      employeeId,
      fcName: fcRow.options.fc_name,
      scenarioId: state.global.scenarioId,
    });

    const updatedDatasources: HiringPlanFormulaDatasource[] = [];
    const sortedDatasources = fcDatasources.toSorted((a, b) =>
      getFormattedFullDate(a.start || "0000-00-00", "start").localeCompare(
        getFormattedFullDate(b.start || "0000-00-00", "start"),
      ),
    );
    for (const [i, datasource] of sortedDatasources.entries()) {
      // Normalize dates to YYYY-MM-DD
      const datasourceEndDate = getFormattedFullDate(datasource.end ?? hiringPlanTemplate.options.end, "end");
      const datasourceStartDate = getFormattedFullDate(datasource.start ?? hiringPlanTemplate.options.start, "start");

      const isFirst = i === 0;
      const isLast = i === sortedDatasources.length - 1;

      // Now check is the datasource needs to be updated at all given the current values for hire_date and term_date
      if (datasourceStartDate > newDatesResolved.term_date || datasourceEndDate < newDatesResolved.hire_date) {
        // If the the start is after the new term_date or the end is before the new hire_date, we can now ignore this datasource so it can be removed
        continue;
      } else if (
        newDatesResolved.term_date < datasourceEndDate ||
        (isLast && newDatesResolved.term_date > datasourceEndDate)
      ) {
        // If the employee's term_date is before the datasource's end, we need to update the datasource's end
        updatedDatasources.push({...datasource, end: newDatesResolved.term_date});
      } else if (
        newDatesResolved.hire_date > datasourceStartDate ||
        (isFirst && newDatesResolved.hire_date < datasourceStartDate)
      ) {
        // If the employee's hire_date is after the datasource's start, we need to update the datasource's start
        updatedDatasources.push({...datasource, start: newDatesResolved.hire_date});
      } else {
        // If the datasource's start and end are within the new hire_date and term_date, we simply add it to the updatedDatasources untouched
        updatedDatasources.push(datasource);
      }
    }

    const localDatasourceDiff = getDatasourceDiff(fcDatasources, updatedDatasources, state);
    datasourceDiff = mergeDatasourceDiffObjects(datasourceDiff, localDatasourceDiff);

    deletedIds.push(...localDatasourceDiff.deletes.map((datasource) => datasource.id));
    upsertedDatasources.push(...localDatasourceDiff.upserts);
  }

  return {
    deletedIds,
    upsertedDatasources,
    datasourceDiff,
  };
}

export function getActiveFcDatasource(datasources: HiringPlanFormulaDatasource[], date?: string) {
  if (!datasources?.length) return null;

  const currentDate = date || dayjs().format("YYYY-MM-DD");
  let selectedDatasource = datasources.at(-1);
  for (const datasource of datasources) {
    const formattedStart = datasource.start ? getFormattedFullDate(datasource.start, "start") : "0000-00-00";
    const formattedEnd = datasource.end ? getFormattedFullDate(datasource.end, "end") : "9999-99-99";
    if (formattedStart <= currentDate && formattedEnd >= currentDate) {
      selectedDatasource = datasource;
    }
  }

  return selectedDatasource;
}

export function getDatasourceOptionsForNewFc(
  fc: FinancialComponent,
  employeeId: string,
  hireDate: string,
  termDate: string | null | undefined,
) {
  return {
    start: hireDate,
    end: termDate || null,
    options: {
      fc_name: fc.name,
      employee_id: employeeId,
      formula: ``,
      ui: {
        type: "amount",
        value: "",
        expressedAs: fc.type === "salary" ? "yearly" : "monthly",
      },
    } as HiringPlanFormulaDatasource["options"],
  };
}

export function getDefaultFcDatasourceForTeam(
  team: Team | null,
  fc: FinancialComponent,
  row_id: string,
  scenario_id: string,
  employee_id: string,
  hireDate: string,
  termDate: string | null | undefined,
): HiringPlanFormulaDatasource {
  const defaults = team?.defaults.values?.[fc.name];

  const options = !defaults
    ? getDatasourceOptionsForNewFc(fc, employee_id, hireDate, termDate)
    : {
        start: hireDate || null,
        end: termDate || null,
        options: {
          ui: defaults[0].dataProvider.options.ui,
          formula: defaults[0].dataProvider.options.formula,
          employee_id,
          fc_name: fc.name,
        },
      };

  return {
    id: id(),
    row_id,
    scenario_id,
    department_id: team?.department_id ?? null,
    type: "hiring-plan-formula",
    ...options,
  };
}

export function getDatasourceChangesAfterFcUpdate({
  datasources,
  updatedDatasource,
  templateOptions,
  replace = true,
  employee,
}: {
  datasources: HiringPlanFormulaDatasource[];
  updatedDatasource: HiringPlanFormulaDatasource;
  templateOptions: TemplateOptions;
  replace?: boolean;
  employee: Employee;
}) {
  // console.log(
  //   "input:",
  //   JSON.stringify({
  //     ranges,
  //     value,
  //     property,
  //     index,
  //   }),
  // );

  const alteredTemplateOptions = getForecastDatesForEmployee({
    employee,
    hiringPlanTemplateOptions: templateOptions,
    scenarioId: updatedDatasource.scenario_id,
  });

  const {datasources: newDatasources, deletedIds} = insertDatasources({
    existingDatasources: datasources,
    datasourcesToAdd: [updatedDatasource],
    forecastDates: alteredTemplateOptions,
    method: replace ? "replace" : "add",
    extendToFillGaps: true,
  });

  // console.log("output:", JSON.stringify(newRanges));

  return {upserts: newDatasources, deletes: deletedIds};
}

export function getUpsertsAfterFcDatasourceRemoval(
  sortedDatasources: HiringPlanFormulaDatasource[],
  datasourceToRemove: HiringPlanFormulaDatasource,
) {
  const index = sortedDatasources.indexOf(datasourceToRemove);

  const previousDatasource = sortedDatasources[index - 1];
  const nextDatasource = sortedDatasources[index + 1];

  let updatedPreviousDatasource = null;
  let updatedNextDatasource = null;
  if (previousDatasource) {
    updatedPreviousDatasource = {
      ...previousDatasource,
      end: datasourceToRemove.end,
    };
  } else if (nextDatasource) {
    updatedNextDatasource = {
      ...nextDatasource,
      start: datasourceToRemove.start,
    };
  }

  const upserts: HiringPlanFormulaDatasource[] = [];
  if (updatedPreviousDatasource) upserts.push(updatedPreviousDatasource);
  if (updatedNextDatasource) upserts.push(updatedNextDatasource);

  return upserts;
}

export function getFormulaFromFcUiOptions(uiOptions: HiringPlanFormulaDatasource["options"]["ui"]) {
  if (!uiOptions.value) return "";
  if (uiOptions.type === "amount") {
    return `=${uiOptions.value}${uiOptions.expressedAs === "yearly" ? "/12" : ""}`;
  } else {
    console.error(`Amount type ${uiOptions.type} not implemented`);
    return "";
  }
}

export function getForecastDatesForEmployee({
  hiringPlanTemplateOptions,
  employee,
  scenarioId,
}: {
  hiringPlanTemplateOptions: TemplateOptions;
  employee: Employee;
  scenarioId: string;
}) {
  const newTemplateOptions = {...hiringPlanTemplateOptions};

  if (employee) {
    const hireDate = employee.scenario_properties[scenarioId]?.hire_date;
    const termDate = employee.scenario_properties[scenarioId]?.term_date;
    if (hireDate) newTemplateOptions.start = hireDate;
    if (termDate && getFormattedFullDate(newTemplateOptions.end, "end") > termDate) newTemplateOptions.end = termDate;
  }

  return newTemplateOptions;
}

export function getFcDatasourceStartForDisplay({
  hiringPlanTemplate,
  hireDate,
  datasource,
}: {
  hiringPlanTemplate: Template;
  hireDate: string;
  datasource: HiringPlanFormulaDatasource;
}) {
  const hireDateForDisplay = getFormattedFullDate(hireDate, "start", "MM/DD/YYYY");

  let displayStartDate = datasource.start
    ? getFormattedFullDate(datasource.start, "start", "MM/DD/YYYY")
    : hireDateForDisplay || "";

  const displayHiringPlanStartDate = getFormattedFullDate(hiringPlanTemplate.options.start, "start", "MM/DD/YYYY");
  let datepickerDisabled = displayStartDate === hireDateForDisplay;

  if (displayStartDate === displayHiringPlanStartDate) {
    datepickerDisabled = true;
    displayStartDate = hireDateForDisplay;
  }

  return {displayStartDate, datepickerDisabled};
}
