import api from "@shared/lib/fetch";

import type {ApiEndpoints} from "@shared/state/store";
import type {Team} from "@shared/types/hiring-plan";

const endpoints = {
  upsertTeam(team: Team, thunkAPI) {
    return api.put<Team>(thunkAPI, `hiring-plan/teams/${team.id}`, team);
  },
  removeTeam(team: Team, thunkAPI) {
    return api.del<Team>(thunkAPI, `hiring-plan/teams/${team.id}`, team);
  },
} satisfies ApiEndpoints;

export default endpoints;
