import {createUseStyles} from "react-jss";

import color from "color";

import colors from "@app/styles/colors";

export const sharedValues = {
  scenarioDropdownMarginRight: 18,
  scenarioDropdownDefaultWidth: 223,
};

export default createUseStyles({
  scenarioDropdownWrapper: {
    cursor: "pointer",
    display: "inline-flex",
    userSelect: "none",
  },
  iconButtonRight: {
    "&$iconButtonRight": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderColor: color(colors.primary.lightGold03).alpha(0.5).rgb().string(),
      background: "white",
      padding: 12.5,

      "& > div": {
        marginLeft: 1,
      },
    },
  },
  dropdownButton: ({showDisplayOptions}: {showDisplayOptions: boolean}) => ({
    "&$dropdownButton": {
      borderTopRightRadius: showDisplayOptions ? 0 : 5,
      borderBottomRightRadius: showDisplayOptions ? 0 : 5,
    },
  }),
});
