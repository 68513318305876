import {useAppDispatch, useAppSelector} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import RightSidebar from "@components/RightSidebar";
import RoundButton from "@components/RoundButton";
import SimpleTooltip from "@components/Tooltip/Simple";
import {selectors} from "@features/templates/state/selectors";
import {updateTemplatesViewState} from "@features/templates/state/slice";
import {getEmployeeDisplayName} from "@shared/lib/employee-utilities";
import {selectVersionLocked} from "@shared/state/global/slice";
import {removeEmployees} from "@state/employees/slice";
import {startTransition} from "react";

import {useSelectedEmployee} from "../hooks";
import {setSelectedEmployeeId, setSidebarOpen} from "../slice";
import SidebarTabs from "./SidebarTabs";

export type HiringPlanSidebarProps = {
  context: "hiringPlan" | "templatesView";
};
export default function HiringPlanSidebar({context}: HiringPlanSidebarProps) {
  const employee = useSelectedEmployee();
  let isOpen = useAppSelector((state) => state.hiringPlan.sidebarOpen);
  const {rowId} = useAppSelector(selectors.activeCell);
  const dispatch = useAppDispatch();
  const isVersionLocked = useAppSelector(selectVersionLocked);

  if (context === "templatesView") {
    isOpen = !!rowId;
  }

  const displayName = getEmployeeDisplayName(employee);

  const handleRemoveEmployee: React.MouseEventHandler<HTMLElement> = () => {
    if (!employee) return;

    dispatch(
      askForConfirmation({
        preset: "delete",
        actionButtonIcon: "trash",
        text: `Are you sure you want to <b>delete employee ${displayName} from all scenarios</b>?<br/><br/>Please note that this action cannot be undone, and all employee data will be permanently deleted.`,
        onConfirm: () => {
          dispatch(removeEmployees(employee));
          dispatch(setSelectedEmployeeId(null));
          dispatch(setSidebarOpen(false));
          dispatch(updateTemplatesViewState({activeCell: {rowId: null, column: null}}));
        },
      }),
    );
  };

  const handleClose = () => {
    dispatch(setSidebarOpen(false));
    startTransition(() => {
      dispatch(updateTemplatesViewState({activeCell: {rowId: null, column: null}}));
    });
  };

  return (
    <RightSidebar
      animate
      isOpen={isOpen}
      onClose={handleClose}
      title={getEmployeeDisplayName(employee) || "Unnamed Employee"}
      topRightButtons={
        isVersionLocked
          ? []
          : [
              <SimpleTooltip key="delete" text="Delete Employee" placement="left">
                <RoundButton enableCssStates icon="trash" onClick={handleRemoveEmployee} />
              </SimpleTooltip>,
            ]
      }
    >
      <>
        <SidebarTabs />
      </>
    </RightSidebar>
  );
}
