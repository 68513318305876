import Dropdown from "@components/Dropdown";
import RoundButton from "@components/RoundButton";
import SVGIcon from "@components/SVGIcon";
import clsx from "clsx";

import useStyles from "./Breadcrumbs.jss";

import type {DropdownItem} from "@components/Dropdown";
import type {IconNames} from "@components/SVGIcon";

export type BreadcrumbItem = {
  text: string;
  dropdownItems?: DropdownItem[];
  dropdownWidth?: number;
  onDropdownChange?: (item: DropdownItem) => void;
  rightButtonIcon?: IconNames | null;
  rightButtonOnClick?: () => void | null;
};

export interface BreadcrumbItemProps {
  item: BreadcrumbItem;
  addSeparator?: boolean;
}

export default function BreadcrumbItemComponent({item, addSeparator = true}: BreadcrumbItemProps) {
  const styles = useStyles();
  return (
    <>
      {item.dropdownItems?.length ? (
        <>
          <Dropdown
            items={item.dropdownItems}
            onSelect={item.onDropdownChange ? item.onDropdownChange : () => null}
            position="bottom"
            align="center"
            width={item.dropdownWidth ?? 175}
            openTriggerClassName={styles.active}
          >
            <div className={clsx(styles.dropdown, item.text.includes("Version: ") ? styles.versionBcItem : "")}>
              <span>{item.text}</span>
              <span className={styles.arrow}>
                <SVGIcon name="dropdown" />
              </span>
            </div>
          </Dropdown>
          {item.rightButtonIcon && item.rightButtonOnClick ? (
            <RoundButton
              icon={item.rightButtonIcon}
              onClick={item.rightButtonOnClick}
              enableCssStates
              className={styles.rightButton}
            />
          ) : null}
          {addSeparator ? <span className={styles.separator}>/</span> : null}
        </>
      ) : (
        <>
          <span>{item.text}</span>
          {addSeparator ? <span className={styles.separator}>/</span> : null}
        </>
      )}
    </>
  );
}
