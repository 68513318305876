import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import sessionAPI from "@shared/apis/session";

import type {RootState} from "@shared/state/store";
import type {User} from "@shared/types/db";

export const fetchSession = createAsyncThunk("session/fetchSession", sessionAPI.fetchSession);
export const deleteSession = createAsyncThunk("session/deleteSession", sessionAPI.logout);
export const authLocal = createAsyncThunk("session/authLocal", sessionAPI.authLocal);
export const acceptInvite = createAsyncThunk("session/authLocal", sessionAPI.acceptInvite);

export const slice = createSlice({
  name: "session",
  initialState: {
    fetchingSession: false,
    loggingOut: false,
    authenticating: false,
    user: null as null | User,
    authError: null as null | string,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authLocal.fulfilled, (state, action) => {
      state.user = action.payload;
      state.authenticating = false;
    });
    builder.addCase(authLocal.pending, (state) => {
      state.authenticating = true;
    });
    builder.addCase(authLocal.rejected, (state, action) => {
      state.user = null;
      state.authenticating = false;
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      state.user = action.payload;
      state.fetchingSession = false;
    });
    builder.addCase(fetchSession.pending, (state) => {
      state.fetchingSession = true;
    });
    builder.addCase(fetchSession.rejected, (state, action) => {
      state.fetchingSession = false;
      state.user = null;
    });
    builder.addCase(deleteSession.pending, (state) => {
      state.loggingOut = true;
    });
  },
});

const reducer = slice.reducer;

export const selectCurrentUser = (state: RootState) => state.session.user;
export const selectFetchingSession = (state: RootState) => state.session.fetchingSession;
export const selectLoggingOut = (state: RootState) => state.session.loggingOut;

export const selectIsSuperuser = (state: RootState) => state.session.user?.role === "superuser";

export default reducer;
