import {createUseStyles} from "react-jss";

export default createUseStyles({
  headsUpNotificationsMain: {
    position: "fixed",
    top: 50,
    right: 25,
    width: 1,
    height: 1,
    zIndex: 5,
    overflow: "visible",
  },
  listWrapper: {
    position: "absolute",
    right: 0,
    top: 0,

    "& > div:not(:first-child)": {
      marginTop: 15,
    },
  },
});
