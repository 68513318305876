import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  simpleTooltipMain: {
    lineHeight: 24,
    fontSize: 12,
    fontWeight: 500,
    color: colors.text.darkBlue01,
    padding: [4, 10, 2, 10],
  },
});
