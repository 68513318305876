import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {getIdsFromPayload} from "@shared/lib/entity-functions";
import {projKey, projKeyOmitNulls} from "@shared/state/utils";
import {getDatasourcesAdapter} from "@state/entity-adapters";

import {extractFromCacheKey, getQboClassToDeptIdMapping, noClassIdentifier} from "../cache/cache-utilities";

import type {EntityId} from "@reduxjs/toolkit";
import type {DatasourceDiff} from "@shared/lib/datasource-utilities";
import type {BasicStore, RootState} from "@state/store";
import type {
  BubbleUpRefreshForTags,
  BubbleUpRefreshParams,
  BubbleUpRefreshParamsForCacheKeys,
  BubbleUpRefreshParamsForRowIds,
  BubbleUpRefreshParamsForTxItemIds,
} from "./bubble-up-refresh";

const {removeMany: removeManyDatasources, upsertMany: upsertManyDatasources} = getDatasourcesAdapter();

export function getAffectedRows(
  payload: {
    storeInstance: BasicStore | RootState;
    basedOn: BubbleUpRefreshParams["basedOn"];
  } & (
    | BubbleUpRefreshForTags
    | BubbleUpRefreshParamsForRowIds
    | BubbleUpRefreshParamsForTxItemIds
    | BubbleUpRefreshParamsForCacheKeys
  ),
) {
  const state = "getState" in payload.storeInstance ? payload.storeInstance.getState() : payload.storeInstance;
  // Final list of scenario -> rowId -> datekeys for which we need to check for changes
  const affectedCacheKeys: Record<string, Record<string, true>> = {};
  const qboClassToDeptIdMapping = getQboClassToDeptIdMapping(state.departments);

  switch (payload.basedOn) {
    case "rowIds": {
      const {dateKeys: initialDateKeys, rowIds, scenarioId} = payload;
      affectedCacheKeys[scenarioId] ||= {};
      for (const rowId of rowIds) {
        const dateKeys = initialDateKeys
          ? new Set(initialDateKeys)
          : getAllTemplateDateKeysForRowId({...payload, state}, rowId);
        for (const dateKey of dateKeys) {
          const cacheKey = projKey(rowId, scenarioId, dateKey);
          affectedCacheKeys[scenarioId][cacheKey] = true;
        }
      }
      break;
    }
    case "tags": {
      const {dateKeys: initialDateKeys, tags, scenarioId} = payload;
      const dateKeys = initialDateKeys ? new Set(initialDateKeys) : null;
      affectedCacheKeys[scenarioId] ||= {};

      for (const tag of tags) {
        // Gather the row ids that those tags include
        // eslint-disable-next-line unicorn/consistent-destructuring
        const idsMatchingTag = state.templateRows.idsByTag[tag] || [];
        for (const rowId of idsMatchingTag) {
          for (const dateKey of dateKeys || getAllTemplateDateKeysForRowId({...payload, state}, rowId)) {
            const cacheKey = projKey(rowId, scenarioId, dateKey);
            affectedCacheKeys[scenarioId][cacheKey] = true;
          }
        }
      }
      break;
    }
    case "txItems": {
      // Gather all changed rowId and dateKey pairs
      // eslint-disable-next-line unicorn/consistent-destructuring
      for (const txItem of payload.txItems) {
        affectedCacheKeys[txItem.scenario_id] ||= {};
        let department = state.departments.entities[txItem.department_id ?? ""];
        if (!department && txItem.tags?.qbo_class) {
          const departmentId =
            qboClassToDeptIdMapping[txItem.tags.qbo_class] ?? qboClassToDeptIdMapping[noClassIdentifier];
          if (departmentId) department = state.departments.entities[departmentId];
        }

        const cacheKey = projKeyOmitNulls(
          txItem.row_id,
          department?.id,
          txItem.tags?.qbo_vendor_id,
          txItem.scenario_id,
          txItem.date.slice(0, 7),
        );
        affectedCacheKeys[txItem.scenario_id][cacheKey] = true;
      }
      break;
    }
    case "datasourceDiff": {
      const updatedCacheKeys = Object.values(payload.datasourceDiff.cacheKeysUpdatedPerDsId ?? {});
      const deletedCacheKeys = Object.values(payload.datasourceDiff.cacheKeysRemovedPerDsId ?? {});
      const cacheKeys = [deletedCacheKeys, updatedCacheKeys].flat(2);
      for (const cacheKey of cacheKeys) {
        const scenarioId = extractFromCacheKey(cacheKey, "scenarioId");
        affectedCacheKeys[scenarioId] ||= {};
        affectedCacheKeys[scenarioId][cacheKey] = true;
      }
    }
  }
  return affectedCacheKeys;
}
function getAllTemplateDateKeysForRowId(
  payload: Omit<BubbleUpRefreshParams, "storeInstance"> & {state: RootState},
  rowId: string,
) {
  const state = payload.state;
  const row = state.templateRows.entities[rowId];
  if (!row) return [];
  const template = state.templates.entities[row.template_id];
  if (!template) return [];

  return getAllDateKeysBetween(template.options.start, template.options.end);
}

export function setNewValueInDiffAndCache(
  cacheKey: string,
  newValue: number | null | undefined,
  existingValue: number | null | undefined,
  diff: {updatedCacheKeys: Record<string, number>; deletedCacheKeys: string[]},
  cache: Record<string, number>,
) {
  if (newValue && newValue !== existingValue) {
    cache[cacheKey] = newValue;
    diff.updatedCacheKeys[cacheKey] = newValue;
  } else if (cache[cacheKey] && !newValue) {
    // If the balance is now 0, delete the cache key
    delete cache[cacheKey];
    diff.deletedCacheKeys.push(cacheKey);
  }
}

export function revertDsChangesToState(state: RootState, datasourceDiff: DatasourceDiff) {
  const addedDatasourceIds: EntityId[] = [];
  const oldDatasourcesById = Object.fromEntries(datasourceDiff.upsertedPreviousVersions.map((ds) => [ds.id, ds]));

  for (const dsId of datasourceDiff.upserts.map((ds) => ds.id)) {
    if (!oldDatasourcesById[dsId]) addedDatasourceIds.push(dsId);
  }

  state.datasources = upsertManyDatasources(state.datasources, datasourceDiff.deletes);
  state.datasources = upsertManyDatasources(state.datasources, datasourceDiff.upsertedPreviousVersions);
  state.datasources = removeManyDatasources(state.datasources, addedDatasourceIds);

  return state;
}

export function applyDsChangesToState(state: RootState, datasourceDiff: DatasourceDiff) {
  state.datasources = upsertManyDatasources(state.datasources, datasourceDiff.upserts);
  state.datasources = removeManyDatasources(state.datasources, getIdsFromPayload(datasourceDiff.deletes));

  return state;
}
