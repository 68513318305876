import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import templateRowsAPI from "@shared/apis/template-rows";
import {getIdsFromPayload} from "@shared/lib/entity-functions";
import {createSyncedActionCreators} from "@shared/lib/misc";
import {getTemplateRowsAdapter} from "@shared/state/entity-adapters";

import {fetchTemplates} from "../templates/slice";

import type {EntityId, PayloadAction} from "@reduxjs/toolkit";
import type {RemoveEntitiesActionPayload} from "@shared/lib/entity-functions";
import type {TemplateRowsState} from "@shared/state/entity-adapters";
import type {RootState} from "@shared/state/store";
import type {TemplateRow} from "@shared/types/db";

export const api = {
  upsert: createAsyncThunk("template-rows/upsert", templateRowsAPI.upsert),
  delete: createAsyncThunk("template-rows/delete", templateRowsAPI.delete),
};

export const getSlice = () => {
  const templateRowsAdapter = getTemplateRowsAdapter();
  return createSlice({
    name: "templateRows",
    initialState: {...templateRowsAdapter.getInitialState(), rowIdToVendorsMapping: {} as Record<string, string[]>},
    reducers: {
      setRowIdToVendorsMapping(state, action: PayloadAction<Record<string, string[]>>) {
        state.rowIdToVendorsMapping = action.payload;
      },
      addRows<S extends TemplateRowsState, T extends TemplateRow>(
        state: S,
        action: PayloadAction<{templateId: string; rows: T[]; index?: number}>,
      ) {
        templateRowsAdapter.addMany(state, action.payload.rows);
      },
      updateRowIntegration(
        _state,
        _action: PayloadAction<{
          rowId: string;
          integrationId: string | null;
          type: "col" | "integration";
          value: string | null;
        }>,
      ) {},
      upsertRows(state, action: PayloadAction<TemplateRow[]>) {
        if (action.payload) templateRowsAdapter.upsertMany(state, action.payload);
      },
      upsertRowsLocal(state, action: PayloadAction<TemplateRow[]>) {
        if (action.payload) templateRowsAdapter.upsertMany(state, action.payload);
      },
      upsertRow(state, action: PayloadAction<TemplateRow | null>) {
        if (action.payload) templateRowsAdapter.upsertOne(state, action.payload);
      },
      removeRow(state, action: PayloadAction<EntityId>) {
        if (action.payload) templateRowsAdapter.removeOne(state, action.payload);
      },
      removeRows(state, action: PayloadAction<RemoveEntitiesActionPayload>) {
        if (action.payload) templateRowsAdapter.removeMany(state, getIdsFromPayload(action.payload));
      },
      removeRowsLocal(state, action: PayloadAction<RemoveEntitiesActionPayload>) {
        if (action.payload) templateRowsAdapter.removeMany(state, getIdsFromPayload(action.payload));
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchTemplates.fulfilled, (state, action) => {
        // ensureWebWorker();
        // if (DEBUG) console.time("Reconstruct datasource ranges");
        // mutateReconstructDataSourceRanges(action.payload.templateRows, action.payload.templates);
        // if (DEBUG) console.timeEnd("Reconstruct datasource ranges");

        templateRowsAdapter.setAll(state, action.payload.templateRows);
        state.rowIdToVendorsMapping = action.payload.rowIdToVendorsMapping;
      });
    },
  });
};

const _slice = getSlice();

export const {upsertRowsLocal, removeRowsLocal, setRowIdToVendorsMapping, updateRowIntegration} = _slice.actions;

export const {addRows, removeRow, upsertRow, upsertRows, removeRows} = createSyncedActionCreators(_slice.actions);

export type TemplateRowsActionsType = typeof _slice.actions;

export * from "./selectors";

export const {
  selectIds: selectTemplateRowIds,
  selectEntities: selectTemplateRowEntities,
  selectAll: selectAllTemplateRows,
  selectTotal: selectTotalTemplateRows,
} = getTemplateRowsAdapter().getSelectors((state: RootState) => state.templateRows);
