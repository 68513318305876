import {createSelector} from "@reduxjs/toolkit";
import {getActiveFcDatasource} from "@shared/data-functions/hiring-plan/fc-datasource-utilities";
import {itemIsNotFalsy} from "@shared/lib/misc";
import {selectEmployeeFcDatasourcesSorted} from "@state/datasources/selectors";
import {selectTemplateRowsState} from "@state/template-rows/slice";
import dayjs from "dayjs";

import type {HiringPlanTemplateRow} from "@shared/types/db";
import type {RootState} from "@state/store";

export const selectFcRowsForEmployeeId = createSelector(
  selectTemplateRowsState,
  (_state: RootState, {employeeId}: {employeeId: string}) => employeeId,
  (templateRowsState, employeeId) => {
    const employeeFcRowIds = templateRowsState.idsByTag[`employee_id::${employeeId}`] || [];
    const employeeFcRows = employeeFcRowIds
      .map((id) => templateRowsState.entities[id])
      .filter(itemIsNotFalsy)
      .filter((item): item is HiringPlanTemplateRow => item.type === "hiring-plan");

    return employeeFcRows;
  },
);

export const selectActiveRangeForFc = createSelector(
  selectEmployeeFcDatasourcesSorted,
  (
    _state: RootState,
    args: {employeeId: string; fcName: string; index: number; hireDate?: string; termDate?: string},
  ) => args,
  (sortedFcDatasources, {index = 0, hireDate, termDate}) => {
    // if (employeeId === "4e590100-4bcb-4644-ba36-4a3845f209ac") debugger;
    const currentDate = dayjs().format("YYYY-MM-DD");
    let dateToUse = currentDate;
    if (hireDate && hireDate > currentDate) dateToUse = hireDate;
    if (termDate && termDate < currentDate) dateToUse = termDate;

    const activeDatasource = getActiveFcDatasource(sortedFcDatasources, dateToUse);
    if (!activeDatasource) return null;
    const datasourceIndex = sortedFcDatasources.findIndex((item) => item.id === activeDatasource.id);
    return sortedFcDatasources[datasourceIndex + index] || null;
  },
);
