import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export default createUseStyles({
  mainPanel: {
    background: colors.ui.white,
    borderRadius: 5,
    boxShadow: "0px 7px 30px 0px #00000024",
    position: "fixed",
    top: 10, // Center vertically
    left: "50%", // Center horizontally
    transform: "translateX(-50%)", // Adjust position to truly center the element
    minWidth: 800,
    maxWidth: "60%",
    margin: [0, "auto"],
    zIndex: 1000, // Keep on top
    padding: 15,
    color: colors.ui.black,
    fontSize: 14,
    fontWeight: 500,

    "& h1": {
      fontSize: 16,
      fontWeight: 600,
      paddingBottom: 10,
      marginBottom: 8,
      width: "100%",
      display: "flex",
      alignItems: "center",
    },

    "&:not($expanded)": {
      borderBottom: `1px solid ${colors.ui.lightBlue05}`,
    },

    "& h2": {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 8,
    },
  },
  tabsWrapper: {
    display: "none",
    "&$expanded": {
      display: "block",
    },
  },
  expanded: {},
  scrollable: {
    maxHeight: `calc(100vh - 100px)`, // 100px for the header
    overflowY: "auto",
    paddingTop: 25,
  },
  controls: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
    alignItems: "center",

    "& > div": {
      marginLeft: 10,
    },
  },
});
