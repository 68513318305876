import {defaultMotionFadeIn} from "@app/styles/general-ui";
import {confirmPresets} from "@app/ui-state/presets";
import Button from "@components/Button/V2";
import RoundButton from "@components/RoundButton";
import clsx from "clsx";
import {lazy, Suspense} from "react";
import {createPortal} from "react-dom";

import useStyles from "./styles.jss";

import type {ConfirmProps} from "@app/ui-state/slice";
import type {CSSProperties} from "react";

const AnimatePresence = lazy(async () => ({default: (await import("framer-motion")).AnimatePresence}));
const MotionDiv = lazy(async () => ({default: (await import("framer-motion")).motion.div}));

export default function Confirm({
  title,
  text,
  onConfirm,
  onCancel,
  backgroundImage,
  mainColor,
  actionButtonText,
  preset,
  cancelButton = true,
  closeButton = true,
  confirmButton = true,
  actionButtonIcon,
  actionButtonIconSize,
  cancelButtonIcon,
  cancelButtonIconSize,
}: ConfirmProps) {
  const styles = useStyles({mainColor});
  const classes = clsx(styles.confirmMain);

  if (preset) {
    title ||= confirmPresets[preset].title;
    actionButtonText ||= confirmPresets[preset].actionButtonText;
    backgroundImage ||= confirmPresets[preset].backgroundImage;
    mainColor ||= confirmPresets[preset].mainColor;
  }

  const domElement = document.querySelector("#root");

  if (!domElement) return null;

  const mainStyles: CSSProperties = {
    backgroundImage: `url('${backgroundImage}')`,
  };

  const confirmButtonJSX =
    confirmButton === true ? (
      <Button
        color={mainColor}
        text={actionButtonText}
        onClick={onConfirm}
        iconLeft={actionButtonIcon}
        iconLeftSize={actionButtonIconSize}
      />
    ) : (
      confirmButton ?? null
    );

  return createPortal(
    <div className={styles.backdrop}>
      <Suspense>
        <AnimatePresence>
          <MotionDiv {...defaultMotionFadeIn} className={classes} style={mainStyles}>
            {closeButton ? (
              <div className={styles.closeButton} onClick={onCancel}>
                <RoundButton enableCssStates onClick={onCancel} icon="cross" iconSize={22} white />
              </div>
            ) : null}
            <div className={styles.title}>{title}</div>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}} />
            <div className={styles.actionButtons}>
              {cancelButton ? (
                <Button
                  text="Cancel"
                  isLink
                  onClick={onCancel}
                  iconLeft={cancelButtonIcon}
                  iconLeftSize={cancelButtonIconSize}
                />
              ) : null}
              {confirmButtonJSX}
            </div>
          </MotionDiv>
        </AnimatePresence>
      </Suspense>
    </div>,
    domElement,
  );
}
