import {combineReducers, configureStore, nanoid} from "@reduxjs/toolkit";
import {isNode, isWebWorker} from "browser-or-node";
import {setAutoFreeze} from "immer";

import {getReducers as getSharedReducers, sliceNamesToDisableWorkerSyncFor} from "./reducers";

setAutoFreeze(false);

import {getListenerMiddleware} from "./listener-middleware";

import type {
  AnyAction,
  AsyncThunkPayloadCreator,
  Dispatch,
  EmptyObject,
  Middleware,
  Reducer,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import type {ThunkAPI, ThunkExtraArg} from "@shared/lib/fetch";
import type {ClientReduxStore} from "client/app/client-store";
import type {ThunkAction} from "redux-thunk";

const sharedReducers = getSharedReducers();
const combinedSharedReducers = combineReducers(sharedReducers);

export function getStore<R extends Reducer, M extends Middleware[]>(
  reducers: R,
  additionalMiddlewares?: M | null,
  thunkExtra?: any,
) {
  const {listenerMiddleware, startListening} = getListenerMiddleware();
  const middlewares: Middleware[] = [...(additionalMiddlewares || []), listenerMiddleware.middleware];
  if (process.env.NODE_ENV !== "production") {
    //middlewares.push(require("redux-immutable-state-invariant").default(), require("redux-freeze"));
  }

  const rootReducer = (state: ReturnType<R> | undefined, action: AnyAction) => {
    if (action.type === "RESET_APP_STATE") {
      // check for action type
      return reducers(undefined, action);
    }
    return reducers(state, action);
  };

  const storeInstance = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      type DefaultMiddlewareOptions = Parameters<typeof getDefaultMiddleware>[0];

      const options: DefaultMiddlewareOptions = {
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: {
            isNode,
            ...(thunkExtra || {}),
          },
        },
      };
      return [...getDefaultMiddleware(options), ...middlewares];
    },
  });

  return {...storeInstance, id: nanoid(), startListening};
}

// export type AppReduxStore<R extends Reducer = typeof combinedSharedReducers> = EnhancedStore<
//   ReturnType<R>,
//   AnyAction,
//   ThunkMiddleware<ReturnType<R>, AnyAction, undefined>[]
// >;
type ReducersType = typeof combinedSharedReducers;
type ExtractRootStateFromReducers<R> = R extends Reducer<infer S> ? S : never;
type RemoveEmptyObjectFromRootState<R> = R extends EmptyObject & infer S ? S : never;

export type RootState = RemoveEmptyObjectFromRootState<ExtractRootStateFromReducers<ReducersType>>;
export type AppReduxStore = ReturnType<typeof getStore<ReducersType, Middleware[]>>;
export type BasicStore = Pick<AppReduxStore | ClientReduxStore, "dispatch" | "getState">;

export type AppActionMeta = {
  initialDispatcher: "user" | "websocket";
  _isThreadSync?: boolean;
  receivedFromWs?: unknown; // Make sure no old receivedFromWs parameters are left over
  sync?: boolean;
  sendToOtherThreadFirst?: boolean;
  origin?: unknown; // Make sure no old origin parameters are left over
  hasBeenSyncedToServer?: boolean;
  listenerTypesRegistered?: ("local" | "api-save")[];
};
export type AppActionWithMeta = AnyAction & {
  meta: AppActionMeta;
};

export type AppDispatch = Dispatch;
export type AppGetStateAndDispatch = Pick<AppReduxStore, "dispatch" | "getState">;

export type ThunkPayloadCreator = AsyncThunkPayloadCreator<any, any>;
export type AppThunkPayloadCreator = (arg: Parameters<ThunkPayloadCreator>[0], thunkAPI: ThunkAPI) => any;

type AsyncThunkConfig = {
  state?: RootState;
  dispatch?: AppThunkDispatch;
};
export type AppThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;
export type AppThunkDispatch = ThunkDispatch<RootState, ThunkExtraArg, AnyAction>;

export type ApiEndpoints = Record<string, AsyncThunkPayloadCreator<any, any, {extra: ThunkAPI["extra"]}>>;

export const workerSyncMiddleware =
  (otherThreadDispatchFn: (action: AnyAction) => Promise<void>): Middleware =>
  (store) =>
  (next) =>
  (action) => {
    if (!action.meta?.sendToOtherThreadFirst) next(action);
    if (!isNode) {
      dispatchInOtherThreadIfNeeded(action, otherThreadDispatchFn);
    }
    if (action.meta?.sendToOtherThreadFirst) next(action);
  };

function dispatchInOtherThreadIfNeeded(
  action: AppActionWithMeta,
  otherThreadDispatchFn: (action: AppActionWithMeta) => Promise<void>,
) {
  if (action.meta._isThreadSync) return;
  const sliceName = action.type.split("/")[0];
  if (sliceName in sharedReducers && !sliceNamesToDisableWorkerSyncFor.includes(sliceName)) {
    const actionToSendToOtherThread: AppActionWithMeta = {
      ...action,
      meta: {...(action.meta ?? {}), _isThreadSync: true},
    };
    if (isWebWorker && action.type === "global/toggleDepartmentsExpanded") {
      // TODO: this is a pretty weird workaround
      return;
    }
    // log("WorkerSyncMiddleware", `Forwarding action to ${isWebWorker ? "main" : "worker"} thread: ${action.type}`);
    otherThreadDispatchFn(actionToSendToOtherThread);
  }
}
