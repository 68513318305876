import {getDatasourceDiff, type DatasourceDiff} from "@shared/lib/datasource-utilities";
import {log, time, timeEnd} from "@shared/lib/debug-provider";
import {intersect} from "@shared/lib/misc";
import {applyDatasourceChanges} from "@state/datasources/slice";
import {getDatasourcesAdapter} from "@state/entity-adapters";

import {buildDependencyCache, type DependencyCache, type DependencyCachePartialState} from "./dependency-cache";
import {makeDependencyKeyHumanReadable} from "./dependency-cache-debug-utilities";
import {applyDependencyCacheDiff, dependencyCachesAreTheSame, getDependencyCacheDiff} from "./dependency-cache-diff";

import type {EntityId} from "@reduxjs/toolkit";
import type {Datasource, FormulaReference} from "@shared/types/datasources";
import type {Template, TemplateRow} from "@shared/types/db";
import type {AppDispatch, RootState} from "@state/store";
import type {ListenerDiff} from "client/app/worker/worker-thread/state-changes-listener";

export function orderDependencyCache(dependencyCache: DependencyCache) {
  // const unresolvedCacheKeysList = Object.keys(dependencyCache.fullList);
  // const cacheKeysResolvedStatusMapping: Record<string, boolean> = Object.fromEntries(
  //   unresolvedCacheKeysList.map((k) => [k, false]),
  // );
  const orderedCacheKeys: string[] = [];

  const visited: Record<string, boolean> = {};
  const temp: Record<string, boolean> = {};

  const visit = (node: string) => {
    if (temp[node]) {
      console.error(
        `Circular dependency detected while ordering dependency cache - last time this cacheKey was visited we had the same number of resolved cache keys`,
      );
      return;
    }
    if (!visited[node]) {
      temp[node] = true;
      if (dependencyCache.cellToDependencies[node]) {
        for (const key of dependencyCache.cellToDependencies[node]) {
          visit(key);
        }
      }
      temp[node] = false;
      visited[node] = true;
      orderedCacheKeys.push(node);
    }
  };

  for (const node of Object.keys(dependencyCache.fullList)) {
    visit(node);
  }

  dependencyCache.orderedCacheKeys = orderedCacheKeys;
}
/**
 * @param ref - the formula reference
 * @param state - the dependency cache
 * @returns a list of row ids that match the formula reference
 */
export function resolveRowIdsForRef(ref: FormulaReference, state: DependencyCachePartialState) {
  let resolvedRowIdsForRef: string[] = [];
  if (ref.row) {
    const rowId = state.templateRows.idsByName[ref.row];
    return rowId ? [rowId] : [];
  }

  if (ref.tags) {
    const arraysToIntersect: string[][] = [];
    for (const [key, value] of Object.entries(ref.tags)) {
      arraysToIntersect.push(state.templateRows.idsByTag[`${key}::${value}`] || []);
    }
    resolvedRowIdsForRef = intersect(arraysToIntersect);
  }

  return resolvedRowIdsForRef;
}

// type DependencyCache = {
//   cellToDependencies: Record<string, Set<string>>;
//   dependencyToCells: Record<string, Set<string>>;
// fullListCollection: DependencyCacheListItem[];
//     fullList: Record<string, DependencyCacheListItem>;
//     orderedCacheKeys: string[];
// };

// export type DependencyCacheListItem = {
//   scenarioId: EntityId;
//   rowId: EntityId;
//   dateKey: string;
//   departmentId?: EntityId | null;
//   vendor?: string | null;
//   total?: boolean;
//   balance?: boolean;
// };

/**
 * Finds circular dependencies for a given cache key in the dependency cache.
 *
 * @param dependencyCache - The dependency cache.
 * @param cacheKey - The cache key to find circular dependencies for.
 * @param state - The root state.
 * @returns An array of cache keys representing the circular dependencies, or null if no circular dependencies are found.
 */
export function findCircularDependenciesForKey2024(
  dependencyCache: DependencyCache,
  cacheKey: string,
  state: RootState,
) {
  const visited: Record<string, boolean> = {};
  const visitedList: string[] = [];

  const dfs = (cacheKey: string, path: string[], cycle: string[]) => {
    if (visited[cacheKey]) return null;
    visited[cacheKey] = true;
    visitedList.push(cacheKey);
    path.push(cacheKey);

    if (dependencyCache.cellToDependencies[cacheKey]) {
      for (const dependency of dependencyCache.cellToDependencies[cacheKey]) {
        if (!visited[dependency]) {
          dfs(dependency, path, cycle);
        } else if (path.includes(dependency)) {
          const cycleStartIndex = path.indexOf(dependency);
          cycle.push(...path.slice(cycleStartIndex));
        }
      }
    }

    path.pop();
    return null;
  };

  const cycle: string[] = [];
  dfs(cacheKey, [], cycle);
  if (cycle.length > 0) {
    console.error(`Found a circular dependency`, {
      cacheKey: makeDependencyKeyHumanReadable(cacheKey, state, false, true),
      dependencies: [...dependencyCache.cellToDependencies[cacheKey]].map((k) =>
        makeDependencyKeyHumanReadable(k, state, false, true),
      ),
      cycle: cycle.map((k) => makeDependencyKeyHumanReadable(k, state, false, true)),
    });
    return cycle;
  } else {
    return null;
  }
}

const datasourcesEntityAdapter = getDatasourcesAdapter();
export function applyDsDiffToDependencyCache(
  state: RootState,
  dependencyCache: DependencyCache,
  datasourceDiff: DatasourceDiff,
  scenarioId: EntityId,
  oldTemplateOptions?: Record<string, Template["options"]>,
) {
  time("applyDsDiffToDependencyCache", "Applying datasource diff to dependency cache");
  const prevDatasourcesState = datasourcesEntityAdapter.setAll(datasourcesEntityAdapter.getInitialState(), [
    ...datasourceDiff.deletes,
    ...datasourceDiff.upsertedPreviousVersions,
  ]);
  const newDatasourcesState = datasourcesEntityAdapter.setAll(
    datasourcesEntityAdapter.getInitialState(),
    datasourceDiff.upserts,
  );

  const prevState = {...state, datasources: prevDatasourcesState};
  const newState = {...state, datasources: newDatasourcesState};

  const dependencyCacheOfPrevDs = buildDependencyCache({
    scenarioId,
    state: prevState,
    rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
    templateOptionsOverride: oldTemplateOptions,
    resolvedDates: datasourceDiff.resolvedDates.old,
    datasourcesOnly: true,
  });

  const dependencyCacheOfNewDs = buildDependencyCache({
    scenarioId,
    state: newState,
    rowIdsWithDepartmentsToResolve: state.global.departmentsExpandedRowIds,
    resolvedDates: datasourceDiff.resolvedDates.new,
    datasourcesOnly: true,
  });

  const dependencyCacheDiff = getDependencyCacheDiff(dependencyCacheOfPrevDs, dependencyCacheOfNewDs);
  let newDependencyCache = dependencyCache;
  if (!dependencyCachesAreTheSame(dependencyCacheDiff)) {
    // console.log(`Found a difference in the dependency cache`, {
    //   dependencyCacheDiff,
    // });
    // console.log(
    //   JSON.stringify(dependencyCache.cellToDependencies[Object.keys(dependencyCacheDiff.cellToDependencies.adds)[0]]),
    // );
    newDependencyCache = applyDependencyCacheDiff(dependencyCache, dependencyCacheDiff);
    time("orderDependencyCache", "Ordering dependency cache");
    orderDependencyCache(newDependencyCache);
    timeEnd("orderDependencyCache", "Ordering dependency cache");
    // Log again after
    // console.log(
    //   JSON.stringify(dependencyCache.cellToDependencies[Object.keys(dependencyCacheDiff.cellToDependencies.adds)[0]]),
    // );
  }
  timeEnd("applyDsDiffToDependencyCache", "Applying datasource diff to dependency cache");

  return {dependencyCache: newDependencyCache, dependencyCacheDiff};
}

export function applyRowChangesToDependencyCache(
  state: RootState,
  dispatch: AppDispatch,
  rowsDiff: ListenerDiff<TemplateRow>,
) {
  const impactedTags: Set<string> = new Set();

  // Handle added rows
  for (const newRow of rowsDiff.added) {
    for (const [key, value] of Object.entries(newRow.tags ?? {})) {
      impactedTags.add(`${key}:${value}`);
    }
  }

  // Handle removed rows
  for (const oldRow of rowsDiff.removed) {
    for (const [key, value] of Object.entries(oldRow.tags ?? {})) {
      impactedTags.add(`${key}:${value}`);
    }
  }

  // Handle updated rows
  for (const {previous: oldRow, current: newRow} of rowsDiff.updated) {
    for (const [key, value] of Object.entries(newRow.tags ?? {})) {
      if (oldRow.tags?.[key] !== value) {
        impactedTags.add(`${key}:${value}`);
      }
    }
    for (const [key, value] of Object.entries(oldRow.tags ?? {})) {
      if (newRow.tags?.[key] !== value) {
        impactedTags.add(`${key}:${value}`);
      }
    }
  }

  const datasourcesImpacted: Set<Datasource> = new Set();
  if (impactedTags.size) {
    for (const tag of impactedTags) {
      const datasourceIds = state.datasources.idsByReferencedEntityOrTag[tag];
      if (!datasourceIds) continue;
      for (const datasourceId of datasourceIds) {
        const datasource = state.datasources.entities[datasourceId];
        if (!datasource) continue;
        datasourcesImpacted.add(datasource);
      }
    }
  }

  log(
    `applyRowChangesToDependencyCache`,
    `Found ${datasourcesImpacted.size} datasources impacted by row changes (tags: ${[...impactedTags].join(", ")})`,
  );

  const datasourceDiff = getDatasourceDiff([], [...datasourcesImpacted], state);

  const somethingHasChanged =
    datasourceDiff.deletes.length ||
    datasourceDiff.upserts.length ||
    Object.keys(datasourceDiff.cacheKeysRemovedPerDsId).length ||
    Object.keys(datasourceDiff.cacheKeysRemovedPerDsId).length;
  if (somethingHasChanged) {
    dispatch(
      applyDatasourceChanges({
        reason: `Tags changed for rows`,
        datasourceDiff: getDatasourceDiff([], [...datasourcesImpacted], state),
      }),
    );
  }
}
