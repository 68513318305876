import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  pageNotFoundMain: {
    color: colors.text.darkBlue01,
    fontSize: 14,
    fontWeight: 500,
    width: "100%",
    display: "flex",
    paddingTop: 100,
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",

    "& h1": {
      fontWeight: 600,
      fontSize: 22,
      marginBottom: 25,
    },

    "& a": {
      fontWeight: 600,
      color: colors.text.lightBlue01,
      display: "inline-flex",
      marginTop: 10,

      "&:hover": {
        color: colors.text.darkBlue01,
      },
    },
  },
});
