import colors from "@app/styles/colors";
import {createUseStyles} from "react-jss";

export const sharedValues = {};

export default createUseStyles({
  createScenarioMain: {
    width: 590,
    maxWidth: 590,
    padding: [210, 29.5, 41, 29.5],
  },
  modalTitle: {
    lineHeight: 35,
    fontSize: 20,
    textAlign: "center",
    fontWeight: 700,
    color: colors.text.black,
    marginBottom: 30,
  },
  firstRow: {
    display: "flex",

    "& > div": {
      flex: 1,
      paddingTop: 25,

      "&:first-child": {
        marginRight: 20,
      },
    },
  },
  inputWrapper: {},
  secondRow: {
    paddingTop: 25,
    height: 139,
  },
  secondRowFormElement: {
    "& > div:last-child": {
      height: "100%",

      "& $inputWrapper, & $inputWrapper textarea": {
        height: 80,
      },
    },
  },
  thirdRow: {
    paddingTop: 17.5,
  },
  buttonsRow: {
    display: "flex",
    paddingTop: 25,
    gap: 15,
    alignItems: "center",
    justifyContent: "start",

    "& > div:nth-child(2)": {
      marginLeft: 15,
    },
  },
  statusText: {
    paddingLeft: 15,
    fontSize: 12,
    color: colors.text.black,
    opacity: 0.5,
  },
});
