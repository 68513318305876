import {getValueFromCache} from "@shared/data-functions/cache/cache-utilities";
import {selectQboStatementRowsByIdenfitierMapping} from "@state/integrations/slice";
import {selectValuesByRowIdDateKey} from "@state/transaction-items/selectors";

import {getAllDateKeysBetween} from "./date-utilities";
import {isBalance} from "./row-utilities";

import type {SanityCheck} from "@shared/types/db";
import type {RootState} from "@state/store";

export function runSanityChecks({state}: {state: RootState}) {
  const returnedSanityChecks: SanityCheck[] = [];
  const sanityChecks = Object.values(state.sanityChecks.entities);
  const qboStatementRowsByIdentifier = selectQboStatementRowsByIdenfitierMapping(state);
  const monthlyCache = selectValuesByRowIdDateKey(state);
  const monthsFailingBySanityCheckId: {[sanityCheckId: string]: string[]} = {};

  const scenarioId = state.global.scenarioId;
  if (!scenarioId)
    return {
      sanityChecks: returnedSanityChecks,
      monthsFailingBySanityCheckId,
    };

  for (const sanityCheck of sanityChecks) {
    if (!sanityCheck) continue;

    let checkFailing = false;

    let getValueToCheckAgainst = (dateKey: string): number | null => null;

    const checkedRow = state.templateRows.entities[sanityCheck.check_entity_identifier];
    const template = state.templates.entities[checkedRow?.template_id ?? ""];
    const scenarioId = state.global.scenarioId;
    if (!checkedRow || !template || !scenarioId) {
      returnedSanityChecks.push({...sanityCheck});
      continue;
    }

    // Get all the months in the template
    const months = getAllDateKeysBetween(
      template.options.start,
      sanityCheck.type === "qbo-statement-row" ? template.options.lastMonthOfActuals : template.options.end,
    );

    if (sanityCheck.type === "qbo-statement-row") {
      // Check if the QBO statement rows are still loading - if so, skip this check
      if (state.integrations.loadingQboStatementRows) {
        returnedSanityChecks.push({...sanityCheck});
        continue;
      }

      const matchingQboStatementRow = qboStatementRowsByIdentifier[sanityCheck.against_entity_identifier];
      if (matchingQboStatementRow) {
        getValueToCheckAgainst = (dateKey: string) => matchingQboStatementRow.data.values[dateKey];
      }
    } else if (sanityCheck.type === "cloudberry-row") {
      const matchingRow = state.templateRows.entities[sanityCheck.against_entity_identifier];
      if (matchingRow) {
        const matchingRowHasChildren = (state.templateRows.idsByParentRowId[matchingRow.id]?.length ?? 0) > 0;
        getValueToCheckAgainst = (dateKey: string) =>
          getValueFromCache(
            monthlyCache,
            matchingRow,
            null,
            null,
            scenarioId,
            dateKey,
            isBalance(matchingRow),
            matchingRowHasChildren,
          );
      }
    }

    // console.log(
    //   `\nComparing ${checkedRow.name} to ${sanityCheck.against_entity_identifier} (${
    //     isWebWorker ? "Worker" : "Main"
    //   } Thread)`,
    // );
    for (const dateKey of months) {
      const actualValue =
        getValueFromCache(monthlyCache, checkedRow, null, null, scenarioId, dateKey, isBalance(checkedRow), true) ?? 0;

      const valueToCheckAgainst = getValueToCheckAgainst(dateKey) ?? 0;

      let diff = Number(actualValue) - Number(valueToCheckAgainst);

      if (diff < 0.01 && diff > -0.01) {
        diff = 0;
      } else {
        checkFailing = true;
        monthsFailingBySanityCheckId[sanityCheck.id] ??= [];
        monthsFailingBySanityCheckId[sanityCheck.id].push(dateKey);
      }
      // console.log(`Date: ${dateKey}, Actual: ${actualValue}, Expected: ${valueToCheckAgainst}, Diff: ${diff}`);
    }

    returnedSanityChecks.push({
      ...sanityCheck,
      passed: !checkFailing,
    });
  }

  return {sanityChecks: returnedSanityChecks, monthsFailingBySanityCheckId};
}
