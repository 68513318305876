import {NO_DEPARTMENT_NAME} from "@shared/data-functions/formula/formula-utilities";
import memoize from "fast-memoize";

import {getFlatEntitiesWithDepth} from "./misc";

import type {Department} from "@shared/types/db";
import type {DepartmentsState} from "@state/entity-adapters";

export const getDepartmentsWithDepth = memoize((departments: Department[], includeNoDepartment: boolean) => {
  let items;
  const flatDepartmentsWithDepth = getFlatEntitiesWithDepth(departments);
  if (includeNoDepartment) {
    items = [
      {
        depth: 0,
        entity: null,
      },
      ...flatDepartmentsWithDepth,
    ];
  } else {
    items = flatDepartmentsWithDepth;
  }

  // Move department with name NO_DEPARTMENT_NAME at the end
  const noDepartmentIndex = items.findIndex((item) => item.entity?.name === NO_DEPARTMENT_NAME);
  if (noDepartmentIndex !== -1) {
    const noDepartment = items.splice(noDepartmentIndex, 1)[0];
    items.push(noDepartment);
  }

  return items;
});

export function getChildIdsRecursively(departmentsState: DepartmentsState, id: string) {
  const ids: string[] = [];

  function recursive(iteratorDeptId: string) {
    const department = departmentsState.entities[iteratorDeptId];
    if (!department) return;
    ids.push(department.id);
    const children = Object.values(departmentsState.entities).filter((item) => item?.parent === department.id);
    for (const child of children) {
      if (!child) continue;
      recursive(child.id);
    }
  }

  recursive(id);

  return ids;
}
